/**
 * @module SERVER
 * @description This will have functions that assist with making AJAX CRUD calls to the app server
 */
// eslint-disable-next-line no-redeclare, no-extra-semi
var SERVER = (function (my) {
  /**
   * @var baseAddress The base address for the app server
   * @private
   */
  var baseAddress = null;
  var errorApiUrl = null;
  /**
   * @method getBaseAddress
   * @description getter for [baseAddress](#~baseAddress)
   * @returns {string} `[baseAddress](#~baseAddress)`
   */
  my.getBaseAddress = function () {
    if (empty(baseAddress)) {
      const apiServer = APP.getProperty("network.apiServer");
      if (apiServer.overrideIP == false) {
        baseAddress = `${window.location.protocol}//${window.location.hostname}:${apiServer.portNo}${apiServer.basePath}`;
      } else {
        baseAddress = `${apiServer.protocol}//${apiServer.ipAddress}:${apiServer.portNo}${apiServer.basePath}`;
      }
    }
    return baseAddress;
  };

  my.getErrorApiUrl = function () {
    if (empty(errorApiUrl)) {
      const apiServer = APP.getProperty("network.apiServer");
      if (apiServer.overrideIP == false) {
        errorApiUrl = `${window.location.protocol}//${window.location.hostname}:${apiServer.portNo}${apiServer.commonErrorPath}`;
      } else {
        errorApiUrl = `${apiServer.protocol}//${apiServer.ipAddress}:${apiServer.portNo}${apiServer.commonErrorPath}`;
      }
    }
    return errorApiUrl;
  };

  /**
   * @method refereshAccessTokenContinueCall
   * @param {*} url url of the call to which 401 error occured so, that it can be continued after access token refresh.
   * @param {*} data data to be sent to the url
   * @returns promise which contains json response returned by the api call.
   */
  my.refereshAccessTokenContinueCall = async function (url, data) {
    let tokenRefUrl = SERVER.getBaseAddress() + "actokrefresh";
    let resp = await SERVER.fetchData(tokenRefUrl, {}); // Refreshing call
    if (resp["status"] == 401) {
      return resp;
    } else {
      let response = await SERVER.fetchData(url, data); // Continue old call
      return response;
    }
  };

  /**
   * @method processResponseForPolling
   * @param response Response which is to be checked whether polling is required or not.
   * @param url url of the next actual api call which is to be done after completion of polling.
   * @param data data to be sent in the body of next api call
   * @returns {promise | object} A promise that resolves into a javascript object containing response from actual api call,
   * or an actual javasript object indicating success.
   */
  var processResponseForPolling = async function (
    response,
    url,
    data,
    receivedRoute,
    showMask
  ) {
    let currentRoute = JSON.parse(JSON.stringify(STORE.here));
    if (currentRoute != receivedRoute) {
      return "ROUTES_MISMATCHED";
    }
    if (response.status == 200) {
      let pollResult;
      if (
        response["polling_info"] &&
        response.polling_info["polling"] == true
      ) {
        let url = SERVER.getBaseAddress() + "pollapi";
        let projVersion = PROJECT.currentProjVersion();
        pollResult = await POLLING.pollAPI(
          url,
          {
            action: response.polling_info.action,
            projVersion: projVersion,
            ...response.data.posts[0],
          },
          "",
          10,
          null,
          showMask
        );
      }
      if (
        response["polling_info"] &&
        response.polling_info["is_final_call"] == false
      ) {
        return SERVER.postData(url, data, showMask);
      }
      const result = {
        status: 200,
        state: null,
        data: { ...response.data },
      };
      // To handle navigation based on the state in response of POLLAPI while creating a new project
      if (url.includes("project/new") && pollResult && pollResult.state) {
        result["state"] = pollResult.state;
      }
      return result;
    }
  };

  /**
   * @method fetchData
   * @description Calls server with HTTP/POST at specified URL and sends specified data.
   * Assumes JSON is returned. Parse whatever is returned from server into a javascript object.
   * @param {string} url Complete url for the server API
   * @param {Object} data The data that gets JSON.stringify'ed and sent to the server as api parameters
   * @returns {Promise} A promise that resolves into a javascript object with return values or rejected on error.
   * @public
   */
  my.fetchData = function (url, data) {
    my.APP = APP;
    const jsonData = JSON.stringify(data);
    if (APP.isDebug() && "undefined" !== typeof printCurl && printCurl) {
      // console.log(`curl --request POST --url ${url} --header 'content-type: application/json' --data '${jsonData}'`);
    }
    // Default options are marked with *
    return fetch(url, {
      body: jsonData, // must match 'Content-Type' header
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "include", // include, same-origin, *omit
      headers: {
        "content-type": "application/json",
        //'X-Proxy-URL': url,
        //'withCredentials':'true',
        //'credentials': 'http://localhost'
        //'crossDomain': 'true'
        // 'Authorization': 'Basic amit:amit'
      },
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      // mode: 'cors', // no-cors, cors, *same-origin
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer", // *client, no-referrer
    }).then((response) => {
      // Handling 401 of authenticate api differently.
      let pageToken = url.split("/")[url.split("/").length - 1];
      if (response.status == 401 && pageToken == "login-via-access-token") {
        CREDENTIALS.resetCreds();
        const link = document.createElement("a");
        const userManagementUrl =
          localStorage.getItem("USER_MANAGEMENT_URL") || "";
        link.href = userManagementUrl;
        link.click();
        return;
      }
      if (
        response.status == 401 &&
        pageToken != "authenticate" &&
        pageToken != "logout" &&
        pageToken != "actokrefresh"
      ) {
        return SERVER.refereshAccessTokenContinueCall(url, data).then(
          (resp) => {
            return resp;
          }
        );
        // APP.router.navigate("/logout");
      } else if (response.status == 401 && pageToken == "actokrefresh") {
        APP.router.navigate("/logout");
        return { status: 401 };
      } else if (pageToken == "logout") {
        return JSON.stringify({ status: 301 });
      } else {
        return response.json();
      }
    }); // parses response to JSON
  };

  /**
   * @method postData
   * @description calls SERVER.fetchData function to get data from actual HTTP endpoint, based on response does furthure polling
   * or immediately returns the response.
   * @param {string} url Complete url for the server API
   * @param {Object} data The data that gets JSON.stringify'ed and sent to the server as api parameters
   * @returns {Promise} A promise that resolves into a javascript object with return values.
   * @public
   */
  my.postData = async function (url, data, showMask) {
    let currentRoute = JSON.parse(JSON.stringify(STORE.here));
    let jsonResponse = await SERVER.fetchData(url, data);
    if (jsonResponse.status == 200 && jsonResponse["polling_info"]) {
      return processResponseForPolling(
        jsonResponse,
        url,
        data,
        currentRoute,
        showMask
      );
    } else {
      return jsonResponse;
    }
  };

  /**
   * @method getData
   * @description Calls server with HTTP/GET at specified URL with params provided. The params are stringified
   * and sent as URL parameters
   * @param {string} urlstring target URL
   * @param {object} params key-value pairs to be sent as parameters.
   * @return {Promise} A promise, that resolves to a JS object that corresponds to the JSON returned by the server.
   * @public
   */
  my.getData = function (urlstring, params) {
    my.APP = APP;
    var url = new URL(urlstring);
    Object.keys(params).forEach((key) =>
      url.searchParams.append(key, params[key])
    );
    return fetch(url, {
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "include", // include, same-origin, *omit
      headers: {
        "content-type": "application/json",
        // 'Authorization': 'Basic amit:amit'
      },
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      // mode: 'cors', // no-cors, cors, *same-origin
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer", // *client, no-referrer
    }).then((response) => {
      if (response.status == 401) {
        APP.router.navigate("/logout");
      } else {
        return response.json();
      }
    }); // parses response to JSON;
  };

  /**
   * @method uploadFile
   * @description Upload a file to the remote API server
   * @param {File} file The file object representing the file to be uploaded to the server
   * @return {Promise} A promise, that resolves
   */
  my.uploadFile = function (url, file) {
    const data = new FormData();
    data.append("file", file);
    data.append("projectKey", PROJECT.currentProjectKey());
    data.append("projVersion", PROJECT.currentProjVersion());
    const opts = {
      body: data, // must match 'Content-Type' header
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "include", // include, same-origin, *omit
      headers: {
        //'content-type': 'multipart/form-data',
        // 'X-Proxy-URL': url,
        //'cache-control': "no-cache",
        //'Access-Control-Allow-Origin': '*'
        //'enctype':'multipart/form-data'
      },
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      // mode: 'no-cors', // no-cors, cors, *same-origin
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer", // *client, no-referrer
    };
    return fetch(url, opts).then((response) => {
      if (response.status == 401) {
        APP.router.navigate("/logout");
      } else {
        return response.json();
      }
    }); // parses response to JSON
  };

  return my;
})(SERVER || {});
