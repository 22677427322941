/**
 * @module PROC_CHARTS
 * @description draw various processor charts in a container
 */
// eslint-disable-next-line no-extra-semi
;var PROC_CHARTS=(function(my){

  /**
   * @member {string} svgNS
   * @description The SVG namespace. Needed for creating an SVG gradient
   * @private
   */
  // eslint-disable-next-line no-unused-vars
  const svgNS = "http://www.w3.org/2000/svg"; 

  /**
   * @member {object[]} charts
   * @description Array of Proc chart objects created using `c3.js`
   */
  var charts={};

  /**
   * @method createGradient
   * @description Create a filler gradient for the proc chart (green to very dark green)
   * @param {SVGSVGElement} svg SVG object to create to create the gradient into.
   * @param {string} id The ID to set on the gradient node
   * @param {number[]} stops array of `stop` objects {offset:'0%', 'stop-color':'#70cb05'}
   * @property {string} stop.offset percentage of total length to set the stop at
   * @property {string} stop.stop-color color of the stop
   * @private
   */
  var createGradient=function(svg,id,stops){
    var svgNS = svg.namespaceURI;
    var grad  = document.createElementNS(svgNS,'linearGradient');
    grad.setAttribute('id',id);
    for (var i=0;i<stops.length;i++){
      var attrs = stops[i];
      var stop = document.createElementNS(svgNS,'stop');
      for (var attr in attrs){
        
        if (Object.prototype.hasOwnProperty.call(attrs, attr)) stop.setAttribute(attr,attrs[attr]);
      }
      grad.appendChild(stop);
    }
  
    var defs = svg.querySelector('defs') ||
        svg.insertBefore( document.createElementNS(svgNS,'defs'), svg.firstChild);
    return defs.appendChild(grad);
  };

  /**
   * @method generate
   * @description Create a proc chart.
   * @param {object} options the parameters to be passed to `c3.js` required to specify the chart, as well as some proc chart
   * specific parameters. After creation the chart will be added to the [charts](#~charts) object.
   * @public
   */
  my.generate=function(options){
    let opt={
      bindto: options.selector,
      size: {
        height: options.height,
        width: options.width
      },
      data: {
        // x: 'x',
        columns: [
          // ['x'].concat(options.datax),
          [options.id].concat(options.data)
        ],
        type: 'area',
      },
      axis: {
        y: {
          max: 100,
          min: 0,
          padding: {top: 0, bottom: 0},
          tick:{
            values:options.yTicks
          }
          // Range includes padding, set 0 if no padding needed
          // padding: {top:0, bottom:0}
        },
        x: {
          // type: 'timeseries',
          // tick: {
          //     format: '%M:%S',
          // },
          show: false
        }
      },
      point: {
        show: false
      },
      legend:{
        show: false
      },
      transition: {
        duration: 100
      },
      id:options.id,
      onrendered: function(){//set the gradient in the area graph
        let svg=qs(this.config.bindto).qs("svg"),
            gradid=`${this.config.data_columns[0][0]}Gradient`
        let grad=createGradient(svg,gradid,[
          {offset:'0%', 'stop-color':'#70cb05'},
          {offset:'100%','stop-color':'#000'}
        ]);
        grad.setAttribute("x1", "0");
        grad.setAttribute("x2", "0");
        grad.setAttribute("y1", "0");
        grad.setAttribute("y2", "1");
      }
    };
    opt.data.colors={};
    opt.data.colors[options.id]=`url(#${options.id}Gradient)`;
    charts[opt.id]=c3.generate(opt);
  }

  /**
   * @method getChart
   * @description look up a stored `c3.js` chart object from the [`charts`](#~charts) object
   * @param {string} id the lookup key.
   * @return the `c3.js` chart object for specified chart
   */
  my.getChart=function(id){
    return charts[id];
  };

  return my;
}(PROC_CHARTS || {}));

