/* global confusionmatrixtableTemplate */
/**
 * @module CONFUSION_MATRIX
 * @description draws the Confusion Matrix shown on the `mp/mll/fprAndPA` page. Implements the 
 * [`CHART`](module-CHART.html) interface.
 */
// eslint-disable-next-line no-extra-semi
;var CONFUSION_MATRIX=(function(my){
  my.type="confusionMatrix";

  my.optsGenerator=function(options){
    return options;
  };
  my.generate=function(options){
    let opts=my.optsGenerator(options);
    qsa("#confusionMatrix .graph").forEach(x=>x.remove());
    qsa("#confusionMatrix .graph-container").forEach(x=>x.removeClass("graph-container").addClass("confusion-matrix-tables"));
    qs("#confusionMatrix .confusion-matrix-tables").innerHTML=confusionmatrixtableTemplate(opts)
    return qs("#confusionMatrix");
  }

  return my;
}(CONFUSION_MATRIX || {}));
