/* global mpeailocalTemplate, EAI_LOCAL_TEST_DATA */
/**
 * @module MP_EAI_LOCAL
 * @description Renders the Explainable AI page that allows the user to compare the
 * different models for their training and test data and their performance using various [`CHART`](module-CHART.html)s.
 */
var MP_EAI_LOCAL = (function (my) {

  var statusCheck = true ;  
  var firstTime = true ;
  /**
   * @member {string} STORE_KEY 
   * @description the key for caching data in the STORE
   * @public
   */
  my.STORE_KEY = "MP_EAI_LOCAL";

  /**
   * @member {object[]} modelsList List of available models, extracted from the model comparison data. This is
   * null until assigned in [load](#~load) and upon source selection.
   * @private
   */
  var modelsList = {};

  /**
   * @member {object[]} sourcesList List of available sources, extracted from the model comparison data. This is
   * null until assigned in [load](#~load).
   * @private
   */
  var sourcesList = [
    {
      name: "Training",
      id: ""
    }
  ];

  /**
   * @member {object} lidata
   * @description data retrieved from either the app store or from the server for this view.
   * @private
   */
  var lidata = null;

  /**
   * @member {object} computeStatus
   * @description URL local interpretability data for all the ids available, retrieved from the server 
   * and stored here after long computation.
   */
  const computeStatus = {
    set: function (source, modelID, status) {
      if (empty(this[source])) {
        this[source] = {};
      }
      this[source][modelID] = status;
      this.updateUI(source, modelID);
    },
    get: function (source, modelID) {
      if (empty(this[source]) || empty(this[source][modelID])) {
        return {
          code: 197,
          url: null,
          reason: '',
        };
      } else {
        return this[source][modelID];
      }
    },
    updateUI: function (source, modelID) {
      if (empty(this[source])) {
        this[source] = {};
      }
      if (this[source][modelID].code === 197) {
        qs("#start-compute").removeClass("triggered,done");    
      } else if (this[source][modelID].code === 198) {
        qs("#start-compute").removeClass("done").addClass("triggered");
        if (!isLIComputeRunning) {
          liPollComputeData(null, 3)
            .then(result => {
              if (result.status === 200) {
                ["code", "url", "reason"].forEach(prop => this[source][modelID][prop] = result[prop]);
                qs("#start-compute").removeClass("triggered").addClass("done");
                updateDownloadButtonTarget();
              }
            })
            .catch(() => {
              APP.showError(i18n.en.APP.UI.ERROR.MODELPERFORMANCE.COMPUTE_POLLING_FAILURE);
            });
        }
      } else if (this[source][modelID].code === 200) {
        qs("#start-compute").removeClass("triggered").addClass("done");
        updateDownloadButtonTarget();
      }
    }
  };

  /**
   * @member {string} currentPKey The ID of the project that is the context of this page. It is easier to 
   * store it rather than pass it around in every function call.
   */
  var currentPKey = null;

  /**
   * @member {object} searchParams used to trigger a search
   */
  my.searchBarValues = {
    default: {
      searchTriggered: false,
      searchIDs: [],
      modelID: "",
      source: "",
      showTop: 5,
      lowOrHigh: "high",
    },
    init: function () {
      Object.freeze(this.default);
      Object.keys(this.default).forEach(key => this[key] = this.default[key]);
    },
    reset: function () {
      this.init();
    },
  };

  /**
   * @method prime
   * @description Primes this view, adds the view's HTML from its JS Pug template into the DOM.
   * Registers listeners.
   * @private
   */
  const prime = function () {
    qs(".wait-for-ready-local").removeClass("active");
    registerListeners();
    const mlist = Object.keys(lidata);
    modelsList = {};
    mlist.forEach(mID => {
      if (empty(lidata[mID]) || empty(lidata[mID].high) || empty(lidata[mID].high.name)) {
        return;
      }
      modelsList[mID] = lidata[mID].high.name;
    });
    const modelSelect = qs("#mp-local-model-select"),
      sourceSelect = qs("#mp-local-source-select"),
      templateOption = modelSelect.qs("option.template");
    var lastOption = null;
    Object.keys(modelsList).forEach((model) => {
      const option = templateOption.cloneNode(false);
      option.text = modelsList[model];
      option.value = model;
      option.removeClass("template").addClass("generated-option").removeAttribute("hidden");
      templateOption.after(option);
      lastOption = option;
    });
    if (empty(my.searchBarValues.modelID)) {
      my.searchBarValues.modelID = modelSelect.value = lastOption.value;
    } else {
      if (modelSelect.qs(`option[value='${my.searchBarValues.modelID}']`)) {
        modelSelect.value = my.searchBarValues.modelID;
      } else {
        my.searchBarValues.modelID = modelSelect.value = lastOption.value;
      }
    }
    if (strictEmpty(my.searchBarValues.source)) {
      my.searchBarValues.source = sourceSelect.value = "";
    } else {
      if (sourceSelect.qs(`option[value='${my.searchBarValues.source}']`)) {
        sourceSelect.value = my.searchBarValues.source;
      } else {
        my.searchBarValues.source = sourceSelect.value = "";
      }
    }
    // updateComputeStatus();
    firstTime = true ;
    let p = liPollComputeData(null, 3);
      p.then(function (result) {
        computeStatus.set(my.searchBarValues.source, my.searchBarValues.modelID, {
          code: 200,
          url: result.data.posts[0].dpath,
          reason: empty(result.data.reason) ? "" : result.data.reason,
        });
      }); 
    const showCountSelect = qs("#mp-local-show-count");
    showCountSelect.value = my.searchBarValues.showTop;

    const searchByID = qs("#mp-local-search-by-id");
    if (!empty(my.searchBarValues.searchIDs)) {
      searchByID.value = my.searchBarValues.searchIDs.join(",");
    } else {
      my.searchBarValues.searchIDs = [];
    }

    const lowOrHigh = qs("#mp-local-low-or-high");
    if (!empty(my.searchBarValues.lowOrHigh)) {
      lowOrHigh.checked = ("high" === my.searchBarValues.lowOrHigh);
    } else {
      my.searchBarValues.lowOrHigh = "high";
      lowOrHigh.checked = true;
    }
    if (my.searchBarValues.searchTriggered) {
      lowOrHigh.setAttribute("disabled", "disabled");
    } else {
      lowOrHigh.removeAttribute("disabled");
    }
  }

  /**
   * @method registerListeners
   * @description Register listeners for search input
   * @private
   */
  const registerListeners = function () {
    const eaiView = qs("#explainable-ai-local");

    const sourceSelect = eaiView.qs("#mp-local-source-select");
    sourceSelect.addEventListener("change", () => {
      cancelComputePolling();
      GLOBAL_AND_LOCAL_INTERP.cancelPolling();
      my.searchBarValues.source = sourceSelect.value;
      my.triggerSearch();
      updateComputeStatus();
    });

    const modelNameSelect = eaiView.qs("#mp-local-model-select");
    modelNameSelect.addEventListener("change", () => {
      cancelComputePolling();
      GLOBAL_AND_LOCAL_INTERP.cancelPolling();
      my.searchBarValues.modelID = modelNameSelect.value;
      my.clearTable();
      my.renderTable();
      updateComputeStatus();
    });

    const topCountSelect = eaiView.qs("#mp-local-show-count");
    topCountSelect.addEventListener("change", () => {
      my.searchBarValues.showTop = parseInt(topCountSelect.value);
      my.clearTable();
      my.renderTable();
    });

    const lowOrHigh = eaiView.qs("#mp-local-low-or-high");
    lowOrHigh.addEventListener("change", () => {
      my.searchBarValues.lowOrHigh = lowOrHigh.checked ? "high" : "low";
      my.clearTable();
      my.renderTable();
    });

    const searchInput = eaiView.qs("#mp-local-search-by-id");
    searchInput.addEventListener("keyup", evt => {
      requestAnimationFrame(function () {
        if (evt.code == "Enter") {
          my.searchBarValues.searchTriggered = true;
          my.triggerSearch();
          return;
        }
        if (!empty(searchInput.value) && !empty(searchInput.value.trim())) {//if searchbox is is not empty
          try {
            // TODO : upon doing a search a console error of {State: Interrupted} is there.
            searchInput.addClass("not-empty");
            const searchIDs = searchInput.value.trim().replace(/\s*,+\s*|\s+|,+/g, ",");
            my.searchBarValues.searchIDs = searchIDs.split(","); //split search ids on commas and spaces
            searchInput.removeClass('error');
            my.searchBarValues.lowOrHigh = "high";
            lowOrHigh.checked = true;
            lowOrHigh.setAttribute("disabled", "disabled");
          } catch (err) {
            my.searchBarValues.searchIDs = [];
            searchInput.addClass("error");
          }
        } else { //reset case
          searchInput.removeClass("not-empty");
          my.searchBarValues.searchIDs = [];
          lowOrHigh.removeAttribute("disabled");
          lowOrHigh.checked = ("high" === my.searchBarValues.lowOrHigh);
          if (my.searchBarValues.searchTriggered) {
            my.searchBarValues.searchTriggered = false;
            my.triggerSearch().then(function () {
              qs("#mp-local-search-by-id").focus();
            });
          }
          my.searchBarValues.searchTriggered = false;
        }
      });
    });

    const searchButton = eaiView.qs("#mp-local-search-by-id-label .search-trigger");
    searchButton.addEventListener("click", function () {
      my.searchBarValues.searchTriggered = true;
      my.triggerSearch();
    });

    const clearLink = eaiView.qs("#mp-local-search-by-id-label .explainer a");
    clearLink.addEventListener("click", function () {
      searchInput.value = "";
      my.searchBarValues.searchIDs = [];
      my.searchBarValues.searchTriggered = false;
      my.triggerSearch();
    });

    const computeTriggerButton = eaiView.qs("#start-compute");
    computeTriggerButton.addEventListener("click", function () {
      let p = liPollComputeData(null, 3);
      p.then(function (result) {
        computeStatus.set(my.searchBarValues.source, my.searchBarValues.modelID, {
          code: 200,
          url: result.data.posts[0].dpath,
          reason: empty(result.data.reason) ? "" : result.data.reason,
        });
      });
    });
  };

  /**
   * @method triggerSearch
   * @description Perform a search based on the values stored in the member variables for the view. All these
   * values are inited and then applied to the UI elements and kept updated as and when the elements change.
   */
  my.triggerSearch = function () {
    lidata = null;
    STORE.setProjectData(currentPKey,PROJECT.currentProjVersion(), MP_EAI_LOCAL.STORE_KEY, {});
    STORE.setProjectMetadata(currentPKey,PROJECT.currentProjVersion(), MP_EAI_LOCAL.STORE_KEY + "_lidata_loaded", false);
    qs(".wait-for-ready-local").addClass("active");
    qs("#explainable-ai-local").innerHTML = mpeailocalTemplate(sourcesList);
    const p = load(currentPKey);
    p.then(function () {
    });
    return p;
  };

  /**
   * @method clearTable
   * @description clears the data table for re-rendering
   */
  my.clearTable = function () {
    qsa("#md-li-table-section tbody tr:not(.template-row)").forEach(r => r.remove());
    qs("#md-li-table-section th.causes").setAttribute("colspan", "1");
  };

  /**
   * @method renderTable
   * @description use the lidata object to render the table and apply the filters as necessary
   */
  my.renderTable = function () {
    const rowTemplate = qs("#md-li-table-section .template-row");
    if (!lidata[my.searchBarValues.modelID] ||
      empty(lidata[my.searchBarValues.modelID]) ||
      !lidata[my.searchBarValues.modelID][my.searchBarValues.lowOrHigh].info ||
      empty(lidata[my.searchBarValues.modelID][my.searchBarValues.lowOrHigh].info)) {
      APP.showInfo(i18n.en.APP.UI.ERROR.MODELPERFORMANCE.NO_MATCH_FOR_FILTERS);
      qs("#md-li-table-section table").addClass("empty");
      return;
    } else {
      APP.dismissMessage();
      qs("#md-li-table-section table").removeClass("empty");
    }
    const info = lidata[my.searchBarValues.modelID][my.searchBarValues.lowOrHigh].info;
    info.forEach((infoItem) => {
      let row = rowTemplate.cloneNode(true);
      row.removeClass("template-row");
      row.qs(".row_id").innerText = infoItem.transid;
      const predTD = row.qs(".prediction");
      predTD.innerText = (d3.format(".4f"))(infoItem.prediction);
      const templateTD = row.qs(".cause");
      templateTD.remove();
      const causeTexts = Object.keys(infoItem.causes);
      causeTexts.sort(function (a, b) {
        return infoItem.causes[b] - infoItem.causes[a];
      });
      let lastTD = null;
      for (let causeIndex = 0; causeIndex < Math.min(causeTexts.length, my.searchBarValues.showTop); causeIndex++) {
        let cause = causeTexts[causeIndex];
        let td = templateTD.cloneNode(true);
        td.qs(".text").innerText = cause;
        let v = infoItem.causes[cause];
        td.setAttribute("data-value", v); td.setAttribute("data-key", cause); td.setAttribute("title", d3.format("0.4f")(v));
        td.qs(".icon").addClass(v < 0 ? "negative" : "positive");
        v = Math.round(Math.abs(v) * 3);
        td.qs(".icon").addClass(`value-${v}`);
        (lastTD || predTD).after(td);
        lastTD = td;
      }
      qs("#md-li-table-section th.causes").setAttribute("colspan", `${Math.min(causeTexts.length, my.searchBarValues.showTop)}`);
      rowTemplate.after(row);
    });

  };

  /**
   * @method load
   * @description load the page's template and add the scope markers for the CSS. load the data for this page, 
   * either from the plot/ API or from the STORE.
   * @param {string} pkey project key which is the context for this view.
   * @async
   * @private
   */
  const load = async function (pkey) {
    let projectKey = pkey ? pkey : PROJECT.currentProjectKey();
    let projVersion = PROJECT.currentProjVersion();
    currentPKey = projectKey;

    // let result = await liPollData(null, 3);
    let result = await GLOBAL_AND_LOCAL_INTERP.pollForData("localinterp",null,3,projectKey,projVersion);
    lidata = result.data.posts[0];
    prime();
    my.renderTable();
  }

  /**
   * @method unload
   * @description deregisters any active listeners
   * @public
   */
  my.unload = function () {
    GLOBAL_AND_LOCAL_INTERP.cancelPolling();
    cancelComputePolling();
    sourcesList = sourcesList.slice(0, 1);
    modelsList = {};
    lidata = null;
    Object.keys(computeStatus).forEach(member => {
      if ("function" == (typeof computeStatus[member]).toLowerCase()) {
        return;
      }
      delete computeStatus[member];
    });
    my.searchBarValues.reset();
    qs("#explainable-ai-local").innerHTML = "";
  };

  /**
   * @method show
   * @description Load and show the correct graph page:
   * * Load the data
   * * Activate the correct graph page
   * * Mark the graph dirty
   * * Generate the graphs in a non-blocking manner
   * @param {string} pkey The project ID of the project in context
   * @async
   * @public
   */
  my.show = async function (pkey) {
    my.searchBarValues.init();
    APP.resetCurrentPageMarker();
    qs("#main-content").setAttribute("class", "");
    qs("#main-content").addClass("explainable-ai");
    let projectKey = pkey ? pkey : PROJECT.currentProjectKey();
    qs("#explainable-ai-local").innerHTML = mpeailocalTemplate();
    APP.loadAvailableSources({ projectKey: projectKey , projVersion: PROJECT.currentProjVersion()})
      .then(sources => sources.data.posts[1].OutOfTime)
      .then(updateSourceDropdownEntries);
    await load(projectKey);
  };

  /**
   * @method updateSourceDropdownEntries
   * @description upon querying the sources update the dropdown with all the OOT sources available
   * @param {Array} sources 
   */
  const updateSourceDropdownEntries = function (sources) {
    const sourceSelect = qs("#mp-local-source-select"),
      templateOption = sourceSelect.qs("option.template");
    sourcesList = sourcesList.slice(0, 1).concat(sources); //keep only the "Training" source entry and append new sources
    sourceSelect.qsa("option.generated-option").forEach(option => option.remove());
    sources.forEach((source) => {
      const option = templateOption.cloneNode(false);
      option.text = source.name;
      option.value = source.id;
      option.removeClass("template").addClass("generated-option").removeAttribute("hidden");
      templateOption.before(option);
    });
  };

  /**
   * @method updateDownloadButtonTarget
   * @description upon successful polling result for the download button, update the download button's href in order to download the data.
   * @private
   */
  const updateDownloadButtonTarget = function () {
    let status = computeStatus.get(my.searchBarValues.source, my.searchBarValues.modelID);
    if (status.code === 200) {
      let source = my.searchBarValues.source == "" ? "default" : my.searchBarValues.source;
      let url = `${SERVER.getBaseAddress()}downloadli?projVersion=${PROJECT.currentProjVersion()}&projectKey=${PROJECT.currentProjectKey()}&modelName=${my.searchBarValues.modelID}&source=${source}`
      // qs("#download-interpretability-data").setAttribute("href", ``);
      qs("#download-interpretability-data").addEventListener("click", () => { window.open(url); });
    } else {
      // qs("#download-interpretability-data").setAttribute("href", "javascript:APP.noop();");
    }
  };

  /**
   * @method checkUserHashAndSetParams
   * @description throws error if userhash is empty and sets the params.
   * @param {object} iparams userHash(optional) and projectKey(optional)
   * @return {object} params required for payload to request the server.
   * @private
   */
  const checkUserHashAndSetParams = function(iparams){
    if (empty(iparams)) {
      iparams = {};
    }
    let userHash = CREDENTIALS.getUserCreds();
    if (userHash == null) {
      throw new Error(i18n.en.APP.UI.ERROR.MODELCFG.USER_NOT_LOGGED_IN);
    }

    let params = extend({
      key: userHash,
      projectKey: PROJECT.currentProjectKey(),
      projVersion: PROJECT.currentProjVersion(),
      modelName: empty(my.searchBarValues.modelID) ? null : my.searchBarValues.modelID,
      source: empty(my.searchBarValues.source) ? null : my.searchBarValues.source,
    }, iparams);

    return params;
  }

  /**
   * @method compute
   * @description Trigger a full data compute on the server and update the download link when it is ready
   * @param {object} iparams userHash(optional) and projectKey(optional)
   * @property {string} iparams.projectKey id of the project for which to get scores
   * @return {object} Raw interpretability data obtained from the API.
   * @private
   * @async
   */
  const compute = async function (iparams) {
    let params = checkUserHashAndSetParams(iparams);    
    if(statusCheck){
    params.statusCheck = true;
    }
    statusCheck = false;
    let url = `${SERVER.getBaseAddress()}licompute`;
    // APP.setProgress("Loading data...");
    let result = null;
    try {
      if (useTestData) {
        result = await EAI_LOCAL_TEST_DATA.getLIComputeData(params);
      } else {
        result = await SERVER.postData(url, params);
      }
    } catch (err) {
      result = null;
    } 
    if(result === "ROUTES_MISMATCHED"){
      return;
    }
    if (result == null || (result.status != "success" && !(result.status >= 100 && result.status < 300))) {
      APP.showError(`${i18n.en.APP.UI.ERROR.MODELPERFORMANCE.DOWNLOAD_GENERIC} Please contact an Administrator.`);
      APP.resetProgress();
      let err = new Error(i18n.en.APP.UI.ERROR.MODELPERFORMANCE.DOWNLOAD_GENERIC); err.name = 'GenericError'; throw err;
    }
    // APP.resetProgress();
    // APP.resetProgress();
    return result;
  };

  /**
   * @method updateComputeStatus
   * @description Check the status of the compute operation on the server and update the UI and variables
   * @param {object} iparams userHash(optional) and projectKey(optional)
   * @property {string} iparams.projectKey id of the project for which to get scores
   * @private
   */
  const updateComputeStatus = function (iparams) {
    let params = checkUserHashAndSetParams(iparams);
    params = extend({statusCheck: true},params);
    if (empty(params.key) || empty(params.projectKey) || empty(params.modelName) || empty(params.statusCheck)) {
      APP.showError(`${i18n.en.APP.UI.ERROR.MODELPERFORMANCE.DOWNLOAD_STATUS_GENERIC} Please contact an Administrator.`);
      let err = new Error(`${i18n.en.APP.UI.ERROR.MODELPERFORMANCE.DOWNLOAD_STATUS_GENERIC} empty parameter passed to status check function. Parameter cannot be determined from state: \n ${JSON.stringify(params)}`); err.name = 'GenericError'; throw err;
    }

    let url = `${SERVER.getBaseAddress()}licompute`;

    let p = null;

    if (useTestData) {
      p = EAI_LOCAL_TEST_DATA.getLIComputeData(params);
    } else {
      p = SERVER.postData(url, params);
    }
    p.then(function (result) {
      if(result === "ROUTES_MISMATCHED"){
        return;
      }
      if (result == null || (result.status != "success" && !(result.status >= 100 && result.status < 300))) {
        APP.showError(`${i18n.en.APP.UI.ERROR.MODELPERFORMANCE.DOWNLOAD_STATUS_GENERIC} Please contact an Administrator.`);
        let err = new Error(i18n.en.APP.UI.ERROR.MODELPERFORMANCE.DOWNLOAD_STATUS_GENERIC); err.name = 'GenericError'; throw err;
      }
      let status = {
        code: result.status,
        url: result.data.posts[0].dpath,
        reason: empty(result.data.reason) ? "" : result.data.reason,
      };
      computeStatus.set(empty(params["source"]) ? "" : params["source"], empty(params["modelName"]) ? "" : params["modelName"], status);
    }).catch(function (err) {
      APP.showError(`${i18n.en.APP.UI.ERROR.MODELPERFORMANCE.DOWNLOAD_STATUS_GENERIC} Please contact an Administrator.`);
      APP.resetProgress();
      let uierror = new Error(i18n.en.APP.UI.ERROR.MODELPERFORMANCE.DOWNLOAD_STATUS_GENERIC); uierror.name = 'GenericError'; throw uierror;
    });
  };

  /**
   * @member {boolean} continueComputePolling
   * @description Polling stops if this is false at the time of evaluating the exit condition. This is set to `false`
   * by the [cancelPolling](#~cancelPolling) function. This flag is used for both the compute function
   * @private
   */
  var continueComputePolling = true;

  /**
   * @member {Number} liPollTimeoutToken 
   * @description This is the reference that can be used to find the current timeOut that's waiting. Used for loadData
   * @private
   */
  var liPollTimeoutToken = null;

  /**
   * @member {Number} liComputePollTimeoutToken 
   * @description This is the reference that can be used to find the current timeOut that's waiting. Used for the 
   * compute data function
   * @private
   */
  var liComputePollTimeoutToken = null;
  var isLIComputeRunning = false;

  /**
   * @method cancelComputePolling
   * @description Sets the [continueComputePolling](#~continueComputePolling) flag false. Also clears any pending
   * polling timer.
   * @public
   */
  const cancelComputePolling = function () {
    continueComputePolling = false;
    if (liComputePollTimeoutToken) {
      clearTimeout(liComputePollTimeoutToken);
      liComputePollTimeoutToken = null;
    }
  };

  /**
   * @method liPollComputeData 
   * @description Start polling the compute api until interrupted by user action or by occurance of success or failure
   * @return {Promise} Returns a promise that is resolved to a completion status. It is 
   * rejected in case of any error or elapse of timeout.
   * @param {Number} timeout If the timer loop is never interrupted it should be stopped in 
   * this much time in milliseconds
   * @param {Number} interval Loop every interval seconds.
   * @public
   */
  const liPollComputeData = function (timeout, interval) {
    var endTime = Number(new Date()) + (timeout || 6 * 60 * 60 * 1000);
    interval = interval * 1000 || 10 * 1000;
    continueComputePolling = true;
    isLIComputeRunning = true;
    statusCheck = true;

    var checkCondition = async function (resolve, reject) {
      liComputePollTimeoutToken = null;
      // If the condition is met, we're done! 
      try {
        APP.setProgress("Polling for data...", false);
        var result = await compute();
        APP.resetProgress();
      } catch (err) {
        console.error("Exception in polling.\n", err);
        APP.showError(i18n.en.APP.UI.ERROR.MODELPERFORMANCE.COMPUTE_POLLING_FAILURE);
        return reject(err);
      }
      if (empty(result) || typeof result == "undefined") {
        console.error("Null result in polling.")
        isLIComputeRunning = false;
        return reject(new Error(i18n.en.APP.UI.ERROR.MODELPERFORMANCE.COMPUTE_POLLING_FAILURE));
      }
      if(!empty(result.status) && (result.status) === 197 && firstTime) {
        firstTime = false; 
        return reject({ State: "interrupted" });
      }
      if (!empty(result.status) && (result.status === 198)) {
        computeStatus.set(my.searchBarValues.source, my.searchBarValues.modelID, {
          code: 198,
          url: '',
          reason: '',
        });
      }
      if (!empty(result.status) && (result.status >= 200 && result.status < 300)) {
        isLIComputeRunning = false;
        return resolve(result);
      }
      // If the condition isn't met but the timeout hasn't elapsed, go again
      else if (Number(new Date()) < endTime) {
        if (continueComputePolling) {
          liPollTimeoutToken = setTimeout(checkCondition, interval, resolve, reject);
        } else {
          continueComputePolling = true;
          isLIComputeRunning = false;
          return reject({ State: "interrupted" });
        }
      }
      // Didn't match and too much time, reject!
      else {
        isLIComputeRunning = false;
        return reject(new Error('timed out for ' + liPollComputeData + ': ' + arguments));
      }
    };

    return new Promise(checkCondition);
  };

  return my;
}(MP_EAI_LOCAL || {}));

