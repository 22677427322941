var FEATURE_SELECTION = (function (my) {
  /**
   * @member {Element} separator
   * @description The DOM element that acts as the draggable separator between the table and the charts area.
   * @private
   */
  var separator = null;

  /**
   * @member {boolean} dragging
   * @description While the user is dragging the [separator](#~separator) with the mouse, this variable is `true`.
   * @private
   */
  var dragging = false;

  /**
   * @member {number} originalLHSWidth
   * @description stores the width of the table area when the view first comes up. This is used to restrict
   * the dragging of the separator to this original size.
   * @private
   */
  var originalLHSWidth = null;

  /**
   * @member {string} dataSource
   * @description Whether the current view is for the "test" or "training" data.
   * @private
   */
  var datasource = "train";

  /**
   * @member {string} selectedModelID
   * @description Whether the current view is for the selected model ID from the dropdown.
   * @private
   */
  var selectedModelID = null;

  /**
   * @member {object} data
   * @description Holds the leaderboard data.
   * @private
   */
  var data = null;

  /**
   * @member {object} charts
   * @description Stores the chart objects created using `d3.js` or `c3.js`.
   * @private
   */
  let charts = {};

  /**
   * @member {object} sideBarData
   * @description Holds the data for the horizontal bar chart.
   * @private
   */
  var sideBarData = {};

  /**
   * @method resizeListener
   * @description window resize listener for redrawing graphs on zoom
   * @params evt resize event
   * @public
   */
  my.resizeListener = function (evt) {
    my.redrawGraphs();
  };

  /**
   * @method sideBarListener
   * @description auto scrolls to the clicked cluster in the side bar graph
   * @params clusterInfo for the clicked cluster
   * @public
   */
  my.sideBarListener = function (clusterInfo) {
    let clusterIndex = clusterInfo.index;
    let clusterName = sideBarData.clusters[clusterIndex];
    const clusterDiv = qs(
      `.variable-clustering-table .cluster-container .cluster-${clusterName}`
    );
    clusterDiv.scrollIntoView();
    const leftDiv = document.getElementById("selection-method-container");
    leftDiv.scrollBy(0, -100);
  };

  /**
   * @method redrawGraphs
   * @description Ask `d3.js` or `c3.js` to redraw the graphs with the data they already have. Needed in case of
   * resizing.
   * @public
   */
  my.redrawGraphs = function () {
    CHART.allowedCharts[APP.getCurrentPageEndToken()].forEach((x) => {
      if (empty(charts[x])) return;
      let chartElement = charts[x].element;
      if (charts[x].isD3Chart) {
        chartElement = charts[x].element.node();
      }
      if (!chartElement) {
        return;
      }
      if (!chartElement.closest(".graphs-outer-area").hasClass("active")) {
        return;
      }
      let outerContainer = chartElement.closest(".graph-outer-container");
      if (outerContainer.offsetWidth < 30) {
        return;
      }
      charts[x].resize({
        width: outerContainer.offsetWidth - 30,
      });
    });
  };

  /**
   * @method regenerateBarGraph
   * @description Regenerates the horizonral bar graph.
   * @params numberOfClusters from the dropdown
   * @private
   */
  const regenerateBarGraph = (numberOfClusters) => {
    APP.setProgress("Rendering plot...", false);
    var clustersArray = sideBarData.clusters; // Slicing feature array to show only atmost 10 clusters after loading
    var varcountArray = sideBarData.varcount;
    requestAnimationFrame(() => {
      charts[FEATURE_IMPORTANCE_CHART.type] = FEATURE_IMPORTANCE_CHART.generate(
        {
          data: {
            y1: {
              name: "y",
              keys: clustersArray.slice(0, numberOfClusters), // Slicing array for showing selected number of feature count
              values: varcountArray,
            },
          },
          c3d3properties: {
            onrendered: APP.resetProgress,
          },
        },
        null,
        "featureSelection"
      );
      my.resizeListener();
    });
  };

  /**
   * @method registerFeatureSelectionListeners
   * @description Register listeners for:
   * * separator drag-and-drop
   * * source selection
   * * model selection
   * * collapsing the cluster in tables
   * * controlling the number of clusters in the side bar graph
   * * window resizing
   * @private
   */
  var registerFeatureSelectionListeners = function () {
    const featureSelection = qs(".variable-clustering");
    separator = featureSelection.qs(".separator");

    featureSelection
      .qs("#modelSelect")
      .addEventListener("change", modelChanged);

    //splitter drag listener
    featureSelection.addEventListener("mousedown", (evt) => {
      if (evt.target === separator && evt.buttons === 1) {
        dragging = true;
        if (originalLHSWidth == null) {
          originalLHSWidth = qs("#selection-method-container").offsetWidth;
        }
      }
    });
    featureSelection.addEventListener("mouseup", () => {
      if (!dragging) return;
      dragging = false;
      requestAnimationFrame(my.redrawGraphs);
    });
    featureSelection.addEventListener("mousemove", (evt) => {
      if (dragging) {
        let lb = qs(".variable-clustering");
        let oldValue = parseInt(
          window.getComputedStyle(lb).getPropertyValue("--graph-area-delta")
        );
        if (originalLHSWidth + oldValue < 410 && evt.movementX < 0) {
          return;
        } //don't reduce below 400px lhs
        featureSelection.style.setProperty(
          "--graph-area-delta",
          oldValue + evt.movementX
        );
      }
    });
    if (!my.windowListenerAdded) {
      window.addEventListener("resize", my.resizeListener);
      my.windowListenerAdded = true;
    }

    qs("#selection-method-container").addEventListener("scroll", function () {
      my.scrollLocation = qs("#selection-method-container").scrollTop;
    });

    featureSelection.addEventListener("click", (evt) => {
      if (evt.target.className == "chevron-down-icon") {
        const cluster = evt.target.getAttribute("index");
        qs(`.variable-clustering .cluster-body-${cluster}`).addClass("hidden");
        evt.target.addClass("hidden");
        qs(
          `.variable-clustering .cluster-card.cluster-${cluster} .chevron-up-icon`
        ).removeClass("hidden");
      } else if (evt.target.className == "chevron-up-icon") {
        const cluster = evt.target.getAttribute("index");
        qs(`.variable-clustering .cluster-body-${cluster}`).removeClass(
          "hidden"
        );
        evt.target.addClass("hidden");
        qs(
          `.variable-clustering .cluster-card.cluster-${cluster} .chevron-down-icon`
        ).removeClass("hidden");
      }
    });

    // Event listener for changing the numer of cluster count
    const clusterDropdown = featureSelection.qs("#cluster-count-select");
    clusterDropdown.addEventListener("change", (evt) => {
      const dropdownValue = evt.target.value;
      clusterDropdown.value = dropdownValue;
      regenerateBarGraph(dropdownValue);
    });
    //download csv file button listner
    qs(".download-button").addEventListener("click", () => {
      const jsonData =
        data[selectedModelID][datasource].varClusInfo.clusInfo || {};
      downloadCsv(jsonData, `${selectedModelID}_feature_selection.csv`);
    });
  };

  /**
   * @method downloadCsv
   * @description method to download CSV file
   * @params JsonData, which is unformatted complete data for the table and filename
   * @private
   */
  const downloadCsv = function (jsonData, filename) {
    const csvData = convertDataToCsv(jsonData);
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    link.click();
  };

  /**
   * @method convertDataToCsv
   * @description method to convert unformatted to CSV file data
   * @params JsonData, which is unformatted complete data for the table
   * @private
   */
  const convertDataToCsv = function (data) {
    const separator = ",";
    const clusters = Object.keys(data);
    const csvHeader = [
      "Cluster",
      "Variable",
      "R2_Own",
      "R2_NC",
      "1-R2_Ratio",
    ].join(separator);
    const csvBody = [];
    clusters.forEach((cluster) => {
      data[cluster].forEach((row) => {
        const variableName = Object.keys(row)[0];
        const variableDetails = [];
        variableDetails.push(`CLUSTER-${cluster}`);
        variableDetails.push(variableName);
        variableDetails.push(row[variableName].RS_Own);
        variableDetails.push(row[variableName].RS_NC);
        variableDetails.push(row[variableName].RS_Ratio);
        csvBody.push(`${variableDetails.join(separator)}`);
      });
    });
    return `${csvHeader}\n${csvBody.join("\n")}`;
  };

  /**
   * @method sortGraphData
   * @description sorts side bar graph data so that the clusters are displayed in order
   * of their features count
   */
  const sortGraphData = function (sortableData) {
    if (empty(sortableData)) {
      throw new Error("No graphdata to sort.");
    }

    const pairs = [];
    for (let i = 0; i < sortableData.clusters.length; i++) {
      pairs.push({
        cluster: sortableData.clusters[i],
        score: sortableData.varcount[i],
      });
    }
    pairs.sort(function (a, b) {
      return b.score - a.score;
    });
    for (let i = 0; i < pairs.length; i++) {
      sortableData.clusters[i] = pairs[i].cluster;
      sortableData.varcount[i] = pairs[i].score;
    }
    return sortableData;
  };

  /**
   * @method populateGraphDropdown
   * @description populates the clusters dropdown.
   */
  const populateGraphDropdown = function () {
    var barCountSelect = qs(".variable-clustering #cluster-count-select");
    qs(".variable-clustering .cluster-dropdown").style.display = "flex"; // Making feature count dropdown visible
    qs(
      ".variable-clustering .graphs-container .graph-outer-container"
    ).style.marginTop = "5rem"; // Adding space for drop-down
    barCountSelect.innerHTML = ""; // Cleared previously loaded feature count if present
    var defaultSelectedOption = false; // flag for make only one option selected by default
    var numberOfClusters = sideBarData.clusters.length - 1; // Number of feature

    // Loop to add number of feature counts in drop-down
    for (i in sideBarData.clusters) {
      var value = parseInt(i) + 1;
      // condition to make one option selected by default
      if ((i == 9 || i == numberOfClusters) && !defaultSelectedOption) {
        defaultSelectedOption = true;
        barCountSelect.innerHTML += `<option value=${value} selected>${value}</option>`;
      } else {
        barCountSelect.innerHTML += `<option value=${value}>${value}</option>`;
      }
    }
  };

  /**
   * @method generateSideBarChart
   * @description generates the side bar chart using feature-importance-chart.js file.
   */
  const generateSideBarChart = function () {
    APP.setProgress("Rendering plot...", false);
    sideBarData = {};
    sideBarData["clusters"] = [];
    sideBarData["varcount"] = [];
    let clustersInfo = data[selectedModelID][datasource].varClusInfo.clusInfo;
    for (let cluster of Object.keys(
      data[selectedModelID][datasource].varClusInfo.clusInfo
    )) {
      sideBarData.clusters.push(cluster);
      sideBarData.varcount.push(clustersInfo[cluster].length);
    }
    sideBarData = sortGraphData(sideBarData);
    populateGraphDropdown();
    var clustersArray = sideBarData.clusters.slice(0, 10); // Slicing feature array to show only atmost 10 clusters after loading
    var varcountArray = sideBarData.varcount.slice(0, 10);
    var barCountSelect = qs(".variable-clustering #cluster-count-select");
    barCountSelect.setAttribute(
      "onfocus",
      `this.size=${clustersArray.length >= 10 ? 10 : clustersArray.length};`
    ); // Set the size of drop-down list
    requestAnimationFrame(() => {
      if (charts[FEATURE_IMPORTANCE_CHART.type]) {
        if (charts[FEATURE_IMPORTANCE_CHART.type].constructor !== Array) {
          charts[FEATURE_IMPORTANCE_CHART.type] = [
            charts[FEATURE_IMPORTANCE_CHART.type],
          ];
        }
        charts[FEATURE_IMPORTANCE_CHART.type].push(
          FEATURE_IMPORTANCE_CHART.generate(
            {
              data: {
                y1: {
                  name: "y",
                  keys: clustersArray,
                  values: varcountArray,
                },
              },
              c3d3properties: {
                onrendered: APP.resetProgress,
              },
            },
            null,
            "featureSelection"
          )
        );
      } else {
        charts[FEATURE_IMPORTANCE_CHART.type] =
          FEATURE_IMPORTANCE_CHART.generate(
            {
              data: {
                y1: {
                  name: "y",
                  keys: clustersArray,
                  values: varcountArray,
                },
              },
              c3d3properties: {
                onrendered: APP.resetProgress,
              },
            },
            null,
            "featureSelection"
          );
      }
      my.resizeListener();
    });
  };

  /**
   * @method getModelOptions
   * @description fetches the available models and populates the model dropdown.
   */
  const getModelOptions = function (model) {
    const modelList = Object.keys(data);
    selectedModelID = empty(model) ? modelList[0] : model.toUpperCase();
    const selectElement = qs("#modelSelect");
    modelList.forEach((modelID) => {
      const optionElement = document.createElement("option");
      optionElement.value = modelID;
      optionElement.textContent = data[modelID].name;
      if (modelID === selectedModelID) {
        optionElement.selected = true;
      }
      selectElement.appendChild(optionElement);
    });
  };

  /**
   * @method modelChanged
   * @description reloads the page with the selected model from the dropdown
   */
  const modelChanged = function () {
    const selectElement = qs(".variable-clustering #modelSelect");
    const selectedOption = selectElement.options[selectElement.selectedIndex];
    const selectedValue = selectedOption.value;
    selectedModelID = selectedValue;
    reloadData();
  };

  /**
   * @method reloadData
   * @description reloads the table and the side bar graph.
   */
  const reloadData = function () {
    loadTable();
    generateSideBarChart();
  };

  /**
   * @method loadTable
   * @description loads the table using the featureSelectionTable.pug file.
   */
  const loadTable = function () {
    const tableData = data[selectedModelID][datasource].varClusInfo || {};
    const table = qs(".variable-clustering .table-container");
    table.innerHTML = "";
    table.appendChild(
      createNode(
        featureselectiontableTemplate({
          data: tableData,
        })
      )
    );

    qsa(".collapsable").forEach((x) => (x.style.display = "none"));
    qsa(".trigger").forEach((x) =>
      x.addEventListener("click", () => {
        index = x.getAttribute("index");
        number = x.getAttribute("numberOfVersions");
        qsa(`.collapse-${index}`).forEach((y) => {
          if (y.style.display === "none") {
            y.style.display = "block";
            x.innerText = "Show Less";
          } else {
            y.style.display = "none";
            x.innerText = `View +${number - 2} more`;
          }
        });
      })
    );
  };

  /**
   * @method resetVariables
   * @description resets all the variables upon landing the page to avoid caching issues.
   */
  const resetVariables = function () {
    datasource = "train";
    selectedModelID = null;
    data = null;
    separator = null;
    dragging = false;
    originalLHSWidth = null;
    charts = {};
    sideBarData = {};
  };

  /**
   * @method loadPage
   * @description loads the page for the first time
   */
  my.loadPage = async function (leaderboardData, source, model) {
    resetVariables();
    data = leaderboardData;
    qs("#main-content .content.ml-leaderboard-segmentation").innerHTML =
      featureselectionTemplate();
    qs("#main-content .content.ml-leaderboard-segmentation").style.overflow =
      "";
    getModelOptions(model);
    loadTable();
    generateSideBarChart();
    registerFeatureSelectionListeners();
  };

  return my;
})(FEATURE_SELECTION || {});
