/* global mpmlleaderboardTemplate, CHART, ROC_CHART, KS_CHART, LIFT_CHART, DISCRIMINATION_CHART, CALIBRATION_CHART,
          PROB_LEVELS, PRECISION_CHART, OGIVE_CHART, CONFUSION_MATRIX, MP_TEST_DATA, ACTUAL_VS_PREDICTED_CHART,
          RESIDUALS_CHART, NORMALITY_CHART, RESIDUAL_VS_PREDICTED_CHART */
/**
 * @module ML_LEADERBOARD
 * @description Renders the Machine Learning leaderboard page that allows the user to compare the
 * different models for their training and test data and their performance using various [`CHART`](module-CHART.html)s.
 */
var ML_LEADERBOARD = (function (my) {
  my.scrollLocation = 0;
  /**
   * @member {string} STORE_KEY
   * @description the key for caching data in the STORE
   * @public
   */
  my.STORE_KEY = "ML_LEADERBOARD";

  /**
   * @member {object} charts
   * @description Stores the chart objects created using `d3.js` or `c3.js`.
   * @private
   */
  var charts = {};

  /**
   * @member {Element} separator
   * @description The DOM element that acts as the draggable separator between the table and the charts area.
   * @private
   */
  var separator = null;

  /**
   * @member {boolean} dragging
   * @description While the user is dragging the [separator](#~separator) with the mouse, this variable is `true`.
   * @private
   */
  var dragging = false;

  /**
   * @member {number} originalLHSWidth
   * @description stores the width of the table area when the view first comes up. This is used to restrict
   * the dragging of the separator to this original size.
   * @private
   */
  var originalLHSWidth = null;

  /**
   * @member {boolean} generated
   * @description Stores whether the graphs have been generated for the currently selected model. This is so
   * that the graph need not be generated again if a page change (between graphs) has happened, but no change
   * in model selection or source selection has happened.
   * @private
   */
  var generated = false;

  /**
   * @member {string} dataSource
   * @description Whether the current view is for the "test" or "training" data.
   * @public
   */
  my.dataSource = "test";

  /**
   * @member {object} graphdata
   * @description Cache the data for the graphs between page context switches between graph pages.
   * @private
   */
  var graphdata = null;

  /**
   * @member {object} sources
   * @description list of all sources that have been added to the project. Retrieved from connection/list/sources and updated here.
   * @private
   */
  var sources = null;

  /**
   *
   */
  const thresholdMap = {
    data: {},
    set: function (model, source, threshold) {
      if (!this.data[model]) {
        this.data[model] = {};
      }
      if (!this.data[model][source]) {
        this.data[model][source] = {}
      }
      this.data[model][source].threshold = threshold;
      this.persist();
    },
    get: function (model, source) {
      try {
        return this.data[model][source].threshold;
      } catch (err) {
        return "";
      }
    },
    unset: function (model, source) {
      try {
        delete this.data[model][source].threshold;
      } catch (err) {
        return;
      }
    },
    persist: function () {
      STORE.setProjectData(PROJECT.currentProjectKey(), PROJECT.currentProjVersion(), ML_LEADERBOARD.STORE_KEY + "_thresholdMapData", this.data);
    },
    load: function () {
      this.data = STORE.getProjectData(PROJECT.currentProjectKey(), PROJECT.currentProjVersion(), ML_LEADERBOARD.STORE_KEY + "_thresholdMapData");
    },
    empty: function () {
      this.data = {};
    }
  };

  /**
   * @method prime
   * @description Primes this view, adds the view's HTML from its JS Pug template into the DOM.
   * Registers listeners.
   * @private
   */
  const prime = function () {
    qs("#leaderboard").innerHTML = mpmlleaderboardTemplate();
    const sourceOptionTemplate = qs("#sourceSelect .template");
    sources.forEach(src => {
      if (empty(src.hasTarget)) {
        return;
      }
      const option = sourceOptionTemplate.cloneNode();
      ["value", "style", "class", "disabled"].forEach(attr => option.removeAttribute(attr));
      option.setAttribute("value", src.id);
      option.innerText = src.name;
      qs("#sourceSelect").append(option);
    });

    qs("#ml-table-outer-container").addEventListener('scroll', function () {
      // sessionStorage.scrollTop = qs("#ml-table-outer-container").scrollTop;
      my.scrollLocation = qs("#ml-table-outer-container").scrollTop;
    });
    // if(sessionStorage.scrollTop != undefined) {
    //   qs("#ml-table-outer-container").scrollTop=sessionStorage.scrollTop;
    // }


    // qs("#ml-table-outer-container").ready(function(){
    //   if (sessionStorage.scrollTop != "undefined") {
    //     qs("#ml-table-outer-container").scrollTop(sessionStorage.scrollTop);
    //   }
    // });
    registerListeners();
  };

  /**
   * @method resizeListener
   * @description window resize listener for redrawing graphs on zoom
   * @params evt resize event
   * @private
   */
  // eslint-disable-next-line no-unused-vars
  var resizeListener = function (evt) {
    my.redrawGraphs();
  };

  const convertDataToCsv = function (liftAnalysisData) {
    const separator = ",";
    const cols = Object.keys(liftAnalysisData);
    var csvHeader = [];
    cols.forEach(col => {
      csvHeader.push(liftAnalysisData[col].name);
    })
    csvHeader.join(separator)
    const csvBody = [];
    var tableRow = [];
    for(let i=0; i< liftAnalysisData[cols[0]].data.length; i++){
      var tableRow = [];
      for(let j=0; j< cols.length; j++){
        tableRow.push(liftAnalysisData[cols[j]].data[i])
      }
      csvBody.push(`${tableRow.join(separator)}`);
    }
    return `${csvHeader}\n${csvBody.join("\n")}`;
  };

  const downloadCsv = function (jsonData, filename) {
    const csvData = convertDataToCsv(jsonData);
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    link.click();
  };

  /**
   * @method registerListeners
   * @description Register listeners for:
   * * separator drag-and-drop
   * * source selection
   * * window resizing
   * @private
   */
  var registerListeners = function () {
    const leaderboard = qs("#ml-leaderboard");
    separator = leaderboard.qs(".separator");

    //splitter drag listener
    leaderboard.addEventListener("mousedown", evt => {
      if (evt.target === separator && evt.buttons === 1) {
        dragging = true;
        if (originalLHSWidth == null) { originalLHSWidth = qs("#ml-table-outer-container").offsetWidth; }
      }
    });
    leaderboard.addEventListener("mouseup", () => {
      if (!dragging) return;
      dragging = false;
      requestAnimationFrame(my.redrawGraphs);
    });
    leaderboard.addEventListener("mousemove", evt => {
      if (dragging) {
        let lb = qs("#ml-leaderboard");
        let oldValue = parseInt(window.getComputedStyle(lb).getPropertyValue("--graph-area-delta"));
        if (originalLHSWidth + oldValue < 410 && evt.movementX < 0) { return; }//don't reduce below 400px lhs
        leaderboard.style.setProperty("--graph-area-delta", oldValue + evt.movementX);
      }
    });

    if (!my.windowListenerAdded) {
      window.addEventListener("resize", resizeListener);
      my.windowListenerAdded = true;
    }

    leaderboard.qs("#sourceSelect").addEventListener("change", selectSource);
    leaderboard.addEventListener("click", evt => {
      if (evt.target.id == "prob-threshold-save") saveProbThresholdListener(evt);
      if (evt.target.id == "prob-reset") resetThresholdFieldListener(evt);
    });
    leaderboard.addEventListener("keydown", evt => {
      if (evt.target.id == "prob-threshold-input") tableAutoSelectListener(evt);
    });
  }

  /**
   * @method load
   * @description load the page's template and add the scope markers for the CSS. load the data for this page,
   * either from the plot/ API or from the STORE.
   * @param {string} pkey project key which is the context for this view.
   * @async
   * @private
   */
  const load = async function (pkey) {
    APP.resetCurrentPageMarker();
    // APP.setCurrentPageMarker("ml-leaderboard");
    qs("#main-content").setAttribute("class", "");
    qs("#main-content").addClass("ml-leaderboard");
    let projectKey = pkey ? pkey : PROJECT.currentProjectKey();
    // graphdata=STORE.getProjectData(projectKey, ML_LEADERBOARD.STORE_KEY);
    if (!graphdata) {
      APP.setProgress("Loading plot data...");
      const result = await Promise.all([loadData({ projectKey: pkey, projVersion: PROJECT.currentProjVersion() }), APP.loadAvailableSources({ projectKey: pkey, projVersion: PROJECT.currentProjVersion(), showProgress: false })]);
      APP.resetProgress();
      graphdata = result[0];
      sources = result[1].data.posts[1].OutOfTime;
      STORE.setProjectData(projectKey, PROJECT.currentProjVersion(), ML_LEADERBOARD.STORE_KEY, graphdata);
      STORE.setProjectMetadata(projectKey, PROJECT.currentProjVersion(), ML_LEADERBOARD.STORE_KEY + "_graphdata_loaded", true);
    }

    prime();
  }

  /**
   * @method unload
   * @description deregisters any active listeners
   * @public
   */
  my.unload = function () {
    if (my.windowListenerAdded) {
      window.removeEventListener("resize", resizeListener);
      delete my.windowListenerAdded;
    }

    graphdata = null;
    qsa("#ml-leaderboard .graphs-outer-area.active .graph-outer-container .graph").forEach(g => {
      g.childNodes.forEach(n => n.remove());
    });
    qsa("#ml-leaderboard .graphs-outer-area.active").forEach(g => {
      g.removeClass("active");
    });
  };

  /**
   * @method show
   * @description Load and show the correct graph page:
   * * Load the data
   * * Activate the correct graph page
   * * Mark the graph dirty
   * * Generate the graphs in a non-blocking manner
   * @param {string} viewName One of "discrimination", "calibration", or "fprAndPA"
   * @param {string} pkey The project ID of the project in context
   * @async
   * @public
   */
  my.show = async function (viewName, source, model, pkey) {
    await load(pkey);

    loadModelsTable();
    qsa("#ml-leaderboard #graph-area .active").forEach(x => x.removeClass("active"));
    qs(`#ml-leaderboard #graph-area #${APP.getCurrentPageEndToken()}`).addClass("active");
    if (empty(source)) {
      source = STORE.getProjectData(pkey, PROJECT.currentProjVersion(), ML_LEADERBOARD.STORE_KEY + "_current_source");
      source = empty(source) ? qs("#sourceSelect").value : source;
    }
    if (empty(model)) {
      model = STORE.getProjectData(pkey, PROJECT.currentProjVersion(), ML_LEADERBOARD.STORE_KEY + "_current_model");
    }
    if (empty(model)) {
      const models = Object.keys(graphdata);
      for (let i = 0; i < models.length; i++) {
        if (!empty(graphdata[models[i]][source])) {
          model = models[i].toLowerCase();
          break;
        }
      }
    }
    qs("#sourceSelect").value = source;
    selectSource(source, false);
    my.selectRow(model, false);
    updateThresholdVisibility();
    generated = false;
    requestAnimationFrame(my.generateGraphs);
  }

  const registerListenerToDownloadGainTable = function (){
    qsa("#ml-leaderboard .tableContainer .leaderboard-models-table .download-button").forEach(downloadBtn => downloadBtn.addEventListener("click", (evt) => {
      let modelID = evt.target.parentElement.getAttribute("id");
      Object.keys(graphdata).forEach(x => {
        if (x.toLowerCase() == modelID) {
          modelID = x;
        }
      });
      const jsonData = graphdata[modelID][my.dataSource].GainInfo || {};
      delete jsonData.status;
      downloadCsv(jsonData, `${modelID}_${my.dataSource}_gain_analysis.csv`);
    }));
  }

  /**
   * @method loadModelsTable
   * @description populate the LHS table with names of the models
   * @private
   */
  const loadModelsTable = function () {
    if (qs("#ml-leaderboard .tableContainer td")) { return; }
    let html = '';
    Object.keys(graphdata).forEach(x => {
      html += addTableRow({
        type: graphdata[x].platform.toLowerCase(),
        id: x.toLowerCase(),
        name: graphdata[x].name
      });
    });
    qs("#ml-leaderboard .tableContainer table tbody").innerHTML = html;
    qs("#ml-table-outer-container tbody tr:first-child td").addClass("selected");
    registerListenerToDownloadGainTable();
  };

  /**
   * @method addTableRow
   * @description Use the data in `row` to generate a table row HTML for rendering the LHS table.
   * @param {object} row
   * @property {string} row.type "SPARK", "H2O" etc
   * @property {string} row.id "LRM", "DRF", "GBM" etc.
   * @property {string} rown.name Full name of the model "Generalized Linear Regression", "Distributed Random Forest" etc.
   * @private
   */
  const addTableRow = function (row) {
    return `
      <tr>
        <td class='type-${row.type}' id='${row.id}' data-type='${row.type}'><a href='javascript:ML_LEADERBOARD.selectRow("${row.id}", true);'>${row.name}</a><span tooltip="Download table" flow="left" class="download-button"></span></td>
      </tr>`;
  };

  /**
   * @method tableAutoSelectListener
   * @description Fired on threshold field update as the user types. This function is debounced so it fires only once every 300ms
   * It autoselects the prob table row with the probability closest to the threshold typed in the threshold field
   * @param {Event} evt
   */
  const tableAutoSelect = function (evt) {
    if (!graphdata) return;
    const input = qs("#prob-threshold-input");
    let value = null;
    try {
      value = parseFloat(input.value);
    } catch {
      return;
    }
    const selectedTD = qs("#ml-table-outer-container td.selected");
    if (selectedTD) {
      modelID = selectedTD.id;
    } else {
      modelID = qs("#ml-table-outer-container tr:first-child td").id;
    }
    Object.keys(graphdata).forEach(x => {
      if (x.toLowerCase() == modelID) {
        modelID = x;
      }
    });
    const probData = graphdata[modelID][my.dataSource].probStats.map((x, i) => { x.rowIndex = i; return x; });

    var selectedIndex = probData.reduce(function (acc, cur, i, arr) {
      if (Math.abs(cur.probLevel - value) < Math.abs(arr[acc].probLevel - value)) {
        selectedIndex = i;
        return i;
      }
      return acc;
    }, 0);

    const table = qs("#probLevelsTableDiv table");
    if (!table) return;
    table.qsa("tr.selected").forEach(x => x.removeClass("selected"));
    table.qs("tbody").children[selectedIndex].addClass("selected");
    updateConfusionMatrix({
      "modelID": modelID,
    });
    table.qs("tr.selected").scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "start"
    });
  };
  const tableAutoSelectListener = debounce(tableAutoSelect, 300);

  /**
   * @method resetThresholdFieldListener
   * @description called when the reset threshold button is clicked. calls the relprob api and resets field contents on success.
   * @param {Event} evt
   */
  const resetThresholdFieldListener = function (evt) {
    const params = {};
    let selectedTD = qs("#ml-table-outer-container td.selected");
    if (selectedTD) {
      params.modelType = selectedTD.id;
    } else {
      params.modelType = qs("#ml-table-outer-container tr:first-child td").id;
    }
    Object.keys(graphdata).forEach(x => {
      if (x.toLowerCase() == params.modelType) {
        params.modelType = x;
      }
    });
    params.dataSource = my.dataSource;

    APP.dismissMessage();
    releaseThreshold(params).then(result => {
      thresholdMap.unset(params.modelType, params.dataSource);
      resetThresholdField();
      APP.resetProgress();
      let msg = result.data.message ? result.data.message : i18n.en.APP.UI.ERROR.MODELPERFORMANCE.PROBABILITY_THRESHOLD_RESET;
      my.unload();
      APP.router.navigate("/mp/mll/fprAndPA");
      setTimeout(function () { APP.showInfo(msg) }, 500);
    }).catch(err => {
      APP.resetProgress();
      APP.showError(err.message);
    });
  };

  /**
   * @method resetThresholdField
   * @description reset the contents of the threshold field and update it min and max attributes based on the the selected
   * source and model's prob data
   * @param {object} probData Probability data to use to update the field
   */
  const resetThresholdField = function (probData) {
    const field = qs("#prob-threshold-input");
    // should be checking for some property of probData to confirm this is probData rather than trying to see if this is NOT probData
    // however doing so will be more complicated and a false failure will only result in refetching the probData.
    // a false success remains a problem though nothing in the current codepath can cause it.
    var modelID = ""
    const selectedTD = qs("#ml-table-outer-container td.selected");
    if (selectedTD) {
      modelID = selectedTD.id;
    } else {
      modelID = qs("#ml-table-outer-container tr:first-child td").id;
    }
    Object.keys(graphdata).forEach(x => {
      if (x.toLowerCase() == modelID) {
        modelID = x;
      }
    });
    if (empty(probData) || probData instanceof Event) {
      probData = graphdata[modelID][my.dataSource].probStats.map((x, i) => { x.rowIndex = i; return x; });
    }
    field.value = thresholdMap.get(modelID, my.dataSource);
    tableAutoSelect();
    field.origMin = probData[0].probLevel;
    field.origMax = probData[probData.length - 1].probLevel;
    field.setAttribute("min", "" + field.origMin);
    field.setAttribute("max", "" + field.origMax);
  };

  /**
   * @method selectRow
   * @description Called from the row click listener to update the RHS graph area with a new model selection.
   * @param {string} modelID "LRM", "DRF", "GBM" etc.
   * @public
   */
  my.selectRow = function (modelID, refresh) {
    qsa('#ml-leaderboard .tableContainer td.selected').forEach(x => x.removeClass("selected"));
    qs(`#ml-leaderboard .tableContainer td#${modelID}`).addClass("selected");
    if (!empty(refresh)) {
      generated = false;
      requestAnimationFrame(my.generateGraphs);
    }
    STORE.setProjectData(PROJECT.currentProjectKey(), PROJECT.currentProjVersion(), ML_LEADERBOARD.STORE_KEY + "_current_model", modelID);
    APP.router.pause();
    APP.router.navigate(`mp/mll/${APP.getCurrentPageEndToken()}/${my.dataSource}/${modelID}/${PROJECT.currentProjectKey()}`);
    APP.router.resume();
  }

  const selectSource = function (source, refresh) {
    if (Object.prototype.toString.call(source).toLowerCase().includes("event")) {
      source = source.target.value;
    }
    my.dataSource = source;
    const tbody = qs(".leaderboard .leaderboard-models-table tbody");
    tbody.qsa("td").forEach(modelCell => modelCell.removeClass("unavailable"));
    Object.keys(graphdata).forEach((modelName) => {//check which models are available for this source
      const modelData = graphdata[modelName];
      const modelCell = tbody.qs(`td#${modelName.toLowerCase()}`);
      if (empty(modelData[my.dataSource])) {
        modelCell.addClass("unavailable");
      }
    });
    //if selected cell is unavailable calculate new selected cell
    let selectedCell = tbody.qs("td.selected:not(.unavailable)");
    if (!selectedCell) {//if selected cell is unavailable
      const availableCells = tbody.qsa("td:not(.unavailable)");
      tbody.qsa("td.selected").forEach(td => td.removeClass("selected"));
      if (!empty(availableCells)) {
        selectedCell = availableCells[0].addClass("selected");
      }
    }
    let modelID = null;
    if (selectedCell) {
      modelID = selectedCell.id;
    } else {
      modelID = "model";
      qsa("#ml-leaderboard .graphs-outer-area.active .graph-outer-container .graph").forEach(g => {
        g.childNodes.forEach(n => n.remove());
      });
    }
    STORE.setProjectData(PROJECT.currentProjectKey(), PROJECT.currentProjVersion(), ML_LEADERBOARD.STORE_KEY + "_current_source", source);
    STORE.setProjectData(PROJECT.currentProjectKey(), PROJECT.currentProjVersion(), ML_LEADERBOARD.STORE_KEY + "_current_model", modelID);
    updateThresholdVisibility();

    if (strictEmpty(refresh) || refresh) {
      generated = false;
      requestAnimationFrame(my.generateGraphs);
    }

    APP.router.pause(true);
    APP.router.navigate(`mp/mll/${APP.getCurrentPageEndToken()}/${my.dataSource}/${modelID}/${PROJECT.currentProjectKey()}`);
    APP.router.pause(false);
  };

  /**
   * action method for updating the visibility of the prob threshold form
   */
  const updateThresholdVisibility = function () {
    if (my.dataSource == "test" || my.dataSource == "train") {
      qs("#prob-threshold-container").removeClass("hidden");
    } else {
      qs("#prob-threshold-container").addClass("hidden");
    }
  };

  /**
   * Button listener for the prbability threshold save button
   * @param {Event} evt
   */
  const saveProbThresholdListener = function (evt) {
    const params = {};
    let selectedTD = qs("#ml-table-outer-container td.selected");
    if (selectedTD) {
      params.modelType = selectedTD.id;
    } else {
      params.modelType = qs("#ml-table-outer-container tr:first-child td").id;
    }
    Object.keys(graphdata).forEach(x => {
      if (x.toLowerCase() == params.modelType) {
        params.modelType = x;
      }
    });
    params.dataSource = my.dataSource;
    const input = qs("#prob-threshold-input");

    if (strictEmpty(input.origMin)) { input.origMin = 0; }
    if (strictEmpty(input.origMax)) { input.origMax = 1; }
    const float4 = d3.format(".4f");

    if (input.value === '' || input.value.match(/[\d\.]*/g)[0] !== input.value) {
      APP.showError(sprintf(i18n.en.APP.UI.ERROR.MODELPERFORMANCE.PROBABILITY_THRESHOLD_INVALID_INPUT, float4(input.origMin), float4(input.origMax)));
      return;
    }

    try {
      params.probability = parseFloat(input.value);
    } catch {
      APP.showError(sprintf(i18n.en.APP.UI.ERROR.MODELPERFORMANCE.PROBABILITY_THRESHOLD_INVALID_INPUT, float4(input.origMin), float4(input.origMax)));
      return;
    }

    if (params.probability < input.origMin || params.probability > input.origMax) {
      APP.showError(sprintf(i18n.en.APP.UI.ERROR.MODELPERFORMANCE.PROBABILITY_THRESHOLD_INVALID_INPUT, float4(input.origMin), float4(input.origMax)));
      return;
    }

    APP.dismissMessage();
    saveProbThreshold(params).then(result => {
      APP.resetProgress();
      let msg = result.data.message ? result.data.message : sprintf(i18n.en.APP.UI.ERROR.MODELPERFORMANCE.PROBABILITY_THRESHOLD_SAVED, params.probability, my.dataSource == "test" ? "Validation" : "Training");
      thresholdMap.set(params.modelType, params.dataSource, params.probability);
      my.unload();
      APP.router.navigate("/mp/mll/fprAndPA");
      setTimeout(function () { APP.showInfo(msg) }, 500);
    }).catch(err => {
      APP.resetProgress();
      APP.showError(err.message);
    });
  };

  /**
   * @method redrawGraphs
   * @description Ask `d3.js` or `c3.js` to redraw the graphs with the data they already have. Needed in case of
   * resizing.
   * @public
   */
  my.redrawGraphs = function () {
    CHART.allowedCharts[APP.getCurrentPageEndToken()].forEach(x => {
      if (empty(charts[x])) return;
      let chartElement = charts[x].element;
      if (charts[x].isD3Chart) {
        chartElement = charts[x].element.node();
      }
      if (!chartElement.closest(".graphs-outer-area").hasClass("active")) {
        return;
      }
      let outerContainer = chartElement.closest(".graph-outer-container");
      charts[x].resize({
        width: outerContainer.offsetWidth - 30,
        height: outerContainer.offsetHeight - outerContainer.qs("h4").offsetHeight - 22
      });
    });
  };

  /**
   * @method generateGraphs
   * @description Depending on the last token in the URL hash, which indicates which set of graphs
   * need to be drawn, discrimination, calibration or fpr, the correct set of graphs will be called to
   * be rendered using the correct data from [graphData](#~graphData). In case of the FPR and Precision
   * Analysis charts, the Probability Levels table is populated right here instead of in its own Graph
   * module.
   * @param {string} modelID "LRM", "DRF", "GBM" etc.
   * @public
   */
  my.generateGraphs = function (modelID) {
    if (!modelID || typeof modelID != "string") {
      let selectedTD = qs("#ml-table-outer-container td.selected");
      if (selectedTD) {
        modelID = selectedTD.id;
      } else {
        modelID = qs("#ml-table-outer-container tr:first-child td").id;
      }
    }
    Object.keys(graphdata).forEach(x => {
      if (x.toLowerCase() == modelID) {
        modelID = x;
      }
    });
    if (generated) {
      return;
    }
    qsa('#ml-leaderboard #ml-table-outer-container .tableContainer .download-button').forEach(downloadBtn => downloadBtn.addClass("hidden"));
    switch (APP.getCurrentPageEndToken()) {
      case "discrimination": {
        let chartCount = 3;
        APP.setProgress("Rendering plot...", false);
        requestAnimationFrame(() => {
          charts[ROC_CHART.type] = ROC_CHART.generate({
            data: {
              y1: {
                name: "ROC",
                values: graphdata[modelID][my.dataSource].Roc.yPoints,
                keys: graphdata[modelID][my.dataSource].Roc.xPoints,
              },
              auc: graphdata[modelID][my.dataSource].Roc.score
            },
            c3d3properties: {
              onrendered: function () {
                chartCount--;
                if (chartCount == 0) {
                  APP.resetProgress();
                }
              }
            }
          });
        });
        requestAnimationFrame(() => {
          charts[KS_CHART.type] = KS_CHART.generate({
            data: {
              score: graphdata[modelID][my.dataSource].KS.score,
              y1: {
                name: "y",
                values: graphdata[modelID][my.dataSource].KS.yPoints,
                keys: graphdata[modelID][my.dataSource].KS.xPoints,
              },
              y2: {
                name: "ydash",
                values: graphdata[modelID][my.dataSource].KS.yDashPoints,
                keys: graphdata[modelID][my.dataSource].KS.xDashPoints
              }
            },
            c3d3properties: {
              onrendered: function () {
                chartCount--;
                if (chartCount == 0) {
                  APP.resetProgress();
                }
              }
            }
          });
        });
        requestAnimationFrame(() => {
          charts[LIFT_CHART.type] = LIFT_CHART.generate({
            data: {
              y1: {
                name: "y",
                values: graphdata[modelID][my.dataSource].DecileLift.yPoints,
                keys: graphdata[modelID][my.dataSource].DecileLift.xPoints,
              }
            },
            c3d3properties: {
              onrendered: function () {
                chartCount--;
                if (chartCount == 0) {
                  APP.resetProgress();
                }
              }
            }
          });
        });
        if (charts[DISCRIMINATION_CHART.type]) { charts[DISCRIMINATION_CHART.type].clear(); }
        requestAnimationFrame(function () {
          charts[DISCRIMINATION_CHART.type] = DISCRIMINATION_CHART.generate({
            score: graphdata[modelID][my.dataSource].Discrimination.score ? graphdata[modelID][my.dataSource].Discrimination.score.toFixed(4) : 0,
            data: {
              y1: {
                name: "0",
                values: graphdata[modelID][my.dataSource].Discrimination.xPoints,
                keys: []
              },
              y2: {
                name: "1",
                values: graphdata[modelID][my.dataSource].Discrimination.yPoints,
                keys: []
              }
            }
          });
        });
        generated = true;
        break;
      }
      case 'calibration':
        charts[CALIBRATION_CHART.type] = CALIBRATION_CHART.generate({
          data: {
            y1: {
              name: "Reliability",
              values: graphdata[modelID][my.dataSource].CalibrationInfo.yPoints,
              keys: graphdata[modelID][my.dataSource].CalibrationInfo.xPoints,
            },
            score: graphdata[modelID][my.dataSource].CalibrationInfo.score,
            scoreName: modelID,
          }
        });
        generated = true;
        requestAnimationFrame(function () {
          my.redrawGraphs();
          d3.select(".c3-legend-item-Reliability text").text(`${graphdata[modelID].name}, score=${(d3.format(".3f"))(graphdata[modelID][my.dataSource].CalibrationInfo.score)}`);
        });
        break;

      case 'fprAndPA': {
        let probData = null, ogiveKeys = null;
        APP.setProgress("Rendering plot...", false);
        probData = graphdata[modelID][my.dataSource].probStats.map((x, i) => { x.rowIndex = i; return x; });
        probData = probData.sort(function (x1, x2) {
          return x1.probLevel < x2.probLevel ? -1 : (x1.probLevel > x2.probLevel ? 1 : 0);
        });
        const table = PROB_LEVELS.generate({ data: probData });
        resetThresholdField(probData);
        table.addEventListener("click", evt => {
          if (evt.target.closest("thead")) { return; }
          if ("td" !== evt.target.tagName.toLowerCase()) { return; }
          table.qsa("tr.selected").forEach(x => x.removeClass("selected"));
          evt.target.closest("tr").addClass("selected");
          updateConfusionMatrix({
            "modelID": modelID,
          });
        });
        table.addEventListener("keydown", evt => { //event for keyboard navigation
          const key = evt.key.toLowerCase();
          if (key != "arrowup" && key != "arrowdown") { return; }
          const selectedTR = evt.target.qs("tr.selected");
          if (!selectedTR) { return; }
          const index = parseInt(selectedTR.getAttribute("data-rowIndex")), delta = key == "arrowup" ? -1 : 1;
          if (index + delta < 0 || index + delta >= table.qsa("tbody tr").length) { return; }
          selectedTR.removeClass("selected");
          evt.target.qs(`tr[data-rowIndex="${index + delta}"`).addClass("selected").scrollIntoView({ block: "center" });
          updateConfusionMatrix({
            "modelID": modelID,
          });
        });
        table.qs("tbody tr:first-child").addClass("selected");
        updateConfusionMatrix({
          "modelID": modelID,
        });
        requestAnimationFrame(function () {
          charts[PRECISION_CHART.type] = PRECISION_CHART.generate({
            data: {
              y1: {
                name: "Reliability",
                values: graphdata[modelID][my.dataSource].PrecisionInfo.yPoints,
                keys: graphdata[modelID][my.dataSource].PrecisionInfo.xPoints,
              }
            },
            c3d3properties: {
              onrendered: APP.resetProgress
            }
          });
          ogiveKeys = graphdata[modelID][my.dataSource].probStats.map(x => x.probLevel);
        });
        requestAnimationFrame(function () {
          charts[OGIVE_CHART.type] = OGIVE_CHART.generate({
            data: {
              y1: {
                name: "Specificity",
                values: graphdata[modelID][my.dataSource].probStats.map(x => x.spec / 100),
                keys: ogiveKeys
              },
              y2: {
                name: "Sensitivity",
                values: graphdata[modelID][my.dataSource].probStats.map(x => x.sens / 100),
                keys: ogiveKeys
              }
            }
          });
        });
        generated = true;
        break;
      }
      case 'gainAnalysis': {
        let liftAnalysisData = null;
        qsa('#ml-leaderboard #ml-table-outer-container .tableContainer .download-button').forEach(downloadBtn => downloadBtn.removeClass("hidden"));
        APP.setProgress("Rendering analysis...", false);
        liftAnalysisData = extend({}, graphdata[modelID][my.dataSource].GainInfo);
        delete liftAnalysisData.status;
        const table = LIFT_ANALYSIS.generate({ data: liftAnalysisData });
        table.addEventListener("keydown", evt => {
          if (evt.target.tagName.toLowerCase() !== "th") {
            return;
          }
          if (evt.keyCode === 13) {
            evt.target.blur();
            evt.target.qsa("div").forEach(div => div.remove());
          }
        });
        table.addEventListener("focusout", evt => {
          const loopModels = Object.keys(graphdata);
          for (const i in loopModels) { // update the column name in all models and sources
            const loopSources = ["test", "train"];
            for (const j in loopSources) {
              graphdata[loopModels[i]][loopSources[j]].GainInfo[evt.target.getAttribute("data-col-key")].name = evt.target.innerText;
            }
          }
        });
        APP.resetProgress();
        break;
      }
      case 'actualVsPredicted':
        APP.setProgress("Rendering plot...", false);
        requestAnimationFrame(() => {
          charts[ACTUAL_VS_PREDICTED_CHART.type] = ACTUAL_VS_PREDICTED_CHART.generate({
            data: {
              y1: {
                name: "ActualVsPredicted",
                values: graphdata[modelID][my.dataSource].ActualPredicted.y,
                keys: graphdata[modelID][my.dataSource].ActualPredicted.x,
              },
              reference: {
                name: "Reference",
                keys: [graphdata[modelID][my.dataSource].ActualPredicted.min_RegLine[0], graphdata[modelID][my.dataSource].ActualPredicted.max_RegLine[0]],
                values: [graphdata[modelID][my.dataSource].ActualPredicted.min_RegLine[1], graphdata[modelID][my.dataSource].ActualPredicted.max_RegLine[1]]
              },
              additionalData: {
                "R2": graphdata[modelID][my.dataSource].ActualPredicted.R2,
                "RMSE": graphdata[modelID][my.dataSource].ActualPredicted.RMSE,
                "MAE": graphdata[modelID][my.dataSource].ActualPredicted.MAE
              }
            },
            c3d3properties: {
              onrendered: APP.resetProgress
            }
          });
          generated = true;
        });
        break;
      case 'pca2d':
        APP.setProgress("Rendering plot...", false);
        requestAnimationFrame(() => {
          charts[PCA_2D_CHART.type] = PCA_2D_CHART.generate({
            data: {
              y1: {
                name: "PCA_2d",
                values: graphdata[modelID][my.dataSource].PCA_2d.y,
                keys: graphdata[modelID][my.dataSource].PCA_2d.x,
                intensity: graphdata[modelID][my.dataSource].PCA_2d.intensity,
              },
            },
            c3d3properties: {
              onrendered: APP.resetProgress
            }
          });
          generated = true;
        })
        break;

      case 'pca3d':
        APP.setProgress("Rendering plot...", false);
        requestAnimationFrame(() => {
          charts[PCA_3D_CHART.type] = PCA_3D_CHART.generate({
            data: {
              name: "PCA_3d",
              x: graphdata[modelID][my.dataSource].PCA_3d.x,
              y: graphdata[modelID][my.dataSource].PCA_3d.y,
              z: graphdata[modelID][my.dataSource].PCA_3d.z,
              intensity: graphdata[modelID][my.dataSource].PCA_3d.intensity,
            },
            c3d3properties: {
              onrendered: APP.resetProgress
            }
          })
          generated = true;
        })
        break;
      case 'predictionVsDensity':
        APP.setProgress("Rendering plot...", false);
        requestAnimationFrame(() => {
          charts[PREDICTION_VS_DENSITY.type] = PREDICTION_VS_DENSITY.generate({
            data: {
              y1: {
                name: "Prediction VS Density",
                values: graphdata[modelID][my.dataSource].PredictedHistogram.height,
                keys: graphdata[modelID][my.dataSource].PredictedHistogram.x,
              }
            },
            c3d3properties: {
              onrendered: APP.resetProgress
            }
          })
          generated = true;
        })
        break;


      case 'residualsPlot':
        APP.setProgress("Rendering plot...", false);
        requestAnimationFrame(() => {
          charts[RESIDUALS_CHART.type] = RESIDUALS_CHART.generate({
            data: {
              y1: {
                name: "Residuals",
                values: graphdata[modelID][my.dataSource].ResidualHistogram.height,
                keys: graphdata[modelID][my.dataSource].ResidualHistogram.x,
              }
            },
            c3d3properties: {
              onrendered: APP.resetProgress
            }
          });
        });
        generated = true;
        break;
      case 'normality':
        APP.setProgress("Rendering plot...", false);
        requestAnimationFrame(() => {
          charts[NORMALITY_CHART.type] = NORMALITY_CHART.generate({
            data: {
              y1: {
                name: "NormalizedResiduals",
                values: graphdata[modelID][my.dataSource].QQ_Residual.y,
                keys: graphdata[modelID][my.dataSource].QQ_Residual.x,
              },
              reference: {
                name: "Reference",
                keys: [graphdata[modelID][my.dataSource].QQ_Residual.min_RegLine[0], graphdata[modelID][my.dataSource].QQ_Residual.max_RegLine[0]],
                values: [graphdata[modelID][my.dataSource].QQ_Residual.min_RegLine[1], graphdata[modelID][my.dataSource].QQ_Residual.max_RegLine[1]]
              }
            },
            c3d3properties: {
              onrendered: APP.resetProgress
            }
          });
        });
        generated = true;
        break;
      case 'predictedVsResidual':
        APP.setProgress("Rendering plot...", false);
        requestAnimationFrame(() => {
          charts[RESIDUAL_VS_PREDICTED_CHART.type] = RESIDUAL_VS_PREDICTED_CHART.generate({
            data: {
              y1: {
                name: "PredictedVsResidual",
                values: graphdata[modelID][my.dataSource].PredictedVsResidual.y,
                keys: graphdata[modelID][my.dataSource].PredictedVsResidual.x,
              },
              reference: {
                name: "Reference",
                keys: [graphdata[modelID][my.dataSource].PredictedVsResidual.min_RegLine[0], graphdata[modelID][my.dataSource].PredictedVsResidual.max_RegLine[0]],
                values: [graphdata[modelID][my.dataSource].PredictedVsResidual.min_RegLine[1], graphdata[modelID][my.dataSource].PredictedVsResidual.max_RegLine[1]]
              }
            },
            c3d3properties: {
              onrendered: APP.resetProgress
            }
          });
        });
        generated = true;
        break;

    }
  };

  /**
   * @method updateConfusionMatrix
   * @description Update the confusion matrix depening on the table row selected in the probability levels table
   * @param {object} opts contains the modelID that's the context for the graphs
   * @property {string} opts.modelID model selected in the LHS table.
   * @private
   */
  var updateConfusionMatrix = function (opts) {
    let tr = qs("#probLevels table tr.selected");
    if (!tr || typeof tr == "undefined") {
      tr = qs("#probLevels table tbody tr:first-child");
      tr.addClass("selected");
    }
    let rowIndex = parseInt(tr.getAttribute("data-rowIndex"));
    let data = {};
    if (rowIndex == -1) {
      data = JSON.parse('{"probLevel":0.01,"tpr":0,"tnr":0,"fpr":0,"fnr":0,"sens":0,"spec":0,"accu":0,"prec":0,"confMatrix":{"nf_nf":0,"nf_f":0,"f_nf":0,"f_f":0},"rowIndex":0}');
    } else {
      data = graphdata[opts.modelID][my.dataSource].probStats[rowIndex];
    }
    CONFUSION_MATRIX.generate({ "data": data });
  }

  /**
   * @method loadData
   * @description Loads the data from the plot API on the server and returns it
   * @param {object} iparams userHash(optional) and projectKey(required)
   * @property {string} iparams.projectKey id of the project for which to get scores
   * @return {object} Raw graph data obtained from the plot API.
   * @private
   * @async
   */
  var loadData = async function (iparams) {
    // Just for testing purpose, because I don't have any anomaly project in backend.
    // if (!useTestData && PROJECT.currentProjectKey() == "24QQC7OW9ICT"){
    if (useTestData) {
      let result = await MP_TEST_DATA.getData();
      return result;
    }
    return API_HELPER.getResult("plot", iparams);
  };

  /**
   * @method saveProbThreshold
   * @description save the user specified prob threshold value
   * @param iparams Example: {"modelType":"GBM","dataSource":"train","probabilty":0.65}
   * @throws throws an error if there's any server error or missing data
   * @async
   * @private
   */
  const saveProbThreshold = async function (iparams) {
    return API_HELPER.getProbThresholdResult("setprobthresh", iparams, "Saving");
  };

  const releaseThreshold = async function (iparams) {
    return API_HELPER.getProbThresholdResult("relprob", iparams, "Releasing");
  };

  return my;
}(ML_LEADERBOARD || {}));
