/* global CHART */
/**
 * @module PCA_2D_CHART
 * @description draws the PCA 2D chart shown on the `mp/mll/pca2d` page. Implements the
 * [`CHART`](module-CHART.html) interface.
 */
// eslint-disable-next-line no-extra-semi
; var PCA_2D_CHART = (function (my) {
  my.type = "pca2d";
  my.colors = {
    "0.0 - 0.2": "#FC5404",
    "0.3 - 0.5": "#F98404",
    "0.6 - 0.8": "#F9B208",
    "0.9 - 1.1": "#F7FD04",
  }
  my.optsGenerator = function (options, index, isClusterVisualization, isDialogChart) {
    let xList = options.data.y1.xList ? options.data.y1.xList : [];
    let yList = options.data.y1.yList ? options.data.y1.yList : [];
    let numberOfGraphs = xList.length * yList.length;
    let opts = extend({}, CHART.opts);

    delete opts.axis.y.min;
    delete opts.axis.y.max;
    delete opts.axis.y.tick.values;
    delete opts.axis.x.min;
    delete opts.axis.x.max;
    delete opts.axis.x.tick.values;

    delete opts.axis.x.label.text;

    if (isClusterVisualization) {
      opts.bindto = `.cluster-visualization-container .chart-container.visualization-chart-${index}`;
    } else if (isDialogChart) {
      opts.bindto = `#cluster-visualization-dialog .visualization-graph-container`;
    } else {
      opts.bindto = "#pca2d .graph";
    }
    opts.axis.y.tick.format = d3.format(".1f");
    opts.axis.x.tick.format = d3.format(".1f");
    opts.axis.y.tick.culling = true;
    opts.axis.x.tick.culling = true;
    if (numberOfGraphs >= 9 && isClusterVisualization) {
      opts.axis.x.tick.values = [];
      opts.axis.y.tick.values = []
    }
    opts.axis.y.label.text = !isClusterVisualization && !isDialogChart ? "PC2" : options.data.y1.yPoint;
    opts.axis.x.label.text = !isClusterVisualization && !isDialogChart ? "PC1" : options.data.y1.xPoint;
    opts.id = my.type;
    if (isClusterVisualization || isDialogChart) {
      let xObj = {};
      let clusterValues = [];
      let clusterKeys = Object.keys(options.data.y1.values);
      for (let i = 0; i < clusterKeys.length; i = i + 2) {
        xObj[clusterKeys[i + 1]] = clusterKeys[i];
        clusterValues.push(options.data.y1.values[clusterKeys[i]]);
        clusterValues.push(options.data.y1.values[clusterKeys[i + 1]]);
      }
      opts.data = {
        xs: xObj,
        columns: clusterValues,
        type: 'scatter',
      }
      opts.tooltip = {
        show: isDialogChart ? true : false
      }
    } else {
      opts.data = {
        xs: {
          PCA_2d: "x1",
        },
        columns: [
          ['x1'].concat(options.data.y1.keys),
          ['PCA_2d'].concat(options.data.y1.values),
        ],
        additionalData: options.data.additionalData,
        types: {
          PCA_2d: 'scatter',
        },
        colors: {
          PCA_2d: (d) => {
            let intensity = options.data.y1.intensity[d.index];
            if (intensity == undefined) {
              return;
            }
            intensity = Number(intensity.toFixed(1))
            let color = "";
            for (k in my.colors) {
              l = k.split("-")[0].trim();
              h = k.split("-")[1].trim();
              if (intensity <= h && intensity >= l) {
                color = my.colors[k];
              }
            }
            return color;
          },
        }
      };
      opts.tooltip = {
        contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
          return `<table class='c3-tooltip'>
                      <tr class='c3-tooltip-name--x}'>
                        <td class='name'><span style='background-color:${color(d[0].id)}'></span>${!isClusterVisualization && !isDialogChart ? "Actual" : options.data.y1.xPoint}</td>
                        <td class='value'>${defaultValueFormat(d[0].x, null, d[0].id)}</td>
                      </tr>
                      <tr class='c3-tooltip-name--${d[0].id}'>
                        <td class='name'><span style='background-color:transparent'></span>${!isClusterVisualization && !isDialogChart ? "Predicted" : options.data.y1.yPoint}</td>
                        <td class='value'>${defaultValueFormat(d[0].value, null, d[0].id)}</td>
                      </tr>
                    </table>`
        },
        horizontal: true
      };
    }
    opts.legend = {
      show: isDialogChart ? true : false,
    };
    opts.grid = {
      x: { show: false },
      y: { show: true },
    };
    opts.point = {
      show: true,
      r: 3
    }
    if (isClusterVisualization || isDialogChart) {
      opts.size = {
        width: isClusterVisualization ? (qs(".charts-container").offsetWidth) / Math.max(xList.length, yList.length) - 30 : 500,
        height: isClusterVisualization ? (qs(".ml-leaderboard-segmentation").offsetHeight - qs(".cluster-visualization-container .options-container").offsetHeight) / Math.min(xList.length, yList.length) : 380
      };
    } else {
      opts.size = {
        width: qs("#pca2dChart").offsetWidth - 30,
        height: qs("#pca2dChart").offsetHeight - qs("#pca2dChart h4").offsetHeight - 22
      };
    }
    if (!empty(options.c3d3properties)) {
      opts = extend(opts, options.c3d3properties);
    }
    return opts;
  };
  my.generate = function (options, index = null, chartType = null) {
    let isClusterVisualization = chartType == 'clusterVisualization' ? true : false;
    let isDialogChart = chartType == 'dialogChart' ? true : false;
    let opt = my.optsGenerator(options, index, isClusterVisualization, isDialogChart);
    CHART.charts[opt.id] = c3.generate(opt);
    if (!isClusterVisualization && !isDialogChart) {
      qsa("#pca2dChart .customLegend").forEach(x => x.remove());
      const legend = createNode(`
        <div class="customLegend">
          <table>
            <tbody></tbody>
          </table>
        </div>
      `);
      let rows = "";
      const format = d3.format("0.3f");
      for (let key in my.colors) {
        const value = my.colors[key]
        rows += `<tr><td class="key">${key}</td><td class="dot"><span style="background-color: ${value};"></span></td></tr>`;
      }
      legend.qs("tbody").innerHTML = rows;
      qs("#pca2dChart .graph-container").appendChild(legend);
    }
    return CHART.charts[opt.id];
  }

  return my;
}(PCA_2D_CHART || {}));
