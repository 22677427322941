var SEGMENT_SELECTION = (function (my) {
  /**
   * @member {Element} separator
   * @description The DOM element that acts as the draggable separator between the table and the charts area.
   * @private
   */
  var separator = null;

  /**
   * @member {boolean} dragging
   * @description While the user is dragging the [separator](#~separator) with the mouse, this variable is `true`.
   * @private
   */
  var dragging = false;

  /**
   * @member {number} originalLHSWidth
   * @description stores the width of the table area when the view first comes up. This is used to restrict
   * the dragging of the separator to this original size.
   * @private
   */
  var originalLHSWidth = null;

  /**
   * @member {string} dataSource
   * @description Whether the current view is for the "test" or "training" data.
   * @private
   */
  var datasource = "train";

  /**
   * @member {string} selectedModelID
   * @description Whether the current view is for the selected model ID from the dropdown.
   * @private
   */
  var selectedModelID = null;

  /**
   * @member {object} data
   * @description Holds the leaderboard data.
   * @private
   */
  var data = null;

  /**
   * @member {object} charts
   * @description Stores the chart objects created using `d3.js` or `c3.js`.
   * @private
   */
  let charts = {};

  /**
   * @member {object} tabledata
   * @description data to generate the selection methods table
   * @private
   */
  let tabledata = {
    elbow: {
      name: "Elbow",
      platform: "",
    },
    silhouette: {
      name: "Silhouette Score",
      platform: "",
    },
  };

  /**
   * @method redrawGraphs
   * @description Ask `d3.js` or `c3.js` to redraw the graphs with the data they already have. Needed in case of
   * resizing.
   * @public
   */
  my.redrawGraphs = function () {
    CHART.allowedCharts[APP.getCurrentPageEndToken()].forEach((x) => {
      if (empty(charts[x])) return;
      let chartElement = charts[x].element;
      if (charts[x].isD3Chart) {
        chartElement = charts[x].element.node();
      }
      if (!chartElement) {
        return;
      }
      if (!chartElement.closest(".graphs-outer-area").hasClass("active")) {
        return;
      }
      let outerContainer = chartElement.closest(".graph-outer-container");
      if (outerContainer.offsetWidth < 30) {
        return;
      }
      charts[x].resize({
        width: outerContainer.offsetWidth - 30,
      });
    });
  };

  /**
   * @method resizeListener
   * @description window resize listener for redrawing graphs on zoom
   * @params evt resize event
   * @public
   */
  my.resizeListener = function (evt) {
    my.redrawGraphs();
  };

  /**
   * @method getModelOptions
   * @description fetches the available models and populates the model dropdown.
   */
  const getModelOptions = function (model) {
    const modelList = Object.keys(data);
    selectedModelID = empty(model) ? modelList[0] : model.toUpperCase();
    const selectElement = qs("#modelSelect");
    modelList.forEach((modelID) => {
      const optionElement = document.createElement("option");
      optionElement.value = modelID;
      optionElement.textContent = data[modelID].name;
      if (modelID === selectedModelID) {
        optionElement.selected = true;
      }
      selectElement.appendChild(optionElement);
    });
  };

  /**
   * @method modelChanged
   * @description reloads the page with the selected model from the dropdown.
   */
  const modelChanged = function () {
    const selectElement = qs(".elbow-and-silhouette-container #modelSelect");
    const selectedOption = selectElement.options[selectElement.selectedIndex];
    const selectedValue = selectedOption.value;
    selectedModelID = selectedValue;
    reloadData();
  };

  /**
   * @method reloadData
   * @description reloads the table, elbow & silhouette graphs.
   */
  const reloadData = function () {
    charts = {};
    loadMethodsTable();
    generateElbowChart();
    generateSilhouetteChart();
    registerOptimalSegmentSelectionListeners();
  };

  /**
   * @method generateElbowChart
   * @description generates the elbow chart using lift-chart.js file.
   */
  const generateElbowChart = function () {
    APP.setProgress("Rendering plot...", false);
    const elbowChartData = data[selectedModelID][datasource].elbowInfo;
    let elbowYpoints = [];
    let elbowXpoints = [];
    elbowChartData.yPoints.forEach((yPoint) =>
      elbowYpoints.push(Math.round(yPoint))
    );
    elbowChartData.xPoints.forEach((xPoint) =>
      elbowXpoints.push(Math.round(xPoint))
    );
    requestAnimationFrame(() => {
      charts[LIFT_CHART.type] = LIFT_CHART.generate(
        {
          data: {
            y1: {
              name: "y",
              values: elbowYpoints,
              keys: elbowXpoints,
            },
          },
          c3d3properties: {
            onrendered: APP.resetProgress,
          },
        },
        null,
        "elbowChart"
      );
      my.resizeListener();
    });
  };

  /**
   * @method generateSilhouetteChart
   * @description generates the silhouette chart using feature-importance-chart.js file.
   */
  const generateSilhouetteChart = function () {
    const silhouetteData = data[selectedModelID][datasource].silhouetteInfo;
    APP.setProgress("Rendering plot...", false);
    requestAnimationFrame(() => {
      if (charts[FEATURE_IMPORTANCE_CHART.type]) {
        if (charts[FEATURE_IMPORTANCE_CHART.type].constructor !== Array) {
          charts[FEATURE_IMPORTANCE_CHART.type] = [
            charts[FEATURE_IMPORTANCE_CHART.type],
          ];
        }
        charts[FEATURE_IMPORTANCE_CHART.type].push(
          FEATURE_IMPORTANCE_CHART.generate(
            {
              data: {
                y1: {
                  name: "y",
                  keys: silhouetteData.xPoints,
                  values: silhouetteData.yPoints,
                },
              },
              c3d3properties: {
                onrendered: APP.resetProgress,
              },
            },
            null,
            "silhouetteGraph"
          )
        );
      } else {
        charts[FEATURE_IMPORTANCE_CHART.type] =
          FEATURE_IMPORTANCE_CHART.generate(
            {
              data: {
                y1: {
                  name: "y",
                  keys: silhouetteData.xPoints,
                  values: silhouetteData.yPoints,
                },
              },
              c3d3properties: {
                onrendered: APP.resetProgress,
              },
            },
            null,
            "silhouetteGraph"
          );
      }
    });
  };

  /**
   * @method addTableRow
   * @description Use the data in `row` to generate a table row HTML for rendering the LHS table.
   */
  const addTableRow = function (row) {
    return `
          <tr>
            <td class='type-${row.type}' id='${row.id}' data-type='${row.type}'><a href='javascript:SEGMENT_SELECTION.selectRow("${row.id}", true);'>${row.name}</a></td>
          </tr>`;
  };

  /**
   * @method selectRow
   * @description Called from the row click listener to update the RHS graph area with a new method selected.
   */
  my.selectRow = function (modelID, refresh) {
    qsa(
      ".elbow-and-silhouette-container .selectMethodTableContainer td.selected"
    ).forEach((x) => x.removeClass("selected"));
    qs(
      `.elbow-and-silhouette-container .selectMethodTableContainer td#${modelID}`
    ).addClass("selected");
    qs(
      `.elbow-and-silhouette-container .graphs-container #${modelID}-graph`
    ).addClass("active");
    if (modelID == "silhouette") {
      qs(
        ".elbow-and-silhouette-container .graphs-container .elbow-graph-container"
      ).addClass("hidden");
      qs(
        ".elbow-and-silhouette-container .graphs-container .silhouette-graph-container"
      ).removeClass("hidden");
      qs(
        `.elbow-and-silhouette-container .graphs-container #elbow-graph`
      ).removeClass("active");
    } else {
      qs(
        ".elbow-and-silhouette-container .graphs-container .elbow-graph-container"
      ).removeClass("hidden");
      qs(
        ".elbow-and-silhouette-container .graphs-container .silhouette-graph-container"
      ).addClass("hidden");
      qs(
        `.elbow-and-silhouette-container .graphs-container #silhouette-graph`
      ).removeClass("active");
    }
    my.resizeListener();
  };

  /**
   * @method registerOptimalSegmentSelectionListeners
   * @description Register listeners for:
   * * separator drag-and-drop
   * * model selection
   * * window resizing
   * @private
   */
  var registerOptimalSegmentSelectionListeners = function () {
    const optimalSegmentSelectionPage = qs(".elbow-and-silhouette-container");
    separator = optimalSegmentSelectionPage.qs(".separator");

    optimalSegmentSelectionPage
      .qs("#modelSelect")
      .addEventListener("change", modelChanged);

    //splitter drag listener
    optimalSegmentSelectionPage.addEventListener("mousedown", (evt) => {
      if (evt.target === separator && evt.buttons === 1) {
        dragging = true;
        if (originalLHSWidth == null) {
          originalLHSWidth = qs("#selection-method-container").offsetWidth;
        }
      }
    });
    optimalSegmentSelectionPage.addEventListener("mouseup", () => {
      if (!dragging) return;
      dragging = false;
      requestAnimationFrame(my.redrawGraphs);
    });
    optimalSegmentSelectionPage.addEventListener("mousemove", (evt) => {
      if (dragging) {
        let lb = qs(".elbow-and-silhouette-container");
        let oldValue = parseInt(
          window.getComputedStyle(lb).getPropertyValue("--graph-area-delta")
        );
        if (originalLHSWidth + oldValue < 410 && evt.movementX < 0) {
          return;
        } //don't reduce below 400px lhs
        optimalSegmentSelectionPage.style.setProperty(
          "--graph-area-delta",
          oldValue + evt.movementX
        );
      }
    });
    if (!my.windowListenerAdded) {
      window.addEventListener("resize", my.resizeListener);
      my.windowListenerAdded = true;
    }

    optimalSegmentSelectionPage
      .qs("#selection-method-container")
      .addEventListener("scroll", function () {
        my.scrollLocation = optimalSegmentSelectionPage.qs(
          "#selection-method-container"
        ).scrollTop;
      });
  };

  /**
   * @method loadMethodsTable
   * @description loads the methods table.
   */
  const loadMethodsTable = function () {
    if (qs("#ml-leaderboard .selectMethodTableContainer td")) {
      return;
    }
    let html = "";
    Object.keys(tabledata).forEach((x) => {
      html += addTableRow({
        type: tabledata[x].platform.toLowerCase(),
        id: x.toLowerCase(),
        name: tabledata[x].name,
      });
    });
    qs(
      ".elbow-and-silhouette-container .selectMethodTableContainer table tbody"
    ).innerHTML = html;
    const firstChild = qs(
      "#selection-method-container tbody tr:first-child td"
    );
    firstChild.addClass("selected");
    const firstChildId = firstChild.getAttribute("id");
    my.selectRow(firstChildId, true);
  };

  /**
   * @method resetVariables
   * @description resets all the variables upon landing the page to avoid caching issues.
   */
  const resetVariables = function () {
    datasource = "train";
    selectedModelID = null;
    data = null;
    dragging = false;
    charts = {};
  };

  /**
   * @method loadPage
   * @description loads the page for the first time
   */
  my.loadPage = function (leaderboardData, source, model) {
    resetVariables();
    data = leaderboardData;
    qs("#main-content .content.ml-leaderboard-segmentation").innerHTML =
      optimalsegmentselectionTemplate();
    qs("#main-content .content.ml-leaderboard-segmentation").style.overflow =
      "";
    getModelOptions(model);
    loadMethodsTable();
    generateElbowChart();
    generateSilhouetteChart();
    registerOptimalSegmentSelectionListeners();
  };

  return my;
})(SEGMENT_SELECTION || {});
