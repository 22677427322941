var COMPARISON_TABLE = (function (my) {
  var segmentationCompCols = {
    columns: [
      {
        label: "Silhouette Score",
        "data-key": "silhouette",
        "additional-tags": ["number", "percent"],
      },
      {
        label: "Accuracy",
        "data-key": "acc",
        "additional-tags": ["number", "percent"],
      },
      {
        label: "Adjusted Rand Index",
        "data-key": "adjRandIdx",
        "additional-tags": ["number"],
      },
      {
        label: "Mutual Information Score",
        "data-key": "miScore",
        "additional-tags": ["number"],
      },
    ],
  };

  var txt_mclassCompCols = {
    columns: [
      {
        label: "Accuracy",
        "data-key": "accuracy",
        "additional-tags": ["number"],
      },
      {
        label: "Precision",
        "data-key": "precision",
        "additional-tags": ["number"],
      },
      {
        label: "Recall",
        "data-key": "recall",
        "additional-tags": ["number"],
      },
      {
        label: "F1",
        "data-key": "f1",
        "additional-tags": ["number"],
      },
      {
        label: "Precision Macro Average",
        "data-key": "precision_macro_average",
        "additional-tags": ["number"],
      },
      {
        label: "F1 Macro Average",
        "data-key": "f1_macro_average",
        "additional-tags": ["number"],
      },
    ],
  };

  my.setValue = function (value, cols, attr) {
    if (!strictEmpty(value) && !isNaN(value)) {
      if (0 === value) {
        value = "-";
      } else if (cols[attr]["additional-tags"].indexOf("percent") >= 0) {
        value = d3.format(".3%")(value / 100);
      } else {
        //if config says this is rendered as a number and it is a number
        value = d3.format(".3f")(value);
      }
    } else if (!strictEmpty(value)) {
      value = "" + value;
    } else {
      value = "-";
    }
    return value;
  };

  /**
   * @method addTableRow
   * @description Clone the template row and load it with data.
   * @param {object} rowData a JS object representing the columns of the table, obtained from the comp API
   * @property {string} type Type of the AI/ML platform
   * @property {string} id LRM, DRF, GBM etc.
   * @property {string} name long full name
   * @property {string} source validation or training
   * @property {number} recommended marker for whether this is the preferred model
   * @property {string} dpath URL to download the model
   * @public
   */
  my.addTableRow = function (rowData, index, validation) {
    let templateRow = null;
    if (index !== null && !validation) {
      templateRow = qs(`.training-card-${index} #mc-table tr.template-row`);
    } else if (index !== null && validation) {
      templateRow = qs(`.validation-card-${index} #mc-table tr.template-row`);
    } else if (index === null) {
      templateRow = qs("#model-comparison #mc-table tr.template-row");
    }
    if (useTestData && PROJECT.currentProject().ptype == "segmentation") {
      mcConfig = segmentationCompCols;
    } else if (useTestData && PROJECT.currentProject().ptype == "txt-mclass") {
      mcConfig = txt_mclassCompCols;
    } else {
      mcConfig = APP.getProperty(
        "project.config.modelcomp",
        PROJECT.currentProjectKey()
      );
    }
    const cols = {};
    for (let i in mcConfig.columns) {
      cols[mcConfig.columns[i]["data-key"]] = mcConfig.columns[i];
    }
    if (templateRow) {
      let tr = templateRow.cloneNode(true);
      tr.removeClass("template-row");
      tr.addClass(rowData.source);
      if (rowData.recommended && index == null) {
        tr.addClass("trophy");
      }
      tr.setAttribute("id", rowData.id);
      tr.setAttribute("data-source", rowData.source);
      tr.qsa("td").forEach((x) => {
        if (x.hasClass("deploy-btn") || x.hasClass("download-btn")) {
          return;
        }
        if (x.hasClass("name")) {
          x.setAttribute("data-type", rowData.type);
          x.setAttribute("data-source", rowData.source);
          x.addClass(`type-${rowData.type}`);
        }
        if (x.hasClass("link")) {
          if (typeof rowData.link !== "undefined" && rowData.link) {
            x.qs("a").href = rowData.link;
          }
        } else {
          const attr = x.getAttribute("data-key");
          let value = rowData[attr];
          if (Array.isArray(value)) {
            value = value.length > 0 ? value[0] : "";
          }
          value = my.setValue(value, cols, attr);
          x.innerText = value;
        }
      });
      return tr.outerHTML;
    }
    return "";
  };

  // used by mp-model-comparison.js, version-comparison.js
  my.setUpRow = function (
    compData,
    mcConfig,
    source,
    x,
    index = null,
    validation = false
  ) {
    const rowData = {
      type: compData[x].platform.toLowerCase(),
      id: x.toLowerCase(),
      name: compData[x].name,
      source: source,
      recommended: compData[x].isPreferred,
    };
    for (let i in mcConfig.columns) {
      const col = mcConfig.columns[i];
      if (
        strictEmpty(compData[x]) ||
        strictEmpty(compData[x][source]) ||
        strictEmpty(compData[x][source][col["data-key"]])
      ) {
        rowData[col["data-key"]] = "-";
      } else {
        rowData[col["data-key"]] = compData[x][source][col["data-key"]];
      }
    }
    return my.addTableRow(rowData, index, validation);
  };

  return my;
})(COMPARISON_TABLE || {});
