/* global CHART*/
/**
 * @module BUCKET_ANALYSIS_CHART
 * @description draws the Bucket Analysis chart shown on the `mp/eai/global` page. Implements the 
 * [`CHART`](module-CHART.html) interface.
 */
// eslint-disable-next-line no-extra-semi
;var BUCKET_ANALYSIS_CHART=(function(my){
  my.type="bucket-analysis";

  my.optsGenerator=function(options){
    let opts=extend({},CHART.opts);
    delete opts.axis.x.tick.count;
    delete opts.axis.x.tick.values;
    delete opts.axis.y.tick.count;
    delete opts.axis.y.tick.values;

    let ymax=1;
    let ymin=0;
    if (options.data.actual.values.length+options.data.predicted.values.length>0){
      ymax=Math.max(...options.data.actual.values, ...options.data.predicted.values);
      ymin=Math.min(...options.data.actual.values, ...options.data.predicted.values);
    }
    
    let xmax=1;
    let xmin=0;
    let setXRange=false;
    // if (options.data.actual.keys.length+options.data.predicted.keys.length>0){
    //   if (!isNaN(options.data.actual.keys[0]) && !isNaN(options.data.predicted.keys[0])) {
    //     xmax=Math.max(...options.data.actual.keys, ...options.data.predicted.keys);
    //     xmin=Math.min(...options.data.actual.keys, ...options.data.predicted.keys);
    //     setXRange = true;        
    //   }
    // }

    ymax=(Math.ceil(ymax*100)*0.01);
    ymin=(Math.ceil(ymin*100)*0.01);
    if (setXRange) {
      xmax=(Math.ceil(xmax*100)*0.01);
      xmin=(Math.ceil(xmin*100)*0.01);
    }

    let max=setXRange?Math.max(ymax, xmax):ymax;
    let min=setXRange?Math.min(ymin, xmin):ymin;
    min-=0.05;max+=0.05;
    if(min <0)
      min=0;
    opts.axis.y.min=min;
    opts.axis.y.max=max;
    
    if (setXRange) {
      opts.axis.x.min=min;
      opts.axis.x.max=max;
    }

    opts.bindto="#bucketAnalysisChart .graph";
    opts.id=my.type;
    // opts.axis.y.tick.format=d3.format(".00f");
    opts.axis.y.label.text='Predictive Score';
    opts.axis.y.label.position='outer-middle';
    opts.axis.x={
      label:{
        text: 'Buckets',
        position: 'outer-right'
      },
      type:"category",
    };
    opts.data={
      xs:{
        actual: "x1",
        predicted: "x2",
      },
      columns: [
        ['x2'].concat(options.data.actual.keys),
        ['x1'].concat(options.data.predicted.keys),
        [options.data.actual.name].concat(options.data.actual.values),
        [options.data.predicted.name].concat(options.data.predicted.values),
      ],
      types: {
        actual: 'line',
        predicted: 'line',
      },
      colors: {
         actual: CHART.colors[2],
         predicted: CHART.colors[0],
      },
      names:{
        actual: "Actual",
        predicted: "Predicted",
      },
    };
    opts.tooltip={
      format: {
        // eslint-disable-next-line no-unused-vars
        value: function (value, ratio, id, index) { 
          if (id==="actual" || id==="predicted"){
            return (d3.format("0.4f"))(value);
          }
          return value;
        }
      }
    };
    if (options.data.isCategorical) {
      opts.data.xs.bucketSize="x3";
      opts.data.columns.push(['x3'].concat(options.data.bucketSize.keys), [options.data.bucketSize.name].concat(options.data.bucketSize.values));
      opts.data.types.bucketSize='bar';
      opts.data.colors.bucketSize=CHART.colors[2]+"50";
      opts.data.names.bucketSize="Frequency";
      opts.data.axes={
        actual: 'y',
        predicted: 'y',
        bucketSize: 'y2',
      };
      opts.axis.y2={
        show: true,
        padding: {
          top: 0,
          bottom: 0
        },
        tick: {
          outer: true
        },
        label: {
          position: "outer-middle",
          text: "Frequency"
        }
      };
    }

    opts.grid={
      x:{show: true, lines: [{value: options.data.score, text: 'Foo', class: 'bucket-score'}]},
      y:{show: true},
    };
    opts.size = {
      width: qs("#bucketAnalysisChart").offsetWidth -30,
      height: qs("#bucketAnalysisChart").offsetHeight - qs("#bucketAnalysisChart h4").offsetHeight - qs("#bucketAnalysisChart .button-bar").offsetHeight - 22
    }
    opts.point={
      show: true,
      r: 3
    }

    if (!empty(options.c3d3properties)){
      opts=extend(opts, options.c3d3properties);
    }
    
    return opts;
  }
  my.generate=function(options){
    let opt=my.optsGenerator(options);
    CHART.charts[opt.id]=c3.generate(opt);
    
    return CHART.charts[opt.id];
  }

  my.destroy=function(){
    CHART.charts[my.type] = CHART.charts[my.type].destroy();
    delete CHART.charts[my.type];
  };
  return my;
}(BUCKET_ANALYSIS_CHART || {}));