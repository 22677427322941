/* global CHART */
/**
 * @module LIFT_CHART
 * @description draws the Lift chart shown on the `mp/mll/discrimination` page. Implements the 
 * [`CHART`](module-CHART.html) interface.
 */
// eslint-disable-next-line no-extra-semi
; var LIFT_CHART = (function (my) {
  my.type = "lift";

  my.optsGenerator = function (options,index,isElbowChart) {
    let opts = extend({}, CHART.opts);

    delete opts.axis.y.tick.values;
    delete opts.axis.y.max;
    delete opts.axis.y.tick.values;
    delete opts.axis.x.max;
    delete opts.axis.x.tick.values;

    if (isElbowChart) {
      if(!index && index !=0){
        opts.bindto = ".elbow-and-silhouette-container .graphs-container .elbow-graph";
      } else {
        opts.bindto = `.elbow-chart-card-${index} #elbow .graph-container .graph`;  
      }
      opts.axis.y.label.text = "Scores";
      opts.axis.y.label.position = "outer-middle";
      opts.legend.show = false;
    } else {
      opts.bindto = "#liftGraph .graph";
      opts.axis.y.label.text = "Lift";
    }
    opts.id = my.type;
    opts.axis.y.tick.format = isElbowChart ? d3.format(".0f") : d3.format(".2");
    let tickSpaces = isElbowChart ? options.data.y1.keys[options.data.y1.keys.length - 1] : 5, tickSpacing = isElbowChart ? 1 : Math.round(Math.ceil(options.data.y1.keys[options.data.y1.keys.length - 1] / 10) * 10) / tickSpaces;
    let minValueForXAxis = isElbowChart ? options.data.y1.keys[0] : 0;

    opts.axis.x = {
      min: minValueForXAxis,
      // max: 1,
      tick: {
        values: isElbowChart ? Array.apply(null, { length: tickSpaces + 1 }).map((x, i) => {
          if (i >= minValueForXAxis) {
            return i * tickSpacing
          }
        }) : Array.apply(null, { length: tickSpaces + 1 }).map((x, i) => i * tickSpacing)
      },
      label: {
        text: isElbowChart ? "Cluster" : "Decile",
        position: isElbowChart ? 'outer-center' : 'outer-right', 
      }
    }
    opts.data = {
      xs: {
        y: "x1",
      },
      columns: [
        ['x1'].concat(options.data.y1.keys),
        [options.data.y1.name].concat(options.data.y1.values),
      ],
      type: 'line',
      colors: {
        y: CHART.colors[2],
      },
      names: {
        y: isElbowChart ? "Score" : "Lift",
      }
    };
    if (isElbowChart) {
      opts.size = {
        width: index != null ? 632 : 600,
        height: index != null ? 373 : 400
      };
    } else {
      opts.size = {
        width: qs("#liftGraph").offsetWidth - 30,
        height: qs("#liftGraph").offsetHeight - qs("#liftGraph h4").offsetHeight - 22
      };
    }
    opts.point = { show: true };

    if (!empty(options.c3d3properties)) {
      opts = extend(opts, options.c3d3properties);
    }

    return opts;
  }
  my.generate = function (options,index=null,graphType = null) {
    let isElbowChart = graphType == 'elbowChart' ? true : false;
    let opt = my.optsGenerator(options,index,isElbowChart);
    CHART.charts[opt.id] = c3.generate(opt);
    return CHART.charts[opt.id];
  }

  return my;
}(LIFT_CHART || {}));
