var SIGN_UP = (function (my) {
  let action;
  let creationBox;
  let completionBox;
  let verificationBox;
  let signInHeader;
  let loginBox;
  let captchaBox;
  let captchaCode;

  const generateCaptcha = function () {
    captchaBox.innerHTML = "";
    var charsArray =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ@!#$%^&*";
    var lengthOtp = 6;
    var captcha = [];
    for (var i = 0; i < lengthOtp; i++) {
      //below code will not allow Repetition of Characters
      var index = Math.floor(Math.random() * charsArray.length + 1); //get the next character from the array
      if (captcha.indexOf(charsArray[index]) == -1)
        captcha.push(charsArray[index]);
      else i--;
    }
    var canv = document.createElement("canvas");
    canv.id = "captcha-text";
    canv.width = 140;
    canv.height = 40;
    var ctx = canv.getContext("2d");
    ctx.font = "25px Georgia";
    ctx.strokeText(captcha.join(""), 0, 30);
    captchaCode = captcha.join("");
    captchaBox.appendChild(canv); // adds the canvas to the element
  };

  var setUpVariables = function () {
    action = qs(".sign-up-box .action");
    creationBox = qs(".form-body.create");
    completionBox = qs(".form-body.complete");
    verificationBox = qs(".form-body.verify");
    signInHeader = qs(".sign-in-header");
    loginBox = qs(".form-body.login");
    captchaBox = qs(".form-body #captcha");
  };

  var getCreationDetails = function () {
    let creationDetails = {};
    creationDetails["first-name"] = creationBox.qs("#first-name").value;
    creationDetails["last-name"] = creationBox.qs("#last-name").value;
    creationDetails["email"] = creationBox.qs("#email").value;
    creationDetails["password"] = creationBox.qs("#password").value;
    creationDetails["verify-password"] =
      creationBox.qs("#verify-password").value;
    console.log(creationDetails);
  };

  var getCompletionDetails = function () {
    let completionDetails = {};
    completionDetails["company-name"] = completionBox.qs("#company-name").value;
    completionDetails["designation"] = completionBox.qs("#designation").value;
    completionDetails["country"] = completionBox.qs("#country").value;
    console.log(completionDetails);
  };

  var getVerificationDetails = function () {
    let otp = "";
    otp += verificationBox.qs("#first").value;
    otp += verificationBox.qs("#second").value;
    otp += verificationBox.qs("#third").value;
    otp += verificationBox.qs("#fourth").value;
    console.log(otp);
  };

  var loginListener = function () {
    return new Promise((resolve, reject) => {
      let fetchLoginDetails = async (evt) => {
        if (
          evt.type.toLowerCase() == "keypress" &&
          evt.key.toLowerCase() !== "enter"
        ) {
          return;
        }
        const captchaValue = loginBox.qs("#captcha-input").value;
        if (captchaCode !== captchaValue) {
          showError(i18n.en.APP.UI.ERROR.INCORRECT_CAPTCHA);
          return;
        }
        var username = loginBox.qs("#input-username").value;
        var password = loginBox.qs("#input-password").value;
        resolve({ uname: username, password: password });
      };

      loginBox.qs("#login-button").addEventListener("click", fetchLoginDetails);
      loginBox.addEventListener("keypress", fetchLoginDetails);
    });
  };

  const showError = function (errorText) {
    let errorBar = qs(".form-body.login .errorBar");
    errorBar.style.display = "block";
    errorBar.innerText = errorText;
  };

  var navigateToLogin = async function () {
    let creds = null,
      result = null;
    action.innerText = "Login to your account";
    verificationBox.addClass("hidden");
    creationBox.addClass("hidden");
    completionBox.addClass("hidden");
    loginBox.removeClass("hidden");
    // we will need to uncomment the commented lines if we have to add sign up boxes
    // signInHeader.removeClass("hidden");
    // signInHeader.qs(".question").innerText = "Don't have an account ?";
    // signInHeader.qs(".sign-in-btn").addClass("hidden");
    // signInHeader.qs(".sign-up-btn").removeClass("hidden");
    signInHeader.addClass("hidden");
    generateCaptcha();
    qs(".form-body .captcha-container .refresh-icon").addEventListener(
      "click",
      generateCaptcha
    );
    while (result == null) {
      try {
        creds = await loginListener();
      } catch (err) {
        return;
      }
      qs(".overlay-img").removeClass("hidden");
      try {
        result = await CREDENTIALS.obtainCreds(creds.uname, creds.password);
      } catch (err) {
        qs(".overlay-img").addClass("hidden");
        qs(".sign-up-container").removeClass("hidden");
        showError(i18n.en.APP.UI.ERROR.INCORRECT_USERNAME_OR_PASSWORD);
      }
    }
    if (CREDENTIALS.isResetRequired()) {
      APP.router.navigate("/security-policy");
    } else {
      APP.router.navigate("/");
    }
  };

  var navigateToAccountCreation = function () {
    action.innerText = "Create your account";
    creationBox.removeClass("hidden");
    loginBox.addClass("hidden");
    signInHeader.qs(".question").innerText = "Already have an account ?";
    signInHeader.qs(".sign-in-btn").removeClass("hidden");
    signInHeader.qs(".sign-up-btn").addClass("hidden");
  };

  var navigateToAccountCompletion = function () {
    action.innerText = "Complete your account";
    creationBox.addClass("hidden");
    completionBox.removeClass("hidden");
    signInHeader.addClass("hidden");
  };

  var validateOTPInput = function () {
    let inputs = qsa("#otp > *[id]");
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].addEventListener("keydown", function (event) {
        if (event.key === "Backspace") {
          inputs[i].value = "";
          if (i !== 0) inputs[i - 1].focus();
        } else {
          if (i === inputs.length - 1 && inputs[i].value !== "") {
            return true;
          } else if (event.keyCode > 47 && event.keyCode < 58) {
            inputs[i].value = event.key;
            if (i !== inputs.length - 1) {
              inputs[i + 1].focus();
            }
            event.preventDefault();
          } else if (event.keyCode > 64 && event.keyCode < 91) {
            inputs[i].value = String.fromCharCode(event.keyCode);
            if (i !== inputs.length - 1) {
              inputs[i + 1].focus();
            }
            event.preventDefault();
          }
        }
      });
    }
  };

  var navigateToAccountVerification = function () {
    action.innerText = "Verify your account";
    completionBox.addClass("hidden");
    verificationBox.removeClass("hidden");
    validateOTPInput();
  };

  var registerEventListeners = function () {
    qs(".sign-up-container .sign-in-btn").addEventListener("click", (evt) => {
      navigateToLogin();
    });

    qs(".sign-up-container .sign-up-btn").addEventListener("click", (evt) => {
      navigateToAccountCreation();
    });

    qs(".sign-up-box").addEventListener("click", (evt) => {
      if (evt.target.className == "create-next") {
        getCreationDetails();
        navigateToAccountCompletion();
      }
      if (evt.target.className == "complete-next") {
        getCompletionDetails();
        navigateToAccountVerification();
      }
      if (evt.target.className == "verify-finish") {
        getVerificationDetails();
        navigateToLogin();
      }
    });
  };

  my.loadPage = function () {
    qs("#signUp-container .content.sign-up").style.display = "block";
    qs("#signUp-container .content.sign-up").innerHTML = signupTemplate();
    setUpVariables();
    registerEventListeners();
    // we will need to remove the below function call when we have to add the sign up boxes as well
    navigateToLogin();
  };

  my.unloadPage = function () {
    qs("#signUp-container .content.sign-up").style.display = "none";
  };
  return my;
})(SIGN_UP || {});
