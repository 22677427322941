/**
 * @module COMPACT_LABEL
 * @description Functionality for a compact label that becomes small on focus or content
 */

;var COMPACT_LABEL=(function(my){
  /**
   * @method registerCompactLabel 
   * @description should be called on new instances of compactLabels. 
   * This registers anonymous listeners on HTMLInputElement inside each compactLabel 
   * so that a 'not-empty' class is added to the input if it is has content. This is then used 
   * to show the small label if there is content in the input box. Without this the label will
   * show on top of the input box when it loses focus.
   * @param {string[]} labels if no compactLabels are passed, method finds and executes on all label.compact that it can find on the page.
   * @public
   */
  my.registerCompactLabel=function(labels){
    if (!labels || "undefined" === typeof labels) {
      labels=qsa("label.compact");
    }
    if ("undefined" !== typeof labels.tagName){labels=[labels];}
    labels.forEach(label=>{
      
      var box = label.qs("input,textarea,select");
      if (box && "undefined" !== typeof box){
        box.addEventListener("change", boxEventListener);
        box.addEventListener("keyup", boxEventListener);
        boxEventListener({currentTarget: box});
        compactObserver.observe(box, {childList: true});
      }
    });
  };

  /**
   * @member {MutationObserver} compactObserver
   * @description Listen for `compactLabel`s inside dialogs and boxes.
   * @private
   */
  var compactObserver=new MutationObserver(mutationsList=>{
    for (var mutation of mutationsList){
      if (mutation.type=='childList'){
        boxEventListener({currentTarget: mutation.target});
      }
    }
  });

  /**
   * @method boxEventListener
   * @description Listen for changes to contents / focus of textbox and update label location. Perform 
   * pattern validation check
   * @param {Event} evt 
   * @private
   */
  var boxEventListener=function(evt){
    if((evt.currentTarget.className == "input-restricted" || evt.currentTarget.className == "textarea-restricted") && evt.currentTarget.value && evt.currentTarget.value.length > 0){
      var inputVal = evt.target.value;
      let re = /[`~!@#$%^&*()|+\=?;:'",.<>\{\}\[\]\\\/]/gi;
      var isSplChar = re.test(inputVal);
      if(isSplChar)
      {
        var no_spl_char = inputVal.replace(/[`~!@#$%^&*()|+\=?;:'",.<>\{\}\[\]\\\/]/gi, '');
        evt.target.value = no_spl_char;
      }
    }
    if (evt.currentTarget.value && evt.currentTarget.value.length>0){
      evt.currentTarget.setAttribute("not-empty", "true");
      evt.currentTarget.closest("label").setAttribute("not-empty", "true");
    } else {
      evt.currentTarget.removeAttribute("not-empty");
      evt.currentTarget.closest("label").removeAttribute("not-empty");
    }
    let box=evt.currentTarget;
    if (box.getAttribute("pattern")){
      box.removeClass("invalid");
      let regex= new RegExp(box.getAttribute("pattern"), "g");
      let val=box.value.trim();
      let match = regex.exec(val);
      if (!match || match==null || typeof match.index == "undefined" || match.index !== 0 ){
        box.addClass("invalid");
      }
    }
  };

  /**
   * @method deregister
   * @description deregister for keyboard and change events. Useful when temporarily closing dialog boxes or tabs
   * @param {HTMLInputElement} compactLabel
   * @public
   */
  my.deregister=function(compactLabel){
    compactLabel.removeEventListener("change", boxEventListener);
    compactLabel.removeEventListener("keyup", boxEventListener);
  }
  return my;
}(COMPACT_LABEL || {}));
