/* global CHART */
/**
 * @module NORMALITY_CHART
 * @description draws the Actual vs. Predicted chart shown on the `mp/mll/normality` page. Implements the 
 * [`CHART`](module-CHART.html) interface.
 */
// eslint-disable-next-line no-extra-semi
;var NORMALITY_CHART=(function(my){
  my.type="normality";

  my.optsGenerator=function(options){
    let opts=extend({},CHART.opts);

    delete opts.axis.y.min;
    delete opts.axis.y.max;
    delete opts.axis.y.tick.values;
    delete opts.axis.x.min;
    delete opts.axis.x.max;
    delete opts.axis.x.tick.values;
    
    delete opts.axis.x.label.text;
    
    opts.bindto="#normalityChart .graph";
    opts.axis.y.tick.format=d3.format(".1f");
    opts.axis.x.tick.format=d3.format(".1f");
    opts.axis.y.tick.culling=true;
    opts.axis.x.tick.culling=true;
    opts.axis.y.label.text="Normalized Residuals";
    opts.axis.x.label.text="Theoretical Quantiles";
    opts.id=my.type;
    opts.data={
      xs:{
        NormalizedResiduals: "x1",
        Reference: "referencex"
      },
      columns: [
        ['x1'].concat(options.data.y1.keys),
        ['referencex'].concat(options.data.reference.keys),
        ['NormalizedResiduals'].concat(options.data.y1.values),
        ['Reference'].concat(options.data.reference.values)
      ],
      types: {
        NormalizedResiduals: 'scatter',
        referencex: 'line'        
      },
      colors: {
         NormalizedResiduals: CHART.colors[0],
         Reference: CHART.colors[2]
      }
    };
    opts.tooltip={
      contents: function(d, defaultTitleFormat, defaultValueFormat, color) {
        if (d[0].id === "NormalizedResiduals") {
          return `<table class='c3-tooltip'>
                    <tr class='c3-tooltip-name--x}'>
                      <td class='name'><span style='background-color:${color(d[0].id)}'></span>Theoretical Quantiles</td>
                      <td class='value'>${defaultValueFormat(d[0].x, null, d[0].id)}</td>
                    </tr>
                    <tr class='c3-tooltip-name--${d[0].id}'>
                      <td class='name'><span style='background-color:transparent'></span>Normalised Residuals</td>
                      <td class='value'>${defaultValueFormat(d[0].value, null, d[0].id)}</td>
                    </tr>
                  </table>`
        }
      },
      horizontal: true
    };
    opts.legend={
      show: false,
      // hide: ['Reference']
    };
    opts.grid={
      x:{show: false},
      y:{show: true},
    };
    opts.point={
      show: true,
      r: 3
    }
    opts.size={
      width: qs("#normalityChart").offsetWidth -30,
      height: qs("#normalityChart").offsetHeight - qs("#normalityChart h4").offsetHeight - 22
    };
    
    if (!empty(options.c3d3properties)){
      opts=extend(opts, options.c3d3properties);
    }
    
    return opts;
  };
  my.generate=function(options){
    let opt=my.optsGenerator(options);
    CHART.charts[opt.id]=c3.generate(opt);
    return CHART.charts[opt.id];
  }

  return my;
}(NORMALITY_CHART || {}));