/* global EAI_GLOBAL_TEST_DATA, mpeaiglobalTemplate, BUCKET_ANALYSIS_CHART, FEATURE_IMPORTANCE_CHART, CHART */
/**
 * @module MP_EAI_GLOBAL
 * @description Renders the Explainable AI page that allows the user to compare the
 * different models for their training and test data and their performance using various [`CHART`](module-CHART.html)s.
 */
var MP_EAI_GLOBAL = (function (my) {
  /**
   * @member {string} STORE_KEY
   * @description the key for caching data in the STORE
   * @public
   */
  my.STORE_KEY = "MP_EAI_GLOBAL";

  /**
   * @member {object} charts
   * @description Stores the chart objects created using `d3.js` or `c3.js`.
   * @private
   */
  const charts = {};

  /**
   * @member {Element} separator
   * @description The DOM element that acts as the draggable separator between the table and the charts area.
   * @private
   */
  // eslint-disable-next-line no-unused-vars
  var separator = null;

  /**
   * @member {boolean} dragging
   * @description While the user is dragging the [separator](#~separator) with the mouse, this variable is `true`.
   * @private
   */
  var dragging = false;

  /**
   * @member {number} originalLHSWidth
   * @description stores the width of the table area when the view first comes up. This is used to restrict
   * the dragging of the separator to this original size.
   * @private
   */
  var originalLHSWidth = null;

  /**
   * @member {boolean} generated
   * @description Stores whether the FeatureImprtance graph has been generated for the currently selected model.
   * This is so that the graph need not be generated again if a page change (between graphs) has happened, but no change
   * in model selection or source selection has happened.
   * @private
   */
  var generated = false;

  /**
   * @member {string} dataSource
   * @description Whether the current view is for the "test" or "training" data. Updated from the event listener
   * for the Source select box
   * @public
   */
  var dataSource = "test";

  /**
   * @member {string} calcMethod
   * @description Whether the current view is for the "DFI" or "PFI" method. Updated from the event listener for
   * Method select box. Available methods are loaded from the project config
   * @public
   */
  var calcMethod = "DFI";

  /**
   * @member {string} currentFeature
   * @description This carries the state of the current selected feature for reference during drilldown. Updated
   * whenever the user selects a bar in the Feature Importance chart
   * @public
   */
  var globalFeatureID = "";

  /**
   * @member {object} graphdata
   * @description Cache the data for the feature importance graph
   * @private
   */
  var graphdata = null;

  /**
   * @member {object} featureGraphData
   * @description Cache the data for the Bucket Analysis graph for the currently selected feature
   * @private
   */
  var featureGraphData = null;

  /**
   * @method prime
   * @description Primes this view, adds the view's HTML from its JS Pug template into the DOM.
   * Registers listeners. Sets initial values for UI input elements and internal storage variables
   * to their default values.
   * @private
   */
  const prime = function () {
    qs(".wait-for-ready-global").removeClass("active");
    renderInitialView();
    registerListeners();

    //source setup
    selectSource("train", false);

    // method setup
    selectMethod("DFI", false);

    globalFeatureID = "";
    sortGraphData();
  }

  /**
   * @method resizeListener
   * @description window resize listener for redrawing graphs on zoom
   * @params evt resize event
   * @private
   */
  // eslint-disable-next-line no-unused-vars
  const resizeListener = function (evt) {
    redrawGraphs();
  };

  /**
   * @method registerListeners
   * @description Register listeners for:
   * * separator drag-and-drop
   * * source selection
   * * method selection
   * * window resizing
   * @private
   */
  const registerListeners = function () {
    const eaiView = qs("#explainable-ai-global"),
      separator = eaiView.qs(".separator");

    //splitter drag listener
    eaiView.addEventListener("mousedown", evt => {
      if (evt.target === separator && evt.buttons === 1) {
        dragging = true;
        if (originalLHSWidth == null) { originalLHSWidth = qs("#mp-eai-table-outer-container").offsetWidth; }
      }
    });
    eaiView.addEventListener("mouseup", () => {
      if (!dragging) return;
      dragging = false;
      requestAnimationFrame(redrawGraphs);
    });
    eaiView.addEventListener("mousemove", evt => {
      if (dragging) {
        let lb = qs("#mp-eai-global");
        let graphAreaDelta = window.getComputedStyle(lb).getPropertyValue("--graph-area-delta");
        if (empty(graphAreaDelta)) {
          graphAreaDelta = "0";
        }
        let oldValue = parseInt(graphAreaDelta);
        if (originalLHSWidth + oldValue < 410 && evt.movementX < 0) { return; }//don't reduce below 400px lhs
        lb.style.setProperty("--graph-area-delta", oldValue + evt.movementX);
      }
    });

    if (!my.windowListenerAdded) {
      window.addEventListener("resize", resizeListener);
      my.windowListenerAdded = true;
    }


    eaiView.qs("#eai-source-select").addEventListener("change", evt => selectSource(evt.target.value, true));

    eaiView.qs("#eai-method-select").addEventListener("change", evt => selectMethod(evt.target.value, true));

    // Event listener for changing the numer of feature count 
    qs("#eai-feature-count-select").addEventListener("change", evt => {
      qs("#eai-feature-count-select").value = evt.target.value;
      regenerateBarGraph();
    });

    eaiView.qsa("#graph-area .back-box").forEach(x => x.addEventListener("click", () => {
      qs(".drop-down-bar").style.display = 'flex'; // Making drop-down visible after clicking back button
      qs("#graph-area .graphs-outer-area .graph-outer-container").style.marginTop = "5rem"; // Adding extra space above graph container for feature count drop-down
      const chart = switchToChart("featureImportanceChart");
      // eslint-disable-next-line no-unused-vars
      const transitionListener = function (ev) {
        requestAnimationFrame(redrawGraphs);
        chart.removeEventListener("transitionend", transitionListener);
      };
      chart.addEventListener("transitionend", transitionListener);
    }));
    eaiView.qsa(".eai-global-table-data span").forEach((x) => {
      x.addEventListener("click", () => {
        let id = x.getAttribute("model-id").toUpperCase()
        let downloadURL = SERVER.getBaseAddress() + `downloadfi?projVersion=${PROJECT.currentProjVersion()}&projectKey=${PROJECT.currentProjectKey()}&modelName=${id}`;
        window.open(downloadURL);
      })
    })

    eaiView.qsa(".download-graph").forEach(downloadGraphBtn => {
      downloadGraphBtn.addEventListener("click", evt => {
        const type = evt.target.getAttribute("type");
        const graphBox = eaiView.qs(`.graphs-area #${type}`);
        var graph = graphBox.qs(".graph-container");
        var graphTitle = graphBox.qs(".title").innerText.toLowerCase();
        var fileName = graphTitle.replace(/ /g,"_");
        html2canvas(graph,{
          backgroundColor: "#000000"
        }).then(function (canvas) {
          const imageURL = canvas.toDataURL();
          const downloadLink = document.createElement("a");
          downloadLink.href = imageURL;
          downloadLink.download = `${fileName}.png`;
          downloadLink.click();
        });
      })
    })
  }

  /**
   * @method load
   * @description load the page's template and add the scope markers for the CSS. load the data for this page,
   * either from the plot/ API or from the STORE.
   * @param {string} pkey project key which is the context for this view.
   * @async
   * @private
   */
  const load = async function (pkey) {
    let projectKey = pkey ? pkey : PROJECT.currentProjectKey();
    let projVersion = PROJECT.currentProjVersion();
    graphdata = STORE.getProjectData(projectKey, PROJECT.currentProjVersion(), MP_EAI_GLOBAL.STORE_KEY);
    if (!graphdata) {
      let result = await GLOBAL_AND_LOCAL_INTERP.pollForData("globinterp", null, 3, projectKey, projVersion);
      graphdata = result.data.posts[0];
      STORE.setProjectData(projectKey, PROJECT.currentProjVersion(), MP_EAI_GLOBAL.STORE_KEY, graphdata);
      STORE.setProjectMetadata(projectKey, PROJECT.currentProjVersion(), MP_EAI_GLOBAL.STORE_KEY + "_graphdata_loaded", true);
    }
    prime();
  }

  /**
   * @method unload
   * @description deregisters any active listeners
   * @public
   */
  my.unload = function () {
    if (my.windowListenerAdded) {
      window.removeEventListener("resize", resizeListener);
      delete my.windowListenerAdded;
    }
    GLOBAL_AND_LOCAL_INTERP.cancelPolling();
  };

  /**
   * @method show
   * @description Load and show the correct graph page:
   * * Load the data
   * * Activate the correct graph page
   * * Mark the graph dirty
   * * Generate the graphs in a non-blocking manner
   * @param {string} pkey The project ID of the project in context
   * @async
   * @public
   */
  my.show = async function (pkey) {
    APP.resetCurrentPageMarker();
    qs("#main-content").setAttribute("class", "");
    qs("#main-content").addClass("explainable-ai");
    let projectKey = pkey ? pkey : PROJECT.currentProjectKey();
    graphdata = STORE.getProjectData(projectKey, PROJECT.currentProjVersion(), MP_EAI_GLOBAL.STORE_KEY);
    const config = APP.getProperty("project.config.explainable-ai.globinterp", projectKey);
    qs("#explainable-ai-global").innerHTML = mpeaiglobalTemplate(config);
    await load(pkey);
  }

  /**
   * @method renderInitialView
   * @description called on view load to render the overall layout of the page and its internal structure.
   * Needs [graphData](#~graphData) to be loaded before being called.
   */
  const renderInitialView = function () {
    loadModelsTable();
    qsa("#explainable-ai-global #graph-area .active").forEach(x => x.removeClass("active"));
    qs(`#explainable-ai-global #graph-area #${APP.getCurrentPageEndToken()}-interpretability`).addClass("active");
    generated = false;
    requestAnimationFrame(generateGraphs);
  }

  /**
   * @method loadModelsTable
   * @description populate the LHS table with names of the models
   * @private
   */
  const loadModelsTable = function () {
    if (qs("#explainable-ai-global .tableContainer td")) { return; }
    let html = '';
    Object.keys(graphdata.modelFiInfo).forEach(x => {
      html += addTableRow({
        type: graphdata.modelFiInfo[x].platform.toLowerCase(),
        id: x.toLowerCase(),
        name: graphdata.modelFiInfo[x].name
      });
    });
    qs("#explainable-ai-global .tableContainer table tbody").innerHTML = html;
    qs("#mp-eai-table-outer-container tbody tr:first-child td").addClass("selected");
  }

  /**
   * @method addTableRow
   * @description Use the data in `row` to generate a table row HTML for rendering the LHS table.
   * @param {object} row
   * @property {string} row.type "SPARK", "H2O" etc
   * @property {string} row.id "LRM", "DRF", "GBM" etc.
   * @property {string} rown.name Full name of the model "Generalized Linear Regression", "Distributed Random Forest" etc.
   * @private
   */
  const addTableRow = function (row) {
    return `
      <tr>
        <td class='type-${row.type} eai-global-table-data' id='${row.id}' data-type='${row.type}'><a href='javascript:MP_EAI_GLOBAL.selectRow("${row.id}");'>
          ${row.name}</a>
          <span model-id=${row.id}></span>
        </td>
      </tr>`;
  }

  /**
   * @method selectRow
   * @description Called from the row click listener to update the RHS graph area with a new model selection.
   * @param {string} modelID "LRM", "DRF", "GBM" etc.
   * @public
   */
  my.selectRow = function (modelID) {
    switchToChart("featureImportanceChart");
    globalFeatureID = "";
    qsa('#explainable-ai-global .tableContainer td.selected').forEach(x => x.removeClass("selected"));
    qs(`#explainable-ai-global .tableContainer td#${modelID}`).addClass("selected");
    generated = false;
    requestAnimationFrame(generateGraphs);
  };

  /**
   * @method selectMethod
   * Change to a particular method name. Updates the state of the UI and changes the
   * selection of the method to Training. Causes the source select to be hidden.
   * @param {String} methodName DFI or PFI
   * @param {Boolean} regenerateGraphs whether or not to call [generateGraphs](method-generateGraphs)
   */
  const selectMethod = function (methodName, regenerateGraphs) {
    if (regenerateGraphs == undefined) {
      regenerateGraphs = true;
    }
    calcMethod = qs("#eai-method-select").value = methodName;
    qs("#explainable-ai-global #eai-method").setAttribute("data-value", calcMethod);
    if (calcMethod.toLowerCase() === "dfi") {
      selectSource("train", false);
    }
    globalFeatureID = "";
    generated = false;
    switchToChart("featureImportanceChart");
    if (regenerateGraphs) {
      requestAnimationFrame(generateGraphs);
    }
  };

  /**
   * @method selectSource
   * Change to a particular source. Updates the state of the UI and changes
   * @param {String} sourceName "test" or "train"
   * @param {Boolean} regenerate whether or not to call [generateGraphs](method-generateGraphs)
   */
  const selectSource = function (sourceName, regenerateGraphs) {
    if (regenerateGraphs == undefined) {
      regenerateGraphs = true;
    }
    dataSource = qs("#eai-source-select").value = sourceName;
    globalFeatureID = "";
    generated = false;
    switchToChart("featureImportanceChart");
    if (regenerateGraphs) {
      requestAnimationFrame(generateGraphs);
    }
  };

  /**
   * @method drilldown
   * @description called from the feature importance graph when a bar is clicked. triggers a drilldown
   * into sub-graph. Registered as listener in [FEATURE_IMPORTANCE_CHART](module-FEATURE_IMPORTANCE_CHART.html).
   * @param {object} d data to identify the bar that was clicked
   * @param {Element} element SVG DOM element for the bar
   * @public
   */
  // eslint-disable-next-line no-unused-vars
  my.drilldown = async function (d, element) {

    let featureID = null,
      modelID = getCurrentModelID();
    try {
      featureID = graphdata.modelFiInfo[modelID].fiInfo[calcMethod][dataSource].features[d.index];
    } catch (err) {
      APP.showWarning(i18n.en.APP.UI.ERROR.MODELPERFORMANCE.NO_SUCH_FEATURE);
    }
    let result = false;
    if (featureID === globalFeatureID) {
      result = featureGraphData;
    } else {
      result = await loadBucketData({ colName: featureID });
    }
    if (result) {
      featureGraphData = result;
      const regenerateBucketAnalysisGraphFlag = (globalFeatureID !== featureID);
      globalFeatureID = featureID;
      if (!empty(globalFeatureID) && regenerateBucketAnalysisGraphFlag && !empty(charts[BUCKET_ANALYSIS_CHART.type])) {
        charts[BUCKET_ANALYSIS_CHART.type] = BUCKET_ANALYSIS_CHART.destroy();
        delete charts[BUCKET_ANALYSIS_CHART.type];
      }
      if (featureGraphData.chartType == "bar") {
        requestAnimationFrame(generateGraphs);
      }
      else {
        const chartEl = switchToChart("bucketAnalysisChart");
        // eslint-disable-next-line no-unused-vars
        const transitionListener = function (ev) {
          if (regenerateBucketAnalysisGraphFlag) {
            requestAnimationFrame(generateGraphs);
          }
        };
        transitionListener();
        // requestAnimationFrame(generateGraphs);
      }
    }
  };

  /**
   * @method switchToChart
   * @description switch between featureImportanceChart and bucketAnalysisChart
   * @param chartName one of featureImprtanceChart and bucketAnalysisChart. This chart will be shown,
   * the other hidden
   * @returns the chart that got shown
   */
  const switchToChart = function (chartName) {
    const fic = qs("#featureImportanceChart"),
      bac = qs("#bucketAnalysisChart"),
      ficd = qs("#featureImportanceDrillChart");
    if (chartName === 'bucketAnalysisChart') {
      fic.addClass("hidden");
      ficd.addClass("hidden");
      bac.removeClass("hidden");
      return bac;
    } else if (chartName == 'featureImportanceChart') {
      bac.addClass("hidden");
      ficd.addClass("hidden");
      fic.removeClass("hidden");
      return fic;
    }
    else if (chartName == 'featureImportanceDrillChart') {
      bac.addClass("hidden");
      fic.addClass("hidden");
      ficd.removeClass("hidden");
    }
    return null;
  };

  /**
   * @method getCurrentModelID
   * @description retrieves the modelID of the currently selected model in the LHS table.
   * @returns {string} the ID of the model that the selected row represents
   */
  const getCurrentModelID = function () {
    let modelID = "",
      selectedTD = qs("#mp-eai-table-outer-container td.selected");
    if (selectedTD) {
      modelID = selectedTD.id;
    } else {
      modelID = qs("#mp-eai-table-outer-container tr:first-child td").id;
    }
    Object.keys(graphdata.modelFiInfo).forEach(x => {
      if (x.toLowerCase() == modelID) {
        modelID = x;
      }
    });
    return modelID;
  };

  /**
   * @method redrawGraphs
   * @description Ask `d3.js` or `c3.js` to redraw the graphs with the data they already have. Needed in case of
   * resizing.
   * @public
   */
  const redrawGraphs = function () {
    CHART.allowedCharts[APP.getCurrentPageEndToken()].forEach(x => {
      if (empty(charts[x])) {
        return;
      }
      let chartElement = charts[x].element;
      if (charts[x].isD3Chart) {
        chartElement = charts[x].element.node();
      }
      if (!chartElement.closest(".graphs-outer-area").hasClass("active")) {
        return;
      }
      let outerContainer = chartElement.closest(".graph-outer-container");
      const newSize = {
        width: outerContainer.offsetWidth - 30,
        height: outerContainer.offsetHeight - outerContainer.qs("h4").offsetHeight - 22
      }
      if (outerContainer.qsa(".button-bar").length > 0) {
        newSize.height -= outerContainer.qs(".button-bar").offsetHeight;
      }
      charts[x].resize(newSize);
    });
  };

  // Method for regneration of feature graph called after change in number of feature count
  var regenerateBarGraph = () => {
    let modelID = getCurrentModelID();
    var featuresArray = Object.values(graphdata.modelFiInfo[modelID].fiInfo[calcMethod][dataSource].features); // Array of all the features of current ModelID
    var scoresArray = Object.values(graphdata.modelFiInfo[modelID].fiInfo[calcMethod][dataSource].scores);  // Array of values of all the features of current ModelID
    var n = qs("#eai-feature-count-select").value; // Selected number of feature count to display
    requestAnimationFrame(() => {
      charts[FEATURE_IMPORTANCE_CHART.type] = FEATURE_IMPORTANCE_CHART.generate({
        method: calcMethod,
        data: {
          y1: {
            name: "y",
            keys: featuresArray.slice(0, n), // Slicing array for showing selected number of feature count
            values: scoresArray,
          },
        },
        c3d3properties: {
          onrendered: APP.resetProgress
        }
      });
    });
  }

  /**
   * @method generateGraphs
   * @description Depending on the last token in the URL hash, which indicates which set of graphs
   * need to be drawn, global-interpretability, local-interpretability, the correct set of graphs will be called to
   * be rendered using the correct data from [graphData](#~graphData). In case of the FPR and Precision
   * Analysis charts, the Probability Levels table is populated right here instead of in its own Graph
   * module.
   * @public
   */
  const generateGraphs = function () {
    APP.setProgress("Rendering plot...", false);
    let modelID = getCurrentModelID();
    let featureID = globalFeatureID;
    if (empty(featureID) && generated) {
      return;
    }
    if (empty(featureID)) {
      var barCountSelect = qs("#eai-feature-count-select");
      qs(".drop-down-bar").style.display = "flex";  // Making feature count dropdown visible 
      qs("#graph-area .graphs-outer-area .graph-outer-container").style.marginTop = "5rem"; // Adding space for drop-down
      barCountSelect.innerHTML = '' // Cleared previously loaded feature count if present
      var defaultSelectedOption = false; // flag for make only one option selected by default
      var numberOfFeatures = graphdata.modelFiInfo[modelID].fiInfo[calcMethod][dataSource].features.length - 1 // Number of feature

      // Loop to add number of feature counts in drop-down
      for (i in graphdata.modelFiInfo[modelID].fiInfo[calcMethod][dataSource].features) {
        var value = parseInt(i) + 1;
        // condition to make one option selected by default
        if ((i == 9 || i == numberOfFeatures) && !defaultSelectedOption) {
          defaultSelectedOption = true;
          barCountSelect.innerHTML += `<option value=${value} selected>${value}</option>`;
        } else {
          barCountSelect.innerHTML += `<option value=${value}>${value}</option>`;
        }
      }

      var featuresArray = Object.values(graphdata.modelFiInfo[modelID].fiInfo[calcMethod][dataSource].features).slice(0, 10); // Slicing feature array to show only atmost 10 features after loading
      var scoresArray = Object.values(graphdata.modelFiInfo[modelID].fiInfo[calcMethod][dataSource].scores).slice(0, 10);

      barCountSelect.setAttribute("onfocus", `this.size=${featuresArray.length >= 10 ? 10 : featuresArray.length};`) // Set the size of drop-down list

      requestAnimationFrame(() => {
        charts[FEATURE_IMPORTANCE_CHART.type] = FEATURE_IMPORTANCE_CHART.generate({
          method: calcMethod,
          data: {
            y1: {
              name: "y",
              keys: featuresArray,
              values: scoresArray,
            },
          },
          c3d3properties: {
            onrendered: APP.resetProgress
          }
        });
      });
      qs("#featureImportanceChart").removeClass("DFI,PFI").addClass(calcMethod);
      generated = true;
      switchToChart("featureImportanceChart");
    }
    else if (featureGraphData.chartType == "bar") {
      qs(".drop-down-bar").style.display = 'none'; // Making drop-down visibilty none after seleting any feature
      qs("#graph-area .graphs-outer-area .graph-outer-container").style.marginTop = "0"; // Removed extra space of drop-down
      requestAnimationFrame(() => {
        charts[FEATURE_IMPORTANCE_DRILL_CHART.type] = FEATURE_IMPORTANCE_DRILL_CHART.generate({
          method: calcMethod,
          data: {
            y1: {
              name: "y",
              keys: featureGraphData.features,
              values: featureGraphData.scores,
            },
          },
          c3d3properties: {
            onrendered: APP.resetProgress
          }
        });
      });
      qs("#featureImportanceDrillChart").removeClass("DFI,PFI").addClass(calcMethod);
      generated = true;
      switchToChart("featureImportanceDrillChart");
    }
    else {
      // eslint-disable-next-line no-unused-vars
      qs(".drop-down-bar").style.display = 'none'; // Making drop-down visibilty none after seleting any feature
      qs("#graph-area .graphs-outer-area .graph-outer-container").style.marginTop = "0";  // Removed extra space of drop-down
      const massagedData = generateBucketAnalysisGraph();
      generated = false;
    }
    // requestAnimationFrame(function(){
    //   my.redrawGraphs();
    // });
  }

  /**
   * @method generateBucketAnalysisGraph
   * @description called if [globalFeatureID](#~globalFeatureID) is set to generate the bucket analysis chart
   * @private
   */
  const generateBucketAnalysisGraph = function () {
    APP.setProgress("Rendering plot...", false);
    const massagedData = { empty: true };
    if (!empty(featureGraphData) && !empty(featureGraphData.impact) && featureGraphData.impact.length != 0) {
      massagedData.empty = false;
      massagedData.isCategorical = featureGraphData.impact[0].isCategorical;
      massagedData.actual = { name: "actual", keys: [], values: [] };
      massagedData.predicted = { name: "predicted", keys: [], values: [] };
      massagedData.bucketSize = { name: "bucketSize", keys: [], values: [] };
      featureGraphData.impact.forEach(impact => {
        let key = null;
        if (massagedData.isCategorical) {
          key = impact.rangeStart;
        } else {
          let formatterStart = impact.rangeStart % 1 === 0 ? d3.format("") : (d3.format(".4g")),
            formatterEnd = impact.rangeEnd % 1 === 0 ? d3.format("") : (d3.format(".4g"));
          key = `${formatterStart(impact.rangeStart)} - ${formatterEnd(impact.rangeEnd)}`;
        }
        massagedData.actual.keys.push(key);
        massagedData.predicted.keys.push(key);
        massagedData.bucketSize.keys.push(key);
        massagedData.actual.values.push(impact.actualScore);
        massagedData.predicted.values.push(impact.predictedScore);
        massagedData.bucketSize.values.push(impact.bucketSize);
      });
    }
    requestAnimationFrame(() => {
      charts[BUCKET_ANALYSIS_CHART.type] = BUCKET_ANALYSIS_CHART.generate({
        data: massagedData,
        c3d3properties: {
          onrendered: APP.resetProgress
        }
      });
    });
    let title = qs("#bucketAnalysisChart .title");
    title.innerText = sprintf(i18n.en.APP.UI.CONTENT.MODELPERFORMANCE.BUCKET_ANALYSIS_CHART.TITLE, globalFeatureID);

    return massagedData;
  };

  /**
   * @method sortGraphData
   * @description sorts Feature Importance chart data so that the features are displayed in order
   * of their score
   */
  const sortGraphData = function () {
    if (empty(graphdata)) {
      throw new Error("No graphdata to sort.");
    }
    const models = Object.keys(graphdata.modelFiInfo),
      sources = Array.prototype.map.call(qs("#eai-source-select").qsa("option"), x => x.value),
      methods = Array.prototype.map.call(qs("#eai-method-select").qsa("option"), x => x.value);

    models.forEach(model => {
      sources.forEach(source => {
        methods.forEach(method => {
          const d = graphdata.modelFiInfo[model].fiInfo[method][source];
          const pairs = [];
          for (let i = 0; i < d.features.length; i++) {
            pairs.push({ feature: d.features[i], score: d.scores[i] });
          }
          pairs.sort(function (a, b) {
            return b.score - a.score;
          });
          for (let i = 0; i < pairs.length; i++) {
            d.features[i] = pairs[i].feature;
            d.scores[i] = pairs[i].score;
          }
        });
      });
    });

  };

  /**
   * @method loadBucketData
   * @description Loads the bucket plot data from the feature impact API on the server and returns it
   * @param {object} iparams with the following properties
   * @property {number} iparams.userHash (optional) the current logged in userID
   * @property {string} iparams.projectKey (optional) id of the project for which to get scores
   * @property {string} iparams.modelName (optional, collected from state) the name of the model as returned by the global interpretability API
   * @property {string} iparams.colName (optional, collected from state) the feature for which impact data needs to be retrieved
   * @property {string} iparams.dSource (optional, collected from state) `test` for Validate, `train` for Training
   * @return {object} Raw graph data obtained from the feature impact API.
   * @private
   * @async
   */
  const loadBucketData = async function (iparams) {
    if (empty(iparams)) {
      iparams = {};
    }
    let userHash = CREDENTIALS.getUserCreds();
    if (userHash == null) {
      throw new Error(i18n.en.APP.UI.ERROR.MODELCFG.USER_NOT_LOGGED_IN);
    }
    let params = extend({
      key: userHash,
      projectKey: PROJECT.currentProjectKey(),
      projVersion: PROJECT.currentProjVersion(),
      modelName: getCurrentModelID(),
      colName: globalFeatureID,
      dSource: dataSource,
    }, iparams);

    Object.keys(params).forEach(key => {
      if (empty(params[key])) {
        let errorstr = i18n.en.APP.UI.ERROR.MODELPERFORMANCE.INSUFFICIENT_PARAMS;
        if (!empty(params.colName)) {
          errorstr = sprintf(errorstr, "getting " + params.colName, key);
        } else {
          errorstr = sprintf(errorstr, "getting details", key);
        }
        throw new Error(errorstr);
      }
    });

    let url = `${SERVER.getBaseAddress()}fimpact`;
    APP.setProgress("Loading data...");
    let result = null;
    try {
      if (useTestData) {
        result = await EAI_GLOBAL_TEST_DATA.getBucketData();
      } else {
        result = await SERVER.postData(url, params);
      }
    } catch (err) {
      result = null;
    }
    if (result === "ROUTES_MISMATCHED") {
      return;
    }
    if (result == null || (result.status != "success" && !(result.status >= 100 && result.status < 300))) {
      APP.showError(`${i18n.en.APP.UI.ERROR.MODELPERFORMANCE.GENERIC} Please contact an Administrator.`);
      globalFeatureID = "";
      APP.resetProgress();
      let err = new Error(i18n.en.APP.UI.ERROR.MODELPERFORMANCE.GENERIC); err.name = 'GenericError'; throw err;
    } else if (result.status == 198) {
      APP.showWarning(sprintf(i18n.en.APP.UI.WARNING.MODELPERFORMANCE.FIMPACT_NOT_READY, globalFeatureID));
      globalFeatureID = "";
      APP.resetProgress();
      let err = new Error(sprintf(i18n.en.APP.UI.WARNING.MODELPERFORMANCE.FIMPACT_NOT_READY, globalFeatureID)); err.name = 'Not Ready'; throw err;
    } else {
      result = result.data.posts[0];
    }
    // APP.resetProgress();
    APP.resetProgress();
    return result;
  };

  return my;
}(MP_EAI_GLOBAL || {}));

