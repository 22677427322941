/**
 * @module PROB_LEVELS
 * @description draws the Probability Levels chart shown on the `mp/mll/fprAndPA` page. Implements the 
 * [`CHART`](module-CHART.html) interface.
 */
;var PROB_LEVELS=(function(my){
  my.type="probLevels";

  my.optsGenerator=function(options){
    options.attributes={
      "class": "scrollable-table",
    }
    return options;
  };
  my.generate=function(options){
    let opts=my.optsGenerator(options);
    qs("#probLevelsTableDiv").innerHTML=problevelstableTemplate(opts)
    return qs("#probLevels table");
  }

  return my;
}(PROB_LEVELS || {}));
