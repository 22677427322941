/* global mpmodelcomparisonTemplate, MC_TEST_DATA */
/**
 * @module MP_MODEL_COMPARISON
 * @description Shows and manages the model comparison page that shows the stats for the various models
 * for test and training data sources
 */
var MP_MODEL_COMPARISON = (function (my) {
  /**
   * @member {string} STORE_KEY
   * @description the key for caching data in the STORE
   * @public
   */
  my.STORE_KEY = "MP_MODEL_COMPARISON";

  /**
   * @member {string} dataSource
   * @description Whether the current view is for the "test" or "training" data.
   * @public
   */
  my.dataSource = "test";

  /**
   * @member {number} originalLHSWidth
   * @description stores the width of the table area when the view first comes up. This is used to restrict
   * the dragging of the separator to this original size.
   * @private
   */
  var originalLHSWidth = null;

  /**
   * @member {boolean} dragging
   * @description While the user is dragging the [separator](#~separator) with the mouse, this variable is `true`.
   * @private
   */
  var dragging = false;

  /**
   * @member {string} payloadData
   * @description stores the payload data for each deployable model from model comparison table
   * @private
   */
  var payloadData = null;

  /**
   * @member {object} compData Comparison data from server API call
   * @private
   */
  var compData = [];

  segmentationCompCols = {
    columns: [
      {
        label: "Silhouette Score",
        "data-key": "silhouette",
        "additional-tags": ["number", "percent"],
      },
      {
        label: "Accuracy",
        "data-key": "acc",
        "additional-tags": ["number", "percent"],
      },
      {
        label: "Adjusted Rand Index",
        "data-key": "adjRandIdx",
        "additional-tags": ["number"],
      },
      {
        label: "Mutual Information Score",
        "data-key": "miScore",
        "additional-tags": ["number"],
      },
    ],
  };
  txt_mclassCompCols = {
    columns: [
      {
        label: "Accuracy",
        "data-key": "accuracy",
        "additional-tags": ["number"],
      },
      {
        label: "Precision",
        "data-key": "precision",
        "additional-tags": ["number"],
      },
      {
        label: "Recall",
        "data-key": "recall",
        "additional-tags": ["number"],
      },
      {
        label: "F1",
        "data-key": "f1",
        "additional-tags": ["number"],
      },
      {
        label: "Precision Macro Average",
        "data-key": "precision_macro_average",
        "additional-tags": ["number"],
      },
      {
        label: "F1 Macro Average",
        "data-key": "f1_macro_average",
        "additional-tags": ["number"],
      },
    ],
  };

  /**
   * @method prime
   * @description Primes this view, adds the view's HTML from its JS Pug template into the DOM.
   * Registers listeners.
   * @private
   */
  var prime = function () {
    var mcConfig = null;
    if (useTestData && PROJECT.currentProject().ptype == "segmentation") {
      mcConfig = segmentationCompCols;
    } else if (useTestData && PROJECT.currentProject().ptype == "txt-mclass") {
      mcConfig = txt_mclassCompCols;
    } else {
      mcConfig = APP.getProperty(
        "project.config.modelcomp",
        PROJECT.currentProjectKey()
      );
    }
    // mlops_deploy is the flag which states if the models are deployable or not.
    if (mcConfig) {
      qs("#model-comparison").innerHTML = mpmodelcomparisonTemplate({
        mcConfig: mcConfig,
        isDeployable: compData.mlops_deploy,
      });
    }
    registerListeners();
  };

  // event listener for navigation in the deployment area.
  const registerEventListenerForNavigation = function () {
    qsa(".navigator").forEach((x) =>
      x.addEventListener("click", (evt) => {
        let currentNavigator = evt.target.getAttribute("id");
        updateDeploymentUI(payloadData, currentNavigator);
        qsa(".navigator").forEach((navigator) =>
          navigator.removeClass("selected")
        );
        qs(`.navigator#${currentNavigator}`).addClass("selected");
      })
    );
  };

  // event listener for navigating the request box for python or curl code in the deployment area.
  const registerEventListenerForRequestNavigation = function () {
    qsa(".request-navigator").forEach((x) =>
      x.addEventListener("click", (evt) => {
        let currentReqNav = evt.target.getAttribute("id");
        let currentTab = qs(".payload-container").getAttribute("tab");
        qsa(".request-navigator").forEach((reqNav) =>
          reqNav.removeClass("selected")
        );
        evt.target.addClass("selected");
        let reqCode = qs(".request-code");
        reqCode.innerText = payloadData[currentTab].request.code[currentReqNav];
        qs(".copy-icon").setAttribute("tooltip", "copy text");
      })
    );
  };

  // event listener to copy the code from deployment area.
  const registerEventListenerForCopyText = function () {
    qs(".copy-icon").addEventListener("click", (evt) => {
      var tempCopyElement = document.createElement("input");
      document.body.appendChild(tempCopyElement);
      tempCopyElement.value = qs(".request-code").innerText;
      tempCopyElement.select();
      document.execCommand("copy", false);
      tempCopyElement.remove();
      evt.target.setAttribute("tooltip", "copied!");
    });
  };

  // fills the code area for request as well as reponse in the deployment area.
  const fillCodeArea = function () {
    let currentTab = qs(".payload-container").getAttribute("tab");
    let reqCode = qs(".request-code");
    let resCode = qs(".response-code");
    reqCode.innerText = payloadData[currentTab].request.code.curl;
    resCode.innerText = JSON.stringify(payloadData[currentTab].response.code);
  };
  /**
   * @method updateDeploymentUI
   * @description rerenders the payload area after deploying.
   * @params {string} result corresponding to the current model ID.
   * @private
   */
  const updateDeploymentUI = function (result, currentTab) {
    qs("#model-comparison-main").addClass("model-comparison-and-payload");
    qs("#model-comparison-main .separator").removeClass("hidden");
    let payloadArea = qs("#model-comparison-main #payload-area");
    payloadArea.removeClass("hidden");
    payloadArea.innerHTML = "";
    payloadDetails = createNode(
      payloadTemplate({
        result: result,
        currentTab: currentTab,
      })
    );
    payloadArea.appendChild(payloadDetails);
    qs(".navigation-header .navigator:first-child").addClass("selected");
    fillCodeArea();
    registerEventListenerForNavigation();
    registerEventListenerForRequestNavigation();
    registerEventListenerForCopyText();
  };

  /**
   * @method getDeploymentDetails
   * @description calls the API for deployment details
   * @params {string} model ID correspoding the model
   * @private
   */
  const getDeploymentDetails = async function (model) {
    const projectKey = PROJECT.currentProjectKey();
    const projVersion = PROJECT.currentProjVersion();
    let params = {
      projectKey: projectKey,
      projVersion: projVersion,
      modelName: model,
    };
    console.log(params);
    if (useTestData) {
      let result = await DEPLOY.getData("deployModel", params);
      return result;
    }
    return API_HELPER.getResult("deployModel", params);
  };

  /**
   * @method registerListeners
   * @description registers change listeners for the data sources (test or training)
   * @private
   */
  var registerListeners = function () {
    const modelComparison = qs("#model-comparison");
    const separator = modelComparison.qs(".separator");

    // event listener for separator dragging
    modelComparison.addEventListener("mousedown", (evt) => {
      if (evt.target === separator && evt.buttons === 1) {
        dragging = true;
        if (originalLHSWidth == null) {
          originalLHSWidth = qs("#mc-outer-container").offsetWidth;
        }
      }
    });
    modelComparison.addEventListener("mouseup", () => {
      if (!dragging) return;
      dragging = false;
    });
    modelComparison.addEventListener("mousemove", (evt) => {
      if (dragging) {
        let lb = qs(".model-comparison-and-payload");
        let oldValue = parseInt(
          window.getComputedStyle(lb).getPropertyValue("--graph-area-delta")
        );
        if (
          (originalLHSWidth + oldValue > 925 && evt.movementX > 0) ||
          (originalLHSWidth + oldValue < 790 && evt.movementX < 0)
        ) {
          return;
        }
        lb.style.setProperty("--graph-area-delta", oldValue + evt.movementX);
      }
    });
    if (window.innerWidth < 1450) {
      qs("#model-comparison-main").style.setProperty(
        "--graph-area-width",
        "20vw"
      );
    }

    // event listener for selecting the source
    modelComparison.qs("#mc-sourceSelect").addEventListener("change", (evt) => {
      my.dataSource = evt.target.value;
      modelComparison
        .qs(".tableContainer table")
        .setAttribute("data-active-source", my.dataSource);
    });

    // event listener for deploy
    modelComparison
      .qs(".tableContainer tbody")
      .addEventListener("click", (evt) => {
        if (evt.target.className === "deploy-icon") {
          let model = evt.target.parentElement.parentElement.getAttribute("id");
          getDeploymentDetails(model.toUpperCase()).then((result) => {
            payloadData = result.data.posts;
            let currentTab = payloadData.header[0].id;
            updateDeploymentUI(payloadData, currentTab);
          });
        } else if (evt.target.className === "download-icon") {
          let model = evt.target.parentElement.parentElement.getAttribute("id");
          downloadIntimeScoreFile(model.toUpperCase(), my.dataSource);
        }
      });
  };

  /**
   * @method downloadIntimeScoreFile
   * @description Calls the downloadintimescore API. This API downloads a file.
   * @private
   */
  var downloadIntimeScoreFile = function (modelName, dataSource) {
    const projectKey = PROJECT.currentProjectKey();
    const projVersion = PROJECT.currentProjVersion();
    let downloadURL = `${SERVER.getBaseAddress()}downloadintimescore?projectKey=${projectKey}&projVersion=${projVersion}&modelName=${modelName}&dataSource=${dataSource}`;
    window.open(downloadURL);
  };

  /**
   * @method load
   * @description Loads the view. Sets identifying markers to scope CSS. Loads data for the
   * view either from the STORE or by making a server call.
   * @param {string} pkey The project ID in the context of which the view is loaded
   * @async
   * @public
   */
  var load = async function (pkey) {
    APP.resetCurrentPageMarker();
    qs("#main-content").setAttribute("class", "");
    qs("#main-content").addClass("mp-model-comparison");
    const modelComparisionHtml = qs("#model-comparison");
    if (modelComparisionHtml) {
      modelComparisionHtml.innerHTML = "";
    }
    compData = STORE.getProjectData(
      pkey,
      PROJECT.currentProjVersion(),
      MP_MODEL_COMPARISON.STORE_KEY
    );
    if (!compData) {
      compData = await my.loadData({
        projectKey: pkey,
        projVersion: PROJECT.currentProjVersion(),
      });
      STORE.setProjectData(
        pkey,
        PROJECT.currentProjVersion(),
        MP_MODEL_COMPARISON.STORE_KEY,
        compData
      );
      STORE.setProjectMetadata(
        pkey,
        PROJECT.currentProjVersion(),
        MP_MODEL_COMPARISON.STORE_KEY + "_compData_loaded",
        true
      );
    }
    prime();
  };

  /**
   * @method show
   * @description load the view's data and then load it into the table
   * @async
   * @public
   */
  my.show = async function (pkey) {
    await load(pkey);
    loadModelsTable();
  };

  /**
   * @method loadModelsTable
   * @description load the data into the table
   * @private
   */
  var loadModelsTable = function () {
    let html = "";
    if (useTestData && PROJECT.currentProject().ptype == "segmentation") {
      mcConfig = segmentationCompCols;
    } else if (useTestData && PROJECT.currentProject().ptype == "txt-mclass") {
      mcConfig = txt_mclassCompCols;
    } else {
      mcConfig = APP.getProperty(
        "project.config.modelcomp",
        PROJECT.currentProjectKey()
      );
    }
    Object.keys(compData).forEach((x) => {
      // don't add the row for mlops_deploy as it is only a flag to check the deployable data
      if (x === "mlops_deploy") {
        return;
      }
      ["test", "train"].forEach((source) => {
        html += COMPARISON_TABLE.setUpRow(compData, mcConfig, source, x);
      });
    });
    qsa(
      "#model-comparison .tableContainer table tbody tr:not(.template-row)"
    ).forEach((x) => x.remove());
    qs("#model-comparison .tableContainer table tbody").innerHTML =
      qs("#model-comparison .tableContainer table tbody").innerHTML + html;
    qs("#model-comparison .tableContainer tbody tr:nth-child(2) td").addClass(
      "selected"
    );
  };

  /**
   * @method selectRow
   * @description listener for selection of table row.
   * @param {string} modelID identifier for the data row that was selected
   * @public
   */
  my.selectRow = function (modelID) {
    qsa("#model-comparison .tableContainer td.selected").forEach((x) =>
      x.removeClass("selected")
    );
    qs(`#model-comparison .tableContainer td#${modelID}`).addClass("selected");
  };

  /**
   * @method loadData
   * @description Loads the data from the comp API on the server and returns it
   * @param {object} iparams userHash(optional) and projectKey(required)
   * @property {string} iparams.projectKey id of the project for which to get scores
   * @return {object} Raw graph data obtained from the comp API.
   * @private
   * @async
   */
  my.loadData = async function (iparams) {
    let url = SERVER.getBaseAddress() + "comp";
    let userHash = CREDENTIALS.getUserCreds();
    if (userHash == null) {
      throw new Error(i18n.en.APP.UI.ERROR.MODELCFG.USER_NOT_LOGGED_IN);
    }
    let params = extend(
      {
        key: userHash,
        projectKey: "",
        projVersion: "",
      },
      iparams
    );
    APP.setProgress("Loading model data...", true);
    let result = null;
    try {
      if (useTestData) {
        result = await MC_TEST_DATA.getData(url, params);
      } else {
        result = await SERVER.postData(url, params);
      }
    } catch (err) {
      result = null;
      APP.resetProgress();
    }
    if (result === "ROUTES_MISMATCHED") {
      return;
    }
    APP.resetProgress();
    compData = result;
    return result;
  };
  return my;
})(MP_MODEL_COMPARISON || {});
