function entryPoint(e){
  onloadAlreadyRun=true;

  APP.init();
}

document.addEventListener('DOMContentLoaded', function(e){
  onloadEventFired=true;
  if (!onloadAlreadyRun){
    entryPoint(e);
  }
});

if (!onloadAlreadyRun && !onloadEventFired){
  entryPoint();
}

if (typeof HTMLDialogElement === 'function') {
  qs("html").classList.add("html5dialog");
} else {
  qs("html").classList.add("no-html5dialog");
}

try{
  if (CSS.supports("display", "contents")){
    qs("html").classList.add("display-contents");
  } else {
    qs("html").classList.add("no-display-contents");
  }
} catch (err){
  qs("html").classList.add("no-display-contents");
}