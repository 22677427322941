/* global DASHBOARD */
/**
 * @module APP
 * @description Provides the application entrypoint and app level scope for
 * methods that affect the whole application
 */
// eslint-disable-next-line no-extra-semi
APP = (function (my) {
  const initializeErrorMonitoring = function () {
    console.error = (msg, ...args) => {
      const error = new Error(msg);
      const stackTrace = error.stack;
      const timestamp = new Date().toISOString();
      let formattedError = `${stackTrace}`;
      args.forEach((arg) => {
        formattedError = formattedError.replace("%s", arg);
      });
      formattedError = formattedError.replace(/\n/g, "\\n");
      let errorObj = {
        error: {
          timestamp: timestamp,
          error_log: formattedError,
        },
        productType: "ituring",
      };
      const response = API_HELPER.storeError(errorObj);
    };

    window.onerror = (message, source, lineno, colno, error) => {
      const timestamp = new Date().toISOString();
      const formattedError = `Unhandled Error: ${message} at ${source}: ${lineno}: ${colno} ${error}`;
      formattedError = formattedError.replace(/\n/g, "\\n");
      let errorObj = {
        error: {
          timestamp: timestamp,
          error_log: formattedError,
        },
        productType: "ituring",
      };
      const response = API_HELPER.storeError(errorObj);
      return true;
    };

    window.addEventListener("unhandledrejection", function (event) {
      const timestamp = new Date().toISOString();
      let formattedError = event.reason.stack;
      formattedError = formattedError.replace(/\n/g, "\\n");
      let errorObj = {
        error: {
          timestamp: timestamp,
          error_log: formattedError,
        },
        productType: "ituring",
      };
      const response = API_HELPER.storeError(errorObj);
    });
  };

  /**
   * @method init
   * @description initialize the whole application. Called from main.js
   * @public
   */
  my.init = async function () {
    await my.initConfig();
    if (APP.getProperty("errorMonitoring.enabled")) {
      initializeErrorMonitoring();
    }
    const navStatus =
      readCookie("navPinned") ||
      APP.getProperty("ui.defaults.navigation.pinnedState") ||
      "pinned";
    if ("pinned" == navStatus.toLowerCase()) {
      my.pinNav();
    } else {
      my.unpinNav();
    }

    if (my.isDebug() && readCookie("printCurl")) {
      window.printCurl = true;
    }

    my.initRoutes();
    my.registerUIListeners();

    let currentRoute = STORE.here;
    if (currentRoute.includes("user-management")) {
      my.router.navigate(currentRoute);
      my.router.resolve();
      return;
    }
    const creds = CREDENTIALS.getUserCreds();
    if (null == creds) {
      if (!APP.getProperty("userManagement.enabled")) {
        my.router.navigate("/sign-up");
      } else {
        const userManagementLink = document.createElement("a");
        const userManagementUrl =
          localStorage.getItem("USER_MANAGEMENT_URL") || "";
        userManagementLink.href = userManagementUrl;
        userManagementLink.click();
      }
    } else {
      await APP.ensureServerConfigLoaded();
      await DASHBOARD.load();
      if (
        my.isDebug() &&
        window.navigateTo &&
        window.navigateTo.length != 0 &&
        typeof window.navigateTo == "string"
      ) {
        my.router.navigate(window.navigateTo);
      } else {
        my.router.navigate("/");
      }
    }
    my.router.resolve();
  };

  /**
   * @method isDebug
   * @description Is the UI running in debug mode. The UI is running in debug mode if:
   * 1. it is a development build, i.e. the "--dev" flag was set during the gulp build
   * 2. the "isDebug" cookie has been set.
   * @return true if running in debug mode, else false
   * @public
   */
  my.isDebug = function () {
    let isDebug = window.isDebug || readCookie("isDebug");
    if (!isDebug || typeof isDebug == "undefined") {
      isDebug = false;
    } else {
      isDebug = true;
    }
    return isDebug;
  };

  return my;
})(APP || {});
