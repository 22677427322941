/**
 * @module REGRESSION_CUSTOMIZER
 * @description 
 */
var REGRESSION_CUSTOMIZER=(function(my){

  my.id="regression";

  // eslint-disable-next-line no-unused-vars
  my.preRoute=function(router, params) {

  };

  my.postRoute=function(router, params) {
    params.nav.qs("#navItem-ae-automodel-nav-link").addClass("disabled");
    params.nav.qsa(["#navItem-mp-mll-actualVsPredicted-nav-link",
              "#navItem-mp-mll-residualsPlot-nav-link", 
              "#navItem-mp-mll-normality-nav-link", 
              "#navItem-mp-mll-predictedVsResidual-nav-link", 
              "#navItem-mp-eai-local-nav-link", 
              "#navItem-mp-eai-global-nav-link", 
              "#navGroupItem-mp-eai"].join(", ")).forEach(x=>x.removeClass("hidden"));    
    CUSTOMIZER_HELPER.configHandler(params);  
  };

  my.reroute=function(params) {
    return CUSTOMIZER_HELPER.reroute(params,"regression");
  }

  return my;
}(REGRESSION_CUSTOMIZER || {}));