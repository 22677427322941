var ODA_APP = (function (my) {
  /**
   * @method handleRedirect
   * @description handles routing according
   */
  my.handleRedirect = async function (
    projectKey,
    projVersion,
    fromModelScoreNode
  ) {
    try {
      let route = "/";
      // If 'fromModelScoreNode' is true, redirect to the score-models screen
      if (fromModelScoreNode === "true") {
        route = "/md/score-models";
      } else {
        const response = await APP.getAllProjects();
        const projects = response.data.posts;
        route = getRoute(projects, projectKey, projVersion);
      }
      redirectToRoute(route, projectKey, projVersion);
    } catch (err) {
      APP.router.navigate("/logout");
    }
  };

  /**
   * @method getRoute
   * @description returns the route based on the version state
   */
  const getRoute = (projects, projectKey, projVersion) => {
    const project = projects.find((proj) => proj.projectkey === projectKey);
    if (!project) {
      return "/";
    }
    const version = project.versionInfo.find(
      (ver) => ver.vname === projVersion
    );
    if (!version) {
      return "/";
    }
    if (version.state == 2) {
      return "/db-connection";
    } else if (version.state == 3) {
      return "/workflow-start";
    } else if (version.state == 4) {
      return "/ae-automodel";
    } else if (version.state == 5 || version.state == 6) {
      return "/mp/model-comparison";
    } else {
      return "/";
    }
  };

  /**
   * @method redirectToRoute
   * @description sets the current project key and project version, then navigates to the specified route
   */
  const redirectToRoute = async (route, projectKey, projVersion) => {
    await DASHBOARD.load();
    PROJECT.setCurrentProjectKey(projectKey);
    PROJECT.setCurrentProjVersion(projVersion);
    APP.clearProjectConfigs(projectKey);
    APP.loadProjectProperty({
      projectKey: projectKey,
      propKey: "project",
      projVersion: projVersion,
    }).then(() => {
      APP.router.navigate(route);
    });
  };

  return my;
})(ODA_APP || {});
