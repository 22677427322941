/* global Navigo, DASHBOARD, FILE_UPLOAD, DB_CONNECTION, VALIDATE_FEATURES, EXPLORE_DATA, AE_AUTOMODEL,
          ML_LEADERBOARD, MP_MODEL_COMPARISON, MP_EAI_GLOBAL, MP_EAI_LOCAL, AUTO_CODE, SCORE_MODELS
 */
/**
 * @module APP
 * @description Provides the application entrypoint and app level scope for
 * methods that affect the whole application. This file contains helper functions to interact with the navigation, dialogs and
 * the error indicator
 */

// eslint-disable-next-line no-extra-semi
APP = (function (my) {
  /**
   * @public
   * @method
   * @description Routes to '/reset-password page if value resetReqd cookie is true.'
   */
  my.resetPasswdIfReqd = function () {
    if (readCookie("resetReqd") == "true") {
      my.router.navigate("/reset-password");
    }
  };
  /**
   * @member {Navigo} router
   * @description can be used to trigger app level navigation using APP.router.navigate("<route>")
   * uses the navigo.js router.
   * @public
   */
  my.router = new Navigo(null, true);

  /**
   * @member {Element} nav
   * @description a global reference to the global left navigation. Can be accessed throughout the
   * UI code as APP.nav
   * @public
   */
  my.nav = qs("#main-nav");

  /**
   * @method initRoutes
   * @description Initialize all the routes for the application. Uses the navigo.js router.
   * Also adds a hook to hide the global progressbar on route change.
   * @public
   */
  my.initRoutes = function () {
    my.router.on("/user-management", async function (params, query) {
      if (!APP.getProperty("userManagement.enabled")) {
        my.router.navigate("/sign-up");
        return;
      }
      const urlParams = new URLSearchParams(query);
      const userToken = urlParams.get("token");
      const ituringProjKey = urlParams.get("ituringProjKey");
      const ituringProjVersion = urlParams.get("ituringProjVersion");
      const ootDSource = urlParams.get("ootDSource");
      const fromODA = urlParams.get("fromODA");
      const fromModelScoreNode = urlParams.get("fromModelScoreNode");
      const userManagementUrl = urlParams.get("userManagementUrl");
      localStorage.setItem("USER_MANAGEMENT_URL", userManagementUrl);
      APP.closeAllDialogs();
      my.setCurrentPageMarker("user-management");
      // If the condition 'fromModelScoreNode === "true"' is met, the SCORE_MODELS.ootDSource is assigned the value of the provided 'ootDSource'
      if (fromModelScoreNode === "true") {
        SCORE_MODELS.ootDSource = ootDSource;
      }
      let result = null;
      if (result == null) {
        my.setProgress(i18n.en.APP.UI.FOOTER.PROGRESS.LOGIN);
        try {
          result = await CREDENTIALS.obtainCredsViaAccessToken(userToken);
        } catch (err) {
          my.resetProgress();
        }
      }
      if (result) {
        // Check whether the URL has the 'fromODA' key set to true
        // If true, then handle routing according to project state
        if (fromODA === "true" && ituringProjKey && ituringProjVersion) {
          ODA_APP.handleRedirect(
            ituringProjKey,
            ituringProjVersion,
            fromModelScoreNode
          );
        } else {
          my.router.navigate("/");
        }
        APP.init();
      }
    });

    /**
     * DASHBOARD
     * PUG: index.pug, project.pug
     * JS: dashboard.js, test/projects-test-data.js
     * MODULE: DASHBOARD
     * SCSS: _dashboard.scss
     */
    my.router.on("/", function () {
      //GO TO DASHBOARD
      APP.closeAllDialogs();
      const creds = CREDENTIALS.getUserCreds();
      if (null == creds) {
        if (!APP.getProperty("userManagement.enabled")) {
          my.router.navigate("/sign-up");
        } else {
          const userManagementLink = document.createElement("a");
          const userManagementUrl =
            localStorage.getItem("USER_MANAGEMENT_URL") || "";
          userManagementLink.href = userManagementUrl;
          userManagementLink.click();
        }
      } else {
        my.resetPasswdIfReqd();
        // Not completely sure if this dependency is needed.
        // We need the server property common.project.new for
        // processing new project action. That could fail if
        // the user hits new project too soon after dashboard load.
        PROJECT.cleanUp();
        APP.loadServerProperty()
          .then(() => DASHBOARD.load())
          .catch(() => DASHBOARD.load());
      }
    });

    /**
     * SECURITY POLICY
     * PUG: securityPolicy.pug
     * JS: security-policy.js, user-management-test-data.js
     * MODULE: SECURITY_POLICY
     * SCSS: _security-policy.scss
     */
    my.router.on("/security-policy", function () {
      APP.closeAllDialogs();
      const creds = CREDENTIALS.getUserCreds();
      if (null == creds) {
        if (!APP.getProperty("userManagement.enabled")) {
          my.router.navigate("/sign-up");
        } else {
          const userManagementLink = document.createElement("a");
          const userManagementUrl =
            localStorage.getItem("USER_MANAGEMENT_URL") || "";
          userManagementLink.href = userManagementUrl;
          userManagementLink.click();
        }
      }
      my.setCurrentPageMarker("security-policy");
      SECURITY_POLICY.loadPage();
    });

    /**
     * RESET PASSWORD
     * PUG: resetPassword.pug
     * JS: reset-password.js, user-management-test-data.js
     * MODULE: RESET_PASSWORD
     * SCSS: _reset-password.scss
     */
    my.router.on("/reset-password", function () {
      APP.closeAllDialogs();
      const creds = CREDENTIALS.getUserCreds();
      if (null == creds) {
        if (!APP.getProperty("userManagement.enabled")) {
          my.router.navigate("/sign-up");
        } else {
          const userManagementLink = document.createElement("a");
          const userManagementUrl =
            localStorage.getItem("USER_MANAGEMENT_URL") || "";
          userManagementLink.href = userManagementUrl;
          userManagementLink.click();
        }
      }
      my.setCurrentPageMarker("reset-password");
      RESET_PASSWORD.loadPage();
    });

    /**
     * HELP
     * PUG: help.pug
     * JS: help.js, user-management-test-data.js
     * MODULE: HELP
     * SCSS: _help.scss
     */
    my.router.on("/help", function () {
      APP.closeAllDialogs();
      const creds = CREDENTIALS.getUserCreds();
      if (null == creds) {
        if (!APP.getProperty("userManagement.enabled")) {
          my.router.navigate("/sign-up");
        } else {
          const userManagementLink = document.createElement("a");
          const userManagementUrl =
            localStorage.getItem("USER_MANAGEMENT_URL") || "";
          userManagementLink.href = userManagementUrl;
          userManagementLink.click();
        }
      }
      my.setCurrentPageMarker("help");
      HELP.loadPage();
    });

    /**
     * FORGET PASSWORD
     * PUG: templates/forget-password.pug
     * JS: forget-password.js
     * MODULE:
     * SCSS:
     */
    my.router.on("/forget-password", function () {
      APP.closeAllDialogs();
      if (qs("#dialogs-active #login")) {
        //if dialog already exists, cancel old dialog
        let loginDlg = qs("#dialogs-active #login");
        loginDlg.fireCustomEvent("cancelled", {
          message: "Moving to forget-password page.",
        });
        APP.hideDialog(loginDlg);
      }
      CREDENTIALS.resetCreds();
      my.updateNavigationVisibility();
      my.setCurrentPageMarker("forget-password");
      qs("#settings").style.display = "none";
      FORGET_PASSWORD.loadPage();
    });

    /**
     * SIGNUP
     * PUG: templates/signUp.pug
     * JS: credentials.js, sign-up.js, test/credentials-test-data.js
     * MODULE: CREDENTIALS, SIGNUP
     * SCSS: _sign-up.scss
     */
    my.router.on("/sign-up", function () {
      my.setCurrentPageMarker("sign-up");
      SIGN_UP.loadPage();
    });

    /**
     * LOGIN
     * PUG: includes/login.pug, templates/login.pug
     * JS: credentials.js, test/credentials-test-data.js
     * MODULE: CREDENTIALS
     * SCSS: _login.scss
     */
    my.router.on("/login", async function () {
      //SHOW LOGIN DIALOG
      SIGN_UP.unloadPage();
      let creds = null,
        result = null,
        isError = false;
      while (result == null) {
        try {
          creds = await CREDENTIALS.showLoginDialog(
            isError ? i18n.en.APP.UI.ERROR.INCORRECT_USERNAME_OR_PASSWORD : ""
          );
        } catch (err) {
          return;
        }
        my.setProgress(i18n.en.APP.UI.FOOTER.PROGRESS.LOGIN);
        try {
          result = await CREDENTIALS.obtainCreds(creds.uname, creds.password);
        } catch (err) {
          my.resetProgress();
          isError = true;
          APP.showError(i18n.en.APP.UI.ERROR.INCORRECT_USERNAME_OR_PASSWORD);
        }
      }
      if (CREDENTIALS.isResetRequired()) {
        my.router.navigate("/security-policy");
      } else {
        my.router.navigate("/");
      }
    });

    /**
     * LOGOUT
     * PUG: n/a
     * JS: credentials.js
     * MODULE: CREDENTIALS
     * SCSS: n/a
     */
    my.router.on("/logout", function () {
      APP.closeAllDialogs();
      SERVER.postData(SERVER.getBaseAddress() + "logout", {}).then((res) => {
        CREDENTIALS.resetCreds();
        DASHBOARD.unload();
        my.updateNavigationVisibility();
        if (!APP.getProperty("userManagement.enabled")) {
          my.router.navigate("/sign-up");
        } else {
          const userManagementLink = document.createElement("a");
          const userManagementUrl =
            localStorage.getItem("USER_MANAGEMENT_URL") || "";
          userManagementLink.href = userManagementUrl;
          userManagementLink.click();
        }
      });
    });

    /**
     * NEW PROJECT DIALOG
     * PUG: templates/createProject.pug
     * JS: project.js, project-test-data.js
     * MODULE: PROJECT
     * SCSS: project.scss
     */
    my.router.on("/new-project", PROJECT.createProject);

    /**
     * VERSION COMPARISON
     * PUG: templates/versionComparison.pug
     * JS: version-comparison.js
     * MODULE: VERSION COMPARISON
     * SCSS: version-comparison.scss
     */
    my.router
      .on("/version-comparison/:pkey", function (params) {
        APP.closeAllDialogs();
        my.setCurrentPageMarker("version-comparison");
        let pkey = params.pkey;
        PROJECT.setCurrentProjectKey(pkey);
        VERSION_COMPARISON.show(pkey);
      })
      .on("/version-comparison", function () {
        APP.router.navigate(`/version-comparison/${pkey}`);
      });

    /**
     * NEW DB CONNECTION DIALOG
     * PUG: templates/dbConnection.pug
     * JS: db-connection.js, test/db-connection-test-data.js
     * MODULE: DB_CONNECTION
     * SCSS: _db-connection.scss
     */
    my.router
      .on("/db-connection/:pkey", function (params) {
        my.resetPasswdIfReqd();
        let pkey = params.pkey;
        if (!pkey) {
          pkey = PROJECT.currentProjectKey();
        } else {
          PROJECT.setCurrentProjectKey(pkey);
        }
        FILE_UPLOAD.hideDialog();
        DB_CONNECTION.dbConnection({
          pkey: pkey,
          destinationOnCancel: "/",
        });
      })
      .on("/db-connection", function () {
        APP.router.navigate(`/db-connection/${PROJECT.currentProjectKey()}`);
        my.resetPasswdIfReqd();
      });

    /**
     * FILE UPLOAD DIALOG
     * PUG: templates/fileUpload.pug
     * JS: file-upload.js, test/file-upload-test-data.js
     * MODULE: FILE_UPLOAD
     * SCSS: _file-upload.scss
     */
    my.router
      .on("/file-upload/:pkey", function (params) {
        my.resetPasswdIfReqd();
        let pkey = params.pkey;
        if (!pkey) {
          pkey = PROJECT.currentProjectKey();
        } else {
          PROJECT.setCurrentProjectKey(pkey);
        }
        DB_CONNECTION.hideDialog();
        FILE_UPLOAD.fileUpload({
          pkey: pkey,
          destinationOnCancel: "/",
        });
      })
      .on("/file-upload", function () {
        APP.router.navigate(`/file-upload/${PROJECT.currentProjectKey()}`);
        my.resetPasswdIfReqd();
      });

    /**
     * VALIDATE FEATURES PAGE
     * PUG: index.pug, templates/table.pug, templates/validateFeatures.pug
     * JS: validate-features.js, test/validate-features-test-data.js
     * MODULE: VALIDATE_FEATURES
     * SCSS: _validate-features.scss
     */
    my.router
      .on("/validate-features/:pkey", function (params) {
        let pkey = params.pkey;
        if (!pkey) {
          pkey = PROJECT.currentProjectKey();
        } else {
          PROJECT.setCurrentProjectKey(pkey);
        }
        VALIDATE_FEATURES.validateFeatures(pkey);
        my.resetPasswdIfReqd();
      })
      .on("/validate-features", function () {
        APP.router.navigate(
          `/validate-features/${PROJECT.currentProjectKey()}`
        );
        my.resetPasswdIfReqd();
      });

    /**
     * EXPLORE DATA PAGE
     * PUG: index.pug, templates/table.pug
     * JS: explore-data.js, test/explore-data-test-data.js
     * MODULE: EXPLORE_DATA
     * SCSS: _explore-data.scss
     */
    my.router
      .on("/explore-data/:pkey", function (params) {
        my.resetPasswdIfReqd();
        let pkey = params.pkey;
        if (!pkey) {
          pkey = PROJECT.currentProjectKey();
        } else {
          PROJECT.setCurrentProjectKey(pkey);
        }
        EXPLORE_DATA.exploreData(pkey);
      })
      .on("/explore-data", function () {
        APP.router.navigate(`/explore-data/${PROJECT.currentProjectKey()}`);
        my.resetPasswdIfReqd();
      });

    /**
     * WORKFLOW START PAGE
     * PUG: index.pug
     * JS: dashboard.js
     * MODULE: n/a
     * SCSS: _workflow-start.scss
     */
    my.router
      .on("/workflow-start/:pkey", function (params) {
        my.resetPasswdIfReqd();
        let pkey = params.pkey;
        if (!pkey) {
          pkey = PROJECT.currentProjectKey();
        } else {
          PROJECT.setCurrentProjectKey(pkey);
        }
        DASHBOARD.workflowStart(pkey);
      })
      .on("/workflow-start", function () {
        APP.router.navigate(`/workflow-start/${PROJECT.currentProjectKey()}`);
        my.resetPasswdIfReqd();
      });

    /**
     * MODEL DEVELOPMENT PAGE
     * PUG: templates/AEAutoModel.pug, templates/dataTreatment.pug, templates/featureEngineering.pug, templates/modelDevelpment.pug, templates/modelDevConfirmationDLG.pug
     * JS: ae-automodel.js, test/model-dev-report-data.js
     * MODULE: AE_AUTOMODEL
     * SCSS: _ae-automodel.scss
     */
    my.router
      .on("/ae-automodel/:pkey", function (params) {
        my.resetPasswdIfReqd();
        let pkey = params.pkey;
        if (!pkey) {
          pkey = PROJECT.currentProjectKey();
        } else {
          PROJECT.setCurrentProjectKey(pkey);
        }
        if (
          STORE.getProjectMetadata(
            PROJECT.currentProjectKey(),
            PROJECT.currentProjVersion(),
            AE_AUTOMODEL.STORE_KEY + "_modeldev_complete"
          )
        ) {
          APP.router.navigate(`/mp/mll`);
        } else {
          AE_AUTOMODEL.showAutoModel(pkey);
        }
      })
      .on("/ae-automodel", function () {
        APP.router.navigate(`/ae-automodel/${PROJECT.currentProjectKey()}`);
        my.resetPasswdIfReqd();
      });

    /**
     * MACHINE LEARNING LEADERBOARD PAGE with DISCRIMINATION, CALIBRATION, and FPR and PRECISION ANALYSIS SUBPAGES
     * PUG: templates/MPMLLeaderboard.pug, templates/probLevelsTable.pug
     * JS: ml-leaderboard.js, test/model-performance-test-data.js
     * MODULE: ML_LEADERBOARD
     * SCSS: _ml-leaderboard.scss
     */
    my.router
      .on("/mp/mll/:subnav/:source/:model/:pkey", function (params) {
        let pkey = params.pkey;
        if (!pkey) {
          pkey = PROJECT.currentProjectKey();
        } else {
          PROJECT.setCurrentProjectKey(pkey);
        }
        let subnav = params.subnav;
        const source = params.source === "source" ? null : params.source;
        const model = params.model === "model" ? null : params.model;
        ML_LEADERBOARD.show(subnav, source, model, pkey);
        my.resetPasswdIfReqd();
      })
      .on("/mp", function () {
        APP.router.navigate(`/mp/mll`);
        my.resetPasswdIfReqd();
      })
      .on("/mp/mll", function (params) {
        const customizer = PROJECT.currentProjectCustomizer();
        params = customizer.reroute(params);
        const subnav = params.subnav;
        APP.router.navigate(`/mp/mll/${subnav}`);
        my.resetPasswdIfReqd();
      })
      .on("/mp/mll/:subnav", function (params) {
        APP.router.navigate(
          `/mp/mll/${params.subnav}/source/model/${PROJECT.currentProjectKey()}`
        );
        my.resetPasswdIfReqd();
      })
      .on("/mp/mll/:subnav/:source", function (params) {
        APP.router.navigate(
          `/mp/mll/${params.subnav}/${
            params.source
          }/model/${PROJECT.currentProjectKey()}`
        );
        my.resetPasswdIfReqd();
      })
      .on("/mp/mll/:subnav/:source/:model", function (params) {
        APP.router.navigate(
          `/mp/mll/${params.subnav}/${params.source}/${
            params.model
          }/${PROJECT.currentProjectKey()}`
        );
        my.resetPasswdIfReqd();
      });

    /**
     * MACHINE LEARNING LEADERBOARD PAGE for segmentation project type with FEATURE SELECTION, OPTIMAL SEGMENT SELECTION, VARIABLE IMPORTANCE, MODEL PERFORMANCE, MODEL INTERPRETABILITY, CLUSTER MERGING, CLUSTER VISUALIZATION subpages
     * PUG: templates/featureSelection.pug, templates/optimalSegmentSelection.pug, templates/variableImportance.pug, templates/modelPerformanceSegmentation.pug, templates/modelInterpretability.pug, templates/clusterMerging.pug, templates/clusterVisualization.pug
     * JS: ml-leaderboard-segmentation.js, feature-selection.js, optimal-segment-selection.js, variable-importance.js, model-performance-segmentation.js, model-interpetability.js, cluster-merging.js, cluster-visualization.js
     * MODULE: ML_LEADERBOARD_SEGMENTATION
     * SCSS: _ml-leaderboard-segmenation.scss, _feature-selection.scss, _optimal-segment-selection.scss, _variable-importance.scss, _model-performace-segmenation.scss, _model-interpretability.scss, _cluster-merging.scss, _cluster-visualization.scss
     */
    my.router
      .on(
        "mlleaderboard-segmentation/:subnav/:source/:model/:pkey",
        function (params) {
          let pkey = params.pkey;
          if (!pkey) {
            pkey = PROJECT.currentProjectKey();
          } else {
            PROJECT.setCurrentProjectKey(pkey);
          }
          let subnav = params.subnav;
          const source = params.source === "source" ? null : params.source;
          const model = params.model === "model" ? null : params.model;
          ML_LEADERBOARD_SEGMENTATION.show(subnav, source, model, pkey);
          my.resetPasswdIfReqd();
        }
      )
      .on("/mlleaderboard-segmentation/:subnav", function (params) {
        APP.router.navigate(
          `/mlleaderboard-segmentation/${
            params.subnav
          }/source/model/${PROJECT.currentProjectKey()}`
        );
        my.resetPasswdIfReqd();
      })
      .on("/mlleaderboard-segmentation/:subnav/:source", function (params) {
        APP.router.navigate(
          `/mlleaderboard-segmentation/${params.subnav}/${
            params.source
          }/model/${PROJECT.currentProjectKey()}`
        );
        my.resetPasswdIfReqd();
      })
      .on(
        "/mlleaderboard-segmentation/:subnav/:source/:model",
        function (params) {
          APP.router.navigate(
            `/mlleaderboard-segmentation/${params.subnav}/${params.source}/${
              params.model
            }/${PROJECT.currentProjectKey()}`
          );
          my.resetPasswdIfReqd();
        }
      );

    /**
     * MACHINE LEARNING LEADERBOARD PAGE for txt-mclass project type with  MODEL PERFORMANCE subpages
     * PUG: templates/featureSelection.pug, templates/optimalSegmentSelection.pug, templates/variableImportance.pug, templates/modelPerformanceSegmentation.pug, templates/modelInterpretability.pug, templates/clusterMerging.pug, templates/clusterVisualization.pug
     * JS: ml-leaderboard-txt-mclass.js, model-performance-txt-mclass.js
     * MODULE: ML_LEADERBOARD_TEXT_MULTI_CLASSIFICATION
     * SCSS: _ml-leaderboard-txt-mclass.scss, _model-performace-txt-mclass.scss
     */
    my.router
      .on(
        "mlleaderboard-txt-mclass/:subnav/:source/:model/:pkey",
        function (params) {
          let pkey = params.pkey;
          if (!pkey) {
            pkey = PROJECT.currentProjectKey();
          } else {
            PROJECT.setCurrentProjectKey(pkey);
          }
          let subnav = params.subnav;
          const source = params.source === "source" ? null : params.source;
          const model = params.model === "model" ? null : params.model;
          ML_LEADERBOARD_TEXT_MULTI_CLASSIFICATION.show(
            subnav,
            source,
            model,
            pkey
          );
          my.resetPasswdIfReqd();
        }
      )
      .on("/mlleaderboard-txt-mclass/:subnav", function (params) {
        APP.router.navigate(
          `/mlleaderboard-txt-mclass/${
            params.subnav
          }/source/model/${PROJECT.currentProjectKey()}`
        );
        my.resetPasswdIfReqd();
      })
      .on("/mlleaderboard-txt-mclass/:subnav/:source", function (params) {
        APP.router.navigate(
          `/mlleaderboard-txt-mclass/${params.subnav}/${
            params.source
          }/model/${PROJECT.currentProjectKey()}`
        );
        my.resetPasswdIfReqd();
      })
      .on(
        "/mlleaderboard-txt-mclass/:subnav/:source/:model",
        function (params) {
          APP.router.navigate(
            `/mlleaderboard-txt-mclass/${params.subnav}/${params.source}/${
              params.model
            }/${PROJECT.currentProjectKey()}`
          );
          my.resetPasswdIfReqd();
        }
      );

    /**
     * BEHAVIOURAL ANALYSIS PAGE with INSIGHTS and PERSONA CREATION subpages
     * PUG: templates/MPBAInsights.pug, templates/MPBAPersonaCreation.pug
     * JS: mp-behavioural-analysis.js, mp-ba-insights.js, mp-ba-persona-creation.js
     * MODULE: MP_BA_PERSONA_CREATION
     * SCSS: _mp-ba-insights.scss, _mp-ba-persona-creation.scss
     */
    my.router
      .on("/mp/ba/:subnav/:pkey", function (params) {
        let pkey = params.pkey;
        if (!pkey) {
          pkey = PROJECT.currentProjectKey();
        } else {
          PROJECT.setCurrentProjectKey(pkey);
        }
        let subnav = params.subnav;
        MP_BEHAVIOURAL_ANALYSIS.show(subnav, pkey);
        my.resetPasswdIfReqd();
      })
      .on("/mp/ba/:subnav", function (params) {
        APP.router.navigate(
          `/mp/ba/${params.subnav}/${PROJECT.currentProjectKey()}`
        );
        my.resetPasswdIfReqd();
      });

    /**
     * MODEL COMPARISON PAGE
     * PUG: templates/MPModelComparison.pug
     * JS: mp-model-comparison.js, test/model-comparison-test-data.js
     * MODULE: MP_MODEL_COMPARISON
     * SCSS: _mp-model-comparison.scss
     */
    my.router
      .on("mp/model-comparison/:pkey", function (params) {
        let pkey = params.pkey;
        if (!pkey) {
          pkey = PROJECT.currentProjectKey();
        } else {
          PROJECT.setCurrentProjectKey(pkey);
        }
        MP_MODEL_COMPARISON.show(pkey);
        my.resetPasswdIfReqd();
      })
      .on("/mp/model-comparison", function () {
        APP.router.navigate(
          `/mp/model-comparison/${PROJECT.currentProjectKey()}`
        );
        my.resetPasswdIfReqd();
      });

    /**
     * EXPLAINABLE AI GLOBAL PAGE
     * PUG: templates/MPEAIGlobal.pug
     * JS: mp-eai-global.js, test/eai-global-test-data.js
     * MODULE: MP_EAI_GLOBAL
     * SCSS: _mp-explainable-ai-global.scss
     */
    my.router
      .on("mp/eai/global/:pkey", function (params) {
        let pkey = params.pkey;
        if (!pkey) {
          pkey = PROJECT.currentProjectKey();
        } else {
          PROJECT.setCurrentProjectKey(pkey);
        }
        MP_EAI_GLOBAL.show(pkey);
        my.resetPasswdIfReqd();
      })
      .on("/mp/eai", function () {
        APP.router.navigate(`/mp/eai/global/${PROJECT.currentProjectKey()}`);
        my.resetPasswdIfReqd();
      })
      // eslint-disable-next-line no-unused-vars
      .on("/mp/eai/global", function (params) {
        APP.router.navigate(`/mp/eai/global/${PROJECT.currentProjectKey()}`);
        my.resetPasswdIfReqd();
      });

    /**
     * EXPLAINABLE AI LOCAL PAGE
     * PUG: templates/MPEAILocal.pug
     * JS: mp-eai-local.js, test/eai-local-test-data.js
     * MODULE: MP_EAI_LOCAL
     * SCSS: _mp-explainable-ai-local.scss
     */
    my.router
      .on("mp/eai/local/:pkey", function (params) {
        let pkey = params.pkey;
        if (!pkey) {
          pkey = PROJECT.currentProjectKey();
        } else {
          PROJECT.setCurrentProjectKey(pkey);
        }
        MP_EAI_LOCAL.show(pkey);
        my.resetPasswdIfReqd();
      })
      .on("/mp/eai", function () {
        APP.router.navigate(`/mp/eai/local/${PROJECT.currentProjectKey()}`);
        my.resetPasswdIfReqd();
      })
      // eslint-disable-next-line no-unused-vars
      .on("/mp/eai/local", function (params) {
        APP.router.navigate(`/mp/eai/local/${PROJECT.currentProjectKey()}`);
        my.resetPasswdIfReqd();
      });

    /**
     * AUTO CODE GENERATOR PAGE
     * PUG: templates/MDAutoCodeGenerator.pug
     * JS: md-auto-code.js, test/model-comparison-test-data.js
     * MODULE: AUTO_CODE
     * SCSS: _md-auto-code.js
     */
    my.router
      .on("/md/auto-code/:pkey", function (params) {
        let pkey = params.pkey;
        if (!pkey) {
          pkey = PROJECT.currentProjectKey();
        } else {
          PROJECT.setCurrentProjectKey(pkey);
        }
        AUTO_CODE.show(pkey);
        my.resetPasswdIfReqd();
      })
      .on("/md/auto-code", function () {
        APP.router.navigate(`/md/auto-code/${PROJECT.currentProjectKey()}`);
        my.resetPasswdIfReqd();
      });

    /**
     * MODEL SCORING PAGE
     * PUG: templates/MDScoreModels.pug
     * JS: md-score-models.js, test/md-score-models-test-data.js
     * MODULE: SCORE_MODELS
     * SCSS: _md-score-models.scss
     */
    my.router
      .on("/md/score-models/:pkey", function (params) {
        let pkey = params.pkey;
        if (!pkey) {
          pkey = PROJECT.currentProjectKey();
        } else {
          PROJECT.setCurrentProjectKey(pkey);
        }
        SCORE_MODELS.show(pkey);
        my.resetPasswdIfReqd();
      })
      .on("/md/score-models", function () {
        APP.router.navigate(`/md/score-models/${PROJECT.currentProjectKey()}`);
        my.resetPasswdIfReqd();
      });

    my.router.hooks({
      // eslint-disable-next-line no-unused-vars
      before: function (done, params) {
        APP.resetPollProgress();
        // Setting username in settings menu which appear on hovering over avatar icon.
        let userName = readCookie("userName");
        qs("#settings").style.display = "flex";
        if (
          !APP.getProperty("userManagement.enabled") &&
          userName !== null &&
          userName !== undefined &&
          userName !== ""
        ) {
          qs("#settings .menu .uname").innerText = userName;
        } else if (
          APP.getProperty("userManagement.enabled") &&
          userName !== null &&
          userName !== undefined &&
          userName !== ""
        ) {
          qs("#settings .uname").innerText = userName;
          qs("#settings").addClass("user-management-enabled");
        }
        my.resetProgress();
        if (!qs("#main-nav").hasClass("pinned")) my.hideNav();
        let p = null;
        if (!empty(params) && !empty(params.pkey)) {
          p = APP.ensureRemoteConfigsLoaded(params.pkey);
        } else {
          let pkey = null;
          try {
            pkey = PROJECT.currentProjectKey();
          } catch (err) {
            console.warn("No active project. Skipping project config loading");
          }
          if (!empty(pkey) && pkey != "BLANK") {
            p = APP.ensureRemoteConfigsLoaded(PROJECT.currentProjectKey());
          } else {
            p = APP.ensureServerConfigLoaded();
          }
        }
        p.then(function (status) {
          if (status) {
            PROJECT.currentProjectCustomizer().preRoute();
            done();
          } else {
            done(false);
          }
        });
      },
      // eslint-disable-next-line no-unused-vars
      after: function (params) {
        let route = STORE.here;
        if (
          route == "/" ||
          route.includes("/version-comparison") ||
          route.includes("/new-project") ||
          route.includes("/user-management")
        ) {
          APP.pageWithoutBreadcrumbAndNav();
        } else {
          APP.pageWithBreadcrumbAndNav();
        }
        if (route == "/sign-up") {
          qs("#page-container").style.display = "none";
          qs("#signUp-container").style.display = "block";
        } else {
          qs("#page-container").style.display = "grid";
          qs("#signUp-container").style.display = "none";
        }
        if (ML_LEADERBOARD.scrollLocation != 0) {
          let scrollBar = qsq("ml-table-outer-container");
          scrollBar.scrollTop = ML_LEADERBOARD.scrollLocation;
        }
        POLLING.continuePolling = false;
        my.setCurrentPageMarker();
        my.navigationUpdater();
        my.updateNavigationVisibility();
        let activeNav = qs("#main-nav a.selected");
        if (STORE.here == "/forget-password") {
          activeNav = { id: "forget-password" };
        }
        if (empty(activeNav) || !activeNav.id.includes("explore-data")) {
          my.dismissMessage();
        }
        if (activeNav && !activeNav.id.includes("ae-automodel")) {
          AE_AUTOMODEL.unload();
        }
        if (activeNav && !activeNav.id.includes("mp-mll")) {
          ML_LEADERBOARD.unload();
        }
        if (activeNav && !activeNav.id.includes("score-models")) {
          SCORE_MODELS.cancelPolling();
        }
        if (activeNav && !activeNav.id.includes("mp-eai-global")) {
          MP_EAI_GLOBAL.unload();
        }
        if (activeNav && !activeNav.id.includes("mp-eai-local")) {
          MP_EAI_LOCAL.unload();
        }
        if (activeNav && !activeNav.id.includes("auto-code")) {
          AUTO_CODE.unload();
        }
        if (activeNav && !activeNav.id.includes("help")) {
          HELP.unloadPage();
        }
        if (activeNav && !activeNav.id.includes("reset-password")) {
          RESET_PASSWORD.unloadPage();
        }
        if (activeNav && !activeNav.id.includes("security-policy")) {
          SECURITY_POLICY.unloadPage();
        }
        if (activeNav && !activeNav.id.includes("forget-password")) {
          FORGET_PASSWORD.unloadPage();
        }
        if (activeNav && !activeNav.id.includes("version-comparison")) {
          VERSION_COMPARISON.unloadPage();
        }
        if (activeNav && !activeNav.id.includes("mlleaderboard-segmentation")) {
          ML_LEADERBOARD_SEGMENTATION.unloadPage();
        }
        if (activeNav && !activeNav.id.includes("mlleaderboard-txt-mclass")) {
          ML_LEADERBOARD_TEXT_MULTI_CLASSIFICATION.unloadPage();
        }
        if (activeNav && !activeNav.id.includes("mp-ba")) {
          MP_BEHAVIOURAL_ANALYSIS.unloadPage();
        }
      },
      leave: my.resetCurrentPageMarker,
    });
  };

  /**
   * @method updateNavigationVisibility
   * @description Based on the current state of the UI, various links in the left
   * navigation are cherry-picked to be enabled or disabled. This is where it happens. This method looks
   * at the URL (actually the pageMarkerString) and enables and disables specific links in the left nav.
   * @public
   */
  my.updateNavigationVisibility = function () {
    let name = my.getCurrentPageMarkerString();
    let nav = qs("#main-nav");
    let currentProjectKey = false;
    try {
      currentProjectKey = PROJECT.currentProjectKey();
    } catch (err) {
      //do nothing
    }
    let currentProject = PROJECT.currentProject();
    nav.qsa(".disabled").forEach((x) => x.removeClass("disabled"));
    nav
      .qsa(
        "#navItem-dashboard-nav-link, #navGroupItem-user-management, #navGroupItem-connection, #navGroupItem-data-engineering, #navItem-explore-data-nav-link, #navGroupItem-ae, #navGroupItem-mp, #navItem-mp-mll-discrimination-nav-link, #navItem-mp-mll-calibration-nav-link, #navItem-mp-mll-fprAndPA-nav-link, #navItem-mp-mll-gainAnalysis-nav-link, #navItem-mp-mll-actualVsPredicted-nav-link, #navItem-mp-mll-residualsPlot-nav-link, #navItem-mp-mll-normality-nav-link, #navItem-mp-mll-predictedVsResidual-nav-link, #navGroupItem-md, #navItem-auto-code-nav-link, #navItem-score-models-nav-link, #navItem-mp-mll-pca2d-nav-link, #navItem-mp-mll-pca3d-nav-link, #navItem-mp-mll-predictionVsDensity-nav-link, #navItem-mlleaderboard-segmentation-featureSelection-nav-link, #navItem-mlleaderboard-segmentation-optimalSegmentSelection-nav-link, #navItem-mlleaderboard-segmentation-variableImportance-nav-link, #navItem-mlleaderboard-segmentation-modelPerformanceSegmentation-nav-link, #navItem-mlleaderboard-txt-mclass-modelPerformanceTextMultiClassification-nav-link, #navItem-mlleaderboard-segmentation-modelInterpretability-nav-link, #navItem-mlleaderboard-segmentation-clusterVisualization-nav-link,#navItem-mlleaderboard-segmentation-clusterMerging-nav-link,#navItem-mp-ba-personaCreation-nav-link,#navItem-mp-ba-insights-nav-link,#navGroupItem-mp-ba"
      )
      .forEach((x) => x.addClass("hidden"));

    const disableConnectionNav = function () {
      nav.qs("#navItem-db-connection-nav-link").addClass("disabled");
      nav.qs("#navItem-file-upload-nav-link").addClass("disabled");
    };

    const hideForSecurity = function () {
      nav.qs("#navGroupItem-connection").addClass("hidden");
      nav.qs("#navGroupItem-data-engineering").addClass("hidden");
      nav.qs("#navGroupItem-mp").addClass("hidden");
    };

    if (name.includes("dashboard")) {
      nav
        .qsa("#navItem-dashboard-nav-link")
        .forEach((x) => x.removeClass("hidden"));
    } else if (name.includes("connection") || name.includes("upload")) {
      nav
        .qsa("#navItem-dashboard-nav-link, #navGroupItem-connection")
        .forEach((x) => x.removeClass("hidden"));
    } else if (
      name.includes("validate-features") ||
      name.includes("workflow-start")
    ) {
      nav
        .qsa(
          "#navItem-dashboard-nav-link, #navGroupItem-connection, #navGroupItem-data-engineering"
        )
        .forEach((x) => x.removeClass("hidden"));
      disableConnectionNav();
    } else if (name.includes("explore-data")) {
      nav
        .qsa(
          "#navItem-dashboard-nav-link, #navGroupItem-connection, #navGroupItem-data-engineering, #navItem-explore-data-nav-link, #navGroupItem-ae"
        )
        .forEach((x) => x.removeClass("hidden"));
      disableConnectionNav();
      nav.qs("#navItem-validate-features-nav-link").addClass("disabled");
    } else if (name.includes("ae")) {
      nav
        .qsa(
          "#navItem-dashboard-nav-link, #navGroupItem-connection, #navGroupItem-data-engineering, #navItem-explore-data-nav-link, #navGroupItem-ae"
        )
        .forEach((x) => x.removeClass("hidden"));
      disableConnectionNav();
      nav.qs("#navItem-validate-features-nav-link").addClass("disabled");
      // if (STORE.getProjectData(PROJECT.currentProjectKey(), EXPLORE_DATA.STORE_KEY) === false){
      //   nav.qs("#navItem-explore-data-nav-link").addClass("disabled");
      // }
    } else if (name.includes("mp")) {
      nav
        .qsa(
          "#navItem-dashboard-nav-link, #navGroupItem-connection, #navGroupItem-data-engineering, #navItem-explore-data-nav-link, #navGroupItem-ae, #navGroupItem-mp, #navGroupItem-md, #navItem-auto-code-nav-link, #navItem-score-models-nav-link"
        )
        .forEach((x) => x.removeClass("hidden"));
      disableConnectionNav();
      nav.qs("#navItem-validate-features-nav-link").addClass("disabled");
      nav.qs("#navItem-ae-automodel-nav-link").addClass("disabled");
      // if (STORE.getProjectData(PROJECT.currentProjectKey(), EXPLORE_DATA.STORE_KEY) === false){
      //   nav.qs("#navItem-explore-data-nav-link").addClass("disabled");
      // }
    } else if (name.includes("md")) {
      nav
        .qsa(
          "#navItem-dashboard-nav-link, #navGroupItem-connection, #navGroupItem-data-engineering, #navItem-explore-data-nav-link, #navGroupItem-ae, #navGroupItem-mp, #navGroupItem-md, #navItem-auto-code-nav-link, #navItem-score-models-nav-link"
        )
        .forEach((x) => x.removeClass("hidden"));
      disableConnectionNav();
      nav.qs("#navItem-validate-features-nav-link").addClass("disabled");
      nav.qs("#navItem-ae-automodel-nav-link").addClass("disabled");
      // if (STORE.getProjectData(PROJECT.currentProjectKey(), EXPLORE_DATA.STORE_KEY) === false){
      //   nav.qs("#navItem-explore-data-nav-link").addClass("disabled");
      // }
    } else if (
      name.includes("help") ||
      name.includes("reset-password") ||
      name.includes("security-policy")
    ) {
      // Added below code till currentProjectKey to fix the navigation bug , as it was showing unnecessary list items.
      nav
        .qsa("#navItem-dashboard-nav-link, #navGroupItem-user-management")
        .forEach((x) => x.removeClass("hidden"));
      hideForSecurity();
      nav.qs("#navGroupItem-md").addClass("hidden");
      currentProjectKey = null;
    } else if (name.includes("forget-password")) {
      APP.nav.qs("#navItem-dashboard-nav-link").addClass("hidden");
      APP.nav.qs("#navGroupItem-ae").addClass("hidden");
      hideForSecurity();
      APP.nav.qs("#navGroupItem-user-management").addClass("hidden");
      CREDENTIALS.updateLoginLogoutLinks();
    }
    if (
      currentProjectKey &&
      (STORE.getProjectMetadata(
        currentProjectKey,
        PROJECT.currentProjVersion(),
        DB_CONNECTION.STORE_KEY + "_connected"
      ) ||
        STORE.getProjectMetadata(
          currentProjectKey,
          PROJECT.currentProjVersion(),
          FILE_UPLOAD.STORE_KEY + "_uploaded"
        ))
    ) {
      disableConnectionNav();
    }
    if (
      currentProjectKey &&
      STORE.getProjectMetadata(
        currentProjectKey,
        PROJECT.currentProjVersion(),
        VALIDATE_FEATURES.STORE_KEY + "_features_saved"
      )
    ) {
      nav.qs("#navItem-validate-features-nav-link").addClass("disabled");
    }
    if (
      currentProjectKey &&
      STORE.getProjectData(
        currentProjectKey,
        PROJECT.currentProjVersion(),
        EXPLORE_DATA.STORE_KEY
      )
    ) {
      nav
        .qsa("#navGroupItem-data-engineering, #navItem-explore-data-nav-link")
        .forEach((x) => x.removeClass("hidden"));
    }
    if (
      currentProjectKey &&
      (STORE.getProjectMetadata(
        currentProjectKey,
        PROJECT.currentProjVersion(),
        AE_AUTOMODEL.STORE_KEY + "_modeldev_complete"
      ) ||
        (!empty(currentProject) && currentProject.state > 4))
    ) {
      nav.qs("#navItem-ae-automodel-nav-link").addClass("disabled");
      nav
        .qsa(
          [
            "#navItem-dashboard-nav-link",
            "#navGroupItem-connection",
            "#navGroupItem-data-engineering",
            "#navItem-explore-data-nav-link",
            "#navGroupItem-ae",
            "#navGroupItem-mp",
            "#navGroupItem-md",
            "#navItem-auto-code-nav-link",
            "#navItem-score-models-nav-link",
          ].join(", ")
        )
        .forEach((x) => x.removeClass("hidden"));
      PROJECT.currentProjectCustomizer().postRoute(APP.router, { nav: nav });
    }
    my.updateBreadcrumb();
  };

  /**
   * @method createBreadCrumbNode
   * @description Create a new list-item to be inserted breadcrumb
   * @param {string} id the id to be assigned to the newly created link
   * @param {string} cls the class to be assigned to the newly created link
   * @param {string} text text for the anchor tag
   * @param {string} link the URL to the anchor tag
   * @return {Element} an <li> tag with a child anchor for insertion into the breadcrumb
   * @private
   */
  var createBreadCrumbNode = function (id, cls, text, link) {
    let templateLI = qs(
      "#breadcrumb-bar #breadcrumbItem-dashboard"
    ).cloneNode();
    templateLI.id = "breadcrumbItem-" + id;
    templateLI.setAttribute("class", cls ? cls : "");
    let templateA = qs(
      "#breadcrumb-bar #breadcrumbItem-dashboard a"
    ).cloneNode();
    if (link == "" || link == null || typeof link !== "string") {
      link = "javascript:(function(){})();";
    }
    templateA.id = id;
    templateA.setAttribute("href", link);
    templateA.setAttribute("class", "");
    templateA.innerText = text;
    templateLI.append(templateA);
    return templateLI;
  };

  /**
   * @method updateBreadcrumb
   * @description update the breadcrumb based on the current page location in the UI.
   * @return array of breadcrumb list-item nodes that were added to the breadcrumb
   */
  my.updateBreadcrumb = function () {
    const breadcrumbMap = BREADCRUMB_MAPPER.getBreadCrumbMap();
    let name = my.getCurrentPageMarkerString();
    let breadcrumbs = [];
    breadcrumbs.push(qs("#breadcrumb-bar #breadcrumbItem-dashboard"));
    if (breadcrumbMap[name]) {
      for (let element of breadcrumbMap[name].breadcrumb) {
        breadcrumbs.push(
          createBreadCrumbNode(
            element.id,
            element.class,
            element.text,
            element.link
          )
        );
      }
    }
    qs("#breadcrumb-bar ul").innerHTML = "";
    breadcrumbs.forEach((li) => qs("#breadcrumb-bar ul").append(li));
    return breadcrumbs;
  };
  return my;
})(APP || {});
