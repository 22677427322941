/**
 * @method polarToCartesian
 * @description Converts 2D polar coordinates (center, radius, angle) into cartesian coordinates (x,y). 
 * Used in the model dev dials and guages
 * @param {number} centerX 
 * @param {number} centerY 
 * @param {number} radius 
 * @param {number} angleInDegrees 
 * @return {object} Object `{x: xvalue, y: yvalue}` representing the cartesian coordinates corresponding to the input polar ones.
 */
function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
  let angleInRadians = (angleInDegrees-90) * Math.PI / 180.0;

  return {
    x: centerX + (radius * Math.cos(angleInRadians)),
    y: centerY + (radius * Math.sin(angleInRadians))
  };
}

/**
 * Create the `d` attribute of a `SVG` `path` that represents an arc with specified cartesian center, radius, start and
 * end angles.
 * @param {number} x 
 * @param {number} y 
 * @param {number} radius 
 * @param {number} startAngle 
 * @param {number} endAngle 
 * @return {string} the value for the said `d` attribute
 */
function describeArc(x, y, radius, startAngle, endAngle){
  let start = polarToCartesian(x, y, radius, endAngle);
  let end = polarToCartesian(x, y, radius, startAngle);
  let largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";
  let d = [
    "M", start.x, start.y, 
    "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
  ].join(" ");
  return d;       
}