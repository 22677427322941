/* global dialogPolyfill*/
/**
 * @module APP
 * @description Provides the application entrypoint and app level scope for 
 * methods that affect the whole application. This file contains helper functions to interact with the navigation, dialogs and 
 * the error indicator
 */

// eslint-disable-next-line no-extra-semi
;APP=(function(my){
  /**
   * @method showNav 
   * @description makes DOM changes to cause the left-hand navigation to become visible. Nav will float on top of 
   * content until pinned.
   * @fires Event Fires a custom event with name "shown" on the #main-nav DOM nav element after the it has been set visible.
   * @public
   */
  my.showNav=function(){
    const nav=qs("#main-nav");
    const trigger=qs("#trigger-nav");
    const page=qs("#page-container");
    if (!nav || !trigger || !page || "undefined" === typeof nav || "undefined" === typeof trigger || "undefined" === typeof page) return;
    nav.addClass("on");
    trigger.addClass("on");
    page.addClass("nav-open");
    nav.setAttribute("aria-hidden", "false");
    nav.fireCustomEvent("shown");
  };

  /**
   * @method hideNav 
   * @description makes DOM changes to cause the left-hand navigation to be hidden. 
   * @fires Event Fires a custom event with name "hidden" on the #main-nav DOM nav element after the it has been hidden.
   * @public
   */
  my.hideNav=function(){
    const nav=qs("#main-nav");
    const trigger=qs("#trigger-nav");
    const page=qs("#page-container");
    if (!nav || !trigger || !page || "undefined" === typeof nav || "undefined" === typeof trigger || "undefined" === typeof page) return;  
    my.unpinNav();
    nav.removeClass("on");
    trigger.removeClass("on");
    page.removeClass("nav-open");
    nav.setAttribute("aria-hidden", "true");
    nav.fireCustomEvent("hidden");
  };

  /**
   * @method pinNav 
   * @description makes DOM changes to pin the left-hand navigation. This will change the layout
   * so that other content is moved to the right of the navigation. Causes navigation to be shown if not already shown.
   * @fires Event Fires a custom event with name "pinned" on the #main-nav DOM nav element after the it has been pinned.
   * @public
   */
  my.pinNav = function(){
    const nav=qs("#main-nav");
    const pin=qs("#pin-nav");
    const page=qs("#page-container");
    const trigger = qs("#trigger-nav");
    trigger.style.display= "none";
    if (!nav || !pin || !page || "undefined" === typeof nav || "undefined" === typeof pin || "undefined" === typeof page) return;
    if (!nav.hasClass('on')) my.showNav();
    nav.addClass("pinned");
    pin.addClass("on");
    page.addClass("nav-pinned");
    nav.fireCustomEvent("pinned");
  };

  /**
   * @method unpinNav 
   * @description makes DOM changes to unpin the left-hand navigation. 
   * @fires Event Fires a custom event with name "unpinned" on the #main-nav DOM nav element after the it has been unpinned.
   * @public
   */
  my.unpinNav=function(){
    const nav=qs("#main-nav");
    const pin=qs("#pin-nav");
    const page=qs("#page-container");
    const trigger = qs("#trigger-nav");
    trigger.style.display="flex";
    if (!nav || !pin || !page || "undefined" === typeof nav || "undefined" === typeof pin || "undefined" === typeof page) return;
    nav.removeClass("pinned");
    pin.removeClass("on");
    page.removeClass("nav-pinned");
    nav.fireCustomEvent("unpinned");
  };

  my.pageWithoutNavBar = function(){
    const trigger = qs("#trigger-nav");
    my.unpinNav();
    my.hideNav();
    trigger.style.display="none";
  }
  my.pageWithoutBreadcrumbAndNav = function(){
    qs('#page-container').style = 'grid-template-rows: 5rem 0rem 1fr 1.8rem;'
    qs('#main').addClass("without-breadcrumb");
    my.pageWithoutNavBar();
  }
  my.pageWithBreadcrumbAndNav = function(){
    qs('#page-container').style = 'grid-template-rows: 5rem 1.8rem 1fr 1.8rem;'
    qs('#main').removeClass("without-breadcrumb");
    const trigger = qs("#trigger-nav");
    const pin=qs("#pin-nav");
    if(pin.hasClass("on")){
      trigger.style.display="none";  
    }
    else{
      trigger.style.display="flex";
    }
    my.pinNav();
    my.showNav();
  }
  /**
   * @method showMask 
   * @description The mask is a body>div#mask that when active is position: absolute and covers the browser
   * window. the mask DOM object has a count of how many of each type of masks is currently "up". This prevents 
   * the situation where a dialog takes down a progress mask when it is hidden or vice versa.
   * @param {string} type A named type of mask. Current values being used is "progress" and "dialog". This 
   * prevents multiple masks from being shown if multiple setProgress() is called multiple times or multiple dialogs
   * are shown. 
   * @public
   */
  my.showMask=function(type){
    if (!type || typeof type === "undefined"){
      return;
    }
    const mask=qs("#mask");
    if (!mask.hasClass("on")){mask.addClass('on');}
    if (typeof mask.dataset.maskUsers === "undefined" || mask.dataset.maskUsers == null){mask.dataset.maskUsers = "";}
    let maskUsers=mask.dataset.maskUsers.split(",");
    if (maskUsers.indexOf(type)<0){
      maskUsers.push(type);
      maskUsers=maskUsers.filter(x=>x.length>0);
      mask.addClass(type);
    }
    mask.dataset.maskUsers=maskUsers.filter(x=>x.length>0).join(",");
  }

  /**
   * @method hideMask 
   * @description hide a named mask.
   * @param {string} type A named type of mask. Current values being used is "progress" and "dialog". This 
   * prevents multiple masks from being shown if multiple setProgress() is called multiple times or multiple dialogs
   * are shown.
   * @public
   */
  my.hideMask=function(type){
    const mask=qs("#mask");
    if (typeof mask.dataset.maskUsers === "undefined" || mask.dataset.maskUsers == null){mask.dataset.maskUsers = "";}
    let maskUsers=mask.dataset.maskUsers.split(",");
    if (!type || typeof type === "undefined"){
      maskUsers.forEach(x=>my.hideMask(x));
      return;
    }
    if (maskUsers.indexOf(type)>=0){
      mask.removeClass(type);
      delete maskUsers[maskUsers.indexOf(type)];
      maskUsers=maskUsers.filter(x=>x.length>0);
      if (maskUsers.length === 0){
        mask.removeClass("on");
      }
      mask.dataset.maskUsers=maskUsers.join(",");
    }
  }

  /**
   * @method showDialog 
   * @description Causes a modal dialog object to be shown. ALso will throw up the page mask behind the dialog
   * @param {HTMLElement} dlg An HTMLDialogElement or a HTMLDivElement with class .box.
   * @public
   */
  my.showDialog=function(dlg, modal){
    const mask=qs("#mask");
    if (dlg.hasClass("dialog") && typeof HTMLDialogElement !== "function") {
      dialogPolyfill.registerDialog(dlg);
    }
    if (!modal || typeof modal === "undefined" ) {modal = false;}
    const activeDialogsContainer=qs("#dialogs-active");
    if (!dlg || !mask || !activeDialogsContainer || "undefined" === typeof dlg || "undefined" === typeof mask || "undefined" === typeof activeDialogsContainer) return;
    my.showMask("dialog");
    activeDialogsContainer.appendChild(dlg);
    activeDialogsContainer.addClass("on");
    activeDialogsContainer.setAttribute("current-dialog", dlg.id);
    if (modal && dlg.showModal) {
      dlg.showModal();
    } else if (dlg.show) {
      dlg.show();
    }
  };

  /**
   * @method hideDialog 
   * @description Hides then deletes a dialog/box element from the DOM. If it is a HTMLDialogElement DOM method dialog.hide() will be called on it.
   * @param {HTMLElement} dlg An HTMLDialogElement or a HTMLDivElement with class .box. 
   * @returns {HTMLElement} hidden dialog or box
   * @public
   */
  my.hideDialog=function(dlg){
    const mask=qs("#mask");
    const sleepingDialogsContainer=qs("#dialogs-sleeping");
    const activeDialogsContainer=qs("#dialogs-active");
    if (!dlg || !mask || !sleepingDialogsContainer || !activeDialogsContainer || "undefined" === typeof dlg || "undefined" === typeof mask || "undefined" === typeof sleepingDialogsContainer || "undefined" === typeof activeDialogsContainer) return;
    my.hideMask("dialog");
    activeDialogsContainer.removeClass("on");
    activeDialogsContainer.removeAttribute("current-dialog");
    sleepingDialogsContainer.appendChild(dlg);
    if (dlg.hide) dlg.hide();
    return dlg;
  };

  /**
   * @method closeAllDialogs
   * @description fires a click event on the close button of all dialogs that are currently active
   * @public
   */
  my.closeAllDialogs=function() {
    qsa("#dialogs-active .dialog button.close").forEach(closeButton => closeButton.fireCustomEvent("click"));
  };

  /**
   * @method showCriticalError 
   * @description Shows an critical error message in the global message location
   * @param {string} message The critical error message to be shown
   * @public
   */
  my.showCriticalError=function(message){showGlobalMessage(message, "critical");}

  /**
   * @method showError 
   * @description Shows an error message in the global message location
   * @param {string} message The error message to be shown
   * @public
   */
  my.showError=function(message){showGlobalMessage(message, "error");}

  /**
   * @method showInfo 
   * @description Shows an info message in the global message location
   * @param {string} message The info message to be shown
   * @public
   */
  my.showInfo=function(message){showGlobalMessage(message, "info");}

  /**
   * @method showWarning 
   * @description Shows an warning message in the global message location
   * @param {string} message The warning message to be shown
   * @public
   */
  my.showWarning=function(message){showGlobalMessage(message, "warning");}
  
  /**
   * @method showGlobalMessage 
   * @description Activates a global message just below the breadcrumb
   * @param {string} message the message to be shown in the message bar
   * @param {string} type one of "critical", "error", "warning", or "info"
   * @private
   */
  const showGlobalMessage=function(message, type){
    const msgBar=qs("#messageBar");
    if (!message || !msgBar || "undefined" === typeof message || "undefined" === typeof msgBar) return;
    msgBar.removeClass("critical,error,warning,info");
    msgBar.addClass(type);
    msgBar.qs(".content").innerHTML=message;
  };


  /**
   * @method dismissMessage 
   * @description Removes currently shown message in the error bar.
   * @public
   */
  my.dismissMessage=function(){
    const msgBar=qs("#messageBar");
    if (!msgBar || "undefined" === typeof msgBar) return;
    msgBar.removeClass("critical,error,warning,info");
    msgBar.qs(".content").innerHTML="";
  };

  /**
   * @method setProgress 
   * @description Show the progress animation with text
   * @param {string} text Progress bar text
   * @public
   */
  my.setProgress=function(text, showMask){
    if (showMask==null || typeof showMask == "undefined"){showMask=true;}
    if (showMask){
      my.showMask("progress");
    }
    qs("body").addClass("progress-on");
    qs("#footer #progress-message").innerText=text;
    qs("#footer #progress").addClass("on");
  };

// Added below function

  my.setPollProgress=function(text, showMask){
    if (showMask==null || typeof showMask == "undefined"){showMask=true;}
    if (showMask){
      my.showMask("progress");
    }
    qs("#breadcrumb-bar").addClass("progress-on");
    qs("#main").addClass("progress-on");
    qs("#footer #progress-message").innerText=text;
    qs("#footer #progress").addClass("on");
  };

  /**
   * @method resetProgress 
   * @description Hide the progress animation and reset the text.
   * @public
   */
  my.resetProgress=function(){
    my.hideMask("progress");
    qs("body").removeClass("progress-on");
    qs("#footer #progress-message").innerText="";
    qs("#footer #progress").removeClass("on");
  };

  // Added below function

  my.resetPollProgress=function(){
    my.hideMask("progress");
    qs("#breadcrumb-bar").removeClass("progress-on");
    qs("#main").removeClass("progress-on");
    qs("#footer #progress-message").innerText="";
    qs("#footer #progress").removeClass("on");
  };

  /**
   * @method resetCurrentPageMarker 
   * @description removes the attribute that identifies the content of the current page.
   * Used in showing the correct watermark on the page.
   * @public
   */
  my.resetCurrentPageMarker=function(){
    qs("#main-content").removeAttribute("current-page-marker");
  };

  /**
   * @method setCurrentPageMarker 
   * @description sets the attribute that identifies the content of the current page.
   * Used in showing the correct watermark on the page.
   * @param name identifier for the current page
   * @public
   */
  my.setCurrentPageMarker=function(name){
    if (!name || typeof name == "undefined"){
      name = my.getCurrentPageMarkerString();
    }
    qs("#main-content").setAttribute("current-page-marker", name);
  };

  /**
   * @method getCurrentPageMarkerString 
   * @description creates an ID by replacing the / in the URL by _ and then taking the
   * first token. Some special cases also exist because these are dialog destinations and don't have their own
   * URLs.
   * @return {string} a unique ID for the current page, the foo in https://server/#/foo/bar
   * @public
   */
  my.getCurrentPageMarkerString=function(){
    let link=my.getCurrentPageLinkNode();
    if (!link){
      if (STORE.here.includes("/new-project")) {return "new-project";}
      else if (STORE.here.includes("/workflow-start")) {return "workflow-start";}
      else if (STORE.here.includes("/forget-password")) {return "forget-password";}
      else if (STORE.here.includes("/version-comparison")) {return "version-comparison";}
      else if(STORE.here.includes("/sign-up")) {return "sign-up";}
      else if(STORE.here.includes("/user-management")) {return "user-management";}
      else if(STORE.here.includes("/mlleaderboard-segmentation")) {return "mlleaderboard-segmentation"}
    }
    let name = my.getCurrentPageLinkNode().getAttribute("to").replace(/\//g, '_');
    if (name.startsWith("_")){name=name.slice(1);}
    if (name.length==0){name="dashboard";}
    return name;
  }

  /**
   * @method getCurrentPageLinkNode 
   * @description Find the link in the left nav corresponding to the current page
   * @return {Element} DOM node corresponding to the anchor tag with the href pointing to the current page
   * @public
   */
  my.getCurrentPageLinkNode=function(){
    let nav=qs("#main-nav");
    return APP_HELPER.getLink(nav);
  };

  /**
   * @method getCurrentPageEndToken 
   * @description In pages with subsections we sometimes have generalized ways of doing 
   * things (handling graphs for example) but need to use the name of the section as a key in look up maps 
   * The page URL has a convenient way of getting this ID as the last token in the URL path.
   * @return {string} returns bar from https://server/#/foo/bar
   * @public
   */
  my.getCurrentPageEndToken=function(){
    let link=my.getCurrentPageLinkNode();
    if (link){
      let to=link.getAttribute('to');
      to=to.split("/");
      return to[to.length-1];
    }
    return "";
  };

  /**
   * @method wait
   * @description wait for `ms` milli-seconds. use by  using `await wait(1000);`
   * @returns {Promise} A promise that resolves in ms milliseconds
   * @param {Number} ms Number of milli-seconds to wait
   * @public
   */
  my.wait=async function(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  /**
   * @method noop 
   * @description an empty function.
   */
  my.noop=function(){};
  return my;
}(APP || {}));
