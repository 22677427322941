/* global CHART */
/**
 * @module RESIDUALS_CHART
 * @description draws the Residuals Plot chart shown on the `mp/mll/residualsPlot` page. Implements the 
 * [`CHART`](module-CHART.html) interface.
 */
// eslint-disable-next-line no-extra-semi
;var RESIDUALS_CHART=(function(my){
  my.type="residuals";

  my.optsGenerator=function(options){
    let opts=extend({},CHART.opts);

    delete opts.axis.y.min;
    delete opts.axis.y.max;
    delete opts.axis.y.tick.values;
    delete opts.axis.x.min;
    delete opts.axis.x.max;
    delete opts.axis.x.tick.values;
    
    delete opts.axis.x.label.text;
    
    opts.bindto="#residualsChart .graph";
    // opts.axis.y.padding={top: 5, bottom: 5};
    // opts.axis.x.padding={left: 5, right: 5};
    opts.axis.y.tick.format=d3.format(".1f");
    opts.axis.x.tick.format=d3.format(".1f");
    opts.axis.y.tick.culling=true;
    opts.axis.x.tick.culling=true;
    opts.axis.y.label.text="Density";
    opts.axis.x.label.text="Residual";
    opts.id=my.type;
    opts.data={
      xs:{
        Density: "x1",
      },
      columns: [
        ['x1'].concat(options.data.y1.keys),
        ['Density'].concat(options.data.y1.values),
      ],
      additionalData: options.data.additionalData,
      type: 'bar',
      colors: {
         Density: CHART.colors[2],
      }
    };
    opts.bar={
      space: -0.01
    };
    opts.legend={
      show: false,
    };
    opts.grid={
      x:{show: false},
      y:{show: true},
    };
    opts.point={
      show: false,
    }
    opts.size={
      width: qs("#residualsChart").offsetWidth -30,
      height: qs("#residualsChart").offsetHeight - qs("#residualsChart h4").offsetHeight - 22
    };
    const dec3Format=d3.format("0.3f");
    opts.tooltip={
      contents: function(d, defaultTitleFormat, defaultValueFormat, color) {
        if (d[0].id === "Density") {
          return `<table class='c3-tooltip'>
                    <tr class='c3-tooltip-name--x}'>
                      <td class='name'><span style='background-color:${color(d[0].id)}'></span>Residual</td>
                      <td class='value'>${dec3Format(d[0].x)}</td>
                    </tr>
                    <tr class='c3-tooltip-name--${d[0].id}'>
                      <td class='name'><span style='background-color:transparent'></span>Density</td>
                      <td class='value'>${defaultValueFormat(d[0].value, null, d[0].id)}</td>
                    </tr>
                  </table>`
        }
      },
      horizontal: true
    };

    if (!empty(options.c3d3properties)){
      opts=extend(opts, options.c3d3properties);
    }
    
    return opts;
  };
  my.generate=function(options){
    let opt=my.optsGenerator(options);
    CHART.charts[opt.id]=c3.generate(opt);
    return CHART.charts[opt.id];
  }

  return my;
}(RESIDUALS_CHART || {}));