var VERSION_COMPARISON = (function (my) {
  var compCols = {
    columns: [
      {
        label: "Silhouette Score",
        "data-key": "silhouette",
        "additional-tags": ["number", "percent"],
      },
      {
        label: "Accuracy",
        "data-key": "acc",
        "additional-tags": ["number", "percent"],
      },
      {
        label: "Adjusted Rand Index",
        "data-key": "adjRandIdx",
        "additional-tags": ["number"],
      },
      {
        label: "Mutual Information Score",
        "data-key": "miScore",
        "additional-tags": ["number"],
      },
    ],
  };
  var dropdownVersionList = [];
  var graphdata = [];
  var generated = false;
  var dataSource = "train";
  var calcMethod = "DFI";
  let charts = {};
  var modelID;
  var modelIDs = [];
  var maxTableSize = 0;
  var isRegressionModel;
  var isSegmentationModel;
  var widgetList = [];
  var project;
  var compData;
  var versionList;
  var mcConfig;

  var gainAndCalibDataSource = "train";
  var gainAndCalibList = [];
  var segmentationDataList = [];

  var resetValues = function () {
    graphdata = [];
    generated = false;
    dataSource = "train";
    calcMethod = "DFI";
    charts = {};
    modelID = null;
    modelIDs = [];
    maxTableSize = 0;
    isRegressionModel = null;
    isSegmentationModel = null;
    gainAndCalibDataSource = "train";
    gainAndCalibList = [];
    segmentationDataList = [];
    dropdownVersionList = [];
  };

  var getValues = function (id) {
    let result = [];
    let collection = document.querySelectorAll("#" + id + " option");
    collection.forEach(function (x) {
      if (x.selected) {
        result.push(x.value);
      }
    });
    return result;
  };

  /**
   * @method registerListeners
   * @description Register listeners for:
   * * click
   * * back button
   * * versions dropdown
   * @private
   */
  var registerListeners = function () {
    qs("#version-comparison-main")
      .qs(".back-container")
      .addEventListener("click", (evt) => {
        APP.router.navigate("/");
      });
    qsa(".version-card-container table").forEach((x) =>
      x.addEventListener("click", (evt) => {
        if (evt.target.className.includes("name")) {
          let versionSelected;
          modelID = evt.target.parentElement.id;
          versionSelected =
            evt.target.parentElement.parentElement.parentElement.getAttribute(
              "index"
            );
          gainAndCalibDataSource = evt.target.getAttribute("data-source");

          qsa(`.training-card-${versionSelected} table tr`).forEach((x) =>
            x.removeClass("selected")
          );
          qsa(`.validation-card-${versionSelected} table tr`).forEach((x) =>
            x.removeClass("selected")
          );

          evt.target.parentElement.addClass("selected");

          APP.setProgress("Populating data...");
          if (widgetList.includes("global-interpretability")) {
            requestAnimationFrame(
              generateGraphs.bind(
                this,
                graphdata[versionSelected],
                versionSelected
              )
            );
          }
          //renders graphs and tables for segmentation model
          if (isSegmentationModel) {
            renderVariableCountGraph(
              segmentationDataList[versionSelected],
              versionSelected
            );
            renderElbowGraph(
              segmentationDataList[versionSelected],
              versionSelected
            );
            renderSilhouetteGraph(
              segmentationDataList[versionSelected],
              versionSelected
            );
            renderConfusionMatrix(
              segmentationDataList[versionSelected],
              versionSelected
            );
            renderRadarChart(
              segmentationDataList[versionSelected],
              versionSelected
            );
          } else if (!isRegressionModel) {
            renderGainAnalysisView(
              gainAndCalibList[versionSelected],
              versionSelected
            );
            // renderCalibrationView(gainAndCalibList[versionSelected], versionSelected);
            renderRocCurveView(
              gainAndCalibList[versionSelected],
              versionSelected
            );
            renderKsGraphView(
              gainAndCalibList[versionSelected],
              versionSelected
            );
          } else {
            renderActualVsPredictedView(
              gainAndCalibList[versionSelected],
              versionSelected
            );
          }
          APP.resetProgress();
        }
      })
    );
    qs(".dropdown #select-box").addEventListener("change", (evt) => {
      const newVersionList = getValues("select-box");
      let selectedProjectKeyForComparison =
        DASHBOARD.selectedProjectKeyForComparison;
      let projects = DASHBOARD.projects;
      let project = projects.find(
        (v) => v.projectkey == selectedProjectKeyForComparison
      );
      project.selectedVersion = newVersionList;
      my.show();
    });
  };

  /**
   * @method getCurrentModelID
   * @description method to get current model ID
   * @private
   */
  const getCurrentModelID = function (versionGraphdata, index) {
    let currentModelId;
    // for gainAnaysis and calibration
    if (!versionGraphdata.modelFiInfo) {
      Object.keys(versionGraphdata).forEach((x) => {
        if (x.toLowerCase() == modelID) {
          currentModelId = x;
        }
      });
    }
    // for global interpretability
    else if (widgetList.includes("global-interpretability")) {
      Object.keys(versionGraphdata.modelFiInfo).forEach((x) => {
        if (x.toLowerCase() == modelID) {
          currentModelId = x;
        }
      });
    }
    if (!currentModelId) {
      modelID = qs(
        `.training-card-${index} .tableContainer tbody tr:nth-child(2)`
      ).id;
      return modelID.toUpperCase();
    }
    return currentModelId.toUpperCase();
  };

  /**
   * @method loadGainAndCalibData
   * @description Loads the data of gain and calibration
   * @private
   */
  var loadGainAndCalibData = async function (iparams) {
    // Just for testing purpose, because I don't have any anomaly project in backend.
    // if (!useTestData && PROJECT.currentProjectKey() == "24QQC7OW9ICT"){
    if (useTestData) {
      let result = await MP_TEST_DATA.getData();
      return result;
    }
    return API_HELPER.getResult("plot", iparams);
  };

  /**
   * @method getGainAndCalibData
   * @description method to get the data of  gain and calibration
   * @public
   */
  my.getGainAndCalibData = async function (projectkey, versionList) {
    gainAndCalibList = [];
    for (version of versionList) {
      let projVersion = version;
      var result = await Promise.all([
        loadGainAndCalibData({
          projectKey: projectkey,
          projVersion: projVersion,
        }),
        APP.loadAvailableSources({
          projectKey: projectkey,
          projVersion: projVersion,
          showProgress: false,
        }),
      ]);
      gainAndCalibData = result[0];
      sources = result[1].data.posts[1].OutOfTime;
      STORE.setProjectData(
        projectkey,
        projVersion,
        ML_LEADERBOARD.STORE_KEY,
        gainAndCalibData
      );
      STORE.setProjectMetadata(
        projectkey,
        projVersion,
        ML_LEADERBOARD.STORE_KEY + "_graphdata_loaded",
        true
      );
      gainAndCalibList.push(gainAndCalibData);
    }
    return gainAndCalibList;
  };

  /**
   * @method loadSegmentationData
   * @description Loads the data for segmentation
   * @private
   */
  const loadSegmentationData = async function (iparams) {
    if (useTestData) {
      let result = await SEGMENTATION_LEADERBOARD_DATA.getData();
      return result;
    }
    return API_HELPER.getResult("plot", iparams);
  };

  /**
   * @method getSegmentationLeaderboardData
   * @description To get leaderboard data for segmentation
   * @public
   */
  my.getSegmentationLeaderboardData = async function (projectkey, versionList) {
    segmentationDataList = [];
    for (version of versionList) {
      var result = await loadSegmentationData({
        projectKey: projectkey,
        projVersion: version,
      });
      segmentationDataList.push(result);
    }
    return segmentationDataList;
  };

  /**
   * @method generateGraphs
   * @description method to generate Feature Importance graph
   * @private
   */
  const generateGraphs = function (versionGraphdata, index) {
    // APP.setProgress("Rendering plot...", true);
    let modelID = getCurrentModelID(versionGraphdata, index);
    requestAnimationFrame(() => {
      charts[FEATURE_IMPORTANCE_CHART.type] = FEATURE_IMPORTANCE_CHART.generate(
        {
          method: calcMethod,
          data: {
            y1: {
              name: "y",
              keys: versionGraphdata.modelFiInfo[modelID].fiInfo[calcMethod][
                dataSource
              ].features,
              values:
                versionGraphdata.modelFiInfo[modelID].fiInfo[calcMethod][
                  dataSource
                ].scores,
            },
          },
          c3d3properties: {
            // onrendered: APP.resetProgress
          },
        },
        index
      );
    });
    qs(`.global-interpretability-card-${index} #versionFeatureImportanceChart`)
      .removeClass("DFI,PFI")
      .addClass(calcMethod);
    generated = true;
  };

  /**
   * @method redrawGraphs
   * @description method to resize graphs
   * @public
   */
  my.redrawGraphs = function (index) {
    CHART.allowedCharts["calibration"].forEach((x) => {
      if (empty(charts[x])) return;
      let chartElement = charts[x].element;
      if (charts[x].isD3Chart) {
        chartElement = charts[x].element.node();
      }
      if (!chartElement.closest(`.graphs-outer-area`).hasClass("active")) {
        return;
      }
      // let outerContainer = chartElement.closest(`.calibration-card-${index} .graph-outer-container`);
      charts[x].resize({
        width: 632,
        height: 373,
      });
    });
  };

  /**
   * @method renderActualVsPredictedView
   * @description method to render ActualVsPredicted graph
   * @private
   */
  const renderActualVsPredictedView = function (
    versionActualVsPredictedData,
    index
  ) {
    let modelID = getCurrentModelID(versionActualVsPredictedData, index);
    // APP.setProgress("Rendering plot...", true);
    requestAnimationFrame(() => {
      charts[ACTUAL_VS_PREDICTED_CHART.type] =
        ACTUAL_VS_PREDICTED_CHART.generate(
          {
            data: {
              y1: {
                name: "ActualVsPredicted",
                values:
                  versionActualVsPredictedData[modelID][gainAndCalibDataSource]
                    .ActualPredicted.y,
                keys: versionActualVsPredictedData[modelID][
                  gainAndCalibDataSource
                ].ActualPredicted.x,
              },
              reference: {
                name: "Reference",
                keys: [
                  versionActualVsPredictedData[modelID][gainAndCalibDataSource]
                    .ActualPredicted.min_RegLine[0],
                  versionActualVsPredictedData[modelID][gainAndCalibDataSource]
                    .ActualPredicted.max_RegLine[0],
                ],
                values: [
                  versionActualVsPredictedData[modelID][gainAndCalibDataSource]
                    .ActualPredicted.min_RegLine[1],
                  versionActualVsPredictedData[modelID][gainAndCalibDataSource]
                    .ActualPredicted.max_RegLine[1],
                ],
              },
              additionalData: {
                R2: versionActualVsPredictedData[modelID][
                  gainAndCalibDataSource
                ].ActualPredicted.R2,
                RMSE: versionActualVsPredictedData[modelID][
                  gainAndCalibDataSource
                ].ActualPredicted.RMSE,
                MAE: versionActualVsPredictedData[modelID][
                  gainAndCalibDataSource
                ].ActualPredicted.MAE,
              },
            },
            c3d3properties: {
              // onrendered: APP.resetProgress
            },
          },
          index
        );
      generated = true;
    });
  };

  /**
   * @method renderKsGraphView
   * @description method to render KS graph
   * @private
   */
  const renderKsGraphView = function (versionKsData, index) {
    let modelID = getCurrentModelID(versionKsData, index);
    requestAnimationFrame(() => {
      charts[KS_CHART.type] = KS_CHART.generate(
        {
          data: {
            score: versionKsData[modelID][gainAndCalibDataSource].KS.score,
            y1: {
              name: "y",
              values: versionKsData[modelID][gainAndCalibDataSource].KS.yPoints,
              keys: versionKsData[modelID][gainAndCalibDataSource].KS.xPoints,
            },
            y2: {
              name: "ydash",
              values:
                versionKsData[modelID][gainAndCalibDataSource].KS.yDashPoints,
              keys: versionKsData[modelID][gainAndCalibDataSource].KS
                .xDashPoints,
            },
          },
        },
        index
      );
    });
  };

  /**
   * @method renderRocCurveView
   * @description method to render RocCurve graph
   * @private
   */
  const renderRocCurveView = function (versionRocData, index) {
    let modelID = getCurrentModelID(versionRocData, index);
    requestAnimationFrame(() => {
      charts[ROC_CHART.type] = ROC_CHART.generate(
        {
          data: {
            y1: {
              name: "ROC",
              values:
                versionRocData[modelID][gainAndCalibDataSource].Roc.yPoints,
              keys: versionRocData[modelID][gainAndCalibDataSource].Roc.xPoints,
            },
            auc: versionRocData[modelID][gainAndCalibDataSource].Roc.score,
          },
        },
        index
      );
    });
  };

  /**
   * @method renderCalibrationView
   * @description method to render Calibration graph
   * @private
   */
  const renderCalibrationView = function (versionGainAndCalibData, index) {
    let modelID = getCurrentModelID(versionGainAndCalibData, index);
    charts[CALIBRATION_CHART.type] = CALIBRATION_CHART.generate(
      {
        data: {
          y1: {
            name: "Reliability",
            values:
              versionGainAndCalibData[modelID][gainAndCalibDataSource]
                .CalibrationInfo.yPoints,
            keys: versionGainAndCalibData[modelID][gainAndCalibDataSource]
              .CalibrationInfo.xPoints,
          },
          score:
            versionGainAndCalibData[modelID][gainAndCalibDataSource]
              .CalibrationInfo.score,
          scoreName: modelID,
        },
      },
      index
    );
    generated = true;
    requestAnimationFrame(function () {
      my.redrawGraphs(index);
      d3.select(".c3-legend-item-Reliability text").text(
        `${versionGainAndCalibData[modelID].name}, score=${d3.format(".3f")(
          versionGainAndCalibData[modelID][gainAndCalibDataSource]
            .CalibrationInfo.score
        )}`
      );
    });
  };

  /**
   * @method renderGainAnalysisView
   * @description method to render Gain Analysis graph
   * @private
   */
  const renderGainAnalysisView = function (versionGainAndCalibData, index) {
    let modelID = getCurrentModelID(versionGainAndCalibData, index);
    let liftAnalysisData = null;
    liftAnalysisData = extend(
      {},
      versionGainAndCalibData[modelID][gainAndCalibDataSource].GainInfo
    );
    delete liftAnalysisData.status;
    const table = LIFT_ANALYSIS.generate({ data: liftAnalysisData }, index);
  };

  /**
   * @method renderGlobalInterpView
   * @description method to render global interpretability graph
   * @private
   */
  const renderGlobalInterpView = function () {
    graphdata.forEach((versionGraphdata, index) => {
      qsa(`.global-interpretability-card-${index} #graph-area .active`).forEach(
        (x) => x.removeClass("active")
      );
      qs(
        `.global-interpretability-card-${index} #graph-area #version-global-interpretability`
      ).addClass("active");
      generated = false;
      requestAnimationFrame(generateGraphs.bind(this, versionGraphdata, index));
    });
  };

  const sortGraphData = function (sortableData) {
    if (empty(sortableData)) {
      throw new Error("No graphdata to sort.");
    }

    const pairs = [];
    for (let i = 0; i < sortableData.clusters.length; i++) {
      pairs.push({
        cluster: sortableData.clusters[i],
        score: sortableData.varcount[i],
      });
    }
    pairs.sort(function (a, b) {
      return b.score - a.score;
    });
    for (let i = 0; i < pairs.length; i++) {
      sortableData.clusters[i] = pairs[i].cluster;
      sortableData.varcount[i] = pairs[i].score;
    }
    return sortableData;
  };

  /**
   * @method renderVariableCountGraph
   * @description method to render the variable count graph
   * @private
   */
  const renderVariableCountGraph = function (versionSegmentationData, index) {
    let modelID = getCurrentModelID(versionSegmentationData, index);
    sideBarData = {};
    sideBarData["clusters"] = [];
    sideBarData["varcount"] = [];
    let clustersInfo =
      versionSegmentationData[modelID][gainAndCalibDataSource].varClusInfo
        .clusInfo;
    for (let cluster of Object.keys(
      versionSegmentationData[modelID][gainAndCalibDataSource].varClusInfo
        .clusInfo
    )) {
      sideBarData.clusters.push(cluster);
      sideBarData.varcount.push(clustersInfo[cluster].length);
    }
    sideBarData = sortGraphData(sideBarData);
    var clustersArray = sideBarData.clusters.slice(0, 10);
    var varcountArray = sideBarData.varcount;
    requestAnimationFrame(() => {
      if (charts[FEATURE_IMPORTANCE_CHART.type]) {
        if (charts[FEATURE_IMPORTANCE_CHART.type].constructor !== Array) {
          charts[FEATURE_IMPORTANCE_CHART.type] = [
            charts[FEATURE_IMPORTANCE_CHART.type],
          ];
        }
        charts[FEATURE_IMPORTANCE_CHART.type].push(
          FEATURE_IMPORTANCE_CHART.generate(
            {
              data: {
                y1: {
                  name: "y",
                  keys: clustersArray,
                  values: varcountArray,
                },
              },
              c3d3properties: {},
            },
            index,
            "featureSelection"
          )
        );
      } else {
        charts[FEATURE_IMPORTANCE_CHART.type] =
          FEATURE_IMPORTANCE_CHART.generate(
            {
              data: {
                y1: {
                  name: "y",
                  keys: clustersArray,
                  values: varcountArray,
                },
              },
              c3d3properties: {},
            },
            index,
            "featureSelection"
          );
      }
    });
  };

  /**
   * @method renderElbowGraph
   * @description method to render the elbow graph
   * @private
   */
  const renderElbowGraph = function (versionSegmentationData, index) {
    let modelID = getCurrentModelID(versionSegmentationData, index);
    const elbowChartData =
      versionSegmentationData[modelID][gainAndCalibDataSource].elbowInfo;
    let elbowYpoints = [];
    let elbowXpoints = [];
    elbowChartData.yPoints.forEach((yPoint) =>
      elbowYpoints.push(Math.round(yPoint))
    );
    elbowChartData.xPoints.forEach((xPoint) =>
      elbowXpoints.push(Math.round(xPoint))
    );
    requestAnimationFrame(() => {
      charts[LIFT_CHART.type] = LIFT_CHART.generate(
        {
          data: {
            y1: {
              name: "y",
              values: elbowYpoints,
              keys: elbowXpoints,
            },
          },
          c3d3properties: {},
        },
        index,
        "elbowChart"
      );
    });
  };

  /**
   * @method renderRadarChart
   * @description method to render the radar chart
   * @private
   */
  const renderRadarChart = function (versionSegmentationData, index) {
    let modelID = getCurrentModelID(versionSegmentationData, index);
    const radarChartData =
      versionSegmentationData[modelID][gainAndCalibDataSource].clusterVarImp;

    var config = {
      w: 350,
      h: 350,
      facet: false,
      levels: 5,
      levelScale: 0.85,
      labelScale: 0.9,
      facetPaddingScale: 2.1,
      showLevels: true,
      showLevelsLabels: false,
      showAxesLabels: true,
      showAxes: true,
      showLegend: true,
      showVertices: true,
      showPolygons: true,
    };

    function createGraphData(clusterData) {
      let data = [];
      for (let i = 0; i < clusterData.clusterNames.length; i++) {
        const groupObj = {
          group: clusterData.clusterNames[i],
          axes: [],
        };
        for (let j = 0; j < 20 && j < clusterData.featureNames.length; j++) {
          groupObj.axes.push({
            axis: clusterData.featureNames[j],
            value: clusterData.varImpInfo[i][j],
            description: "",
          });
        }
        data.push(groupObj);
      }
      return data;
    }

    function drawGraph() {
      var element = document.getElementById(`radar-chart-${index}`);
      var graphData = createGraphData(radarChartData);
      SegmentationRadarChart.draw(element, graphData, config);
    }

    drawGraph();
  };

  /**
   * @method renderSilhouetteGraph
   * @description method to render the silhouette graph
   * @private
   */
  const renderSilhouetteGraph = function (versionSegmentationData, index) {
    let modelID = getCurrentModelID(versionSegmentationData, index);
    const silhouetteData =
      versionSegmentationData[modelID][gainAndCalibDataSource].silhouetteInfo;
    requestAnimationFrame(() => {
      if (charts[FEATURE_IMPORTANCE_CHART.type]) {
        if (charts[FEATURE_IMPORTANCE_CHART.type].constructor !== Array) {
          charts[FEATURE_IMPORTANCE_CHART.type] = [
            charts[FEATURE_IMPORTANCE_CHART.type],
          ];
        }
        charts[FEATURE_IMPORTANCE_CHART.type].push(
          FEATURE_IMPORTANCE_CHART.generate(
            {
              data: {
                y1: {
                  name: "y",
                  keys: silhouetteData.xPoints,
                  values: silhouetteData.yPoints,
                },
              },
              c3d3properties: {},
            },
            index,
            "silhouetteGraph"
          )
        );
      } else {
        charts[FEATURE_IMPORTANCE_CHART.type] =
          FEATURE_IMPORTANCE_CHART.generate(
            {
              data: {
                y1: {
                  name: "y",
                  keys: silhouetteData.xPoints,
                  values: silhouetteData.yPoints,
                },
              },
              c3d3properties: {},
            },
            index,
            "silhouetteGraph"
          );
      }
    });
  };

  /**
   * @method formatConfusionMatrixData
   * @description method to format confusion matrix data
   * @private
   */
  const formatConfusionMatrixData = function (confMatrixData) {
    let formattedConfMatrixData = [];
    for (let i = 0; i < confMatrixData.cfMatrix.length; i++) {
      let intermediateObj = {};
      for (let j = 0; j < confMatrixData.clusterNames.length; j++) {
        intermediateObj[confMatrixData.clusterNames[j]] =
          confMatrixData.cfMatrix[i][j];
      }
      formattedConfMatrixData.push(intermediateObj);
    }
    return formattedConfMatrixData;
  };

  /**
   * @method renderConfusionMatrix
   * @description method to render the confusion matrix table
   * @private
   */
  const renderConfusionMatrix = function (versionSegmentationData, index) {
    let modelID = getCurrentModelID(versionSegmentationData, index);
    let formattedConfMatrix = formatConfusionMatrixData(
      versionSegmentationData[modelID][gainAndCalibDataSource].confusionMatrix
    );
    const confusionMatrix = qs(
      `.confusion-matrix-card-${index} .confusion-matrix`
    );
    confusionMatrix.innerHTML = "";
    confusionMatrix.appendChild(
      createNode(
        segmentationtableTemplate({
          data: formattedConfMatrix,
          headerText: ["Actual/Prediction"].concat(
            Object.keys(formattedConfMatrix[0])
          ),
          headerKeys: Object.keys(formattedConfMatrix[0]),
          isConfusionMatrix: true,
        })
      )
    );
  };

  /**
   * @method fetchGlobalInterpData
   * @description method to fetch global interpretability data
   * @public
   */
  my.fetchGlobalInterpData = async function (projectkey, versionList) {
    graphdata = [];
    for (version of versionList) {
      let graphsData = STORE.getProjectData(
        projectkey,
        version,
        MP_EAI_GLOBAL.STORE_KEY
      );
      if (!graphsData) {
        let result = await GLOBAL_AND_LOCAL_INTERP.pollForData(
          "globinterp",
          null,
          3,
          projectkey,
          version,
          true
        );
        graphsData = result.data.posts[0];
        STORE.setProjectData(
          projectkey,
          version,
          MP_EAI_GLOBAL.STORE_KEY,
          graphsData
        );
        STORE.setProjectMetadata(
          projectkey,
          version,
          MP_EAI_GLOBAL.STORE_KEY + "_graphdata_loaded",
          true
        );
      }
      graphdata.push(graphsData);
    }
    return graphdata;
  };

  /**
   * @method getModelComparisonData
   * @description method to get model comparison data
   * @public
   */
  my.getModelComparisonData = async function (projectkey, versionList) {
    let trainingAndValidationData = [];
    for (version of versionList) {
      let url = SERVER.getBaseAddress() + "comp";
      let userHash = CREDENTIALS.getUserCreds();
      if (userHash == null) {
        throw new Error(i18n.en.APP.UI.ERROR.MODELCFG.USER_NOT_LOGGED_IN);
      }
      let projVersion = version;
      let params = {
        key: userHash,
        projectKey: projectkey,
        projVersion: projVersion,
      };
      // APP.setProgress("Loading model data...", true);
      let result = null;
      try {
        if (useTestData) {
          result = await MC_TEST_DATA.getData(url, params);
        } else {
          result = await SERVER.postData(url, params);
        }
      } catch (err) {
        result = null;
        // APP.resetProgress();
      }
      if (result === "ROUTES_MISMATCHED") {
        // APP.resetProgress();
        return;
      }
      // APP.resetProgress();
      compdata = result;
      STORE.setProjectData(
        projectkey,
        projVersion,
        MP_MODEL_COMPARISON.STORE_KEY,
        compdata
      );
      STORE.setProjectMetadata(
        projectkey,
        projVersion,
        MP_MODEL_COMPARISON.STORE_KEY + "_compData_loaded",
        true
      );
      maxTableSize = Math.max(maxTableSize, Object.keys(compdata).length);
      trainingAndValidationData.push(result);
    }
    return trainingAndValidationData;
  };

  /**
   * @method loadModelsTable
   * @description Loads the data of model table for validation and training
   * @public
   */
  var loadModelsTable = function (compData, validation) {
    compData.forEach((compData, index) => {
      let html = "";
      Object.keys(compData).forEach((x) => {
        // don't add the row for mlops_deploy as it is only a flag to check the deployable data.
        if (x === "mlops_deploy") {
          return;
        }
        let source = "train";
        if (validation) {
          source = "test";
        }
        [source].forEach((source) => {
          html += COMPARISON_TABLE.setUpRow(
            compData,
            mcConfig,
            source,
            x,
            index,
            validation
          );
        });
      });
      if (validation) {
        qsa(
          `.validation-card-${index} .tableContainer table tbody tr:not(.template-row)`
        ).forEach((x) => x.remove());
        qs(`.validation-card-${index} .tableContainer table tbody`).innerHTML =
          qs(`.validation-card-${index} .tableContainer table tbody`)
            .innerHTML + html;
        // qs(`.validation-card-${index} .tableContainer tbody tr:nth-child(2) td`).addClass("selected");
      } else {
        qsa(
          `.training-card-${index} .tableContainer table tbody tr:not(.template-row)`
        ).forEach((x) => x.remove());
        qs(`.training-card-${index} .tableContainer table tbody`).innerHTML =
          qs(`.training-card-${index} .tableContainer table tbody`).innerHTML +
          html;
        qs(
          `.training-card-${index} .tableContainer tbody tr:nth-child(2)`
        ).addClass("selected");
      }
    });
  };

  /**
   * @method loadHtml
   * @description Loads the html from the pug template with the values
   * @public
   */
  var loadHtml = function () {
    qs("#main-content .content.version-comparison").style.display = "block";
    qs("#main-content .content.version-comparison").innerHTML =
      versioncomparisonTemplate({
        project: project,
        versionList: versionList,
        widgetList: widgetList,
        trainingAndValidationDataColumns: mcConfig,
        dropdownVersionList: dropdownVersionList,
      });
  };

  /**
   * @method populateVersionDropdown
   * @description Sets the values of the dropdown options for selecting versions to be compared
   * @public
   */
  var populateVersionDropdown = function (project) {
    project.versionInfo.forEach((x) => {
      if (x.namedState == "Completed") {
        dropdownVersionList.push(x.vname);
      }
    });
  };

  /**
   * @method decideWidgetList
   * @description According to project type sets the list of widgets to be shown
   * @public
   */
  var decideWidgetList = function (project) {
    // widgetList = ['version-info', 'training', 'validation', 'global-interpretability', 'gain-analysis', 'calibration'];
    widgetList = [
      "version-info",
      "training",
      "validation",
      "global-interpretability",
      "gain-analysis",
      "roc-curve",
      "ks-graph",
    ];
    isRegressionModel = project.ptype == "regression" ? true : false;
    isSegmentationModel = project.ptype == "segmentation" ? true : false;
    if (isRegressionModel) {
      widgetList = [
        "version-info",
        "training",
        "validation",
        "global-interpretability",
        "actual-vs-predicted",
      ];
    } else if (isSegmentationModel) {
      widgetList = [
        "version-info",
        "training",
        "validation",
        "feature-count",
        "elbow-chart",
        "silhouette-score",
        "confusion-matrix",
        "model-interp",
      ];
    }
  };

  /**
   * @method loadModelComparisonData
   * @description gets the data for model comparision and then loads data that are dependent on model comparision data
   * @public
   */
  var loadModelComparisonData = function () {
    my.getModelComparisonData(project["projectkey"], versionList).then(
      (trainingAndValidationResult) => {
        compData = trainingAndValidationResult; //data for training and validation

        loadModelsTable(compData, false); //for training
        loadModelsTable(compData, true); //for validation

        if (widgetList.includes("global-interpretability")) {
          my.fetchGlobalInterpData(project["projectkey"], versionList).then(
            (globalInterpGraphdata) => {
              renderGlobalInterpView();
            }
          );
        }
        // 32 is the height of each row and 36 is the height of thead
        let tableSize = maxTableSize * 32 + 36;
        qsa(".version-card .tableContainer table").forEach((x) => {
          const diff = tableSize - x.clientHeight;
          x.style.marginBottom = `${diff}px`;
        });
        registerListeners();
      }
    );
  };

  /**
   * @method populateData
   * @description Populates all the required data
   * @public
   */
  var populateData = function () {
    APP.setProgress("Populating data...");
    loadModelComparisonData();
    if (isSegmentationModel) {
      my.getSegmentationLeaderboardData(
        project["projectkey"],
        versionList
      ).then(() => {
        segmentationDataList.forEach((versionSegmentationData, index) => {
          renderVariableCountGraph(versionSegmentationData, index);
          renderElbowGraph(versionSegmentationData, index);
          renderSilhouetteGraph(versionSegmentationData, index);
          renderConfusionMatrix(versionSegmentationData, index);
          renderRadarChart(versionSegmentationData, index);
        });
        APP.resetProgress();
      });
    } else if (!isRegressionModel) {
      my.getGainAndCalibData(project["projectkey"], versionList).then(() => {
        gainAndCalibList.forEach((versionGainAndCalibData, index) => {
          renderGainAnalysisView(versionGainAndCalibData, index);
          // renderCalibrationView(versionGainAndCalibData, index);
          renderRocCurveView(versionGainAndCalibData, index);
          renderKsGraphView(versionGainAndCalibData, index);
        });
        APP.resetProgress();
      });
    } else {
      my.getGainAndCalibData(project["projectkey"], versionList).then(() => {
        gainAndCalibList.forEach((versionGainAndCalibData, index) => {
          renderActualVsPredictedView(versionGainAndCalibData, index);
        });
        APP.resetProgress();
      });
    }
  };

  /**
   * @method initPage
   * @description initializes page and makes it ready for the data to be loaded.
   * @async
   * @public
   */
  var initPage = function () {
    const versionComparisionHtml = qs(".version-comparison");
    if (versionComparisionHtml) {
      versionComparisionHtml.innerHTML = "";
    }
    let selectedProjectKeyForComparison =
      DASHBOARD.selectedProjectKeyForComparison;
    let projects = DASHBOARD.projects;
    project = projects.find(
      (v) => v.projectkey == selectedProjectKeyForComparison
    );
    versionList = project.selectedVersion;

    // populating the list of all versions for the dropdown
    populateVersionDropdown(project);

    // getting list of widgets to be shown per version
    decideWidgetList(project);

    // getting the config for the widgets
    if (useTestData && PROJECT.currentProject().ptype == "segmentation") {
      mcConfig = compCols;
    } else {
      mcConfig = APP.getProperty(
        "project.config.modelcomp",
        PROJECT.currentProjectKey()
      );
    }
    // Loading initial Html template
    loadHtml();

    // Load the version dropdown select box
    let selectVersions = new vanillaSelectBox("#select-box", {
      placeHolder: "Choose up to 3 versions",
      translations: { all: "All", items: "Versions" },
    });

    // Here we devide the grid to the number of required columns as per the version list
    qs(
      ".versions-container"
    ).style.gridTemplateColumns = `repeat(auto-fill,minmax(${
      100 / versionList.length
    }%,1fr))`; //to adjust the version cards in the grid according to no. of versions compared
  };

  /**
   * @method loadPage
   * @description initializes page and poppulates data
   * @async
   * @public
   */
  var loadPage = async function (pkey) {
    initPage();
    populateData();
  };

  /**
   * @method show
   * @description load the view's data and then load it into the table
   * @async
   * @public
   */
  my.show = async function (pkey) {
    resetValues();
    await loadPage(pkey);
  };

  my.unloadPage = function () {
    qs("#main-content .content.version-comparison").style.display = "none";
  };

  return my;
})(VERSION_COMPARISON || {});
