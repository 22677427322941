/**
 * @module ACCORDION
 * @description provides the functionality for the selection of tabs
 * in an accordion component
 */
// eslint-disable-next-line no-extra-semi
;var ACCORDION=(function(my){
  /**
   * @method register
   * @description register an accordion's outer wrapper so that event listeners
   * can be attached to the input elements therein to change ARIA attributes
   * @param accordionContainer One of:
   * - A string CSS selector to select one or more outer containers
   * - A NodeList/Array of accordion container objects
   * - One accordion container element
   * @public
   */
  my.register=function(accordionContainer){
    if ("string" == (typeof accordionContainer).toLowerCase()){
      accordionContainer = qsa(accordionContainer);
    }
    if (accordionContainer instanceof Element){
      accordionContainer = [accordionContainer];
    }
    accordionContainer.forEach(ac=>{
      ac.qsa(".accordion-selector").forEach(input=>input.addEventListener("change", inputListener));
    });
  };

  /**
   * @method deregister
   * @description deregister a previously registered accordion
   * @param accordionContainer One of:
   * - A string CSS selector to select one or more outer containers
   * - A NodeList/Array of accordion container objects
   * - One accordion container element
   * @public
   */
  my.deregister=function(accordionContainer){
    if ("string" == (typeof accordionContainer).toLowerCase()){
      accordionContainer = qsa(accordionContainer);
    }
    if (accordionContainer instanceof Element){
      accordionContainer = [accordionContainer];
    }
    accordionContainer.forEach(ac=>{
      ac.qsa(".accordion-selector").forEach(input=>input.removeEventListener("change", inputListener));
    });
  }

  /**
   * @function inputListener this is registered on the input radio elements in the
   * accordion as change listeners. Listens for change and update ARIA attributes on
   * the label and target panels.
   * @param {Event} evt 
   * @private
   */
  var inputListener=function(evt){
    let input = evt.target;
    let container = input.closest(".accordion-container");
    // let label = container.qs(`.accordion-tab[for="${input.id}"]`);
    let closingPanel = container.qs(".accordion-panel[aria-hidden='false']");
    container.qsa(`input.accordion-selector[name=${input.name}]`).forEach(radio => {
      let panel = container.qs(`.accordion-panel#${radio.getAttribute("aria-controls")}`);
      let label = container.qs(`.accordion-tab[for="${radio.id}"]`);
      if(radio==input){
        panel.setAttribute("aria-hidden", "false");  
        label.setAttribute("aria-selected", "true");
        container.fireCustomEvent("panelShown", {oldPanel: closingPanel, newPanel: panel});
      } else {
        panel.setAttribute("aria-hidden", "true");  
        label.setAttribute("aria-selected", "false");
      }
    });
  };

  return my;
}(ACCORDION || {}));