/**
 * @module STORE 
 * @description A data store. All system wide data will be stored in the store. Has facility to 
 * set observers on data in the store so that subsystems may be informed of data changes.
 */
// eslint-disable-next-line no-redeclare
var STORE=(function(my){
  /**
   * @var {object} _observers 
   * @description stores observers in a map by name and observer id
   */
  var _observers={};

  /**
   * @var {number} _observerCounter 
   * @description an incremental counter that is incremented after each call to addObserver. 
   */
  var _observerCounter=0;

  /**
   * @method addObserver 
   * @description add an observer for a property stored in the STORE
   * @param {string} name - name of the store property that you want to observe
   * @param {STORE-addObserverCallback} f - The observer callback that is called when the property specified by name changes.
   * @return {Number} An identifying number that can be used to remove this observer at a later point.
   * @public
   */
  my.addObserver=function(name, f){
    if (!_observers[name] || "undefined" == _observers[name]){
      _observers[name]={};
    }
    _observers[name][""+_observerCounter] = f;
    return (++_observerCounter-1);
  };
  /**
   * This callback is called when the property for which the observer is added changes
   * @callback STORE-addObserverCallback
   * @param {Object} p property that changed
   */

  /**
   * @method removeObserver 
   * @description remove an observer for a property stored in the STORE
   * @param {string} name name of the property
   * @param {Number} id id of the observer callback that was registered using addObserver.
   * @return {function} The callback function that was registerd as an observer
   * @public
   */
  my.removeObserver=function(name, id){
    if (!_observers[name] || "undefined" == _observers[name]){
      return null;
    }
    if (!_observers[name][id] || "undefined" == _observers[name][id]){
      return null;
    }
    const f=_observers[name][id];
    delete _observers[name][id];
    return f;
  }

  /**
   * @var {object[]} _projects 
   * @description the underlying property that stores an array of projects obtained from the server.
   * @private
   */
  var _projects=null;

  /**
   * @member {object} projects 
   * @description getters and setters for the underlying _projects. The setter calls all the observers looking at this property.
   */
  Object.defineProperty(my, 'projects', { 
    get: function(){return _projects;},
    set: function(p){
      _projects=p; 
      if (!_observers.projects || typeof _observers.projects == "undefined"){return;}
      for (var x in _observers.projects){_observers.projects[x](p);}
    } 
  });

  /**
   * @member {string} here 
   * @description the current location in the application, based on the window.location.hash. No setter. use APP.router.navigate to set the location.
   */
  Object.defineProperty(my, 'here', {
    get: function(){
      var hash=window.location.hash;
      if (hash.startsWith("#/")){
        hash=hash.substr(2);
      } else if (hash.startsWith("#")){
        hash=hash.substr(1);
      }
      if (hash === ""){
        hash='/';
      }
      return hash.startsWith("/")?hash:"/"+hash;
    }
  });

  /**
   * @member {object} _projectStuff
   * @description storage object for storing the data and metadata for various pages in the UI.
   * TODO: This should be made private at some point.
   * @public
   */
  my._projectStuff={};

  /**
   * @method setProjectData
   * @description Store some data in the store. 
   * @param {string} projectID project context
   * @param {string} dataKey lookup key
   * @param {object} data data to be stored
   * @return {object} The `data` parameter that was passed to the method.
   * @public
   */
  my.setProjectData=function(projectID, projVersion, dataKey, data){
    if (!Object.keys(my._projectStuff).includes(projectID)){
      my._projectStuff[projectID]={};
      my._projectStuff[projectID][projVersion]={};
      my._projectStuff[projectID][projVersion].data={};
      my._projectStuff[projectID][projVersion].metadata={};
    }
    else if(!Object.keys(my._projectStuff[projectID]).includes(projVersion)) {
      my._projectStuff[projectID][projVersion]={};
      my._projectStuff[projectID][projVersion].data={};
      my._projectStuff[projectID][projVersion].metadata={};
    }
    my._projectStuff[projectID][projVersion].data[dataKey]=data;
    return data;
  }

  /**
   * @method setProjectMetadata
   * @description Store some data in the store. The difference from [`setProjectData`](#~setProjectData) is one of semantics.
   * @param {string} projectID project context
   * @param {string} dataKey lookup key
   * @param {object} data data to be stored
   * @return {object} The `data` parameter that was passed to the method.
   * @public
   */
  my.setProjectMetadata=function(projectID, projVersion, metaKey, metadata){
    if (!Object.keys(my._projectStuff).includes(projectID)){
      my._projectStuff[projectID]={};
      my._projectStuff[projectID][projVersion]={};
      my._projectStuff[projectID][projVersion].data={};
      my._projectStuff[projectID][projVersion].metadata={};
    }
    my._projectStuff[projectID][projVersion].metadata[metaKey]=metadata;
  }

  /**
   * @method getProjectData
   * @description Correspoinding getter for [`setProjectData`](#~setProjectData). Returns `false` if either project or 
   * key doesn't exist.
   * @param {string} projectID project context
   * @param {string} dataKey lookup key
   * @return {object} The data object that was previously stored using [`setProjectData`](#~setProjectData). 
   * Returns `false` if either project or key doesn't exist.
   * @public
   */
  my.getProjectData=function(projectID,projVersion, dataKey){
    if (!Object.keys(my._projectStuff).includes(projectID) || !Object.keys(my._projectStuff[projectID]).includes(projVersion)){
      return false;
    }
    if (!Object.keys(my._projectStuff[projectID][projVersion].data).includes(dataKey)){
      return false
    }
    return my._projectStuff[projectID][projVersion].data[dataKey];
  }

  /**
   * @method getProjectMetadata
   * @description Correspoinding getter for [`setProjectMetadata`](#~setProjectMetadata). Returns `false` if either project or 
   * key doesn't exist.
   * @param {string} projectID project context
   * @param {string} dataKey lookup key
   * @return {object} The data object that was previously stored using [`setProjectMetadata`](#~setProjectMetadata). 
   * Returns `false` if either project or key doesn't exist.
   * @public
   */
  my.getProjectMetadata=function(projectID,projVersion, metaKey){
    if (!Object.keys(my._projectStuff).includes(projectID) || !Object.keys(my._projectStuff[projectID]).includes(projVersion)){
      return false;
    }
    if (!Object.keys(my._projectStuff[projectID][projVersion].metadata).includes(metaKey)){
      return false
    }
    return my._projectStuff[projectID][projVersion].metadata[metaKey];
  }

  /**
   * @method purge
   * @description Empty all data.
   * @public
   */
  my.purge=function(){
    my._projectStuff={};
  }

  return my;
}(STORE || {}));
