/* global CHART */
/**
 * @module ROC_CHART
 * @description draws the ROC chart shown on the `mp/mll/discrimination` page. Implements the 
 * [`CHART`](module-CHART.html) interface.
 */
// eslint-disable-next-line no-extra-semi
;var ROC_CHART=(function(my){
  my.type="roc";

  my.optsGenerator=function(options,index){
    let opts=extend({},CHART.opts);
    delete opts.axis.y.tick.count;
    if(index!=null){
      opts.bindto=`.roc-curve-card-${index} #versionRocCurve .graph`;
    }
    else{
      opts.bindto= "#rocCurve .graph";
    }
    opts.axis.y.tick.format=d3.format(".2");
    opts.axis.y.label.text="True Positive Rate";
    opts.axis.x.label.text="False Positive Rate";
    opts.legend={
      show: true,
      hide: ['Reference']
    };
    opts.id=my.type;
    opts.data={
      xs:{
        ROC: "x1",
        Reference: "x2"
      },
      columns: [
        ['x1'].concat(options.data.y1.keys),
        ['x2', 0, 1],
        [options.data.y1.name].concat(options.data.y1.values),
        ['Reference' , 0, 1]
      ],
      type: 'line',
      colors: {
         ROC: CHART.colors[0],
         Reference: CHART.colors[2]
      }
    };
    opts.grid={
      x:{show: true, lines: [{value: 0.2}, {value: 0.4}, {value: 0.6}, {value: 0.8}]},
      y:{show: true},
    };
    if(index!=null){
      opts.size={
        width: 632,
        height: 373,
      };
    }
    else{
      opts.size={
        width: qs("#rocCurve").offsetWidth -30,
        height: qs("#rocCurve").offsetHeight - qs("#rocCurve h4").offsetHeight - 22,
      };
    }
    opts.tooltip={
      contents: function(d, defaultTitleFormat, defaultValueFormat, color) {
        if (d[0].id === "ROC") {
          return `<table class='c3-tooltip'>
                    <tr><th colspan='2'>${defaultTitleFormat(d[0].x)}</th></tr>
                    <tr class='c3-tooltip-name--${d[0].id}'>
                      <td class='name'><span style='background-color:${color(d[0].id)}'></span>${d[0].name}</td>
                      <td class='value'>${defaultValueFormat(d[0].value, null, d[0].id)}</td>
                    </tr>
                  </table>`
        }
      },
      horizontal: true
    };

    if (!empty(options.c3d3properties)){
      opts=extend(opts, options.c3d3properties);
    }
    
    return opts;
  };
  my.generate=function(options,index=null){
    let opt=my.optsGenerator(options,index);
    CHART.charts[opt.id]=c3.generate(opt);
    d3.select(".c3-legend-item-ROC text").text("AUC="+(d3.format(".3f"))(options.data.auc));
    return CHART.charts[opt.id];
  }

  return my;
}(ROC_CHART || {}));
