/**
 * @module  SEGMENTATION_CUSTOMIZER
 * @description
 */
var SEGMENTATION_CUSTOMIZER = (function (my) {
  my.id = "segmentation";

  // eslint-disable-next-line no-unused-vars
  my.preRoute = function (router, params) {};

  my.postRoute = function (router, params) {
    params.nav.qs("#navItem-ae-automodel-nav-link").addClass("disabled");
    params.nav
      .qsa(
        [
          "#navItem-mlleaderboard-segmentation-featureSelection-nav-link",
          "#navItem-mlleaderboard-segmentation-optimalSegmentSelection-nav-link",
          "#navItem-mlleaderboard-segmentation-variableImportance-nav-link",
          "#navItem-mlleaderboard-segmentation-modelPerformanceSegmentation-nav-link",
          "#navItem-mlleaderboard-segmentation-modelInterpretability-nav-link",
          "#navItem-mlleaderboard-segmentation-clusterMerging-nav-link",
          "#navItem-mlleaderboard-segmentation-clusterVisualization-nav-link",
          "#navItem-mp-ba-personaCreation-nav-link",
          "#navItem-mp-ba-insights-nav-link",
          "#navGroupItem-mp-ba",
        ].join(", ")
      )
      .forEach((x) => x.removeClass("hidden"));
    CUSTOMIZER_HELPER.configHandler(params);
  };

  my.reroute = function (params) {
    return CUSTOMIZER_HELPER.reroute(params, "segmentation");
  };

  return my;
})(SEGMENTATION_CUSTOMIZER || {});
