/* global CHART */
/**
 * @module PCA_3D_CHART
 * @description draws the PCA 3D chart shown on the `mp/mll/pca3d` page. Implements the 
 * [`CHART`](module-CHART.html) interface.
 */
// eslint-disable-next-line no-extra-semi
;var PCA_3D_CHART=(function(my){
  my.type="pca3d";

  my.optsGenerator=function(options){
    let opts=extend({},CHART.opts);

    delete opts.axis.y.min;
    delete opts.axis.y.max;
    delete opts.axis.y.tick.values;
    delete opts.axis.x.min;
    delete opts.axis.x.max;
    delete opts.axis.x.tick.values;
    
    delete opts.axis.x.label.text;
    
    opts.bindto="#pca3d .graph";
    // opts.axis.y.tick.format=d3.format(".1f");
    // opts.axis.x.tick.format=d3.format(".1f");
    // opts.axis.y.tick.culling=true;
    // opts.axis.x.tick.culling=true;
    // opts.axis.y.label.text="PC2";
    // opts.axis.x.label.text="PC1";
    opts.id=my.type;
    opts.data={
      key: "Bubbles",
      values: []
      // xs:{
      //   ActualVsPredicted: "x1",
      //   Reference: "referencex"
      // },
      // columns: [
      //   ['x1'].concat(options.data.y1.keys),
      //   ['referencex'].concat(options.data.reference.keys),
      //   ['ActualVsPredicted'].concat(options.data.y1.values),
      //   ['Reference'].concat(options.data.reference.values)
      // ],
      // additionalData: options.data.additionalData,
      // types: {
      //   ActualVsPredicted: 'scatter',
      //   referencex: 'line'        
      // },
      // colors: {
      //    ActualVsPredicted: CHART.colors[0],
      //    Reference: CHART.colors[2]
      // }
    };
    for(let i = 0; i < (options.data.intensity).length; i++) {
      opts.data.values.push({key: i, value: options.data.intensity[i], x: options.data.x[i], y: options.data.y[i], z: options.data.z[i], size:1, color:"red"});
    }
    opts.tooltip={
    //   contents: function(d, defaultTitleFormat, defaultValueFormat, color) {
    //     if (d[0].id === "ActualVsPredicted") {
    //       return `<table class='c3-tooltip'>
    //                 <tr class='c3-tooltip-name--x}'>
    //                   <td class='name'><span style='background-color:${color(d[0].id)}'></span>Actual</td>
    //                   <td class='value'>${defaultValueFormat(d[0].x, null, d[0].id)}</td>
    //                 </tr>
    //                 <tr class='c3-tooltip-name--${d[0].id}'>
    //                   <td class='name'><span style='background-color:transparent'></span>Predicted</td>
    //                   <td class='value'>${defaultValueFormat(d[0].value, null, d[0].id)}</td>
    //                 </tr>
    //               </table>`
    //     }
    //   },
      horizontal: true
    };
    opts.legend={
      show: false,
      // hide: ['Reference']
    };
    opts.grid={
      x:{show: false},
      y:{show: true},
    };
    opts.point={
      show: true,
      r: 3
    }
    opts.size={
      width: qs("#pca3dChart").offsetWidth -30,
      height: qs("#pca3dChart").offsetHeight - qs("#pca3dChart h4").offsetHeight - 22
    };
    if (!empty(options.c3d3properties)){
      opts=extend(opts, options.c3d3properties);
    }
    return opts;
  };
  my.generate=function(options){
    let opt=my.optsGenerator(options);
    CHART.charts[opt.id]=my.create3dChart(opt);
    // qsa("#actualVsPredictedChart .customLegend").forEach(x=>x.remove());
    // const legend=createNode(`
    //   <div class="customLegend">
    //     <table>
    //       <tbody></tbody>
    //     </table>
    //   </div>
    // `);
    // let rows="";
    const format=d3.format("0.3f");
    // for (let key in opt.data.additionalData) {
    //   const value=opt.data.additionalData[key];
    //   rows+=`<tr><td class="key">${key}</td><td class="value">${format(value)}</td></tr>`;
    // }
    // legend.qs("tbody").innerHTML=rows;
    // qs("#actualVsPredictedChart .graph-container").appendChild(legend);
    return CHART.charts[opt.id];
  }

  my.create3dChart = function(opt){
    let margin = {top: 10, right: 10, bottom: 30, left: 30};
    let width = opt.size.width - margin.left - margin.right;
    let height = opt.size.height - margin.top - margin.bottom;

    var chartHolder = d3.select(opt.bindto);
    chartHolder.attr("style", `height: ${height}px;`);
    var mychart = d3.x3d.chart.bubbleChart();
    // mychart.mappings({ x: 'x', y: 'y', z: 'z', size: 'size', color: 'color', key: 'key', value: 'value' })
    console.log(opt.data);
    chartHolder.datum(opt.data).call(mychart);
    
    return chartHolder.node();

  }
  return my;
}(PCA_3D_CHART || {}));