var MP_BA_INSIGHTS = (function (my) {
  /**
   * @member {string} selectedModelID
   * @description Whether the current view is for the selected model ID from the dropdown.
   * @private
   */
  var selectedModelID = null;

  /**
   * @member {object} data
   * @description Holds the leaderboard data.
   * @private
   */
  var data = null;

  /**
   * @member {object} projectKey
   * @description project key of the current project.
   * @private
   */
  var projectKey = null;

  /**
   * @method loadData
   * @description makes the API call to get the behavioural analysis table data on /fetchpp with suitable payload and returns the result
   */
  const loadData = async function (iparams) {
    if (useTestData) {
      let result = await BEHAVIOURAL_ANALYSIS_DATA.getData();
      return result;
    }
    return API_HELPER.getResult("fetchpp", iparams);
  };

  /**
   * @method getModelOptions
   * @description fetches the available models and populates the model dropdown.
   */
  const getModelOptions = function (leaderboardData) {
    const modelList = Object.keys(leaderboardData);
    selectedModelID = modelList[0];
    const selectElement = qs(".insights #modelSelect");
    selectElement.innerHTML = "";
    modelList.forEach((modelID) => {
      const optionElement = document.createElement("option");
      optionElement.value = modelID;
      optionElement.textContent = leaderboardData[modelID].name;
      if (modelID === selectedModelID) {
        optionElement.selected = true;
      }
      selectElement.appendChild(optionElement);
    });
  };

  /**
   * @method modelChanged
   * @description reloads the page with the selected model from the dropdown
   */
  const modelChanged = function () {
    const selectElement = qs(".insights #modelSelect");
    const selectedOption = selectElement.options[selectElement.selectedIndex];
    const selectedValue = selectedOption.value;
    selectedModelID = selectedValue;
    reloadData();
  };

  /**
   * @method registerInsightsPageListners
   * @description Register listener for opening the radar chart on icon click.
   * @private
   */
  const registerInsightsPageListners = function () {
    const insightsPage = qs("#main-content .content.mp-behavioural-analysis");
    insightsPage.qs("#modelSelect").addEventListener("change", modelChanged);
    insightsPage
      .qsa(".table-container .insights-table .list tr")
      .forEach((row) => {
        row
          .qs("td:last-child .visualize-icon")
          .addEventListener("click", (evt) => {
            const featureName = row.qs("td:first-child").textContent;
            openRadarChartDialog(featureName);
          });
      });
  };

  /**
   * @method loadRadarChart
   * @description Loads the radar chart based on the radar chart type.
   */
  var loadRadarChart = function (featureName) {
    var config = {
      w: 400,
      h: 400,
      facet: false,
      levels: 5,
      levelScale: 0.85,
      labelScale: 0.9,
      facetPaddingScale: 2.1,
      showLevels: true,
      showLevelsLabels: false,
      showAxesLabels: true,
      showAxes: true,
      showLegend: true,
      showVertices: true,
      showPolygons: true,
    };

    function createGraphData(featureName) {
      const featureObject = data.meanInfo.find(
        (obj) => obj.feature === featureName
      );
      const keys = Object.keys(featureObject);
      const cluster = keys.filter((elem) => elem.startsWith("cluster_")).sort();
      let graphData = [];
      const groupObj = {
        group: featureName,
        axes: [],
      };
      for (let i = 0; i < cluster.length; i++) {
        groupObj.axes.push({
          axis: cluster[i],
          value: featureObject[cluster[i]],
          description: "",
        });
      }
      graphData.push(groupObj);
      return graphData;
    }

    function drawGraph() {
      var element = document.getElementById("insights-radar-chart");
      var graphData = createGraphData(featureName);
      SegmentationRadarChart.draw(element, graphData, config);
    }
    drawGraph();
  };

  /**
   * @method openRadarChartDialog
   * @description Opens the radar chart in the dialog box.
   */
  const openRadarChartDialog = function (featureName) {
    var radarChartDlg = null;
    radarChartDlg = createNode(insightsgraphdialogTemplate());
    qs("#dialogs-sleeping").appendChild(radarChartDlg);
    APP.showDialog(radarChartDlg);
    loadRadarChart(featureName);
    radarChartDlg.qs("button.close").addEventListener("click", () => {
      APP.hideDialog(radarChartDlg);
      radarChartDlg.remove();
    });
  };

  /**
   * @method reloadData
   * @description reloads the data and insights table.
   */
  const reloadData = async function () {
    data = await loadData({
      projectKey: projectKey,
      projVersion: PROJECT.currentProjVersion(),
      modelName: selectedModelID,
      datatype: "persona",
    });
    data = data.data.posts[0].insights;
    loadTable();
    const insightsPage = qs("#main-content .content.mp-behavioural-analysis");
    insightsPage
      .qsa(".table-container .insights-table .list tr")
      .forEach((row) => {
        row
          .qs("td:last-child .visualize-icon")
          .addEventListener("click", (evt) => {
            const featureName = row.qs("td:first-child").textContent;
            openRadarChartDialog(featureName);
          });
      });
  };

  /**
   * @method convertToPercentile
   * @description converts the values of columns to a percentile-based value array
   */
  function convertToPercentile(arr) {
    const result = [""];
    for (let i = 1; i < arr.length - 2; i++) {
      let count = 0;
      for (let j = 1; j < arr.length - 2; j++) {
        if (parseFloat(arr[j].textContent) < parseFloat(arr[i].textContent)) {
          count++;
        }
      }
      const percentile = (count / arr.length) * 100;
      result.push(percentile);
    }
    result.push("");
    result.push("");
    return result;
  }

  /**
   * @method assignBackgroundColors
   * @description gives the background color to each entry in the table based on the intensity
   */
  const assignBackgroundColors = function () {
    const rows = qsa(".insights-table tr");
    for (let i = 2; i < rows.length; i++) {
      const cols = rows[i].getElementsByTagName("td");
      const percentileValues = convertToPercentile(cols);
      let min = Number.MAX_VALUE;
      let max = Number.MIN_VALUE;
      for (let j = 1; j < percentileValues.length - 2; j++) {
        const val = percentileValues[j];
        if (val < min) {
          min = val;
        }
        if (val > max) {
          max = val;
        }
      }
      const range = max - min;
      for (let j = 1; j < percentileValues.length - 2; j++) {
        const val = percentileValues[j];
        lightness = 95 - (15 * (val - min)) / range;
        cols[j].style.backgroundColor = `hsl(214, 82%, ${lightness}%)`;
      }
    }
  };

  /**
   * @method loadTable
   * @description loads the insights table using insightsTable.pug file.
   */
  const loadTable = function () {
    qs(".insights .table-container").innerHTML = "";
    //insights Header Row Data
    const insightsHeaderRow = ["Distribution"];
    Object.keys(data.countInfo)
      .sort()
      .forEach((cluster) =>
        insightsHeaderRow.push(data.countInfo[cluster].toString())
      );
    //Header Keys
    const headerKeys = ["feature"];
    Object.keys(data.countInfo)
      .sort()
      .forEach((cluster) => headerKeys.push(cluster));
    headerKeys.push("overall", "visualize");
    //Header text
    const clusters = Object.keys(data.countInfo).sort();
    const headerText = ["CLUSTER"];
    for (let i = 0; i < clusters.length; i++) {
      headerText.push(clusters[i].substring(8));
    }
    headerText.push("Overall Mean", "Visualize");
    qsa(".insights .table-container").forEach((table) =>
      table.appendChild(
        createNode(
          insightstableTemplate({
            data: data.meanInfo,
            headerText: headerText,
            headerKeys: headerKeys,
            insightsHeaderRow: insightsHeaderRow,
          })
        )
      )
    );
    assignBackgroundColors();
  };

  /**
   * @method resetVariables
   * @description resets all the variables upon landing the page to avoid caching issues.
   */
  const resetVariables = function () {
    selectedModelID = null;
    data = null;
    projectKey = null;
  };

  /**
   * @method loadPage
   * @description loads the page for the first time
   */
  my.loadPage = async function (pkey, leaderboardData) {
    resetVariables();
    const behaviouralAnalysisPage = qs(
      "#main-content .content.mp-behavioural-analysis"
    );
    behaviouralAnalysisPage.innerHTML = mpbainsightsTemplate();
    behaviouralAnalysisPage.style.overflow = "";
    getModelOptions(leaderboardData);
    projectKey = pkey;
    data = await loadData({
      projectKey: projectKey,
      projVersion: PROJECT.currentProjVersion(),
      modelName: selectedModelID,
      datatype: "persona",
    });
    data = data.data.posts[0].insights;
    loadTable();
    registerInsightsPageListners();
  };

  return my;
})(MP_BA_INSIGHTS || {});
