/**
 * @module  ANOMALY_CUSTOMIZER
 * @description 
 */
var ANOMALY_CUSTOMIZER=(function(my){

  my.id="anomaly";

  // eslint-disable-next-line no-unused-vars
  my.preRoute=function(router, params) {

  };

  my.postRoute=function(router, params) {
    params.nav.qs("#navItem-ae-automodel-nav-link").addClass("disabled");
    params.nav.qsa(["#navItem-mp-mll-discrimination-nav-link", 
              // "#navItem-mp-mll-calibration-nav-link", 
              "#navItem-mp-mll-fprAndPA-nav-link",
              "#navItem-mp-mll-pca2d-nav-link",
              "#navItem-mp-mll-pca3d-nav-link", 
              "#navItem-mp-mll-predictionVsDensity-nav-link"
              // "#navItem-mp-mll-gainAnalysis-nav-link", 
              // "#navItem-mp-eai-local-nav-link", 
              // "#navItem-mp-eai-global-nav-link", 
              // "#navGroupItem-mp-eai"
            ].join(", ")).forEach(x=>x.removeClass("hidden"));    
    CUSTOMIZER_HELPER.configHandler(params);        
  };

  my.reroute=function(params) {
    return CUSTOMIZER_HELPER.reroute(params,"anamoly");
  }

  return my;
}(ANOMALY_CUSTOMIZER || {}));
