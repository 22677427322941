/**
 * @module LIFT_ANALYSIS
 * @description draws the Probability Levels chart shown on the `mp/mll/fprAndPA` page. Implements the 
 * [`CHART`](module-CHART.html) interface.
 */
;var LIFT_ANALYSIS=(function(my){
  my.type="liftAnalysis";

  my.optsGenerator=function(options){
    options.attributes={
      "class": "scrollable-table",
    }
    return options;
  };
  my.generate=function(options, index=null){
    let opts=my.optsGenerator(options);
    if(index!=null){
      qs(`.gain-analysis-card-${index} #versionLiftAnalysisTableDiv`).innerHTML=liftanalysistableTemplate(opts)
      return qs(`.gain-analysis-card-${index} #tab-content-liftAnalysis table`);
    }
    else{
      qs("#liftAnalysisTableDiv").innerHTML=liftanalysistableTemplate(opts)
      return qs("#tab-content-liftAnalysis table");
    }
  }

  return my;
}(LIFT_ANALYSIS || {}));
