var BREADCRUMB_MAPPER = (function (my) {
  my.getBreadCrumbMap = function () {
    const breadcrumbMap = {
      help: {
        breadcrumb: [{ id: "br-help", class: "", text: "Help", link: "" }],
      },
      "reset-password": {
        breadcrumb: [
          {
            id: "br-reset-password",
            class: "",
            text: "Reset Password",
            link: "",
          },
        ],
      },
      "security-policy": {
        breadcrumb: [
          {
            id: "br-security-policy",
            class: "",
            text: "Security Policy",
            link: "",
          },
        ],
      },
      "forget-password": {
        breadcrumb: [
          {
            id: "br-forget-password",
            class: "",
            text: "Forget Password",
            link: "",
          },
        ],
      },
      "db-connection": {
        breadcrumb: [
          {
            id: "br-project-" + PROJECT.currentProjectKey(),
            class: "",
            text: PROJECT.currentProjectKey(),
            link: "/#/",
          },
          {
            id: "br-project-version-" + PROJECT.currentProjVersion(),
            class: "",
            text: PROJECT.currentProjVersion(),
            link: "/#/",
          },
          {
            id: "br-connectDB",
            class: "",
            text: "New DB Connection",
            link: "/#/db-connection/" + PROJECT.currentProjectKey(),
          },
        ],
      },
      "file-upload": {
        breadcrumb: [
          {
            id: "br-project-" + PROJECT.currentProjectKey(),
            class: "",
            text: PROJECT.currentProjectKey(),
            link: "/#/",
          },
          {
            id: "br-project-version-" + PROJECT.currentProjVersion(),
            class: "",
            text: PROJECT.currentProjVersion(),
            link: "/#/",
          },
          {
            id: "br-connectDB",
            class: "",
            text: "File Upload",
            link: "/#/file-upload/" + PROJECT.currentProjectKey(),
          },
        ],
      },
      "validate-features": {
        breadcrumb: [
          {
            id: "br-project-" + PROJECT.currentProjectKey(),
            class: "",
            text: PROJECT.currentProjectKey(),
            link: "/#/",
          },
          {
            id: "br-project-version-" + PROJECT.currentProjVersion(),
            class: "",
            text: PROJECT.currentProjVersion(),
            link: "/#/",
          },
          {
            id: "br-data-engineering",
            class: "",
            text: "Data Engineering",
            link: "",
          },
          {
            id: "br-validate-features",
            class: "",
            text: "Validate Features",
            link: "/#/validate-features/" + PROJECT.currentProjectKey(),
          },
        ],
      },
      "workflow-start": {
        breadcrumb: [
          {
            id: "br-project-" + PROJECT.currentProjectKey(),
            class: "",
            text: PROJECT.currentProjectKey(),
            link: "/#/",
          },
          {
            id: "br-project-version-" + PROJECT.currentProjVersion(),
            class: "",
            text: PROJECT.currentProjVersion(),
            link: "/#/",
          },
          {
            id: "br-data-engineering",
            class: "",
            text: "Data Engineering",
            link: "",
          },
          {
            id: "br-validate-features",
            class: "",
            text: "Validate Features",
            link: "/#/validate-features/" + PROJECT.currentProjectKey(),
          },
        ],
      },
      "explore-data": {
        breadcrumb: [
          {
            id: "br-project-" + PROJECT.currentProjectKey(),
            class: "",
            text: PROJECT.currentProjectKey(),
            link: "/#/",
          },
          {
            id: "br-project-version-" + PROJECT.currentProjVersion(),
            class: "",
            text: PROJECT.currentProjVersion(),
            link: "/#/",
          },
          {
            id: "br-data-engineering",
            class: "",
            text: "Data Engineering",
            link: "",
          },
          {
            id: "br-explore-data",
            class: "",
            text: "Explore Data",
            link: "/#/explore-data/" + PROJECT.currentProjectKey(),
          },
        ],
      },
      "ae-automodel": {
        breadcrumb: [
          {
            id: "br-project-" + PROJECT.currentProjectKey(),
            class: "",
            text: PROJECT.currentProjectKey(),
            link: "/#/",
          },
          {
            id: "br-project-version-" + PROJECT.currentProjVersion(),
            class: "",
            text: PROJECT.currentProjVersion(),
            link: "/#/",
          },
          {
            id: "algorithm-engineering",
            class: "",
            text: "Algorithm Engineering",
            link: "",
          },
          {
            id: "br-model-development",
            class: "",
            text: "Model Development",
            link: "/#/ae-automodel/" + PROJECT.currentProjectKey(),
          },
        ],
      },
      //
      // for segmentation screen
      "mlleaderboard-segmentation_featureSelection": {
        breadcrumb: [
          {
            id: "br-project-" + PROJECT.currentProjectKey(),
            class: "",
            text: PROJECT.currentProjectKey(),
            link: "/#/",
          },
          {
            id: "br-project-version-" + PROJECT.currentProjVersion(),
            class: "",
            text: PROJECT.currentProjVersion(),
            link: "/#/",
          },
          {
            id: "br-model-performance",
            class: "",
            text: "Model Performance",
            link: "",
          },
          {
            id: "br-mlleaderboard-segmentation",
            class: "",
            text: "ML Leaderboard",
            link: "",
          },
          {
            id: "br-featureSelection",
            class: "",
            text: "Feature Selection",
            link:
              "/#/mlleaderboard-segmentation/featureSelection/" +
              PROJECT.currentProjectKey(),
          },
        ],
      },
      "mlleaderboard-segmentation_optimalSegmentSelection": {
        breadcrumb: [
          {
            id: "br-project-" + PROJECT.currentProjectKey(),
            class: "",
            text: PROJECT.currentProjectKey(),
            link: "/#/",
          },
          {
            id: "br-project-version-" + PROJECT.currentProjVersion(),
            class: "",
            text: PROJECT.currentProjVersion(),
            link: "/#/",
          },
          {
            id: "br-model-performance",
            class: "",
            text: "Model Performance",
            link: "",
          },
          {
            id: "br-mlleaderboard-segmentation",
            class: "",
            text: "ML Leaderboard",
            link: "",
          },
          {
            id: "br-optimalSegmentSelection",
            class: "",
            text: "Optimal Segment Selection",
            link:
              "/#/mlleaderboard-segmentation/optimalSegmentSelection/" +
              PROJECT.currentProjectKey(),
          },
        ],
      },
      "mlleaderboard-segmentation_variableImportance": {
        breadcrumb: [
          {
            id: "br-project-" + PROJECT.currentProjectKey(),
            class: "",
            text: PROJECT.currentProjectKey(),
            link: "/#/",
          },
          {
            id: "br-project-version-" + PROJECT.currentProjVersion(),
            class: "",
            text: PROJECT.currentProjVersion(),
            link: "/#/",
          },
          {
            id: "br-model-performance",
            class: "",
            text: "Model Performance",
            link: "",
          },
          {
            id: "br-mlleaderboard-segmentation",
            class: "",
            text: "ML Leaderboard",
            link: "",
          },
          {
            id: "br-variableImportance",
            class: "",
            text: "Variable Importance",
            link:
              "/#/mlleaderboard-segmentation/variableImportance/" +
              PROJECT.currentProjectKey(),
          },
        ],
      },
      "mlleaderboard-segmentation_modelPerformanceSegmentation": {
        breadcrumb: [
          {
            id: "br-project-" + PROJECT.currentProjectKey(),
            class: "",
            text: PROJECT.currentProjectKey(),
            link: "/#/",
          },
          {
            id: "br-project-version-" + PROJECT.currentProjVersion(),
            class: "",
            text: PROJECT.currentProjVersion(),
            link: "/#/",
          },
          {
            id: "br-model-performance",
            class: "",
            text: "Model Performance",
            link: "",
          },
          {
            id: "br-mlleaderboard-segmentation",
            class: "",
            text: "ML Leaderboard",
            link: "",
          },
          {
            id: "br-modelPerformanceSegmentation",
            class: "",
            text: "Model Performance",
            link:
              "/#/mlleaderboard-segmentation/modelPerformanceSegmentation/" +
              PROJECT.currentProjectKey(),
          },
        ],
      },
      "mlleaderboard-segmentation_modelInterpretability": {
        breadcrumb: [
          {
            id: "br-project-" + PROJECT.currentProjectKey(),
            class: "",
            text: PROJECT.currentProjectKey(),
            link: "/#/",
          },
          {
            id: "br-project-version-" + PROJECT.currentProjVersion(),
            class: "",
            text: PROJECT.currentProjVersion(),
            link: "/#/",
          },
          {
            id: "br-model-performance",
            class: "",
            text: "Model Performance",
            link: "",
          },
          {
            id: "br-mlleaderboard-segmentation",
            class: "",
            text: "ML Leaderboard",
            link: "",
          },
          {
            id: "br-modelInterpretability",
            class: "",
            text: "Model Interpretability",
            link:
              "/#/mlleaderboard-segmentation/modelInterpretability/" +
              PROJECT.currentProjectKey(),
          },
        ],
      },
      "mlleaderboard-segmentation_clusterMerging": {
        breadcrumb: [
          {
            id: "br-project-" + PROJECT.currentProjectKey(),
            class: "",
            text: PROJECT.currentProjectKey(),
            link: "/#/",
          },
          {
            id: "br-project-version-" + PROJECT.currentProjVersion(),
            class: "",
            text: PROJECT.currentProjVersion(),
            link: "/#/",
          },
          {
            id: "br-clusterMerging",
            class: "",
            text: "Cluster Merging",
            link: "",
          },
          {
            id: "br-mlleaderboard-segmentation",
            class: "",
            text: "ML Leaderboard",
            link: "",
          },
          {
            id: "br-clusterMerging",
            class: "",
            text: "Cluster Merging",
            link:
              "/#/mlleaderboard-segmentation/clusterMerging/" +
              PROJECT.currentProjectKey(),
          },
        ],
      },
      "mlleaderboard-segmentation_clusterVisualization": {
        breadcrumb: [
          {
            id: "br-project-" + PROJECT.currentProjectKey(),
            class: "",
            text: PROJECT.currentProjectKey(),
            link: "/#/",
          },
          {
            id: "br-project-version-" + PROJECT.currentProjVersion(),
            class: "",
            text: PROJECT.currentProjVersion(),
            link: "/#/",
          },
          {
            id: "br-model-performance",
            class: "",
            text: "Model Performance",
            link: "",
          },
          {
            id: "br-mlleaderboard-segmentation",
            class: "",
            text: "ML Leaderboard",
            link: "",
          },
          {
            id: "br-clusterVisualization",
            class: "",
            text: "Cluster Visualization",
            link:
              "/#/mlleaderboard-segmentation/clusterVisualization/" +
              PROJECT.currentProjectKey(),
          },
        ],
      },
      mp_ba_insights: {
        breadcrumb: [
          {
            id: "br-project-" + PROJECT.currentProjectKey(),
            class: "",
            text: PROJECT.currentProjectKey(),
            link: "/#/",
          },
          {
            id: "br-project-version-" + PROJECT.currentProjVersion(),
            class: "",
            text: PROJECT.currentProjVersion(),
            link: "/#/",
          },
          {
            id: "br-model-performance",
            class: "",
            text: "Model Performance",
            link: "",
          },
          {
            id: "br-mp-ba",
            class: "",
            text: "BEHAVIOURAL ANALYSIS",
            link: "",
          },
          {
            id: "br-insights",
            class: "",
            text: "Insights",
            link: "/#/mp/ba/insights/" + PROJECT.currentProjectKey(),
          },
        ],
      },
      mp_ba_personaCreation: {
        breadcrumb: [
          {
            id: "br-project-" + PROJECT.currentProjectKey(),
            class: "",
            text: PROJECT.currentProjectKey(),
            link: "/#/",
          },
          {
            id: "br-project-version-" + PROJECT.currentProjVersion(),
            class: "",
            text: PROJECT.currentProjVersion(),
            link: "/#/",
          },
          {
            id: "br-model-performance",
            class: "",
            text: "Model Performance",
            link: "",
          },
          {
            id: "br-mp-ba",
            class: "",
            text: "BEHAVIOURAL ANALYSIS",
            link: "",
          },
          {
            id: "br-personaCreation",
            class: "",
            text: "Persona Creation",
            link: "/#/mp/ba/personaCreation/" + PROJECT.currentProjectKey(),
          },
        ],
      },

      mp_mll_discrimination: {
        breadcrumb: [
          {
            id: "br-project-" + PROJECT.currentProjectKey(),
            class: "",
            text: PROJECT.currentProjectKey(),
            link: "/#/",
          },
          {
            id: "br-project-version-" + PROJECT.currentProjVersion(),
            class: "",
            text: PROJECT.currentProjVersion(),
            link: "/#/",
          },
          {
            id: "br-model-performance",
            class: "",
            text: "Model Performance",
            link: "",
          },
          {
            id: "br-ml-leaderboard",
            class: "",
            text: "ML Leaderboard",
            link: "",
          },
          {
            id: "br-discrimination",
            class: "",
            text: "Discrimination",
            link: "/#/mp/mll/discrimination/" + PROJECT.currentProjectKey(),
          },
        ],
      },
      mp_mll_calibration: {
        breadcrumb: [
          {
            id: "br-project-" + PROJECT.currentProjectKey(),
            class: "",
            text: PROJECT.currentProjectKey(),
            link: "/#/",
          },
          {
            id: "br-project-version-" + PROJECT.currentProjVersion(),
            class: "",
            text: PROJECT.currentProjVersion(),
            link: "/#/",
          },
          {
            id: "br-model-performance",
            class: "",
            text: "Model Performance",
            link: "",
          },
          {
            id: "br-ml-leaderboard",
            class: "",
            text: "ML Leaderboard",
            link: "",
          },
          {
            id: "br-calibration",
            class: "",
            text: "Calibration",
            link: "/#/mp/mll/calibration/" + PROJECT.currentProjectKey(),
          },
        ],
      },
      mp_mll_fprAndPA: {
        breadcrumb: [
          {
            id: "br-project-" + PROJECT.currentProjectKey(),
            class: "",
            text: PROJECT.currentProjectKey(),
            link: "/#/",
          },
          {
            id: "br-project-version-" + PROJECT.currentProjVersion(),
            class: "",
            text: PROJECT.currentProjVersion(),
            link: "/#/",
          },
          {
            id: "br-model-performance",
            class: "",
            text: "Model Performance",
            link: "",
          },
          {
            id: "br-ml-leaderboard",
            class: "",
            text: "ML Leaderboard",
            link: "",
          },
          {
            id: "br-fpr",
            class: "",
            text: "FPR and Precision Analysis",
            link: "/#/mp/mll/fprAndPA/" + PROJECT.currentProjectKey(),
          },
        ],
      },
      mp_mll_gainAnalysis: {
        breadcrumb: [
          {
            id: "br-project-" + PROJECT.currentProjectKey(),
            class: "",
            text: PROJECT.currentProjectKey(),
            link: "/#/",
          },
          {
            id: "br-project-version-" + PROJECT.currentProjVersion(),
            class: "",
            text: PROJECT.currentProjVersion(),
            link: "/#/",
          },
          {
            id: "br-model-performance",
            class: "",
            text: "Model Performance",
            link: "",
          },
          {
            id: "br-ml-leaderboard",
            class: "",
            text: "ML Leaderboard",
            link: "",
          },
          {
            id: "br-ga",
            class: "",
            text: "Gain Analysis",
            link: "/#/mp/mll/gainAnalysis/" + PROJECT.currentProjectKey(),
          },
        ],
      },
      mp_mll_actualVsPredicted: {
        breadcrumb: [
          {
            id: "br-project-" + PROJECT.currentProjectKey(),
            class: "",
            text: PROJECT.currentProjectKey(),
            link: "/#/",
          },
          {
            id: "br-project-version-" + PROJECT.currentProjVersion(),
            class: "",
            text: PROJECT.currentProjVersion(),
            link: "/#/",
          },
          {
            id: "br-model-performance",
            class: "",
            text: "Model Performance",
            link: "",
          },
          {
            id: "br-ml-leaderboard",
            class: "",
            text: "ML Leaderboard",
            link: "",
          },
          {
            id: "br-actualVsPredicted",
            class: "",
            text: "Actual vs. Predicted",
            link: "/#/mp/mll/actualVsPredicted/" + PROJECT.currentProjectKey(),
          },
        ],
      },
      mp_mll_residualsPlot: {
        breadcrumb: [
          {
            id: "br-project-" + PROJECT.currentProjectKey(),
            class: "",
            text: PROJECT.currentProjectKey(),
            link: "/#/",
          },
          {
            id: "br-project-version-" + PROJECT.currentProjVersion(),
            class: "",
            text: PROJECT.currentProjVersion(),
            link: "/#/",
          },
          {
            id: "br-model-performance",
            class: "",
            text: "Model Performance",
            link: "",
          },
          {
            id: "br-ml-leaderboard",
            class: "",
            text: "ML Leaderboard",
            link: "",
          },
          {
            id: "br-residualsPlot",
            class: "",
            text: "Residuals",
            link: "/#/mp/mll/residualsPlot/" + PROJECT.currentProjectKey(),
          },
        ],
      },
      mp_mll_normality: {
        breadcrumb: [
          {
            id: "br-project-" + PROJECT.currentProjectKey(),
            class: "",
            text: PROJECT.currentProjectKey(),
            link: "/#/",
          },
          {
            id: "br-project-version-" + PROJECT.currentProjVersion(),
            class: "",
            text: PROJECT.currentProjVersion(),
            link: "/#/",
          },
          {
            id: "br-model-performance",
            class: "",
            text: "Model Performance",
            link: "",
          },
          {
            id: "br-ml-leaderboard",
            class: "",
            text: "ML Leaderboard",
            link: "",
          },
          {
            id: "br-normality",
            class: "",
            text: "Normality",
            link: "/#/mp/mll/normality/" + PROJECT.currentProjectKey(),
          },
        ],
      },
      mp_mll_predictedVsResidual: {
        breadcrumb: [
          {
            id: "br-project-" + PROJECT.currentProjectKey(),
            class: "",
            text: PROJECT.currentProjectKey(),
            link: "/#/",
          },
          {
            id: "br-project-version-" + PROJECT.currentProjVersion(),
            class: "",
            text: PROJECT.currentProjVersion(),
            link: "/#/",
          },
          {
            id: "br-model-performance",
            class: "",
            text: "Model Performance",
            link: "",
          },
          {
            id: "br-ml-leaderboard",
            class: "",
            text: "ML Leaderboard",
            link: "",
          },
          {
            id: "br-predictedVsResidual",
            class: "",
            text: "Residual vs. Predicted",
            link:
              "/#/mp/mll/predictedVsResidual/" + PROJECT.currentProjectKey(),
          },
        ],
      },
      mp_mll_pca2d: {
        breadcrumb: [
          {
            id: "br-project-" + PROJECT.currentProjectKey(),
            class: "",
            text: PROJECT.currentProjectKey(),
            link: "/#/",
          },
          {
            id: "br-project-version-" + PROJECT.currentProjVersion(),
            class: "",
            text: PROJECT.currentProjVersion(),
            link: "/#/",
          },
          {
            id: "br-model-performance",
            class: "",
            text: "Model Performance",
            link: "",
          },
          {
            id: "br-ml-leaderboard",
            class: "",
            text: "ML Leaderboard",
            link: "",
          },
          { id: "br-pca2d", class: "", text: "PCA 2d", link: "" },
        ],
      },
      mp_mll_pca3d: {
        breadcrumb: [
          {
            id: "br-project-" + PROJECT.currentProjectKey(),
            class: "",
            text: PROJECT.currentProjectKey(),
            link: "/#/",
          },
          {
            id: "br-project-version-" + PROJECT.currentProjVersion(),
            class: "",
            text: PROJECT.currentProjVersion(),
            link: "/#/",
          },
          {
            id: "br-model-performance",
            class: "",
            text: "Model Performance",
            link: "",
          },
          {
            id: "br-ml-leaderboard",
            class: "",
            text: "ML Leaderboard",
            link: "",
          },
          { id: "br-pca3d", class: "", text: "PCA 3d", link: "" },
        ],
      },
      mp_mll_predictionVsDensity: {
        breadcrumb: [
          {
            id: "br-project-" + PROJECT.currentProjectKey(),
            class: "",
            text: PROJECT.currentProjectKey(),
            link: "/#/",
          },
          {
            id: "br-project-version-" + PROJECT.currentProjVersion(),
            class: "",
            text: PROJECT.currentProjVersion(),
            link: "/#/",
          },
          {
            id: "br-model-performance",
            class: "",
            text: "Model Performance",
            link: "",
          },
          {
            id: "br-ml-leaderboard",
            class: "",
            text: "ML Leaderboard",
            link: "",
          },
          {
            id: "br-predictionVsDensity",
            class: "",
            text: "Prediction vs. Density",
            link: "",
          },
        ],
      },
      mp_eai_global: {
        breadcrumb: [
          {
            id: "br-project-" + PROJECT.currentProjectKey(),
            class: "",
            text: PROJECT.currentProjectKey(),
            link: "/#/",
          },
          {
            id: "br-project-version-" + PROJECT.currentProjVersion(),
            class: "",
            text: PROJECT.currentProjVersion(),
            link: "/#/",
          },
          {
            id: "br-model-performance",
            class: "",
            text: "Model Performance",
            link: "",
          },
          { id: "br-eai", class: "", text: "Explainable AI", link: "" },
          {
            id: "br-global",
            class: "",
            text: "Global Interpretability",
            link: "/#/mp/eai/global/" + PROJECT.currentProjectKey(),
          },
        ],
      },
      mp_eai_local: {
        breadcrumb: [
          {
            id: "br-project-" + PROJECT.currentProjectKey(),
            class: "",
            text: PROJECT.currentProjectKey(),
            link: "/#/",
          },
          {
            id: "br-project-version-" + PROJECT.currentProjVersion(),
            class: "",
            text: PROJECT.currentProjVersion(),
            link: "/#/",
          },
          {
            id: "br-model-performance",
            class: "",
            text: "Model Performance",
            link: "",
          },
          { id: "br-eai", class: "", text: "Explainable AI", link: "" },
          {
            id: "br-global",
            class: "",
            text: "Local Interpretability",
            link: "/#/mp/eai/local/" + PROJECT.currentProjectKey(),
          },
        ],
      },
      "mp_model-comparison": {
        breadcrumb: [
          {
            id: "br-project-" + PROJECT.currentProjectKey(),
            class: "",
            text: PROJECT.currentProjectKey(),
            link: "/#/",
          },
          {
            id: "br-project-version-" + PROJECT.currentProjVersion(),
            class: "",
            text: PROJECT.currentProjVersion(),
            link: "/#/",
          },
          {
            id: "br-model-performance",
            class: "",
            text: "Model Performance",
            link: "",
          },
          {
            id: "br-model-comparison",
            class: "",
            text: "Model Comparison",
            link: "/#/mp/model-comparison" + PROJECT.currentProjectKey(),
          },
        ],
      },
      "md_auto-code": {
        breadcrumb: [
          {
            id: "br-project-" + PROJECT.currentProjectKey(),
            class: "",
            text: PROJECT.currentProjectKey(),
            link: "/#/",
          },
          {
            id: "br-project-version-" + PROJECT.currentProjVersion(),
            class: "",
            text: PROJECT.currentProjVersion(),
            link: "/#/",
          },
          {
            id: "br-model-deployment",
            class: "",
            text: "Model Deployment",
            link: "",
          },
          {
            id: "br-auto-code",
            class: "",
            text: "Auto Code Generator",
            link: "/#/auto-code/" + PROJECT.currentProjectKey(),
          },
        ],
      },
      "md_score-models": {
        breadcrumb: [
          {
            id: "br-project-" + PROJECT.currentProjectKey(),
            class: "",
            text: PROJECT.currentProjectKey(),
            link: "/#/",
          },
          {
            id: "br-project-version-" + PROJECT.currentProjVersion(),
            class: "",
            text: PROJECT.currentProjVersion(),
            link: "/#/",
          },
          {
            id: "br-model-deployment",
            class: "",
            text: "Model Deployment",
            link: "",
          },
          {
            id: "br-score-models",
            class: "",
            text: "Score Models",
            link: "/#/score-models/" + PROJECT.currentProjectKey(),
          },
        ],
      },
      "mlleaderboard-txt-mclass_modelPerformanceTextMultiClassification": {
        breadcrumb: [
          {
            id: "br-project-" + PROJECT.currentProjectKey(),
            class: "",
            text: PROJECT.currentProjectKey(),
            link: "/#/",
          },
          {
            id: "br-project-version-" + PROJECT.currentProjVersion(),
            class: "",
            text: PROJECT.currentProjVersion(),
            link: "/#/",
          },
          {
            id: "br-model-performance",
            class: "",
            text: "Model Performance",
            link: "",
          },
          {
            id: "br-mlleaderboard-txt-mclass",
            class: "",
            text: "ML Leaderboard",
            link: "",
          },
          {
            id: "br-modelPerformanceTextMultiClassification",
            class: "",
            text: "Model Performance",
            link: "/#/mlleaderboard-txt-mclass/modelPerformanceTextMultiClassification/",
          },
        ],
      },
    };
    return breadcrumbMap;
  };

  return my;
})(BREADCRUMB_MAPPER || {});
