/* global CHART */
/**
 * @module DISCRIMINATION_CHART
 * @description draws the Discrimination box-chart shown on the `mp/mll/discrimination` page. Implements the 
 * [`CHART`](module-CHART.html) interface.
 */
// eslint-disable-next-line no-extra-semi
;var DISCRIMINATION_CHART=(function(my){
  my.type="discrimination";

  my.optsGenerator=function(options){
    let opts=extend({}, options);
    
    opts.bindto="#discrimSlope .graph";
    opts.id=my.type;
    opts.colors= {
        y1: CHART.colors[0],
        y2: CHART.colors[1]
    };
    
    opts.size={
      width: qs("#rocCurve").offsetWidth -30,
      height: qs("#rocCurve").offsetHeight - qs("#rocCurve h4").offsetHeight - 30,
    };
    
    return opts;
  };
  my.currentOptions=null;

  my.generate=function(options){
    let opt=null;
    if (!options) {
      opt=my.currentOptions;
    } else {
      my.currentOptions=opt=my.optsGenerator(options);
    }
    if (CHART.charts[opt.id]){
      my.clear(opt.id);
    }
    CHART.charts[opt.id]={
      element: my.createBoxChart(opt),
      isD3Chart: true,
      resize: function(){
        my.clear(opt.id);
        my.currentOptions.width = qs("#rocCurve").offsetWidth -30;
        my.currentOptions.height = qs("#rocCurve").offsetHeight - qs("#rocCurve h4").offsetHeight - 30;
        DISCRIMINATION_CHART.generate();
      },
      redraw: function(){
        DISCRIMINATION_CHART.generate();
      },
      clear: function(){
        DISCRIMINATION_CHART.clear(opt.id);
      }
    };
    return CHART.charts[opt.id];
  }

  my.createBoxChart = function(opt){
    let margin = {top: 10, right: 10, bottom: 30, left: 30};
    let width = opt.size.width - margin.left - margin.right;
    let height = opt.size.height - margin.top - margin.bottom;
    let barWidth = 30;

    // Setup a color scale for filling each box
    let colorScale = d3.scaleOrdinal([CHART.colors[3], CHART.colors[1]])
      .domain(Object.keys(opt.data));
    

    // Prepare the data for the box plots
    let boxPlotData = [], keys=[], mins=[], maxes=[];
    // eslint-disable-next-line no-unused-vars
    for (let [index, plotdata] of Object.entries(opt.data)) {
      let obj = {};
      obj["key"] = plotdata.name;
      keys.push(plotdata.name);
      mins.push(plotdata.values.minvalue); maxes.push(plotdata.values.maxvalue);
      // obj["counts"] = groupCount;
      obj["quartile"] = [plotdata.values.q1, plotdata.values.q2, plotdata.values.q3];
      obj["whiskers"] = [plotdata.values.minvalue, plotdata.values.maxvalue];
      obj["color"] = colorScale(obj["key"]);
      obj["lcolor"] = d3.color(colorScale(obj["key"])).brighter(2).hex();
      boxPlotData.push(obj);
    }

    // Compute an ordinal xScale for the keys in boxPlotData
    let xScale = d3.scalePoint()
      .domain(keys)
      .rangeRound([0, width])
      .padding([0.5]);

    // Compute a global y scale based on the global counts
    let min = d3.min(mins);
    let max = d3.max(maxes);
    let yScale = d3.scaleLinear()
      .domain([min-0.05, max+0.05])
      .range([height - 20, 0]);

    
    // append the svg obgect to the body of the page
    // appends a 'group' element to 'svg'
    // moves the 'group' element to the top left margin
    let svg=d3.select(opt.bindto).select("svg");
    if (svg.node()==null){
      svg = CHART.charts[opt.id]?CHART.charts[opt.id].element:d3.select(opt.bindto).append("svg");
    }
    svg.attr("width", width + margin.left + margin.right)
       .attr("height", height + margin.top + margin.bottom);

    let outerGroup=svg.append("g")
        .attr("transform",
              "translate(" + margin.left + "," + margin.top + ")");

    // append a group for the box plot elements
    let g = outerGroup.append("g");

    // Draw the box plot vertical lines
    // eslint-disable-next-line no-unused-vars
    let verticalLines = g.selectAll(".verticalLines")
        .data(boxPlotData)
        .enter()
      .append("line")
        .attr("x1", function(datum) { return xScale(datum.key); })
        .attr("y1", function(datum) { return yScale(datum.whiskers[0]); })
        .attr("x2", function(datum) { return xScale(datum.key); })
        .attr("y2", function(datum) { return yScale(datum.whiskers[1]); })
        .attr("stroke", function(datum){return datum.lcolor; })
        .attr("stroke-width", 1)
        .attr("fill", "none");

    // Draw the boxes of the box plot, filled and on top of vertical lines
    // eslint-disable-next-line no-unused-vars
    let rects = g.selectAll("rect")
        .data(boxPlotData)
        .enter()
      .append("rect")
        .attr("width", barWidth)
        .attr("height", function(datum) {
          let quartiles = datum.quartile;
          let height =  yScale(quartiles[0]) - yScale(quartiles[2]);      
          return height;
        })
        .attr("x", function(datum) { return xScale(datum.key) - (barWidth/2); })
        .attr("y", function(datum) { return yScale(datum.quartile[2]); })
        .attr("fill", function(datum) { return datum.color; })
        .attr("stroke", function(datum){return datum.lcolor; })
        .attr("stroke-width", 0);

    // Now render all the horizontal lines at once - the whiskers and the median
    let horizontalLineConfigs = [
      // Top whisker
      {
        x1: function(datum) { return xScale(datum.key) - barWidth/2 },
        y1: function(datum) { return yScale(datum.whiskers[0]) },
        x2: function(datum) { return xScale(datum.key) + barWidth/2 },
        y2: function(datum) { return yScale(datum.whiskers[0]) }
      },
      // Median line
      {
        x1: function(datum) { return xScale(datum.key) - barWidth/2 },
        y1: function(datum) { return yScale(datum.quartile[1]) },
        x2: function(datum) { return xScale(datum.key) + barWidth/2 },
        y2: function(datum) { return yScale(datum.quartile[1]) }
      },
      // Bottom whisker
      {
        x1: function(datum) { return xScale(datum.key) - barWidth/2 },
        y1: function(datum) { return yScale(datum.whiskers[1]) },
        x2: function(datum) { return xScale(datum.key) + barWidth/2 },
        y2: function(datum) { return yScale(datum.whiskers[1]) }
      }
    ];

    for(let i=0; i < horizontalLineConfigs.length; i++) {
      let lineConfig = horizontalLineConfigs[i];

      // Draw the whiskers at the min for this series
      // eslint-disable-next-line no-unused-vars
      let horizontalLine = g.selectAll(".whiskers")
          .data(boxPlotData)
          .enter()
        .append("line")
          .attr("x1", lineConfig.x1)
          .attr("y1", lineConfig.y1)
          .attr("x2", lineConfig.x2)
          .attr("y2", lineConfig.y2)
          .attr("stroke", function(datum){return datum.lcolor;})
          .attr("stroke-width", 1)
          .attr("fill", "none");
    }

    // Move the left axis over 25 pixels, and the top axis over 35 pixels
    //let axisY = outerGroup.append("g").attr("transform", "translate(25,0)");
    //let axisX = outerGroup.append("g").attr("transform", "translate(35,0)");

    //x-axis
    outerGroup.append("g")
      .attr("class", "discriminationAxis")
      .attr("transform", `translate(0, ${height - 20})`)
      .call(d3.axisBottom(xScale));

    // Add the Y Axis
    outerGroup.append("g")
      .attr("class", "discriminationAxis")
      .call(d3.axisLeft(yScale));

    // Add the Score
    outerGroup.append("g")
      .append("text")
      .attr("transform", `translate(0, ${height+20})`)
      .attr("x", (width / 2))             
      .attr("y", 0 - (margin.top / 2))
      .attr("text-anchor", "middle")  
      .attr("class", "discriminationTitle")
      .style("font-size", "0.8rem") 
      .attr("dominant-baseline", "hanging")
      .text(`Discrimination Score=${opt.score}`);

    return svg;
  };

  my.clear = function(id){
    CHART.charts[id].element.selectAll("*").remove();
    // CHART.charts[id].element.remove();
    delete CHART.charts[id];
  };

  return my;
}(DISCRIMINATION_CHART || {}));