var MODEL_PERFORMANCE_TEXT_MULTI_CLASSIFICATION = (function (my) {
  /**
   * @member {string} dataSource
   * @description Whether the current view is for the "test" or "training" data.
   * @private
   */
  var datasource = null;

  /**
   * @member {string} selectedModelID
   * @description Whether the current view is for the selected model ID from the dropdown.
   * @private
   */
  var selectedModelID = null;

  /**
   * @member {array} formattedOverallPerformanceTable
   * @description formatted data for overall performance table used to generate on UI.
   * @private
   */
  var formattedOverallPerformanceTable = [];

  /**
   * @member {object} data
   * @description Holds the leaderboard data.
   * @private
   */
  var data = null;

  /**
   * @member {object} mcConfig
   * @description the column details for the performance table.
   * @private
   */
  var mcConfig = null;

  /**
   * @member {object} mcConfig
   * @description the column details for the performance table , used only with test data otherwise we will get in get/config api.
   * @private
   */
  var compCols = {
    columns: [
      {
        label: "Accuracy",
        "data-key": "accuracy",
        "additional-tags": ["number"],
      },
      {
        label: "Precision",
        "data-key": "precision",
        "additional-tags": ["number"],
      },
      {
        label: "Recall",
        "data-key": "recall",
        "additional-tags": ["number"],
      },
      {
        label: "F1",
        "data-key": "f1",
        "additional-tags": ["number"],
      },
      {
        label: "Precision Micro Average",
        "data-key": "prec_micro_avg",
        "additional-tags": ["number"],
      },
      {
        label: "Precision Macro Average",
        "data-key": "prec_macro_avg",
        "additional-tags": ["number"],
      },
      {
        label: "Recall Micro Average",
        "data-key": "rec_micro_avg",
        "additional-tags": ["number"],
      },
      {
        label: "Recall Macro Average",
        "data-key": "rec_macro_avg",
        "additional-tags": ["number"],
      },
      {
        label: "F1 Micro Average",
        "data-key": "f1_micro_avg",
        "additional-tags": ["number"],
      },
      {
        label: "F1 Macro Average",
        "data-key": "f1_macro_avg",
        "additional-tags": ["number"],
      },
    ],
  };

  /**
   * @method getDataSourceOptions
   * @description fetches the available data sources by calling an API and populates the sources dropdown.
   */
  const getDataSourceOptions = async function (source) {
    let sourcesList = [];
    try {
      sourcesList = await APP.loadAvailableSources({
        projectKey: PROJECT.currentProjectKey(),
        projVersion: PROJECT.currentProjVersion(),
      });

      if (
        sourcesList.data.posts[1]["OutOfTime"] &&
        sourcesList.data.posts[1]["OutOfTime"].length > 0
      ) {
        sourcesList = sourcesList.data.posts[1]["OutOfTime"];
        if (!sourcesList) {
          sourcesList = [];
        }
      } else {
        sourcesList = [];
      }
    } catch (err) {
      console.error(err.message);
      APP.showError(err.message);
      sourcesList = [];
    }
    const selectElement = qs("#sourceSelect");
    while (selectElement.options.length > 3) {
      selectElement.remove(3);
    }
    const dataSourceList = sourcesList;
    const sourceOptionTemplate = qs("#sourceSelect .template");
    dataSourceList.forEach((src) => {
      if (empty(src.hasTarget)) {
        return;
      }
      const option = sourceOptionTemplate.cloneNode();
      ["value", "style", "class", "disabled"].forEach((attr) =>
        option.removeAttribute(attr)
      );
      option.setAttribute("value", src.id);
      option.innerText = src.name;
      selectElement.append(option);
    });
    datasource = empty(source) ? "train" : source;
  };

  /**
   * @method getModelOptions
   * @description fetches the available models and populates the model dropdown.
   */
  const getModelOptions = function (model) {
    const modelList = Object.keys(data);
    selectedModelID = empty(model) ? modelList[0] : model.toUpperCase();
    const selectElement = qs("#modelSelect");
    modelList.forEach((modelID) => {
      const optionElement = document.createElement("option");
      optionElement.value = modelID;
      optionElement.textContent = data[modelID].name;
      if (modelID === selectedModelID) {
        optionElement.selected = true;
      }
      selectElement.appendChild(optionElement);
    });
  };

  /**
   * @method dataSourceChanged
   * @description reloads the page with the selected data source from the dropdown
   */
  const dataSourceChanged = function () {
    const selectElement = qs(".model-performance-txt-mclass #sourceSelect");
    const selectedOption = selectElement.options[selectElement.selectedIndex];
    const selectedValue = selectedOption.value;
    datasource = selectedValue;
    reloadData();
    dataChanged();
  };

  /**
   * @method modelChanged
   * @description reloads the page with the selected model from the dropdown
   */
  const modelChanged = function () {
    const selectElement = qs(".model-performance-txt-mclass #modelSelect");
    const selectedOption = selectElement.options[selectElement.selectedIndex];
    const selectedValue = selectedOption.value;
    selectedModelID = selectedValue;
    datasource = "train";
    let sourceSelect = qs(".model-performance-txt-mclass #sourceSelect");
    sourceSelect.value = datasource;
    reloadData();
    getDataSourceOptions();
    dataChanged();
  };

  const dataChanged = function () {
    registerMPTextMultiClassificationListeners();
  };

  /**
   * @method reloadData
   * @description reloads the table and the side bar graph.
   */
  const reloadData = function () {
    formattedOverallPerformanceTable = [];
    loadTable();
  };

  /**
   * @method loadConfusionMatrix
   * @description loads the confusion matrix using the txtmclassconfusionmatrixtableTemplate.pug file.
   */
  const loadConfusionMatrix = function () {
    const confMatrixData = data[selectedModelID][datasource].confusion_matrix;
    const confusionMatrix = qs(
      ".model-performance-txt-mclass .confusion-matrix"
    );
    confusionMatrix.innerHTML = "";
    if (confMatrixData.status === "ready") {
      confusionMatrix.appendChild(
        createNode(
          txtmclassconfusionmatrixtableTemplate({
            data: confMatrixData.data.rows,
            headerText: ["Actual/Prediction"].concat(
              confMatrixData.data.column_names
            ),
          })
        )
      );
      const confusionMatrixTable = confusionMatrix.qs(
        ".txt-mclass-confusion-matrix-table"
      );
      let tableWidth = 10 * confMatrixData.data.column_names.length;
      confusionMatrixTable.style.width = tableWidth + "%";
      confusionMatrixTable.style.maxWidth = "100%";
    } else {
      confusionMatrix.appendChild(
        createNode(
          txtmclassemptytableTemplate({
            headingRowName: "Confusion Matrix",
          })
        )
      );
      const confusionMatrixTable = confusionMatrix.qs(
        ".txt-mclass-confusion-matrix-table"
      );
      confusionMatrixTable.style.width = "50%";
    }
  };

  /**
   * @method loadClassWisePerformanceTable
   * @description loads the class wise performance table using the txtmclasspreformancetable.pug file.
   */
  const loadClassWisePerformanceTable = function () {
    const classWiseData = data[selectedModelID][datasource].class_wise;
    const classWisePerformance = qs(
      ".model-performance-txt-mclass .performance-table-container"
    );
    classWisePerformance.innerHTML = "";
    if (classWiseData.status === "ready") {
      classWisePerformance.appendChild(
        createNode(
          txtmclasspreformancetableTemplate({
            data: classWiseData.data.rows,
            headerText: classWiseData.data.header,
          })
        )
      );
      const classWisePerformanceTable = classWisePerformance.qs(
        ".txt-mclass-performance-table"
      );
      let tableWidth = 10 * classWiseData.data.header.length;
      classWisePerformanceTable.style.width = tableWidth + "%";
      classWisePerformanceTable.style.maxWidth = "100%";
    } else {
      classWisePerformance.appendChild(
        createNode(
          txtmclassemptytableTemplate({
            headingRowName: "Class Wise Performance",
          })
        )
      );
      const classWisePerformanceTable = classWisePerformance.qs(
        ".txt-mclass-performance-table"
      );
      classWisePerformanceTable.style.width = "50%";
    }
  };

  /**
   * @method loadOverallPerformanceTable
   * @description loads the performance table using the performanceMetricBoxContainer.pug file.
   */
  const loadOverallPerformanceTable = async function () {
    if (useTestData) {
      mcConfig = compCols;
    } else {
      mcConfig = APP.getProperty(
        "project.config.modelcomp",
        PROJECT.currentProjectKey()
      );
    }
    let overallPerformanceData =
      data[selectedModelID][datasource].overall_performance;
    if (overallPerformanceData.status !== "ready") {
      overallPerformanceData = {};
    }
    if (formattedOverallPerformanceTable.length == 0) {
      formatPerformanceTabelData(overallPerformanceData, mcConfig.columns);
    }
    const performanceMetricBoxContainer = qs(
      ".model-performance-txt-mclass .performance-metric-box-container"
    );
    performanceMetricBoxContainer.innerHTML = "";
    performanceMetricBoxContainer.appendChild(
      createNode(
        performancemetricboxcontainerTemplate({
          performanceMetricData: formattedOverallPerformanceTable,
          headerKeys: Object.keys(overallPerformanceData),
        })
      )
    );
  };

  /**
   * @method formatPerformanceTabelData
   * @description formats the model performance data and makes it ready to be rendered on UI.
   */
  const formatPerformanceTabelData = function (
    performanceTableData,
    tablecols
  ) {
    for (let col of tablecols) {
      let intermediateObj = {};
      intermediateObj["name"] = col.label;
      intermediateObj["value"] = performanceTableData[col["data-key"]];
      formattedOverallPerformanceTable.push(intermediateObj);
    }
  };

  /**
   * @method getOverallPerformanceData
   * @description Loads the data from the comp API on the server and returns it
   * @param {object} iparams userHash(optional) and projectKey(required)
   * @property {string} iparams.projectKey id of the project for which to get scores
   * @return {object} Raw graph data obtained from the comp API.
   * @private
   * @async
   */
  const getOverallPerformanceData = async function (iparams) {
    let url = SERVER.getBaseAddress() + "comp";
    let userHash = CREDENTIALS.getUserCreds();
    if (userHash == null) {
      throw new Error(i18n.en.APP.UI.ERROR.MODELCFG.USER_NOT_LOGGED_IN);
    }
    let params = extend(
      {
        key: userHash,
        projectKey: "",
        projVersion: "",
      },
      iparams
    );
    APP.setProgress("Loading model data...", true);
    let result = null;
    try {
      if (useTestData) {
        result = await MC_TEST_DATA.getData(url, params);
      } else {
        result = await SERVER.postData(url, params);
      }
    } catch (err) {
      result = null;
      APP.resetProgress();
    }
    if (result === "ROUTES_MISMATCHED") {
      return;
    }
    APP.resetProgress();
    compData = result;
    return result;
  };

  /**
   * @method loadTable
   * @description loads the confusion matrix and performance table.
   */
  const loadTable = function () {
    loadOverallPerformanceTable();
    loadClassWisePerformanceTable();
    loadConfusionMatrix();
  };

  /**
   * @method registerMPTextMultiClassificationListeners
   * @description Register listeners for:
   * * source selection
   * * model selection
   * @private
   */
  const registerMPTextMultiClassificationListeners = function () {
    const modelPerformanceTextMultiClassificationPage = qs(
      "#main-content .content.ml-leaderboard-txt-mclass"
    );
    const sourceSelect =
      modelPerformanceTextMultiClassificationPage.qs("#sourceSelect");
    if (sourceSelect) {
      sourceSelect.addEventListener("change", dataSourceChanged);
    }
    const modelSelect =
      modelPerformanceTextMultiClassificationPage.qs("#modelSelect");
    if (modelSelect) {
      modelSelect.addEventListener("change", modelChanged);
    }
    const confusionMatrixTable = qs(".txt-mclass-confusion-matrix-table");
    if (confusionMatrixTable) {
      confusionMatrixTable.addEventListener("click", (evt) => {
        if (evt.target.className === "chevron-down-icon") {
          qs(`.txt-mclass-confusion-matrix-table .headerRow`).addClass(
            "hidden"
          );
          if (qs(`.txt-mclass-confusion-matrix-table .list`)) {
            qs(`.txt-mclass-confusion-matrix-table .list`).addClass("hidden");
          }
          evt.target.addClass("hidden");
          qs(
            `.txt-mclass-confusion-matrix-table .headingRow .chevron-up-icon`
          ).removeClass("hidden");
        } else if (evt.target.className === "chevron-up-icon") {
          qs(`.txt-mclass-confusion-matrix-table .headerRow`).removeClass(
            "hidden"
          );
          if (qs(`.txt-mclass-confusion-matrix-table .list`)) {
            qs(`.txt-mclass-confusion-matrix-table .list`).removeClass(
              "hidden"
            );
          }
          evt.target.addClass("hidden");
          qs(
            `.txt-mclass-confusion-matrix-table .headingRow .chevron-down-icon`
          ).removeClass("hidden");
        }
      });
    }
    const overallPerformanceTable = qs(".txt-mclass-performance-table");
    if (overallPerformanceTable) {
      overallPerformanceTable.addEventListener("click", (evt) => {
        if (evt.target.className === "chevron-down-icon") {
          qs(`.txt-mclass-performance-table .headerRow`).addClass("hidden");
          if (qs(`.txt-mclass-performance-table .list`)) {
            qs(`.txt-mclass-performance-table .list`).addClass("hidden");
          }
          evt.target.addClass("hidden");
          qs(
            `.txt-mclass-performance-table .headingRow .chevron-up-icon`
          ).removeClass("hidden");
        } else if (evt.target.className === "chevron-up-icon") {
          qs(`.txt-mclass-performance-table .headerRow`).removeClass("hidden");
          if (qs(`.txt-mclass-performance-table .list`)) {
            qs(`.txt-mclass-performance-table .list`).removeClass("hidden");
          }
          evt.target.addClass("hidden");
          qs(
            `.txt-mclass-performance-table .headingRow .chevron-down-icon`
          ).removeClass("hidden");
        }
      });
    }
  };

  const collapseTables = function () {
    const confusionMatrixTable = qs(".txt-mclass-confusion-matrix-table");
    if (confusionMatrixTable) {
      qs(`.txt-mclass-confusion-matrix-table .headerRow`).addClass("hidden");
      if (qs(`.txt-mclass-confusion-matrix-table .list`)) {
        qs(`.txt-mclass-confusion-matrix-table .list`).addClass("hidden");
      }
      qs(
        `.txt-mclass-confusion-matrix-table .headingRow .chevron-down-icon`
      ).addClass("hidden");
      qs(
        `.txt-mclass-confusion-matrix-table .headingRow .chevron-up-icon`
      ).removeClass("hidden");
    }
    const overallPerformanceTable = qs(".txt-mclass-performance-table");
    if (overallPerformanceTable) {
      qs(`.txt-mclass-performance-table .headerRow`).addClass("hidden");
      if (qs(`.txt-mclass-performance-table .list`)) {
        qs(`.txt-mclass-performance-table .list`).addClass("hidden");
      }
      qs(
        `.txt-mclass-performance-table .headingRow .chevron-down-icon`
      ).addClass("hidden");
      qs(
        `.txt-mclass-performance-table .headingRow .chevron-up-icon`
      ).removeClass("hidden");
    }
  };

  /**
   * @method resetVariables
   * @description resets all the variables upon landing the page to avoid caching issues.
   */
  const resetVariables = function () {
    datasource = null;
    selectedModelID = null;
    data = null;
  };

  /**
   * @method loadPage
   * @description loads the page for the first time
   */
  my.loadPage = async function (leaderboardData, source, model) {
    resetVariables();
    data = leaderboardData;
    qs("#main-content .content.ml-leaderboard-txt-mclass").innerHTML =
      modelperformancetextmulticlassificationTemplate();
    qs("#main-content .content.ml-leaderboard-txt-mclass").style.overflow = "";
    getModelOptions(model);
    await getDataSourceOptions(source);
    loadTable();
    registerMPTextMultiClassificationListeners();
    collapseTables();
  };

  return my;
})(MODEL_PERFORMANCE_TEXT_MULTI_CLASSIFICATION || {});
