var APP_HELPER = (function(my){

  /**
   * @method getLink
   * @description Find the link in the left nav corresponding to the current page
   * @return {Element} DOM node corresponding to the anchor tag with the href pointing to the current page
   * @public
   * used by app-actions.js, app-listeners.js
  */
  my.getLink = function(nav){
    let link=null;
    //dashboard link / will match anything so treat it separately.
    // eslint-disable-next-line no-useless-escape
    let linksList=nav.innerHTML.match(/to="([\/\w\d\-]*)"/g).map(x=>x.split('"')[1]).slice(1);
    if (STORE.here == "/"){
      link=nav.qs("#dashboard-nav-link");
    } else {
      for (let i=linksList.length-1;i>=0;i--){
        if (STORE.here.startsWith(linksList[i])){
          link=nav.qs(`[to^='${linksList[i]}']`);
          break;
        }
      }
    }
    return link;
  }

  return my;
})(APP_HELPER || {});