var VARIABLE_IMPORTANCE = (function (my) {
  /**
   * @member {string} dataSource
   * @description Whether the current view is for the "test" or "training" data.
   * @private
   */
  var datasource = null;

  /**
   * @member {string} selectedModelID
   * @description Whether the current view is for the selected model ID from the dropdown.
   * @private
   */
  var selectedModelID = null;

  /**
   * @member {object} data
   * @description Holds the leaderboard data.
   * @private
   */
  var data = null;

  /**
   * @method getDataSourceOptions
   * @description fetches the available data sources by calling an API and populates the sources dropdown.
   */
  const getDataSourceOptions = async function (source) {
    let sourcesList = [];
    try {
      sourcesList = await APP.loadAvailableSources({
        projectKey: PROJECT.currentProjectKey(),
        projVersion: PROJECT.currentProjVersion(),
      });

      if (
        sourcesList.data.posts[1]["OutOfTime"] &&
        sourcesList.data.posts[1]["OutOfTime"].length > 0
      ) {
        sourcesList = sourcesList.data.posts[1]["OutOfTime"];
        if (!sourcesList) {
          sourcesList = [];
        }
      } else {
        sourcesList = [];
      }
    } catch (err) {
      console.error(err.message);
      APP.showError(err.message);
      sourcesList = [];
    }
    const dataSourceList = sourcesList;
    const sourceOptionTemplate = qs("#sourceSelect .template");
    dataSourceList.forEach((src) => {
      if (empty(src.hasTarget)) {
        return;
      }
      const option = sourceOptionTemplate.cloneNode();
      ["value", "style", "class", "disabled"].forEach((attr) =>
        option.removeAttribute(attr)
      );
      option.setAttribute("value", src.id);
      option.innerText = src.name;
      qs("#sourceSelect").append(option);
    });
    datasource = empty(source) ? "train" : source;
    if (datasource != "train") {
      let sourceSelect = qs(".variable-importance #sourceSelect");
      sourceSelect.value = datasource;
    }
  };

  /**
   * @method getModelOptions
   * @description fetches the available models and populates the model dropdown.
   */
  const getModelOptions = function (model) {
    const modelList = Object.keys(data);
    selectedModelID = empty(model) ? modelList[0] : model.toUpperCase();
    const selectElement = qs("#modelSelect");
    modelList.forEach((modelID) => {
      const optionElement = document.createElement("option");
      optionElement.value = modelID;
      optionElement.textContent = data[modelID].name;
      if (modelID === selectedModelID) {
        optionElement.selected = true;
      }
      selectElement.appendChild(optionElement);
    });
  };

  /**
   * @method dataSourceChanged
   * @description reloads the page with the selected data source from the dropdown
   */
  const dataSourceChanged = function () {
    const selectElement = qs(".variable-importance #sourceSelect");
    const selectedOption = selectElement.options[selectElement.selectedIndex];
    const selectedValue = selectedOption.value;
    datasource = selectedValue;
    loadTable();
  };

  /**
   * @method modelChanged
   * @description reloads the page with the selected model from the dropdown
   */
  const modelChanged = function () {
    const selectElement = qs(".variable-importance #modelSelect");
    const selectedOption = selectElement.options[selectElement.selectedIndex];
    const selectedValue = selectedOption.value;
    selectedModelID = selectedValue;
    loadTable();
    tableScrollListners();
  };

  /**
   * @method highlightMaxValueInTable
   * @description highlights the maximum value in each row of the table.
   */
  const highlightMaxValueInTable = function (table) {
    qsa(`.variable-importance .${table} .list .row`).forEach((row) => {
      var maxValue = Number.MIN_VALUE;
      items = row.qsa(".column");
      for (let item of items) {
        let value = item.getAttribute("tooltip");
        maxValue = Math.max(maxValue, value);
      }
      for (item of items) {
        let value = item.getAttribute("tooltip");
        if (value == maxValue) {
          item.addClass("winner");
          break;
        }
      }
    });
  };

  /**
   * @method highlightMaxValues
   * @description calls the functions to higlight the max values for each row of the table.
   */
  const highlightMaxValues = function () {
    highlightMaxValueInTable("unsupervised-table");
    highlightMaxValueInTable("supervised-table");
  };

  /**
   * @method loadTable
   * @description loads the variable importance table with the help of variableImportanceTable.pug file.
   */
  const loadTable = function () {
    qs(".variable-importance .variable-importance-table").innerHTML = "";
    qsa(".variable-importance .variable-importance-table").forEach((table) =>
      table.appendChild(
        createNode(
          variableimportancetableTemplate({
            unsupervisedData: data[selectedModelID][datasource].clusterVarImp,
            supervisedData:
              data[selectedModelID][datasource].clusterVarImpValidation,
          })
        )
      )
    );
    highlightMaxValues();
  };

  /**
   * @method resetVariables
   * @description resets all the variables upon landing the page to avoid caching issues.
   */
  const resetVariables = function () {
    datasource = null;
    selectedModelID = null;
    data = null;
  };

  /**
   * @method registerVariableImportanceListeners
   * @description Register listeners for:
   * * separator drag-and-drop
   * * model selection
   * * window resizing
   * @private
   */
  const registerVariableImportanceListeners = function () {
    const variableImportancePage = qs(
      "#main-content .content.ml-leaderboard-segmentation"
    );
    variableImportancePage
      .qs("#sourceSelect")
      .addEventListener("change", dataSourceChanged);
    variableImportancePage
      .qs("#modelSelect")
      .addEventListener("change", modelChanged);
    tableScrollListners();

    //download csv file button listner
    qs(".download-button").addEventListener("click", () => {
      const unsupervisedData =
        data[selectedModelID][datasource].clusterVarImp || {};
      const supervisedData =
        data[selectedModelID][datasource].clusterVarImpValidation || {};
      downloadCsv(
        unsupervisedData,
        supervisedData,
        `${selectedModelID}_${datasource}_var_imp.csv`
      );
    });
  };

  /**
   * @method downloadCsv
   * @description method to download CSV file
   * @params unsupervisedData and supervisedData, which are unformatted data for the table and filename
   * @private
   */
  const downloadCsv = function (unsupervisedData, supervisedData, filename) {
    const csvData = convertJsonToCsv(unsupervisedData, supervisedData);
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    link.click();
  };

  /**
   * @method convertDataToCsv
   * @description method to convert unformatted to CSV file data
   * @params unsupervisedData and supervisedData, which are unformatted data for the table
   * @private
   */
  const convertJsonToCsv = function (unsupervisedData, supervisedData) {
    const separator = ",";
    const csvAlgoHeader = [""];
    csvAlgoHeader.push(unsupervisedData.algoName);
    for (let j = 1; j < unsupervisedData.clusterNames.length; j++) {
      csvAlgoHeader.push("");
    }
    csvAlgoHeader.push(supervisedData.algoName);
    for (let j = 1; j < supervisedData.clusterNames.length; j++) {
      csvAlgoHeader.push("");
    }
    csvAlgoHeader.join(separator);
    const csvHeader = [
      "Features",
      ...unsupervisedData.clusterNames,
      ...supervisedData.clusterNames,
    ].join(separator);
    const features = unsupervisedData.featureNames;
    const csvBody = [];
    for (let i = 0; i < features.length; i++) {
      const row = [];
      row.push(features[i]);
      for (let j = 0; j < unsupervisedData.clusterNames.length; j++) {
        row.push(unsupervisedData.varImpInfo[j][i]);
      }
      for (let j = 0; j < supervisedData.clusterNames.length; j++) {
        row.push(supervisedData.varImpInfo[j][i]);
      }
      csvBody.push(row.join(separator));
    }
    return `${csvAlgoHeader}\n${csvHeader}\n${csvBody.join("\n")}`;
  };

  const tableScrollListners = function () {
    const scrollableTableContainer = document.querySelectorAll(
      ".scrollable-table-container"
    );
    scrollableTableContainer.forEach((div) =>
      div.addEventListener("scroll", (e) => {
        scrollableTableContainer.forEach((d) => {
          d.scrollTop = div.scrollTop;
          d.scrollLeft = div.scrollLeft;
        });
      })
    );
  };

  /**
   * @method loadPage
   * @description loads the page for the first time
   */
  my.loadPage = async function (leaderboardData, source, model) {
    resetVariables();
    data = leaderboardData;
    qs("#main-content .content.ml-leaderboard-segmentation").innerHTML =
      variableimportanceTemplate();
    qs("#main-content .content.ml-leaderboard-segmentation").style.overflow =
      "";
    getModelOptions(model);
    await getDataSourceOptions(source);
    loadTable();
    registerVariableImportanceListeners();
  };

  return my;
})(VARIABLE_IMPORTANCE || {});
