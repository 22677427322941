/* global CHART */
/**
 * @module RESIDUAL_VS_PREDICTED_CHART
 * @description draws the Actual vs. Predicted chart shown on the `mp/mll/predictedVsResidual` page. Implements the 
 * [`CHART`](module-CHART.html) interface.
 */
// eslint-disable-next-line no-extra-semi
;var RESIDUAL_VS_PREDICTED_CHART=(function(my){
  my.type="predictedVsResidual";

  my.optsGenerator=function(options){
    let opts=extend({},CHART.opts);

    delete opts.axis.y.min;
    delete opts.axis.y.max;
    delete opts.axis.y.tick.values;
    delete opts.axis.x.min;
    delete opts.axis.x.max;
    delete opts.axis.x.tick.values;
    
    delete opts.axis.x.label.text;
    
    opts.bindto="#predictedVsResidualChart .graph";
    opts.axis.y.tick.format=d3.format(".1f");
    opts.axis.x.tick.format=d3.format(".1f");
    opts.axis.y.tick.culling=true;
    opts.axis.x.tick.culling=true;
    opts.axis.y.label.text="Residual";
    opts.axis.x.label.text="Predicted";
    opts.id=my.type;
    opts.data={
      xs:{
        PredictedVsResidual: "x1",
        Reference: "referencex"
      },
      columns: [
        ['x1'].concat(options.data.y1.keys),
        ['referencex'].concat(options.data.reference.keys),
        ['PredictedVsResidual'].concat(options.data.y1.values),
        ['Reference'].concat(options.data.reference.values)
      ],
      additionalData: options.data.additionalData,
      types: {
        PredictedVsResidual: 'scatter',
        referencex: 'line'        
      },
      colors: {
         PredictedVsResidual: CHART.colors[0],
         Reference: CHART.colors[2]
      }
    };
    opts.tooltip={
      contents: function(d, defaultTitleFormat, defaultValueFormat, color) {
        if (d[0].id === "PredictedVsResidual") {
          return `<table class='c3-tooltip'>
                    <tr class='c3-tooltip-name--x}'>
                      <td class='name'><span style='background-color:${color(d[0].id)}'></span>Predicted</td>
                      <td class='value'>${defaultValueFormat(d[0].x, null, d[0].id)}</td>
                    </tr>
                    <tr class='c3-tooltip-name--${d[0].id}'>
                      <td class='name'><span style='background-color:transparent'></span>Residual</td>
                      <td class='value'>${defaultValueFormat(d[0].value, null, d[0].id)}</td>
                    </tr>
                  </table>`
        }
      },
      horizontal: true
    };
    opts.legend={
      show: false,
      // hide: ['Reference']
    };
    opts.grid={
      x:{show: false},
      y:{show: true},
    };
    opts.point={
      show: true,
      r: 3
    }
    opts.size={
      width: qs("#predictedVsResidualChart").offsetWidth -30,
      height: qs("#predictedVsResidualChart").offsetHeight - qs("#predictedVsResidualChart h4").offsetHeight - 22
    };
    
    if (!empty(options.c3d3properties)){
      opts=extend(opts, options.c3d3properties);
    }
    
    return opts;
  };
  my.generate=function(options){
    let opt=my.optsGenerator(options);
    CHART.charts[opt.id]=c3.generate(opt);
    qsa("#predictedVsResidualChart .customLegend").forEach(x=>x.remove());
    const legend=createNode(`
      <div class="customLegend">
        <table>
          <tbody></tbody>
        </table>
      </div>
    `);
    let rows="";
    const format=d3.format("0.3f");
    for (let key in opt.data.additionalData) {
      const value=opt.data.additionalData[key];
      rows+=`<tr><td class="key">${key}</td><td class="value">${format(value)}</td></tr>`;
    }
    legend.qs("tbody").innerHTML=rows;
    qs("#predictedVsResidualChart .graph-container").appendChild(legend);
    return CHART.charts[opt.id];
  }

  return my;
}(RESIDUAL_VS_PREDICTED_CHART || {}));