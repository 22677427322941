/**
 * @module EMPTY_CUSTOMIZER
 * @description 
 */
var EMPTY_CUSTOMIZER=(function(my){

  my.id="empty";

  // eslint-disable-next-line no-unused-vars
  my.preRoute=function(router, params) {

  };

  // eslint-disable-next-line no-unused-vars
  my.postRoute=function(router, params) {
    
  };

  // eslint-disable-next-line no-unused-vars
  my.reroute=function(params) {
    return params;
  };
  
  return my;
}(EMPTY_CUSTOMIZER || {}));