/* global CHART, ceilPow10, MP_EAI_GLOBAL */
/**
 * @module FEATURE_IMPORTANCE_CHART
 * @description draws the Feature Importance chart shown on the `mp/eai/global` page. Implements the 
 * [`CHART`](module-CHART.html) interface.
 */
// eslint-disable-next-line no-extra-semi
;var FEATURE_IMPORTANCE_CHART=(function(my){
  my.type="feature-importance";
  my.bindto = null;

  my.optsGenerator=function(options,index,isModelInterpretability,isFeatureSelection,isSilhouetteGraph){
    if(index != null && !isModelInterpretability && !isFeatureSelection && !isSilhouetteGraph){
      my.bindto = `.global-interpretability-card-${index} #versionFeatureImportanceChart .graph`;  
    } else if(isModelInterpretability && index != null) {
      my.bindto = `.model-interpretability .side-bar-graph.side-graph-${index}`;
    } else if(isFeatureSelection && index == null) {
      my.bindto = `.variable-clustering .graphs-container .graph-container`;
    } else if(isFeatureSelection && index !=null){
      my.bindto = `.feature-count-card-${index} #featureCount .graph-container .graph`;  
    } else if(isSilhouetteGraph && index == null) {
      my.bindto = `.elbow-and-silhouette-container .graphs-container .bar-graph`;
    } else if(isSilhouetteGraph && index !=null){
      my.bindto = `.silhouette-score-card-${index} #silhouetteGraph .graph-container .graph`;  
    } else {
      my.bindto = "#featureImportanceChart .graph";
    }
    let opts=extend({},CHART.opts);
    delete opts.axis.y.tick.count;
    delete opts.axis.x.tick.count;
    
    opts.bindto=my.bindto;
    opts.id=my.type;
    // opts.axis.rotated=true;
    let ymax=1;
    let ymin=0;
    if (options.data.y1.values.length>0){
      ymax=Math.max(...options.data.y1.values);
      ymin=Math.min(...options.data.y1.values);
    }
    opts.axis.y.padding={top: 20, bottom: 20};
    if (ymax<0.0001) {
      opts.axis.y.tick.format=d3.format("-1.2e");
    } else {
      opts.axis.y.tick.format=d3.format("-1.2g");
    }
    
    opts.axis.y.tick.values=[];
    opts.axis.y.tick.rotate=45;
    opts.axis.y.tick.multiline = false;
    //calculate the range of the axis
    let graphYmax=0, graphYmin=0;
    if (ymin < 0 && ymax > 0) {
      let step = ceilPow10(ymax) / 10;

      //add positive values to ticks
      for(let i=0; (graphYmax = step * i) < ymax; i++) {
        graphYmax = step * i;
        opts.axis.y.tick.values.push(graphYmax);
      }
      opts.axis.y.tick.values.push(graphYmax);

      //add negative values to ticks
      for(let i=-1; (graphYmin = step * i) > ymin; i--) {
        opts.axis.y.tick.values.push(graphYmin);
      }
      opts.axis.y.tick.values.push(graphYmin);

      // set graph outer boundaries
      opts.axis.y.min=graphYmin;
      opts.axis.y.max=graphYmax;
    } else if (ymin < 0 && ymax <= 0) {
      let step = ceilPow10(-1 * ymin) / 10;
      graphYmax = step;
      opts.axis.y.tick.values.push(graphYmax);
      //add negative values to ticks
      for(let i=0; (graphYmin = step * i) > ymin; i--) {
        opts.axis.y.tick.values.push(graphYmin);
      }
      opts.axis.y.tick.values.push(graphYmin);
      
      // set graph outer boundaries
      opts.axis.y.min=graphYmin;
      opts.axis.y.max=graphYmax;
    } else if (ymin >= 0 && ymax > 0) {
      let step = ceilPow10(ymax) / 10;

      //add positive values to ticks
      for(let i=0; (graphYmax = step * i) < ymax; i++) {
        graphYmax = step * i;
        opts.axis.y.tick.values.push(graphYmax);
      }
      opts.axis.y.tick.values.push(graphYmax);

      graphYmin = 0;

      // set graph outer boundaries
      opts.axis.y.min=graphYmin;
      opts.axis.y.max=graphYmax;
    }
    // opts.axis.y.min=ymin;
    // opts.axis.y.max=ymax;
    opts.axis.y.tick.values.sort(function(x1,x2){return x1-x2;});
    opts.axis.x.type="category";
    opts.axis.x.tick={
      rotate: 75,
      multiline: false
    };
    opts.axis.x.height=130;
    opts.axis.x.label={
      position: "outer-middle",
      text: isFeatureSelection || isSilhouetteGraph ? "Clusters" :"Features",
    }
    opts.axis.y.label={
      position: "outer-center",
      text: isFeatureSelection ? "#Features" : isSilhouetteGraph ? "Silhouette Score" : "Importance",
    }
    opts.axis.rotated=true;
    delete opts.axis.x.max;
    delete opts.axis.x.min;
    opts.data={
      x: 'x',
      columns: [
        ['x'].concat(options.data.y1.keys),
        [options.data.y1.name].concat(options.data.y1.values),
      ],
      type: 'bar',
      colors: {
        y: CHART.colors[2],
      },
      names: {
        y: isFeatureSelection ? "Features Count" : isSilhouetteGraph ? "Silhouette Score" : "Importance"
      },
      order: "asc",
      empty: {
        label: {
          text: "No feature data available for this model."
        }
      },
    };
    if(isFeatureSelection && index == null){
      opts.data = extend(opts.data , {onclick: function (d) { FEATURE_SELECTION.sideBarListener(d) } });
    } else if(index == null && !isModelInterpretability && !isSilhouetteGraph){
      opts.data = extend(opts.data , {onclick: MP_EAI_GLOBAL.drilldown});
    }
    opts.bar={
      width: {
        ratio: 0.5,
      },
    }
    opts.legend={
      show: isFeatureSelection || isSilhouetteGraph || isModelInterpretability ? false : true,
    };
    opts.grid={
      x:{show: true},
      y:{
        show: true,
        lines: [
          {value: 0, axis: 'y', class: 'zero-line'},
        ]
      },
    };
    opts.point={
      show: true,
      r: 5
    }
    if(index != null || isModelInterpretability || isFeatureSelection || isSilhouetteGraph){
      opts.size = {
        width: isFeatureSelection ? 500 : 632,
        height: 373,
      };
    }
    else if(index == null && !isModelInterpretability && !isFeatureSelection && !isSilhouetteGraph){
      opts.size = {
        width: qs("#featureImportanceChart").offsetWidth - 30,
        height: qs("#featureImportanceChart").offsetHeight - qs("#featureImportanceChart h4").offsetHeight - 22,
      };
    }

    if (!empty(options.c3d3properties)){
      opts=extend(opts, options.c3d3properties);
    }
    
    return opts;
  };
  my.generate=function(options, index=null, graphType=null){
    let isModelInterpretability = graphType == 'modelInterpretability' ? true : false;
    let isFeatureSelection = graphType == 'featureSelection' ? true : false;
    let isSilhouetteGraph = graphType == 'silhouetteGraph' ? true : false;
    let opt=my.optsGenerator(options,index,isModelInterpretability,isFeatureSelection,isSilhouetteGraph);
    CHART.charts[opt.id]=c3.generate(opt);
    d3.select(".c3-legend-item-Importance text").text(`${options.data.scoreName}=${(d3.format(".3f"))(options.data.score)}`);
    requestAnimationFrame(function(){
      if (options.data.y1.values.length === 0) {
        qs(FEATURE_IMPORTANCE_CHART.bindto).addClass("no-data");
      } else {
        qs(FEATURE_IMPORTANCE_CHART.bindto).removeClass("no-data");
      }
    });
    return CHART.charts[opt.id];
  }

  return my;
}(FEATURE_IMPORTANCE_CHART || {}));