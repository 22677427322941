/* global dbconnectionTemplate,DB_CONNECTION_DATA*/
/**
 * @module DB_CONNECTION
 * @description this module creates, shows, handles the input and the workflow for the new DB connection dialog.
 */
var DB_CONNECTION = (function (my) {
  /**
   * @member {string} STORE_KEY the key used to cache this dialog's data in the STORE
   * @public
   */
  my.STORE_KEY = "DB_CONNECTION";

  /**
   * @member _conname stored connection name for the current DB connection
   * @private
   */
  var _conname = "123";

  var accessType = null;

  /**
   * @method getCurrentConnection
   * @description Get the current DB connection name. Exists only in the context of a project. Does not have meaning otherwise.
   * @returns {string} returns the current active DB connection.
   * @public
   */
  my.getCurrentConnection = function () {
    return _conname;
  };

  /**
   * @method getConnList
   * @description Makes an API call and get list of save connections for user.
   * @returns {object}
   * @private
   */
  let getConnList = async () => {
    let url = SERVER.getBaseAddress() + "connection/getconn";
    let result = null;
    let params = {};

    try {
      if (useTestData) {
        result = await DB_CONNECTION_DATA.getConnectionListData(url, params);
      } else {
        result = await SERVER.postData(url, params);
      }
    } catch (err) {
      console.log(err);
      result = null;
    }
    if (result === "ROUTES_MISMATCHED") {
      return;
    }
    return result;
  };

  /**
   * @method getSingleConn
   * @description Makes an API call with connection name and gets details for that connection.
   * @returns {object}
   * @private
   */
  let getSingleConn = async (connName) => {
    let url = SERVER.getBaseAddress() + "connection/get";
    let params = { conname: connName };
    let result = null;
    try {
      if (useTestData) {
        result = await DB_CONNECTION_DATA.getSingleConnData(url, params);
      } else {
        result = await SERVER.postData(url, params);
      }
    } catch (err) {
      console.log(err);

      result = null;
    }
    if (result === "ROUTES_MISMATCHED") {
      return;
    }
    return result;
  };

  /**
   * @method showDBConnectionDialog
   * @description Shows the database connection dialog. Obtains parameters for the DB connection and returns them as a promise.
   * @returns {Promise} A promise that resolves to parameters for a DB connection else rejected with the string "cancelled".
   * @param {object} iparams Either a string with the project key, or a config object with the following properties
   * @property {string} iparams.pkey project key
   * @property {string} iparams.destinationOnSuccess URL after the hash to redirect after success
   * @property {string} iparams.destinationOnError URL after the hash to redirect to on error
   * @property {string} iparams.destinationOnCancel URL after the hash to redirect to on cancel. Can also be the string "back" in which
   * case the UI will navigate back to the previous screen
   * @property {string} iparams.sourceType "development" for training. this is the data source type.
   * @public
   * @async
   */
  my.showDBConnectionDialog = async function (iparams) {
    APP.setProgress("Getting saved Connections ...");
    let result = await getConnList();
    let connectionsList = [];
    if (result["status"] == 200) {
      connectionsList = result.data.posts[0][0].conn_name;
    }
    APP.resetProgress();
    return new Promise((resolve, reject) => {
      var dlg = qs("#new-db-connection-dialog");
      if (!dlg || typeof dlg == "undefined") {
        let dbConfig = APP.getProperty("common.datacfg.dbcfg");
        dlg = createNode(dbconnectionTemplate(dbConfig));
        qs("#dialogs-sleeping").appendChild(dlg);
      }
      if (iparams.sourceType == "development") {
        dlg.qs("#dev-or-prod").checked = false;
      } else {
        dlg.qs("#dev-or-prod").checked = true;
      }
      dlg.qs("button.close").addEventListener("click", () => {
        //dialog close button
        APP.hideDialog(dlg);
        dlg.remove();
        if (iparams.destinationOnCancel != "back") {
          APP.router.navigate(iparams.destinationOnCancel);
        }
        reject("cancelled");
      });

      let existingConnRadioButton = dlg.qs("#existing-db");
      let newConnRadioButton = dlg.qs("#new-connection-db");
      let selectInput = dlg.qs("#select-named-connection");

      if (connectionsList.length > 0) {
        selectInput.innerHTML = "";
        existingConnRadioButton.disabled = false;
        connectionsList.forEach((conn) => {
          let opt = document.createElement("option");
          opt.innerHTML = conn;
          opt.value = conn;
          selectInput.appendChild(opt);
        });
      }

      let setFormValues = (connDetails) => {
        dlg.qs("#input-new-named-connection").value = connDetails.conname;
        dlg
          .qs("#input-new-named-connection")
          .dispatchEvent(new Event("change"));
        dlg.qs("#select-db-type").value = connDetails.dbtype;
        dlg.qs("#input-db-name").value = connDetails.dbname;
        dlg.qs("#input-table-name").value = connDetails.tblname;
        dlg.qs("#input-db-server-name").value = String(
          connDetails.serverip
        ).trim();
        dlg.qs("#input-port-number").value = String(connDetails.portno).trim();
        dlg.qs("#input-db-username").value = connDetails.uname;
        dlg.qs("#input-db-password").value = "";
        dlg.qs("#dev-or-prod").checked = connDetails.sourcetype;
        dlg
          .qsa(
            "#input-new-named-connection, #select-db-type, #input-db-name, #input-table-name, #input-db-server-name, #input-port-number, #input-db-username, #input-db-password"
          )
          .forEach(function (x) {
            x.setAttribute("not-empty", true);
            if (x.validity.patternMismatch == false) {
              x.removeClass("invalid");
            }
          });
      };

      let loadConnDetails = (evt) => {
        APP.setProgress("Getting Connection Details...");
        getSingleConn(evt.target.value).then((connDetails) => {
          APP.resetProgress();
          if (connDetails.status == 200) {
            connDetails = connDetails.data.posts[0];
            connDetails = JSON.parse(connDetails);
            setFormValues(connDetails);
          }
        });
      };

      existingConnRadioButton.addEventListener("change", (evt) => {
        selectInput.disabled = false;
        loadConnDetails({ target: { value: selectInput.value } });
      });

      newConnRadioButton.addEventListener("change", (evt) => {
        selectInput.disabled = true;
      });

      selectInput.addEventListener("change", loadConnDetails);

      let changeListener = function () {
        //re-disable next button and remove test button's overlays
        CONNECTION_DIALOG.setDlgButtonStatus("untested", "db");
      };
      dlg
        .qsa(
          "#input-new-named-connection, #select-db-type, #input-db-name, #input-table-name, #input-db-server-name, #input-port-number, #input-db-username, #input-db-password"
        )
        .forEach(function (x) {
          x.addEventListener("change", changeListener);
          x.addEventListener("keydown", changeListener);
        });

      let clickListener = function (evt) {
        //test/next buttons clicked.
        let allValid = true;
        dlg
          .qsa(
            "#input-new-named-connection, #select-db-type, #input-db-name, #input-table-name, #input-db-server-name, #input-port-number, #input-db-username, #input-db-password"
          )
          .forEach(function (x) {
            if (x.hasClass("invalid")) {
              allValid = false;
            }
          });
        if (!allValid) {
          CONNECTION_DIALOG.setDlgButtonStatus("fail", "db");
          return;
        }
        var params = {
          connectionType:
            evt.currentTarget.id == "test-button" ? "test" : "final",
          conname: dlg.qs("#input-new-named-connection").value,
          dbtype: dlg.qs("#select-db-type").value,
          dbname: dlg.qs("#input-db-name").value,
          tblname: dlg.qs("#input-table-name").value,
          serverip: dlg.qs("#input-db-server-name").value,
          portno: dlg.qs("#input-port-number").value,
          uname: dlg.qs("#input-db-username").value,
          passwd: dlg.qs("#input-db-password").value,
          sourcetype: dlg.qs("#dev-or-prod").checked,
          datatype: dlg.qs("#dev-or-prod").checked ? "OUT_OF_TIME" : "IN_TIME",
        };
        //need to remove the listeners on buttons and input/select controls because the resolve in the event listener is for to this Promise instance.
        //it will be a new promise instance when the function is called again once the test goes through.
        dlg.qs("#test-button").removeEventListener("click", clickListener);
        dlg.qs("#next-button").removeEventListener("click", clickListener);
        dlg
          .qsa(
            "#input-new-named-connection, #select-db-type, #input-db-name, #input-table-name, #input-db-server-name, #input-port-number, #input-db-username, #input-db-password"
          )
          .forEach(function (x) {
            x.removeEventListener("change", changeListener);
            x.removeEventListener("keydown", changeListener);
          });
        resolve(params);
      };
      dlg.qs("#test-button").addEventListener("click", clickListener);
      dlg.qs("#next-button").addEventListener("click", clickListener);
      if (!dlg.open) APP.showDialog(dlg);
      if (!empty(dlg.qsa(".close:focus"))) {
        dlg.qs("#input-new-named-connection").focus();
      }
      accessType = readCookie("accessType");
      handleButtonDisabling();
    });
  };

  const handleButtonDisabling = function () {
    let testButton = qs("#new-db-connection-dialog #test-button");
    if (accessType == "view") {
      testButton.disabled = true;
      testButton.setAttribute("tooltip", "Permission Denied");
      testButton.setAttribute("flow", "middle-top");
    }
  };

  /**
   * @method dbConnection
   * @description Create a database connection by showing a DB connection dialog and getting connection
   * parameters from the user. Doesn't dismiss the dialog until the user is done testing.
   * @param {object} iparams Either a string with the project key, or a config object with the following properties
   * @property {string} iparams.pkey project key
   * @property {string} iparams.destinationOnSuccess URL after the hash to redirect after success
   * @property {string} iparams.destinationOnError URL after the hash to redirect to on error
   * @property {string} iparams.destinationOnCancel URL after the hash to redirect to on cancel. Can also be the string "back" in which
   * case the UI will navigate back to the previous screen
   * @property {string} iparams.sourceType "development" for training. this is the data source type.
   * @return {Promise} a promise that is resolves to the connection params or rejects with "cancelled"
   * @async
   * @public
   */
  my.dbConnection = async function (iparams) {
    let projectKey = null;

    const defaultParams = {
      destinationOnSuccess: "/workflow-start",
      destinationOnError: "/",
      destinationOnCancel: "back",
      sourceType: "development",
    };
    if (typeof iparams == "string") {
      iparams = {
        pkey: iparams,
      };
    }
    iparams = extend(defaultParams, iparams);

    // if (typeof iparams=="string"){
    //   iparams={
    //     pkey: iparams,
    //     destinationOnSuccess: "/workflow-start",
    //     destinationOnError: "/",
    //     destinationOnCancel: "back",
    //     sourceType: "development",
    //   };
    // }
    projectKey = iparams.pkey;
    let params = { connectionType: "test" };
    while (params.connectionType == "test") {
      try {
        params = await my.showDBConnectionDialog(iparams);
        params.projectKey = projectKey
          ? projectKey
          : PROJECT.currentProjectKey();
        if (params.connectionType == "test") {
          let result = await createDBConnection(params, true);
          if (
            result != null &&
            (result.status == "success" ||
              (result.status >= 200 && result.status < 300))
          ) {
            CONNECTION_DIALOG.setDlgButtonStatus("success", "db");
            APP.resetProgress();
          } else {
            CONNECTION_DIALOG.setDlgButtonStatus("fail", "db");
            APP.resetProgress();
          }
          continue;
        }
        let dlg = qs("#new-db-connection-dialog");
        APP.hideDialog(dlg);
        dlg.remove();
        let result = await createDBConnection(params, false);
        if (
          result != null &&
          (result.status == "success" ||
            (result.status >= 200 && result.status < 300))
        ) {
          _conname = params.conname;
          APP.resetProgress();
          STORE.setProjectMetadata(
            params.projectKey,
            PROJECT.currentProjVersion(),
            DB_CONNECTION.STORE_KEY + "_connected",
            true
          );
          if (iparams.destinationOnSuccess != "back") {
            APP.router.navigate(iparams.destinationOnSuccess);
          }
          setTimeout(
            APP.showInfo,
            200,
            i18n.en.APP.UI.ERROR.DB_CONNECTION.GENERIC_SUCCESS
          );
          return Promise.resolve(params);
        } else {
          APP.resetProgress();
          if (iparams.destinationOnError != "back") {
            APP.router.navigate(iparams.destinationOnError);
          }
          APP.showError(
            i18n.en.APP.UI.ERROR.DB_CONNECTION.GENERIC + ": " + result.status
          );
          return Promise.reject(
            i18n.en.APP.UI.ERROR.DB_CONNECTION.GENERIC + ": " + result.status
          );
        }
      } catch (err) {
        APP.resetProgress();
        if ("cancelled" == err) {
          // setTimeout(APP.showWarning, 200, (i18n.en.APP.UI.WARNING.DB_CONNECTION.CANCELLED));
          if (iparams.destinationOnCancel != "back") {
            APP.router.navigate(iparams.destinationOnCancel);
          }
          return Promise.reject(err);
        } else {
          setTimeout(APP.showError, 200, err.message);
          CONNECTION_DIALOG.setDlgButtonStatus("fail", "db");
          if (params.testOrFinal == "final") {
            APP.router.navigate(iparams.destinationOnError);
          }
        }
      }
    }
    if (!params) {
      APP.showError(i18n.en.APP.UI.ERROR.DB_CONNECTION.GENERIC);
      if (iparams.destinationOnError) {
        if (iparams.destinationOnError != "back") {
          APP.router.navigate(iparams.destinationOnError);
        }
      } else {
        APP.router.navigate("/");
      }
      return Promise.reject(i18n.en.APP.UI.ERROR.DB_CONNECTION.GENERIC);
    }
  };

  /**
   * @method createDBConnection
   * @description Makes a server call to create a DB connection
   * @param {Object} iparams parameters for the DB connection
   * @property {Number} iparams.key userHash for this server session
   * @property {string} iparams.conname name to assign this connection
   * @property {string} iparams.dbtype database type: Oracle, MySQL, PostgreSQL, Hive
   * @property {string} iparams.tblname the table in the DB to connect to
   * @property {string} iparams.serverip FQN or ip addresss of the DB server
   * @property {string} iparams.portno Port# for the DB connection
   * @property {string} iparams.uname DB username
   * @property {string} iparams.passwd DB password for iparams.uname
   * @property {string} iparams.datatype Which kind of data is it IN_TIME or OUT_OF_TIME
   * @property {boolean} isTest test connection params or actually establish connection
   * @private
   * @async
   */
  var createDBConnection = async function (iparams, isTest) {
    let url = SERVER.getBaseAddress() + "connection/db/test";
    if (!isTest) {
      url = SERVER.getBaseAddress() + "connection/db/new";
    }
    let userHash = CREDENTIALS.getUserCreds();
    if (isTest) {
      APP.setProgress(i18n.en.APP.UI.FOOTER.PROGRESS.TEST_CONNECTION);
    } else {
      APP.setProgress(i18n.en.APP.UI.FOOTER.PROGRESS.CREATE_CONNECTION);
    }
    if (userHash == null) {
      throw new Error(i18n.en.APP.UI.ERROR.DB_CONNECTION.GENERIC);
    }
    let params = extend(
      {
        key: userHash,
        projVersion: PROJECT.currentProjVersion(),
        conname: "",
        dbtype: "",
        dbname: "",
        tblname: "",
        serverip: "",
        portno: "",
        uname: "",
        passwd: "",
        datatype: "IN_TIME",
      },
      iparams
    );
    let keys = [];
    Object.keys(params).forEach((k) => {
      if ("" === params[k]) {
        keys.push(k);
      }
    });
    if (keys.length > 0) {
      let err = new Error(
        `${i18n.en.APP.UI.ERROR.DB_CONNECTION.EMPTY_INPUT}:\n${keys.join(", ")}`
      );
      err.name = "InputError";
      throw err;
    }
    let result = null;
    try {
      if (useTestData) {
        result = await DB_CONNECTION_DATA.connect(url, params);
      } else {
        result = await SERVER.postData(url, params);
      }
    } catch (err) {
      result = null;
    }
    if (result === "ROUTES_MISMATCHED") {
      return;
    }
    if (
      result == null ||
      (result.status != "success" &&
        !(result.status >= 200 && result.status < 300))
    ) {
      let reason = "";
      if (!empty(result) && !empty(result.data) && !empty(result.data.reason)) {
        reason = result.data.reason;
      }
      let err = new Error(
        `${i18n.en.APP.UI.ERROR.DB_CONNECTION.GENERIC} ${reason}`
      );
      err.name = "DBConnectionError";
      throw err;
    }
    return result;
  };

  /**
   * @method hideDialog
   * @description hide the db connection dialog by simulating a close button click. Will trigger the
   * callback normally called on clicking the dialog's close button.
   * @public
   */
  my.hideDialog = function () {
    var dlg = qs("#new-db-connection-dialog");
    if (dlg && !dlg.hidden) {
      // dlg.qs("button.close").fireCustomEvent("click");
      APP.hideDialog(dlg);
      dlg.remove();
      // if (iparams.destinationOnCancel != "back"){
      //   APP.router.navigate(iparams.destinationOnCancel);
      // }
    }
  };

  return my;
})(DB_CONNECTION || {});
