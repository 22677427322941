// eslint-disable-next-line no-redeclare
/*global APP:writable, SERVER_CONFIG_TEST_DATA, configFilePath, PROJECT_CONFIG_TEST_DATA,
   */
/**
 * @module APP
 * @description Provides the application entrypoint and app level scope for
 * methods that affect the whole application. This file extends the module
 * with methods for storing and syncing configuration values, updating them,
 * setting new values. Currently it does not auto-update using server push.
 * This should probably be added at some point.
 * It stores configuration in objects in the store. It creates an object for
 * client storage, server storage and one is created for each new project that
 * gets loaded. When a particular config key needs to be looked up it is checked
 * in the project level, server level and then the client level. If a User level
 * is created at some point, we will need to add that on top of the project
 * level.
 */
// eslint-disable-next-line no-extra-semi
;APP=(function(my){
  /**
   * @member _configStore
   * @description stores multi-level configuration information
   * @private
   */
  const _configStore = {
    client: {},
    preferences: {},
    server: {},
    project: {},
  };

  /**
   * @method getClientStore
   * @description return the [_configStore.client](#~_configStore) cached store, or load it if empty.
   * Use these instead of directly accessing the configStore in order to ensure that the store is loaded.
   * @return the client store instance
   * @private
   * @async
   */
  // eslint-disable-next-line no-unused-vars
  const getClientStore = async function() {
    if (empty(_configStore.client)) {
      await my.loadClientConfig();
    }
    return _configStore.client;
  };

  /**
   * @method getServerStore
   * @description return the [_configStore.server](#~_configStore) cached store, or load it if empty.
   * Use these instead of directly accessing the configStore in order to ensure that the store is loaded.
   * @return the server store instance
   * @private
   * @async
   */
  // eslint-disable-next-line no-unused-vars
  const getServerStore = async function() {
    if (empty(_configStore.server)) {
      await my.loadServerProperty();
    }
    return _configStore.server;
  };

    /**
   * @method getProjectStore
   * @description return the correct [_configStore.project](#~_configStore) cached store, or load it if empty.
   * Use these instead of directly accessing the configStore in order to ensure that the store is loaded.
   * @return the project store instance
   * @private
   * @async
   */
  // eslint-disable-next-line no-unused-vars
  const getProjectStore = async function(projectKey) {
    if (empty(projectKey)) {
      projectKey=PROJECT.currentProjectKey();
    }
    if (empty(_configStore.project) || empty(_configStore.project[projectKey])) {
      await my.loadProjectProperty({projectKey: projectKey});
    }
    return _configStore.project[projectKey];
  };

  /**
   * @method ensureProjectConfigLoaded
   * @description ensures that the project level config has been loaded from the server. It has not been
   * loaded, [getProjectStore()](#~getProjectStore) will be called to load the config.
   * @param {string} projectKey
   */
  my.ensureProjectConfigLoaded = async function(projectKey) {
    let loaded=false;
    try {
      if (empty(projectKey)) {
        projectKey=PROJECT.currentProjectKey();
      }
      if (empty(_configStore.project[projectKey])) {
        await getProjectStore(projectKey);
      }
      loaded=true;
    } catch (err) {
      APP.showError(err);
    }
    return loaded;
  };

  /**
   * @method ensureServerConfigLoaded
   * @description ensures that the server level config has been loaded from the server. It has not been
   * loaded, [getServerStore()](#~getServerStore) will be called to load the config.
   */
  my.ensureServerConfigLoaded = async function() {
    let loaded=false;
    try {
      if (empty(_configStore.server)) {
        await getServerStore();
      }
      loaded=true;
    } catch (err) {
      APP.showError(err.message);
    }
    return loaded;
  };

  /**
   * @method ensureRemoteConfigsLoaded
   * @description A shorthand for executing [ensureServerConfigLoaded](#~ensureServerConfigLoaded) and [ensureProjectConfigLoaded](#~ensureProjectConfigLoaded)
   * in parallel and returning true if both are true.
   * @return a promise that resovlves to true if both configs are loaded else false.
   */
  my.ensureRemoteConfigsLoaded = async function(projectKey) {
    let loaded=true;
    const p=async function(projectKey) {
      return Promise.all([my.ensureProjectConfigLoaded(projectKey), my.ensureServerConfigLoaded()]);
    }
    const reducer = (acc, cv) => acc && cv;
    const configsStatuses = await p(projectKey);
    loaded=configsStatuses.reduce(reducer, loaded);
    return loaded;
  };

  /**
   * @method initConfig
   * @description initialize the APP config module. Call the constituent init functions and load
   * configuration information from persistence.
   * @public
   * @async
   */
  my.initConfig=async function(){
    await loadClientConfig();
  };

  /**
   * @method loadClientConfig
   * @description load `/client-configuration.json` and add its values into the [_configStore](#~_configStore)
   * @private
   * @async
   */
  const loadClientConfig = async function(){
    if (!empty(_configStore.client)) {
      return Promise.resolve();
    }
    return fetch(configFilePath, {
      cache: 'no-cache',
      credentials: 'omit',
      headers: {
        'content-type': 'application/json',
      },
      method: 'GET',
      redirect: 'follow', // manual, *follow, error
      referrer: 'no-referrer', // *client, no-referrer
    })
    .then(response => response.json())
    .then(config => {
      _configStore.client=config;
    })
    .catch(err=>console.error(err));
  };

  /**
   * @method integrateResult
   * @description the result passed back when configuration is retrieved from the server is the
   * value for the particular property key that is queried. It needs to be placed in the
   * correct location in the correct location on the config store.
   * This is a utility function. It is also the used in the test module for this module
   * @param store the config store that needs to be updated
   * @param key the dot separated key that was queried to get this result
   * @param configVal the result object (the one inside the posts) that is received from the server
   * @public
   */
  my.integrateResult = function(store, key, configVal) {
    let propVal = {}, currentVal = propVal;
    let keys=key.split(".");
    for (let i=0;i<keys.length; i++) {
      if (i==keys.length-1) {
        if (!currentVal[keys[i]] || (typeof currentVal[keys[i]] === "undefined")) {
          currentVal[keys[i]] = configVal;
        } else if (typeof currentVal[keys[i]] !== typeof configVal) {
          currentVal[keys[i]] = configVal;
        } else if (typeof currentVal[keys[i]] !== "object") {
          currentVal[keys[i]] = configVal
        } else if (typeof currentVal[keys[i]] === "object") {
          currentVal[keys[i]] = extend(currentVal[keys[i]], configVal);
        }
      } else {
        if (empty(currentVal[keys[i]])) {
          currentVal[keys[i]] = {};
        }
        currentVal=currentVal[keys[i]];
      }
    }
    store=extend(store, propVal);
  };

  /**
   * @method saveRemoteProperty
   * @description set a server level property. Will call the set api and update the provided propKey and propVal
   * on the server. If successful, will also update the local cache.
   * @param {object} param parameters containing propKey, propVal and projectKey. The last is only needed
   * if a project level property is being saved.
   * @returns {boolean} Returns true if successfull
   * @private
   * @throws {Error} Throws an error if required parameters are missing or if the save was unsuccessful or
   * if a user isn't logged in.
   * @async
   */
  const saveRemoteProperty = async function(params) {
    let url=SERVER.getBaseAddress() + 'config/set',
        userHash=CREDENTIALS.getUserCreds();

    extend({
      key: userHash,
      projectKey: null,
      propKey: null
    }, params);

    if (userHash == null){
      APP.resetProgress();
      let msg=sprintf(i18n.en.APP.UI.ERROR.USER_NOT_LOGGED_IN_GENERIC, i18n.en.APP.UI.ERROR.CONFIGURATION.CANNOT_SET_SERVER_CONFIG);
      msg=sprintf(msg, "");
      let err=new Error(msg);err.name='ConfigError';throw err;
    }

    if (empty(params.propKey)) {
      APP.resetProgress();
      let msg=sprintf(i18n.en.APP.UI.ERROR.CONFIGURATION.SAVE_SERVER_CONFIG_MISSING_PARAMS, "propKey");
      let err=new Error(msg);err.name='ConfigError';throw err;
    }

    let result=null;
    try {
      if (useTestData){
        result=await SERVER_CONFIG_TEST_DATA.setConfig(url, params);
      } else {
        result=await SERVER.postData(url, params);
      }
    } catch(err) {
      result=null;
    }
    if(result === "ROUTES_MISMATCHED"){
      return;
    }
    if (result==null || result.status < 200 || result.status >= 300){
      APP.resetProgress();
      let msg = null;
      if (result && result.data && result.data.reason) {
        msg = sprintf(i18n.en.APP.UI.ERROR.CONFIGURATION.CANNOT_SAVE_SERVER_CONFIG, result.data.reason);
      } else {
        msg = sprintf(i18n.en.APP.UI.ERROR.CONFIGURATION.CANNOT_SAVE_SERVER_CONFIG, "");
      }
      let err=new Error(msg);err.name='ConfigError';throw err;
    }

    return true;
  };

  /**
   * @method saveServerProperty
   * @description Set the value of a dot separated server (non-project) property. Will show a UI error
   * in case of error
   * @param {object} params the server call requires the userHash, propKey and propVal. The first
   * is autofetched.
   * @return true if no error occurs while saving
   * @public
   * @async
   */
  my.saveServerProperty = async function(params) {
    try {
      let success=await saveRemoteProperty(params);
      if (success) {
        my.integrateResult(_configStore.server, params.propKey, params.propVal);
        return true;
      }
    } catch (err) {
      APP.showError(err.message);
      console.error(err);
    }
    return false;
  }

  /**
   * @method saveProjectProperty
   * @description Set the value of a dot separated project property. Will show a UI error
   * in case of error
   * @param {object} params the server call requires the userHash, propKey and propVal. The first
   * is autofetched.
   * @public
   * @return true if no error occurs while saving
   * @async
   */
  my.saveProjectProperty = async function(params) {
    params=extend({projectKey: PROJECT.currentProjectKey()}, params);
    try {
      let success=await saveRemoteProperty(params);
      if (success) {
        my.integrateResult(_configStore.project[params.projectKey], params.propKey, params.propVal);
        return true;
      }
    } catch (err) {
      APP.showError(err.message);
      console.error(err);
    }
    return false;
  }

  /**
   * @method loadRemoteProperty
   * @description Should be called after logging in to load configuration from the API server.
   * for future retrieval and aggregation.
   * @param params userHash as `key` and an optional 'projectKey` as project ID. Unlike other such
   * methods, projectKey is not autofetched. Specify it if you need a project based property.
   * `propKey` contains a dot separated property key. If you ask for a project specific property without
   * a project being active you will get an error.
   * @private
   * @return the value fetched from the server. Usually will be used to integrate into the [configStore](#~_configStore)
   * @async
   */
  const loadRemoteProperty=async function(params){
    let url=SERVER.getBaseAddress() + 'config/get',
    userHash=CREDENTIALS.getUserCreds();

    APP.setProgress("Loading config...");

    params=extend({
      key: userHash,
      projectKey: null,
      propKey: null,
      projVersion: null
    }, params);

    if (userHash == null){
      APP.resetProgress();
      let msg=sprintf(i18n.en.APP.UI.ERROR.USER_NOT_LOGGED_IN_GENERIC, i18n.en.APP.UI.ERROR.CONFIGURATION.CANNOT_RETRIEVE_SERVER_CONFIG);
      msg=sprintf(msg, "");
      let err=new Error(msg)
      throw err;
    }

    if (empty(params.propKey)) {
      APP.resetProgress();
      let msg=sprintf(i18n.en.APP.UI.ERROR.CONFIGURATION.LOAD_SERVER_CONFIG_MISSING_PARAMS, "propKey");
      let err=new Error(msg)
      throw err;
    }

    let result=null;
    try {
      if (useTestData){
        if (!empty(params.projectKey)) {
          result=await PROJECT_CONFIG_TEST_DATA.getConfig(url, params);
        } else {
          result=await SERVER_CONFIG_TEST_DATA.getConfig(url, params);
        }
      } else {
        result=await SERVER.postData(url, params);
      }
    } catch(err) {
      result=null;
    }
    if(result === "ROUTES_MISMATCHED"){
      return;
    }
    if (result==null || result.status < 200 || result.status >= 300){
      APP.resetProgress();
      let msg = null;
      if (result && result.data && result.data.reason) {
        msg = sprintf(i18n.en.APP.UI.ERROR.CONFIGURATION.CANNOT_RETRIEVE_SERVER_CONFIG, result.data.reason);
      } else {
        msg = sprintf(i18n.en.APP.UI.ERROR.CONFIGURATION.CANNOT_RETRIEVE_SERVER_CONFIG, "");
      }
      let err=new Error(msg);err.name='GenericError';throw err;
    }
    APP.resetProgress();
    return result.data.posts[0];
  };

  /**
   * @method loadServerProperty
   * @description called after log-in to retrive common config information from the server
   * @param params the property to be retrieved from the server. If no param or propKey is passed,
   * `{propKey: "common"}` will be assumed.
   * @public
   * @async
   */
  my.loadServerProperty=async function(params) {
    try {
      if (empty(params)) {
        params={};
      }
      if (empty(params.propKey)) {
        params.propKey="common";
      }
      let propVal=await loadRemoteProperty(params);
      // my.integrateResult(_configStore.server, params.propKey, propVal);
      _configStore.server = extend(_configStore.server || {}, propVal);
      return my.findValue(propVal, params.propKey);
    } catch (err) {
      // APP.showError(err.message);
      console.warn("%cTemporarily disabled display of server config load error. RE-ENABLE AFTER MERGE OF GENERALIZATION BRANCH.", "font-size: 1.2rem; background-color: #e5229f;")
      console.error(err);
    }
    return null;
  };

  /**
   * @method loadProjectProperty
   * @description load the project level configuration/capability information received upon
   * querying the server and adding it into the [_configStore](#~_configStore) for retrieval
   * and aggregation
   * @param params {object} the property to be loaded is `params.propKey'. If no `params.projectKey`
   * is passed it will be retrieved from the current project. If one doesn't exist, an error
   * will be thrown.
   * @public
   * @async
   */
  my.loadProjectProperty=async function(params) {
    if (empty(params)) {
      params={};
    }
    if (empty(params.projectKey)) {
      params.projectKey = PROJECT.currentProjectKey();
    }
    if (empty(params.propKey)) {
      params.propKey="project";
    }
    if(empty(params.projVersion)){
      let currentProject = PROJECT.currentProject();
      if(PROJECT.currentProjVersion()){
        params.projVersion = PROJECT.currentProjVersion();
      }
      else if (currentProject){
        let comparableVerison = currentProject.versionInfo.find(v=> v.state>5);
          params.projVersion = comparableVerison.vname;
      } else {
        params.projVersion = 'V-1';
      }
    }
    try {
      let propVal= await loadRemoteProperty(params);
      // my.integrateResult(_configStore.project[params.projectKey], params.propKey, propVal);
      _configStore.project[params.projectKey]=extend(_configStore.project[params.projectKey] || {}, propVal);
      return my.findValue(propVal, params.propKey);
    } catch (err) {
      APP.showError(err.message);
      console.error(err);
    }
    return null;
  };

  /**
   * @method resetProjectConfig
   * @description Empty out the project level config from the [_configStore](#~_configStore)
   * @public
   */
  my.resetProjectConfig=function(pKey){
    _configStore.project[pKey]={};
  };

  /**
   * @method findValue
   * @description Parse a `'.'` separated key to find the keys in the config tree that identify
   * the required configuration property and retrieve its value.
   * This is a utility function. Also called from the test module.
   * @return the required property value if found, `null` otherwise
   * @param store {object} The configuration object in which to search for the key
   * @param propKey {string} a `'.'` separated key like `trunk.branch.leaf` or `trunk.branch` or just `trunk`
   * @public
   */
  my.findValue=function(store, propKey) {
    const keys=propKey.split(".");
    let value = store;
    for (let i=0; i<keys.length && !empty(value); i++) {
      value=value[keys[i]];
    }
    return strictEmpty(value)?null:value;
  }

  /**
   * @method getPreferenceProperty
   * @description Preferences are user level configurations. Currently unimplemented
   * @param {*} key
   * @public
   * @return null
   */
  // eslint-disable-next-line no-unused-vars
  my.getPreferenceProperty=function(key) {
    //do nothing
    return null;
  };


  /**
   * @method setPreferenceProperty
   * @description Currently unimplemented
   * @param {string} key
   * @param {any} value
   * @public
   */
  // eslint-disable-next-line no-unused-vars
  my.setPreferenceProperty=function(key, value) {
    //do nothing
  }

  /**
   * @method writePreferences
   * @description flush preferences from [_configStore.preferences](#~_configStore) to the actual
   * serialization location. Currently unimplemented
   * @private
   * @async
   */
  // eslint-disable-next-line no-unused-vars
  const writePreferences=async function() {
    //do nothing for now
  }

  /**
   * @method getProjectProperty
   * @description Retrieve a project level property from the [_configStore](#~_configStore).
   * @param {string} propKey
   * @param {string} projectKey (Optional) Uses currently active
   * @return the property value, or null if not found
   * @private
   */
  const getProjectProperty=function(propKey, projectKey) {
    let value=null;
    if (empty(projectKey)) {
      try {
        projectKey=PROJECT.currentProjectKey();
      } catch (err) {
        console.warn("no active project. skipping.");
        projectKey=null;
      }
    }
    if (!empty(projectKey)) {
      value = my.findValue(_configStore.project[projectKey], propKey);
    }
    return value;
  };

  /**
   * @method getServerProperty
   * @description retrive a server level property from the [_configStore](#~_configStore)
   * @param {string} propKey
   * @return the property value, or null if not found
   * @private
   */
  const getServerProperty = function(propKey) {
    return my.findValue(_configStore.server, propKey);
  };

  /**
   * @method getClientProperty
   * @description retrieve a client level configuration from the [_configStore](#~configStore)
   * @param {string} propKey
   * @return the property value, or null if not found
   * @private
   */
  const getClientProperty = function(propKey) {
    return my.findValue(_configStore.client, propKey);
  };

  /**
   * @method getProperty
   * @description go through Preference->Project->Server->Client config stores to return the first
   * hit for a particular property
   * @param {string} propKey Dot separated configuration property key
   * @param {string} projectKey (Optional) defaults to currently active project. Needed only for project
   * level property
   * @return the property value, or null if not found
   * @public
   */
  my.getProperty=function(propKey, projectKey) {
    let value=null;

    //check in preferences
    value = my.getPreferenceProperty(propKey);
    if (!empty(value)) {
      return value;
    }

    //check in project
    value = getProjectProperty(propKey, projectKey);
    if (!empty(value)) {
      return value;
    }

    //check in server
    value = getServerProperty(propKey);
    if (!empty(value)) {
      return value;
    }

    //check in client
    value = getClientProperty(propKey);

    return value;
  };

  /**
   * @method clearRemoteConfigs
   * @description empty out cached configs for server and project levels from the [_configStore](#~_configStore).
   * Should be called upon logout.
   * @public
   */
  my.clearRemoteConfigs=function() {
    _configStore.project={};
    _configStore.server={};
  };

  /**
   * @method clearProjectConfigs
   * @description empty out cached configs for all projects from the [_configStore](#~_configStore). Should be called
   * upon closing a project and returning to dashboard
   * @public
   * @param {string} projectKey if empty, all projects will be flushed otherwise specified project will be flushed.
   */
  my.clearProjectConfigs=function(projectKey) {
    if (!empty(projectKey)) {
      delete _configStore.project[projectKey];
    } else {
      _configStore.project={};
    }
  }

  return my;
}(APP || {}));
