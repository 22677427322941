/* global ROC_CHART, DISCRIMINATION_CHART, KS_CHART, LIFT_CHART, CALIBRATION_CHART, PRECISION_CHART,
  OGIVE_CHART, CONFUSION_MATRIX, PROB_LEVELS, FEATURE_IMPORTANCE_CHART, BUCKET_ANALYSIS_CHART, ACTUAL_VS_PREDICTED_CHART,
  RESIDUALS_CHART, NORMALITY_CHART, RESIDUAL_VS_PREDICTED_CHART */
/**
 * @module CHART
 * @description
 * This is a helper module that stores instances of various charts generated in the
 * [`ML_LEADERBOARD`](module-ML_LEADERBOARD.html) module as key-value pairs with the
 * chart's `type` as key and the `Chart` instance object as value. This module also
 * stores the palette that's used for coloring the graphs. A few details about how
 * to create the charts follow.
 * ## ML Leaderboard Charts
 * * [`ROC_CHART`](module-ROC_CHART.html)
 * * [`KS_CHART`](module-KS_CHART.html)
 * * [`DISCRIMINATION_CHART`](module-DISCRIMINATION_CHART.html)
 * * [`LIFT_CHART`](module-LIFT_CHART.html)
 * * [`CALIBRATOIN_CHART`](module-CALIBRATION_CHART.html)
 * * [`PROB_LEVELS`](module-PROB_LEVELS.html)
 * * [`PRECISION_CHART`](module-PRECISION_CHART.html)
 * * [`CONFUSION_MATRIX`](module-CONFUSION_MATRIX.html)
 * * [`OGIVE_CHART`](module-OGIVE_CHART.html)
 * * ['ACTUAL_VS_PREDICTED_CHART'](module-ACTUAL_VS_PREDICTED_CHART.html)
 * * ['RESIDUAL_CHART'](module-RESIDUAL_CHART.html)
 * * ['NORMALITY_CHART'](module-NORMALITY_CHART.html)
 * * ['RESIDUAL_VS_PREDICTED_CHART'](module-RESIDUAL_VS_PREDICTED_CHART.html)
 * ### Chart interface
 * Every chart module implements the following public properties and methods:
 * ```
 * string type
 * object optsGenerator(options)
 * chart generate(options)
 * ```
 * `type`: This is the name that forms the ID of the chart through all the lookup tables in the UI code
 *
 * `optsGenerator()`: The d3.js chart needs a lot more options that are not affected by the data or the view, and hence are static for the particular chart. These options are set here. This is the method that takes the options object passed in from the generate() method and augments the options with defaults for this chart. This prevents the main view code from being made clunky with lots of chart  options.
 *
 * `generate()`: This method is called from the leaderboard
 * page to trigger a redraw of the chart. This is the
 * method that calls `c3.js`'s `generate()` method and stores
 * the result chart object in the global CHART module. In
 * case of the Discrimination chart (which is directly implemented in `d3.js`) and the Confusion Matrix (which is a simple table with interactivity), the `generate()` method returns a returns an object that simulates the same
 * interface as the object returned by `c3.js`.
 */
// eslint-disable-next-line no-extra-semi
var CHART = (function (my) {
  /**
   * @member {string} type This chart is never instantiated so its type is `null`
   * @public
   */
  my.type = null;

  /**
   * @member {string} svgNS
   * @description The SVG namespace. Needed for creating custom SVG components
   * @private
   */
  // eslint-disable-next-line no-unused-vars
  var svgNS = "http://www.w3.org/2000/svg";

  /**
   * @member {object} charts
   * @description The store into which each chart instance stores its generated chart. These
   * objects are used to trigger redraw and resize of the charts. It can also be used to change
   * the data drawn on the chart.
   * @public
   */
  my.charts = {};

  /**
   * @member colors
   * @description An array of colors used in the charts. It helps to use numbered colors from here
   * to maintain consistency and to allow the charts to be restyled from a single place.
   * @public
   */
  my.colors = [
    "#f9bc38", //yellow
    "#85c72e", //green
    "#27c3ee", //blue
    "#df3931", //red
    "#8f50b6", //violet
    "#db1c9b", //magenta
    "#e63a13", //orange
  ];

  /**
   * @member allowedCharts
   * @description This mapping is used to determine which charts should be rendered on
   * which of the sub-pages of the leaderboard. The keys used here are the same as those that
   * trigger the router navigation to these pages.
   * @public
   */
  my.allowedCharts = {
    discrimination: [
      ROC_CHART.type,
      DISCRIMINATION_CHART.type,
      KS_CHART.type,
      LIFT_CHART.type,
    ],
    calibration: [CALIBRATION_CHART.type],
    fprAndPA: [
      PRECISION_CHART.type,
      OGIVE_CHART.type,
      PROB_LEVELS.type,
      CONFUSION_MATRIX.type,
    ],
    gainAnalysis: [LIFT_ANALYSIS.type],
    global: [FEATURE_IMPORTANCE_CHART.type, BUCKET_ANALYSIS_CHART.type],
    actualVsPredicted: [ACTUAL_VS_PREDICTED_CHART.type],
    residualsPlot: [RESIDUALS_CHART.type],
    normality: [NORMALITY_CHART.type],
    predictedVsResidual: [RESIDUAL_VS_PREDICTED_CHART.type],
    pca2d: [PCA_2D_CHART.type],
    pca3d: [PCA_3D_CHART.type],
    predictionVsDensity: [PREDICTION_VS_DENSITY.type],
    featureSelection: [FEATURE_IMPORTANCE_CHART.type],
    modelInterpretability: [SPIDER_CHART.type, FEATURE_IMPORTANCE_CHART.type],
    optimalSegmentSelection: [FEATURE_IMPORTANCE_CHART.type, LIFT_CHART.type],
  };

  /**
   * @member _opts
   * @description These are the lowest common denominator options from which the options for
   * individual charts are derived.
   * @public
   */
  let _opts = {
    data: {
      type: "line",
    },
    axis: {
      y: {
        max: 1,
        min: 0,
        padding: { top: 0, bottom: 0 },
        tick: {
          values: [0, 0.2, 0.4, 0.6, 0.8, 1.0],
          count: 6,
          outer: true,
        },
        label: {
          position: "outer-right",
        },
        // Range includes padding, set 0 if no padding needed
        // padding: {top:0, bottom:0}
      },
      x: {
        min: 0,
        max: 1,
        tick: {
          values: [0, 0.2, 0.4, 0.6, 0.8, 1.0],
          count: 6,
          outer: true,
        },
        label: {
          position: "outer-right",
        },
      },
    },
    point: {
      show: false,
    },
    legend: {
      show: true,
      color: "#ffffff",
    },
    transition: {
      duration: 100,
    },
  };

  Object.defineProperty(my, "opts", {
    get: function () {
      return JSON.parse(JSON.stringify(_opts));
    },
  });

  return my;
})(CHART || {});
