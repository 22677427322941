/* global CHART */
/**
 * @module ACTUAL_VS_PREDICTED_CHART
 * @description draws the Actual vs. Predicted chart shown on the `mp/mll/actualVsPredicted` page. Implements the 
 * [`CHART`](module-CHART.html) interface.
 */
// eslint-disable-next-line no-extra-semi
;var ACTUAL_VS_PREDICTED_CHART=(function(my){
  my.type="actualVsPredicted";

  my.optsGenerator=function(options,index){
    let opts=extend({},CHART.opts);

    delete opts.axis.y.min;
    delete opts.axis.y.max;
    delete opts.axis.y.tick.values;
    delete opts.axis.x.min;
    delete opts.axis.x.max;
    delete opts.axis.x.tick.values;
    
    delete opts.axis.x.label.text;
    
    if(index!=null){
      opts.bindto=`.actual-vs-predicted-card-${index} #versionActualVsPredictedChart .graph`;
    }
    else{
      opts.bindto="#actualVsPredictedChart .graph";
    }
    opts.axis.y.tick.format=d3.format(".1f");
    opts.axis.x.tick.format=d3.format(".1f");
    opts.axis.y.tick.culling=true;
    opts.axis.x.tick.culling=true;
    opts.axis.y.label.text="Predicted Value";
    opts.axis.x.label.text="Actual Value";
    opts.id=my.type;
    opts.data={
      xs:{
        ActualVsPredicted: "x1",
        Reference: "referencex"
      },
      columns: [
        ['x1'].concat(options.data.y1.keys),
        ['referencex'].concat(options.data.reference.keys),
        ['ActualVsPredicted'].concat(options.data.y1.values),
        ['Reference'].concat(options.data.reference.values)
      ],
      additionalData: options.data.additionalData,
      types: {
        ActualVsPredicted: 'scatter',
        referencex: 'line'        
      },
      colors: {
         ActualVsPredicted: CHART.colors[0],
         Reference: CHART.colors[2]
      }
    };
    opts.tooltip={
      contents: function(d, defaultTitleFormat, defaultValueFormat, color) {
        if (d[0].id === "ActualVsPredicted") {
          return `<table class='c3-tooltip'>
                    <tr class='c3-tooltip-name--x'>
                      <td class='name'><span style='background-color:${color(d[0].id)}'></span>Actual</td>
                      <td class='value'>${defaultValueFormat(d[0].x, null, d[0].id)}</td>
                    </tr>
                    <tr class='c3-tooltip-name--${d[0].id}'>
                      <td class='name'><span style='background-color:transparent'></span>Predicted</td>
                      <td class='value'>${defaultValueFormat(d[0].value, null, d[0].id)}</td>
                    </tr>
                  </table>`
        }
      },
      horizontal: true
    };
    opts.legend={
      show: false,
      // hide: ['Reference']
    };
    opts.grid={
      x:{show: false},
      y:{show: true},
    };
    opts.point={
      show: true,
      r: 3
    }
    if(index!=null){
      opts.size={
        width: 632,
        height: 373,
      };
    }
    else{
      opts.size={
        width: qs("#actualVsPredictedChart").offsetWidth -30,
        height: qs("#actualVsPredictedChart").offsetHeight - qs("#actualVsPredictedChart h4").offsetHeight - 22
      };
    }
    if (!empty(options.c3d3properties)){
      opts=extend(opts, options.c3d3properties);
    }
    return opts;
  };
  my.generate=function(options,index=null){
    let opt=my.optsGenerator(options,index);
    CHART.charts[opt.id]=c3.generate(opt);
    if(index!=null){
      qsa(`.actual-vs-predicted-card-${index} #versionActualVsPredictedChart .customLegend`).forEach(x=>x.remove());
    }
    else{
      qsa("#actualVsPredictedChart .customLegend").forEach(x=>x.remove());
    }
    const legend=createNode(`
      <div class="customLegend">
        <table>
          <tbody></tbody>
        </table>
      </div>
    `);
    let rows="";
    const format=d3.format("0.3f");
    for (let key in opt.data.additionalData) {
      const value=opt.data.additionalData[key];
      rows+=`<tr><td class="key">${key}</td><td class="value">${format(value)}</td></tr>`;
    }
    legend.qs("tbody").innerHTML=rows;
    if(index!=null){
      qs(`.actual-vs-predicted-card-${index} #versionActualVsPredictedChart .graph-container`).appendChild(legend);
    }
    else{
      qs("#actualVsPredictedChart .graph-container").appendChild(legend);
    }
    return CHART.charts[opt.id];
  }

  return my;
}(ACTUAL_VS_PREDICTED_CHART || {}));