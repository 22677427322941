/* global loginTemplate, CREDENTIALS_DATA */
/**
 * @module CREDENTIALS
 * @description This file does all the work of obtaining and storing credentials except for the server call to authenticate.
 * stores userHash which is used in all server calls
 */
// eslint-disable-next-line no-redeclare
var CREDENTIALS = (function (my) {
  /**
   * @member {string} userHash the session key returned by the login API that identifies the user.
   * This needs to be sent with every API call.
   * @private
   */
  var userHash = null;

  var userName = null;

  var accessType = null;

  /**
   * @member {number}
   * @description This variable is used to keep track if reset if required, if yes redirect user to '/reset-password' instead of '/'.
   * This variable is accessed using a getter function in app-router.js inside '/login' route, if it is '0' then navigate to
   * '/' else navigate to '/security-policy'.
   */
  var resetRequired = 0;

  /**
   * @member {boolean} cachedCredsLoaded whether they have been checked, not whether they are valid
   * @private
   */
  var cachedCredsLoaded = false;

  /**
   * @private
   * @member
   * @description Variable to store the current user's username.
   */
  var uname = null;
  var cachedUnameLoaded = false;

  /**
   * @private
   * @method
   * @description Returns the username of the currently logged in user.
   */
  my.getUsername = function () {
    if (uname == null && !cachedUnameLoaded) {
      uname = readCookie("userName");
      cachedUnameLoaded = true;
    }
    return uname;
  };

  /**
   * @method loadCreds
   * @description Load user hash from the cookie. check if it is valid. Set into userHash
   * @return {string} userHash if available from cookie else null;
   * @private
   */
  const loadCreds = function () {
    userHash = readCookie("userHash");
    if (!checkCachedCreds()) {
      userHash = null;
    }
    cachedCredsLoaded = true;
    return userHash;
  };

  /**
   * @method isResetRequired
   * @description Getter function for internal variable reset required.
   */
  my.isResetRequired = function () {
    return resetRequired == 0 ? false : true;
  };

  /**
   * @method obtainCreds
   * @description Contact server using login API and username and password. Get user cred and update
   * userHash. store userHash in cookie. Currently using unhashed cleartext password because
   * of time restrictions. CHANGE AS SOON AS POSSIBLE!
   * @modifies navigation-links left navigation login/logout links are updated
   * @modifies cookie sets the cookie to store the userHash
   * @param {string} username username
   * @param {string} password cleartext password
   * @return {string} userHash obtained from server
   * @public
   */
  my.obtainCreds = async function (username, password) {
    await authenticate({ uname: username, password: password }).then(
      (response) => {
        userHash = "" + response;
      }
    );
    if (userHash != null) {
      setCookie("userHash", userHash);
      setCookie("userName", username);
    }
    my.updateLoginLogoutLinks();
    return userHash;
  };

  my.obtainCredsViaAccessToken = async function (token) {
    await authenticateViaToken({ token: token }).then((response) => {
      userHash = "" + response.hash;
      userName = response.username;
    });
    if (userHash != null) {
      setCookie("userHash", userHash);
      setCookie("userName", userName);
    }
    my.updateLoginLogoutLinks();
    return userHash;
  };

  const authenticateViaToken = async function (iparams) {
    const url = SERVER.getBaseAddress() + "login-via-access-token";
    let params = {
      access_token: iparams.token,
    };
    var result = null;
    var hash = null;
    var username = null;
    try {
      if (useTestData) {
        result = await CREDENTIALS_DATA.login(url, params);
      } else {
        result = await SERVER.postData(url, params);
      }
    } catch (err) {
      result = null;
    }
    try {
      if (
        result.status === "success" ||
        (result.status >= 200 && result.status < 300)
      ) {
        hash = result.data.posts[0].key;
        username = result.data.posts[0].username;
        accessType =
          result.data.posts[0].role_access_info &&
          result.data.posts[0].role_access_info.accessType
            ? result.data.posts[0].role_access_info.accessType
            : "normal";
        setCookie("accessType", accessType);
        return { hash, username };
      } else {
        showErrorDialog();
        return;
      }
    } catch (err) {
      showErrorDialog();
      return;
    }
  };

  const showErrorDialog = function () {
    if (qs("#dialogs-active #error-dialog")) {
      //if dialog already exists, cancel old dialog
      let errorDlg = qs("#dialogs-active #error-dialog");
      errorDlg.fireCustomEvent("cancelled", {
        message: "Another dialog triggered.",
      });
    }
    var errorDlg = null;
    errorDlg = createNode(errordialogTemplate());
    return new Promise((resolve, reject) => {
      qs("#dialogs-sleeping").appendChild(errorDlg);
      APP.showDialog(errorDlg);
      let okAction = (evt) => {
        if (
          evt.type.toLowerCase() == "keypress" &&
          evt.key.toLowerCase() !== "enter"
        ) {
          return;
        }
        APP.hideDialog(errorDlg);
        errorDlg.remove();
        console.log("clicked");
        CREDENTIALS.resetCreds();
        const link = document.createElement("a");
        const userManagementUrl =
          localStorage.getItem("USER_MANAGEMENT_URL") || "";
        link.href = userManagementUrl;
        link.click();
      };
      errorDlg.qs("#dashboard-button").addEventListener("click", okAction);
      errorDlg.addEventListener("keypress", okAction);
      errorDlg.addEventListener("cancelled", (evt) => {
        errorDlg.remove();
        reject(`Cancelled. ${evt.detail.message}`);
      });
    });
  };

  const getPublicKey = function () {
    return fetch("../../../publicKey.pub")
      .then((res) => res.text())
      .then((key) => {
        return key;
      })
      .catch((e) => console.error(e));
  };

  const getEncryptedPassword = async (plaintextPassword) => {
    const publicKeyPem = await getPublicKey();
    const publicKey = forge.pki.publicKeyFromPem(publicKeyPem);
    const encryptedPasswordBytes = publicKey.encrypt(
      plaintextPassword,
      "RSA-OAEP",
      {
        md: forge.md.sha256.create(),
        mgf1: {
          md: forge.md.sha256.create(),
        },
      }
    );
    const encryptedPasswordBase64 = forge.util.encode64(encryptedPasswordBytes);
    return encryptedPasswordBase64;
  };

  /**
   * @method authenticate
   * @description Calls the server with username/password and obtains a user hash.
   * @param {Object} iparams {uname: <username>, password: <password>}
   * @return {Promise} a promise that resolves to the user hash
   * @throws {Error} All errors are stupidly thrown as "Incorrect username or password"
   * @public
   */
  const authenticate = async function (iparams) {
    const url = SERVER.getBaseAddress() + "login/authenticate";
    let params = extend({ uname: "", password: "" }, iparams);
    if (params.uname.length === 0 || params.password.length === 0) {
      throw new Error(i18n.en.APP.UI.ERROR.INCORRECT_USERNAME_OR_PASSWORD);
    }
    var result = null;
    var hash = null;
    const encryptedPassword = await getEncryptedPassword(params.password);
    params.password = encryptedPassword;
    params["isEncrypted"] = 1;
    try {
      if (useTestData) {
        result = await CREDENTIALS_DATA.login(url, params);
      } else {
        result = await SERVER.postData(url, params);
      }
    } catch (err) {
      result = null;
    }
    try {
      if (result != null) {
        if (
          result.status === "success" ||
          (result.status >= 200 && result.status < 300)
        ) {
          hash = result.data.posts[0].key;
          resetRequired = result.data.posts[0].reset_reqd;
          accessType =
            result.data.posts[0].role_access_info &&
            result.data.posts[0].role_access_info.accessType
              ? result.data.posts[0].role_access_info.accessType
              : "normal";
          setCookie("accessType", accessType);
          setCookie("resetReqd", my.isResetRequired());
        }
      }
    } catch (err) {
      throw new Error(i18n.en.APP.UI.ERROR.INCORRECT_USERNAME_OR_PASSWORD);
    }
    if (hash == null) {
      throw new Error(i18n.en.APP.UI.ERROR.INCORRECT_USERNAME_OR_PASSWORD);
    }
    return hash;
  };

  /**
   * @method getUserCreds
   * @description return user hash. get it from cookie if not already loaded from there
   * @returns {string} user hash. null if none found.
   * @public
   */
  my.getUserCreds = function () {
    if (userHash == null && !cachedCredsLoaded) {
      userHash = loadCreds();
    }
    my.updateLoginLogoutLinks();
    if (typeof userHash == "string") {
      userHash = parseInt(userHash, 10);
    }
    return userHash;
  };

  /**
   * @method resetCreds
   * @description delete creds in memory and from cookie. Will force user to log in again at next
   * server call.
   * @modifies cookie unsets the cookie
   * @modifies navigation-links updates which links are on and off
   * @public
   */
  my.resetCreds = function () {
    userHash = null;
    uname = null;
    accessType = null;
    setCookie("userHash", "");
    setCookie("userName", "");
    setCookie("accessType", "");
    my.updateLoginLogoutLinks();
  };

  /**
   * @method checkCachedCreds
   * @description This function should check whether the user hash stored in the cookie is still valid.
   * This should be done by making a call to a version of the login API that checks user hash.
   * There is no server API right now to do this so I'm just going to return true for now.
   * @private
   * @returns {boolean} whether cached creds exist.
   */
  const checkCachedCreds = function () {
    return userHash && typeof userHash !== "undefined" && userHash.length > 0;
  };

  /**
   * @method showLoginDialog
   * @description Show a login dialog and obtain username/password from it
   * @return {Promise} a promise that resolves into username and password object {uname: username, password: password}
   * @public
   */
  my.showLoginDialog = function (errorMsg) {
    if (qs("#dialogs-active #login")) {
      //if dialog already exists, cancel old dialog
      let loginDlg = qs("#dialogs-active #login");
      loginDlg.fireCustomEvent("cancelled", {
        message: "Another dialog triggered.",
      });
    }
    APP.nav.qs("#navItem-dashboard-nav-link").addClass("hidden");
    APP.nav.qs("#navGroupItem-connection").addClass("hidden");
    APP.nav.qs("#navGroupItem-data-engineering").addClass("hidden");
    APP.nav.qs("#navGroupItem-ae").addClass("hidden");
    APP.nav.qs("#navGroupItem-mp").addClass("hidden");
    APP.nav.qs("#navGroupItem-user-management").addClass("hidden");

    var loginDlg = null;
    if (errorMsg) {
      loginDlg = createNode(loginTemplate({ errorMsg: errorMsg }));
    } else {
      loginDlg = createNode(loginTemplate({ errorMsg: "" }));
    }
    return new Promise((resolve, reject) => {
      qs("#dialogs-sleeping").appendChild(loginDlg);
      // APP.registerCompactLabel(loginDlg.qsa('label.compact'));
      APP.showDialog(loginDlg);
      let okAction = (evt) => {
        if (
          evt.type.toLowerCase() == "keypress" &&
          evt.key.toLowerCase() !== "enter"
        ) {
          return;
        }
        var username = loginDlg.qs("#input-username").value;
        var password = loginDlg.qs("#input-password").value;
        //TODO: do something about remember me too!
        APP.hideDialog(loginDlg);
        loginDlg.remove();
        resolve({ uname: username, password: password });
      };
      loginDlg.qs("#login-button").addEventListener("click", okAction);
      loginDlg.addEventListener("keypress", okAction);
      loginDlg.addEventListener("cancelled", (evt) => {
        loginDlg.remove();
        reject(`Cancelled. ${evt.detail.message}`);
      });
    });
  };

  /**
   * @method updateLoginLogoutLinks
   * @description call after userHash has potentially been updated to update status
   * of login/logout links in the main navigation
   * @public
   */
  my.updateLoginLogoutLinks = function () {
    if (userHash != null && typeof userHash != "undefined") {
      qs("#navItem-login-nav-link").addClass("hidden");
      // qs("#navItem-logout-nav-link").removeClass("hidden");
    } else {
      // qs("#navItem-logout-nav-link").addClass("hidden");
      qs("#navItem-login-nav-link").removeClass("hidden");
    }
  };
  return my;
})(CREDENTIALS || {});
