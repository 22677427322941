/* global CHART */
/**
 * @module CALIBRATION_CHART
 * @description draws the Calibration chart shown on the `mp/mll/calibration` page. Implements the 
 * [`CHART`](module-CHART.html) interface.
 */
// eslint-disable-next-line no-extra-semi
;var CALIBRATION_CHART=(function(my){
  my.type="calibration";

  my.optsGenerator=function(options, index){
    let opts=extend({},CHART.opts);
    // delete opts.axis.y.tick.count;
    // delete opts.axis.x.tick.count;
    
    delete opts.axis.x.label.text;
    
    let ymax=1;
    let ymin=0;
    if (options.data.y1.values.length>0){
      ymax=Math.max(...options.data.y1.values);
      ymin=Math.min(...options.data.y1.values);
    }
    
    let xmax=1;
    let xmin=0;
    if (options.data.y1.keys.length>0){
      xmax=Math.max(...options.data.y1.keys);
      xmin=Math.min(...options.data.y1.keys);
    }

    ymax=(Math.ceil(ymax*100)*0.01);
    ymin=(Math.ceil(ymin*100)*0.01);
    xmax=(Math.ceil(xmax*100)*0.01);
    xmin=(Math.ceil(xmin*100)*0.01);

    let max=Math.max(ymax, xmax);
    let min=Math.min(ymin, xmin);
    min-=0.05;max+=0.05;
    if (min<0) min=0;
    if (max>1) max=1;
    
    opts.axis.y.min=min;
    opts.axis.y.max=max;
    opts.axis.y.padding={top: 20, bottom: 20};
    
    opts.axis.x.min=min;
    opts.axis.x.max=max;
    opts.axis.x.padding={left: max/20.0, right: max/20.0};
    
    if(index!=null){
      opts.bindto=`.calibration-card-${index} #versionCalibrationChart .graph`;
    }
    else{
      opts.bindto="#calibrationChart .graph";
    }
    opts.axis.y.tick.format=d3.format(".2f");
    opts.axis.y.tick.values=[0,0.2,0.4,0.6,0.8,1.0,min,max];
    opts.axis.y.tick.values=opts.axis.y.tick.values.sort(function(x1,x2){return x1<x2;});
    opts.axis.x.tick.format=d3.format(".2f");
    opts.axis.x.tick.values=[0,0.2,0.4,0.6,0.8,1.0,min,max];
    opts.axis.x.tick.values=opts.axis.x.tick.values.sort(function(x1,x2){return x1<x2;});
    opts.axis.y.label.text="Fraction of positives";
    opts.axis.x.label.text="Mean Predicted Value";
    opts.id=my.type;
    opts.data={
      xs:{
        Reliability: "x1",
        Reference: "referencex"
      },
      columns: [
        ['x1'].concat(options.data.y1.keys),
        ['referencex', 0, max],
        [options.data.y1.name].concat(options.data.y1.values),
        ['Reference', 0, max]
      ],
      type: 'line',
      colors: {
         Reliability: CHART.colors[0],
         Reference: CHART.colors[1]
      }
    };
    opts.tooltip={
      contents: function(d, defaultTitleFormat, defaultValueFormat, color) {
        if (d[0].id === "Reliability") {
          return `<table class='c3-tooltip'>
                    <tr><th colspan='2'>${defaultTitleFormat(d[0].x)}</th></tr>
                    <tr class='c3-tooltip-name--${d[0].id}'>
                      <td class='name'><span style='background-color:${color(d[0].id)}'></span>${d[0].name}</td>
                      <td class='value'>${defaultValueFormat(d[0].value, null, d[0].id)}</td>
                    </tr>
                  </table>`
        }
      },
      horizontal: true
    };
    opts.legend={
      show: true,
      hide: ['Reference']
    };
    opts.grid={
      x:{show: true},
      y:{show: true},
    };
    opts.point={
      show: true,
      r: 5
    }
    if(index!=null){
      opts.size={
        width: 632,
        height: 373,
      };
    }
    else{
      opts.size={
        width: qs("#calibrationChart").offsetWidth -30,
        height: qs("#calibrationChart").offsetHeight - qs("#calibrationChart h4").offsetHeight - 22
      };
    }
    
    if (!empty(options.c3d3properties)){
      opts=extend(opts, options.c3d3properties);
    }

    return opts;
  };
  my.generate=function(options, index=null){
    let opt=my.optsGenerator(options,index);
    CHART.charts[opt.id]=c3.generate(opt);
    d3.select(".c3-legend-item-Reliability text").text(`${options.data.scoreName}=${(d3.format(".3f"))(options.data.score)}`);
    return CHART.charts[opt.id];
  }

  return my;
}(CALIBRATION_CHART || {}));
