/* global */
/**
 * @module SECURITY_POLICY
 * @description Renders the security policy page to the users.
 */

var SECURITY_POLICY = (function (my) {
  /**
   * @private
   * @method getQuestionsData
   * @description Gets security questions from server.
   */
  var getQuestionsData = async function () {
    let userHash = CREDENTIALS.getUserCreds();
    APP.setProgress("Getting Security questions.");
    if (userHash == null) {
      throw new Error("User Not logged in cannot get security questions.");
    }
    let params = { uname: CREDENTIALS.getUsername() };
    return API_HELPER.getQuestionsForSecurity("settings/scrtquestcfg",params,"security-policy");
  };

  /**
   * @private
   * @method
   * @description
   */
  var updateQuestions = async function (data) {
    let url = SERVER.getBaseAddress() + "settings/updscrtquest";
    let userHash = CREDENTIALS.getUserCreds();
    APP.setProgress("Saving Answers.");
    if (userHash == null) {
      throw new Error("User not logged in cannot save answers.");
    }
    // here call proper function to get value of qnInfo
    let params = extend({ uname: CREDENTIALS.getUsername() }, data);
    return API_HELPER.getQuestionsForSecurity("settings/updscrtquest",params,"updateQues");
  };

  /**
   * @private
   * @method collectAnswers
   * @description Collects each answer from input box and checks for question duplicacy.
   */
  var collectAnswers = function () {
    let page = qs("#main-content .content.security-policy");
    let questions = page.qsa(".single-question-container .single-question");
    let questionsCollected = {}; // to keep track which questions are collected, so that no question is collected twice.
    let finalAnswers = [];
    for (let q of questions) {
      let ques = q.qs("select").value;
      let ans = q.qs("input").value;
      if (ans == "" || ans == undefined || ans == null) {
        return { data: null, err: "Please enter answer for each question." };
      }
      if (questionsCollected[ques]) {
        return { data: null, err: "Same question is selected more than once." };
      }
      questionsCollected[ques] = true;
      finalAnswers.push({ qn: ques, ans: ans });
    }
    return { data: finalAnswers, err: null };
  };

  /**
   * @private
   * @method saveAnswers
   * @description makes post request to server and shows message accordingly.
   */
  var saveAnswers = function () {
    let ans = collectAnswers();
    if (ans.err != null) {
      APP.showError(ans.err);
      return;
    }
    updateQuestions({ qnInfo: ans.data }).then((response) => {
      console.log(response);
    });
  };

  /**
   * @private
   * @method
   * @description Loads inner html of the page on the basis of data argument received.
   */
  var init = function (data) {
    qs("#main-content .content.security-policy").style.display = "block";
    qs("#main-content .content.security-policy").style.zIndex = "1";

    qs(
      "#main-content .content.security-policy"
    ).innerHTML = securitypolicyTemplate(data);
    let saveButton = qs(
      "#main-content .content.security-policy .page-container .button-container button"
    );
    saveButton.addEventListener("click", saveAnswers);
  };

  /**
   * @public
   * @method loadPage
   * @description Starts loading of security-policy page.
   */
  my.loadPage = function () {
    let questions = null;
    getQuestionsData().then((data) => {
      questions = data;
      init(questions);
    });
  };

  /**
   * @public
   * @method unloadPage
   * @description Removes event listeners from page and sets innerHTML to none.
   */
  my.unloadPage = function () {
    let saveButton = qs(
      "#main-content .content.security-policy .page-container .button-container button"
    );
    if (saveButton) {
      saveButton.removeEventListener("click", saveAnswers);
    }
    qs("#main-content .content.security-policy").style.display = "none";
  };
  return my;
})(SECURITY_POLICY || {});
