/**
 * @function forEach
 * @description 
 * ## `NodeList.forEach`
 * Immitate Array.forEach for browsers where it isn't available
 * @param {function} callback function(node, index, NodeList) 
 * @param {object} thisArg the `this` context for the callback function
 */
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = function (callback, thisArg) {
      thisArg = thisArg || window;
      for (let i = 0; i < this.length; i++) {
          callback.call(thisArg, this[i], i, this);
      }
  };
}

/**
 * @function qs 
 * @description 
 * ## `HTMLDocument.qs`
 * Alias for `document.querySelector`
 */
// eslint-disable-next-line no-redeclare, no-unused-vars
var qs = document.querySelector.bind(document);

/**
 * @function qsa
 * @description 
 * ## `HTMLDocument.qsa`
 * Alias for `document.querySelectorAll`
 */
// eslint-disable-next-line no-redeclare, no-unused-vars
var qsa = document.querySelectorAll.bind(document);

var qse = function(element,x){
  return element.getElementsByClassName(x);
}

var qsei = function(element,x){
  return element.querySelectorAll('#'+x);
}

var qsq = function(x){
  return document.getElementById(x);
}

if (!Element.prototype.qs){
  /**
   * @function qs
   * @description 
   * ## `Element.qs`
   * Alias for `Element.querySelector`
   */
  Element.prototype.qs = function() {
    return this.querySelector.apply(this, arguments);
  };
  
  /**
   * @function qsa
   * @description 
   * ## `Element.qs`
   * Alias for `Element.querySelectorAll`
   */
  Element.prototype.qsa = function() {
    return this.querySelectorAll.apply(this, arguments);
  };
}

/**
 * @var {boolean} supportsPassive
 * @description Global variable that will indicate whether event listeners can be registerd to be called in a passive manner.
 * Passive scroll event listeners are executed without blocking the main UI thread.
 */
// eslint-disable-next-line no-unused-vars
var supportsPassive = false;
try {
  var opts = Object.defineProperty({}, 'passive', {
    // eslint-disable-next-line getter-return
    get: function() {
      supportsPassive = true;
    }
  });
  window.addEventListener("test", null, opts);
} catch (e) {
  //do nothing
}

/**
 * Create a DOM node(s) from an HTML string
 * @param {string} s HTML string to create the DOM node for
 */
// eslint-disable-next-line no-redeclare, no-unused-vars
function createNode(s){
  var div=document.createElement("div");
  div.innerHTML=s;
  if (0===div.children.length){return null;}
  else if (1===div.children.length){return div.children[0];}
  return div.children;
}

/**
 * @function children
 * @description an analogue for jQuery.children. Find all descendents that match `selector`
 * @param {Element} el parent element
 * @param {string} selector CSS selector to identify the descendents to select
 * @returns {Array} An array of descendents that match the selector
 */ 
// eslint-disable-next-line no-redeclare, no-unused-vars
function children(el, selector){
  var descendents=el.qsa(selector);
  var children=[];
  if (descendents){
    for (let i in descendents){
      var child=descendents[i];
      if (child.parentNode === el){
        children.push(child);
      }
    }
  }
  return children;
}

/**
 * @define matches
 * @description 
 * ## `Element.matches`
 * Cross browser compatibility for Element.matches.
 */
if (!Element.prototype.matches){
  Element.prototype.matches = Element.prototype.msMatchesSelector || 
                              Element.prototype.webkitMatchesSelector;
}

/**
 * @function closest
 * @description 
 * ## `Element.closest`
 * Get the closest ancestor that matches a selector
 * @param {string} s Selector to identify the ancestor to return
 * @return {Element} The closest ancestor in the DOM hierarchy that matches the selector `s`. If nothing matches and 
 * the traversal hits root, return `null`.
 */
if (!Element.prototype.closest){
  Element.prototype.closest = function(s) {
      var el = this;
      var ancestor = this;
      if (!document.documentElement.contains(el)) return null;
      do {
          if (ancestor.matches(s)) return ancestor;
          ancestor = ancestor.parentElement;
      } while (ancestor !== null); 
      return null;
  };
}

/**
 * @function attrs
 * @description 
 * ## `Element.attrs`
 * Return an array of attribute values for an array of attribute names passed to the function
 * @param {array|string} attrs A single a attribute name or an array of Attr objects or strings that are attribute names
 * @returns {array} An array of attribute values.
 * 
 */
if (!Element.prototype.attrs){
  Element.prototype.attrs=function(attrs){
    if (attrs && "array" !== (typeof attrs).toLowerCase() && "string" !== (typeof attrs).toLowerCase()){
      throw new Error("Argument should be array of attributes or a string of space/comma delimited attributes.");
    } else {
      attrs=Array.from(this.attributes);
    }
    if ("string" === typeof attrs){
      attrs=attrs.replace(/[\s]+,[\s]*|,[\s]*|[\s]+/g, ",").split(",");
    }
    var values={};
    attrs.forEach(attr => {
      if (attr instanceof Attr){
        values[attr.name]=attr.value;
      } else if(typeof attr == "string"){
        values[attr]=this.getAttribute(attr);
      }
    });
    return values;
  }
}

/**
 * @function toggleClass
 * @description 
 * ## `Element.toggleClass`
 * Adds the classes that are passed to the classList if absent, or removes them if present.
 * @param {array|string} classes one or more classnames either as an array or as a string of space/comma delimitted classes.
 * @returns {HTMLElement} The element passed as input to the method. Serves to chain functions.
 */
if (!Element.prototype.toggleClass){
  Element.prototype.toggleClass = function(classes){
    if ("array" !== (typeof classes).toLowerCase() && "string" !== (typeof classes).toLowerCase()){
      throw new Error("Argument should be array of classes or a string of space/comma delimited classes.");
    }
    if ("string" === typeof classes){
      classes=classes.replace(/[\s]+,[\s]*|,[\s]*|[\s]+/g, ",").split(",");
    }
    classes.forEach(cls => {
      this.classList.toggle(cls);
    });
    return this;
  }
}

/**
 * @function addClass
 * @description 
 * ## `Element.addClass`
 * Adds the classes that are passed to the classList if absent.
 * @param {array|string} classes one or more classnames either as an array or as a string of space/comma delimitted classes.
 * @returns {HTMLElement} The element passed as input to the method. Serves to chain functions.
 */
if (!Element.prototype.addClass){
  Element.prototype.addClass = function(classes){
    if ("array" !== (typeof classes).toLowerCase() && "string" !== (typeof classes).toLowerCase()){
      throw new Error("Argument should be array of classes or a string of space/comma delimited classes.");
    }
    if ("string" === typeof classes){
      classes=classes.replace(/[\s]+,[\s]*|,[\s]*|[\s]+/g, ",").split(",");
    }
    classes.forEach(cls => {
      this.classList.add(cls);
    });
    return this;
  }
}

/**
 * @function removeClass
 * @description 
 * ## `Element.removeClass`
 * Removes the classes that are passed from the classList if present.
 * @param {array|string} classes one or more classnames either as an array or as a string of space/comma delimitted classes.
 * @returns {HTMLElement} The element passed as input to the method. Serves to chain functions.
 */
if (!Element.prototype.removeClass){
  Element.prototype.removeClass = function(classes){
    if ("array" !== (typeof classes).toLowerCase() && "string" !== (typeof classes).toLowerCase()){
      throw new Error("Argument should be array of classes or a string of space/comma delimited classes.");
    }
    if ("string" === typeof classes){
      classes=classes.replace(/[\s]+,[\s]*|,[\s]*|[\s]+/g, ",").split(",");
    }
    classes.forEach(cls => {
      this.classList.remove(cls);
    });
    return this;
  }
}

/**
 * @function hasClass
 * @description 
 * ## `Element.hasClass`
 * Checks if the classList of an element contains one of the classes passed as a comma/space delimitted string.
 * @param {string} cls one or more classnames as a string of space/comma delimitted classes.
 * @returns {boolean} `true` if even one of the classes is present in the classList, `false` otherwise
 */
if (!Element.prototype.hasClass){
  Element.prototype.hasClass = function(cls){
    if ("string" !== (typeof cls).toLowerCase()){
      throw new Error("Argument should be a string classname.");
    }
    let classes=cls.replace(/[\s]+,[\s]*|,[\s]*|[\s]+/g, ",").split(",");
    if (classes.length==1){
      return this.classList.contains(cls);
    } else {
      for (let i=0;i<classes.length;i++){
        if (this.classList.contains(classes[i])){return true;}
      }
      return false;
    }
  }
}

/**
 * @function fireCustomEvent
 * @description
 * ## Element.fireCustomEvent
 * Fires a named custom event on an element that can be registered for with `Element.addEventListener()`.
 * @param {string} name Event name
 * @param {object} data Data that can be passed to the listener.
 */
if (!Element.prototype.fireCustomEvent){
  Element.prototype.fireCustomEvent = function(name, data){
    var e;
    if ("undefined" === typeof data || !data){
      data = {};
    }
    data.target = this;
    var eData = {
      detail: data
    }
    try {
      e = new CustomEvent(name, eData);
    } catch(err){
      e = document.createEvent("CustomEvent");
      e.initCustomEvent(name, true, true, data);
    }
    this.dispatchEvent(e);
  }
}
