/* global */
/**
 * @module APP
 * @description Provides the application entrypoint and app level scope for 
 * methods that affect the whole application. This file contains event listeners that 
 * for the main menu trigger, dismisser, and helper listener for compactLabel
 */
// eslint-disable-next-line no-extra-semi
;APP=(function(my){

  /**
   * @method menuTrigger 
   * @description click eventListner that checks and opens and closes the left-side nav
   * @param evt click event
   * @private
   */
  var menuTrigger=function(evt){
    const nav=qs("#main-nav");
    if ("trigger-nav"===evt.currentTarget.id){
      if (nav.hasClass("on")){
        APP.hideNav(); 
      } else {
        APP.showNav();
      }
    } else if ("close-nav"===evt.currentTarget.id){
      const nav=qs("#main-nav");
      if (nav.hasClass("pinned")) return;
      APP.hideNav();
    } else if ("pin-nav"===evt.currentTarget.id){
      const nav=qs("#main-nav");
      if (nav && typeof nav !== "undefined"){
        if (nav.hasClass("pinned")){
          APP.unpinNav();
        } else {
          APP.pinNav();
        }
      }
    }
  };

  /**
   * @method maskClicked 
   * @description eventListener that is triggered when the mask is clicked. closes the navigation if it open and unpinned
   * @param evt click event
   * @private
   */
  // eslint-disable-next-line no-unused-vars
  var maskClicked=function(evt){
    const page=qs("#page-container");
    if (page.hasClass("nav-open") && !APP.nav.hasClass("pinned")){
      APP.hideNav();
    }
  };

  /**
   * @method pinnedStatusChanged 
   * @description eventListener that is triggered for custom event that's fired when the nav is pinned. Stores pinned status as a cookie for later retrieval.
   * @param evt custom event from APP.pinNav and APP.unpinNav
   * @private
   */
  var pinnedStatusChanged=function(evt){
    setCookie("navPinned", evt.type);
  };

  /**
   * @method navigationUpdater 
   * @description Highlight the current links and groups in the navigation.
   * @public
   */
  my.navigationUpdater=function(){
    let nav=qs("#main-nav");
    nav.qsa(".selected").forEach(x=>x.removeClass("selected"));
    let link = APP_HELPER.getLink(nav);
    if (link){
      link.addClass("selected");
      let containerTextNode=link.closest(".nav-group-container.l1");
      if (containerTextNode){
        children(containerTextNode, ".group-text").forEach(x=>x.addClass("selected"));
        // containerTextNode.qsa(".group-text").forEach(x=>x.addClass("selected"));
      }
      containerTextNode=link.closest(".nav-group-container.l2");
      if (containerTextNode){
        children(containerTextNode, ".group-text").forEach(x=>x.addClass("selected"));
        // containerTextNode.qsa(".group-text").forEach(x=>x.addClass("selected"));
      }
    }
  }

  /**
   * @method createProject 
   * @description click event listener for the New Project button.
   * @private
   */
  // eslint-disable-next-line no-unused-vars
  // var createProject=function(evt){
  //   APP.router.navigate("/new-project");
  // };

  /**
   * @method registerUIListeners 
   * @description registers the listeners in this file on appropriate elements on the page.
   * Also registers a mutation observer for new dialogs or boxes and registers the compactLabels inside.
   * @public
   */
  my.registerUIListeners=function(){
    //nav controls
    [].forEach.call(qsa(".nav-control"), function(button){
      button.addEventListener("click", menuTrigger);
    });
    
    //register for clicks on the mask; close nav on mask click
    qs("#mask").addEventListener("click", maskClicked);
  
    // set/unset cookie on nav pin/unpin
    qs("#main-nav").addEventListener("pinned", pinnedStatusChanged);
    qs("#main-nav").addEventListener("unpinned", pinnedStatusChanged);

    //create new project from dashboard
    // qs("#createProject").addEventListener("click", createProject);

    qs("#messageBar .close").addEventListener("click", APP.dismissMessage);
  
    //observe for new dialogs and register compactLabels therein for input content listeners
    var dlgObserver=new MutationObserver(mutationsList=>{
      for (var mutation of mutationsList){
        if (mutation.type=='childList'){
          for (var dlg of mutation.addedNodes){
            COMPACT_LABEL.registerCompactLabel(dlg.qsa("label.compact"));
          }
        }
      }
    });
    dlgObserver.observe(qs("#dialogs-active"), {attributes: false, childList: true});
  };
  
  return my;
}(APP || {}));





