var MP_BEHAVIOURAL_ANALYSIS = (function (my) {
  /**
   * @member {object} leaderboardData
   * @description Holds the leaderboard data.
   * @private
   */
  var leaderboardData = null;

  /**
   * @member {object} loadData
   * @description Makes the API call on /plot with suitable payload and returns them leaderboard data
   */
  const loadData = async function (iparams) {
    if (useTestData) {
      let result = await SEGMENTATION_LEADERBOARD_DATA.getData();
      return result;
    }
    return API_HELPER.getResult("plot", iparams);
  };

  /**
   * @member {object} loadHtml
   * @description loads a particular page of the leaderboard based on the subnav.
   */
  var loadHtml = function (subnav, pkey) {
    qs("#main-content .content.mp-behavioural-analysis").style.display =
      "block";
    if (subnav == "insights") {
      MP_BA_INSIGHTS.loadPage(pkey, leaderboardData);
    } else if (subnav == "personaCreation") {
      MP_BA_PERSONA_CREATION.loadPage(pkey, leaderboardData);
    }
  };

  /**
   * @member {object} loadHtml
   * @description the first function which is called from app-router.js on mp/ba route.
   * @public
   */
  my.show = async function (subnav, pkey) {
    if (!leaderboardData) {
      APP.setProgress("Loading data...");
      leaderboardData = await loadData({
        projectKey: pkey,
        projVersion: PROJECT.currentProjVersion(),
      });
      APP.resetProgress();
    }
    qs("#main-content .content.mp-behavioural-analysis").style.zIndex = 1;
    const mpBehaviouralAnalysisHtml = qs(".mp-behavioural-analysis");
    if (mpBehaviouralAnalysisHtml) {
      mpBehaviouralAnalysisHtml.innerHTML = "";
    }
    loadHtml(subnav, pkey);
  };

  /**
   * @member {object} unloadPage
   * @description called from the app-router to unload the page when route is changed from mp/ba.
   * @public
   */
  my.unloadPage = function () {
    qs("#main-content .mp-behavioural-analysis").style.display = "none";
  };

  return my;
})(MP_BEHAVIOURAL_ANALYSIS || {});
