/* global */
/**
 * @module HELP
 * @description Renders the help page to the users.
 */

var HELP = (function (my) {
  my.loadPage = function () {
    qs("#main-content .content.help").style.display = "block";
  };
  my.unloadPage = function () {
    qs("#main-content .content.help").style.display = "none";
  };
  return my;
})(HELP || {});
