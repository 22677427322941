var CLUSTER_VISUALIZATION = (function (my) {
  /**
  * @member {object} charts
  * @description Stores the chart objects created using `d3.js` or `c3.js`.
  * @private
  */
  let charts = {};

  /**
   * @member {string} dataSource
   * @description Whether the current view is for the "test" or "training" data.
   * @private
   */
  var datasource = null;

  /**
   * @member {string} selectedModelID
   * @description Whether the current view is for the selected model ID from the dropdown.
   * @private
   */
  var selectedModelID = null;

  /**
   * @member {object} data
   * @description Holds the leaderboard data.
   * @private
   */
  var data = null;

  /**
   * @member {array} sourcesList
   * @description List of all the available sources.
   * @private
   */
  var sourcesList = [];

  /**
  * @method getDataSourceOptions
  * @description fetches the available data sources by calling an API and populates the sources dropdown.
  */
  const getDataSourceOptions = async function (source, reload = false) {
    if (!reload) {
      sourcesList = [];
      try {
        sourcesList = await APP.loadAvailableSources({
          projectKey: PROJECT.currentProjectKey(),
          projVersion: PROJECT.currentProjVersion(),
        });
        if (
          sourcesList.data.posts[1]["OutOfTime"] &&
          sourcesList.data.posts[1]["OutOfTime"].length > 0
        ) {
          sourcesList = sourcesList.data.posts[1]["OutOfTime"];
          if (!sourcesList) {
            sourcesList = [];
          }
        } else {
          sourcesList = [];
        }
      } catch (err) {
        console.error(err.message);
        APP.showError(err.message);
        sourcesList = [];
      }
    }
    const dataSourceList = sourcesList;
    const sourceOptionTemplate = qs("#sourceSelect .template");
    dataSourceList.forEach((src) => {
      if (empty(src.hasTarget)) {
        return;
      }
      const option = sourceOptionTemplate.cloneNode();
      ["value", "style", "class", "disabled"].forEach((attr) =>
        option.removeAttribute(attr)
      );
      option.setAttribute("value", src.id);
      option.innerText = src.name;
      qs("#sourceSelect").append(option);
    });
    if (!reload) {
      datasource = empty(source) ? "train" : source;
      if (datasource != 'train') {
        let sourceSelect = qs(".variable-clustering #sourceSelect");
        sourceSelect.value = datasource;
      }
    } else {
      const selectElement = qs(".cluster-visualization-container #sourceSelect");
      selectElement.value = datasource;
    }
  };

  /**
  * @method getModelOptions
  * @description fetches the available models and populates the model dropdown.
  */
  const getModelOptions = function (model, reload = false) {
    const modelList = Object.keys(data);
    if (!reload) {
      selectedModelID = empty(model) ? modelList[0] : model.toUpperCase();
    }
    const selectElement = qs("#modelSelect");
    modelList.forEach((modelID) => {
      const optionElement = document.createElement("option");
      optionElement.value = modelID;
      optionElement.textContent = data[modelID].name;
      if (modelID === selectedModelID) {
        optionElement.selected = true;
      }
      selectElement.appendChild(optionElement);
    });
  };

  /**
  * @method dataSourceChanged
  * @description reloads the page with the selected data source from the dropdown.
  */
  const dataSourceChanged = function () {
    const selectElement = qs(".cluster-visualization-container #sourceSelect");
    const selectedOption = selectElement.options[selectElement.selectedIndex];
    const selectedValue = selectedOption.value;
    datasource = selectedValue;
    reloadPage();
  };

  /**
  * @method modelChanged
  * @description reloads the page with the selected model from the dropdown.
  */
  const modelChanged = function () {
    const selectElement = qs(".cluster-visualization-container #modelSelect");
    const selectedOption = selectElement.options[selectElement.selectedIndex];
    const selectedValue = selectedOption.value;
    selectedModelID = selectedValue;
    reloadPage();
  };

  /**
  * @method reloadPage
  * @description reloads the whole page.
  */
  const reloadPage = function () {
    qs("#main-content .content.ml-leaderboard-segmentation").innerHTML = "";
    qs("#main-content .content.ml-leaderboard-segmentation").innerHTML = clustervisualizationTemplate({ data: data[selectedModelID][datasource].ccAnalysis.ccInfo });
    getModelOptions(null, true);
    getDataSourceOptions(null, true);
    APP.setProgress("Rendering plot...");
    setTimeout(async () => {
      let isGenerated = await loadClusterCharts();
      if (isGenerated) {
        APP.resetProgress();
      }
    }, 1);
    registerListeners();
    qs("#main-content .content.ml-leaderboard-segmentation").addClass("isClusterVisualization");
  }

  /**
  * @method getRepeatedIndexes
  * @description returns the list of indexes on which a particular cluster is present.
  */
  const getRepeatedIndexes = function (arr, clusterNo) {
    var indexes = [];
    for (i = 0; i < arr.length; i++) {
      if (arr[i] == clusterNo) {
        indexes.push(i)
      }
    }
    return indexes;
  }

  /**
  * @method formatVisualizationData
  * @description formats the cluster visualization data in a suitable format so that it can be rendered on the UI.
  */
  const formatVisualizationData = function (clusterVisualizationData, index, xPoint, yPoint) {
    let clusterVisulationPoints = {};
    let clusters = clusterVisualizationData.ccInfo.Cluster_No.filter((value, index, array) => array.indexOf(value) === index);
    for (let cluster = 0; cluster < clusters.length; cluster++) {
      let clusterIndices = getRepeatedIndexes(clusterVisualizationData.ccInfo.Cluster_No, cluster);
      let xPoints = [];
      let yPoints = [];
      let xName = `cluster${cluster}-X`;
      let yName = `cluster${cluster}`;
      xPoints.push(xName);
      yPoints.push(yName);
      clusterIndices.forEach(x => xPoints.push(clusterVisualizationData.ccInfo[xPoint][x]));
      clusterIndices.forEach(y => yPoints.push(clusterVisualizationData.ccInfo[yPoint][y]));
      clusterVisulationPoints[xName] = xPoints;
      clusterVisulationPoints[yName] = yPoints;
    }
    return clusterVisulationPoints;
  };

  /**
  * @method generateClusterChart
  * @description generates a cluster visualization chart using the pca-2d-chart.js file.
  */
  const generateClusterChart = function (index, xPoint, yPoint, dialogChart = null) {
    let chartType = dialogChart ? "dialogChart" : "clusterVisualization";
    let formattedClusterVisulaizationData = formatVisualizationData(data[selectedModelID][datasource].ccAnalysis, index, xPoint, yPoint);
    requestAnimationFrame(() => {
      charts[PCA_2D_CHART.type] = PCA_2D_CHART.generate(
        {
          data: {
            y1: {
              name: "Cluster_Visualization",
              values: formattedClusterVisulaizationData,
              xPoint: xPoint,
              yPoint: yPoint,
              xList: data[selectedModelID][datasource].ccAnalysis.ccInfo.xInfoList,
              yList: data[selectedModelID][datasource].ccAnalysis.ccInfo.yInfoList,
            },
          },
          c3d3properties: {},
        },
        index,
        chartType
      );
    });
  }

  /**
  * @method resetVariables
  * @description resets all the variables upon landing the page to avoid caching issues.
  */
  const resetVariables = function () {
    charts = {};
    datasource = null;
    selectedModelID = null;
    data = null;
    sourcesList = [];
  };

  /**
  * @method openClusterVisualizationDialog
  * @description opens the cluster visualization dialog with graph.
  */
  const openClusterVisualizationDialog = function (xPoint, yPoint) {
    var clusterVisualizationDlg = null;
    clusterVisualizationDlg = createNode(visualizationgraphdialogTemplate());
    qs("#dialogs-sleeping").appendChild(clusterVisualizationDlg);
    APP.showDialog(clusterVisualizationDlg);
    generateClusterChart(null, xPoint, yPoint, "dialogChart");
    clusterVisualizationDlg.qs("button.close").addEventListener("click", () => {//dialog close button
      APP.hideDialog(clusterVisualizationDlg);
      clusterVisualizationDlg.remove();
    });
  }

  /**
  * @method registerGraphDialogListener
  * @description event listener to open the graph in a dialog box on clicking the graph.
  */
  const registerGraphDialogListener = function () {
    qs(".ml-leaderboard-segmentation .charts-container").addEventListener("click", evt => {
      let chartTobeShown = evt.target.closest('.chart-container');
      if (!chartTobeShown) {
        return;
      }
      let xPoint = chartTobeShown.getAttribute("x");
      let yPoint = chartTobeShown.getAttribute("y");
      if (qs("#dialogs-active #visualization-chart-dialog")) {//if dialog already exists, cancel old dialog
        let clusterVisualizationDlg = qs("#dialogs-active #visualization-chart-dialog");
        clusterVisualizationDlg.fireCustomEvent("cancelled", { message: "Another dialog triggered." });
      }
      openClusterVisualizationDialog(xPoint, yPoint);
    });
  }

  /**
  * @method loadClusterCharts
  * @description loads all the cluster visualization charts by looping in the data.
  */
  const loadClusterCharts = async function () {
    var xList = data[selectedModelID][datasource].ccAnalysis.ccInfo.xInfoList;
    var yList = data[selectedModelID][datasource].ccAnalysis.ccInfo.yInfoList;
    var index = 0;
    for (let i = 0; i < xList.length; i++) {
      for (let j = 0; j < yList.length; j++) {
        generateClusterChart(index, xList[i], yList[j]);
        index++;
      }
    }
    return true;
  }

  /**
   * @method registerListeners
   * @description Register listeners for:
   * * source selection
   * * model selection
   * * opening the dialog on graph click
   * @private
   */
  const registerListeners = function () {
    const clusterVisualizationPage = qs("#main-content .content.ml-leaderboard-segmentation");
    clusterVisualizationPage
      .qs("#sourceSelect")
      .addEventListener("change", dataSourceChanged);
    clusterVisualizationPage
      .qs("#modelSelect")
      .addEventListener("change", modelChanged);
    registerGraphDialogListener();
  }

  /**
  * @method loadPage
  * @description loads the page for the first time
  */
  my.loadPage = async function (leaderboardData, source, model) {
    qs("#main-content .content.ml-leaderboard-segmentation").addClass("isClusterVisualization");
    resetVariables();
    data = leaderboardData;
    selectedModelID = empty(model) ? Object.keys(data)[0] : model.toUpperCase();
    datasource = empty(source) ? "train" : source;
    qs("#main-content .content.ml-leaderboard-segmentation").innerHTML = clustervisualizationTemplate({ data: data[selectedModelID][datasource].ccAnalysis.ccInfo });
    qs("#main-content .content.ml-leaderboard-segmentation").style.overflow = "";
    getModelOptions(model);
    await getDataSourceOptions(source);
    registerListeners();
    APP.setProgress("Rendering plot...");
    setTimeout(async () => {
      let isGenerated = await loadClusterCharts();
      if (isGenerated) {
        APP.resetProgress();
      }
    }, 1);
  };

  return my;
})(CLUSTER_VISUALIZATION || {});
