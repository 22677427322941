var CLUSTER_MERGING = (function (my) {
  /**
   * @member {string} dataSource
   * @description Whether the current view is for the "test" or "training" data.
   * @private
   */
  var datasource = "train";

  /**
   * @member {string} selectedModelID
   * @description Whether the current view is for the selected model ID from the dropdown.
   * @private
   */
  var selectedModelID = null;

  /**
   * @member {object} data
   * @description Holds the leaderboard data.
   * @private
   */
  var data = null;

  /**
   * @member {string} formattedMergingTableData
   * @description formatted data to generate the cluster merging table.
   * @private
   */
  var formattedMergingTableData = [];

  /**
  * @method getModelOptions
  * @description fetches the available models and populates the model dropdown.
  */
  const getModelOptions = function (leaderboardData, model) {
    const modelList = Object.keys(leaderboardData);
    selectedModelID = empty(model) ? modelList[0] : model.toUpperCase();
    const selectElement = qs("#modelSelect");
    modelList.forEach((modelID) => {
      const optionElement = document.createElement("option");
      optionElement.value = modelID;
      optionElement.textContent = leaderboardData[modelID].name;
      if (modelID === selectedModelID) {
        optionElement.selected = true;
      }
      selectElement.appendChild(optionElement);
    });
  };

  /**
  * @method modelChanged
  * @description reloads the page with the selected model from the dropdown
  */
  const modelChanged = function () {
    const selectElement = qs(".cluster-merging #modelSelect");
    const selectedOption = selectElement.options[selectElement.selectedIndex];
    const selectedValue = selectedOption.value;
    selectedModelID = selectedValue;
    reloadData();
  };

  /**
  * @method reloadData
  * @description reloads the data and the cluster merging table.
  */
  const reloadData = async function () {
    const payload = {
      projectKey: PROJECT.currentProjectKey(),
      projVersion: PROJECT.currentProjVersion(),
      datatype: "merging",
      modelName: selectedModelID,
    };
    APP.setProgress("Loading data...");
    const result = await getClusterMergingData(payload);
    APP.resetProgress();
    data = result.data.posts[0];
    loadMergingTable(data);
  };

  /**
  * @method getClusterMergingData
  * @description makes the API call to get the cluster merging table data on /fetchpp with suitable payload and returns the result
  */
  const getClusterMergingData = async function (iparams) {
    if (useTestData) {
      return RESET_MERGING_DATA.getData();
    }
    const result = await API_HELPER.getResult("fetchpp", iparams);
    return result;
  };

  /**
  * @method saveMergingData
  * @description makes the API call to save the cluster merging table data on /savepp with suitable payload and returns the result
  */
  const saveMergingData = async function (payload) {
    if (useTestData) {
      return RESET_MERGING_DATA.getData();
    }
    const result = await API_HELPER.getResult("savepp", payload);
    if (
      result == null ||
      (result.status != "success" &&
        !(result.status >= 200 && result.status < 300))
    ) {
      if (result.status == 404) {
        return result;
      }
      return "failed";
    }
    return result;
  };

  /**
  * @method saveMergingListener
  * @description event listener on the save button to save the cluster merging info.
  */
  const saveMergingListener = async function () {
    let clusterMergingData = JSON.parse(JSON.stringify(data));
    qsa(".cluster-merging .table-container .merging-input").forEach(
      (mergeInput) => {
        const cluster = mergeInput.getAttribute('cluster');
        clusterMergingData.info[cluster].clusterToMerge = mergeInput.value;
      }
    );
    const payload = {
      projectKey: PROJECT.currentProjectKey(),
      projVersion: PROJECT.currentProjVersion(),
      datatype: "merging",
      modelName: selectedModelID,
      info: clusterMergingData,
    };
    const result = await saveMergingData(payload);
    APP.dismissMessage();
    if (result === "failed") {
      APP.showError("There was an error in merging the clusters");
      return;
    } else if (result.status == 404) {
      APP.showError(result.data.reason);
    } else {
      APP.showInfo("Clusters merged successfully");
      data = result.data.posts[0];
      loadMergingTable(data);
    }
  };

  /**
  * @method resetMergingData
  * @description makes the API call to reset the cluster merging table data on /resetsegmerge with suitable payload and returns the result
  */
  const resetMergingData = async function (payload) {
    if (useTestData) {
      return RESET_MERGING_DATA.getData();
    }
    const result = await API_HELPER.getResult("resetsegmerge", payload);
    if (
      result == null ||
      (result.status != "success" &&
        !(result.status >= 200 && result.status < 300))
    ) {
      if (result.status == 404) {
        return result;
      }
      return "failed";
    }
    return result;
  };

  /**
  * @method resetMergingTable
  * @description resets the cluster merging table.
  */
  const resetMergingTable = async function () {
    const payload = {
      projectKey: PROJECT.currentProjectKey(),
      projVersion: PROJECT.currentProjVersion(),
      modelName: selectedModelID,
    };
    const result = await resetMergingData(payload);
    APP.dismissMessage();
    if (result === "failed") {
      APP.showError("There was an error in resetting the table");
      return;
    } else if (result.status == 404) {
      APP.showError(result.data.reason);
    } else {
      APP.showInfo("Table reset successful");
      data = result.data.posts[0];
      loadMergingTable(data);
    }
  };

  /**
   * @method registerListeners
   * @description Register listeners for:
   * * model selection
   * * saving the cluster merging
   * * resetting the cluster merging
   * * window resizing
   * @private
   */
  const registerListeners = function () {
    const clusterMergingPage = qs(".cluster-merging");
    clusterMergingPage
      .qs("#modelSelect")
      .addEventListener("change", modelChanged);
    clusterMergingPage
      .qs(".save-merging-btn")
      .addEventListener("click", saveMergingListener);
    clusterMergingPage
      .qs(".reset-merging-btn")
      .addEventListener("click", resetMergingTable);
    if (!my.windowListenerAdded) {
      window.addEventListener("resize", my.resizeListener);
      my.windowListenerAdded = true;
    }
  };

  /**
  * @method resizeListener
  * @description window resize listener for redrawing graphs on zoom
  * @params evt resize event
  * @public
  */
  my.resizeListener = function (evt) {
    const buttonConatiner = qs(".cluster-merging .button-container");
    buttonConatiner.style.width =
      qs(".cluster-merging-table").offsetWidth + "px";
  };

  /**
  * @method formatMergingTableData
  * @description formats the cluster merging table data in the suitable format for rendering on UI,
  */
  const formatMergingTableData = function (distanceInfo) {
    formattedMergingTableData = [];
    let distInfo = distanceInfo.info;
    for (let i of Object.keys(distInfo)) {
      let intermediateObj = {};
      intermediateObj["count"] = distInfo[i].size;
      intermediateObj["count%"] = distInfo[i].ratio;
      let distanceObj = {};
      distInfo[i].distanceInfo.forEach((item, index) => {
        distanceObj[index] = item;
      });
      intermediateObj["distance"] = distanceObj;
      intermediateObj["mergeWith"] = distInfo[i].clusterToMerge;
      formattedMergingTableData.push(intermediateObj);
    }
  };

  /**
  * @method loadMergingTable
  * @description loads the cluster merging table using the clusterMergingTable.pug file.
  */
  const loadMergingTable = function (resetTableData = false) {
    formatMergingTableData(resetTableData);
    let headerKeys = ["count", "count%"].concat(
      Object.keys(formattedMergingTableData[0].distance)
    );
    headerKeys.push("mergeWith");
    let headerText = ["Cluster", "Count", "Count %"].concat(
      Object.keys(resetTableData.info)
    );
    const mergingTable = qs(".cluster-merging .table-container");
    mergingTable.innerHTML = "";
    mergingTable.appendChild(
      createNode(
        clustermergingtableTemplate({
          data: formattedMergingTableData,
          headerText: headerText,
          headerKeys: headerKeys,
          isMergingTable: true,
          twoHeaders: true,
          clusters: resetTableData.clusters
        })
      )
    );
    my.resizeListener();
  };

  /**
  * @method resetVariables
  * @description resets all the variables upon landing the page to avoid caching issues.
  */
  const resetVariables = function () {
    datasource = "train";
    selectedModelID = null;
    data = null;
    formattedMergingTableData = [];
  };

  /**
  * @method loadPage
  * @description loads the page for the first time
  */
  my.loadPage = async function (leaderboardData, source, model) {
    resetVariables();
    qs("#main-content .content.ml-leaderboard-segmentation").innerHTML =
      clustermergingTemplate();
    qs("#main-content .content.ml-leaderboard-segmentation").style.overflow =
      "";
    getModelOptions(leaderboardData, model);
    const payload = {
      projectKey: PROJECT.currentProjectKey(),
      projVersion: PROJECT.currentProjVersion(),
      datatype: "merging",
      modelName: selectedModelID,
    };
    APP.setProgress("Loading data...");
    const result = await getClusterMergingData(payload);
    APP.resetProgress();
    data = result.data.posts[0];
    loadMergingTable(data);
    registerListeners();
  };
  return my;
})(CLUSTER_MERGING || {});
