/**
 * @module DIAL
 * @description Functionality for a dial shaped progress bar
 */
// eslint-disable-next-line no-extra-semi
var DIAL = (function (my) {
  /**
   * @alias MutationObserver
   * @description Work around browser differences
   */
  var MutationObserver =
    window.MutationObserver ||
    window.WebKitMutationObserver ||
    window.MozMutationObserver;

  /**
   * @member {MutationObserver} dialObserver
   * @description Listen for change in value and update the dials progress depiction
   * @private
   */
  var dialObserver = new MutationObserver(function (mutations) {
    mutations.forEach(function (mutation) {
      if (
        mutation.type == "attributes" &&
        mutation.attributeName == "data-value"
      ) {
        mutation.target.nextElementSibling.qs(
          "span"
        ).innerText = mutation.target.getAttribute("data-value");
      }
    });
  });

  /**
   * @method registerDial
   * @description should be called on new instances of dials so that their progress content is updated
   * when you set its progress value.
   * @param {HTMLElement[]} dts NodeList of dt elements or a single dt element;
   * @public
   */
  my.registerDial = function (dts) {
    if (!dts || "undefined" === typeof dts) {
      dts = qsa("dt.dial");
    }
    if ("undefined" !== typeof dts.tagName) {
      dts = [dts];
    }
    [].forEach.call(dts, function (dt) {
      dialObserver.observe(dt, { attributes: true });
    });
  };
  return my;
})(DIAL || {});
