/* global VALIDATE_FEATURES_DATA, tableTemplate, togglebuttonTemplate, dvconfigdlgTemplate */
/**
 * @module VALIDATE_FEATURES
 * @description module for creation of the "Validate Features" page. The page shows the table of
 * features received using the `de/featurevalidate` API and allows the user to select features and
 * mark one as the target and optionally one as the ID. This feature configuration is sent to the
 * `de/modelcfg` API.
 */
var VALIDATE_FEATURES = (function (my) {
  /**
   * @member {string} STORE_KEY
   * @description the key for caching data in the STORE
   * @public
   */
  my.STORE_KEY = "VALIDATE_FEATURES";

  /**
   * @member {object} initialTableData
   * @description stores the table data which comes from the API initially. This data is used as a
   * reference for filtering the table data according to search text or the selected feature type.
   * @private
   */
  var initialTableData = null;

  /**
   * @member {object} filteredProjects
   * @description stores the projects whose state is greater than 4.
   * @private
   */
  var filteredProjects = null;

  /**
   * @member {object} dvConfigData
   * @description the original configuration as received from the server. Used to populate
   * the dialog's state initally and upon reset
   * @private
   */
  let dvConfigData = null;

  /**
   * @member {opject} dvConfigNewData
   * @description the current state of the dialogs contents as modified by the user are updated
   * in this object
   * @private
   */
  let dvConfigNewData = null;

  /**
   * @member {number}
   * @private
   * @description Global constant which specifies the number of rows to display on each page.
   */
  const rowPerPage = 50;

  /**
   * @member {number}
   * @private
   * @description Keeps track of the current page number in context of pagination.
   */
  let currentPageNumber = 1;

  /**
   * @member {number}
   * @private
   * @description Keeps track of starting index in tableData array for current page.
   */
  let start = null;

  /**
   * @member {number}
   * @private
   * @description Keeps track of ending index in tableData array for current page.
   */
  let end = null;

  /**
   * @member {Array}
   * @private
   * @description Global state variable to hold the value of table data array.
   */
  let tableDataArray = [];

  let projectsData = []; // For storing all existing projects having state greater than 6.

  var accessType = null;

  /**
   * @member selectionModel
   * @description store the target/input/reject/essential/id state for each feature. The UI should always reflect
   * the state from this model
   * @property {string} target The feature currently marked as TARGET
   * @property {string[]} input A list of features currently marked INPUT
   * @property {string[]} reject A list of features currently marked REJECT
   * @property {string[]} essential A list of features currently marked ESSENTIAL
   * @property {string} id The feature currently marked as ID
   * @property {function} clear Resets `target`, `input`, `reject`, 'essential' and `id` to initial empty values.
   * @property {function} set [params: bucket, featureName] Add featureName to bucket. Ensure that:
   * * Feature previously marked `target` is moved to the `input` list
   * * Feature previously marked `id` is moved to the `input` list
   * * Feature being set as `target` is removed from `input`, `reject`, 'essential', and `id` lists
   * * Feature being set as `id` is removed from `target`, `input`, `reject` and 'essential' lists
   * * Feature being set as `input`, `reject`, 'essential' or `id` is unset as `target`. 'target' is implicitly 'essential'
   * * Feature being set as `input`, `reject`, 'essential'  or `target` is unset as `id`. 'id' is implicityly 'essential'
   * @private
   */
  const selectionModel = {
    target: null,
    input: [],
    reject: [],
    essential: [],
    id: null,
    empty: function () {
      this.target = null;
      this.input = [];
      this.reject = [];
      this.essential = [];
      this.id = null;
    },
    clear: function () {
      //reset all to input
      if (this.target) {
        this.input.push(this.target);
        this.target = null;
      }
      if (this.id) {
        this.input.push(this.id);
        this.id = null;
      }
      this.reject.forEach((feature) => this.input.push(feature));
      this.reject = [];
      this.essential.forEach((feature) => this.input.push(feature));
      this.essential = [];
    },
    set: function (bucket, featureName) {
      if (typeof bucket !== "string" || typeof featureName !== "string") {
        //invalid arguments
        let err = new Error(
          "Invalid argument. bucket and featureName should be strings"
        );
        throw err;
      }
      if (
        ["target", "input", "reject", "essential", "id"].indexOf(bucket) < 0
      ) {
        //invalid bucket
        let err = new Error(
          "Invalid argument. bucket should be one of 'target', 'input', 'reject', 'essential', 'id'"
        );
        throw err;
      }
      if (
        this[bucket] == featureName ||
        (this[bucket] !== null &&
          typeof this[bucket] == "object" &&
          this[bucket].indexOf(featureName) >= 0)
      ) {
        //return if new setting=current setting
        return;
      }
      if (bucket == "target" || bucket == "id") {
        //remove from input/reject/essential lists
        ["input", "reject", "essential"].forEach((b) => {
          let index = this[b].indexOf(featureName);
          if (index >= 0) {
            this[b].splice(index, 1);
          }
        });
        // move current bucket value to input list
        if (this[bucket] !== null) {
          this.input.push(this[bucket]);
          this[bucket] = null;
        }
        // if this feature is a target or id set that bucket to null
        ["target", "id"].forEach((b) => {
          if (this[b] == featureName) {
            this[b] = null;
          }
        });

        this[bucket] = featureName;
      } else {
        //remove from input/reject/essential lists
        ["input", "reject", "essential"].forEach((b) => {
          let index = this[b].indexOf(featureName);
          if (index >= 0) {
            this[b].splice(index, 1);
          }
        });
        // if this feature is a target or id set that bucket to null
        ["target", "id"].forEach((b) => {
          if (this[b] == featureName) {
            this[b] = null;
          }
        });

        //add to new place
        this[bucket].push(featureName);
      }
    },
  };

  /**
   * @method getData
   * @description call the `de/featurevalidate` API to get the list of features to be configured.
   * @param {string} pkey The project ID in the context of which the view is loaded
   * @return {object} returns the result of the `de/featurevalidate` server API call (the actual deserialized JSON)
   * @public
   * @async
   */
  const getData = async function (pkey) {
    let url = SERVER.getBaseAddress() + "de/featurevalidate",
      userHash = CREDENTIALS.getUserCreds(),
      projectKey = pkey ? pkey : PROJECT.currentProjectKey();

    let storedData = STORE.getProjectData(
      projectKey,
      PROJECT.currentProjVersion(),
      my.STORE_KEY
    );
    if (storedData) {
      return storedData;
    }

    APP.setProgress(i18n.en.APP.UI.FOOTER.PROGRESS.FETCHING_FEATURES);
    if (userHash == null) {
      throw new Error(
        i18n.en.APP.UI.ERROR.USER_NOT_LOGGED_IN_CANNOT_RETRIEVE_PROJECTS +
          "\n" +
          i18n.en.APP.UI.ERROR.VALIDATE_FEATURES.GENERIC
      );
    }
    if (projectKey == null) {
      throw new Error(
        i18n.en.APP.UI.INFO.VALIDATE_FEATURES.NOT_IN_CONTEXT_OF_PROJECT
      );
    }
    let params = {
      key: userHash,
      projectKey: projectKey,
      projVersion: PROJECT.currentProjVersion(),
    };
    let result = null;
    try {
      if (useTestData) {
        result = await VALIDATE_FEATURES_DATA.getData();
      } else {
        result = await SERVER.postData(url, params);
      }
    } catch (err) {
      result = null;
    }
    if (result === "ROUTES_MISMATCHED") {
      return;
    }
    if (
      result == null ||
      (result.status != "success" &&
        !(result.status >= 200 && result.status < 300))
    ) {
      APP.showError(
        "There was an error in fetching feature list from the server. Please contact an Administrator."
      );
      APP.resetProgress();
      let err = new Error(i18n.en.APP.UI.ERROR.VALIDATE_FEATURES.GENERIC);
      err.name = "GenericError";
      throw err;
    }
    STORE.setProjectData(
      projectKey,
      PROJECT.currentProjVersion(),
      my.STORE_KEY,
      result
    );
    STORE.setProjectMetadata(
      projectKey,
      PROJECT.currentProjVersion(),
      my.STORE_KEY + "_features_fectched",
      true
    );
    return result;
  };

  /**
   * @member listObject
   * @description the object returned by `list.js`, the library that provides sorting and searching
   * functionality for the table.
   */
  my.listObject = null;

  /**
   * @method bucketChanged
   * @description A delegated event listener that will update the selection model for the table
   * and execute a UI update
   * @param {Event} evt Change event for a bucket button in the features table
   */
  const bucketChanged = function (evt) {
    if (evt.target.hasClass("validate-action")) {
      let radio = evt.target;
      let feature = radio
        .closest("tr")
        .qs("td[data-key=featureName]")
        .getAttribute("data-val");
      let bucket = radio.getAttribute("extra");
      selectionModel.set(bucket, feature);
      updateTableSelection();
      const featureInInitialTableData = initialTableData.find(
        (element) => element.featureName === feature
      );
      featureInInitialTableData.role = bucket;
    }
  };

  /**
   * @method updateTableSelection
   * @description reset all the buckets from the [`selectionModel`](#~selectionModel)
   */
  const updateTableSelection = function () {
    if (selectionModel.target) {
      let input = qs(
        `#features-table-container td[data-val='${selectionModel.target}']~td.actions input[extra='target']`
      );
      input !== null ? (input.checked = true) : APP.noop();
    }
    if (selectionModel.id) {
      let input = qs(
        `#features-table-container td[data-val='${selectionModel.id}']~td.actions input[extra='id']`
      );
      input !== null ? (input.checked = true) : APP.noop();
    }
    if (selectionModel.input.length > 0) {
      selectionModel.input.forEach((featureName) => {
        let input = qs(
          `#features-table-container td[data-val='${featureName}']~td.actions input[extra='input']`
        );
        input !== null ? (input.checked = true) : APP.noop();
      });
    }
    if (selectionModel.reject.length > 0) {
      selectionModel.reject.forEach((featureName) => {
        let input = qs(
          `#features-table-container td[data-val='${featureName}']~td.actions input[extra='reject']`
        );
        input !== null ? (input.checked = true) : APP.noop();
      });
    }
    if (selectionModel.essential.length > 0) {
      selectionModel.essential.forEach((featureName) => {
        let input = qs(
          `#features-table-container td[data-val='${featureName}']~td.actions input[extra='essential']`
        );
        input !== null ? (input.checked = true) : APP.noop();
      });
    }
  };

  function registerEventListeners(list, tableData) {
    qs("button#save-validate-features").addEventListener(
      "click",
      saveFeatureConfig
    );

    qs("button#import-features").addEventListener("click", showProjectsDialog);

    qs("#features-table-container thead tr.headerRow")
      .qs(".featureType")
      .addEventListener("click", (evt) => {
        //sorting
        if (evt.target.tagName.toLowerCase() !== "th") {
          return;
        }
        let sortOrder = "asc";
        if (evt.currentTarget.hasClass("asc")) {
          sortOrder = "desc";
        }
        list.sort(evt.currentTarget.getAttribute("data-key"), {
          order: sortOrder,
          sortFunction: function (itemA, itemB) {
            return list.utils.naturalSort.caseInsensitive(
              itemA.elm.qs(".feature-type-select").value,
              itemB.elm.qs(".feature-type-select").value
            );
          },
        });
        evt.currentTarget
          .closest("tr")
          .qsa("th")
          .forEach((x) => x.removeClass("asc,desc"));
        evt.currentTarget.addClass(sortOrder);
      });

    qs("#features-table-container tbody").addEventListener(
      "change",
      bucketChanged
    );

    qs("#features-table-container .selectAll").addEventListener(
      "change",
      (evt) => {
        //check/uncheck all listener
        qsa("#features-table-container td.select .on-off input").forEach(
          (button) => {
            button.checked = evt.currentTarget.checked;
          }
        );
      }
    );

    // Event listener for the select column in table (choose between numerical and categorical).
    qs("#features-table-container tbody").addEventListener("change", (evt) => {
      if (evt.target.hasClass("feature-type-select")) {
        let value = evt.target.value;
        let feature = evt.target
          .closest("tr")
          .qs("td.featureName")
          .getAttribute("data-val");
        let featureInTableData = tableData.find(
          (element) => element.featureName === feature
        );
        let featureInInitialTableData = initialTableData.find(
          (element) => element.featureName === feature
        );
        let featureInTableDataArray = tableDataArray.find(
          (element) => element.featureName === feature
        );
        featureInTableData.featureType =
          value[0].toUpperCase() + value.slice(1, value.length);
        featureInInitialTableData.featureType =
          value[0].toUpperCase() + value.slice(1, value.length);
        featureInTableDataArray.featureType =
          value[0].toUpperCase() + value.slice(1, value.length);
      } else {
        return;
      }
    });

    qs(".validate-features .bulk-update-action").addEventListener(
      "change",
      (evt) => {
        let bucket = evt.target.value;
        if (bucket != "") {
          // selectionModel.clear();
          qsa(
            "#features-table-container tbody tr td.select input:checked"
          ).forEach((checkedInput) => {
            selectionModel.set(
              bucket,
              checkedInput
                .closest("tr")
                .qs("td.featureName")
                .getAttribute("data-val")
            );
            let feature = checkedInput
              .closest("tr")
              .qs("td.featureName")
              .getAttribute("data-val");
            let featureInInitialTableData = initialTableData.find(
              (element) => element.featureName === feature
            );
            featureInInitialTableData.role = bucket;
          });
        }
        updateTableSelection();
      }
    );

    qsa("#features-table-container td.select .on-off input") //row selection change listener
      .forEach((radio) =>
        radio.addEventListener("change", (evt) => {
          qs(
            ".validate-features .bulk-update-action option:first-child"
          ).selected = true; //deselect bulk action on change of row selection.
          if (evt.currentTarget.checked == false) {
            //uncheck select all if some row is unchecked
            qs("#features-table-container .selectAll").checked = false;
          }
        })
      );

    qsa(".tooltip-info").forEach((x) =>
      x.addEventListener("mouseover", (evt) => {
        let tooltipBox = evt.target.closest(".tooltip-info");
        tooltipBox.setAttribute("flow", "middle-top");
        let text = tooltipBox.getAttribute("tooltip");
        let availableHeight = evt.y - 260; //height available to show the tooltip
        let numberOfLinesReq = Math.floor(text.length / 24); //24 characters can be present in 1 line
        let minHeightReq = numberOfLinesReq * 20; //one line takes 20px approx
        if (availableHeight < minHeightReq) {
          tooltipBox.setAttribute("flow", "right");
        }
      })
    );
  }

  function addSortingAndSearching(searchText, selectedFeature) {
    //add sorting and searching
    let headerRow = qs("#features-table-container thead tr.headerRow");
    headerRow.qsa("th").forEach((th) => {
      //no automatic sorting except featureName;
      if (!th.hasClass("featureName")) {
        th.removeClass("sort");
      }
    });
    let searchRow = headerRow.cloneNode(true);
    searchRow.qsa("th").forEach((th) => th.removeClass("sort")); //no sorting in search row
    searchRow.removeClass("headerRow").addClass("searchRow");
    searchRow.qsa("th").forEach((th) => {
      th.innerHTML = "";
      if (th.hasClass("featureName")) {
        th.innerHTML = `<input type="text" class="search-featureName" data-search="featureName"/>`;
      }
      if (th.hasClass("featureType")) {
        th.innerHTML = `<select id="feature-type-search" class="feature-type-search featureType">
                        <option value="all">All</option>
                        <option value="numeric">Numeric</option>
                        <option value="categorical">Categorical</option>
                      </select>`;
      }
    });
    qs("#features-table-container thead").appendChild(searchRow);
    if (searchText) {
      searchRow.qs(".search-featureName").value = searchText;
      searchRow.qs(".search-featureName").focus();
    }
    if (selectedFeature) {
      searchRow.qs(".feature-type-search").value = selectedFeature;
    }

    // performs the filtering if there is any change in the search box.
    searchRow.qs(".search-featureName").addEventListener("keyup", (evt) => {
      let searchText = evt.target.value.toLowerCase();
      let searchTableData = JSON.parse(JSON.stringify(initialTableData));
      let selectedFeature = searchRow
        .qs(".feature-type-search")
        .value.toLowerCase();
      if (selectedFeature !== "all") {
        searchTableData = searchTableData.filter(
          (element) =>
            element.featureName.toLowerCase().indexOf(searchText) !== -1 &&
            element.featureType.toLowerCase() === selectedFeature
        );
      } else {
        searchTableData = searchTableData.filter(
          (element) =>
            element.featureName.toLowerCase().indexOf(searchText) !== -1
        );
      }
      loadTableAndSetupPageNumbers(
        JSON.parse(JSON.stringify(searchTableData)),
        searchText,
        selectedFeature
      );
      // for case when we type something in search bar and then remove it. Then we have to click again on the search box to type something
      if (searchText.length === 0) {
        qs(".featureName .search-featureName").focus();
      }
    });

    // performs the filtering if there is any change in the select box.
    searchRow.qs(".feature-type-search").addEventListener("change", (evt) => {
      let selectedFeature = evt.target.value.toLowerCase();
      let searchText = searchRow.qs(".search-featureName").value.toLowerCase();
      let searchTableData = JSON.parse(JSON.stringify(initialTableData));
      if (searchText) {
        if (selectedFeature !== "all") {
          searchTableData = searchTableData.filter(
            (element) =>
              element.featureType.toLowerCase() === selectedFeature &&
              element.featureName.toLowerCase().indexOf(searchText) !== -1
          );
        } else {
          searchTableData = searchTableData.filter(
            (element) =>
              element.featureName.toLowerCase().indexOf(searchText) !== -1
          );
        }
      } else if (selectedFeature !== "all") {
        searchTableData = searchTableData.filter(
          (element) => element.featureType.toLowerCase() === selectedFeature
        );
      }
      loadTableAndSetupPageNumbers(
        JSON.parse(JSON.stringify(searchTableData)),
        searchText,
        selectedFeature
      );
    });
  }

  /**
   * @function loadTable
   * @private
   * @description Loads the table in validate features page.
   * @param {tableData} Array containing all the rows in required object form.
   * @param {start} Starting index of the range to display.
   * @param {end} ending index of the range to display.
   *
   */
  function loadTable(tableData, start, end, searchText, selectedFeature) {
    qs("#features-table-container").childNodes.forEach((x) => x.remove()); //empty the table
    qs("#features-table-container").appendChild(
      createNode(
        tableTemplate({
          data: tableData.slice(start, end),
          headerText: ["Feature Name", "Feature Type", "Role"],
          headerKeys: ["featureName", "featureType", "actions"],
          hasSelectionCol: true,
          hasSelectAll: true,
          hasActionsColumn: true,
          tableAttributes: {
            class: "scrollable-table",
          },
          actions: function (rowIndex) {
            return `<div>
                  ${togglebuttonTemplate({
                    text: "Target",
                    id: "target-button-" + rowIndex,
                    name: "actions-" + rowIndex,
                    class: "validate-action target",
                    checked: false,
                    extra: "target",
                  })}
                  ${togglebuttonTemplate({
                    text: "Input",
                    id: "input-button-" + rowIndex,
                    name: "actions-" + rowIndex,
                    class: "validate-action input",
                    checked: true,
                    extra: "input",
                  })}
                  ${togglebuttonTemplate({
                    text: "Essential",
                    id: "essential-button-" + rowIndex,
                    name: "actions-" + rowIndex,
                    class: "validate-action essential",
                    checked: false,
                    extra: "essential",
                  })}
                  ${togglebuttonTemplate({
                    text: "Reject",
                    id: "reject-button-" + rowIndex,
                    name: "actions-" + rowIndex,
                    class: "validate-action reject",
                    checked: false,
                    extra: "reject",
                  })}
                  ${togglebuttonTemplate({
                    text: "Uid",
                    id: "id-button-" + rowIndex,
                    name: "actions-" + rowIndex,
                    class: "validate-action id",
                    checked: false,
                    extra: "id",
                  })}
                </div>`;
          },
          renderers: {
            featureType: function (rowIndex, colIndex, value) {
              return `<select class="feature-type-select" id="feature-type-select-${rowIndex}" index=${rowIndex}>
                    <option value="numeric" ${
                      value.toLowerCase() == "numeric" ? "selected" : ""
                    }>Numeric</option>
                    <option value="categorical" ${
                      value.toLowerCase() == "categorical" ? "selected" : ""
                    }>Categorical</option>
                    </select>`;
              // return `<span class="foo">${value}</span>`;
            },
          },
        })
      )
    );
    var list = new List("features-table-container", {
      valueNames: ["select", "featureName", "featureType", "actions"],
    });
    my.listObject = list;
    // my.listObject.on("updated", updateTableSelection);
    addSortingAndSearching(searchText, selectedFeature);
    registerEventListeners(list, tableData);
  }

  /**
   * @method handleEmptyTable
   * @description disables both the previous and next buttons.
   */
  const handleEmptyTable = function () {
    qs("#validate-feature-prev").disabled = true;
    qs("#validate-feature-next").disabled = true;
  };

  /**
   * @method setUpSelectedRadios
   * @description marks the action inputs as checked after the reloading of the table according to the last save on
   * action inputs for a each feature.
   */
  const setUpSelectedRadios = function (tableData) {
    for (let feature of tableData) {
      qsa(
        `#features-table-container td[data-val="${feature.featureName}"]~td.actions input`
      ).forEach((input) => (input.checked = false));
      let selectedRadio = qs(
        `#features-table-container td[data-val='${feature.featureName}']~td.actions input[extra='${feature.role}']`
      );
      if (selectedRadio) {
        selectedRadio.checked = true;
      }
    }
  };

  /**
   * @method loadTableAndSetupPageNumbers
   * @description loads the table and sets the page numbers according to the table data.
   */
  const loadTableAndSetupPageNumbers = function (
    tableData,
    searchText,
    selectedFeature
  ) {
    qs("#main-content .content.validate-features").innerHTML =
      validatefeaturesTemplate();
    accessType = readCookie("accessType");
    handleButtonDisabling();
    start = 0;
    end = start + rowPerPage;
    let totalNumberOfPages =
      tableData.length % rowPerPage === 0
        ? Math.floor(tableData.length / rowPerPage)
        : Math.floor(tableData.length / rowPerPage) + 1;
    loadTable(tableData, start, end, searchText, selectedFeature);
    setUpSelectedRadios(tableData);
    currentPageNumber = 1; //to always go to page 1 after the table is reloaded
    if (tableData.length !== 0) {
      let pageNumbersBar = generatePageNumbersBar(
        currentPageNumber,
        totalNumberOfPages
      );
      qs("#pagenumber-indicator").innerHTML = pageNumbersBar;

      if (currentPageNumber == 1) {
        qs("#validate-feature-prev").disabled = true;
        qs("#validate-feature-prev").addClass("disable");
      }

      if (totalNumberOfPages == 1) {
        qs("#validate-feature-next").disabled = true;
        qs("#validate-feature-next").addClass("disable");
      }
      qs("#validate-feature-next").addEventListener("click", () => {
        currentPageNumber = currentPageNumber + 1;
        createValidRange("NEXT", totalNumberOfPages, tableData.length);
        loadTable(tableData, start, end, searchText, selectedFeature);
        updateTableSelection();
      });

      qs("#validate-feature-prev").addEventListener("click", () => {
        currentPageNumber = currentPageNumber - 1;
        createValidRange("NEXT", totalNumberOfPages, tableData.length);
        loadTable(tableData, start, end, searchText, selectedFeature);
        updateTableSelection();
      });

      qs("#pagenumber-indicator").addEventListener("click", (evt) => {
        if (evt.target.hasClass("page-number")) {
          let pageNumber = evt.target.getAttribute("page-number");
          currentPageNumber = Number(pageNumber);
          createValidRange("PAGE", totalNumberOfPages, tableData.length);
          loadTable(tableData, start, end, searchText, selectedFeature);
          updateTableSelection();
        }
      });
    } else {
      handleEmptyTable();
    }
  };

  /**
   * @method validateFeatures
   * @description called from the router to initialize and display the view and handle the user interaction
   * for selecting dv and rejected/essential features, setting ID feature.
   * @param {string} pkey The project ID in the context of which the view is loaded
   * @public
   * @async
   */
  my.validateFeatures = async function (pkey) {
    currentPageNumber = 1;
    let result = await getData();
    selectionModel.empty();
    let tableData = [];
    for (let x in result.data.posts[0]) {
      selectionModel.input.push(x);
      tableData.push({
        featureName: x,
        featureType: result.data.posts[0][x],
        role: "input",
      });
    }
    tableDataArray = tableData;
    initialTableData = JSON.parse(JSON.stringify(tableData));
    // tableData = tableData.slice(0, 1);
    APP.resetProgress();
    let featureTableContainer = qs("#features-table-container");
    if (featureTableContainer) {
      featureTableContainer.childNodes.forEach((x) => x.remove()); //empty the table
    }

    //The view's data is cached so that it can still be shown again. The modelCfg API can
    //called only once. So the save button is disabled the second time through.
    if (
      STORE.getProjectMetadata(
        pkey,
        PROJECT.currentProjVersion(),
        VALIDATE_FEATURES.STORE_KEY + "_features_saved"
      ) === false
    ) {
      let saveButton = qs("button#save-validate-features");
      if (saveButton) {
        qs("button#save-validate-features").removeAttribute("disabled");
      }
    }
    // Steps for pagination of data :
    // Initializing starting and ending range of row to display on first page.
    // Finding total number of pages which will be required to show complete data.
    // Loading first page table with initial range value.
    // Disabling prev and next button based on page number, if first disable prev button, if only one page is required disable next button.
    //  Also added 'disable' class.
    // Adding respective event listeners on prev and next buttons.

    loadTableAndSetupPageNumbers(JSON.parse(JSON.stringify(tableData)), "", "");

    projectsData = await getProjects();

    if (projectsData.length == 0) {
      // Checking any project exists with state higer than 5 exist or not
      qs("button#import-features").disabled = true; // If no project exists then button will be disabled
      qs("button#import-features").setAttribute(
        "title",
        "No projects available to import"
      ); // Changing tooltip title to No projects available
    }
  };

  const handleButtonDisabling = function () {
    let saveValidateFeaturesButton = qs("button#save-validate-features");
    if (accessType == "view") {
      saveValidateFeaturesButton.disabled = true;
      saveValidateFeaturesButton.setAttribute("tooltip", "Permission Denied");
      saveValidateFeaturesButton.setAttribute("flow", "right");
    }
  };

  /**
   * @function createValidRange
   * @private
   * @description Creates a valid range of start-end values for pagination on a particular page and
   * updates bottomPageNumberBar and state of prev, next buttons.
   * @param {action} Not used, just taken for future refrence.
   * @param {totalNumberOfPages} Total number of pages in pagination.
   * @param {dataLength} Total number of rows.
   */
  function createValidRange(action, totalNumberOfPages, dataLength) {
    qs("#pagenumber-indicator").innerHTML = generatePageNumbersBar(
      currentPageNumber,
      totalNumberOfPages
    );
    start = (currentPageNumber - 1) * rowPerPage;
    end = start + rowPerPage;

    if (currentPageNumber == totalNumberOfPages) {
      end = dataLength;
      qs("#validate-feature-next").disabled = true;
      qs("#validate-feature-next").addClass("disable");
    } else {
      qs("#validate-feature-next").disabled = false;
      qs("#validate-feature-next").removeClass("disable");
    }

    if (currentPageNumber == 1) {
      qs("#validate-feature-prev").disabled = true;
      qs("#validate-feature-prev").addClass("disable");
    } else {
      qs("#validate-feature-prev").disabled = false;
      qs("#validate-feature-prev").removeClass("disable");
    }
  }

  /**
   * @function generatePageNumbersBar
   * @private
   * @description Creates the html for bottomPageNumberBar based on total number of pages and value of current page.
   * @param {currentPageNumber}
   * @param {totalNumberOfPages}
   *
   */
  function generatePageNumbersBar(currentPageNumber, totalNumberOfPages) {
    let pageNumbers = [];
    let count = 0;
    let i = 1;
    let leftOver = false;
    let rightOver = false;
    pageNumbers.push(currentPageNumber);

    while ((leftOver != true || rightOver != true) && count != 6) {
      let chooseThisRound = false;
      if (currentPageNumber - i < 1) {
        leftOver = true;
      } else {
        pageNumbers.push(currentPageNumber - i);
        chooseThisRound = true;
        count++;
      }

      if (currentPageNumber + i > totalNumberOfPages) {
        rightOver = true;
      } else {
        pageNumbers.push(currentPageNumber + i);
        chooseThisRound = true;
        count++;
      }
      if (chooseThisRound) {
        i++;
      }
    }

    if (!pageNumbers.includes(1)) {
      pageNumbers.push(1);
    }
    if (!pageNumbers.includes(totalNumberOfPages)) {
      pageNumbers.push(totalNumberOfPages);
    }

    pageNumbers.sort((a, b) => a - b);

    finalPageNumbers = [];
    for (let i = 0; i < pageNumbers.length - 1; i++) {
      finalPageNumbers.push(pageNumbers[i]);
      if (pageNumbers[i + 1] - pageNumbers[i] > 1) {
        finalPageNumbers.push(-1);
      }
    }
    finalPageNumbers.push(totalNumberOfPages);
    return generateHtml(finalPageNumbers, currentPageNumber);
  }

  /**
   * @function generateHtml
   * @private
   */
  function generateHtml(pageNumbers, currentPageNumber) {
    result = ``;
    for (let i = 0; i < pageNumbers.length; i++) {
      let x = pageNumbers[i];
      if (x == -1) {
        result += `<span class="dots">..</span>`;
      } else if (x == currentPageNumber) {
        result += `<span class="page-number current-page" page-number=${x}>${x}</span>`;
      } else {
        result += `<span class="page-number" page-number=${x}>${x}</span>`;
      }
    }
    return result;
  }

  /**
   * @function handleEmptyUserHashAndProjectKey
   * @description throws errors if either userHash or projectKey is empty.
   * @private
   */
  const handleEmptyUserHashAndProjectKey = function (userHash, projectKey) {
    if (userHash == null) {
      APP.resetProgress();
      APP.showError(
        i18n.en.APP.UI.ERROR.USER_NOT_LOGGED_IN_CANNOT_RETRIEVE_PROJECTS +
          "\n" +
          i18n.en.APP.UI.ERROR.VALIDATE_FEATURES.GENERIC
      );
      throw new Error(
        i18n.en.APP.UI.ERROR.USER_NOT_LOGGED_IN_CANNOT_RETRIEVE_PROJECTS +
          "\n" +
          i18n.en.APP.UI.ERROR.VALIDATE_FEATURES.GENERIC
      );
    }
    if (projectKey == null) {
      APP.resetProgress();
      APP.showError(
        i18n.en.APP.UI.INFO.VALIDATE_FEATURES.NOT_IN_CONTEXT_OF_PROJECT
      );
      throw new Error(
        i18n.en.APP.UI.INFO.VALIDATE_FEATURES.NOT_IN_CONTEXT_OF_PROJECT
      );
    }
  };

  /**
   * @function formatImportedData
   * @description formats the import validate feature data in the format suitable for UI.
   * @private
   */
  const formatImportedData = function (data) {
    let featureList = [];
    selectionModel.empty();
    for (let feature of data.numList) {
      let featureObj = {};
      featureObj["featureName"] = feature;
      featureObj["featureType"] = "Numeric";
      featureObj["role"] = "input";
      selectionModel.input.push(feature);
      featureList.push(featureObj);
    }
    for (let feature of data.catList) {
      let featureObj = {};
      featureObj["featureName"] = feature;
      featureObj["featureType"] = "Categorical";
      featureObj["role"] = "input";
      selectionModel.input.push(feature);
      featureList.push(featureObj);
    }
    for (let feature of data.dropList) {
      let featureInFeatureList = featureList.find(
        (element) => element.featureName === feature
      );
      selectionModel.input.splice(selectionModel.input.indexOf(feature), 1);
      selectionModel.reject.push(feature);
      featureInFeatureList.role = "reject";
    }
    for (let feature of data.essential) {
      let featureInFeatureList = featureList.find(
        (element) => element.featureName === feature
      );
      selectionModel.input.splice(selectionModel.input.indexOf(feature), 1);
      selectionModel.essential.push(feature);
      featureInFeatureList.role = "essential";
    }
    if (data.dvVar) {
      let featureInFeatureList = featureList.find(
        (element) => element.featureName === data.dvVar
      );
      selectionModel.input.splice(selectionModel.input.indexOf(data.dvVar), 1);
      selectionModel.target = data.dvVar;
      featureInFeatureList.role = "target";
    }
    featureInFeatureList = featureList.find(
      (element) => element.featureName === data.uidVar
    );
    selectionModel.input.splice(selectionModel.input.indexOf(data.uidVar), 1);
    selectionModel.id = data.uidVar;
    featureInFeatureList.role = "id";
    return featureList;
  };

  /**
   * @function getImportedData
   * @description calls the API to get import validate features data.
   * @private
   */
  const getImportedData = async function (params) {
    if (useTestData) {
      let result =
        await VALIDATE_FEATURES_IMPORT_DATA.getImportConfigurations();
      return result;
    }
    return API_HELPER.getResult("config/impmdlcfg", params);
  };

  /**
   * @function importConfigurationData
   * @description calls the getImportData function and loads the table with new data.
   * @private
   */
  const importConfigurationData = async function () {
    const selectedProject = qs("#projects-dialog #select-project").value;
    var selectedVersion = null;
    const versionsList = qsa(
      "#projects-dialog .version-radio-list .version-item"
    );
    versionsList.forEach((versionItem) => {
      if (versionItem.qs("input").checked == true) {
        selectedVersion = versionItem.qs("input").value;
        return;
      }
    });
    let params = { projectKey: selectedProject, projVersion: selectedVersion };
    APP.setProgress("Importing Data");
    let importedData = await getImportedData(params);
    APP.resetProgress();
    importedTableData = formatImportedData(importedData.data.posts[0]);
    tableDataArray = importedTableData;
    initialTableData = JSON.parse(JSON.stringify(importedTableData));
    loadTableAndSetupPageNumbers(
      JSON.parse(JSON.stringify(importedTableData)),
      "",
      ""
    );
  };

  /**
   * @function registerEventListenerOnRadios
   * @description registers the click event listener on the input radios to select version from dialog box.
   * @private
   */
  const registerEventListenerOnRadios = function () {
    qs("#projects-dialog .version-radio-list").addEventListener(
      "click",
      (evt) => {
        if (evt.target.className === "version-radio-input") {
          qsa(".version-radio-input").forEach(
            (inputRadio) => (inputRadio.checked = false)
          );
          evt.target.checked = true;
        }
      }
    );
  };

  /**
   * @function selectFirstVersion
   * @description select the first version which comes up for any selected project by default.
   * @private
   */
  const selectFirstVersion = function () {
    const versionList = qs("#projects-dialog .version-radio-list");
    if (versionList) {
      const firstChild = versionList.firstChild;
      if (firstChild) {
        const firstInput = firstChild.qs("input");
        if (firstInput) {
          firstInput.checked = true;
        }
      }
    }
  };
  /**
   * @function populateOptions
   * @description dynamically updates the version options with respect to the project selected.
   * @private
   */
  const populateOptions = function (currentProjKey) {
    qs("#projects-dialog #select-project").addEventListener("change", (evt) => {
      let selectedProjectKey = evt.target.value;
      let selectedProject = filteredProjects.find(
        (proj) => proj.projectkey === selectedProjectKey
      );
      let versionRadioList = qs(".version-radio-list");
      versionRadioList.innerHTML = "";
      let listItemHtml = "";
      for (version of selectedProject.versionInfo) {
        listItemHtml += `<li class="version-item"><input class="version-radio-input" type=radio value=${version.vname}></input><span>${version.vname}</span><span>${version.description}</span></li>`;
      }
      versionRadioList.innerHTML = listItemHtml;
      selectFirstVersion();
    });
    let currentProject = filteredProjects.find(
      (proj) => proj.projectkey === currentProjKey
    );
    let versionInfo = [];
    if (currentProject) {
      versionInfo = currentProject.versionInfo;
    } else {
      const selectedProjectKey = qs("#projects-dialog #select-project").value;
      const selectedProject = filteredProjects.find(
        (proj) => proj.projectkey === selectedProjectKey
      );
      if (selectedProject) {
        versionInfo = selectedProject.versionInfo;
      }
    }
    if (versionInfo.length > 0) {
      let versionRadioList = qs(".version-radio-list");
      versionRadioList.innerHTML = "";
      let listItemHtml = "";
      for (version of versionInfo) {
        listItemHtml += `<li class="version-item"><input class="version-radio-input" type=radio value=${version.vname}></input><span>${version.vname}</span><span>${version.description}</span></li>`;
      }
      versionRadioList.innerHTML = listItemHtml;
      selectFirstVersion();
    }
  };

  /**
   * @function getProjects
   * @description calls the dashboard projects api and filters the projects with state > 4.
   * @private
   */
  const getProjects = async function () {
    result = await APP.getAllProjects().then((response) => {
      projects = response.data.posts;
    });
    filteredProjects = JSON.parse(JSON.stringify(projects));
    for (proj of filteredProjects) {
      proj.versionInfo = proj.versionInfo.filter(
        (version) => version.state > 4
      );
    }
    filteredProjects = filteredProjects.filter(
      (proj) => proj.versionInfo.length > 0
    );
    return filteredProjects;
  };

  /**
   * @function showProjectsDialog
   * @description shows the select project dialog.
   * @private
   */
  const showProjectsDialog = async function () {
    if (qs("#dialogs-active #projects-dialog")) {
      //if dialog already exists, cancel old dialog
      let projectsDialog = qs("#dialogs-active #projects-dialog");
      projectsDialog.fireCustomEvent("cancelled", {
        message: "Another dialog triggered.",
      });
    }
    //filter projects for current project type
    const currentProjectType = PROJECT.currentProject().ptype;
    const filterdProjectsData = projectsData.filter((project) => {
      return project.ptype === currentProjectType;
    });
    const currentProjKey = PROJECT.currentProjectKey();
    var projectsDialog = null;
    projectsDialog = createNode(
      projectsdialogTemplate({
        projectsData: filterdProjectsData,
        currentProjKey: currentProjKey,
      })
    );
    return new Promise((resolve, reject) => {
      qs("#dialogs-sleeping").appendChild(projectsDialog);
      APP.showDialog(projectsDialog);
      let okAction = (evt) => {
        if (
          evt.type.toLowerCase() == "keypress" &&
          evt.key.toLowerCase() !== "enter"
        ) {
          return;
        }
        importConfigurationData();
        APP.hideDialog(projectsDialog);
        projectsDialog.remove();
      };
      populateOptions(currentProjKey);
      registerEventListenerOnRadios();
      projectsDialog
        .qs("#import-configs-button")
        .addEventListener("click", okAction);
      projectsDialog.addEventListener("keypress", okAction);
      projectsDialog.addEventListener("cancelled", (evt) => {
        projectsDialog.remove();
        reject(`Cancelled. ${evt.detail.message}`);
      });
      [].forEach.call(
        projectsDialog.qsa("button.close, #cancel-button"),
        function (button) {
          button.addEventListener("click", () => {
            APP.hideDialog(projectsDialog);
            projectsDialog.remove();
            reject("cancelled");
          });
        }
      );
    });
  };

  /**
   * @method saveFeatureConfig
   * @description callback for the save button click. Makes a call to the de/modelcfg API on the server with array of
   * dropped features and the name of the target feature and optional id feature.
   * @public
   * @async
   */
  const saveFeatureConfig = async function () {
    let url = SERVER.getBaseAddress() + "de/modelcfg",
      userHash = CREDENTIALS.getUserCreds(),
      projectKey = PROJECT.currentProjectKey(),
      isDVCategorical = false;
    APP.setProgress("Saving features...");
    handleEmptyUserHashAndProjectKey(userHash, projectKey);
    var uidVarObject = { uidVar: "" },
      dropVarObject = { dropVar: [] },
      dvVarObject = { dvVar: "" },
      catListObject = { catList: [] },
      numListObject = { numList: [] },
      essentialFeaturesListObject = { essential: [] };
    var params = {
      key: userHash,
      projectKey: projectKey,
      projVersion: PROJECT.currentProjVersion(),
      post: [
        dropVarObject,
        dvVarObject,
        catListObject,
        numListObject,
        essentialFeaturesListObject,
      ],
    };
    my.listObject.items.forEach((x) => {
      x.show();
    });
    // qsa("#features-table-container .validate-action.reject:checked").forEach(
    //   (button) => {
    //     dropVarObject.dropVar.push(
    //       button
    //         .closest("tr")
    //         .qs("td[data-key='featureName']")
    //         .getAttribute("data-val")
    //     );
    //   }
    // );
    dropVarObject.dropVar = selectionModel.reject;

    // qsa("#features-table-container .validate-action.essential:checked").forEach(
    //   (button) => {
    //     essentialFeaturesListObject.essential.push(
    //       button
    //         .closest("tr")
    //         .qs("td[data-key='featureName']")
    //         .getAttribute("data-val")
    //     );
    //   }
    // );
    essentialFeaturesListObject.essential = selectionModel.essential;

    // qsa("#features-table-container .validate-action.target:checked").forEach(
    //   (button) => {
    //     dvVarObject.dvVar = button
    //       .closest("tr")
    //       .qs("td[data-key='featureName']")
    //       .getAttribute("data-val");
    //   }
    // );
    dvVarObject.dvVar = selectionModel.target;
    dvVarObject.dvId = selectionModel.id;
    if (
      (!dvVarObject.dvVar || !dvVarObject.dvId) &&
      (PROJECT.currentProject().ptype == "classification" ||
        PROJECT.currentProject().ptype == "txt-mclass" ||
        PROJECT.currentProject().ptype == "regression")
    ) {
      let msg = "Please select a target and a UID variable.";
      APP.showError(msg);
      APP.resetProgress();
      return;
    } else if (
      !dvVarObject.dvId &&
      (PROJECT.currentProject().ptype == "anomaly" ||
        PROJECT.currentProject().ptype == "segmentation")
    ) {
      let msg = "Please select a UID variable.";
      APP.showError(msg);
      APP.resetProgress();
      return;
    }
    // qsa("#features-table-container .feature-type-select").forEach((select) => {
    //   if (select.value == "numeric") {
    //     numListObject.numList.push(
    //       select
    //         .closest("tr")
    //         .qs("td[data-key='featureName']")
    //         .getAttribute("data-val")
    //     );
    //   }
    //   if (select.value == "categorical") {
    //     catListObject.catList.push(
    //       select
    //         .closest("tr")
    //         .qs("td[data-key='featureName']")
    //         .getAttribute("data-val")
    //     );
    //   }
    // });

    tableDataArray.forEach((item) => {
      if (item.featureType === "Numeric") {
        numListObject.numList.push(item.featureName);
      } else if (item.featureType === "Categorical") {
        catListObject.catList.push(item.featureName);
      }
    });

    //add uidVar to params object only a feature has been set as ID in the table.
    // qsa("#features-table-container .validate-action.id:checked").forEach(
    //   (button) => {
    //     uidVarObject.uidVar = button
    //       .closest("tr")
    //       .qs("td[data-key='featureName']")
    //       .getAttribute("data-val");
    //   }
    // );
    uidVarObject.uidVar = selectionModel.id;

    if (uidVarObject.uidVar !== "") {
      params.post.push(uidVarObject);
    }

    if (dvVarObject.dvVar == "") {
      APP.resetProgress();
      APP.showError(
        i18n.en.APP.UI.ERROR.VALIDATE_FEATURES.TARGET_VARIABLE_NOT_SELECTED
      );
      throw new Error(
        i18n.en.APP.UI.ERROR.VALIDATE_FEATURES.TARGET_VARIABLE_NOT_SELECTED
      );
    }

    isDVCategorical = catListObject.catList.indexOf(dvVarObject.dvVar) >= 0;
    let result = null;
    let error = null;
    try {
      if (useTestData) {
        result = await VALIDATE_FEATURES_DATA.save(params);
      } else {
        // console.log(params);
        result = await SERVER.postData(url, params);
      }
    } catch (err) {
      result = null;
      error = err;
      throw error;
    }
    if (result === "ROUTES_MISMATCHED") {
      return;
    }
    if (
      result == null ||
      (result.status != "success" &&
        !(result.status >= 200 && result.status < 300))
    ) {
      APP.resetProgress();
      let msg = null;
      if (result && result.data) {
        if (result.data.reason) {
          msg = `Could not save feature settings. Server error: ${result.data.reason}`;
        } else if (result.data.message) {
          msg = `Could not save feature settings. Server error: ${result.data.message}`;
        }
      } else {
        if (error) {
          msg = `Could not save feature settings. Server error: ${error.message}`;
        } else {
          msg =
            "Could not save feature settings. Please contact an Administrator.";
        }
      }
      if (msg) {
        APP.showError(msg);
      }
      let err = new Error(msg);
      err.name = "GenericError";
      throw err;
    } else {
      APP.showInfo(i18n.en.APP.UI.INFO.VALIDATE_FEATURES.SUCCESS);
      STORE.setProjectMetadata(
        projectKey,
        PROJECT.currentProjVersion(),
        my.STORE_KEY + "_features_saved",
        true
      );

      if (isDVCategorical && PROJECT.currentProject().ptype !== "txt-mclass") {
        dvConfig()
          .then(function () {
            STORE.setProjectMetadata(
              projectKey,
              PROJECT.currentProjVersion(),
              my.STORE_KEY + "target_feature_config_saved",
              true
            );
            navigateAwayToNextPage();
            APP.showInfo(
              i18n.en.APP.UI.INFO.VALIDATE_FEATURES.DV_CONFIG.SUCCESS
            );
          })
          .catch(function (err) {
            APP.showError(err.message);
            APP.resetProgress();
          });
      } else {
        navigateAwayToNextPage();
      }
    }
    APP.resetProgress();
  };

  /**
   * @method navigateAwayToNextPage
   * @description in case of successful save with numerical target dv config or successful
   * save of both feature config and dv config in case of categorical target dv, disable the
   * save button and navigate away to the explore-data page.
   */
  const navigateAwayToNextPage = function () {
    const projectKey = PROJECT.currentProjectKey();
    qs("button#save-validate-features").setAttribute("disabled", "disabled");
    APP.router.navigate(`/explore-data/${projectKey}`);
  };

  /**
   * @method dvConfig
   * @description get existing dv config from the server. show dialog and get user's configuration. save new configuration
   * @async
   * @private
   */
  const dvConfig = async function () {
    dvConfigData = await getDVConfig();
    dvConfigData = dvConfigData.data.posts;
    dvConfigData.forEach((dv) => (dv.ismandatory = !empty(dv.ismandatory)));
    dvConfigNewData = JSON.parse(JSON.stringify(dvConfigData));
    await showDVConfigDialog();
    await saveDVConfig();
  };

  /**
   * @method showDVConfigDialog
   * @description makes a call to get the current dv configuration from the server
   * @private
   * @async
   */
  const showDVConfigDialog = async function () {
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {
      let dlg = qs("#dv-configuration-dialog");
      if (empty(dlg)) {
        dlg = createNode(dvconfigdlgTemplate());
        qs("#dialogs-sleeping").appendChild(dlg);
      }
      setupDlgConfigLists(dlg);
      APP.showDialog(dlg);
      dlg.qs(".save-button").addEventListener("click", () => {
        APP.hideDialog(dlg);
        dlg.remove();
        resolve(dvConfigNewData);
      });
      dlg.qs(".reset-button").addEventListener("click", () => {
        dlg
          .qsa(".dv-list-container:not(.template)")
          .forEach((el) => el.remove());
        setupDlgConfigLists(dlg);
        dvConfigNewData = JSON.parse(JSON.stringify(dvConfigData));
      });
    });
  };

  /**
   * Sets up the drag and drop lists in the DV config dialog based on dvConfigData
   * @param {HTMLDialogElement} dlg
   */
  const setupDlgConfigLists = function (dlg) {
    const listContainerTemplate = dlg.qs(".dv-list-container.template");
    dvConfigData.forEach((item) => {
      const clone = listContainerTemplate.cloneNode(true),
        dv = clone.qs(".dv span"),
        list = clone.qs(".dv-value-list"),
        listItemTemplate = list.qs(".dv-value.template");
      clone.removeClass("template");
      dv.innerText = item.dv;
      dv.setAttribute("data-value", item.dv);
      dv.setAttribute("data-ismandatory", item.ismandatory);
      item.values.forEach((value) => {
        const liclone = listItemTemplate.cloneNode(true);
        liclone.removeClass("template");
        liclone.setAttribute("data-value", value);
        liclone.innerText = value;
        list.appendChild(liclone);
      });
      dlg.qs(".lists-container").appendChild(clone);
      if (item.values.length == 0 && item.ismandatory) {
        //if it's the only one mark so that it can't be dragged
        clone.addClass("error");
      }
      if (item.values.length <= 2) {
        clone.style.setProperty("--list-item-count", 2);
      } else if (item.values.length >= 6) {
        clone.style.setProperty("--list-item-count", 6);
      } else {
        clone.style.setProperty("--list-item-count", item.values.length);
      }
      dlg.qsa(".dv-list-container:not(.template)").forEach((listContainer) => {
        new Sortable(listContainer.qs(".dv-value-list"), {
          group: "dvs", // set both lists to same group
          animation: 150,
          onAdd: function (evt) {
            const fromDV = parseInt(
                evt.from
                  .closest(".dv-list-container")
                  .qs(".dv span")
                  .getAttribute("data-value")
              ),
              toDV = parseInt(
                evt.to
                  .closest(".dv-list-container")
                  .qs(".dv span")
                  .getAttribute("data-value")
              ),
              transferredValue = evt.item.getAttribute("data-value");
            dvConfigNewData.forEach((config) => {
              if (config.dv === fromDV) {
                config.values.splice(
                  config.values.indexOf(transferredValue),
                  1
                );
              }
              if (config.dv === toDV) {
                config.values.push(transferredValue);
              }
            });
            [evt.from, evt.to].forEach((ul) => {
              //if either list has become empty, check whether it is allowed, if not mark as error
              const items = ul.qsa(".dv-value:not(.template)"),
                container = ul.closest(".dv-list-container"),
                dv = container.qs(".dv span"),
                ismandatory =
                  dv.getAttribute("data-ismandatory").toLowerCase() === "true";
              if (items.length === 0 && ismandatory) {
                container.addClass("error");
              } else {
                container.removeClass("error");
              }
              if (items.length <= 2) {
                container.style.setProperty("--list-item-count", 2);
              } else if (items.length >= 6) {
                container.style.setProperty("--list-item-count", 6);
              } else {
                container.style.setProperty("--list-item-count", items.length);
              }
            });
            if (
              qsa("#dv-configuration-dialog .dv-list-container.error").length >
              0
            ) {
              qs("#dv-configuration-dialog .save-button").setAttribute(
                "disabled",
                "disabled"
              );
              qs("#dv-configuration-dialog .save-button").setAttribute(
                "title",
                i18n.en.APP.UI.BTN.VALIDATE_FEATURES.DV_CONFIG.SAVE.ERROR_TITLE
              );
            } else {
              qs("#dv-configuration-dialog .save-button").removeAttribute(
                "disabled"
              );
              qs("#dv-configuration-dialog .save-button").setAttribute(
                "title",
                i18n.en.APP.UI.BTN.VALIDATE_FEATURES.DV_CONFIG.SAVE.TITLE
              );
            }

            evt.to.qsa(".dv-value:not(.template)");
          },
        });
      });
    });
  };

  /**
   * @method getDVConfig
   * @description make an api call to /getdv to fetch the current configuration of the
   * dv value mappings
   * @return {object} the data returned by the server
   * @throws {Error} a generic error with the error message in case of API failure or insufficient params
   * @private
   * @async
   */
  const getDVConfig = async function () {
    let url = SERVER.getBaseAddress() + "modelcfg/getdv",
      userHash = CREDENTIALS.getUserCreds(),
      projectKey = PROJECT.currentProjectKey();

    APP.setProgress(i18n.en.APP.UI.FOOTER.PROGRESS.FETCHING_CURRENT_DV_CONFIG);
    if (userHash == null) {
      throw new Error(
        i18n.en.APP.UI.ERROR.VALIDATE_FEATURES.DV_CONFIG.USER_NOT_LOGGED_IN
      );
    }
    if (projectKey == null) {
      throw new Error(
        i18n.en.APP.UI.INFO.VALIDATE_FEATURES.NOT_IN_CONTEXT_OF_PROJECT
      );
    }
    let params = {
      key: userHash,
      projectKey: projectKey,
      projVersion: PROJECT.currentProjVersion(),
    };
    let result = null;
    try {
      if (useTestData) {
        result = await VALIDATE_FEATURES_DATA.getDVConfig(params);
      } else {
        result = await SERVER.postData(url, params);
      }
    } catch (err) {
      result = null;
    }
    if (result === "ROUTES_MISMATCHED") {
      return;
    }
    if (
      result == null ||
      (result.status != "success" &&
        !(result.status >= 200 && result.status < 300))
    ) {
      APP.showError(
        i18n.en.APP.UI.ERROR.VALIDATE_FEATURES.DV_CONFIG.FETCH_ERROR
      );
      APP.resetProgress();
      let err = new Error(
        i18n.en.APP.UI.ERROR.VALIDATE_FEATURES.DV_CONFIG.FETCH_ERROR
      );
      err.name = "GenericError";
      throw err;
    }
    return result;
  };

  /**
   * @method saveDVConfig
   * @description save the user specified mapping of DV values to outcomes. Read from [dvConfigNewData](#~dvConfigNewData)
   * @throws throws an error if there's any server error or missing data
   * @async
   * @private
   */
  const saveDVConfig = async function () {
    let url = SERVER.getBaseAddress() + "modelcfg/setdv",
      userHash = CREDENTIALS.getUserCreds(),
      projectKey = PROJECT.currentProjectKey();

    APP.setProgress("Saving target config...");
    handleEmptyUserHashAndProjectKey(userHash, projectKey);
    var params = {
      key: userHash,
      projectKey: projectKey,
      projVersion: PROJECT.currentProjVersion(),
      data: dvConfigNewData,
    };

    let result = null;
    try {
      if (useTestData) {
        result = await VALIDATE_FEATURES_DATA.setDVConfig(params);
      } else {
        result = await SERVER.postData(url, params);
      }
    } catch (err) {
      result = null;
    }
    if (result === "ROUTES_MISMATCHED") {
      return;
    }
    if (
      result == null ||
      (result.status != "success" &&
        !(result.status >= 200 && result.status < 300))
    ) {
      APP.resetProgress();
      let msg = null;
      if (result && result.data && result.data.reason) {
        msg = `Could not save target settings. Server error: ${result.data.reason}`;
      } else {
        msg = i18n.en.APP.UI.ERROR.VALIDATE_FEATURES.DV_CONFIG.SAVE_ERROR;
      }
      let err = new Error(msg);
      err.name = "GenericError";
      throw err;
    }
    return result;
  };

  return my;
})(VALIDATE_FEATURES || {});
