/* global EMPTY_CUSTOMIZER, CLASSIFICATION_CUSTOMIZER, REGRESSION_CUSTOMIZER */
/**
 * @module APP
 * @description The APP module provides the application entrypoint and app level scope for
 * methods that affect the whole application.
 *
 * This file extends the module
 * with methods for customizing the UI regions for specific project types.
 * The idea is to have a base UI and then apply customizers to that modify the
 * UI at project load.
 *
 */
// eslint-disable-next-line no-extra-semi
APP = (function (my) {
  /**
   * @member {Object} _customizers
   * @description A customizer store
   * @private
   */
  const _customizers = {
    empty: EMPTY_CUSTOMIZER,
    classification: CLASSIFICATION_CUSTOMIZER,
    regression: REGRESSION_CUSTOMIZER,
    anomaly: ANOMALY_CUSTOMIZER,
    segmentation: SEGMENTATION_CUSTOMIZER,
    "txt-mclass": TEXT_MULTI_CLASSIFICATION_CUSTOMIZER,
  };

  /**
   * @method getCustomizer
   * @description return the customizer for the project type provided
   * @param {string} ptype project type: classification, regression, estimation, optimization
   * @return a customizer object on which you can call appropriate methods to customize the UI
   * @public
   */
  my.getCustomizer = function (ptype) {
    if (empty(ptype)) {
      ptype = "empty";
    }
    return _customizers[ptype];
  };

  return my;
})(APP || {});
