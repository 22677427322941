/* global */
/**
 * @module RESET_PASSWORD
 * @description Renders the reset password page to the users.
 */

var RESET_PASSWORD = (function (my) {
  const PROPERTY_BLOCK = "block";
  const PROPERTY_ZINDEX1 = "1";
  const PROPERTY_NONE = "none";
  /**
   * @private
   * @member
   * @description Regex used for password validation.
   */
  var passwordValidatorRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;

  /**
   * @private
   * @method
   * @description Gets security questions configured for a particular user.
   */
  var listQuestionData = async function () {
    let url = SERVER.getBaseAddress() + "settings/listscrtquest";
    let userHash = CREDENTIALS.getUserCreds();
    APP.setProgress("Checking for Security questions.");
    if (userHash == null) {
      throw new Error("User Not logged in cannot get security questions.");
    }
    let params = { uname: CREDENTIALS.getUsername() };
    return API_HELPER.getQuestionsForSecurity("settings/listscrtquest",params,"reset-password");
  };

  /**
   * @private
   * @method
   * @description Saves password to server.
   */
  var savePasswordToServer = async function (data) {
    let userHash = CREDENTIALS.getUserCreds();
    APP.setProgress("Saving New Password.");
    if (userHash == null) {
      throw new Error("User not logged in cannot save answers.");
    }
    let params = extend({ uname: CREDENTIALS.getUsername() }, data);
    return API_HELPER.savePasswordToServer("settings/resetpswdinsession",params);
  };

  /**
   * @private
   * @method
   * @description Collects input from all fields and validates them.
   */
  var savePassword = function () {
    // verify password with regex
    let ip1 = qs(
      "#main-content .content.reset-password .page-container .new-password input"
    );
    let ip2 = qs(
      "#main-content .content.reset-password .page-container .re-enter-new-password input"
    );
    let oldPassword = qs(
      "#main-content .content.reset-password .page-container .old-password input"
    );
    if (
      oldPassword.value == "" ||
      oldPassword.value == undefined ||
      oldPassword.value == null
    ) {
      APP.showError("Please enter old password first.");
      return;
    }
    if (ip1.value == "" || ip1.value == null || ip1.value == undefined) {
      APP.showError("Please enter a new password.");
      return;
    }
    if (ip1.value != ip2.value) {
      APP.showError("Entered Passwords don't match.");
      return;
    }

    if (!ip1.value.match(passwordValidatorRegex)) {
      APP.showError("New password does not follow password policy.");
      return;
    }
    savePasswordToServer({
      pswd: ip1.value,
      currentPswd: oldPassword.value,
    }).then(function (result) {
      if (result.status == 404) {
        APP.showError(result.data.reason);
      } else {
        let timeDelay = function (time) {
          let tic = (resolve, reject) => {
            if (time == 0) {
              resolve({ msg: "DONE" });
            } else {
              APP.showInfo(
                `Password reset successfull. You will be redirected to login screen in ${time} seconds.`
              );
              time = time - 1;
              setTimeout(tic, 1000, resolve, reject);
            }
          };
          return new Promise(tic);
        };
        // Time in seconds.
        timeDelay(3).then(() => {
          setCookie("resetReqd", false);
          APP.router.navigate("/logout");
        })
      }
    });
  };

  /**
   * @private
   * @method
   * @description Initialization function
   */
  var init = function () {
    qs(
      "#main-content .content.reset-password"
    ).innerHTML = resetpasswordTemplate();
    // call here password validator.
    let button = qs(
      "#main-content .content.reset-password .page-container .button-container button"
    );
    button.addEventListener("click", savePassword);
  };
  /**
   * @public
   * @method
   * @description Fetches questions configured for user and start initialization of page.
   */
  my.loadPage = function () {
    qs("#main-content .content.reset-password").style.display = PROPERTY_BLOCK;
    qs("#main-content .content.reset-password").style.zIndex = PROPERTY_ZINDEX1;

    listQuestionData().then(function (data) {
      if (data.qns.length > 0) {
        init();
      } else {
        APP.showError("Configure Security Policy First");
      }
    });
  };
  /**
   * @public
   * @method
   * @description Unloads the page, removes all event listeners.
   */
  my.unloadPage = function () {
    let button = qs(
      "#main-content .content.reset-password .page-container .button-container button"
    );
    if (button) {
      button.removeEventListener("click", savePassword);
    }
    qs("#main-content .content.reset-password").style.display = PROPERTY_NONE;
  };
  return my;
})(RESET_PASSWORD || {});
