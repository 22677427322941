/* global MP_MODEL_COMPARISON, MD_SCORE_MODELS_TEST_DATA, mdscoremodelsTemplate, DB_CONNECTION, FILE_UPLOAD */
/**
 * @module SCORE_MODELS
 * @description #/md/score-models/ page controller. This page allows the user to create and select a data source and
 * score a model on that data. The scored model is then available for download. Multiple models can be scored
 * simultaneously and their progress is shown while they are being scored.
 */
var SCORE_MODELS = (function (my) {
  /**
   * @member {string} STORE_KEY the key used to cache this screen's data in the STORE
   * @public
   */
  my.STORE_KEY = "MD_SCORE_MODELS";

  /**
   * @member {string} dataSource "test" or "train"; defaults to "test"
   * @public
   */
  my.dataSource = "test";

  /**
   * @member {string} ootDSource - The datasource to be displayed as selected in the Data source dropdown, obtained from the ODA polling response.
   * @public
   */
  my.ootDSource = "";

  /**
   * @member {object[]} modelsList List of available models, extracted from the model comparison data. This is
   * null until assigned in [load](#~load).
   * @private
   */
  var modelsList = null;

  /**
   * @member {string} currentPVersion The version of the project that is the context of this page. It is easier to
   * store it rather than pass it around in every function call.
   */
  var currentPVersion = null;

  var accessType = null;

  /**
   * @member {string} currentPKey The ID of the project that is the context of this page. It is easier to
   * store it rather than pass it around in every function call.
   */
  var currentPKey = null;

  /**
   * @method prime
   * @description Primes this view, adds the view's HTML from its JS Pug template into the DOM.
   * Registers listeners.
   * @private
   */
  var prime = function () {
    const scoringConfig = APP.getProperty(
      "project.config.ootscoring",
      PROJECT.currentProjectKey()
    );
    qs("#score-models").innerHTML = mdscoremodelsTemplate(scoringConfig);
    accessType = readCookie("accessType");
    handleButtonDisabling();
    registerListeners();
  };

  /**
   * @method registerListeners
   * @description Register listeners for:
   * * compact labels
   * * row click
   * * source selection
   * * model selection
   * * start scoring
   * @private
   */
  var registerListeners = function () {
    COMPACT_LABEL.registerCompactLabel(
      qsa("#score-models .searchBar label.compact")
    );
    // Made the buttons as dropdown options
    // qs("#source-button").addEventListener("click",event => {
    //   if(event.target.value == "Add file data source"){
    //     FILE_UPLOAD.fileUpload({
    //       projectKey: currentPKey,
    //       destinationOnSuccess: "back",
    //       destinationOnError: "back",
    //       destinationOnCancel: "back",
    //       sourceType: "production",
    //     }).then(function(conninfo){
    //       updateSourcesSelect(currentPKey);
    //     }, function(msg){
    //     });
    //   }
    //   if(event.target.value == "Add DB data source"){
    //     DB_CONNECTION.dbConnection({
    //       projectKey: currentPKey,
    //       destinationOnSuccess: "back",
    //       destinationOnError: "back",
    //       destinationOnCancel: "back",
    //       sourceType: "production",
    //     }).then(function(conninfo){
    //       updateSourcesSelect(currentPKey);
    //     }, function(msg){
    //     });
    //   }
    // });

    // Below code was when buttons were not dropdown options
    qs("#score-models #add-db-data-source").addEventListener(
      "click",
      function () {
        qs("#source-button .dropdown-trigger-input").checked = false;
        DB_CONNECTION.dbConnection({
          projectKey: currentPKey,
          destinationOnSuccess: "back",
          destinationOnError: "back",
          destinationOnCancel: "back",
          sourceType: "production",
        }).then(
          function (conninfo) {
            updateSourcesSelect(currentPKey);
          },
          function (msg) {}
        );
      }
    );

    qs("#score-models #add-file-data-source").addEventListener(
      "click",
      function () {
        qs("#source-button .dropdown-trigger-input").checked = false;
        FILE_UPLOAD.fileUpload({
          projectKey: currentPKey,
          destinationOnSuccess: "back",
          destinationOnError: "back",
          destinationOnCancel: "back",
          sourceType: "production",
        }).then(
          function (conninfo) {
            updateSourcesSelect(currentPKey);
          },
          function (msg) {}
        );
      }
    );

    qs("#score-models #start-scoring").addEventListener("click", function () {
      let source = qs(
        "#score-models .searchBar #select-md-sm-data-source"
      ).value;
      let model = qs("#score-models .searchBar #select-md-sm-model-name").value;
      let scoreID = model + "_" + source;
      if (
        continuePolling &&
        scoreListForPolling[currentPKey] &&
        scoreListForPolling[currentPKey][currentPVersion] &&
        scoreListForPolling[currentPKey][currentPVersion].indexOf(
          "" + scoreID
        ) >= 0
      ) {
        APP.showWarning(
          "Scoring is in progress for this model. Please select a different model or source"
        );
        return;
      }
      APP.dismissMessage();
      let previousRoute = STORE.here;
      startScoring({
        projectKey: currentPKey,
        projVersion: PROJECT.currentProjVersion(),
        model: model,
        source: source,
      })
        .then((scoringID) => {
          getScoresTableRows({
            projectKey: currentPKey,
            projVersion: PROJECT.currentProjVersion(),
            scoring_ids: [scoringID.data.posts[0].id],
          }).then((tableRows) => {
            let currentRoute = STORE.here;
            if (currentRoute != previousRoute) {
              return;
            }
            progressTDValueObserver.disconnect();
            let tbody = document.createElement("tbody");
            tbody.innerHTML = tableRows;
            while (tbody.childNodes.length > 0) {
              let trs = qsa("#score-models .tableContainer table tbody tr");
              const tr = tbody.childNodes[0];
              if (tr.getAttribute("status").toLowerCase() == "running") {
                addScoreForPolling(tr.getAttribute("score-id"));
              }
              if (trs.length == 1) {
                qs("#score-models .tableContainer table tbody").appendChild(tr);
              } else {
                qs("#score-models .tableContainer table tbody").insertBefore(
                  tr,
                  trs[1]
                );
              }
            }
            qsa("#score-models #md-sm-table .bind").forEach((x) =>
              progressTDValueObserver.observe(x, { attributes: true })
            );
            if (scoresPending()) {
              pollScoringProgress(null, 2);
            }
          });
        })
        .catch(function (err) {
          APP.showError(err.message);
        });
    });

    qs("#score-models #md-sm-table").addEventListener("click", function (evt) {
      let tr = null;
      if (evt instanceof HTMLElement) {
        tr = evt.closest("tr");
      } else {
        tr = evt.target.closest("tr");
      }
      if (!tr) {
        return;
      }
      const modelID = tr.getAttribute("model");
      const source = tr.getAttribute("data-source");
      if (
        evt.srcElement &&
        evt.srcElement.hasClass("dpath") &&
        evt.srcElement.hasClass("download-auto-code")
      ) {
        downloadScoreModel(modelID, source);
      }
    });
  };

  var downloadScoreModel = function (modelId, source) {
    let downloadURL = `${SERVER.getBaseAddress()}downloadootscore?projVersion=${PROJECT.currentProjVersion()}&projectKey=${PROJECT.currentProjectKey()}&modelName=${modelId}&fileName=${source}`;
    window.open(downloadURL);
  };

  /**
   * @method load
   * @description load the page's template and add the scope markers for the CSS. load the data for this page,
   * either from the comp/ API or from the STORE.
   * @param {string} pkey project key which is the context for this view.
   * @async
   * @private
   */
  var load = async function (pkey) {
    currentPKey = pkey;
    APP.resetCurrentPageMarker();
    qs("#main-content").setAttribute("class", "");
    qs("#main-content").addClass("md-score-models");
    modelsList = await getModelsList(currentPKey);
    prime();
  };

  /**
   * @method show
   * @description Load and show the model scoring page:
   * * Load the data
   * * Load the model names for the select box
   * * Load the source names for the select box
   * * Load the data into the table and add all the table rows
   * * Start polling for scores if scoring is in progress and render that
   * @param {string} pkey The project ID of the project in context
   * @async
   * @public
   */
  my.show = async function (pkey) {
    modelsList = null;
    await load(pkey);
    currentPVersion = PROJECT.currentProjVersion();
    loadModelsSelect(pkey);
    await updateSourcesSelect(pkey);
    await loadScoresTable(pkey);
    if (scoresPending()) {
      pollScoringProgress(null, 2);
    }
  };

  const handleButtonDisabling = function () {
    let addFileDataSourceButton = qs("#score-models #add-file-data-source");
    let addDBDataSourceButton = qs("#score-models #add-db-data-source");
    let startScoringButton = qs("#score-models #start-scoring");
    let splitButton = qs("#score-models #source-button ");
    if (accessType == "view") {
      addFileDataSourceButton.disabled = true;
      addDBDataSourceButton.disabled = true;
      startScoringButton.disabled = true;
      splitButton.addClass("disabled-bg");
      splitButton.setAttribute("tooltip", "Permission Denied");
      splitButton.setAttribute("flow", "middle-down");
      startScoringButton.setAttribute("tooltip", "Permission Denied");
      startScoringButton.setAttribute("flow", "middle-down");
    }
  };

  /**
   * @method loadModelsSelect
   * @description Use data from the list of models obtained in [load](#~load) to populate the dropdown
   * that allows the user to select a model to score.
   * **NOTE**: this method does not check whether the [modelsList](#~modelsList) is populated. Care should
   * be taken to call this method only after [load](#~load) has been called.
   * @param {string} pkey The project ID of the project in context
   * @private
   */
  // eslint-disable-next-line no-unused-vars
  var loadModelsSelect = function (pkey) {
    let html = "";
    Object.keys(modelsList).forEach((x) => {
      if (x === "mlops_deploy") {
        return;
      }
      // eslint-disable-next-line no-unused-vars
      ["test"].forEach((source) => {
        html += `<option value="${x.toUpperCase()}" id="md-sm-model-name-option-${x.toUpperCase()}">${
          modelsList[x].name
        }</option>`;
      });
    });
    qs("#score-models #select-md-sm-model-name").innerHTML += html;
  };

  /**
   * @method updateSourcesSelect
   * @description Use data from the connection/list/sources API to populate the select box
   * that lists the available data sources.
   * @param {string} pkey The project ID of the project in context
   * @private
   * @async
   */
  var updateSourcesSelect = async function (pkey) {
    let html = "";
    let sourcesList = [];

    try {
      sourcesList = await APP.loadAvailableSources({
        projectKey: pkey,
        projVersion: PROJECT.currentProjVersion(),
      });
      /*
       * Server returns array of objects of array of objects... This is probably an implementation detail
       * leaking into the API. Hence the magic number 1 for getting the OutOfTime object.
       */
      if (
        sourcesList.data.posts[1]["OutOfTime"] &&
        sourcesList.data.posts[1]["OutOfTime"].length > 0
      ) {
        sourcesList = sourcesList.data.posts[1]["OutOfTime"];
        if (!sourcesList) {
          sourcesList = [];
        }
      } else {
        sourcesList = [];
      }
    } catch (err) {
      console.error(err.message);
      APP.showError(err.message);
      sourcesList = [];
    }
    sourcesList.forEach((x) => {
      let name = x.name;
      if (x.name.includes("-")) {
        name = x.name.substr(0, x.name.lastIndexOf("-"));
      }
      html += `<option class="md-sm-data-source" ${
        name === my.ootDSource ? "selected" : ""
      } value="${name}" id="md-sm-source-option-${x.name}">${name} [${
        x.type
      }]</option>`;
    });
    qsa("#score-models #select-md-sm-data-source .md-sm-data-source").forEach(
      (x) => x.remove()
    );
    qs("#score-models #select-md-sm-data-source").innerHTML += html;
    qs("#score-models #select-md-sm-data-source").setAttribute(
      "data-source-count",
      sourcesList.length
    );
  };

  /**
   * @method getScoresTableRows
   * @description Load the scores
   * @param {object} iparams userHash(optional) and projectKey(required)
   * @property {string} iparams.projectKey id of the project for which to get scores
   * @async
   * @private
   */
  var getScoresTableRows = async function (iparams) {
    let html = "";
    APP.setProgress("Loading scores...", true);
    let modelScores = await loadScores(iparams);
    let posts = modelScores.data.posts;
    if (posts && posts.length > 0) {
      modelScores = posts;
    } else {
      modelScores = [];
    }
    for (let i = 0; i < modelScores.length; i++) {
      let score = modelScores[i]; //[0];
      let model = await getModelDetails(iparams.projectKey, score.model);
      if (score.status == "running") {
        addScoreForPolling(score.id);
      }
      const scoringConfig = APP.getProperty(
        "project.config.ootscoring",
        PROJECT.currentProjectKey()
      );
      const rowData = {
        type: model.platform.toLowerCase(),
        id: score.id,
        name: model.name,
        source: score.source,
        recommended: model.isPreferred,
        dpath: `${SERVER.getBaseAddress()}downloadootscore?projVersion=${PROJECT.currentProjVersion()}&projectKey=${PROJECT.currentProjectKey()}&modelName=${
          score.model
        }&fileName=${score.source}`,
        hasTarget: !empty(score.hasTarget),
        analysisLink:
          PROJECT.currentProject().ptype == "segmentation"
            ? `mlleaderboard-segmentation/${
                PROJECT.currentProjectCustomizer().reroute().subnav
              }/${
                score.source
              }/${score.model.toLowerCase()}/${PROJECT.currentProjectKey()}`
            : `mp/mll/${PROJECT.currentProjectCustomizer().reroute().subnav}/${
                score.source
              }/${score.model.toLowerCase()}/${PROJECT.currentProjectKey()}`,
        status: score.status,
        model: score.model,
      };
      for (let i in scoringConfig.columns) {
        const col = scoringConfig.columns[i];
        if (strictEmpty(score[col["data-key"]])) {
          rowData[col["data-key"]] = "-";
        } else {
          rowData[col["data-key"]] = score[col["data-key"]];
        }
      }
      html += addTableRow(rowData);
    }
    return html;
  };

  /**
   * @method loadScoresTable
   * @description Clear the scores table, get the rendered table rows for the scoring data and add it
   * to the table, and then register the table to listen to attribute changes on the TDs for progress updates.
   * @param {string} pkey The project ID of the project in context
   * @async
   * @private
   */
  var loadScoresTable = async function (pkey) {
    let html = await getScoresTableRows({
      projectKey: pkey,
      projVersion: PROJECT.currentProjVersion(),
    });
    qsa(
      "#score-models .tableContainer table tbody tr:not(.template-row)"
    ).forEach((x) => x.remove());
    qs("#score-models .tableContainer table tbody").innerHTML += html;
    qsa("#score-models #md-sm-table .bind").forEach((x) =>
      progressTDValueObserver.observe(x, { attributes: true })
    );
  };

  /**
   * @member {class} MutationObserver
   * @description Aliasing the MutationObserver for cross browser compatibility. It will be used to create an
   * observer instance, [progressTDValueObserver](#~progressTDValueObserver) observe for attribute changes
   * that we'll make to the TD for progress updates.
   * @private
   */
  var MutationObserver =
    window.MutationObserver ||
    window.WebKitMutationObserver ||
    window.MozMutationObserver;

  /**
   * @member {MutationObserver} progressTDValueObserver
   * @description Observe for attribute changes that we'll make to the TD for progress updates. Update the style
   * of the TD to add a custom property --progress-value on it that will cascade a progress width change.
   * @private
   */
  var progressTDValueObserver = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if (
        mutation.type == "attributes" &&
        mutation.attributeName.startsWith("data-")
      ) {
        mutation.target.setAttribute(
          "style",
          `--progress-value: ${mutation.target.getAttribute("data-value")}`
        );
      }
    });
  });

  /**
   * @method addTableRow
   * @description Clone a template row and populate it with data.
   * @param {object} rowData One of the objects from the array from the comp API
   * @property {string} rowData.type Type of the AI/ML platform
   * @property {string} rowData.id LRM, DRF, GBM etc.
   * @property {string} rowData.name long full name
   * @property {string} rowData.source validation or training
   * @property {number} rowData.recommended marker for whether this is the preferred model
   * @property {string} rowData.dpath URL to download the model
   * @property {string} rowData.status "running" or "completed"
   * @property {string} rowData.hasTarget if true plot link is enabled
   * @private
   */
  var addTableRow = function (rowData) {
    let templateRow = qs("#score-models #md-sm-table tr.template-row");
    const scoringConfig = APP.getProperty(
      "project.config.ootscoring",
      PROJECT.currentProjectKey()
    );
    const cols = {};
    for (let i in scoringConfig.columns) {
      cols[scoringConfig.columns[i]["data-key"]] = scoringConfig.columns[i];
    }
    if (templateRow) {
      let tr = templateRow.cloneNode(true);
      tr.removeClass("template-row");
      tr.setAttribute("score-id", rowData.id);
      tr.setAttribute("data-source", rowData.source);
      tr.setAttribute("status", rowData.status.toLowerCase());
      if (rowData.recommended) {
        tr.addClass("trophy");
      }
      tr.qsa("td").forEach((x) => {
        if (x.hasClass("name")) {
          x.setAttribute("data-type", rowData.type);
          x.setAttribute("data-source", rowData.source);
          x.addClass(`type-${rowData.type}`);
        }
        if (x.hasClass("dpath")) {
          if (typeof rowData.dpath !== "undefined" && rowData.dpath) {
            let url = `${SERVER.getBaseAddress()}downloadootscore?projVersion=${PROJECT.currentProjVersion()}&projectKey=${PROJECT.currentProjectKey()}&modelName=${
              rowData.model
            }&fileName=${rowData.source}`;
            x.setAttribute("model", rowData.model);
            tr.setAttribute("model", rowData.model);
          }
        } else if (x.hasClass("analysis-link")) {
          if (
            typeof rowData.analysisLink !== "undefined" &&
            rowData.analysisLink
          ) {
            if (empty(rowData.hasTarget)) {
              x.addClass("disabled");
            } else {
              x.qs("a").setAttribute("href", `#/${rowData.analysisLink}`);
              x.removeClass("disabled");
            }
          }
        } else if (x.hasClass("progress")) {
          let progress = rowData.progress;
          if (!progress) {
            progress = 0;
          }
          x.setAttribute("data-value", progress);
          x.setAttribute("style", "--progress-value: 0");
          x.addClass("bind");
        } else {
          let attr = x.getAttribute("data-key");
          let value = rowData[attr];
          if (Array.isArray(value)) {
            value = value.length > 0 ? value[0] : "";
          }
          value = COMPARISON_TABLE.setValue(value, cols, attr);
          x.innerText = value;
        }
      });
      return tr.outerHTML;
    }
    return "";
  };

  /**
   * @method getModelsList
   * @description Get the list of models from the [STORE](module-STORE.html) if available, else from the comp API
   * and then store it in the [STORE](module-STORE.html)
   * @param {string} pkey project key which is the context for this view.
   * @return {Promise} A Promise that resolves to the [modelsList](#~modelsList) array.
   * @private
   * @async
   */
  var getModelsList = async function (pkey) {
    if (modelsList) {
      return Promise.resolve(modelsList);
    }
    modelsList = STORE.getProjectData(
      pkey,
      PROJECT.currentProjVersion(),
      MP_MODEL_COMPARISON.STORE_KEY
    );
    if (!modelsList) {
      modelsList = await MP_MODEL_COMPARISON.loadData({
        projectKey: pkey,
        projVersion: PROJECT.currentProjVersion(),
      });
      STORE.setProjectData(
        pkey,
        PROJECT.currentProjVersion(),
        MP_MODEL_COMPARISON.STORE_KEY,
        modelsList
      );
      STORE.setProjectMetadata(
        pkey,
        PROJECT.currentProjVersion(),
        MP_MODEL_COMPARISON.STORE_KEY + "_compData_loaded",
        true
      );
    }
    return modelsList;
  };

  /**
   * @method getModelDetails
   * @description Look up a model from the [modelsList](#~modelsList).
   * @param {string} pkey project key which is the context for this view.
   * @param {string} modelID ID of the model, "LRM", "DRF", "GBM" etc.
   * @private
   * @async
   */
  var getModelDetails = async function (pkey, modelID) {
    modelsList = await getModelsList(pkey);
    let model = null;
    for (let x in modelsList) {
      if (x.toLowerCase() == modelID.toLowerCase()) {
        model = modelsList[x];
        break;
      }
    }
    return model;
  };

  /**
   * @method loadScores
   * @description Gets the list of scores from the [SERVER](module-SERVER.html)'s score/list API
   * @param {object} iparams userHash(optional) and projectKey(required)
   * @property {string} iparams.projectKey id of the project for which to get scores
   * @private
   * @async
   */
  var loadScores = async function (iparams) {
    let url = SERVER.getBaseAddress() + "score/list";
    let userHash = CREDENTIALS.getUserCreds();
    if (userHash == null) {
      throw new Error(i18n.en.APP.UI.ERROR.MODELCFG.USER_NOT_LOGGED_IN);
    }
    let params = extend(
      {
        key: userHash,
        projectKey: "",
        projVersion: "",
      },
      iparams
    );

    let result = null;
    try {
      if (useTestData) {
        result = await MD_SCORE_MODELS_TEST_DATA.getResults(url, params);
      } else {
        result = await SERVER.postData(url, params);
      }
    } catch (err) {
      result = null;
    }
    if (result === "ROUTES_MISMATCHED") {
      return;
    }
    APP.resetProgress();
    if (
      result == null ||
      (result.status != "success" &&
        !(result.status >= 200 && result.status < 300))
    ) {
      let msg = "";
      if (!empty(result) && !empty(result.data) && !empty(result.data.reason)) {
        msg = result.data.reason;
      }
      msg = sprintf(
        i18n.en.APP.UI.ERROR.MODELDEPLOYMENT.LIST_SCORES_ERROR,
        msg
      );
      let err = new Error(msg);
      err.name = "ScoreModelsError";
      throw err;
    }

    return result;
  };

  /**
   * @method getScoreProgress
   * @description Get the progress of the scoring process for the specified IDs
   * @param {object} iparams userHash(optional) and projectKey(required)
   * @property {string} iparams.projectKey id of the project in context
   * @property {string[]} iparams.scoring_ids Array of IDs returned by the score/start API or score/list API
   * @return {object} The raw result sent by the server for the score/progress API
   * @private
   * @async
   */
  var getScoreProgress = async function (iparams) {
    let url = SERVER.getBaseAddress() + "score/progress";
    let userHash = CREDENTIALS.getUserCreds();
    APP.setProgress("Getting scores...", false);
    if (userHash == null) {
      throw new Error(i18n.en.APP.UI.ERROR.MODELCFG.USER_NOT_LOGGED_IN);
    }
    if (scoreListForPolling[currentPKey] == undefined) {
      scoreListForPolling[currentPKey] = {};
      scoreListForPolling[currentPKey][currentPVersion] = [];
    }
    let params = extend(
      {
        key: userHash,
        projectKey: "",
        scoring_ids: scoreListForPolling[currentPKey][currentPVersion],
      },
      iparams
    );

    let result = null;
    try {
      if (useTestData) {
        result = await MD_SCORE_MODELS_TEST_DATA.getProgress(url, params);
      } else {
        result = await SERVER.postData(url, params);
      }
    } catch (err) {
      result = null;
      APP.resetProgress();
    }
    if (result === "ROUTES_MISMATCHED") {
      return;
    }
    APP.resetProgress();
    if (
      result == null ||
      (result.status != "success" &&
        !(result.status >= 200 && result.status < 300))
    ) {
      let msg = "";
      if (!empty(result) && !empty(result.data) && !empty(result.data.reason)) {
        msg = result.data.reason;
      }
      msg = sprintf(i18n.en.APP.UI.ERROR.MODELDEPLOYMENT.POLLING_ERROR, msg);
      let err = new Error(msg);
      err.name = "ScoreModelsError";
      throw err;
    }
    return result;
  };

  /**
   * @method updateProgress
   * @description This method is called with the result of the [getScoreProgress](#~getScoreProgress) method as a input
   * parameter in order to update the progress value in the UI else update the row with the actual scoring results
   * @param {object} result The value returned by the [getScoreProgress](#~getScoreProgress) method, i.e. the result of
   * the score/progress API
   */
  var updateProgress = function (result) {
    let scores = result.data.posts;
    const scoringConfig = APP.getProperty(
      "project.config.ootscoring",
      PROJECT.currentProjectKey()
    );
    const cols = {};
    for (let i in scoringConfig.columns) {
      cols[scoringConfig.columns[i]["data-key"]] = scoringConfig.columns[i];
    }
    scores.forEach((score) => {
      qs(
        `#score-models #md-sm-table tr[score-id="${score.id}"] td.progress`
      ).setAttribute("data-value", score.progress);
      if (score.progress == 100) {
        stopPollingScore(score.id);
        APP.setProgress("Loading scores...", false);
        loadScores({
          projectKey: currentPKey,
          projVersion: PROJECT.currentProjVersion(),
          scoring_ids: [score.id],
        }).then(function (result) {
          let scoresToUpdate = result.data.posts;
          for (let i = 0; i < scoresToUpdate.length; i++) {
            let scoreToUpdate = scoresToUpdate[i];
            scoreToUpdate.hasTarget = !empty(scoreToUpdate.hasTarget);
            scoreToUpdate.analysisLink =
              PROJECT.currentProject().ptype == "segmentation"
                ? `mlleaderboard-segmentation/${
                    PROJECT.currentProjectCustomizer().reroute().subnav
                  }/${
                    scoreToUpdate.source
                  }/${scoreToUpdate.model.toLowerCase()}/${PROJECT.currentProjectKey()}`
                : `mp/mll/${
                    PROJECT.currentProjectCustomizer().reroute().subnav
                  }/${
                    scoreToUpdate.source
                  }/${scoreToUpdate.model.toLowerCase()}/${PROJECT.currentProjectKey()}`;
            if (scoreToUpdate.id == score.id) {
              let tr = qs(
                `#score-models #md-sm-table tr[score-id="${score.id}"]`
              );
              tr.setAttribute("status", "completed");
              Object.keys(cols).forEach((attr) => {
                let value = scoreToUpdate[attr];
                if ("dpath" == attr || "analysis-link" == attr) {
                  return;
                }
                value = COMPARISON_TABLE.setValue(value, cols, attr);
                tr.qs(`td.${attr}`).innerText = value;
              });
              tr.qsa("td.dpath a").forEach((link) =>
                link.setAttribute("href", scoreToUpdate.dpath)
              );
              tr.qsa("td.analysis-link").forEach((analysisLinkCell) => {
                if (
                  typeof scoreToUpdate.analysisLink !== "undefined" &&
                  scoreToUpdate.analysisLink
                ) {
                  if (empty(scoreToUpdate.hasTarget)) {
                    analysisLinkCell.addClass("disabled");
                  } else {
                    analysisLinkCell
                      .qs("a")
                      .setAttribute("href", `#/${scoreToUpdate.analysisLink}`);
                    analysisLinkCell.removeClass("disabled");
                  }
                }
              });
            }
          }
        });
      }
    });
  };

  /**
   * @method startScoring
   * @description Start the scoring for a specified project+source+model.
   * @param {object} iparams userHash(optional) and projectKey(required)
   * @property {string} iparams.projectKey id of the project in context
   * @return {object} The raw result sent by the server for the score/start API
   * @private
   * @async
   */
  var startScoring = async function (iparams) {
    let url = SERVER.getBaseAddress() + "score/start";
    let userHash = CREDENTIALS.getUserCreds();
    if (userHash == null) {
      throw new Error(i18n.en.APP.UI.ERROR.MODELCFG.USER_NOT_LOGGED_IN);
    }
    let params = extend(
      {
        key: userHash,
        projectKey: "",
        projVersion: "",
      },
      iparams
    );
    APP.setProgress("Scoring...", true);
    let result = null;
    try {
      if (useTestData) {
        result = await MD_SCORE_MODELS_TEST_DATA.startScoring(url, params);
      } else {
        result = await SERVER.postData(url, params);
      }
    } catch (err) {
      APP.resetProgress();
      result = null;
    }
    if (result === "ROUTES_MISMATCHED") {
      return;
    }
    APP.resetProgress();
    if (
      result == null ||
      (result.status != "success" &&
        !(result.status >= 200 && result.status < 300))
    ) {
      let msg = "";
      if (!empty(result) && !empty(result.data) && !empty(result.data.reason)) {
        msg = result.data.reason;
      }
      msg = sprintf(
        i18n.en.APP.UI.ERROR.MODELDEPLOYMENT.START_SCORING_ERROR,
        msg
      );
      console.error(msg);
      let err = new Error(msg);
      err.name = "ScoreModelsError";
      throw err;
    }
    return result;
  };

  /**
   * @member {boolean} continuePolling
   * @description Polling stops if this is false at the time of evaluating the exit condition. This is set to `false`
   * by the [cancelPolling](#~cancelPolling) function.
   * @private
   */
  var continuePolling = true;

  /**
   * @member {object} scoreListForPolling
   * @description This is the list of IDs that will be sent to the score/progress API for getting updated progress.
   * IDs are added to the list if another model is concurrently scored, and removed from here if scoring is completed
   * for a particular model.
   * @see [addScoreForPolling](#~addScoreForPolling), [stopPollingScore](#~stopPollingScore)
   * @private
   */
  var scoreListForPolling = {};

  /**
   * @method addScoreForPolling
   * @description Adds a scoreID to the [scoreListForPolling](#~scoreListForPolling) list. This list is then used to
   * receive progress updates of the scoring process.
   * @param {string} scoreID ID of the score to be polled
   * @private
   */
  var addScoreForPolling = function (scoreID) {
    if (!scoreListForPolling[currentPKey]) {
      scoreListForPolling[currentPKey] = {};
      scoreListForPolling[currentPKey][currentPVersion] = [];
    }
    if (
      scoreListForPolling[currentPKey][currentPVersion].indexOf("" + scoreID) <
      0
    ) {
      scoreListForPolling[currentPKey][currentPVersion].push("" + scoreID);
    }
  };

  /**
   * @method stopPollingScore
   * @description Remove specified score ID from the [scoreListForPolling](#~scoreListForPolling) list. If the list
   * is empty call [cancelPolling](#~cancelPolling) funciton.
   * @param {string} scoreID ID of the score to be removed from progress update polling
   * @private
   */
  var stopPollingScore = function (scoreID) {
    let index = scoreListForPolling[currentPKey][currentPVersion].indexOf(
      "" + scoreID
    );
    if (index >= 0) {
      scoreListForPolling[currentPKey][currentPVersion].splice(index, 1);
    }
    if (scoreListForPolling[currentPKey][currentPVersion].length == 0) {
      my.cancelPolling();
    }
  };

  /**
   * @method clearPollingScoreList
   * @description Empty the [scoreListForPolling](#~scoreListForPolling) list. This is not called from anywhere,
   * but it should also call [cancelPolling](#~cancelPolling) after emptying, for efficiency. It will happen anyway
   * upon the next polling cycle.
   */
  // eslint-disable-next-line no-unused-vars
  const clearPollingScoreList = function () {
    scoreListForPolling[currentPKey][currentPVersion] = [];
  };

  /**
   * @method scoresPending
   * @description convenience method for checking whether the [scoreListForPolling](#~scoreListForPolling) list is
   * emtpy.
   * @return {boolean} true if the list has any score IDs still polling.
   * @private
   */
  var scoresPending = function () {
    if (
      scoreListForPolling[currentPKey] == undefined ||
      scoreListForPolling[currentPKey][currentPVersion] == undefined
    ) {
      return false;
    }
    return scoreListForPolling[currentPKey][currentPVersion].length > 0;
  };

  /**
   * @method cancelPolling
   * @description Sets the [continuePolling](#~continuePolling) flag false. Also clears any pending
   * polling timer.
   * @public
   */
  my.cancelPolling = function () {
    continuePolling = false;
    if (pollTimeoutToken) {
      clearTimeout(pollTimeoutToken);
    }
  };

  /**
   * @member {Number} pollTimeoutToken
   * @description This is the reference that can be used to find the current timeOut that's waiting.
   * @private
   */
  var pollTimeoutToken = null;

  /**
   * @method pollScoringProgress
   * @description Start polling the score/progress API until interrupted by user action or by completion of scoring
   * #### HOW THE POLLING CODE WORKS
   * There's a continuePolling flag that is checked each time the timer is restarted.
   * When the polling is first started, it returns a Promise that is resoved when the
   * self referential timer hits an exit condition. An exit condition is hit either when
   * the user navigates away from the page, or when the [scoreListForPolling](#~scoreListForPolling) list
   * is empty. In that case the promise is resolved.
   * @return {Promise} Returns a promise that is resolved to a completion status. It is
   * rejected in case of any error or elapse of timeout.
   * @param {Number} timeout If the timer loop is never interrupted it should be stopped in
   * this much time in milliseconds
   * @param {Number} interval Loop every interval seconds.
   * @public
   */
  const pollScoringProgress = function (timeout, interval) {
    var endTime = Number(new Date()) + (timeout || 6 * 60 * 60 * 1000);
    interval = interval * 1000 || 10 * 1000;
    continuePolling = true;

    var checkCondition = async function (resolve, reject) {
      pollTimeoutToken = null;
      // If the condition is met, we're done!
      try {
        APP.setProgress("Polling  Report...", false);
        var result = await getScoreProgress({
          projectKey: currentPKey,
          projVersion: PROJECT.currentProjVersion(),
        });
        APP.resetProgress();
      } catch (err) {
        APP.showError(err.message);
        return reject(err);
      }
      if (
        !result ||
        !result.status ||
        !(result.status >= 200 && result.status < 300)
      ) {
        let msg = "";
        if (
          !empty(result) &&
          !empty(result.data) &&
          !empty(result.data.reason)
        ) {
          msg = result.data.reason;
        }
        msg = `Error retrieving updates from server: ${msg} Please contact administrator.`;
        return reject(new Error(msg));
      }
      // If the condition isn't met but the timeout hasn't elapsed, go again
      else if (Number(new Date()) < endTime) {
        updateProgress(result);
        if (!scoresPending()) {
          return resolve("done");
        }
        if (continuePolling) {
          pollTimeoutToken = setTimeout(
            checkCondition,
            interval,
            resolve,
            reject
          );
        } else {
          continuePolling = true;
          return reject({ State: "interrupted" });
        }
      }
      // Didn't match and too much time, reject!
      else {
        return reject(
          new Error("timed out for " + pollScoringProgress + ": " + arguments)
        );
      }
    };

    return new Promise(checkCondition);
  };

  return my;
})(SCORE_MODELS || {});
