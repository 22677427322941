/* global CHART */
/**
 * @module SPIDER_CHART
 * @description draws the Spider chart shown on the `ml-leaderboard-segmentation/modelInterpretability` page. Implements the 
 * [`CHART`](module-CHART.html) interface.
 */
// eslint-disable-next-line no-extra-semi
; var SPIDER_CHART = (function (my) {
    my.type = "radar";

    // with an older version
    // my.generate = function () {
    //     console.log("generating spider chart");
    //     var w = 500,
    //         h = 500;

    //     var colorscale = d3.scaleOrdinal(d3.schemeCategory10);

    //     //Legend titles
    //     var LegendOptions = ['Smartphone', 'Tablet'];

    //     //Data
    //     var d = [
    //         [
    //             { axis: "Email", value: 0.59 },
    //             { axis: "Social Networks", value: 0.56 },
    //             { axis: "Internet Banking", value: 0.42 },
    //             { axis: "News Sportsites", value: 0.34 },
    //             { axis: "Search Engine", value: 0.48 },
    //             { axis: "View Shopping sites", value: 0.14 },
    //             { axis: "Paying Online", value: 0.11 },
    //             { axis: "Buy Online", value: 0.05 },
    //             { axis: "Stream Music", value: 0.07 },
    //             { axis: "Online Gaming", value: 0.12 },
    //             { axis: "Navigation", value: 0.27 },
    //             { axis: "App connected to TV program", value: 0.03 },
    //             { axis: "Offline Gaming", value: 0.12 },
    //             { axis: "Photo Video", value: 0.4 },
    //             { axis: "Reading", value: 0.03 },
    //             { axis: "Listen Music", value: 0.22 },
    //             { axis: "Watch TV", value: 0.03 },
    //             { axis: "TV Movies Streaming", value: 0.03 },
    //             { axis: "Listen Radio", value: 0.07 },
    //             { axis: "Sending Money", value: 0.18 },
    //             { axis: "Other", value: 0.07 },
    //             { axis: "Use less Once week", value: 0.08 }
    //         ], [
    //             { axis: "Email", value: 0.48 },
    //             { axis: "Social Networks", value: 0.41 },
    //             { axis: "Internet Banking", value: 0.27 },
    //             { axis: "News Sportsites", value: 0.28 },
    //             { axis: "Search Engine", value: 0.46 },
    //             { axis: "View Shopping sites", value: 0.29 },
    //             { axis: "Paying Online", value: 0.11 },
    //             { axis: "Buy Online", value: 0.14 },
    //             { axis: "Stream Music", value: 0.05 },
    //             { axis: "Online Gaming", value: 0.19 },
    //             { axis: "Navigation", value: 0.14 },
    //             { axis: "App connected to TV program", value: 0.06 },
    //             { axis: "Offline Gaming", value: 0.24 },
    //             { axis: "Photo Video", value: 0.17 },
    //             { axis: "Reading", value: 0.15 },
    //             { axis: "Listen Music", value: 0.12 },
    //             { axis: "Watch TV", value: 0.1 },
    //             { axis: "TV Movies Streaming", value: 0.14 },
    //             { axis: "Listen Radio", value: 0.06 },
    //             { axis: "Sending Money", value: 0.16 },
    //             { axis: "Other", value: 0.07 },
    //             { axis: "Use less Once week", value: 0.17 }
    //         ]
    //     ];

    //     //Options for the Radar chart, other than default
    //     var mycfg = {
    //         w: w,
    //         h: h,
    //         maxValue: 0.6,
    //         levels: 6,
    //         ExtraWidthX: 300
    //     }

    //     //Call function to draw the Radar chart
    //     //Will expect that data is in %'s
    //     RadarChart.draw(".model-interpretability .graph-container", d, mycfg);

    //     ////////////////////////////////////////////
    //     /////////// Initiate legend ////////////////
    //     ////////////////////////////////////////////

    //     var svg = d3.select('#body')
    //         .selectAll('svg')
    //         .append('svg')
    //         .attr("width", w + 300)
    //         .attr("height", h)

    //     //Create the title for the legend
    //     var text = svg.append("text")
    //         .attr("class", "title")
    //         .attr('transform', 'translate(90,0)')
    //         .attr("x", w - 70)
    //         .attr("y", 10)
    //         .attr("font-size", "12px")
    //         .attr("fill", "#404040")
    //         .text("What % of owners use a specific service in a week");

    //     //Initiate Legend	
    //     var legend = svg.append("g")
    //         .attr("class", "legend")
    //         .attr("height", 100)
    //         .attr("width", 200)
    //         .attr('transform', 'translate(90,20)')
    //         ;
    //     //Create colour squares
    //     legend.selectAll('rect')
    //         .data(LegendOptions)
    //         .enter()
    //         .append("rect")
    //         .attr("x", w - 65)
    //         .attr("y", function (d, i) { return i * 20; })
    //         .attr("width", 10)
    //         .attr("height", 10)
    //         .style("fill", function (d, i) { return colorscale(i); })
    //         ;
    //     //Create text next to squares
    //     legend.selectAll('text')
    //         .data(LegendOptions)
    //         .enter()
    //         .append("text")
    //         .attr("x", w - 52)
    //         .attr("y", function (d, i) { return i * 20 + 9; })
    //         .attr("font-size", "11px")
    //         .attr("fill", "#737373")
    //         .text(function (d) { return d; })
    //         ;
    // }

    // with newer version
    my.generate = function () {
        var margin = { top: 100, right: 100, bottom: 100, left: 100 },
            // width = Math.min(700, window.innerWidth - 10) - margin.left - margin.right,
            // height = Math.min(width, window.innerHeight - margin.top - margin.bottom - 20);
            width = 200;
            height = 200;

        ////////////////////////////////////////////////////////////// 
        ////////////////////////// Data ////////////////////////////// 
        ////////////////////////////////////////////////////////////// 

        var data = [
            [//iPhone
                { axis: "Battery Life", value: 0.22 },
                { axis: "Brand", value: 0.28 },
                { axis: "Contract Cost", value: 0.29 },
                { axis: "Design And Quality", value: 0.17 },
                { axis: "Have Internet Connectivity", value: 0.22 },
                { axis: "Large Screen", value: 0.02 },
                { axis: "Price Of Device", value: 0.21 },
                { axis: "To Be A Smartphone", value: 0.50 }
            ], [//Samsung
                { axis: "Battery Life", value: 0.27 },
                { axis: "Brand", value: 0.16 },
                { axis: "Contract Cost", value: 0.35 },
                { axis: "Design And Quality", value: 0.13 },
                { axis: "Have Internet Connectivity", value: 0.20 },
                { axis: "Large Screen", value: 0.13 },
                { axis: "Price Of Device", value: 0.35 },
                { axis: "To Be A Smartphone", value: 0.38 }
            ], [//Nokia Smartphone
                { axis: "Battery Life", value: 0.26 },
                { axis: "Brand", value: 0.10 },
                { axis: "Contract Cost", value: 0.30 },
                { axis: "Design And Quality", value: 0.14 },
                { axis: "Have Internet Connectivity", value: 0.22 },
                { axis: "Large Screen", value: 0.04 },
                { axis: "Price Of Device", value: 0.41 },
                { axis: "To Be A Smartphone", value: 0.30 }
            ]
        ];
        ////////////////////////////////////////////////////////////// 
        //////////////////// Draw the Chart ////////////////////////// 
        ////////////////////////////////////////////////////////////// 

        var color = d3.scaleOrdinal()
            .range(["red", "blue", "green"]);

        var radarChartOptions = {
            w: width,
            h: height,
            margin: margin,
            maxValue: 0.5,
            levels: 5,
            roundStrokes: true,
            color: color
        };
        //Call function to draw the Radar chart
        RadarChart(".model-interpretability .graph-container", data, radarChartOptions);

        ////////////////////////////////////////////
        /////////// Initiate legend ////////////////
        ////////////////////////////////////////////
        var LegendOptions = ['iPhone', 'Samsung', 'Nokia Smartphone'];
        var colorscale = d3.scaleOrdinal().range(["red", "blue", "green"]);


        var svg = d3.select('.model-interpretability .graph-container')
            .selectAll('svg')
            .append('svg')
            .attr("width", width + 300)
            .attr("height", height)

        //Create the title for the legend
        var text = svg.append("text")
            .attr("class", "title")
            .attr('transform', 'translate(90,0)')
            .attr("x", width - 70)
            .attr("y", 10)
            .attr("font-size", "12px")
            .attr("fill", "#404040")
            .text("Mobile Phones");

        //Initiate Legend	
        var legend = svg.append("g")
            .attr("class", "legend")
            .attr("height", 100)
            .attr("width", 200)
            .attr('transform', 'translate(90,20)')
            ;
        //Create colour squares
        legend.selectAll('rect')
            .data(LegendOptions)
            .enter()
            .append("rect")
            .attr("x", width - 65)
            .attr("y", function (d, i) { return i * 20; })
            .attr("width", 10)
            .attr("height", 10)
            .style("fill", function (d, i) { return colorscale(i); })
            ;
        //Create text next to squares
        legend.selectAll('text')
            .data(LegendOptions)
            .enter()
            .append("text")
            .attr("x", width - 52)
            .attr("y", function (d, i) { return i * 20 + 9; })
            .attr("font-size", "11px")
            .attr("fill", "#737373")
            .text(function (d) { return d; })
            ;
    }

    return my;
}(SPIDER_CHART || {}));
