var MP_BA_PERSONA_CREATION = (function (my) {
  /**
   * @member {string} selectedModelID
   * @description Whether the current view is for the selected model ID from the dropdown.
   * @private
   */
  var selectedModelID = null;

  /**
   * @member {object} behaviouralAnalysisData
   * @description Holds the behavioral analysis data.
   * @private
   */
  var behaviouralAnalysisData = null;

  /**
   * @member {object} data
   * @description Holds the persona data.
   * @private
   */
  var data = null;

  /**
   * @member {object} projectKey
   * @description project key of the current project.
   * @private
   */
  var projectKey = null;

  /**
   * @method loadData
   * @description makes the API call to get the behavioural analysis table data on /fetchpp with suitable payload and returns the result
   */
  const loadData = async function (iparams) {
    if (useTestData) {
      let result = await BEHAVIOURAL_ANALYSIS_DATA.getData();
      return result;
    }
    return API_HELPER.getResult("fetchpp", iparams);
  };

  /**
   * @method saveFormData
   * @description makes the API call to save the persona profile data on /savepp with suitable payload and returns the result
   */
  const saveFormData = async function (data) {
    if (useTestData) {
      return "success";
    }
    let result = null;
    const params = {
      projectKey: projectKey,
      projVersion: PROJECT.currentProjVersion(),
      modelName: selectedModelID,
      datatype: "persona",
      info: data,
    };
    result = await API_HELPER.getResult("savepp", params);
    if (
      result == null ||
      (result.status != "success" &&
        !(result.status >= 200 && result.status < 300))
    ) {
      if (result.status == 404) {
        return result;
      }
      return "failed";
    }
    return "success";
  };

  /**
   * @method getModelOptions
   * @description fetches the available models and populates the model dropdown.
   */
  const getModelOptions = function (leaderboardData) {
    const modelList = Object.keys(leaderboardData);
    selectedModelID = modelList[0];
    const selectElement = qs(".persona-creation #modelSelect");
    selectElement.innerHTML = "";
    modelList.forEach((modelID) => {
      const optionElement = document.createElement("option");
      optionElement.value = modelID;
      optionElement.textContent = leaderboardData[modelID].name;
      if (modelID === selectedModelID) {
        optionElement.selected = true;
      }
      selectElement.appendChild(optionElement);
    });
  };

  /**
   * @method modelChanged
   * @description reloads the page with the selected model from the dropdown
   */
  const modelChanged = function () {
    const selectElement = qs(".persona-creation #modelSelect");
    const selectedOption = selectElement.options[selectElement.selectedIndex];
    const selectedValue = selectedOption.value;
    selectedModelID = selectedValue;
    reloadData();
  };

  /**
   * @method updateInputField
   * @description updates the input fields of the form
   */
  const updateInputField = function (evt, field) {
    let eventClass = evt.target.getAttribute("class").split(" ")[0];
    let buttonElement = evt.target;
    let buttonContainer = evt.target.parentElement;
    let clusterID = evt.target.parentElement.getAttribute("cluster-id");
    let inputElement = evt.target.parentElement.parentElement.qs(
      `.cluster-${field}-col`
    );
    if (eventClass === "edit-description-btn") {
      inputElement.disabled = false;
      inputElement.focus();
      buttonElement.addClass("hidden");
      buttonContainer.qs(`.save-${field}`).removeClass("hidden");
      buttonContainer.qs(`.save-${field}`).addClass("save-description-btn");
      buttonContainer.qs(".stop-editing").removeClass("hidden");
      buttonContainer.qs(".stop-editing").addClass("stop-editing-btn");
    } else if (eventClass === `save-${field}`) {
      const newFieldValue = inputElement.value;
      inputElement.disabled = true;
      buttonElement.addClass("hidden");
      buttonElement.removeClass("save-description-btn");
      buttonContainer.qs(".edit-description-btn").removeClass("hidden");
      buttonContainer.qs(".stop-editing").removeClass("stop-editing-btn");
      buttonContainer.qs(".stop-editing").addClass("hidden");
      //updating field value in data
      if (field === "description") {
        data[clusterID].descr = newFieldValue;
      } else {
        data[clusterID].name = newFieldValue;
      }
    } else if (eventClass === "stop-editing") {
      if (field === "description") {
        inputElement.value = data[clusterID].descr;
      } else {
        inputElement.value = data[clusterID].name;
      }
      inputElement.disabled = true;
      buttonElement.addClass("hidden");
      buttonElement.removeClass("stop-editing-btn");
      buttonContainer.qs(".edit-description-btn").removeClass("hidden");
      buttonContainer.qs(`.save-${field}`).removeClass("save-description-btn");
      buttonContainer.qs(`.save-${field}`).addClass("hidden");
    }
  };

  /**
   * @method registerPersonaCreationPageListners
   * @description Register listeners for:
   * * model selection
   * * description editing
   * * name editing
   * * saving the persona profile
   * @private
   */
  const registerPersonaCreationPageListners = function () {
    const personaCreationPage = qs(
      "#main-content .content.mp-behavioural-analysis"
    );
    personaCreationPage
      .qs("#modelSelect")
      .addEventListener("change", modelChanged);
    //edit description buttons listners
    personaCreationPage
      .qsa(".description-edit-button-container")
      .forEach((container) => {
        container.addEventListener("click", (evt) =>
          updateInputField(evt, "description")
        );
      });
    //edit name buttons listners
    personaCreationPage
      .qsa(".name-edit-button-container")
      .forEach((container) => {
        container.addEventListener("click", (evt) =>
          updateInputField(evt, "name")
        );
      });
    //listner for save btn
    personaCreationPage.qs(".save-btn").addEventListener("click", () => {
      //call API
      behaviouralAnalysisData.profileSummary = data;
      let result = saveFormData(behaviouralAnalysisData);
      APP.dismissMessage();
      if (result === "failed") {
        APP.showError("There was an error in saving profile summary");
      } else if (result.status == 404) {
        APP.showError(result.data.reason);
      } else {
        APP.showInfo("Profile summary saved");
      }
    });
  };

  /**
   * @method reloadData
   * @description reloads the page.
   */
  const reloadData = async function () {
    data = await loadData({
      projectKey: projectKey,
      projVersion: PROJECT.currentProjVersion(),
      modelName: selectedModelID,
      datatype: "persona",
    });
    data = data.data.posts[0].profileSummary;
    loadForm();
    registerPersonaCreationPageListners();
  };

  /**
   * @method loadForm
   * @description loads the form template on UI.
   */
  const loadForm = function () {
    qs(
      "#main-content .content.mp-behavioural-analysis .persona-creation .persona-creation-form"
    ).innerHTML = personacreationformTemplate({
      data: data,
      clusterIDs: Object.keys(data).sort(),
    });
  };

  /**
   * @method resetVariables
   * @description resets all the variables upon landing the page to avoid caching issues.
   */
  const resetVariables = function () {
    selectedModelID = null;
    data = null;
    projectKey = null;
    behaviouralAnalysisData = null;
  };

  /**
   * @method loadPage
   * @description loads the page for the first time
   */
  my.loadPage = async function (pkey, leaderboardData) {
    resetVariables();
    const behaviouralAnalysisPage = qs(
      "#main-content .content.mp-behavioural-analysis"
    );
    behaviouralAnalysisPage.innerHTML = mpbapersonacreationTemplate();
    behaviouralAnalysisPage.style.overflow = "";
    getModelOptions(leaderboardData);
    projectKey = pkey;
    data = await loadData({
      projectKey: projectKey,
      projVersion: PROJECT.currentProjVersion(),
      modelName: selectedModelID,
      datatype: "persona",
    });
    behaviouralAnalysisData = data.data.posts[0];
    data = data.data.posts[0].profileSummary;
    loadForm();
    registerPersonaCreationPageListners();
  };

  return my;
})(MP_BA_PERSONA_CREATION || {});
