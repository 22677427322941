// Perform polling locally and returns dummy response at end of polling if usingTestData is true,
// else polls actual api.
var POLLING = (function (my) {
  my.pollAPI = function (url, params, message, interval, timeout, showMask) {
    var response = {
      status: "Success",
      projectKey: params.projectKey,
      action: params.action,
    };
    var pollingCounter = 0;
    var pollingCounterStop = 10;

    var endTime = Number(new Date()) + (timeout || 6 * 60 * 60 * 1000);
    interval = interval * 1000 || 10 * 1000;
    my.continuePolling = true;
    // let loadingDialog = createNode(loadingdialogTemplate({ message: message }));
    // APP.showDialog(loadingDialog);
    APP.setPollProgress("Polling for Report...", showMask);
    if (!showMask) {
      qs("#breadcrumb-bar").removeClass("progress-on");
      qs("#main").removeClass("progress-on");
    }
    // Actual polling function
    var pollingFunction = async (resolve, reject) => {
      if (!my.continuePolling) {
        APP.resetPollProgress();
        return reject({ State: "interrupted" });
      }
      let result = null;
      try {
        let url = SERVER.getBaseAddress() + "pollapi";
        result = await SERVER.postData(url, params, showMask);
      } catch (err) {
        console.error("Exception in polling.\n", err);
        // APP.hideDialog(loadingDialog);
        // loadingDialog.remove();
        APP.resetPollProgress();
        APP.showError(
          "Error in retrieving updates from server. Please contact adminitrator."
        );
        return reject(err);
      }
      if (!result || typeof result == "undefined") {
        console.error("Null result in polling.");
        // APP.hideDialog(loadingDialog);
        // loadingDialog.remove();
        APP.resetPollProgress();
        return reject(
          new Error(
            "Error in retrieving updates from server. Please contact adminitrator."
          )
        );
      } else if (Number(new Date()) < endTime) {
        // If the condition isn't met but the timeout hasn't elapsed, go again
        try {
          if (result.status == "Success") {
            // APP.hideDialog(loadingDialog);
            // loadingDialog.remove();
            APP.resetPollProgress();
            return resolve(result);
          } else if (result.status == "Failure") {
            if (result.message) {
              APP.showError(result.message);
            } else {
              APP.showError(
                "Received status failed in polling. Please contact adminitrator."
              );
            }
            APP.resetPollProgress();
            return reject(new Error(result.message));
          }
          // else if (result.status == "InProgress") {
          //   // Do Nothing, let the polling continue.
          // }
          else if (result.status == "InProgress" && !my.continuePolling) {
            APP.resetPollProgress();
            return reject({ State: "interrupted" });
          }
        } catch (err) {
          //do nothing
        }
        if (my.continuePolling) {
          pollTimeoutToken = setTimeout(
            pollingFunction,
            interval,
            resolve,
            reject
          );
        } else {
          my.continuePolling = true;
          // APP.hideDialog(loadingDialog);
          // loadingDialog.remove();
          APP.resetPollProgress();
          return reject({ State: "interrupted" });
        }
      } else {
        // APP.hideDialog(loadingDialog);
        // loadingDialog.remove();
        // Didn't match and too much time, reject!
        APP.resetPollProgress();
        return reject(new Error("timed out"));
      }
    };

    var dummyPollingFuntion = async (resolve, reject) => {
      APP.setPollProgress(message);
      pollingCounter = pollingCounter + 1;
      if (pollingCounter == pollingCounterStop) {
        // APP.hideDialog(loadingDialog);
        // loadingDialog.remove();
        APP.resetPollProgress();
        return resolve(extend({ status: "Success" }, response));
      } else {
        var timeoutToken = setTimeout(
          dummyPollingFuntion,
          interval,
          resolve,
          reject
        );
      }
    };
    return useTestData
      ? new Promise(dummyPollingFuntion)
      : new Promise(pollingFunction);
  };
  return my;
})(POLLING || {});
