var API_HELPER = (function (my) {
  // used by version-comparison.js, ml-leaderboard.js, ae-automodel.js
  my.getResult = async function (endpoint, iparams) {
    let url = SERVER.getBaseAddress() + endpoint;
    let userHash = CREDENTIALS.getUserCreds();
    if (userHash == null) {
      throw new Error(i18n.en.APP.UI.ERROR.MODELCFG.USER_NOT_LOGGED_IN);
    }
    let params = extend(
      {
        key: userHash,
        projectKey: "",
        projVersion: "",
      },
      iparams
    );
    let result = null;
    if (useTestData) {
      let obj = {
        status: 200,
        data: {
          posts: [],
        },
      };
      return obj;
    }
    try {
      result = await SERVER.postData(url, params);
    } catch (err) {
      result = null;
    }
    if (result === "ROUTES_MISMATCHED") {
      return;
    }
    return result;
  };

  // used by ml-leaderboard.js
  my.getProbThresholdResult = async function (endpoint, iparams, state) {
    let url = SERVER.getBaseAddress() + endpoint,
      userHash = CREDENTIALS.getUserCreds(),
      projectKey = PROJECT.currentProjectKey();

    APP.setProgress(`${state} threshold...`);
    if (userHash == null) {
      APP.resetProgress();
      APP.showError(
        i18n.en.APP.UI.ERROR.USER_NOT_LOGGED_IN_CANNOT_RETRIEVE_PROJECTS +
          "\n" +
          i18n.en.APP.UI.ERROR.VALIDATE_FEATURES.GENERIC
      );
      throw new Error(
        i18n.en.APP.UI.ERROR.USER_NOT_LOGGED_IN_CANNOT_RETRIEVE_PROJECTS +
          "\n" +
          i18n.en.APP.UI.ERROR.VALIDATE_FEATURES.GENERIC
      );
    }
    if (projectKey == null) {
      APP.resetProgress();
      APP.showError(
        i18n.en.APP.UI.INFO.VALIDATE_FEATURES.NOT_IN_CONTEXT_OF_PROJECT
      );
      throw new Error(
        i18n.en.APP.UI.INFO.VALIDATE_FEATURES.NOT_IN_CONTEXT_OF_PROJECT
      );
    }

    const params = extend(
      {
        key: userHash,
        projectKey: projectKey,
        projVersion: PROJECT.currentProjVersion(),
      },
      iparams
    );

    let result = null;
    try {
      if (useTestData && state === "Saving") {
        result = await MP_TEST_DATA.setProbThreshold(params);
      } else if (useTestData && state === "Releasing") {
        result = await MP_TEST_DATA.releaseProbThreshold(params);
      } else {
        result = await SERVER.postData(url, params);
      }
    } catch (err) {
      result = null;
    }
    if (result === "ROUTES_MISMATCHED") {
      return;
    }
    if (
      result == null ||
      (result.status != "success" &&
        !(result.status >= 200 && result.status < 300))
    ) {
      APP.resetProgress();
      let msg = null;
      if (result && result.data && result.data.reason) {
        if (state === "Saving") {
          msg = `Could not save probability threshold. Server error: ${result.data.reason}`;
        } else {
          msg = `Could not release probability threshold. Server error: ${result.data.reason}`;
        }
      } else {
        msg =
          i18n.en.APP.UI.ERROR.MODELPERFORMANCE.PROBABILITY_THRESHOLD_GENERIC;
      }
      let err = new Error(msg);
      err.name = "GenericError";
      throw err;
    }
    APP.resetProgress();
    return result;
  };

  // used by security-policy.js, forget-password.js, reset-password.js
  my.getQuestionsForSecurity = async function (endpoint, params, state) {
    let url = SERVER.getBaseAddress() + endpoint;
    let result = null;
    let questionsData = null;
    try {
      if (useTestData) {
        if (state === "security-policy") {
          result = await USER_MANAGEMENT_DATA.getQuetionsData(url, params);
        } else if (state === "forget-password" || state === "reset-password") {
          result = await USER_MANAGEMENT_DATA.getQuetionsDataPasswdReset(
            url,
            params,
            false
          );
        } else if (state === "updateQues") {
          result = await USER_MANAGEMENT_DATA.updateSecurityQuestions(
            url,
            params
          );
        }
      } else {
        result = await SERVER.postData(url, params);
      }
      APP.resetProgress();
    } catch (err) {
      result = null;
      APP.resetProgress();
    }
    if (result === "ROUTES_MISMATCHED") {
      return;
    }
    try {
      if (
        result != null &&
        (result.status == 200 || (result.status >= 200 && result.status < 300))
      ) {
        if (state === "updateQues") {
          APP.showInfo("Answers saved successfully.");
          questionsData = result;
        } else {
          questionsData = result.data.posts[0];
        }
      }
    } catch (err) {
      if (state === "updateQues") {
        APP.Error("Error in saving answers to server.");
        throw new Error("Error in saving answers to server.");
      } else {
        throw new Error("Error in getting questions from server.");
      }
    }
    if (questionsData == null && state !== "updateQues") {
      throw new Error("Error in getting questions from server.");
    }
    return questionsData;
  };

  // used by reset-password.js, forget-password.js
  my.savePasswordToServer = async function (endpoint, params) {
    let url = SERVER.getBaseAddress() + endpoint;
    try {
      if (useTestData) {
        result = await USER_MANAGEMENT_DATA.resetPassword(url, params, false);
      } else {
        result = await SERVER.postData(url, params);
      }
      APP.resetProgress();
    } catch (err) {
      result = null;
      APP.resetProgress();
    }
    if (result === "ROUTES_MISMATCHED") {
      return;
    }
    try {
      if (
        result != null &&
        (result.status == 200 || (result.status >= 200 && result.status < 300))
      ) {
        APP.showInfo("New Password saved successfully.");
        // saved successfully.
      }
    } catch (err) {
      APP.Error("Error in saving new password to server.");
      throw new Error("Error in saving answers to server.");
    }
    return result;
  };

  // used by app-data.js
  my.getAndResetExpcfg = async function (endpoint, state) {
    let url = SERVER.getBaseAddress() + endpoint;
    let userHash = CREDENTIALS.getUserCreds();
    if (state === "reset") {
      APP.setProgress(
        i18n.en.APP.UI.FOOTER.PROGRESS.RESET_EXPERT_EXPERIMENTATION_DATA
      );
    } else if (state === "get") {
      APP.setProgress(
        i18n.en.APP.UI.FOOTER.PROGRESS.GET_EXPERT_EXPERIMENTATION_DATA
      );
    }
    if (userHash == null) {
      if (state === "reset") {
        throw new Error(
          i18n.en.APP.UI.ERROR.USER_NOT_LOGGED_IN_CANNOT_RESET_EXPERT_EXPERIMENTATION_DATA
        );
      } else if (state === "get") {
        throw new Error(
          i18n.en.APP.UI.ERROR.USER_NOT_LOGGED_IN_CANNOT_GET_EXPERT_EXPERIMENTATION_DATA
        );
      }
    }
    let params = {
      key: userHash,
      projectKey: PROJECT.currentProjectKey(),
      projVersion: PROJECT.currentProjVersion(),
    };

    var result = null;
    var ModelConfigData = null;
    try {
      if (useTestData) {
        result = await MODEL_CONFIGURATION.getData(url, params);
      } else {
        result = await SERVER.postData(url, params);
      }
      APP.resetProgress();
    } catch (err) {
      result = null;
      APP.resetProgress();
    }
    if (result === "ROUTES_MISMATCHED") {
      return;
    }
    try {
      if (result != null) {
        if (
          result.status === "success" ||
          (result.status >= 200 && result.status < 300)
        ) {
          ModelConfigData = result.data;
        }
      }
    } catch (err) {
      if (state === "reset") {
        throw new Error(
          i18n.en.APP.UI.ERROR.EXPERTEXP.RESET_EXPERT_EXPERIMENTATION_DATA
        );
      } else if (state === "get") {
        throw new Error(
          i18n.en.APP.UI.ERROR.EXPERTEXP.GET_EXPERT_EXPERIMENTATION_DATA
        );
      }
    }
    if (ModelConfigData == null) {
      if (state === "reset") {
        throw new Error(
          i18n.en.APP.UI.ERROR.EXPERTEXP.RESET_EXPERT_EXPERIMENTATION_DATA
        );
      } else if (state === "get") {
        throw new Error(
          i18n.en.APP.UI.ERROR.EXPERTEXP.GET_EXPERT_EXPERIMENTATION_DATA
        );
      }
    }
    return ModelConfigData;
  };

  my.storeError = async function (payload) {
    console.log(payload);
    let url = SERVER.getErrorApiUrl();
    let userHash = CREDENTIALS.getUserCreds();
    console.log(url, payload);
    let result = null;
    if (useTestData) {
      let obj = {
        status: 200,
        data: {
          posts: [],
        },
      };
      return obj;
    }
    try {
      result = await SERVER.postData(url, payload);
    } catch (err) {
      result = null;
    }
    if (result === "ROUTES_MISMATCHED") {
      return;
    }
    return result;
  };

  return my;
})(API_HELPER || {});
