/* global projectdeleteconfirmationdlgTemplate, createprojectTemplate, PROJECTS_DATA, EMPTY_CUSTOMIZER */
/**
 * @module PROJECT
 * @description An object to handle the representation of a project in the application. Handles project
 * creation dialog and action and keeps track of the currently active project context.
 */
// eslint-disable-next-line no-redeclare
var PROJECT = (function (my) {
  my.createTriggeredFromVersion = false;
  my.deleteTriggeredFromVersion = false;
  const checkboxList = [
    {
      id: "checkbox-1",
      name: "Import data",
    },
    {
      id: "checkbox-2",
      name: "Import use case configuration",
    },
    {
      id: "checkbox-3",
      name: "Import data treatment",
    },
    {
      id: "checkbox-4",
      name: "Import feature selection",
    },
  ];
  const txtMClassCheckboxList = [
    {
      id: "checkbox-1",
      name: "Import data",
    },
    {
      id: "checkbox-2",
      name: "Import use case configuration",
    },
    {
      id: "checkbox-3",
      name: "Import data treatment",
    },
  ];
  /**
   * @member {string} _currentProjectKey
   * @description a project key that represents the currently active project context.
   * @private
   */
  var _currentProjectKey = null;

  // varaible that represents the currently active project version
  var _currentProjVersion = null;

  my.currentProjVersion = function () {
    return _currentProjVersion;
  };

  /**
   * @method currentProjectKey
   * @description current application project context
   * @returns {string} project ID of the currently active project context
   * @public
   */
  my.currentProjectKey = function () {
    if ((_currentProjectKey == null && !APP.isDebug()) || STORE.here === "/") {
      return null;
    } else if (_currentProjectKey == null && APP.isDebug() && useTestData) {
      return PROJECTS_DATA.testProjectKey();
    }
    return _currentProjectKey;
  };

  /**
   * @method getProject
   * @description return the project object for the specified project key
   * @param {pkey} pkey
   * @private
   */
  const getProject = function (pkey, projVersion) {
    if (empty(pkey) || pkey == "BLANK") return false;
    if (empty(STORE.projects)) return false;
    let project = STORE.projects.find((p) => p.projectkey === pkey);
    let version;
    if (project.versionInfo) {
      version = project.versionInfo.find((v) => v.vname === projVersion);
    }
    if (!projVersion || !version) {
      return project;
    }
    if (empty(version)) return false;
    return version;
  };

  /**
   * @method currentProject
   * @description returns the currently active project from the STORE's projects object.
   * @public
   */
  my.currentProject = function () {
    let pkey = null;
    let projVersion = null;
    try {
      pkey = my.currentProjectKey();
      projVersion = my.currentProjVersion();
    } catch (err) {
      return false;
    }
    let project;
    if (!projVersion) {
      project = getProject(pkey);
    } else {
      project = getProject(pkey, projVersion);
    }
    if (project) {
      // if(project.projectkey == "24QQC7OW9ICT") {
      //   project.ptype = "anomaly"
      // }
      return JSON.parse(JSON.stringify(project));
    }
    return false;
  };

  /**
   * @method setCurrentProjectKey
   * @description Sets the currently active project key
   * @param {string} key project key
   * @public
   */
  my.setCurrentProjectKey = function (key) {
    if (key) {
      _currentProjectKey = key;
    }
  };

  // set Project Version
  my.setCurrentProjVersion = function (version) {
    if (version) {
      if (version == "BLANK") {
        _currentProjVersion = null;
        return;
      }
      _currentProjVersion = version;
    }
  };

  /**
   * @method currentProjectCustomizer
   * @description get the customizer instance that will tailor the UI for the current project type.
   * @public
   */
  my.currentProjectCustomizer = function () {
    let pkey = null,
      project = null;
    try {
      pkey = my.currentProjectKey();
      version = my.currentProjVersion();
      project = getProject(pkey, version);
    } catch (err) {
      //do nothing
    }
    if (!project) {
      return EMPTY_CUSTOMIZER;
    }
    return APP.getCustomizer(project.ptype);
  };

  const registerEventListenerOnImportToggle = function () {
    qs(".toggle-and-versions-box .toggle-container input").addEventListener(
      "change",
      (evt) => {
        if (evt.target.checked) {
          qs(
            ".toggle-and-versions-box .versions-checkbox-container"
          ).removeClass("hidden");
        } else {
          qs(".toggle-and-versions-box .versions-checkbox-container").addClass(
            "hidden"
          );
        }
      }
    );
  };

  const registerEventListenerOnImportCheckboxes = function () {
    const versionsCheckboxContainer = qs(".versions-checkbox-container");
    versionsCheckboxContainer
      .qsa(".checkbox-container .checkbox-input")
      .forEach((checkboxInput) => {
        checkboxInput.addEventListener("change", (evt) => {
          const checkboxId = Number(
            evt.target.getAttribute("id").split("-")[1]
          );
          if (evt.target.checked) {
            for (let id = 1; id < checkboxId; id++) {
              versionsCheckboxContainer.qs(
                `.checkbox-container .checkbox-input#checkbox-${id}`
              ).checked = true;
            }
          } else {
            for (let id = checkboxId + 1; id <= 4; id++) {
              versionsCheckboxContainer.qs(
                `.checkbox-container .checkbox-input#checkbox-${id}`
              ).checked = false;
            }
          }
        });
      });
  };

  /**
   * @method showCreateProjectDialog
   * @description show a dialog that gets the paraemeters for creating a new project on the server
   * @returns {Promise} returns a Promise that resolves to a set of parameters required for creating
   * a new project on the server. Rejects with "cancelled" if closed by user
   * @property {string} pname Project Name
   * @property {string} description Description of the project
   * @property {string} ptype Project Type: Classification, Regression, Estimation, Optimization
   * @property {string} platform Platform: In-house, CI Cloud
   * @async
   * @public
   */
  my.showCreateProjectDialog = async function () {
    return new Promise((resolve, reject) => {
      const projectConfig = APP.getProperty("common.project.new");
      // var dlg = createNode(createprojectTemplate(projectConfig));
      const selectedProjectkey = DASHBOARD.selectedProjectkey;
      const project = DASHBOARD.projects.find(
        (p) => p.projectkey == selectedProjectkey
      );
      const projectType = project ? project.ptype : null;
      var dlg = createNode(
        createprojectTemplate({
          projectConfig: projectConfig,
          fromVersion: my.createTriggeredFromVersion,
          projects: DASHBOARD.projects,
          projectKey: selectedProjectkey,
          checkboxList:
            projectType === "txt-mclass" ? txtMClassCheckboxList : checkboxList,
        })
      );
      qs("#dialogs-sleeping").appendChild(dlg);
      // APP.registerCompactLabel(dlg.qsa('label.compact'));
      APP.showDialog(dlg);
      [].forEach.call(
        dlg.qsa("button.close, #cancel-button"),
        function (button) {
          button.addEventListener("click", () => {
            APP.hideDialog(dlg);
            dlg.remove();
            reject("cancelled");
          });
        }
      );
      dlg.qs("#input-pname").focus();
      dlg.qs("#create-button").addEventListener("click", () => {
        var pname = dlg.qs("#input-pname").value;
        var description = dlg.qs("#textarea-description").value;
        var ptype = dlg.qs("#select-ptype").value;
        var platform = dlg.qs("#select-platform").value;
        if (my.createTriggeredFromVersion) {
          var baseVersion = null;
          if (
            dlg.qs(".toggle-and-versions-box .toggle-container input") &&
            dlg.qs(".toggle-and-versions-box .toggle-container input").checked
          ) {
            baseVersion = dlg.qs("#select-version")
              ? dlg.qs("#select-version").value
              : null;
          }
          var skipDataCfg = dlg.qs("#checkbox-1")
            ? dlg.qs("#checkbox-1").checked
            : false;
          var skipModelCfg = dlg.qs("#checkbox-2")
            ? dlg.qs("#checkbox-2").checked
            : false;
          var skipDt = dlg.qs("#checkbox-3")
            ? dlg.qs("#checkbox-3").checked
            : false;
          var skipFs = dlg.qs("#checkbox-4")
            ? dlg.qs("#checkbox-4").checked
            : false;
          var retrainType = baseVersion ? "customized" : "full";
        }
        APP.hideDialog(dlg);
        dlg.remove();
        if (my.createTriggeredFromVersion) {
          resolve({
            pname,
            description,
            ptype,
            platform,
            baseVersion,
            skipDataCfg,
            skipModelCfg,
            skipDt,
            skipFs,
            retrainType,
          });
        } else {
          resolve({
            pname,
            description,
            ptype,
            platform,
          });
        }
      });
      if (my.createTriggeredFromVersion) {
        registerEventListenerOnImportToggle();
        registerEventListenerOnImportCheckboxes();
      }
    });
  };

  /**
   * @method showDeleteProjectConfirmationDialog
   * @description show a confirmation dialog to delete a project
   * @returns {Promise} returns a Promise that resolves to a set of parameters (projectkey and userhash)
   * required to delete a project on the server. Rejects with "cancelled" if closed by user
   * @property {string} projectKey Project ID
   * @param {string} pkey Project ID of the project that needs deleting. This is returned upon confirmation
   * @async
   * @public
   */
  my.showDeleteProjectConfirmationDialog = async function (pkey, projVersion) {
    return new Promise((resolve, reject) => {
      var dlg = createNode(
        projectdeleteconfirmationdlgTemplate({
          pkey: pkey,
          projVersion: projVersion,
          fromVersion: my.deleteTriggeredFromVersion,
          projects: DASHBOARD.projects,
        })
      );
      qs("#dialogs-sleeping").appendChild(dlg);
      APP.showDialog(dlg);
      [].forEach.call(
        dlg.qsa("button.close, #cancel-button"),
        function (button) {
          button.addEventListener("click", () => {
            APP.hideDialog(dlg);
            dlg.remove();
            reject("cancelled");
          });
        }
      );
      dlg.qs("#delete-button").addEventListener("click", () => {
        APP.hideDialog(dlg);
        dlg.remove();
        resolve({ projectKey: pkey, projVersion: projVersion });
      });
    });
  };

  /**
   * @method createProject
   * @description Called fromt the router to collect the project creation params from the user and
   * create a new project on the API server
   * @public
   */
  my.createProject = async function () {
    let params = null;
    try {
      params = await my.showCreateProjectDialog();
    } catch (err) {
      if ("cancelled" == err) {
        setTimeout(
          APP.showWarning,
          200,
          i18n.en.APP.UI.WARNING.PROJECT_CREATION.CANCELLED
        );
        APP.router.navigate("/");
        return;
      }
    }
    if (!params) {
      APP.showError(i18n.en.APP.UI.ERROR.PROJECT_CREATION.GENERIC);
      APP.router.navigate("/");
      return;
    }
    try {
      var result = await createProjectAction(params);
      if (
        result.status == "success" ||
        (result.status >= 200 && result.status < 300)
      ) {
        setTimeout(
          APP.showInfo,
          200,
          i18n.en.APP.UI.INFO.PROJECT_CREATION.GENERIC_SUCCESS
        );
        APP.resetProgress();
        let project = STORE.projects.find(
          (p) => p.projectkey === result.data.posts[0].projectKey
        );
        const versionState = result.state ? result.state : 2;
        if (!project) {
          STORE.projects.push({
            projectkey: result.data.posts[0].projectKey,
            projVersion: result.data.posts[0].projVersion,
            createdOn: new Date(),
            pname: params.pname,
            platform: params.platform,
            ptype: params.ptype,
            state: versionState,
          });
        } else {
          project.versionInfo.push({
            vname: result.data.posts[0].projVersion,
            createdOn: new Date(),
            pname: params.pname,
            platform: params.platform,
            ptype: params.ptype,
            state: versionState,
          });
        }
        const route = getRoute(versionState);
        //load project level configuration for this project and then load the db connection screen
        APP.clearProjectConfigs(result.data.posts[0].projectKey);
        APP.loadProjectProperty({
          projectKey: result.data.posts[0].projectKey,
          propKey: "project",
          projVersion: result.data.posts[0].projVersion,
        }).then(function () {
          APP.router.navigate(route);
        });
        return;
      }
    } catch (err) {
      setTimeout(APP.showError, 200, err.message);
    }
    APP.resetProgress();
    APP.router.navigate("/");
  };

  /**
   * @method getRoute
   * @description returns the route based on state
   */
  const getRoute = (state) => {
    if (state == 2) {
      return "/db-connection";
    } else if (state == 3) {
      return "/workflow-start";
    } else if (state == 4) {
      return "/ae-automodel";
    } else if (state == 5 || state == 6) {
      return "/mp/model-comparison";
    } else {
      return "/";
    }
  };

  /**
   * @method createProjectAction
   * @description Create a new project on the server using the `project/new` API
   * @param {object} iparams Input parameters for creating a project as obtained from the project creation dialog
   * @property {string} pname Project Name
   * @property {string} description Description of the project
   * @property {string} ptype Project Type: Classification, Regression, Estimation, Optimization
   * @property {string} platform Platform: In-house, CI Cloud
   */
  var createProjectAction = async function (iparams) {
    let url = SERVER.getBaseAddress() + "project/new";
    let userHash = CREDENTIALS.getUserCreds();
    APP.setProgress(i18n.en.APP.UI.FOOTER.PROGRESS.CREATE_PROJECT);
    if (userHash == null) {
      throw new Error(i18n.en.APP.UI.ERROR.PROJECT_CREATION.USER_NOT_LOGGED_IN);
    }
    let params = extend(
      {
        key: userHash,
        pname: "",
        description: "",
        ptype: "",
        platform: "",
        NoModels: 0,
      },
      iparams
    );
    if (my.createTriggeredFromVersion) {
      params = extend(
        {
          projectkey: DASHBOARD.selectedProjectkey,
        },
        params
      );
    }
    let keys = [];
    Object.keys(params).forEach((k) => {
      if ("" === params[k]) {
        keys.push(k);
      }
    });
    if (keys.length > 0) {
      let err = new Error(
        sprintf(
          i18n.en.APP.UI.ERROR.PROJECT_CREATION.EMPTY_INPUT,
          keys.join(", ")
        )
      );
      err.name = "InputError";
      throw err;
    }
    let result = null;
    try {
      if (useTestData) {
        result = await PROJECTS_DATA.create(url, params);
      } else {
        result = await SERVER.postData(url, params);
      }
    } catch (err) {
      result = { status: 498, data: { reason: err.message } };
    }
    if (result === "ROUTES_MISMATCHED") {
      return;
    }
    if (
      result == null ||
      (result.status != "success" &&
        (result.status < 200 || result.status >= 300))
    ) {
      let reason = "";
      if (!empty(result) && !empty(result.data) && !empty(result.data.reason)) {
        reason = result.data.reason;
      }
      let err = new Error(
        `${i18n.en.APP.UI.ERROR.PROJECT_CREATION.GENERIC} ${reason}`
      );
      err.name = "ProjectCreationError";
      throw err;
    }
    my.setCurrentProjectKey(result.data.posts[0]["projectKey"]);
    my.setCurrentProjVersion(result.data.posts[0]["projVersion"]);
    return result;
  };

  /**
   * @method deleteProjectAction
   * @description Delete an existing project on the server using the `project/delete` API
   * @param {object} iparams Input parameters for creating a project as obtained from the project creation dialog
   * @property {string} projectKey Project key for the project to be deleted
   * @async
   * @return {object} the JSON decoded version of server response
   * @throws {Error} Throws a GenericError error with error message.
   * @public
   */
  my.deleteProjectAction = async function (iparams) {
    let url = SERVER.getBaseAddress() + "project/delete";
    let userHash = CREDENTIALS.getUserCreds();
    APP.setProgress(i18n.en.APP.UI.FOOTER.PROGRESS.DELETE_PROJECT);
    if (userHash == null) {
      throw new Error(i18n.en.APP.UI.ERROR.PROJECT_CREATION.USER_NOT_LOGGED_IN);
    }
    let params = extend(
      {
        key: userHash,
        projectKey: "",
        projVersion: "",
      },
      iparams
    );
    let keys = [];
    Object.keys(params).forEach((k) => {
      if ("" === params[k]) {
        keys.push(k);
      }
    });
    if (keys.length > 0) {
      let err = new Error(
        `${i18n.en.APP.UI.ERROR.PROJECT_DELETION.NO_PKEY}:\n${keys.join(", ")}`
      );
      err.name = "InputError";
      throw err;
    }
    let result = null;
    try {
      if (useTestData) {
        result = await PROJECTS_DATA.delete(url, params);
      } else {
        result = await SERVER.postData(url, params);
      }
    } catch (err) {
      result = null;
      const er = new Error(i18n.en.APP.UI.ERROR.PROJECT_DELETION.GENERIC);
      er.name = "GenericError";
      throw er;
    }
    if (result === "ROUTES_MISMATCHED") {
      return;
    }
    if (
      result == null ||
      (result.status != "success" && result.status != 200)
    ) {
      let msg =
        result && result.data && result.data.reason
          ? result.data.reason
          : i18n.en.APP.UI.ERROR.PROJECT_DELETION.GENERIC;
      let err = new Error(msg);
      err.name = "GenericError";
      throw err;
    }
    return result;
  };

  /**
   * @method cleanUp
   * @description unset the active project to "BLANK"
   */
  my.cleanUp = function () {
    my.setCurrentProjectKey("BLANK");
    my.setCurrentProjVersion("BLANK");
  };

  return my;
})(PROJECT || {});
