/* global FILE_UPLOAD_DATA, isDnDAvailable*/
/**
 * @module FILE_UPLOAD
 * @description Create the file upload dialog from the Pug template. Handles the Drag and drop
 * interaction and also the API calls for the new connection. Also uploads the file to the server, shows
 * progress etc.
 */
var FILE_UPLOAD = (function (my) {
  /**
   * @member STORE_KEY the store key used for cached data storage in the STORE
   * @public
   */
  my.STORE_KEY = "FILE_UPLOAD";
  my.counter = 0;
  var flag = true;
  var localFileUpload = true;
  const supportedFileTypes = [".csv"];
  var accessType = null;

  /**
   * @method showfileUploadDialog
   * @description Shows the database connection dialog. Obtains parameters for the File upload and returns them as a promise.
   * @param {object} iparams config object for setting up the file upload function.
   * @property {string} iparams.pkey project key
   * @property {string} iparams.destinationOnSuccess URL after the hash to redirect after success
   * @property {string} iparams.destinationOnError URL after the hash to redirect to on error
   * @property {string} iparams.destinationOnCancel URL after the hash to redirect to on cancel. Can also be the string "back" in which
   * case the UI will navigate back to the previous screen
   * @property {string} iparams.sourceType "development" for training. this is the data source type.
   * @returns {Promise} A promise that resolves to parameters for a File upload else rejected with the string "cancelled".
   * @async
   * @public
   */
  var localOrRemoteListener = null;
  my.showUploadDialog = async function (iparams) {
    return new Promise((resolve, reject) => {
      var dlg = qs("#file-upload-dialog");
      if (!dlg || typeof dlg == "undefined") {
        let fileConfig = APP.getProperty("common.datacfg.filecfg");
        dlg = createNode(fileuploadTemplate(fileConfig));
        qs("#dialogs-sleeping").appendChild(dlg);
      }
      setUpDnD();
      dlg.qs("#remote-secret-key").style.display = "none";
      dlg.qs("#remote-access-key").style.display = "none";
      dlg.qs("#remote-auth-token").style.display = "none";
      dlg
        .qs("#select-remote-file-storage-type")
        .addEventListener("change", (event) => {
          if (
            event.target.value == "s3" ||
            event.target.value == "Azure" ||
            event.target.value == "bigquery" ||
            event.target.value == "hdfs"
          ) {
            dlg.qs("#remote-secret-key").style.display = "grid";
            dlg.qs("#remote-access-key").style.display = "grid";
            dlg.qs("#remote-auth-token").style.display = "grid";
          } else {
            dlg.qs("#remote-secret-key").style.display = "none";
            dlg.qs("#remote-access-key").style.display = "none";
            dlg.qs("#remote-auth-token").style.display = "none";
          }
        });
      if (iparams.sourceType == "development") {
        dlg.qs("#file-dev-or-prod").checked = false;
      } else {
        dlg.qs("#file-dev-or-prod").checked = true;
      }
      // eslint-disable-next-line no-unused-vars
      dlg.qs("button.close").addEventListener("click", (evt) => {
        //dialog close button
        APP.hideDialog(dlg);
        dlg.remove();
        if (iparams.destinationOnCancel != "back") {
          APP.router.navigate(iparams.destinationOnCancel);
        }
        reject("cancelled");
      });

      COMPACT_LABEL.registerCompactLabel(dlg.qs("#loc-on-server"));

      // eslint-disable-next-line no-unused-vars
      let changeListener = function (evt) {
        //re-disable next button and remove test button's overlays
        CONNECTION_DIALOG.setDlgButtonStatus("untested", "file");
      };
      dlg
        .qsa(
          "#local-or-remote, #input-file-connection-name, #input-remote-file-connection-name, #input-loc-on-server"
        )
        .forEach(function (x) {
          x.addEventListener("change", changeListener);
          x.addEventListener("keydown", changeListener);
        });

      // eslint-disable-next-line no-unused-vars
      localOrRemoteListener =
        localOrRemoteListener ||
        function (evt) {
          qsa(
            "#file-upload-dialog .remote-upload, #file-upload-dialog .local-upload"
          ).forEach((section) => section.toggleClass("hidden"));
        };
      dlg
        .qs("#local-or-remote")
        .removeEventListener("change", localOrRemoteListener);
      dlg
        .qs("#local-or-remote")
        .addEventListener("change", localOrRemoteListener);

      let clickListener = function (evt) {
        //test/next buttons clicked.
        let allValid = true;
        dlg
          .qsa(
            "#input-file-connection-name, #input-remote-file-connection-name, #input-loc-on-server"
          )
          .forEach(function (x) {
            if (x.hasClass("invalid")) {
              allValid = false;
            }
          });
        if (!allValid) {
          CONNECTION_DIALOG.setDlgButtonStatus("fail", "file");
          return;
        }
        var params = {
          testOrFinal:
            evt.currentTarget.id == "file-test-button" ? "test" : "final",
          connectionType: dlg.qs("#local-or-remote").checked
            ? "remote"
            : "host",
          datatype: dlg.qs("#file-dev-or-prod").checked
            ? "OUT_TIME"
            : "IN_TIME",
        };
        if (params.connectionType == "host") {
          params = extend(params, {
            conname: dlg.qs("#input-file-connection-name").value,
            host: {
              filePath: dlg.qs("#input-loc-on-server").value,
              sourcetype: dlg.qs("#file-dev-or-prod").checked,
            },
          });
          if (dlg.qs("#input-loc-on-server").value == "") {
            localFileUpload = false;
            APP.showError("Missing Input: Enter a valid file path");
          } else {
            localFileUpload = true;
          }
        } else if (params.connectionType == "remote") {
          params = extend(params, {
            conname: dlg.qs("#input-remote-file-connection-name").value,
            remote: {
              storageType: dlg.qs("#select-remote-file-storage-type").value,
              url: dlg.qs("#input-remote-path").value,
              SecretKey: dlg.qs("#input-remote-secret-key").value,
              AccessKey: dlg.qs("#input-remote-access-key").value,
              Authtoken: dlg.qs("#input-remote-auth-token").value,
            },
          });
          if (
            (dlg.qs("#input-remote-secret-key").value != "" &&
              dlg.qs("#input-remote-access-key").value == "") ||
            (dlg.qs("#input-remote-secret-key").value == "" &&
              dlg.qs("#input-remote-access-key").value != "")
          ) {
            flag = false;
            APP.showError(
              "Missing Input: Enter both access key and secret key"
            );
          } else {
            flag = true;
          }
        }
        //need to remove the listeners on buttons and input/select controls because the resolve in the event listener is for to this Promise instance.
        //it will be a new promise instance when the function is called again once the test goes through.
        dlg.qs("#file-test-button").removeEventListener("click", clickListener);
        dlg.qs("#file-next-button").removeEventListener("click", clickListener);
        dlg
          .qsa(
            "#local-or-remote, #input-file-connection-name, #input-remote-file-connection-name, #input-loc-on-server"
          )
          .forEach(function (x) {
            x.removeEventListener("change", changeListener);
            x.removeEventListener("keydown", changeListener);
          });
        var form = dlg.qs("#drop-area");
        form.removeEventListener("dragover", dragon);
        form.removeEventListener("dragenter", dragon);
        form.removeEventListener("dragleave", dragoff);
        form.removeEventListener("drop", dragoff);
        form.removeEventListener("drop", dropfile);
        resolve(params);
      };
      dlg.qs("#file-test-button").addEventListener("click", clickListener);
      dlg.qs("#file-next-button").addEventListener("click", clickListener);
      if (!dlg.open) APP.showDialog(dlg);
      if (!empty(dlg.qsa(".close:focus"))) {
        dlg.qs("#input-file-connection-name").focus();
      }
      accessType = readCookie("accessType");
      handleButtonDisabling();
    });
  };

  const handleButtonDisabling = function () {
    let testButton = qs("#file-upload-dialog #file-test-button");
    if (accessType == "view") {
      testButton.disabled = true;
      testButton.setAttribute("tooltip", "Permission Denied");
      testButton.setAttribute("flow", "middle-top");
    }
    return;
  };

  /**
   * @method setUpDnD
   * @description set up the drag and drop functionality on the file upload dialog. The relevant
   * drag listeners are added and the dropped file is uploaded to the upload.php target on the webserver
   * @private
   */
  var setUpDnD = function () {
    var dlg = qs("#file-upload-dialog");
    if (!isDnDAvailable()) {
      dlg.qs("#drop-area").addClass("no-dnd");
      return;
    }
    if (isDnDAvailable()) {
      var form = qs("#drop-area");
      form.addEventListener("dragover", dragon);
      form.addEventListener("dragenter", dragon);
      form.addEventListener("dragleave", dragoff);
      form.addEventListener("drop", dragoff);
      form.addEventListener("drop", dropfile);
    }
    form.qs("#file-upload-input").addEventListener("change", (evt) => {
      try {
        checkFiles(evt.target.files);
      } catch (err) {
        const dlg = qs("#file-upload-dialog");
        dlg
          .qs("#drop-area")
          .addClass("upload-failed")
          .removeClass("upload-successful");
        dlg.qs(".status.fail .server-error").innerText = err.message;
        dlg.qs(".status.fail .resolution").innerText = "";
        APP.showError(err.message);
        return;
      }
      handleFiles(evt.target.files);
    });
  };
  var dragon = function (evt) {
    evt.target.addClass("is-dragover");
    evt.preventDefault();
    evt.stopPropagation();
  };
  var dragoff = function (evt) {
    evt.target.removeClass("is-dragover");
    evt.preventDefault();
    evt.stopPropagation();
  };
  var dropfile = function (evt) {
    evt.target.removeClass("is-dragover");
    const droppedItems = evt.dataTransfer.items;
    const droppedFiles = evt.dataTransfer.files;
    try {
      checkFiles(droppedFiles, droppedItems);
    } catch (err) {
      const dlg = qs("#file-upload-dialog");
      dlg
        .qs("#drop-area")
        .addClass("upload-failed")
        .removeClass("upload-successful");
      dlg.qs(".status.fail .server-error").innerText = err.message;
      dlg.qs(".status.fail .resolution").innerText = "";
      APP.showError(err.message);
      return;
    }
    handleFiles(droppedFiles);
    evt.preventDefault();
    evt.stopPropagation();
  };

  /**
   * Check whether the files dropped on the drop target are supported files
   * @param {File[]} files array of File objects as dropped by the dnd interaction or browsed using the file input
   * @throws Will throw an error if an unsupported file type is uploaded
   */
  const checkFiles = function (files, items) {
    let isError = false,
      errMsg = "",
      isFileTypeSupported = false;

    if (!isError && files.length !== 1) {
      isError = true;
      errMsg = i18n.en.APP.UI.ERROR.FILE_UPLOAD.TOO_MANY_FILES;
    }

    if (!isError) {
      // handle Chrome
      if (items) {
        if (typeof items[0].webkitGetAsEntry == "function") {
          isError = !items[0].webkitGetAsEntry().isFile;
        } else if (typeof items[0].getAsEntry == "function") {
          isError = !items[0].getAsEntry().isFile;
        }
      }
      if (isError) {
        errMsg = i18n.en.APP.UI.ERROR.FILE_UPLOAD.CANNOT_UPLOAD_FOLDERS;
      }
    }

    if (!isError) {
      supportedFileTypes.forEach((x) => {
        isFileTypeSupported = isFileTypeSupported || files[0].name.endsWith(x);
      });
      if (!isFileTypeSupported) {
        isError = true;
        errMsg = sprintf(
          i18n.en.APP.UI.ERROR.FILE_UPLOAD.WRONG_FILE_TYPE,
          supportedFileTypes.join(", ")
        );
      }
    }
    if (isError) {
      throw new Error(errMsg);
    }
  };

  /**
   * @method handleFiles
   * @description call uploadFile on each file in the passed files list
   * @param {File[]} files array of File objects as dropped by the dnd interaction or browsed using the file input
   * dialog
   * @private
   */
  const handleFiles = function (files) {
    const dlg = qs("#file-upload-dialog");
    [...files].forEach(uploadFile);
    dlg.qs("#drop-area").addClass("uploading");
  };

  /**
   * @method uploadFile
   * @description upload the specified file object to upload.php. update the file upload dialog
   * to indicate success or failure. update the file input dialog with the name of the file uploaded, for use
   * with a further call to connection/file/new API
   * @param {File} file the file to be uploaded
   */
  var uploadFile = function (file) {
    var dlg = qs("#file-upload-dialog");
    let url = SERVER.getBaseAddress() + "uploadfile";
    // let url='/upload.php';
    // let formData=new FormData();
    // formData.append('file', file);
    // fetch(url, {
    //   method: 'POST',
    //   body: formData,
    // })
    // .then(response => response.json())
    SERVER.uploadFile(url, file)
      //.then(response => response.json())
      .then(function (result) {
        dlg.qs("#drop-area").removeClass("uploading,is-dragover");
        if (result.OK == 1) {
          dlg
            .qs("#drop-area")
            .addClass("upload-successful")
            .removeClass("upload-failed");
          dlg.qs(".status.success span.name").innerText = result.filename;
          dlg.qs("#input-loc-on-server").value = result.filename;
          dlg.qs("#input-loc-on-server").fireCustomEvent("change");
        } else if (result.OK == 0) {
          dlg
            .qs("#drop-area")
            .addClass("upload-failed")
            .removeClass("upload-successful");
          dlg.qs(".status.fail .server-error").innerText = result.info;
          dlg.qs(".status.fail .resolution").innerText = result.resolution;
        }
      })
      .catch(function (err) {
        if (err.message === "Failed to fetch") {
          APP.showError(
            "Unable to upload file (Network Issue), Please check your internet connection"
          );
        } else {
          APP.showError(err.message);
        }
      });
  };

  /**
   * @method fileUpload
   * @description Create a File connection by showing a File Upload dialog and getting connection
   * parameters from the user. Doesn't dismiss the dialog until the user is done testing.
   * @param {Object} iparams parameters for the File upload connection
   * @param {Number} iparams.key userHash for this server session
   * @param {string} iparams.conname name to assign this connection
   * @param {string} iparams.filePath path to file on server
   * @param {string} iparams.datatype IN_TIME or OUT_TIME
   * @param {boolean} isTest test connection params or actually establish connection
   * @public
   * @async
   */
  my.fileUpload = async function (iparams) {
    let projectKey = null;
    const defaultParams = {
      destinationOnSuccess: "/workflow-start",
      destinationOnError: "/",
      destinationOnCancel: "back",
      sourceType: "development",
    };
    if (typeof iparams == "string") {
      iparams = {
        pkey: iparams,
      };
    }
    iparams = extend(defaultParams, iparams);
    projectKey = iparams.pkey;
    let params = { testOrFinal: "test" };
    while (params.testOrFinal == "test") {
      try {
        params = await my.showUploadDialog(iparams);
        params.projectKey = projectKey
          ? projectKey
          : PROJECT.currentProjectKey();
        let dlg = qs("#file-upload-dialog");
        if (params.testOrFinal == "test") {
          let result = await createFileConnection(params, true);
          if (result == undefined) {
            break;
          }
          if (
            (result != null || result != undefined) &&
            (result.status == "success" ||
              (result.status >= 200 && result.status < 300)) &&
            flag &&
            localFileUpload
          ) {
            CONNECTION_DIALOG.setDlgButtonStatus("success", "file");
          } else if (flag == false) {
            CONNECTION_DIALOG.setDlgButtonStatus("fail", "file");
          } else if (localFileUpload == false) {
            CONNECTION_DIALOG.setDlgButtonStatus("fail", "file");
          } else {
            CONNECTION_DIALOG.setDlgButtonStatus("fail", "file");
            APP.showError(
              `${i18n.en.APP.UI.ERROR.FILE_UPLOAD.GENERIC}: ${result.reason}`
            );
          }
          APP.resetProgress();
          continue;
        }
        APP.hideDialog(dlg);
        dlg.remove();
        let result = await createFileConnection(params, false);
        if (
          (result != null || result != undefined) &&
          (result.status == "success" ||
            (result.status >= 200 && result.status < 300))
        ) {
          APP.resetProgress();
          STORE.setProjectMetadata(
            params.projectKey,
            PROJECT.currentProjVersion(),
            FILE_UPLOAD.STORE_KEY + "_connected",
            true
          );
          if (iparams.destinationOnSuccess != "back") {
            APP.router.navigate(iparams.destinationOnSuccess);
          }
          APP.showInfo("Flat file connection created successfully.");
          return Promise.resolve(params);
        } else if (
          (result != null || result != undefined) &&
          (result.status == 404 ||
            (result.status >= 400 && result.status < 500))
        ) {
          APP.resetProgress();
          if (iparams.destinationOnError != "back") {
            APP.router.navigate(iparams.destinationOnError);
          }
          APP.showError(result.data.reason);
          return Promise.reject(result.data.reason);
        } else {
          APP.resetProgress();
          if (iparams.destinationOnError != "back") {
            APP.router.navigate(iparams.destinationOnError);
          }
          APP.showError(
            i18n.en.APP.UI.ERROR.FILE_UPLOAD.GENERIC + ": " + result.status
          );
          return Promise.reject(
            i18n.en.APP.UI.ERROR.FILE_UPLOAD.GENERIC + ": " + result.status
          );
        }
      } catch (err) {
        APP.resetProgress();
        if ("cancelled" == err) {
          //setTimeout(APP.showWarning, 200, (i18n.en.APP.UI.WARNING.FILE_UPLOAD.CANCELLED));
          if (iparams.destinationOnCancel != "back") {
            APP.router.navigate(iparams.destinationOnCancel);
          }
          return Promise.reject(err);
        } else {
          CONNECTION_DIALOG.setDlgButtonStatus("fail", "file");
          setTimeout(APP.showError, 200, err.message);
          if (params.testOrFinal == "final") {
            APP.router.navigate(iparams.destinationOnError);
          }
        }
      }
    }
    if (!params) {
      APP.showError(i18n.en.APP.UI.ERROR.FILE_UPLOAD.GENERIC);
      if (iparams.destinationOnError) {
        if (iparams.destinationOnError != "back") {
          APP.router.navigate(iparams.destinationOnError);
        }
      } else {
        APP.router.navigate("/");
      }
      return Promise.reject(i18n.en.APP.UI.ERROR.FILE_UPLOAD.GENERIC);
    }
  };

  /**
   * @method createFileConnection
   * @description Makes a server call to create a File upload connection to
   * connection/file/new API
   * @param {Object} iparams parameters for the File upload connection
   * @param {Number} iparams.key userHash for this server session
   * @param {string} iparams.conname name to assign this connection
   * @param {string} iparams.datatype IN_TIME or OUT_TIME
   * @param {string} iparams.projectKey ID of the currently active project
   * @param {string} iparams.connectionType "remote" or "host" depending on whether the file was uploaded from a remote location or from the browser
   * @param {Object} iparams.host A hash containing parameters for local upload of file
   * @param {string} iparams.host.filePath path to file on server
   * @param {string} iparams.host.sourcetype Currently false for everything
   * @param {Object} iparams.remote A hash containing parameters for remote upload of file
   * @param {string} iparams.remote.url ure
   * @param {string} iparams.remote.storageType "local", "s3", "hdfs", "custom"
   * @param {boolean} isTest test connection params or actually establish connection
   * @private
   * @async
   */
  var createFileConnection = async function (iparams, isTest) {
    let url = SERVER.getBaseAddress() + "connection/file/test";
    if (!isTest) {
      url = SERVER.getBaseAddress() + "connection/file/new";
    }
    let userHash = CREDENTIALS.getUserCreds();
    if (isTest) {
      APP.setProgress(i18n.en.APP.UI.FOOTER.PROGRESS.TEST_CONNECTION);
    } else {
      APP.setProgress(i18n.en.APP.UI.FOOTER.PROGRESS.CREATE_CONNECTION);
    }
    if (userHash == null) {
      throw new Error(i18n.en.APP.UI.ERROR.FILE_UPLOAD.GENERIC);
    }
    let params = {
      key: userHash,
      projVersion: PROJECT.currentProjVersion(),
      connectionType: "",
      conname: "",
      datatype: "IN_TIME",
    };
    if (iparams.connectionType !== undefined && iparams.connectionType) {
      params.connectionType = iparams.connectionType;
    }
    if (params.connectionType == "") {
      params.host = "";
      params.remote = "";
    }
    params = extend(params, iparams);
    let keys = [];
    Object.keys(params).forEach((k) => {
      if ("" === params[k]) {
        keys.push(k);
      }
    });
    if (keys.length > 0) {
      let err = new Error(
        `${i18n.en.APP.UI.ERROR.FILE_UPLOAD.EMPTY_INPUT}:\n${keys.join(", ")}`
      );
      err.name = "InputError";
      throw err;
    }
    if (flag == false) {
      let err = new Error(
        `${i18n.en.APP.UI.ERROR.FILE_UPLOAD.EMPTY_INPUT}: Enter both access key and secret key `
      );
      err.name = "Input Error";
      throw err;
    }
    if (localFileUpload == false) {
      let err = new Error(
        `${i18n.en.APP.UI.ERROR.FILE_UPLOAD.EMPTY_INPUT}: Enter a valid file path `
      );
      err.name = "Input Error";
      throw err;
    }
    let result = null;
    try {
      if (useTestData) {
        result = await FILE_UPLOAD_DATA.connect(url, params);
      } else {
        result = await SERVER.postData(url, params);
      }
    } catch (err) {
      result = null;
    }
    if (result === "ROUTES_MISMATCHED") {
      return;
    }
    if (result.status == 404) {
      APP.showError(result.data.reason);
      result = null;
    }
    if (
      result != undefined &&
      (result == null ||
        (result.status != "success" &&
          !(result.status >= 200 && result.status < 300)))
    ) {
      let errmsg = i18n.en.APP.UI.ERROR.FILE_UPLOAD.GENERIC;
      if (result.data && result.data.reason) {
        errmsg = errmsg + `: ${result.data.reason}`;
      }
      let err = new Error(errmsg);
      err.name = "GenericError";
      throw err;
    }
    return result;
  };

  /**
   * @method hideDialog
   * @description hide the file upload dialog by simulating a close button click. Will trigger the
   * callback normally called on clicking the dialog's close button.
   * @public
   */
  my.hideDialog = function () {
    var dlg = qs("#file-upload-dialog");
    if (dlg && !dlg.hidden) {
      APP.hideDialog(dlg);
      dlg.remove();
    }
  };

  return my;
})(FILE_UPLOAD || {});
