/* global MP_MODEL_COMPARISON, mdautocodegeneratorTemplate, MODEL_DEPLOYMENT_TEST_DATA, */
/**
 * @module AUTO_CODE
 * @description Loads and handles the page for the Auto code generator. This page depends on
 * data returned by the comp API and thus doesn't have its own STORE key. It will in fact try
 * to retrieve cached information from the MD_MODEL_COMPARISON page by using its STORE key.
 */
var AUTO_CODE = (function (my) {
  /**
   * @member {string} dataSource "test" or "train"; defaults to "test"
   * @public
   */
  my.dataSource = "test";

  /**
   * @member {object} compData Comparison data from server API call
   * @private
   */
  var compData = null;

  /**
   * @member {String} autodocPath the URL to the autodoc path for this project
   */
  var autodocPath = null;

  const AUTODOC_STORE_KEY = "MD_AUTO_CODE_AUTODOC_STORE_KEY";

  /**
   * @member {object} searchList When a list.js instance is used to add sorting and search capability its
   * instance variable is stored here.
   * @private
   */
  // eslint-disable-next-line no-unused-vars
  var searchList = null;

  /**
   * @method prime
   * @description Primes this view, adds the view's HTML from its JS Pug template into the DOM.
   * Registers listeners.
   * @private
   */
  var prime = function () {
    const autocodeConfig = APP.getProperty(
      "project.config.autocode",
      PROJECT.currentProjectKey()
    );
    qs("#auto-code-generator").innerHTML =
      mdautocodegeneratorTemplate(autocodeConfig);
    updateAutodocLinkStatus();
    registerListeners();
  };

  /**
   * @method registerListeners
   * @description registers change listeners for the tab on the container element, using event delegation.
   * @private
   */
  var registerListeners = function () {
    qs("#auto-code-generator").addEventListener("change", (evt) => {
      if (evt.target.id == "md-sourceSelect") {
        my.dataSource = evt.target.value;
        qs("#auto-code-generator .tableContainer table").setAttribute(
          "data-active-source",
          my.dataSource
        );
      } else if (evt.target.hasClass("tab-input")) {
        qsa(".tab-trigger[name='api-tab-group']:not(.template)").forEach((x) =>
          x.removeClass("selected")
        );
        qs(`#${evt.target.getAttribute("label-id")}`).addClass("selected");
      }
    });
    qs("#auto-code-generator #md-table").addEventListener(
      "click",
      my.selectRow
    );
  };

  /**
   * @method load
   * @description Loads the view. Sets identifying markers to scope CSS. Loads data for the
   * view either from the STORE or by making a server call.
   * @param {string} pkey The project ID in the context of which the view is loaded
   * @async
   * @public
   */
  var load = async function (pkey) {
    APP.resetCurrentPageMarker();
    qs("#main-content").setAttribute("class", "");
    qs("#main-content").addClass("md-auto-code");
    compData = STORE.getProjectData(
      pkey,
      PROJECT.currentProjVersion(),
      MP_MODEL_COMPARISON.STORE_KEY
    );
    if (!compData) {
      compData = await MP_MODEL_COMPARISON.loadData({
        projectKey: pkey,
        projVersion: PROJECT.currentProjVersion(),
      });
      STORE.setProjectData(
        pkey,
        PROJECT.currentProjVersion(),
        MP_MODEL_COMPARISON.STORE_KEY,
        compData
      );
      STORE.setProjectMetadata(
        pkey,
        PROJECT.currentProjVersion(),
        MP_MODEL_COMPARISON.STORE_KEY + "_compData_loaded",
        true
      );
    }
    autodocPath = STORE.getProjectData(
      pkey,
      PROJECT.currentProjVersion(),
      AUTODOC_STORE_KEY
    );
    if (empty(autodocPath)) {
      let autodocPromise = pollForAutoDocumentation(null, 3);
      autodocPromise
        .then(function (result) {
          if (
            result.data &&
            result.data.posts &&
            result.data.posts.length > 0 &&
            result.data.posts[0].dpath
          ) {
            autodocPath =
              SERVER.getBaseAddress() +
              `downloaddoc?projVersion=${PROJECT.currentProjVersion()}&projectKey=${PROJECT.currentProjectKey()}`;
          }
          STORE.setProjectData(
            pkey,
            PROJECT.currentProjVersion(),
            AUTODOC_STORE_KEY,
            autodocPath
          );
          updateAutodocLinkStatus();
        })
        .catch(function (err) {
          APP.showError(err.message);
          autodocPath = null;
          updateAutodocLinkStatus();
        });
    } else {
      updateAutodocLinkStatus();
    }
    prime();
  };

  /**
   * @method show
   * @description load the view's data into the table
   * @async
   * @public
   */
  my.show = async function (pkey) {
    await load(pkey);

    loadModelsTable();
  };

  /**
   * @method loadModelsTable
   * @description load the data into the table and initialize list.js on it
   * @private
   */
  var loadModelsTable = function () {
    let html = "";
    Object.keys(compData).forEach((x) => {
      if (x === "mlops_deploy") {
        return;
      }
      ["test"].forEach((source) => {
        html += addTableRow({
          type: compData[x].platform.toLowerCase(),
          id: x.toLowerCase(),
          name: compData[x].name,
          source: source,
          recommended: compData[x].isPreferred,
          dpath: compData[x].dpath,
        });
      });
    });
    qs("#auto-code-generator .tableContainer table tbody").innerHTML = html;
    my.selectRow(
      qs(
        "#auto-code-generator .tableContainer tbody tr:first-child td:first-child"
      )
    );
    searchList = new List(qs("#md-outer-container .model-list"), {
      valueNames: ["name"],
    });
  };

  /**
   * @method addTableRow
   * @description Clone the template row and load it with data.
   * @param {object} rowData a JS object representing the columns of the table, obtained from the comp API
   * @property {string} type Type of the AI/ML platform
   * @property {string} id LRM, DRF, GBM etc.
   * @property {string} name long full name
   * @property {string} source validation or training
   * @property {number} recommended marker for whether this is the preferred model
   * @property {string} dpath URL to download the model
   * @private
   */
  var addTableRow = function (rowData) {
    let templateRow = qs("#auto-code-generator #md-table tr.template-row");
    if (templateRow) {
      let tr = templateRow.cloneNode(true);
      tr.removeClass("template-row");
      tr.addClass(rowData.source);
      tr.setAttribute("model-ID", rowData.id);
      if (rowData.recommended) {
        tr.addClass("trophy");
      }
      tr.setAttribute("data-source", rowData.source);
      tr.qsa("td").forEach((x) => {
        if (x.hasClass("trophyCol")) return;
        if (x.hasClass("name")) {
          x.setAttribute("data-type", rowData.type);
          x.setAttribute("data-source", rowData.source);
          x.addClass(`type-${rowData.type}`);
        }
        if (x.hasClass("dpath")) {
          if (typeof rowData.dpath !== "undefined" && rowData.dpath) {
            let downloadURL =
              SERVER.getBaseAddress() +
              `downloadmodel?projVersion=${PROJECT.currentProjVersion()}&projectKey=${PROJECT.currentProjectKey()}&modelName=${
                rowData.id
              }`;
            x.qs("span").addEventListener("click", () => {
              window.open(downloadURL);
            });
          }
        } else {
          const value = rowData[x.getAttribute("data-key")];
          if (typeof value === "number" && !Number.isInteger(value)) {
            x.innerText = value.toFixed(3);
          } else {
            x.innerText = value;
          }
        }
      });
      return tr.outerHTML;
    }
    return "";
  };

  var downloadAutoCode = function (modelId) {
    let downloadURL =
      SERVER.getBaseAddress() +
      `downloadmodel?projVersion=${PROJECT.currentProjVersion()}&projectKey=${PROJECT.currentProjectKey()}&modelName=${modelId}`;
    window.open(downloadURL);
  };

  /**
   * @method selectRow
   * @description listener for selection of table row. update the RHS with the code for that model.
   * If the API has not returned any code, use the template code instead.
   * @param {Event} evt Click event for the table row.
   * @public
   */
  my.selectRow = function (evt) {
    let tr = null;
    if (evt instanceof HTMLElement) {
      tr = evt.closest("tr");
    } else {
      tr = evt.target.closest("tr");
    }
    if (!tr) {
      return;
    }
    qsa("#auto-code-generator #md-table tbody tr.selected").forEach((x) =>
      x.removeClass("selected")
    );
    tr.addClass("selected");
    const modelID = tr.getAttribute("model-id");

    // Checking if the element is the download button then triggering download
    if (
      evt.srcElement &&
      evt.srcElement.hasClass("dpath") &&
      evt.srcElement.hasClass("download-auto-code")
    ) {
      downloadAutoCode(modelID);
    }

    qsa(
      "#auto-code-generator #api .tabsTriggersContainer label:not(.template)"
    ).forEach((x) => x.remove());
    qs("#auto-code-generator #api .tabs-content-container")
      .qsa(".tab-input:not(.template), .tab-content:not(.template)")
      .forEach((x) => x.remove());
    if (!compData[modelID.toUpperCase()].api) {
      compData[modelID.toUpperCase()].api = {
        Java: `
import au.com.bytecode.opencsv.CSVReader;
import hex.ModelCategory;
import hex.genmodel.GenModel;
import hex.genmodel.MojoModel;
import hex.genmodel.algos.tree.SharedTreeMojoModel;
import hex.genmodel.algos.glrm.GlrmMojoModel;
import hex.genmodel.easy.EasyPredictModelWrapper;
import hex.genmodel.easy.RowData;
import hex.genmodel.easy.prediction.*;

private void loadModel(String modelName) throws Exception {
  try {
    loadMojo(modelName);
  } catch (IOException e) {
    loadPojo(modelName);
  }
}

private void loadMojo(String modelName) throws IOException {
  GenModel genModel = MojoModel.load(modelName);
  EasyPredictModelWrapper.Config config = new EasyPredictModelWrapper.Config().setModel(genModel).setConvertUnknownCategoricalLevelsToNa(true).setConvertInvalidNumbersToNa(setInvNumNA);

  if (getTreePath)
    config.setEnableLeafAssignment(true);

  if (returnGLRMReconstruct)
    config.setEnableGLRMReconstrut(true);

  model = new EasyPredictModelWrapper(config);

  BinomialModelPrediction p = model.predictBinomial(row);
  if (getTreePath) {
      writeTreePaths(p.leafNodeAssignments, output);
    } else {
      output.write(p.label);
      output.write(",");
      for (int i = 0; i < p.classProbabilities.length; i++) {
        if (i > 0) {
          output.write(",");
        }
        output.write(myDoubleToString(p.classProbabilities[i]));
      }
    }
  }
}

public static void main() {
  loadModel(modelName)
}
        `,
        PMML: `
<!-- Not Supported -->
        `,
        SQL: `
-- Not Supported
        `,
      };
    }
    Object.keys(compData[modelID.toUpperCase()].api).forEach((lang, i) => {
      let label = qs(
        "#auto-code-generator #api .tabsTriggersContainer label.template"
      ).cloneNode();
      let input = qs(
        "#auto-code-generator #api .tabs-content-container .tab-input.template"
      ).cloneNode();
      let content = qs(
        "#auto-code-generator #api .tabs-content-container .tab-content.template"
      ).cloneNode();
      let code = compData[modelID.toUpperCase()].api[lang];
      label.removeClass("template,selected");
      label.innerText = lang;
      label.setAttribute("id", `tab-label-${lang}`);
      label.setAttribute("for", `tab-radio-${lang}`);
      // label.setAttribute("name", `api-tab-group-${lang}`);
      input.removeClass("template");
      input.innerText = code;
      input.setAttribute("id", `tab-radio-${lang}`);
      // input.setAttribute("name", `api-tab-group-${lang}`);
      input.setAttribute("label-id", `tab-label-${lang}`);
      content.removeClass("template");

      let languageMappings = {
        //for syntax highlighting
        java: "java",
        pmml: "xml",
        sql: "sql",
      };
      content.innerHTML = `<pre><code class="${
        languageMappings[lang.toLowerCase()]
      }"></code></pre>`;
      content.qs("code").innerText = code;
      content.setAttribute("id", `tab-content-${lang}`);
      // hljs.highlightBlock(content);
      // content.setAttribute("name", `api-tab-group-${lang}`);
      qs("#auto-code-generator #api .tabsTriggersContainer").append(label);
      qs("#auto-code-generator #api .tabs-content-container").append(input);
      qs("#auto-code-generator #api .tabs-content-container").append(content);
      hljs.highlightBlock(content.qs("code"));
      if (i == 0) {
        label.addClass("selected");
        input.checked = true;
      }
    });
  };

  /**
   * @member {boolean} continuePolling
   * @description Polling stops if this is false at the time of evaluating the exit condition. This is set to `false`
   * by the [cancelPolling](#~cancelPolling) function.
   * @private
   */
  var continuePolling = true;

  /**
   * @member {Number} pollTimeoutToken
   * @description This is the reference that can be used to find the current timeOut that's waiting.
   * @private
   */
  var pollTimeoutToken = null;

  /**
   * @method cancelPolling
   * @description Sets the [continuePolling](#~continuePolling) flag false. Also clears any pending
   * polling timer.
   * @public
   */
  const cancelPolling = function () {
    continuePolling = false;
    if (pollTimeoutToken) {
      clearTimeout(pollTimeoutToken);
    }
  };

  /**
   * @method pollForAutoDocumentation
   * @description Start polling the autodoc api until interrupted by user action or by occurance of success or failure
   * #### HOW THE POLLING CODE WORKS
   * There's a continuePolling flag that is checked each time the timer is restarted.
   * When the polling is first started, it returns a Promise that is resoved when the
   * self referential timer hits an exit condition. An exit condition is hit either when
   * the user navigates away from the page, or when the server call returns a non 198 result status
   * @return {Promise} Returns a promise that is resolved to a completion status. It is
   * rejected in case of any error or elapse of timeout.
   * @param {Number} timeout If the timer loop is never interrupted it should be stopped in
   * this much time in milliseconds
   * @param {Number} interval Loop every interval seconds.
   * @public
   */
  const pollForAutoDocumentation = function (timeout, interval) {
    var endTime = Number(new Date()) + (timeout || 6 * 60 * 60 * 1000);
    interval = interval * 1000 || 10 * 1000;
    continuePolling = true;

    var checkCondition = async function (resolve, reject) {
      pollTimeoutToken = null;
      // If the condition is met, we're done!
      try {
        APP.setProgress("Polling for autodoc...", false);
        var result = await loadAutoDoc();
        APP.resetProgress();
      } catch (err) {
        console.error("Exception in polling.\n", err);
        APP.showError(i18n.en.APP.UI.ERROR.MODELDEPLOYMENT.POLLING_FAILURE);
        return reject(err);
      }
      if (empty(result) || typeof result == "undefined") {
        console.error("Null result in polling.");
        return reject(
          new Error(i18n.en.APP.UI.ERROR.MODELDEPLOYMENT.POLLING_FAILURE)
        );
      }
      if (
        !empty(result) &&
        !empty(result.status) &&
        result.status >= 200 &&
        result.status < 300
      ) {
        return resolve(result);
      }
      // If the condition isn't met but the timeout hasn't elapsed, go again
      else if (Number(new Date()) < endTime) {
        if (continuePolling) {
          pollTimeoutToken = setTimeout(
            checkCondition,
            interval,
            resolve,
            reject
          );
        } else {
          continuePolling = true;
          return reject({ State: "interrupted" });
        }
      }
      // Didn't match and too much time, reject!
      else {
        return reject(
          new Error(
            "timed out for " + pollForAutoDocumentation + ": " + arguments
          )
        );
      }
    };

    return new Promise(checkCondition);
  };

  /**
   * @method loadData
   * @description Loads the data from the plot API on the server and returns it
   * @param {object} iparams userHash(optional) and projectKey(optional)
   * @property {string} iparams.projectKey id of the project for which to get scores
   * @return {object} Raw graph data obtained from the plot API.
   * @private
   * @async
   */
  const loadAutoDoc = async function (iparams) {
    if (empty(iparams)) {
      iparams = {};
    }
    let userHash = CREDENTIALS.getUserCreds();
    if (userHash == null) {
      throw new Error(i18n.en.APP.UI.ERROR.MODELCFG.USER_NOT_LOGGED_IN);
    }
    let params = extend(
      {
        key: userHash,
        projectKey: PROJECT.currentProjectKey(),
        projVersion: PROJECT.currentProjVersion(),
      },
      iparams
    );

    let url = `${SERVER.getBaseAddress()}autodoc`;
    // APP.setProgress("Loading data...");
    var result = null;
    try {
      if (useTestData) {
        result = await MODEL_DEPLOYMENT_TEST_DATA.getAutodoc();
      } else {
        result = await SERVER.postData(url, params);
      }
    } catch (err) {
      result = null;
    }
    if (result === "ROUTES_MISMATCHED") {
      return;
    }
    if (
      result == null ||
      (result.status != "success" &&
        !(result.status >= 100 && result.status < 300))
    ) {
      let reason = "";
      if (result && result.data && result.data.reason) {
        reason = result.data.reason;
      }
      const errMessage = `${i18n.en.APP.UI.ERROR.MODELDEPLOYMENT.AUTODOC} ${reason}  Please contact an Administrator.`;
      APP.showError(errMessage);
      APP.resetProgress();
      let err = new Error(errMessage);
      err.name = "PollingError";
      throw err;
    }
    // APP.resetProgress();
    return result;
  };

  /**
   * @method updateAutodocLinkStatus
   * @description update the autodoc link's state and href based on the value of [autodocPath](#~autodocPath)
   */
  const updateAutodocLinkStatus = function () {
    const autodocLinkButton = qs("#auto-doc");
    if (
      empty(autodocPath) ||
      PROJECT.currentProject().ptype == "segmentation"
    ) {
      autodocLinkButton.setAttribute("disabled", "disabled");
      // autodocLinkButton.setAttribute("href", "javascript:APP.noop();");
      autodocLinkButton.removeAttribute("download");
      autodocLinkButton.setAttribute(
        "title",
        autodocLinkButton.getAttribute("not-ready-title")
      );
      autodocLinkButton.addClass("disabled");
    } else {
      autodocLinkButton.removeAttribute("disabled");
      // autodocLinkButton.setAttribute("href", autodocPath);
      autodocLinkButton.addEventListener("click", () => {
        window.open(autodocPath);
      });
      autodocLinkButton.setAttribute(
        "download",
        autodocPath.substring(autodocPath.lastIndexOf("/") + 1)
      );
      autodocLinkButton.setAttribute(
        "title",
        autodocLinkButton.getAttribute("ready-title")
      );
      autodocLinkButton.removeClass("disabled");
    }
  };

  /**
   * @method unload
   * @description stops polling for autocode
   * @public
   */
  my.unload = function () {
    cancelPolling();
  };

  return my;
})(AUTO_CODE || {});
