/* global CHART */
/**
 * @module KS_CHART
 * @description draws the KS chart shown on the `mp/mll/discrimination` page. Implements the 
 * [`CHART`](module-CHART.html) interface.
 */
// eslint-disable-next-line no-extra-semi
;var KS_CHART=(function(my){
  my.type="ks";

  my.optsGenerator=function(options,index){
    let opts=extend({},CHART.opts);
    delete opts.axis.y.tick.values;
    if(index!=null){
      opts.bindto=`.ks-graph-card-${index} #versionKsGraph .graph`;
    }
    else{
      opts.bindto="#ksGraph .graph";
    }
    opts.id=my.type;
    opts.axis.y.tick.format=d3.format(".0%");
    opts.axis.y.label.text='Cumulative % Records';
    let tickSpaces=5, tickSpacing=Math.round(Math.ceil(options.data.y1.keys[options.data.y1.keys.length-1]/10)*10)/tickSpaces;
    opts.axis.x={
      min: 0,
      // max: 1,
      tick:{
        values: Array.apply(null, {length: tickSpaces+1}).map((x, i)=>i*tickSpacing)
      },
      label:{
        text: 'Decile',
        position: 'outer-right'
      } 
    };
    opts.data={
      xs:{
        y: "x1",
        ydash: "x2"
      },
      columns: [
        ['x1'].concat(options.data.y1.keys),
        ['x2'].concat(options.data.y2.keys),
        [options.data.y1.name].concat(options.data.y1.values),
        [options.data.y2.name].concat(options.data.y2.values)
      ],
      type: 'line',
      colors: {
         y: CHART.colors[3],
         ydash: CHART.colors[1]
      },
      names:{
        y: "% Bad",
        ydash: "% Good"
      }
    };
    opts.grid={
      x:{show: true, lines: [{value: options.data.score, text: `KS=${options.data.score}`, class: 'ks-score'}]},
      y:{show: true},
    };
    if(index!=null){
      opts.size={
        width: 632,
        height: 373,
      };
    }
    else{
      opts.size={
        width: qs("#ksGraph").offsetWidth -30,
        height: qs("#ksGraph").offsetHeight - qs("#ksGraph h4").offsetHeight - 22
      };
    }

    if (!empty(options.c3d3properties)){
      opts=extend(opts, options.c3d3properties);
    }

    return opts;
  }
  my.generate=function(options, index=null){
    let opt=my.optsGenerator(options,index);
    CHART.charts[opt.id]=c3.generate(opt);
    return CHART.charts[opt.id];
  }

  return my;
}(KS_CHART || {}));
