/**
 * @module ML_LEADERBOARD_TEXT_MULTI_CLASSIFICATION
 * @description Renders the Machine Learning leaderboard page for txt-mclass project type
 */

var ML_LEADERBOARD_TEXT_MULTI_CLASSIFICATION = (function (my) {
  /**
   * @member {object} leaderboardData
   * @description Holds the leaderboard data.
   * @private
   */
  var leaderboardData = null;

  /**
   * @member {object} loadData
   * @description Makes the API call on /plot with suitable payload and returns them leaderboard data
   */
  const loadData = async function (iparams) {
    if (useTestData) {
      let result = await TEXT_MULTI_CLASSIFICATION_LEADERBOARD_DATA.getData();
      return result;
    }
    return API_HELPER.getResult("plot", iparams);
  };

  /**
   * @member {object} loadHtml
   * @description loads a particular page of the leaderboard based on the subnav.
   */
  var loadHtml = function (subnav, source, model) {
    qs("#main-content .content.ml-leaderboard-txt-mclass").style.display =
      "block";
    if (subnav == "modelPerformanceTextMultiClassification") {
      MODEL_PERFORMANCE_TEXT_MULTI_CLASSIFICATION.loadPage(
        leaderboardData,
        source,
        model
      );
    }
  };

  /**
   * @member {object} loadHtml
   * @description the first function which is called from app-router.js on ml-leaderboard-txt-mclass route.
   * @public
   */
  my.show = async function (subnav, source, model, pkey) {
    qs("#main-content .content.ml-leaderboard-txt-mclass").style.zIndex = 1;
    qs("#main-content .content.ml-leaderboard-txt-mclass").removeClass(
      "isClusterVisualization"
    );
    const leaderboardTextMultiClassificationHtml = qs(
      ".ml-leaderboard-txt-mclass"
    );
    if (leaderboardTextMultiClassificationHtml) {
      leaderboardTextMultiClassificationHtml.innerHTML = "";
    }
    if (!leaderboardData) {
      APP.setProgress("Loading data...");
      leaderboardData = await loadData({
        projectKey: pkey,
        projVersion: PROJECT.currentProjVersion(),
      });
      APP.resetProgress();
    }
    loadHtml(subnav, source, model);
  };

  /**
   * @member {object} unloadPage
   * @description called from the app-router to unload the page when route is changed from ml-leaderboard-txt-mclass.
   * @public
   */
  my.unloadPage = function () {
    leaderboardData = null;
    qs("#main-content .content.ml-leaderboard-txt-mclass").style.display =
      "none";
  };

  return my;
})(ML_LEADERBOARD_TEXT_MULTI_CLASSIFICATION || {});
