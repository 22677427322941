var FORGET_PASSWORD = (function (my) {
  const PROPERTY_BLOCK = "block";
  const PROPERTY_ZINDEX1 = "1";
  const PROPERTY_NONE = "none";
  /**
   * @private
   * @member
   * @description Regex used for password validation.
   */
  var passwordValidatorRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;

  /**
   * @private
   * @method
   * @description Gets security questions configured for a particular user.
   */
  var listQuestionData = async function (uname) {
    APP.setProgress("Checking for Security questions.");
    let params = { uname: uname };
    return API_HELPER.getQuestionsForSecurity("settings/listscrtquest",params,"forget-password");
  };

  /**
   * @private
   * @method
   * @description Saves password to server.
   */
  var savePasswordToServer = async function (data) {
    APP.setProgress("Saving New Password.");
    let params = extend(data);
    return API_HELPER.savePasswordToServer("settings/resetpswd",params);
  };

  /**
   * @private
   * @method
   * @description Validates answers given by user by making an api call.
   */
  var verifyAnswersFromServer = async function (params) {
    let url = SERVER.getBaseAddress() + "settings/vldtscrtquestans";
    APP.setProgress("Verifying Answers.");
    let result = null;
    try {
      if (useTestData) {
        result = await USER_MANAGEMENT_DATA.validateAnswers(url, params, false);
      } else {
        result = await SERVER.postData(url, params);
      }
      APP.resetProgress();
    } catch (err) {
      result = null;
      APP.resetProgress();
    }
    if(result === "ROUTES_MISMATCHED"){
      return;
    }
    try {
      if (
        result != null &&
        (result.status == 200 || (result.status >= 200 && result.status < 300))
      ) {
      }
    } catch (err) {
      APP.Error("Error in verifying answers from server.");
      throw new Error("Error in verifying answers from server.");
    }
    return result;
  };

  /**
   * @private
   * @method
   * @description Checks password against password policy and makes 'savePasswordToServer' function call.
   */
  var verifySavePassword = function (username, answers) {
    let ip = qsa(
      "#main-content .content.forget-password .page-container .reset-password-section input"
    );
    let pass1 = ip[0].value;
    let pass2 = ip[1].value;
    if (
      pass1 == undefined ||
      pass2 == undefined ||
      pass1 == "" ||
      pass2 == ""
    ) {
      APP.showError("Please fill both fields.");
      return;
    }
    if (pass1 != pass2) {
      APP.showError("Entered passwords do not match.");
      return;
    }
    if (!pass1.match(passwordValidatorRegex)) {
      APP.showError("Password do not satisfy our password policy");
      return;
    }
    savePasswordToServer({ uname: username, pswd: pass1, qnAnsInfo: answers }).then(function (result) {
      if (result.status == 200) {
        APP.showInfo("Password reset successfull");
        // qs(
        //   "#main-content .content.forget-password .login-link-container"
        // ).style.display = "block";
        // qs(
        //   "#main-content .content.forget-password .login-link-container .link").addEventListener("click", () => {
        //     location.reload();
        //   })
        let timeDelay = function (time) {
          let tic = (resolve, reject) => {
            if (time == 0) {
              resolve({ msg: "DONE" });
            } else {
              APP.showInfo(
                `Password reset successfull. You will be redirected to login screen in ${time} seconds.`
              );
              time = time - 1;
              setTimeout(tic, 1000, resolve, reject);
            }
          };
          return new Promise(tic);
        }
        // Time in seconds.
        timeDelay(3).then(() => {
          APP.router.navigate("/sign-up");
        });
      } else {
        APP.showError("Error in password reset");
      }
    });
  };

  /**
   * @private
   * @method
   * @description Checks response received from server, if all answers are true renders next part of screen.
   */
  // This function is click event handler of reset button in reset-password-section, made it global so that it can be accessed by unload function.
  var savePswd = function () {};
  var verifyAnswers = function () {
    let answers = [];
    qsa(
      "#main-content .content.forget-password .page-container .security-question-section input"
    ).forEach((x) => answers.push(x.value));
    for (let ans of answers) {
      if (ans == "" || ans == null || ans == undefined) {
        APP.showError("Please enter answers to all questions.");
        return;
      }
     }
    let username = qs(
      "#main-content .content.forget-password .page-container .username-section input"
    ).value;
    if (username == "") {
      APP.showError("Please enter username.");
      return;
    }
    verifyAnswersFromServer({ uname: username, qnAnsInfo: answers }).then(
      function (result) {
        result = result.data.posts[0];
        for (let k of Object.keys(result)) {
          if (!result[k]) {
            APP.showError("You entered wrong answer for a question.");
            return;
          }
        }
        APP.showInfo("You entered right answers for all questions.");

        username = qs(
          "#main-content .content.forget-password .page-container .username-section input"
        ).value;
        // Overriding defination of savePswd function, so that username can be passed as argument.
        savePswd = function () {
          verifySavePassword(username, answers);
        };
        qs(
          "#main-content .content.forget-password"
        ).innerHTML = forgetpasswordTemplate({ flag: true });
        qs(
          "#main-content .content.forget-password .page-container .security-question-section"
        ).addClass("hidden");
        qs(
            "#main-content .content.forget-password .page-container .username-section"
          ).addClass("hidden");
        qs(
          "#main-content .content.forget-password .page-container .reset-password-section button"
        ).addEventListener("click", savePswd);
      }
    );
  };

  /**
   *@private
   *@method
   *@description Renders list of questions on screen which are retreived from server.
   */
  var showSecurityQuestionsSection = function (questions) {
    let securityQuestionHtml = ``;
    for (let q of questions) {
      securityQuestionHtml += `
      <div class="single-question-container">
        <div class="question">${q}<div>
        <div class="input-container">
          <input type="text" class="border-bottom" autocomplete="off">
        </div>
      </div>
      `;
    }
    qs(
      "#main-content .content.forget-password .security-question-section .question-container"
    ).innerHTML = securityQuestionHtml;
    qs(
      "#main-content .content.forget-password .page-container .security-question-section"
    ).removeClass("hidden");
    qs(
      "#main-content .content.forget-password .page-container .security-question-section button"
    ).addEventListener("click", verifyAnswers);
  };

  /**
   * @private
   * @method
   * @description Gets username from page and reterives security questions for that user.
   */
  var showQuestions = function () {
    let username = qs(
      "#main-content .content.forget-password .username-section input"
    ).value;
    if (username == "" || username == undefined || username == null) {
      APP.showError("Please enter a username.");
      return;
    }
    listQuestionData(username).then(function (questionList) {
      if (questionList.qns.length == 0) {
        APP.showError("Security Questions are not configured for the user.");
      } else {
        showSecurityQuestionsSection(questionList.qns);
      }
    });
  };

  /**
   *@method
   *@public
   *@description Starts loading of the page, entrypoint to forget-password system.
   */
  my.loadPage = function () {
    qs("#main-content .content.forget-password").style.display = PROPERTY_BLOCK;
    qs("#main-content .content.forget-password").style.zIndex = PROPERTY_ZINDEX1;
    qs(
      "#main-content .content.forget-password"
    ).innerHTML = forgetpasswordTemplate({ flag: false });
    qs(
      "#main-content .content.forget-password .page-container .security-question-section"
    ).addClass("hidden");
    qs(
      "#main-content .content.forget-password .page-container .reset-password-section"
    ).addClass("hidden");

    let getQstnBtn = qs(
      "#main-content .content.forget-password .username-section button"
    );
    getQstnBtn.addEventListener("click", showQuestions);
  };

  /**
   * @method
   * @public
   * @description Removes all event listeners and clears the page.
   */
  my.unloadPage = function () {
    let getQstnBtn = qs(
      "#main-content .content.forget-password .username-section button"
    );
    if (getQstnBtn) {
      getQstnBtn.removeEventListener("click", showQuestions);
    }
    let verifyAnswerBtn = qs(
      "#main-content .content.forget-password .security-question-section button"
    );
    if (verifyAnswerBtn) {
      verifyAnswerBtn.removeEventListener("click", verifyAnswers);
    }
    let resetBtn = qs(
      "#main-content .content.forget-password .reset-password-section button"
    );
    if (resetBtn) {
      resetBtn.removeEventListener("click", savePswd);
    }

    qs("#main-content .content.forget-password").style.display = PROPERTY_NONE;
  };
  return my;
})(FORGET_PASSWORD || {});
