/**
 * @module MD_STATS_CHART
 * @description draw various MD stats charts in a container
 */
;var MD_STATS_CHART=(function(my){ // eslint-disable-line no-extra-semi
  /**
   * @member {object[]} charts
   * @description Array of MD chart objects created using `c3.js`
   */
  var charts={};

  /**
   * @method generate
   * @description Create a AUC/LogLoss stats chart.
   * @param {object} options the parameters to be passed to `c3.js` required to specify the chart. After creation the chart will be added to the [charts](#~charts) object.
   * @public
   */
  my.generate=function(options){
    let opt={
      bindto: options.selector,
      size: {
        height: options.height,
        width: options.width
      },
      data: {
        columns: options.data,
        type: "line",
      },
      axis: {
        y: {
          // max: 100,
          min: 0,
          padding: {top: 0, bottom: 0, left: 0},
          tick:{
            values:options.yTicks
          }
        },
        x: {
          // type: 'timeseries',
          // tick: {
          //     format: '%M:%S',
          // },
          show: false
        }
      },
      point: {
        show: true
      },
      legend:{
        show: true
      },
      transition: {
        duration: 100
      },
      id:options.id,
      onrendered: function(){
        //do nothing
      }
    };
    charts[opt.id]=c3.generate(opt);
  };

  /**
   * @method getChart
   * @description look up a stored `c3.js` chart object from the [`charts`](#~charts) object
   * @param {string} id the lookup key.
   * @return the `c3.js` chart object for specified chart
   */
  my.getChart=function(id){
    return charts[id];
  };

  return my;
}(MD_STATS_CHART || {}));

