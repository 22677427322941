/* global CHART */
/**
 * @module PRECISION_CHART
 * @description draws the Precision chart shown on the `mp/mll/fprAndPA` page. Implements the 
 * [`CHART`](module-CHART.html) interface.
 */
// eslint-disable-next-line no-extra-semi
;var PRECISION_CHART=(function(my){
  my.type="precision";

  my.optsGenerator=function(options){
    let opts=extend({},CHART.opts);
    delete opts.axis.y.tick.count;
    delete opts.axis.y.min;
    delete opts.axis.y.max;
    delete opts.axis.y.tick.values;

    delete opts.axis.x.label.text;
    delete opts.axis.x.min;
    delete opts.axis.x.max;
    delete opts.axis.x.tick.values;
    
    opts.bindto="#precisionGraph .graph";
    opts.axis.y.tick.format=d3.format(".3");
    opts.axis.y.label.text="Precision";
    opts.axis.x.label.text="Sensitivity";
    opts.axis.x.tick.format=d3.format(".3");
    opts.id=my.type;
    opts.data={
      xs:{
        Reliability: "x1",
      },
      columns: [
        ['x1'].concat(options.data.y1.keys),
        [options.data.y1.name].concat(options.data.y1.values),
      ],
      type: 'line',
      colors: {
         Reliability: CHART.colors[0],
      }
    };
    opts.legend={
      show: false,
    };
    opts.grid={
      x:{show: true},
      y:{show: true},
    };
    opts.point={
      show: true,
      r: 3
    }

    if (!empty(options.c3d3properties)){
      opts=extend(opts, options.c3d3properties);
    }
    
    return opts;
  };
  my.generate=function(options){
    let opt=my.optsGenerator(options);
    CHART.charts[opt.id]=c3.generate(opt);
    return CHART.charts[opt.id];
  }

  return my;
}(PRECISION_CHART || {}));