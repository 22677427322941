/* global */
/**
 * @module EXPERT_EXPERIMENTATION
 * @description Renders and handles Expert Experimentation pages
 */

var EXPERT_EXPERIMENTATION = (function (my) {
  my.varType = "Numerical";
  my.featureTableData = {};
  var filteredFeatureTableData;
  var asc = true;
  var searchTableData = {};
  // initial data fetched from the APIs to be stored in these two variables
  var intialExpConfigData = {};
  var initialModelConfigData = {};
  var initialTooltipData = {};
  var initialFeatureTableData = {};
  // data in action
  var data = {};
  var expData = {};
  var minMaxObj = {};
  var tooltipData = {};
  var projectKey;
  // Flag for formatting imported data;
  var importedFlag = false;
  var accessType = null;
  var existingClonedHeading = null;
  var enabledTabsInfo = [];

  /**
   * @function generateChart
   * @description Generates the graph on the basis of the value of range input.
   * @private
   */
  var generateChart = function (data) {
    // Making content of graph container empty.
    qs("#graph").innerHTML = "";

    // Specifying dimensions of the graph
    const width = 250;
    const height = 250;
    const margin = { top: 10, bottom: 20, left: 30, right: 10 };

    const svg = d3
      .select("#graph")
      .append("svg")
      .attr("width", width - margin.left - margin.right)
      .attr("height", height - margin.top - margin.bottom)
      .attr("viewBox", [0, 0, width, height]);

    const x = d3
      .scaleBand()
      .domain(d3.range(data.length))
      .range([margin.left, width - margin.right])
      .padding(0.1);

    const y = d3
      .scaleLinear()
      .domain([0, 100])
      .range([height - margin.bottom, margin.top]);

    svg
      .append("g")
      .attr("fill", "rgba(0, 145, 218, 0.8)")
      .selectAll("rect")
      .data(data)
      .join("rect")
      .attr("x", (d, i) => x(i))
      .attr("y", (d) => y(d.score))
      .attr("title", (d) => d.score)
      .attr("class", "rect")
      .attr("height", (d) => y(0) - y(d.score))
      .attr("width", x.bandwidth());

    function yAxis(g) {
      g.attr("transform", `translate(${margin.left}, 0)`)
        .call(d3.axisLeft(y).ticks(null, data.format))
        .attr("font-size", "12px");
    }

    function xAxis(g) {
      g.attr("transform", `translate(0,${height - margin.bottom})`)
        .call(d3.axisBottom(x).tickFormat((i) => data[i].name))
        .attr("font-size", "12px");
    }

    svg.append("g").call(xAxis);
    svg.append("g").call(yAxis);
    svg.node();
  };

  /**
   * @function getImportedData
   * @description calls the API to get import validate features data.
   * @private
   */
  const getImportedData = async function (params) {
    let result = {};
    if (useTestData) {
      result = await MODEL_CONFIGURATION_IMPORT_DATA.getImportConfigurations();
      return result;
    }
    result = await API_HELPER.getResult("config/impprojcfg", params);
    return result;
  };

  /**
   * @function importConfigurationData
   * @description calls the getImportData function and loads the table with new data.
   * @private
   */
  const importConfigurationData = async function () {
    const selectedProject = qs("#projects-dialog #select-project").value;
    var selectedVersion = null;
    const versionsList = qsa(
      "#projects-dialog .version-radio-list .version-item"
    );
    versionsList.forEach((versionItem) => {
      if (versionItem.qs("input").checked == true) {
        selectedVersion = versionItem.qs("input").value;
        return;
      }
    });
    let params = { projectKey: selectedProject, projVersion: selectedVersion };
    APP.setProgress("Importing Data");
    let importedData = await getImportedData(params);
    APP.resetProgress();
    // importedData = null;
    if (importedData == null) {
      APP.showWarning("Import failed because of mismatching of schema data");
      return;
    }
    data = JSON.parse(JSON.stringify(importedData.data.posts[0]));
    formatDataForFeatureTable();
    filteredFeatureTableData = JSON.parse(
      JSON.stringify(initialFeatureTableData)
    );
    formatData(data, expData, false);
    // console.log(data);
    showData();
  };

  /**
   * @function registerEventListenerOnRadios
   * @description registers the click event listener on the input radios to select version from dialog box.
   * @private
   */
  const registerEventListenerOnRadios = function () {
    qs("#projects-dialog .version-radio-list").addEventListener(
      "click",
      (evt) => {
        if (evt.target.className === "version-radio-input") {
          qsa(".version-radio-input").forEach(
            (inputRadio) => (inputRadio.checked = false)
          );
          evt.target.checked = true;
        }
      }
    );
  };

  /**
   * @function selectFirstVersion
   * @description select the first version which comes up for any selected project by default.
   * @private
   */
  const selectFirstVersion = function () {
    const versionList = qs("#projects-dialog .version-radio-list");
    if (versionList) {
      const firstChild = versionList.firstChild;
      if (firstChild) {
        const firstInput = firstChild.qs("input");
        if (firstInput) {
          firstInput.checked = true;
        }
      }
    }
  };

  /**
   * @function populateOptions
   * @description dynamically updates the version options with respect to the project selected.
   * @private
   */
  const populateOptions = function (currentProjKey) {
    qs("#projects-dialog #select-project").addEventListener("change", (evt) => {
      let selectedProjectKey = evt.target.value;
      let selectedProject = filteredProjects.find(
        (proj) => proj.projectkey === selectedProjectKey
      );
      let versionRadioList = qs(".version-radio-list");
      versionRadioList.innerHTML = "";
      let listItemHtml = "";
      for (version of selectedProject.versionInfo) {
        listItemHtml += `<li class="version-item"><input class="version-radio-input" type=radio value=${version.vname}></input><span>${version.vname}</span><span>${version.description}</span></li>`;
      }
      versionRadioList.innerHTML = listItemHtml;
      selectFirstVersion();
    });
    let currentProject = filteredProjects.find(
      (proj) => proj.projectkey === currentProjKey
    );
    let versionInfo = [];
    if (currentProject) {
      versionInfo = currentProject.versionInfo;
    } else {
      const selectedProjectKey = qs("#projects-dialog #select-project").value;
      const selectedProject = filteredProjects.find(
        (proj) => proj.projectkey === selectedProjectKey
      );
      if (selectedProject) {
        versionInfo = selectedProject.versionInfo;
      }
    }
    if (versionInfo.length > 0) {
      let versionRadioList = qs(".version-radio-list");
      versionRadioList.innerHTML = "";
      let listItemHtml = "";
      for (version of versionInfo) {
        listItemHtml += `<li class="version-item"><input class="version-radio-input" type=radio value=${version.vname}></input><span>${version.vname}</span><span>${version.description}</span></li>`;
      }
      versionRadioList.innerHTML = listItemHtml;
      selectFirstVersion();
    }
  };

  /**
   * @function getProjects
   * @description calls the dashboard projects api and filters the projects with state > 4.
   * @private
   */
  const getProjects = async function () {
    result = await APP.getAllProjects().then((response) => {
      projects = response.data.posts;
    });
    filteredProjects = JSON.parse(JSON.stringify(projects));
    for (proj of filteredProjects) {
      proj.versionInfo = proj.versionInfo.filter(
        (version) => version.state > 5
      );
    }
    filteredProjects = filteredProjects.filter(
      (proj) => proj.versionInfo.length > 0
    );
    return filteredProjects;
  };

  /**
   * @function showProjectsDialog
   * @description shows the select project dialog.
   * @private
   */
  const showProjectsDialog = async function () {
    projectsData = await getProjects();
    //filter projects for current project type
    const currentProjectType = PROJECT.currentProject().ptype;
    const filterdProjectsData = projectsData.filter((project) => {
      return project.ptype === currentProjectType;
    });
    if (qs("#dialogs-active #projects-dialog")) {
      //if dialog already exists, cancel old dialog
      let projectsDialog = qs("#dialogs-active #projects-dialog");
      projectsDialog.fireCustomEvent("cancelled", {
        message: "Another dialog triggered.",
      });
    }
    const currentProjKey = PROJECT.currentProjectKey();
    var projectsDialog = null;
    projectsDialog = createNode(
      projectsdialogTemplate({
        projectsData: filterdProjectsData,
        currentProjKey: currentProjKey,
      })
    );
    return new Promise((resolve, reject) => {
      qs("#dialogs-sleeping").appendChild(projectsDialog);
      APP.showDialog(projectsDialog);
      let okAction = (evt) => {
        if (
          evt.type.toLowerCase() == "keypress" &&
          evt.key.toLowerCase() !== "enter"
        ) {
          return;
        }
        importConfigurationData();
        importedFlag = true;
        APP.hideDialog(projectsDialog);
        projectsDialog.remove();
      };
      populateOptions(currentProjKey);
      registerEventListenerOnRadios();
      projectsDialog
        .qs("#import-configs-button")
        .addEventListener("click", okAction);
      projectsDialog.addEventListener("keypress", okAction);
      projectsDialog.addEventListener("cancelled", (evt) => {
        projectsDialog.remove();
        reject(`Cancelled. ${evt.detail.message}`);
      });
      [].forEach.call(
        projectsDialog.qsa("button.close, #cancel-button"),
        function (button) {
          button.addEventListener("click", () => {
            APP.hideDialog(projectsDialog);
            projectsDialog.remove();
            reject("cancelled");
          });
        }
      );
    });
  };

  const registerEventListernerOnImportConfiguration = function () {
    qs(".import-btn-box .import-config").addEventListener(
      "click",
      showProjectsDialog
    );
  };

  /**
   * @function handleVisibilityOfOptioncfgs
   * @description Sets event listner on all the select inputs for displaying option specific configuration.
   * @private
   */
  var handleVisibilityOfOptioncfgs = function (evt) {
    // Time taking function
    // return;
    let target = evt.target;
    let value = target.value;
    let targetId = target.getAttribute("id");
    let optionCfgsContainer = qsa(`.${targetId}-optioncfgs`);
    if (optionCfgsContainer) {
      optionCfgsContainer.forEach((opt) => {
        let correctKey = opt.getAttribute("key");
        if (correctKey == value) {
          opt.removeClass("hidden");
        } else {
          opt.addClass("hidden");
        }
      });
    }
    let key = target.getAttribute("key");
    let ttIdentifier = target.getAttribute("ttIdentifier");
    let tooltips;
    if (key) {
      tooltips = qsa(`.tooltip-${key}-container`);
    } else if (ttIdentifier) {
      tooltips = qsa(`.tooltip-${ttIdentifier}-container`);
    }
    if (tooltips) {
      tooltips.forEach((x) => {
        let tooltipId = x.getAttribute("id");
        if (tooltipId == value) {
          x.removeClass("hidden");
        } else {
          x.addClass("hidden");
        }
      });
    }
  };

  /**
   * @function validateClistInput
   * @description Sets event listner on clist input field for input validation.
   * @private
   */
  var validateClistInput = function () {
    // checks if input character is a valid character
    // Replace this with proper Regex.
    function isValidChar(character) {
      let validCharset = "1234567890,.";
      for (let v of validCharset) {
        if (v == character) {
          return true;
        }
      }
      return false;
    }

    function isValidCharForOnlyInt(character) {
      let validCharset = "1234567890,";
      for (let v of validCharset) {
        if (v == character) {
          return true;
        }
      }
      return false;
    }

    var validator = (evt) => {
      let currentValue = evt.target.value;
      let validInput = "";
      if (currentValue[0] == ",") {
        currentValue = currentValue.slice(1);
      }
      for (let i = 0; i < currentValue.length - 1; i++) {
        // If input contains two consecutive commas, only one will be considered.
        if (currentValue[i] == "," && currentValue[i + 1] == ",") {
          continue;
        }
        if (currentValue[i] == "." && currentValue[i + 1] == ".") {
          continue;
        }
        if (currentValue[i] == "." && currentValue[i + 1] == ",") {
          continue;
        }
        if (currentValue[i] == "," && currentValue[i + 1] == ".") {
          continue;
        }
        if (isValidChar(currentValue[i])) {
          // If character is invalid, it will not be added in the final valid input
          validInput = validInput + currentValue[i];
        }
      }
      // Checking last character, if it is valid or not.
      if (isValidChar(currentValue[currentValue.length - 1])) {
        validInput = validInput + currentValue[currentValue.length - 1];
      }
      evt.target.value = validInput;
    };

    var onlyIntValidator = (evt) => {
      let currentValue = evt.target.value;
      let validInput = "";
      if (currentValue[0] == ",") {
        currentValue = currentValue.slice(1);
      }
      for (let i = 0; i < currentValue.length - 1; i++) {
        // If input contains two consecutive commas, only one will be considered.
        if (currentValue[i] == "," && currentValue[i + 1] == ",") {
          continue;
        }
        if (isValidCharForOnlyInt(currentValue[i])) {
          // If character is invalid, it will not be added in the final valid input
          validInput = validInput + currentValue[i];
        }
      }
      // Checking last character, if it is valid or not.
      if (isValidCharForOnlyInt(currentValue[currentValue.length - 1])) {
        validInput = validInput + currentValue[currentValue.length - 1];
      }
      evt.target.value = validInput;
    };

    let clist = qsa(".clist-ip");
    if (clist != undefined) {
      clist.forEach((ele) => {
        if (ele.hasClass("onlyInt")) {
          ele.addEventListener("input", onlyIntValidator);
        } else {
          ele.addEventListener("input", validator);
        }
      });
    }
  };

  /**
   * @function saveHorizontalEntrytabData
   * @description  Creates and returns a object containing all the details entered by users in those tabs which have horizontal style entries.
   * @private
   */
  var saveHorizontalEntrytabData = function (
    initialDataObject,
    tabKey,
    setIfEmptyValue,
    boundCheck,
    tabLabel,
    currentTab
  ) {
    if (initialDataObject[tabKey]) {
      let postData = {};
      // Collecting data from Feature Engineering Tab
      postData[tabKey] = initialDataObject[tabKey];
      for (const key in postData[tabKey]) {
        // Checking if the algorithm is enabled.
        if (qs(`[id="${key}"]`) != undefined) {
          postData[tabKey][key].enable = qs(`[id="${key}"]`).checked ? 1 : 0;
        }
        if (qs(`[id="${key}"]`) != undefined && !qs(`[id="${key}"]`).checked) {
          // continue loop with default data, if algorithm is not enabled. Do not collect data further.
          continue;
        }

        // Collecting data of range == 0 inputs.
        if (
          postData[tabKey][key].range == 0 &&
          postData[tabKey][key].subcfg == undefined &&
          postData[tabKey][key].type == undefined
        ) {
          postData[tabKey][key].values = setIfEmptyValue(
            qs(`[id="${key}-input"]`).value,
            key.toUpperCase(),
            tabLabel
          );
        }

        // Collecting data of range == 0 inputs, with option type
        if (
          postData[tabKey][key].range == 0 &&
          postData[tabKey][key].subcfg == undefined &&
          postData[tabKey][key].type == "option"
        ) {
          if (qs(`[id="${key}-input"]`)) {
            postData[tabKey][key].selectedValue = setIfEmptyValue(
              qs(`[id="${key}-input"]`).value,
              key.toUpperCase(),
              tabLabel
            );
          } else {
            postData[tabKey][key].selectedValue = setIfEmptyValue(
              qs(`#${currentTab}-${key}-input`).value,
              key.toUpperCase(),
              tabLabel
            );
          }
          if (postData[tabKey][key].optioncfgs) {
            for (let i = 0; i < postData[tabKey][key].optioncfgs.length; i++) {
              // Each object in optioncfgs array consist of only one key mapped to actual object.
              // This portion is similar to below one just optioncfgs is replaced with subcfg.
              for (const subKey in postData[tabKey][key].optioncfgs[i]) {
                let object = postData[tabKey][key].optioncfgs[i][subKey];
                if (object.range == 0 && object.type == undefined) {
                  postData[tabKey][key].optioncfgs[i][subKey].values =
                    setIfEmptyValue(
                      qs(`[id="${key}-subcfg-${subKey}"]`).value,
                      subKey.toUpperCase(),
                      tabLabel
                    );
                }
                if (object.range == 0 && object.type == "option") {
                  postData[tabKey][key].optioncfgs[i][subKey].selectedValue =
                    setIfEmptyValue(
                      qs(`[id="${key}-subcfg-${subKey}"]`).value,
                      subKey.toUpperCase(),
                      tabLabel
                    );
                }
                if (object.range == 1) {
                  for (const order of postData[tabKey][key].optioncfgs[i][
                    subKey
                  ]["order"]) {
                    postData[tabKey][key].optioncfgs[i][subKey].values[order] =
                      setIfEmptyValue(
                        qs(`[id="${key}-subcfg-${subKey}-${order}"]`).value,
                        subKey.toUpperCase(),
                        tabLabel
                      );
                  }
                }
              }
            }
          }
        }

        // Collecting data of range == 0 inputs which also have subcfg field.
        // subcfg field is an array of objects in FE tab.
        if (
          postData[tabKey][key].range == 0 &&
          postData[tabKey][key].subcfg != undefined
        ) {
          for (let i = 0; i < postData[tabKey][key].subcfg.length; i++) {
            // Each object in subcfg array consist of only one key mapped to actual object.
            for (const subKey in postData[tabKey][key].subcfg[i]) {
              let object = postData[tabKey][key].subcfg[i][subKey];
              if (object.range == 0 && object.type == undefined) {
                postData[tabKey][key].subcfg[i][subKey].values =
                  setIfEmptyValue(
                    qs(`[id="${key}-subcfg-${subKey}"]`).value,
                    subKey.toUpperCase(),
                    tabLabel
                  );
              }
              if (object.range == 0 && object.type == "option") {
                postData[tabKey][key].subcfg[i][subKey].selectedValue =
                  setIfEmptyValue(
                    qs(`[id="${key}-subcfg-${subKey}"]`).value,
                    subKey.toUpperCase(),
                    tabLabel
                  );
              }
              if (object.range == 1) {
                for (const order of postData[tabKey][key].subcfg[i][subKey][
                  "order"
                ]) {
                  postData[tabKey][key].subcfg[i][subKey].values[order] =
                    setIfEmptyValue(
                      qs(`[id="${key}-subcfg-${subKey}-${order}"]`).value,
                      subKey.toUpperCase(),
                      tabLabel
                    );
                }
              }
            }
          }
        }

        // Collecting data of range == 1 inputs.
        if (
          postData[tabKey][key].range == 1 &&
          postData[tabKey][key].subcfg == undefined
        ) {
          for (const subKey in postData[tabKey][key].values) {
            postData[tabKey][key].values[subKey] = setIfEmptyValue(
              qs(`[id="${key}-values-${subKey}"]`).value,
              key.toUpperCase(),
              tabLabel
            );
          }
        }
      }
      return postData[tabKey];
    }
  };

  /**
   * @function saveVerticalEntryTabData
   * @description  Creates and returns a object containing all the details entered by users in those tabs which have vertical style entries.
   * @private
   */
  var saveVerticalEntryTabData = function (
    initialDataObject,
    tabKey,
    setIfEmptyValue,
    boundCheck,
    tabLabel
  ) {
    // // Collecting data from Model Development Tab.
    if (initialDataObject[tabKey]) {
      let postData = {};
      postData[tabKey] = JSON.parse(JSON.stringify(initialDataObject[tabKey]));
      for (const key in postData[tabKey]) {
        if (qs(`[id="${key}"]`) != undefined) {
          // Checking if the algorithm is enabled.
          postData[tabKey][key].enable = qs(`[id="${key}"]`).checked ? 1 : 0;
        }
        if (qs(`[id="${key}"]`) != undefined && !qs(`[id="${key}"]`).checked) {
          // continue loop with default data, if algorithm is not enabled. Do not collect data further.
          continue;
        }
        if (postData[tabKey][key].subcfg != undefined) {
          if (postData[tabKey][key].allowClone) {
            let i = 1;
            qsa(`.${key}-cloned-container`).forEach((x) => {
              let objct = JSON.parse(JSON.stringify(postData[tabKey][key]));
              let newLabelKey = `${key}-C${i}`;
              while (postData[tabKey].order.includes(newLabelKey)) {
                i++;
                newLabelKey = `${key}-C${i}`;
              }
              objct.label_key = newLabelKey;
              objct.name = x.qs(`[id="${key}-title"]`).innerText;
              objct.allowClone = 0;
              let lastIndexOfCurrentModelInOrderList = 0;
              for (let j = postData[tabKey].order.length - 1; j >= 0; j--) {
                var cloneKey = `${key}-`;
                if (
                  postData[tabKey].order[j] == key ||
                  postData[tabKey].order[j].includes(cloneKey)
                ) {
                  lastIndexOfCurrentModelInOrderList = j;
                  break;
                }
              }
              postData[tabKey].order.splice(
                lastIndexOfCurrentModelInOrderList + 1,
                0,
                newLabelKey
              );
              postData[tabKey][newLabelKey] = objct;
              for (const subKey in postData[tabKey][newLabelKey].subcfg) {
                let obj = JSON.parse(
                  JSON.stringify(postData[tabKey][newLabelKey].subcfg[subKey])
                );

                // Collecting data of range == 0 inputs and type == option.
                if (obj.range == 0 && obj.type == "option") {
                  if (x.qs(`[id="${key}-subcfg-${subKey}"]`)) {
                    postData[tabKey][newLabelKey].subcfg[subKey].selectedValue =
                      setIfEmptyValue(
                        x.qs(`[id="${key}-subcfg-${subKey}"]`).value,
                        subKey.toUpperCase(),
                        tabLabel
                      );
                  }
                }

                // Collecting data of range == 0 inputs and type == clist.
                if (obj.range == 0 && obj.type == "clist") {
                  // converting string containing commas into an array of numbers.
                  let clistValue = setIfEmptyValue(
                    x.qs(`[id="${key}-subcfg-${subKey}"]`).value,
                    subKey.toUpperCase(),
                    tabLabel
                  );
                  let lastIndex = clistValue.length - 1;
                  // Removing comma, if last character is comma
                  clistValue =
                    clistValue[lastIndex] == ","
                      ? clistValue.slice(0, -1)
                      : clistValue;
                  postData[tabKey][newLabelKey].subcfg[subKey].values =
                    clistValue.split(",").map((n) => Number(n));
                }
                if (obj.range == 0 && obj.type == "mclist") {
                  // converting string containing \n into a list of lists.
                  let mclistValue = setIfEmptyValue(
                    x.qs(`[id="${key}-subcfg-${subKey}"]`).value,
                    subKey.toUpperCase(),
                    tabLabel
                  );
                  let st = mclistValue;
                  let st2 = st.split("\n");
                  let ans = [];
                  let mid = [];
                  let final = [];
                  for (let i = 0; i < st2.length; i++) {
                    if (st2[i] == "") continue;
                    ans.push(st2[i].split(","));
                    for (let j = 0; j < ans.length; j++) {
                      for (let k = 0; k < ans[j].length; k++) {
                        if (ans[j][k] == "") continue;
                        mid.push(Number(ans[j][k]));
                      }
                    }
                    final.push(mid);
                    mid = [];
                    ans = [];
                  }
                  postData[tabKey][newLabelKey].subcfg[subKey].values = final;
                  // Removing comma, if last character is comma
                  // mclistValue = mclistValue[lastIndex] == "," ? mclistValue.slice(0, -1) : mclistValue;
                  // postData[tabKey][key].subcfg[subKey].values = mclistValue.split(",").map(n => Number(n));
                }
                // Collecting data of range == 0 inputs.
                if (obj.range == 0 && obj.type == undefined) {
                  postData[tabKey][newLabelKey].subcfg[subKey].values =
                    setIfEmptyValue(
                      x.qs(`[id="${key}-subcfg-${subKey}"]`).value,
                      subKey.toUpperCase(),
                      tabLabel
                    );
                }

                // Collecting data of range == 1 inputs.
                if (obj.range == 1 && obj.values != undefined) {
                  for (const v in obj.values) {
                    if (x.qs(`[id="${key}-subcfg-${subKey}-${v}"]`)) {
                      postData[tabKey][newLabelKey].subcfg[subKey].values[v] =
                        setIfEmptyValue(
                          x.qs(`[id="${key}-subcfg-${subKey}-${v}"]`).value,
                          subKey.toUpperCase(),
                          tabLabel
                        );
                    }
                  }
                }
              }
              i++;
            });
          }
          for (const subKey in postData[tabKey][key].subcfg) {
            let obj = JSON.parse(
              JSON.stringify(postData[tabKey][key].subcfg[subKey])
            );

            // Collecting data of range == 0 inputs and type == option.
            if (obj.range == 0 && obj.type == "option") {
              if (qs(`[id="${key}-subcfg-${subKey}"]`)) {
                postData[tabKey][key].subcfg[subKey].selectedValue =
                  setIfEmptyValue(
                    qs(`[id="${key}-subcfg-${subKey}"]`).value,
                    subKey.toUpperCase(),
                    tabLabel
                  );
              }
            }

            // Collecting data of range == 0 inputs and type == clist.
            if (obj.range == 0 && obj.type == "clist") {
              // converting string containing commas into an array of numbers.
              let clistValue = setIfEmptyValue(
                qs(`[id="${key}-subcfg-${subKey}"]`).value,
                subKey.toUpperCase(),
                tabLabel
              );
              let lastIndex = clistValue.length - 1;
              // Removing comma, if last character is comma
              clistValue =
                clistValue[lastIndex] == ","
                  ? clistValue.slice(0, -1)
                  : clistValue;
              postData[tabKey][key].subcfg[subKey].values = clistValue
                .split(",")
                .map((n) => Number(n));
            }
            if (obj.range == 0 && obj.type == "mclist") {
              // converting string containing \n into a list of lists.
              let mclistValue = setIfEmptyValue(
                qs(`[id="${key}-subcfg-${subKey}"]`).value,
                subKey.toUpperCase(),
                tabLabel
              );
              let st = mclistValue;
              let st2 = st.split("\n");
              let ans = [];
              let mid = [];
              let final = [];
              for (let i = 0; i < st2.length; i++) {
                if (st2[i] == "") continue;
                ans.push(st2[i].split(","));
                for (let j = 0; j < ans.length; j++) {
                  for (let k = 0; k < ans[j].length; k++) {
                    if (ans[j][k] == "") continue;
                    mid.push(Number(ans[j][k]));
                  }
                }
                final.push(mid);
                mid = [];
                ans = [];
              }
              postData[tabKey][key].subcfg[subKey].values = final;
            }
            // Collecting data of range == 0 inputs.
            if (obj.range == 0 && obj.type == undefined) {
              postData[tabKey][key].subcfg[subKey].values = setIfEmptyValue(
                qs(`[id="${key}-subcfg-${subKey}"]`).value,
                subKey.toUpperCase(),
                tabLabel
              );
            }

            // Collecting data of range == 1 inputs.
            if (obj.range == 1 && obj.values != undefined) {
              for (const v in obj.values) {
                if (qs(`[id="${key}-subcfg-${subKey}-${v}"]`)) {
                  postData[tabKey][key].subcfg[subKey].values[v] =
                    setIfEmptyValue(
                      qs(`[id="${key}-subcfg-${subKey}-${v}"]`).value,
                      subKey.toUpperCase(),
                      tabLabel
                    );
                }
              }
            }
          }
        } else {
          if (key == "default") {
            postData[tabKey][key].selectedValue = setIfEmptyValue(
              qs(`#${tabKey}-${key}-input`).value,
              key.toUpperCase(),
              tabLabel
            );
            if (postData[tabKey][key].optioncfgs) {
              for (
                let i = 0;
                i < postData[tabKey][key].optioncfgs.length;
                i++
              ) {
                // Each object in optioncfgs array consist of only one key mapped to actual object.
                // This portion is similar to below one just optioncfgs is replaced with subcfg.
                for (const subKey in postData[tabKey][key].optioncfgs[i]) {
                  let object = postData[tabKey][key].optioncfgs[i][subKey];
                  if (object.range == 0 && object.type == undefined) {
                    postData[tabKey][key].optioncfgs[i][subKey].values =
                      setIfEmptyValue(
                        qs(`[id="${key}-subcfg-${subKey}"]`).value,
                        subKey.toUpperCase(),
                        tabLabel
                      );
                  }
                  if (object.range == 0 && object.type == "option") {
                    postData[tabKey][key].optioncfgs[i][subKey].selectedValue =
                      setIfEmptyValue(
                        qs(`[id="${key}-subcfg-${subKey}"]`).value,
                        subKey.toUpperCase(),
                        tabLabel
                      );
                  }
                  if (object.range == 1) {
                    for (const order of postData[tabKey][key].optioncfgs[i][
                      subKey
                    ]["order"]) {
                      postData[tabKey][key].optioncfgs[i][subKey].values[
                        order
                      ] = setIfEmptyValue(
                        qs(`[id="${key}-subcfg-${subKey}-${order}"]`).value,
                        subKey.toUpperCase(),
                        tabLabel
                      );
                    }
                  }
                  if (object.range == 0 && object.type == "clist") {
                    // converting string containing commas into an array of numbers.
                    let clistValue = setIfEmptyValue(
                      qs(`[id="${key}-subcfg-${subKey}"]`).value,
                      subKey.toUpperCase(),
                      tabLabel
                    );
                    let lastIndex = clistValue.length - 1;
                    // Removing comma, if last character is comma
                    clistValue =
                      clistValue[lastIndex] == ","
                        ? clistValue.slice(0, -1)
                        : clistValue;
                    postData[tabKey][key].optioncfgs[i][subKey].values =
                      clistValue.split(",").map((n) => Number(n));
                  }
                  if (object.range == 0 && object.type == "mclist") {
                    // converting string containing \n into a list of lists.
                    let mclistValue = setIfEmptyValue(
                      qs(`[id="${key}-subcfg-${subKey}"]`).value,
                      subKey.toUpperCase(),
                      tabLabel
                    );
                    let st = mclistValue;
                    let st2 = st.split("\n");
                    let ans = [];
                    let mid = [];
                    let final = [];
                    for (let i = 0; i < st2.length; i++) {
                      if (st2[i] == "") continue;
                      ans.push(st2[i].split(","));
                      for (let j = 0; j < ans.length; j++) {
                        for (let k = 0; k < ans[j].length; k++) {
                          if (ans[j][k] == "") continue;
                          mid.push(Number(ans[j][k]));
                        }
                      }
                      final.push(mid);
                      mid = [];
                      ans = [];
                    }
                    postData[tabKey][key].optioncfgs[i][subKey].values = final;
                  }
                }
              }
            }
          }
          if (key == "add_action") {
            var finalObject = {};
            for (let i of qsa(`.multi-field.${tabKey}`)) {
              if (i.className === "multi-field hidden") {
                continue;
              }
              // trying to do something different
              var obj = JSON.parse(
                JSON.stringify(postData[tabKey][key].secLevelOptions)
              );
              let feature = qse(i, "feature")[0].value;
              let method = qse(i, "method")[0].value;
              obj.selectedValue = method;
              for (let j = 0; j < obj.optioncfgs.length; j++) {
                for (const subKey in obj.optioncfgs[j]) {
                  if (
                    obj.optioncfgs[j][subKey].range == 0 &&
                    obj.optioncfgs[j][subKey].type == undefined
                  ) {
                    obj.optioncfgs[j][subKey].values = qsei(
                      i,
                      `${key}-subcfg-${subKey}`
                    )[0].value;
                  }
                  if (
                    obj.optioncfgs[j][subKey].range == 0 &&
                    obj.optioncfgs[j][subKey].type == "option"
                  ) {
                    obj.optioncfgs[j][subKey].selectedValue = qsei(
                      i,
                      `${key}-subcfg-${subKey}`
                    )[0].value;
                  }
                  if (obj.optioncfgs[j][subKey].range == 1) {
                    for (const order of obj.optioncfgs[j][subKey]["order"]) {
                      obj.optioncfgs[j][subKey].values[order] = qsei(
                        i,
                        `${key}-subcfg-${subKey}-${order}`
                      )[0].value;
                    }
                  }
                  if (
                    obj.optioncfgs[j][subKey].range == 0 &&
                    obj.optioncfgs[j][subKey].type == "clist"
                  ) {
                    let clistValue = setIfEmptyValue(
                      qsei(i, `${key}-subcfg-${subKey}`)[0].value,
                      subKey.toUpperCase(),
                      tabLabel
                    );
                    let lastIndex = clistValue.length - 1;
                    // Removing comma, if last character is comma
                    clistValue =
                      clistValue[lastIndex] == ","
                        ? clistValue.slice(0, -1)
                        : clistValue;
                    obj.optioncfgs[j][subKey].values = clistValue
                      .split(",")
                      .map((n) => Number(n));
                  }
                  if (
                    obj.optioncfgs[j][subKey].range == 0 &&
                    obj.optioncfgs[j][subKey].type == "mclist"
                  ) {
                    let mclistValue = setIfEmptyValue(
                      qsei(i, `${key}-subcfg-${subKey}`)[0].value,
                      subKey.toUpperCase(),
                      tabLabel
                    );
                    let st = mclistValue;
                    let st2 = st.split("\n");
                    let ans = [];
                    let mid = [];
                    let final = [];
                    for (let i = 0; i < st2.length; i++) {
                      if (st2[i] == "") continue;
                      ans.push(st2[i].split(","));
                      for (let j = 0; j < ans.length; j++) {
                        for (let k = 0; k < ans[j].length; k++) {
                          if (ans[j][k] == "") continue;
                          mid.push(Number(ans[j][k]));
                        }
                      }
                      final.push(mid);
                      mid = [];
                      ans = [];
                    }
                    obj.optioncfgs[j][subKey].values = final;
                  }
                }
              }
              let object = {};
              object[feature] = obj;
              finalObject = Object.assign(finalObject, object);
            }
            postData[tabKey][key].user_inputs = finalObject;
          }
        }
      }
      return postData[tabKey];
    } else if (initialDataObject["DE"]) {
      let postData = initialDataObject["DE"];
      let featureCards = qsa(`.ediv.${tabKey} .feature-card`);
      featureCards.forEach((x) => {
        let feature = x.getAttribute("feature");
        if (x.hasClass("hidden")) {
          return;
        }
        if (!x.qs(".feature-customiser").hasClass("feature-selected")) {
          return;
        }
        let method = x.qs(`#${tabKey}-${feature}-input`).value;
        let type = x.qs("#varType").getAttribute("data-text");

        if (postData.featureInfo[feature] == undefined) {
          postData.featureInfo[feature] = {};
        }
        if (postData.featureInfo[feature][tabKey] == undefined) {
          postData.featureInfo[feature][tabKey] = {};
        }

        postData.featureInfo[feature][tabKey].method = method;
        postData.featureInfo[feature]["type"] = type;
        let optioncfgObject = postData.optionInfo[tabKey].optioncfgs;
        for (let i = 0; i < optioncfgObject.length; i++) {
          for (const subkey in optioncfgObject[i]) {
            if (subkey == method) {
              if (
                optioncfgObject[i][method].range == 0 &&
                optioncfgObject[i][method].type == undefined
              ) {
                postData.featureInfo[feature][tabKey].custom = setIfEmptyValue(
                  x.qs(`[id="${feature}-subcfg-${method}"]`).value,
                  method.toUpperCase(),
                  tabLabel,
                  feature
                );
              }
              if (
                optioncfgObject[i][method].range == 0 &&
                optioncfgObject[i][method].type == "option"
              ) {
                postData.featureInfo[feature][tabKey].selectedValue =
                  setIfEmptyValue(
                    x.qs(`[id="${feature}-subcfg-${method}"]`).value,
                    method.toUpperCase(),
                    tabLabel,
                    feature
                  );
              }
              if (optioncfgObject[i][method].range == 1) {
                for (const order of optioncfgObject[i][method].order) {
                  postData.featureInfo[feature][tabKey][order] =
                    setIfEmptyValue(
                      x.qs(`[id="${feature}-subcfg-${method}-${order}"]`).value,
                      method.toUpperCase(),
                      tabLabel,
                      feature
                    );
                }
              }
              if (
                optioncfgObject[i][method].range == 0 &&
                optioncfgObject[i][method].type == "clist"
              ) {
                let clistValue = setIfEmptyValue(
                  x.qs(`[id="${feature}-subcfg-${method}"]`).value,
                  method.toUpperCase(),
                  tabLabel,
                  feature
                );
                let lastIndex = clistValue.length - 1;
                // Removing comma, if last character is comma
                clistValue =
                  clistValue[lastIndex] == ","
                    ? clistValue.slice(0, -1)
                    : clistValue;
                postData.featureInfo[feature][tabKey].values = clistValue
                  .split(",")
                  .map((n) => Number(n));
              }
              if (
                optioncfgObject[i][method].range == 0 &&
                optioncfgObject[i][method].type == "mclist"
              ) {
                let mclistValue = setIfEmptyValue(
                  x.qs(`${feature}-subcfg-${method}`).value,
                  method.toUpperCase(),
                  tabLabel,
                  feature
                );
                let st = mclistValue;
                let st2 = st.split("\n");
                let ans = [];
                let mid = [];
                let final = [];
                for (let i = 0; i < st2.length; i++) {
                  if (st2[i] == "") continue;
                  ans.push(st2[i].split(","));
                  for (let j = 0; j < ans.length; j++) {
                    for (let k = 0; k < ans[j].length; k++) {
                      if (ans[j][k] == "") continue;
                      mid.push(Number(ans[j][k]));
                    }
                  }
                  final.push(mid);
                  mid = [];
                  ans = [];
                }
                postData.featureInfo[feature][tabKey].values = final;
              }
            }
          }
        }
      });
      return postData;
    }
  };

  /**
   * @function createPostObject
   * @description creates final data object based on the user's input to make post request to API.
   * @param initialDataObject Default Data object received from initial API call.
   * @private
   */
  var createPostObject = function (initialDataObject) {
    let postData = {};
    let errorHappened = false;
    let errorMsg = "";

    if (importedFlag) {
      initialDataObject["DE"].featureInfo = {};
    }

    // Sets 'errorHappened' to true if value passed is equal to ""(empty value).
    let setIfEmptyValue = (value, sub, tab, feature = null) => {
      if (value == "") {
        errorHappened = true;
        if (feature) {
          errorMsg = `Please fill a value for ${sub} in ${tab} Tab for ${feature}.`;
        } else {
          errorMsg = `Please fill a value for ${sub} in ${tab} Tab.`;
        }
      }
      return value;
    };

    // Performs bound check on numerical input in 'cdi' and 'sampling' subtab.
    let boundCheck = (value, min, max, arg) => {
      if (value == "") {
        errorHappened = true;
        errorMsg = `Please fill all values in ${arg}.`;
      }
      if (value < min || value > max) {
        errorHappened = true;
        errorMsg = `Entered Value of ${arg} is not between ${min} and ${max}.`;
      }
      return value;
    };

    // Collecting data of headers
    postData["headers"] = initialDataObject.headers;
    postData["tabinfo"] = initialDataObject.tabinfo;

    // Setting value of 'userCfgd' to true.
    postData["userCfgd"] = true;

    for (let tab of initialDataObject.tabinfo) {
      let currentTab = tab.label_key;
      let tabStyle = tab.tab_style;
      const isCurrentTabEnabled = tab.enabled !== false;
      // processing all objects inside sampling tab.
      if (currentTab == "sampling") {
        for (let v in initialDataObject) {
          if (v == "cdi") {
            // Collecting data from cdi subtab.
            postData["cdi"] = initialDataObject.cdi;
            if (isCurrentTabEnabled) {
              postData["cdi"].enabled = qs(
                "#cdi-container .select-input-container select"
              ).value;
              let incrateValue = qs(
                "#cdi-container .input-container input"
              ).value;
              postData["cdi"].incrate = Number(
                setIfEmptyValue(
                  incrateValue,
                  "Incidence Rate",
                  "Curate Data Imbalance"
                )
              );
              postData["cdi"].priorProb = Number(postData["cdi"].incrate);

              boundCheck(
                qs(
                  ".single-input-box-block.incidence-rate .input-container input"
                ).value,
                initialDataObject.cdi.minIncrateValue,
                initialDataObject.cdi.maxIncrateValue,
                "Incidence Rate in Curate Data Imbalance Tab"
              );
            }
          } else if (v == "sampling") {
            // Collecting data from sampling subtab.
            // ---------------------------------------
            // Reverting back the value of 'initialDataObject.sampling' to it's original state (structure that is given by api), i.e the object pointed by
            // key 'sampling' inside data for sampling tab.
            // CURRENTLY -->
            // initialDataObject = {sampling : {cdi : {}, sampling: {}}, FE : {}, MODELLING : {}, ...} (this structure was required for proper rendering of ui)
            // AFTER IF STATEMENT EXECUTION -->
            // initialDataObject = {cdi : {}, sampling: {}, FE : {}, MODELLING : {}, ...} (originally received from api)
            if (initialDataObject.sampling.sampling) {
              initialDataObject.sampling = initialDataObject.sampling.sampling;
            }
            postData["sampling"] = initialDataObject.sampling;
            if (isCurrentTabEnabled) {
              postData["sampling"].enabled = qs(
                "#sampling-container .select-input-container select"
              ).value;
              postData["sampling"].testsplit = setIfEmptyValue(
                qs("#ip2").value,
                "Train-Test Split",
                "Sampling"
              );

              // Bound check on train-split input box
              let inputBox = qs(
                ".single-input-box-block.train-test-split .input-container input"
              );
              boundCheck(
                inputBox.value,
                100 - initialDataObject.sampling.maxTestsplit,
                100 - initialDataObject.sampling.minTestsplit,
                "Train-Test Split in Sampling Tab"
              );
            }
          } else {
            // line number 454, 455, 456 are just done to maintain the structure of the api response object.
            // Do not concern about the efficiency of these three statements.

            let tempData = {};
            tempData[v] = {};
            tempData[v][v] = initialDataObject[v];
            if (
              isCurrentTabEnabled &&
              initialDataObject.headers.find(
                (header) =>
                  header.tab_map === currentTab && header.label_key === v
              )
            ) {
              postData[v] = saveHorizontalEntrytabData(
                tempData,
                v,
                setIfEmptyValue,
                boundCheck,
                tab.name,
                currentTab
              )[v];
            } else {
              postData[v] = initialDataObject[v];
            }
          }
        }
      } else if (currentTab == "FE") {
        if (!isCurrentTabEnabled) {
          postData[currentTab] = initialDataObject[currentTab];
        } else {
          postData["FE"] = saveHorizontalEntrytabData(
            initialDataObject,
            currentTab,
            setIfEmptyValue,
            boundCheck,
            tab.name
          );
        }
      } else if (tabStyle == "de") {
        postData["DE"] = saveVerticalEntryTabData(
          initialDataObject,
          currentTab,
          setIfEmptyValue,
          boundCheck,
          tab.name
        );
      } else {
        if (!isCurrentTabEnabled) {
          postData[currentTab] = initialDataObject[currentTab];
        } else {
          postData[currentTab] = saveVerticalEntryTabData(
            initialDataObject,
            currentTab,
            setIfEmptyValue,
            boundCheck,
            tab.name
          );
        }
      }
    }
    // if there is an error happened the value of 'errorHappened' will be true and appropriate data object will be returned.
    return errorHappened
      ? { validData: false, msg: errorMsg }
      : { validData: true, data: postData };
  };

  /**
   * @function validateMClistInput
   * @description Sets event listner on mclist input field for input validation.
   * @private
   */
  var validateMClistInput = function () {
    // checks if input character is a valid character
    // Replace this with proper Regex.
    function isValidChar(character) {
      let validCharset = "1234567890,.\n";
      for (let v of validCharset) {
        if (v == character) {
          return true;
        }
      }
      return false;
    }
    var validator = (evt) => {
      let currentValue = evt.target.value;
      let validInput = "";
      if (
        currentValue[0] == "," ||
        currentValue[0] == "\n" ||
        currentValue[0] == "."
      ) {
        currentValue = currentValue.slice(1);
      }
      for (let i = 0; i < currentValue.length - 1; i++) {
        // If input contains two consecutive commas, only one will be considered.
        if (currentValue[i] == "," && currentValue[i + 1] == ",") {
          continue;
        }
        if (currentValue[i] == "\n" && currentValue[i + 1] == "\n") {
          continue;
        }
        if (currentValue[i] == "." && currentValue[i + 1] == ".") {
          continue;
        }
        if (currentValue[i] == "." && currentValue[i + 1] == ",") {
          continue;
        }
        if (currentValue[i] == "," && currentValue[i + 1] == ".") {
          continue;
        }
        if (currentValue[i] == "." && currentValue[i + 1] == "\n") {
          continue;
        }
        if (currentValue[i] == "\n" && currentValue[i + 1] == ".") {
          continue;
        }
        if (isValidChar(currentValue[i])) {
          // If character is invalid, it will not be added in the final valid input
          validInput = validInput + currentValue[i];
        }
      }
      // Checking last character, if it is valid or not.
      if (isValidChar(currentValue[currentValue.length - 1])) {
        validInput = validInput + currentValue[currentValue.length - 1];
      }
      evt.target.value = validInput;
    };
    let mclist = qsa(".mclist-ip");
    if (mclist != undefined) {
      mclist.forEach((ele) => {
        ele.addEventListener("input", validator);
      });
    }
  };

  /**
   * @function setupCdiTabInputs
   * @description This function setups and handle the input events in cdi tab.
   * @private
   */
  var setupCdiTabInputs = function () {
    let slider = qs("#cdi-container .range-input-container input");
    let incidenceInput = qs("#cdi-container .input-container input");
    let rangeInputLabel = qs("#cdi-container span.range-input-value");

    incidenceInput.value = rangeInputLabel.innerHTML;
    slider.value = rangeInputLabel.innerHTML;
    generateChart([
      { name: "YES", score: slider.value },
      { name: "NO", score: 100 - slider.value },
    ]);

    // key : Key is used to track which element's event handler called this function.
    let changeValue = function (value, key) {
      value = Number(value);
      if (key != "input") {
        incidenceInput.value = value;
      }
      if (key != "slider") {
        slider.value = value;
      }
      rangeInputLabel.innerHTML = value;
      generateChart([
        { name: "YES", score: value },
        { name: "NO", score: 100 - value },
      ]);
    };
    slider.addEventListener("input", (evt) => {
      changeValue(evt.target.value, "slider");
    });
    incidenceInput.addEventListener("input", (evt) => {
      changeValue(evt.target.value, "input");
    });
  };

  /**
   * @function setupSamplingTabInputs
   * @description This function setups and handle the input events in sampling tab.
   * @private
   */
  var setupSamplingTabInputs = function () {
    let slider = qs("#sampling-container .range-input-container input");
    let leftLabel = qs(
      "#sampling-container .range-input-container .label-segments .left"
    );
    let rightLabel = qs(
      "#sampling-container .range-input-container .label-segments .right"
    );
    let leftSeg = qs(
      "#sampling-container .range-input-container .split-bar .left"
    );
    let rightSeg = qs(
      "#sampling-container .range-input-container .split-bar .right"
    );
    let leftIp = qs("#ip1");
    let rightIp = qs("#ip2");

    let sliderValue = slider.value;
    // key : Key is used to track which element's event handler called this function.
    let changeValue = function (value, key) {
      value = Number(value);
      leftLabel.innerHTML = `${value} %`;
      rightLabel.innerHTML = `${100 - value} %`;
      leftSeg.style.width = `${value}%`;
      rightSeg.style.width = `${100 - value}%`;
      if (key != "leftIp") {
        leftIp.value = value;
      }
      if (key != "rightIp") {
        rightIp.value = 100 - value;
      }
      if (key != "slider") {
        slider.value = value;
      }
    };
    changeValue(sliderValue, "");
    slider.addEventListener("input", (evt) => {
      changeValue(evt.target.value, "slider");
    });
    leftIp.addEventListener("input", (evt) => {
      changeValue(evt.target.value, "leftIp");
    });
    rightIp.addEventListener("input", (evt) => {
      changeValue(100 - evt.target.value, "rightIp");
    });
  };

  /**
   * @function setupCloningModelDevelopmentTab
   * @description This function setups and handle the cloning in Modelling tab.
   * @private
   */
  var setupCloningModelDevelopmentTab = function () {
    // cloning in model development
    let ind = 1;
    qs(".ediv.MODELLING").addEventListener("click", (event) => {
      let key = event.target.getAttribute("key");

      // allows cloning of the respective container on clone icon click
      if (event.target.className === "clone-icon") {
        let keyInData = `${key}-C${ind}`;
        if (data["MODELLING"][keyInData]) {
          ind++;
        }
        let heading = qs(`[id="${key}-title"]`).innerText;
        let referenceNode = qs(`[id="${key}-input-container"]`);
        let node1 = event.target.parentElement.parentElement.cloneNode(true);
        node1.qs(".clone").remove();
        let node2 = qs(`[id="${key}-input-container"]`).cloneNode(true);
        let removeButton = `<div class="delete-clone"><span class="delete-clone-icon" tooltip="Remove" flow="up"></span></div>`;
        node1.innerHTML += removeButton;
        node1.addClass(`${key}-cloned-${ind}`);
        node1.setAttribute("key", key);
        node1.qs(".toggle").addClass("hidden");
        node1.qs(".edit-title").removeClass("hidden");
        node2.addClass(`${key}-cloned-${ind}`);
        node1.qs(".title").id = `${key}-title`;
        node1.qs(".title").innerText = heading + ` clone ${ind}`;
        node1.qs(".title").removeClass("disabled");
        node1.qs(".title").setAttribute("contentEditable", false);
        node1.qs(".title").addClass("cloned-title");
        node1.style.display = "flex";
        let node = qs(".model-config-row-container").cloneNode(true);
        node.innerHTML = "";
        node.className = "";
        node.setAttribute("key", key);
        node.addClass(`cloned-container`);
        node.addClass(`${key}-cloned-container`);
        node.appendChild(node1);
        node.appendChild(node2);
        referenceNode.parentNode.insertBefore(node, referenceNode.nextSibling);
        ind++;
      }

      // allows deletion of the cloned container on click of delete icon
      if (event.target.className === "delete-clone-icon") {
        event.target.parentElement.parentElement.parentElement.remove();
      }

      // allows editing of the title of cloned container on click on pencil icon
      if (event.target.className === "edit-icon") {
        const clonedHeadingContainer = event.target.parentElement.parentElement;
        existingClonedHeading = clonedHeadingContainer.qs(".title").innerText;
        clonedHeadingContainer
          .qs(".title")
          .setAttribute("contentEditable", true);
        clonedHeadingContainer.qs(".title").focus();
        clonedHeadingContainer.qs(".save-title").removeClass("hidden");
        clonedHeadingContainer.qs(".unsave-title").removeClass("hidden");
        event.target.parentElement.addClass("hidden");
      }

      if (event.target.className === "save-icon") {
        const clonedHeadingContainer = event.target.parentElement.parentElement;
        clonedHeadingContainer
          .qs(".title")
          .setAttribute("contentEditable", false);
        clonedHeadingContainer.qs(".save-title").addClass("hidden");
        clonedHeadingContainer.qs(".unsave-title").addClass("hidden");
        clonedHeadingContainer.qs(".edit-title").removeClass("hidden");
        currentTitle = clonedHeadingContainer.qs(".title").innerText;
        let clonedTitles = qsa(".model-config-row-container .title");
        var restrictUpdate = false;
        for (let clonedTitle of clonedTitles) {
          if (
            clonedTitle.innerText === currentTitle &&
            clonedTitle !== clonedHeadingContainer.qs(".title")
          ) {
            restrictUpdate = true;
            break;
          }
        }
        if (restrictUpdate) {
          clonedHeadingContainer.qs(".title").innerText = existingClonedHeading;
        } else {
          existingClonedHeading = currentTitle;
        }
      }

      if (event.target.className === "cross-icon") {
        const clonedHeadingContainer = event.target.parentElement.parentElement;
        clonedHeadingContainer.qs(".title").innerText = existingClonedHeading;
        clonedHeadingContainer
          .qs(".title")
          .setAttribute("contentEditable", false);
        clonedHeadingContainer.qs(".save-title").addClass("hidden");
        clonedHeadingContainer.qs(".unsave-title").addClass("hidden");
        clonedHeadingContainer.qs(".edit-title").removeClass("hidden");
      }
    });
    let flag = false;
    // checking if there is a tab with olt style and setting its scroll to unset , as scroll of only multi-field should remain active
    for (let i of data.tabinfo) {
      if (i.tab_style == "olt") {
        flag = true;
        qs(`.ediv.${i.label_key}`).style.overflowY = "unset";
      }
    }
  };

  /**
   * @function controlContentOfToggle
   * @description controlContentOfToggle handles the hidding and unhidding of the title, description and input-container attached to a toggle button.
   * @private
   */
  var controlContentOfToggleInput = function (id, tabStyle) {
    // tabStyle is used to determine that toggle button lies in which tab so that appropriate action can be taken.
    let toggleId = id;
    let toggle = qs(`#${toggleId}`);
    let desc = qs(`#${toggleId}-desc`);
    if (toggle.checked) {
      // If tabStyle is HR then, enabling all input and selct elements inside FE-input-container.
      if (tabStyle == "HR") {
        let inputElements = qsa(`#${toggleId}-input-container input`);
        let selectElements = qsa(`#${toggleId}-input-container select`);
        inputElements.forEach((element, index) => {
          inputElements[index].disabled = false;
        });
        selectElements.forEach((element, index) => {
          selectElements[index].disabled = false;
        });
      } else {
        qs(`#${toggleId}-input-container`).style.display = "flex";
        var cloneObject = qs(`#${toggleId}-clone`);
        if (cloneObject) {
          cloneObject.style.display = "flex";
        }
      }
      qs(`#${toggleId}-title`).removeClass("disabled");
      if (desc != undefined) {
        desc.removeClass("disabled");
      }
    } else {
      // If tabStyle is HR then, disabling all input and select elements inside.
      if (tabStyle == "HR") {
        let inputElements = qsa(`#${toggleId}-input-container input`);
        let selectElements = qsa(`#${toggleId}-input-container select`);
        inputElements.forEach((element, index) => {
          inputElements[index].disabled = true;
        });
        selectElements.forEach((element, index) => {
          selectElements[index].disabled = true;
        });
      } else {
        qs(`#${toggleId}-input-container`).style.display = "none";
        var cloneObject = qs(`#${toggleId}-clone`);
        if (cloneObject) {
          cloneObject.style.display = "none";
          qsa(`.${toggleId}-cloned-container`).forEach((x) => x.remove());
        }
      }
      qs(`#${toggleId}-title`).addClass("disabled");
      if (desc != undefined) {
        desc.addClass("disabled");
      }
    }
  };

  /**
   * @function registerEventListenerOnTableButtons
   * @description registers the event listener on the feature table of de style tab.
   * @private
   */
  var registerEventListenerOnTableButtons = function () {
    // event listener on edit feature
    qsa(".feature-edit-btn").forEach((x) =>
      x.addEventListener("click", (evt) => {
        let tab = evt.target.getAttribute("tab");
        let index = evt.target.getAttribute("index");
        const customiser = qs(`.${tab}-customiser-${index}`);
        customiser.removeClass("hidden");
        evt.target.parentElement.addClass("hidden");
        const key = customiser.getAttribute("key");
        const value = customiser.qs("#" + tab + "-" + key + "-input").value;
        customiser.qsa(".optioncfgs").forEach((opt) => {
          let correctKey = opt.getAttribute("key");
          if (correctKey == value) {
            opt.removeClass("hidden");
          } else {
            opt.addClass("hidden");
          }
        });
        let ttIdentifier = customiser
          .qs("#" + tab + "-" + key + "-input")
          .getAttribute("ttIdentifier");
        let tooltips;
        if (ttIdentifier) {
          tooltips = qsa(`.tooltip-${ttIdentifier}-container`);
        }
        if (tooltips) {
          tooltips.forEach((x) => {
            let tooltipId = x.getAttribute("id");
            if (tooltipId == value) {
              x.removeClass("hidden");
            } else {
              x.addClass("hidden");
            }
          });
        }
      })
    );

    // event listenr on close button in edit feature
    qsa(".feature-customiser .close-btn").forEach((x) =>
      x.addEventListener("click", (evt) => {
        let tab = evt.target.parentElement.getAttribute("tab");
        let feature = evt.target.parentElement.getAttribute("feature");
        let featureInFeatureTableData = JSON.parse(
          JSON.stringify(
            my.featureTableData[tab].find((v) => v.feature === feature)
          )
        );
        let methodSelected = featureInFeatureTableData.method;
        qs(`#${tab}-${feature}-input`).value = methodSelected;
        let row = evt.target.parentElement.parentElement.parentElement;
        let selectedOptioncfg = null;
        for (
          let index = 0;
          index < featureInFeatureTableData.optioncfgs.length;
          index++
        ) {
          if (
            Object.keys(featureInFeatureTableData.optioncfgs[index])[0] ==
            methodSelected
          ) {
            selectedOptioncfg = featureInFeatureTableData.optioncfgs[index];
            break;
          }
        }
        row.qsa(".optioncfgs").forEach((optioncfg) => {
          if (!optioncfg.hasClass("hidden")) {
            optioncfg
              .qsa(".single-input-box input")
              .forEach((optioncfgInput) => {
                let optype = optioncfgInput.getAttribute("optype");
                let uiOptioncfg = optioncfg.qs(
                  `[id="${feature}-subcfg-${methodSelected}-${optype}"]`
                );
                if (uiOptioncfg) {
                  uiOptioncfg.value =
                    selectedOptioncfg[methodSelected].values[optype];
                } else {
                  uiOptioncfg = optioncfg.qs(
                    `[id="${feature}-subcfg-${methodSelected}"]`
                  );
                  if (uiOptioncfg) {
                    uiOptioncfg.value =
                      selectedOptioncfg[methodSelected].values;
                  }
                }
              });
            optioncfg
              .qsa(".single-input-box select")
              .forEach((optioncfgInput) => {
                let optype = optioncfgInput.getAttribute("optype");
                let uiOptioncfg = optioncfg.qs(
                  `[id="${feature}-subcfg-${methodSelected}-${optype}"]`
                );
                if (!uiOptioncfg) {
                  uiOptioncfg = optioncfg.qs(
                    `[id="${feature}-subcfg-${methodSelected}"]`
                  );
                }
                if (uiOptioncfg) {
                  uiOptioncfg.value =
                    selectedOptioncfg[methodSelected].selectedValue;
                }
              });
          }
        });
        evt.target.parentElement.parentElement.addClass("hidden");
        evt.target.parentElement.parentElement.parentElement
          .qs(".edit-panel")
          .removeClass("hidden");
      })
    );

    // event listener on tick button in edit feature
    qsa(".feature-customiser .tick-btn").forEach((x) =>
      x.addEventListener("click", (evt) => {
        let tab = evt.target.parentElement.getAttribute("tab");
        let feature = evt.target.parentElement.getAttribute("feature");
        let row = evt.target.parentElement.parentElement.parentElement;
        let featureMethodSelected = qs(`#${tab}-${feature}-input`).value;
        let featureInFilteredData = filteredFeatureTableData[tab].find(
          (v) => v.feature === feature
        );
        featureInFilteredData.method = featureMethodSelected;
        featureInFilteredData.modified = true;
        let featureInSearchData = null;
        if (Object.keys(searchTableData).length != 0) {
          featureInSearchData = searchTableData[tab].find(
            (v) => v.feature === feature
          );
        }
        if (featureInSearchData) {
          featureInSearchData.method = featureMethodSelected;
        }
        // making change in the data which is used to render the feature table
        // console.log(JSON.parse(JSON.stringify(my.featureTableData)));
        let featureInFeatureTableData = my.featureTableData[tab].find(
          (v) => v.feature === feature
        );
        featureInFeatureTableData.method = featureMethodSelected;
        featureInFeatureTableData.modified = true;
        row.qs("#feature-method").innerText = featureMethodSelected;
        row.qsa(".optioncfgs").forEach((optioncfg) => {
          if (!optioncfg.hasClass("hidden")) {
            optioncfg
              .qsa(".single-input-box input")
              .forEach((optioncfgInput) => {
                let optype = optioncfgInput.getAttribute("optype");
                let selectedOptioncfg = optioncfg.qs(
                  `[id="${feature}-subcfg-${featureMethodSelected}-${optype}"]`
                );
                if (selectedOptioncfg) {
                  for (
                    let index = 0;
                    index < featureInFeatureTableData.optioncfgs.length;
                    index++
                  ) {
                    if (
                      Object.keys(
                        featureInFeatureTableData.optioncfgs[index]
                      )[0] == featureMethodSelected
                    ) {
                      featureInFeatureTableData.optioncfgs[index][
                        featureMethodSelected
                      ].values[optype] = selectedOptioncfg.value;
                      featureInFilteredData.optioncfgs[index][
                        featureMethodSelected
                      ].values[optype] = selectedOptioncfg.value;
                      if (featureInSearchData) {
                        featureInSearchData.optioncfgs[index][
                          featureMethodSelected
                        ].values[optype] = selectedOptioncfg.value;
                      }
                    }
                  }
                } else {
                  selectedOptioncfg = optioncfg.qs(
                    `[id="${feature}-subcfg-${featureMethodSelected}"]`
                  );
                  for (
                    let index = 0;
                    index < featureInFeatureTableData.optioncfgs.length;
                    index++
                  ) {
                    if (
                      Object.keys(
                        featureInFeatureTableData.optioncfgs[index]
                      )[0] == featureMethodSelected
                    ) {
                      featureInFeatureTableData.optioncfgs[index][
                        featureMethodSelected
                      ].values = selectedOptioncfg.value;
                      featureInFilteredData.optioncfgs[index][
                        featureMethodSelected
                      ].values = selectedOptioncfg.value;
                      if (featureInSearchData) {
                        featureInSearchData.optioncfgs[index][
                          featureMethodSelected
                        ].values = selectedOptioncfg.value;
                      }
                    }
                  }
                }
              });
            optioncfg
              .qsa(".single-input-box select")
              .forEach((optioncfgInput) => {
                let optype = optioncfgInput.getAttribute("optype");
                let selectedOptioncfg = optioncfg.qs(
                  `[id="${feature}-subcfg-${featureMethodSelected}-${optype}"]`
                );
                if (selectedOptioncfg) {
                  for (
                    let index = 0;
                    index < featureInFeatureTableData.optioncfgs.length;
                    index++
                  ) {
                    if (
                      Object.keys(
                        featureInFeatureTableData.optioncfgs[index]
                      )[0] == featureMethodSelected
                    ) {
                      featureInFeatureTableData.optioncfgs[index][
                        featureMethodSelected
                      ].selectedValue = selectedOptioncfg.value;
                      featureInFilteredData.optioncfgs[index][
                        featureMethodSelected
                      ].selectedValue = selectedOptioncfg.value;
                      if (featureInSearchData) {
                        featureInSearchData.optioncfgs[index][
                          featureMethodSelected
                        ].selectedValue = selectedOptioncfg.value;
                      }
                    }
                  }
                } else {
                  selectedOptioncfg = optioncfg.qs(
                    `[id="${feature}-subcfg-${featureMethodSelected}"]`
                  );
                  for (
                    let index = 0;
                    index < featureInFeatureTableData.optioncfgs.length;
                    index++
                  ) {
                    if (
                      Object.keys(
                        featureInFeatureTableData.optioncfgs[index]
                      )[0] == featureMethodSelected
                    ) {
                      featureInFeatureTableData.optioncfgs[index][
                        featureMethodSelected
                      ].selectedValue = selectedOptioncfg.value;
                      featureInFilteredData.optioncfgs[index][
                        featureMethodSelected
                      ].selectedValue = selectedOptioncfg.value;
                      if (featureInSearchData) {
                        featureInSearchData.optioncfgs[index][
                          featureMethodSelected
                        ].selectedValue = selectedOptioncfg.value;
                      }
                    }
                  }
                }
              });
          }
        });
        // console.log(JSON.parse(JSON.stringify(my.featureTableData)));
        evt.target.parentElement.parentElement.addClass("hidden");
        evt.target.parentElement.parentElement.addClass("feature-selected");
        row.qs(".edit-panel").removeClass("hidden");
      })
    );

    // event listener on the checkboxes for disabling the header checkbox
    qsa(".feature-checkbox-container .feature-checkbox-input").forEach((x) =>
      x.addEventListener("change", (evt) => {
        let tab = evt.target.getAttribute("tab");
        if (!x.checked) {
          qs(`.header-checkbox-input#${tab}`).checked = false;
        }
      })
    );
  };

  /**
   * @function registerEventListenerOnFilter
   * @description registers the event listener on the filter container of de style tab.
   * @private
   */
  var registerEventListenersOnFilter = function () {
    // event listener on apply filter
    qsa(".apply-filter-btn").forEach((x) =>
      x.addEventListener("click", (evt) => {
        let tab = evt.target.getAttribute("tab");
        let toggleButton = qs(
          `.ediv.${tab} .search-action-container .action-container .toggle`
        );
        let filterList = [];
        let isVartypePresent = false;
        qsa(`.ediv.${tab} .filter-select.filter-selected`).forEach((x) => {
          let obj = {
            key: x.id,
            value: x.value,
          };
          my.varType = x.value;
          filterList.push(obj);
          isVartypePresent = true;
        });
        qsa(`.ediv.${tab} .filter-input-container.filter-selected`).forEach(
          (x) => {
            let inputs = x.qsa(".filter-input");
            let min = parseFloat(inputs[0].value);
            let max = parseFloat(inputs[1].value);
            let obj = {
              key: inputs[0].id,
              min: min,
              max: max,
            };
            filterList.push(obj);
          }
        );
        // console.log(filterList);
        if (filterList.length > 0) {
          if (isVartypePresent) {
            toggleButton.parentElement.setAttribute("show-tooltip", "false");
            toggleButton.removeClass("disabled");
            toggleButton.addClass("active");
            toggleButton.qs("input").disabled = false;
          }
          filteredFeatureTableData = JSON.parse(
            JSON.stringify(my.featureTableData)
          );
          for (element of filterList) {
            if (element.key == "varType") {
              filteredFeatureTableData[tab] = filteredFeatureTableData[
                tab
              ].filter((feature) => feature.type == element.value);
            } else {
              filteredFeatureTableData[tab] = filteredFeatureTableData[
                tab
              ].filter(
                (feature) =>
                  feature[element.key] >= element.min &&
                  feature[element.key] <= element.max
              );
            }
          }
          // setting the search table data used in sorting and emptying the search box
          searchTableData = JSON.parse(
            JSON.stringify(filteredFeatureTableData)
          );
          qs(`.ediv.${tab} .search-action-container #search-field`).value = "";
          // rendering the filtered table
          let featureTable = createNode(
            featuretableTemplate({
              currentLabelKey: tab,
              completeData: data,
              expData: expData.data.posts[0],
              featureTableData: filteredFeatureTableData,
              tooltipData: tooltipData,
            })
          );
          qs(`.ediv.${tab} .feature-cards-container`).innerHTML = "";
          qs(`.ediv.${tab} .feature-cards-container`).appendChild(featureTable);
          registerEventListenerOnTableButtons();
          qsa(`.feature-card select`).forEach((ele) => {
            ele.addEventListener("change", handleVisibilityOfOptioncfgs);
          });
          // retaining the checkboxes as checked when filter is applied in bulk action mode
          if (toggleButton.qs("input").checked) {
            qsa(`.ediv.${tab} .feature-card .feature-checkbox`).forEach((x) => {
              x.removeClass("hidden");
              x.qs("input").checked = true;
            });
            qsa(`.ediv.${tab} .edit-panel`).forEach((x) =>
              x.addClass("hidden")
            );
          }
          let globalMethod = createNode(
            globalmethodTemplate({
              currentLabelKey: tab,
              variable: my.varType,
              completeData: JSON.parse(JSON.stringify(data)),
              tooltipData: tooltipData,
            })
          );
          let globalMethodContainer = qs(
            `.ediv.${tab} .global-method-container`
          );
          globalMethodContainer.innerHTML = "";
          globalMethodContainer.appendChild(globalMethod);
          globalMethodContainer
            .qs(`.tick-btn`)
            .addEventListener("click", (evt) => {
              // console.log(JSON.parse(JSON.stringify(filteredFeatureTableData)));
              let globalMethodSelected = globalMethodContainer.qs(
                `#${tab}-global-input`
              ).value;
              qsa(`.ediv.${tab} .feature-card`).forEach((x) => {
                if (x.qs(".feature-checkbox input").checked) {
                  let feature = x.getAttribute("feature");
                  let featureInFilteredData = filteredFeatureTableData[
                    tab
                  ].find((v) => v.feature === feature);
                  featureInFilteredData.method = globalMethodSelected;
                  featureInFilteredData.modified = true;
                  let featureInSearchData = null;
                  if (Object.keys(searchTableData).length != 0) {
                    featureInSearchData = searchTableData[tab].find(
                      (v) => v.feature === feature
                    );
                  }
                  if (featureInSearchData) {
                    featureInSearchData.method = globalMethodSelected;
                  }
                  // making change in the data which is used to render the feature table
                  let featureInFeatureTableData = my.featureTableData[tab].find(
                    (v) => v.feature === feature
                  );
                  featureInFeatureTableData.method = globalMethodSelected;
                  featureInFeatureTableData.modified = true;
                  x.qs(`#${tab}-${feature}-input`).value = globalMethodSelected;
                  globalMethodContainer
                    .qsa(".optioncfgs")
                    .forEach((optioncfg) => {
                      if (!optioncfg.hasClass("hidden")) {
                        optioncfg
                          .qsa(".single-input-box input")
                          .forEach((optioncfgInput) => {
                            let optype = optioncfgInput.getAttribute("optype");
                            let globalOptioncfg = optioncfg.qs(
                              `#global-subcfg-${globalMethodSelected}-${optype}`
                            );
                            if (globalOptioncfg) {
                              let featureOptioncfg = x.qs(
                                `#${feature}-subcfg-${globalMethodSelected}-${optype}`
                              );
                              featureOptioncfg.value = globalOptioncfg.value;
                              for (
                                let index = 0;
                                index <
                                featureInFeatureTableData.optioncfgs.length;
                                index++
                              ) {
                                if (
                                  Object.keys(
                                    featureInFeatureTableData.optioncfgs[index]
                                  )[0] == globalMethodSelected
                                ) {
                                  featureInFeatureTableData.optioncfgs[index][
                                    globalMethodSelected
                                  ].values[optype] = globalOptioncfg.value;
                                  featureInFilteredData.optioncfgs[index][
                                    globalMethodSelected
                                  ].values[optype] = globalOptioncfg.value;
                                  if (featureInSearchData) {
                                    featureInSearchData.optioncfgs[index][
                                      globalMethodSelected
                                    ].values[optype] = globalOptioncfg.value;
                                  }
                                }
                              }
                            } else {
                              globalOptioncfg = optioncfg.qs(
                                `#global-subcfg-${globalMethodSelected}`
                              );
                              let featureOptioncfg = x.qs(
                                `[id="${feature}-subcfg-${globalMethodSelected}"]`
                              );
                              featureOptioncfg.value = globalOptioncfg.value;
                              for (
                                let index = 0;
                                index <
                                featureInFeatureTableData.optioncfgs.length;
                                index++
                              ) {
                                if (
                                  Object.keys(
                                    featureInFeatureTableData.optioncfgs[index]
                                  )[0] == globalMethodSelected
                                ) {
                                  featureInFeatureTableData.optioncfgs[index][
                                    globalMethodSelected
                                  ].values = globalOptioncfg.value;
                                  featureInFilteredData.optioncfgs[index][
                                    globalMethodSelected
                                  ].values = globalOptioncfg.value;
                                  if (featureInSearchData) {
                                    featureInSearchData.optioncfgs[index][
                                      globalMethodSelected
                                    ].values = globalOptioncfg.value;
                                  }
                                }
                              }
                            }
                          });
                        optioncfg
                          .qsa(".single-input-box select")
                          .forEach((optioncfgInput) => {
                            let optype = optioncfgInput.getAttribute("optype");
                            let globalOptioncfg = optioncfg.qs(
                              `#global-subcfg-${globalMethodSelected}-${optype}`
                            );
                            if (globalOptioncfg) {
                              let featureOptioncfg = x.qs(
                                `[id="${feature}-subcfg-${globalMethodSelected}-${optype}"]`
                              );
                              featureOptioncfg.value = globalOptioncfg.value;
                              for (
                                let index = 0;
                                index <
                                featureInFeatureTableData.optioncfgs.length;
                                index++
                              ) {
                                if (
                                  Object.keys(
                                    featureInFeatureTableData.optioncfgs[index]
                                  )[0] == globalMethodSelected
                                ) {
                                  featureInFeatureTableData.optioncfgs[index][
                                    globalMethodSelected
                                  ].selectedValue = globalOptioncfg.value;
                                  featureInFilteredData.optioncfgs[index][
                                    globalMethodSelected
                                  ].selectedValue = globalOptioncfg.value;
                                  if (featureInSearchData) {
                                    featureInSearchData.optioncfgs[index][
                                      globalMethodSelected
                                    ].selectedValue = globalOptioncfg.value;
                                  }
                                }
                              }
                            } else {
                              globalOptioncfg = optioncfg.qs(
                                `#global-subcfg-${globalMethodSelected}`
                              );
                              let featureOptioncfg = x.qs(
                                `[id="${feature}-subcfg-${globalMethodSelected}"]`
                              );
                              featureOptioncfg.value = globalOptioncfg.value;
                              for (
                                let index = 0;
                                index <
                                featureInFeatureTableData.optioncfgs.length;
                                index++
                              ) {
                                if (
                                  Object.keys(
                                    featureInFeatureTableData.optioncfgs[index]
                                  )[0] == globalMethodSelected
                                ) {
                                  featureInFeatureTableData.optioncfgs[index][
                                    globalMethodSelected
                                  ].selectedValue = globalOptioncfg.value;
                                  featureInFilteredData.optioncfgs[index][
                                    globalMethodSelected
                                  ].selectedValue = globalOptioncfg.value;
                                  if (featureInSearchData) {
                                    featureInSearchData.optioncfgs[index][
                                      globalMethodSelected
                                    ].selectedValue = globalOptioncfg.value;
                                  }
                                }
                              }
                            }
                          });
                      }
                    });
                  x.qs("#feature-method").innerText = globalMethodSelected;
                  x.qs(".feature-customiser").addClass("feature-selected");
                }
              });
              evt.target.addClass("hidden");
            });
          handleVisibilityOfOptioncfgs({ target: qs(`#${tab}-global-input`) });
          qs(`.ediv.${tab} .global-method-container`).addEventListener(
            "change",
            handleVisibilityOfOptioncfgs
          );
          let numberOfCardsFiltered = qsa(`.ediv.${tab} .feature-card`).length;
          qs(`.ediv.${tab} .filter-results`).removeClass("hidden");
          qs(
            `.ediv.${tab} .filter-results`
          ).innerHTML = `<span>Showing <strong class="strong">${numberOfCardsFiltered}</strong> filtered results</span>`;
          qs(`.ediv.${tab} .search-box`).style.marginRight = "0";
        }
      })
    );

    // event listener on reset filter
    qsa(".reset-filter-btn").forEach((x) =>
      x.addEventListener("click", (evt) => {
        let tab = evt.target.getAttribute("tab");
        let toggleButton = qs(
          `.ediv.${tab} .search-action-container .action-container .toggle`
        );
        toggleButton.parentElement.setAttribute("show-tooltip", "true");
        toggleButton.addClass("disabled");
        toggleButton.removeClass("active");
        toggleButton.qs("input").checked = false;
        toggleButton.qs("input").disabled = true;
        let bulkText = qs(`.bulk-action.${tab}`);
        let singleText = qs(`.single-action.${tab}`);
        singleText.style.color = "#FFFFFF";
        bulkText.style.color = "rgba(255, 255, 255, 0.5)";
        qsa(`#${tab}-feature-container .edit-panel`).forEach((x) =>
          x.removeClass("hidden")
        );
        qsa(`#${tab}-feature-container .feature-customiser`).forEach((x) =>
          x.addClass("hidden")
        );
        qs(`.header-checkbox#${tab}`).addClass("hidden");
        qsa(`#${tab}-feature-container .feature-checkbox`).forEach((x) =>
          x.addClass("hidden")
        );
        qs(`.global-method-container#${tab}`).addClass("hidden");
        qsa(`.ediv.${tab} .feature-card`).forEach((x) =>
          x.removeClass("hidden")
        );
        qsa(".tick-and-wrong").forEach((x) => x.removeClass("hidden"));
        qs(`.ediv.${tab} .filter-select`).value = "Numerical";
        qsa(`.ediv.${tab} .filter-box`).forEach((filterBox) => {
          let filterInput = filterBox.qs(".filter-input");
          if (!filterInput) {
            return;
          }
          let filterParameter = filterInput.getAttribute("id");
          if (filterParameter == "vartype") {
            return;
          }
          filterBox.qs(`.filter-input.min#${filterParameter}`).value =
            minMaxObj[tab][filterParameter].min;
          if (!filterBox.qs(".slider-box.noUi-target")) return;
          if (minMaxObj[tab][filterParameter].max <= 2146644683) {
            filterBox.qs(`.filter-input.max#${filterParameter}`).value =
              minMaxObj[tab][filterParameter].max;
            filterBox
              .qs(".slider-box.noUi-target")
              .noUiSlider.set([
                filterBox.qs(`.filter-input.min#${filterParameter}`).value,
                filterBox.qs(`.filter-input.max#${filterParameter}`).value,
              ]);
          } else {
            filterBox.qs(`.filter-input.max#${filterParameter}`).value =
              minMaxObj[tab][filterParameter].max;
            filterBox
              .qs(".slider-box.noUi-target")
              .noUiSlider.set([
                filterBox.qs(`.filter-input.min#${filterParameter}`).value,
                10,
              ]);
          }
        });
        inputBoxes = qsa(`.ediv.${tab} .filter-input-container`);
        selectBoxes = qsa(`.ediv.${tab} .filter-select`);
        inputBoxes.forEach((inputBox) =>
          inputBox.removeClass("filter-selected")
        );
        selectBoxes.forEach((selectBox) =>
          selectBox.removeClass("filter-selected")
        );

        let featureTable = createNode(
          featuretableTemplate({
            currentLabelKey: tab,
            completeData: data,
            expData: expData.data.posts[0],
            featureTableData: my.featureTableData,
            tooltipData: tooltipData,
          })
        );
        qs(`.ediv.${tab} .feature-cards-container`).innerHTML = "";
        qs(`.ediv.${tab} .feature-cards-container`).appendChild(featureTable);
        registerEventListenerOnTableButtons();
        qsa(`.feature-card select`).forEach((ele) => {
          ele.addEventListener("change", handleVisibilityOfOptioncfgs);
        });
        qs(`.ediv.${tab} .filter-results`).addClass("hidden");
        qs(`.ediv.${tab} .search-box`).style.marginRight = "auto";
        qsa(`.ediv.${tab} .filter-checkbox-container input`).forEach(
          (x) => (x.checked = false)
        );
        filteredFeatureTableData = JSON.parse(
          JSON.stringify(my.featureTableData)
        );
        // setting the search table data used in sorting and emptying the search box
        searchTableData = {};
        qs(`.ediv.${tab} .search-action-container #search-field`).value = "";
      })
    );

    // event listener on header checkbox of feature table
    qsa(".header-checkbox input").forEach((x) =>
      x.addEventListener("change", (evt) => {
        let tab = evt.target.getAttribute("id");
        if (x.checked) {
          qs(`#${tab}-feature-container`)
            .qsa(".feature-checkbox input")
            .forEach((y) => (y.checked = "true"));
        } else {
          qs(`#${tab}-feature-container`)
            .qsa(".feature-checkbox input")
            .forEach((z) => {
              z.checked = false;
            });
        }
      })
    );

    // event listener on filter checkbox
    qsa(".filter-checkbox input").forEach((x) =>
      x.addEventListener("change", (evt) => {
        let tab = evt.target.getAttribute("id");
        let feature = evt.target.getAttribute("feature");
        let inputBox = qs(`.ediv.${tab} .filter-input-container#${feature}`);
        let selectBox = qs(`.ediv.${tab} .filter-select#${feature}`);
        if (x.checked) {
          if (inputBox) {
            inputBox.addClass("filter-selected");
          }
          if (selectBox) {
            selectBox.addClass("filter-selected");
          }
        } else {
          if (inputBox) {
            inputBox.removeClass("filter-selected");
          }
          if (selectBox) {
            selectBox.removeClass("filter-selected");
          }
        }
      })
    );

    // On Filter inputs
    qsa(".filter-input-container .filter-input").forEach((inputBox) => {
      inputBox.addEventListener("change", (evt) => {
        evt.target.parentElement
          .qs(".slider-box")
          .noUiSlider.set([
            evt.target.parentElement.qs(".min").value,
            evt.target.parentElement.qs(".max").value,
          ]);
      });
    });

    qsa(".filter-input-container .slider-container").forEach((container) => {
      window.addEventListener("mouseup", (event) => {
        if (event.target != container && !container.contains(event.target)) {
          let filterInputContainer = qsa(".filter-input-container");
          if (filterInputContainer) {
            filterInputContainer.forEach((x) => x.removeClass("focused"));
          }
          container.addClass("hidden");
        }
      });
    });

    qsa(".filter-input-container .slider-box").forEach((input) => {
      let tab = input.parentElement.parentElement.getAttribute("tab");
      let feature = input.parentElement.parentElement.getAttribute("feature");
      if (minMaxObj[tab][feature].max <= 2146644683) {
        try {
          noUiSlider.create(input, {
            start: [minMaxObj[tab][feature].min, minMaxObj[tab][feature].max],
            connect: true,
            range: {
              min: minMaxObj[tab][feature].min,
              max: minMaxObj[tab][feature].max,
            },
            step: 1,
            format: {
              to: (v) => v | 0,
              from: (v) => v | 0,
            },
          });
        } catch (err) {
          // console.log(err);
        }
        input.noUiSlider.on("update", function (values) {
          if (tab && feature) {
            qs(`.${tab}-${feature}-min`).value = values[0];
            qs(`#${tab}-${feature}-input .min-val`).innerText = values[0];
            qs(`.${tab}-${feature}-max`).value = values[1];
            qs(`#${tab}-${feature}-input .max-val`).innerText = values[1];
          }
        });
      } else {
        try {
          noUiSlider.create(input, {
            start: [minMaxObj[tab][feature].min, 10],
            connect: true,
            range: {
              min: 0,
              max: 10,
            },
            step: 1,
            format: {
              to: (v) => v | 0,
              from: (v) => v | 0,
            },
          });
        } catch (err) {
          // console.log(err);
        }
        let range = [minMaxObj[tab][feature].min];
        let maxValue = minMaxObj[tab][feature].max / 10;
        for (let i = 1; i <= 10; i++) {
          range.push(`${maxValue * i}`);
        }
        input.noUiSlider.on("update", function (values) {
          if (tab && feature) {
            qs(`.${tab}-${feature}-min`).value = range[values[0]];
            qs(`#${tab}-${feature}-input .min-val`).innerText =
              range[values[0]];
            qs(`.${tab}-${feature}-max`).value = range[values[1]];
            qs(`#${tab}-${feature}-input .max-val`).innerText =
              range[values[1]];
          }
        });
      }
    });

    qsa(".filter-input-container").forEach((x) =>
      x.addEventListener("click", (evt) => {
        x.addClass("focused");
        let tab = evt.target.getAttribute("tab");
        let feature = evt.target.getAttribute("feature");
        if (!tab || !feature) {
          return;
        }
        x.qsa(".slider-container").forEach((container) => {
          container.removeClass("hidden");
        });
      })
    );
  };

  /**
   * @function registerEventListenerOnSlide
   * @description registers the event listener on the toggle button of single action and bulk action of de style tab.
   * @private
   */
  var registerEventListenrsOnSlide = function () {
    // event listener on toggle button of single action and bulk action
    let actionToggleButton = qsa(".action-container .toggle input").forEach(
      (x) =>
        x.addEventListener("change", function () {
          let id = x.getAttribute("id");
          let bulkText = qs(`.bulk-action.${id}`);
          let singleText = qs(`.single-action.${id}`);
          if (this.checked) {
            bulkText.style.color = "#FFFFFF";
            singleText.style.color = "rgba(255, 255, 255, 0.5)";
            qsa(`#${id}-feature-container .edit-panel`).forEach((x) =>
              x.addClass("hidden")
            );
            qsa(`#${id}-feature-container .edit-panel `);
            qsa(`#${id}-feature-container .feature-customiser`).forEach((x) =>
              x.addClass("hidden")
            );
            qs(`.header-checkbox#${id}`).removeClass("hidden");
            qs(`.header-checkbox#${id} input`).checked = true;
            qsa(`#${id}-feature-container .feature-checkbox`).forEach((x) =>
              x.removeClass("hidden")
            );
            qsa(`#${id}-feature-container .feature-checkbox input`).forEach(
              (x) => (x.checked = true)
            );
            qs(`.global-method-container#${id}`).removeClass("hidden");
            qs(`.ediv.${id} .global-method-container .tick-btn`).removeClass(
              "hidden"
            );
            qsa(".feature-checkbox-container .feature-checkbox-input").forEach(
              (x) =>
                x.addEventListener("change", (evt) => {
                  let tab = evt.target.getAttribute("tab");
                  if (!x.checked) {
                    qs(`.header-checkbox-input#${tab}`).checked = false;
                  }
                })
            );
          } else {
            singleText.style.color = "#FFFFFF";
            bulkText.style.color = "rgba(255, 255, 255, 0.5)";
            qsa(`#${id}-feature-container .edit-panel`).forEach((x) =>
              x.removeClass("hidden")
            );
            qs(`.header-checkbox#${id}`).addClass("hidden");
            qsa(`#${id}-feature-container .feature-checkbox`).forEach((x) =>
              x.addClass("hidden")
            );
            qs(`.global-method-container#${id}`).addClass("hidden");
          }
          qsa(`.feature-card select`).forEach((ele) => {
            ele.addEventListener("change", handleVisibilityOfOptioncfgs);
          });
        })
    );
  };

  /**
   * @function registerEventListenerOnNavButtons
   * @description registers the event listener on the next and previous button for navigation between tabs.
   * @private
   */
  var registerEventListenersOnNavButtons = function () {
    let tabKeys = enabledTabsInfo.map((v) => v.label_key);
    qsa(".btn.next").forEach((btn) => {
      btn.addEventListener("click", (evt) => {
        let index = Number(btn.getAttribute("index"));
        let nextTab = qs(
          `#unstarted .tab-container .tab-label-container .tab-label[key=${
            tabKeys[index + 1]
          }]`
        );
        tabClickHandler({ target: nextTab });
      });
    });
    qsa(".btn.prev").forEach((btn) => {
      btn.addEventListener("click", (evt) => {
        let index = Number(btn.getAttribute("index"));
        let nextTab = qs(
          `#unstarted .tab-container .tab-label-container .tab-label[key=${
            tabKeys[index - 1]
          }]`
        );
        tabClickHandler({ target: nextTab });
      });
    });

    // /////////////////// Adding event listeners on save and reset buttons in modelling tab. /////////////////////////////////////

    qsa(".tab-body-container .btn.save").forEach((btn) => {
      btn.addEventListener("click", (evt) => {
        // Collecting updated values entered by user from UI and making a post request on save button click.
        // 'createPostObject' returns an object containing {validData:boolen(false), msg:string} in case of any error or
        // { validData:boolen(true), data:object } in case if no error is occured.
        var showEditWarning = false;
        saveTitleButtons = qsa(
          ".tab-body .model-config-row-container .save-title"
        );
        for (let saveTitleBtn of saveTitleButtons) {
          if (saveTitleBtn.className.includes("hidden") === false) {
            showEditWarning = true;
            break;
          }
        }
        if (showEditWarning) {
          APP.showWarning("Please finish editing the title of cloned model");
          return;
        }
        qsa(".ediv .reset-filter-btn").forEach((resetBtn) => {
          resetBtn.click();
        });
        let modelConfigData = createPostObject(data);
        console.log(modelConfigData);
        // Checking if any error occured in data collection.
        if (modelConfigData.validData == false) {
          // Showing error message on the UI.
          APP.showError(modelConfigData.msg);
        } else {
          APP.dismissMessage();
          APP.postModelConfigurationData(modelConfigData.data).then((resp) => {
            if (resp != null) {
              // Calling config/get api
              let projectKey = PROJECT.currentProjectKey();
              APP.clearProjectConfigs(projectKey);
              APP.loadProjectProperty({
                projectKey: projectKey,
                propKey: "project",
              }).then(function () {
                AE_AUTOMODEL.showAutoModel(projectKey, false);
              });
            } else {
              // resp == null already handled in post api call.
            }
          });
        }
      });
    });

    qsa(".tab-body-container .btn.reset").forEach((btn) => {
      btn.addEventListener("click", (evt) => {
        my.load(projectKey, true);
      });
    });
  };

  /**
   * @function registerEventListenerToSort
   * @description registers the event listener on the table header to sort the feature table of de style tab.
   * @private
   */
  var registerEventListenerToSort = function () {
    qsa(".stats-heading").forEach((header) =>
      header.addEventListener("click", (evt) => {
        const sortableHeaderContainer = evt.target.closest(".sortable-header");
        if (
          sortableHeaderContainer &&
          sortableHeaderContainer.contains(evt.target)
        ) {
          let tab = evt.target.getAttribute("tab");
          let sortKey = evt.target.getAttribute("id");
          let sortableData = {};
          if (Object.keys(searchTableData).length !== 0) {
            sortableData = JSON.parse(JSON.stringify(searchTableData));
          } else {
            sortableData = JSON.parse(JSON.stringify(filteredFeatureTableData));
          }
          sortableData[tab] = sortableData[tab].sort((a, b) => {
            let direction;
            if (sortKey == "feature") {
              direction =
                a[sortKey].toLowerCase() > b[sortKey].toLowerCase() ? 1 : -1;
            } else {
              direction = a[sortKey] > b[sortKey] ? 1 : -1;
            }
            return asc ? direction : direction * -1;
          });
          // console.log(JSON.parse(JSON.stringify(sortableData)));
          qsa(".sortable-header").forEach((x) => {
            x.removeClass("sort-asc");
            x.removeClass("sort-desc");
          });
          if (asc) {
            sortableHeaderContainer.addClass("sort-asc");
          } else {
            sortableHeaderContainer.addClass("sort-desc");
          }
          asc = !asc;
          let featureTable = createNode(
            featuretableTemplate({
              currentLabelKey: tab,
              completeData: data,
              expData: expData.data.posts[0],
              featureTableData: JSON.parse(JSON.stringify(sortableData)),
              tooltipData: tooltipData,
            })
          );
          qs(`.ediv.${tab} .feature-cards-container`).innerHTML = "";
          qs(`.ediv.${tab} .feature-cards-container`).appendChild(featureTable);
          registerEventListenerOnTableButtons();
          qsa(`.feature-card select`).forEach((ele) => {
            ele.addEventListener("change", handleVisibilityOfOptioncfgs);
          });
          if (qs(`.ediv.${tab} .action-container .toggle input`).checked) {
            qsa(`.ediv.${tab} .feature-checkbox`).forEach((x) => {
              x.removeClass("hidden");
              if (qs(`.ediv.${tab} .header-checkbox input`).checked) {
                x.qs("input").checked = true;
              }
            });
            qsa(`.ediv.${tab} .edit-panel`).forEach((x) =>
              x.addClass("hidden")
            );
          }
        }
      })
    );
  };

  /**
   * @function registerEventListenerOnButtons
   * @description registers the event listener on the buttons
   * @private
   */
  var registerEventListernersOnButtons = function () {
    // LISTENERS FOR DE STYLE TAB
    // event listener on the feature search box
    qsa(".search-action-container #search-field").forEach((x) =>
      x.addEventListener("keyup", (evt) => {
        let tab = evt.target.getAttribute("tab");
        let searchText = evt.target.value.toLowerCase();
        searchTableData = JSON.parse(JSON.stringify(filteredFeatureTableData));
        searchTableData[tab] = searchTableData[tab].filter(
          (feature) => feature.feature.toLowerCase().indexOf(searchText) !== -1
        );
        let featureTable = createNode(
          featuretableTemplate({
            currentLabelKey: tab,
            completeData: data,
            expData: expData.data.posts[0],
            featureTableData: searchTableData,
            tooltipData: tooltipData,
          })
        );
        qs(`.ediv.${tab} .feature-cards-container`).innerHTML = "";
        qs(`.ediv.${tab} .feature-cards-container`).appendChild(featureTable);
        registerEventListenerOnTableButtons();
        qsa(`.feature-card select`).forEach((ele) => {
          ele.addEventListener("change", handleVisibilityOfOptioncfgs);
        });
        let toggleButton = qs(
          `.ediv.${tab} .search-action-container .action-container .toggle`
        );
        if (toggleButton.qs("input").checked) {
          qsa(`.ediv.${tab} .feature-card .feature-checkbox`).forEach((x) => {
            x.removeClass("hidden");
            x.qs("input").checked = true;
          });
          qsa(`.ediv.${tab} .edit-panel`).forEach((x) => x.addClass("hidden"));
        }
      })
    );
    // This function must use event delegation because when number of rows are huge, the number of event listeners increases causing delay.
    registerEventListenerOnTableButtons();

    registerEventListenersOnFilter();

    registerEventListenrsOnSlide();

    registerEventListenersOnNavButtons();
  };

  /**
   * @function registerEventListenrsOnToggle
   * @description add event listener on every toggle button in tab-body-container.
   * @private
   */
  var registerEventListenrsOnToggle = function () {
    let toggles = qs(".tab-body-container").qsa("input[type=checkbox]");
    for (let i = 0; i < toggles.length; i++) {
      let tabkey = toggles[i].getAttribute("tabkey");
      let style = tabkey == "FE" ? "HR" : "VR";
      if (tabkey == null) {
        continue;
      }
      toggles[i].addEventListener("change", function () {
        // Passing tab key as argument so that, it can be determiner that toggle button lies in which tab.
        controlContentOfToggleInput(this.id, style);
      });
      controlContentOfToggleInput(toggles[i].id, style);
    }
  };

  /**
   * @function tabClickHandler
   * @description Click handler funtion for tab change.
   * @private
   */
  var tabClickHandler = function (evt) {
    let tabs = qsa("#unstarted .tab-container .tab-label-container .tab-label");
    for (let i = 0; i < tabs.length; i++) {
      tabs[i].removeClass("active");
      let tabBody = qs(
        `#main-content .tab-body-container .${tabs[i].getAttribute("key")}`
      );
      tabBody.style.display = "none";
    }

    // adding active class to clicked tab and setting display property of tab-body to block
    evt.target.addClass("active");
    let tabClass = evt.target.getAttribute("key");
    qs(`#main-content .tab-body-container .${tabClass}`).style.display =
      "block";
    qsa(".ediv").forEach((x) => (x.scrollTop = 0));
    qsa(".ediv .filter-container").forEach((x) => x.removeClass("hidden"));
    let lastTab = tabs[tabs.length - 1];
    if (lastTab.hasClass("active")) {
      accessType = readCookie("accessType");
      handleButtonDisabling();
    }
  };

  const handleButtonDisabling = function () {
    let saveButton = qs(".tab-body-container .button-panel #save-btn");
    if (accessType == "view") {
      saveButton.disabled = true;
      saveButton.setAttribute("tooltip", "Permission Denied");
      saveButton.setAttribute("flow", "left");
    }
  };

  /**
   * @function checkTabPresence
   * @description checks the presence of a tab
   * @private
   */
  const checkTabPresence = function (tabs, tabKey) {
    const isTabPresent = tabs.find(
      (tab) => tab.label_key === tabKey && tab.enabled !== false
    );
    if (isTabPresent) {
      return true;
    } else {
      return false;
    }
  };

  /**
   * @function registerEventListenersOnTabs
   * @description register event listener on the tabs for initial stage.
   * @private
   */
  var registerEventListenersOnTabs = function () {
    // Initializing tab label click handling
    let tabs = qsa("#unstarted .tab-container .tab-label-container .tab-label");
    tabs[0].addClass("active"); // Adding active class to first tab becuse it is selected by default.
    for (let i = 0; i < tabs.length; i++) {
      tabs[i].addEventListener("click", tabClickHandler); // Added event handler for tab change.
      if (i == 0) {
        // first tab body will be shown by default
        qs(
          `#unstarted .tab-container .tab-body-container .${tabs[
            i
          ].getAttribute("key")}`
        ).style.display = "block";
      } else {
        // All tabs except first tab will be hidden
        qs(
          `#unstarted .tab-container .tab-body-container .${tabs[
            i
          ].getAttribute("key")}`
        ).style.display = "none";
      }
    }
    registerEventListenrsOnToggle(); // Adds event listeners on each toggle button present inside all tabs.

    // Checking if project expert experimentation data contains sampling subtab tab attribte, if yes then sampling subtab must
    // have been rendered on screen and subtab initialization must be done.
    const isSamplingTabPresent = checkTabPresence(data.tabinfo, "sampling");
    if (isSamplingTabPresent && data.sampling != undefined) {
      setupSamplingTabInputs(); // Creates two-way data binding between range input and input boxes inside Curate Data Imbalance subtab
    }

    // Checking if project expert experimentation data contains cdi subtab tab attribte, if yes then cdi subtab must have been
    // rendered on screen and subtab initialization must be done.
    const isCdiTabPresent = checkTabPresence(data.tabinfo, "cdi");
    if (isCdiTabPresent && data.cdi != undefined) {
      setupCdiTabInputs(); // Creates two-way data binding between range input and input boxes inside sampling subtab
    }

    validateClistInput(); // Setups input validation event handlers on clist input type
    validateMClistInput();

    qsa("#unstarted .tab-container select.table-select").forEach((ele) => {
      ele.addEventListener("change", handleVisibilityOfOptioncfgs);
      // handleVisibilityOfOptioncfgs({ target: ele }); // Executing it for initial setup.
    });
    qsa("#unstarted .tab-container select.non-table-select").forEach((ele) => {
      ele.addEventListener("change", handleVisibilityOfOptioncfgs);
      handleVisibilityOfOptioncfgs({ target: ele }); // Executing it for initial setup.
    });

    qsa(".tooltip-container").forEach((x) =>
      x.addEventListener("mouseover", (evt) => {
        evt.target.setAttribute("flow", "up");
        let text = evt.target.getAttribute("tooltip");
        let availableHeight = evt.y - 235; //height available to show the tooltip
        let numberOfLinesReq = Math.floor(text.length / 24); //24 characters can be present in 1 line
        let minHeightReq = numberOfLinesReq * 20; //one line takes 20px approx
        if (availableHeight < minHeightReq) {
          evt.target.setAttribute("flow", "down");
        }
      })
    );

    qs(".ediv.MODELLING").addEventListener("mouseover", (evt) => {
      if (
        evt.target.className == "clone-icon" ||
        evt.target.className == "delete-clone-icon" ||
        evt.target.className == "edit-icon"
      ) {
        evt.target.setAttribute("flow", "up");
        let text = evt.target.getAttribute("tooltip");
        let availableHeight = evt.y - 260; //height available to show the tooltip
        let numberOfLinesReq = Math.floor(text.length / 24); //24 characters can be present in 1 line
        if (numberOfLinesReq == 0) {
          numberOfLinesReq = 1;
        }
        let minHeightReq = numberOfLinesReq * 20; //one line takes 20px approx
        if (availableHeight < minHeightReq) {
          evt.target.setAttribute("flow", "down");
        }
      }
    });
  };

  /**
   * @function populateTabTable
   * @description populates the feature table for de style of tab.
   * @private
   */
  var populateTabTable = function (currentLabelKey) {
    let featureTable = createNode(
      featuretableTemplate({
        currentLabelKey: currentLabelKey,
        completeData: data,
        expData: expData.data.posts[0],
        featureTableData: my.featureTableData,
        tooltipData: tooltipData,
      })
    );
    qs(`.ediv.${currentLabelKey} .feature-cards-container`).appendChild(
      featureTable
    );
  };

  /**
   * @function populateTables
   * @description populates the tables for de style of tab.
   * @private
   */
  var populateTables = function () {
    let tabLabels = data.tabinfo;
    for (const [i, label] of tabLabels.entries()) {
      let currentLabelKey = label.label_key;
      let currentTabStyle = label.tab_style;
      let isTabEnabled = label.enabled !== false;
      if (currentTabStyle == "de" && isTabEnabled) {
        populateTabTable(currentLabelKey);
        label["isPopulated"] = true;
      }
    }
  };

  const getEnabledTabs = function (clonedTabinfo) {
    for (let i = clonedTabinfo.length - 1; i >= 0; i--) {
      if (clonedTabinfo[i].enabled === false) {
        clonedTabinfo.splice(i, 1);
      }
    }
    return clonedTabinfo;
  };
  /**
   * @function showData
   * @description shows all the data for the model configuration.
   * @private
   */
  var showData = function () {
    // Rendering expert experimentation UI using data received from API.
    enabledTabsInfo = getEnabledTabs(structuredClone(data.tabinfo));
    qs("#unstarted .tab-container").innerHTML = modelconfigurationTemplate({
      tabinfo: enabledTabsInfo,
      completeData: data,
      expData: expData.data.posts[0],
      minMaxObj: minMaxObj,
      tooltipData: tooltipData,
    });

    populateTables();

    registerEventListernerOnImportConfiguration();

    registerEventListenersOnTabs();

    setupCloningModelDevelopmentTab();

    registerEventListernersOnButtons();

    registerEventListenerToSort();
  };

  /**
   * @function getFilterMinMax
   * @description stores the min and max values for each filter of de style of tab.
   * @private
   */
  var getFilterMinMax = function (expData, data) {
    expData = expData.data.posts[0];
    minMaxObj = {};
    let numObj = Object.values(expData["Numerical"]);
    let catObj = Object.values(expData["Categorical"]);
    let binObj = [];
    if (expData["Binary"]) {
      binObj = Object.values(expData["Binary"]);
    }
    for (let tab of data.tabinfo) {
      let currentLabelKey = tab.label_key;
      let currentTabStyle = tab.tab_style;
      let tabObj = {};
      if (currentTabStyle == "de") {
        let stats = data["DE"].statsInfo[currentLabelKey];
        for (let stat of stats) {
          let minVal = Number.MAX_VALUE;
          let maxVal = Number.MIN_VALUE;
          for (let numerical of numObj) {
            if (numerical[stat] == undefined) {
              break;
            }
            minVal = Math.min(minVal, numerical[stat]);
            maxVal = Math.max(maxVal, numerical[stat]);
          }
          for (let categorical of catObj) {
            if (categorical[stat] == undefined) {
              break;
            }
            minVal = Math.min(minVal, categorical[stat]);
            maxVal = Math.max(maxVal, categorical[stat]);
          }
          for (let binary of binObj) {
            if (binary[stat] == undefined) {
              break;
            }
            minVal = Math.min(minVal, binary[stat]);
            maxVal = Math.max(maxVal, binary[stat]);
          }
          minVal = Math.floor(minVal);
          maxVal = Math.ceil(maxVal);
          tabObj[stat] = { min: minVal, max: maxVal };
        }
        minMaxObj[currentLabelKey] = tabObj;
      }
    }
  };

  /**
   * @function formatData
   * @description formats the sampling data in a suitable format which is to be used to render the UI.
   * @private
   */
  var formatData = function (data, expData, resetExpExp) {
    // If we are not resetting, save the data as initial data, so that this data could be set as when reset is clicked
    let samplingTabData = {};
    if (!resetExpExp) {
      intialExpConfigData = JSON.parse(JSON.stringify(expData));
      initialModelConfigData = JSON.parse(JSON.stringify(data));
      initialTooltipData = JSON.parse(JSON.stringify(tooltipData));
    }
    // getting data for headers
    for (let header of data.headers) {
      if (header.tab_map == "sampling") {
        samplingTabData[header.label_key] = data[header.label_key];
      }
    }
    data.sampling = JSON.parse(JSON.stringify(samplingTabData));
  };

  const processData = function (
    feature,
    currentTabLabelKey,
    featureType,
    exploreData,
    stats,
    features,
    method
  ) {
    let featureObj = {};
    featureObj["feature"] = feature;
    featureObj["type"] = featureType;
    featureObj["method"] = method;
    featureObj["optioncfgs"] = JSON.parse(
      JSON.stringify(data["DE"].optionInfo[currentTabLabelKey].optioncfgs)
    );
    for (let i = 0; i < featureObj["optioncfgs"].length; i++) {
      let currentOptioncfg = Object.keys(featureObj["optioncfgs"][i])[0];
      if (currentOptioncfg == featureObj["method"]) {
        if (featureObj["optioncfgs"][i][currentOptioncfg].selectedValue) {
          featureObj["optioncfgs"][i][currentOptioncfg].selectedValue =
            features[feature][currentTabLabelKey].selectedValue;
        } else if (featureObj["optioncfgs"][i][currentOptioncfg].order) {
          for (let subOptioncfg of featureObj["optioncfgs"][i][currentOptioncfg]
            .order) {
            featureObj["optioncfgs"][i][currentOptioncfg].values[subOptioncfg] =
              features[feature][currentTabLabelKey][subOptioncfg];
          }
        } else {
          if (features[feature][currentTabLabelKey].values) {
            featureObj["optioncfgs"][i][currentOptioncfg].values =
              features[feature][currentTabLabelKey].values;
          } else {
            featureObj["optioncfgs"][i][currentOptioncfg].values =
              features[feature][currentTabLabelKey].custom;
          }
        }
      }
    }
    if (importedFlag) {
      featureObj["modified"] = true;
    } else {
      featureObj["modified"] = false;
    }
    for (let stat of stats) {
      if (exploreData[featureType][feature][stat] != undefined) {
        if (typeof exploreData[featureType][feature][stat] == "number") {
          let val = exploreData[featureType][feature][stat];
          val = Math.round(val * 1000) / 1000;
          featureObj[stat] = val;
        } else {
          featureObj[stat] = exploreData[featureType][feature][stat];
        }
      } else {
        featureObj[stat] = "-";
      }
    }
    return featureObj;
  };

  const formatDataForFeatureTable = function () {
    let tabLabels = data.tabinfo;
    let exploreData = expData.data.posts[0];
    for (const [i, label] of tabLabels.entries()) {
      let currentTabLabelKey = label.label_key;
      let stats = data["DE"].statsInfo[currentTabLabelKey];
      let currentTabStyle = label.tab_style;
      if (currentTabStyle == "de") {
        let features = data["DE"].featureInfo;
        let featureList = Object.keys(features);
        let currentTabFeatureInfo = [];
        for (let feature of featureList) {
          let featureType = features[feature].type;

          let alternativeFeatureType = "Categorical";

          if (featureType == "Categorical")
            alternativeFeatureType = "Numerical";

          if (
            features[feature][currentTabLabelKey] &&
            exploreData[featureType][feature]
          ) {
            const method = features[feature][currentTabLabelKey].method;
            let processedData = processData(
              feature,
              currentTabLabelKey,
              featureType,
              exploreData,
              stats,
              features,
              method
            );
            currentTabFeatureInfo.push(processedData);
          } else if (
            importedFlag &&
            features[feature][currentTabLabelKey] &&
            exploreData[alternativeFeatureType][feature]
          ) {
            let variableType = "";

            if (alternativeFeatureType == "Numerical") {
              variableType = "numtype";
            } else {
              variableType = "cattype";
            }

            if (
              data["DE"].optionInfo[currentTabLabelKey].options[variableType]
                .values.length == 0
            )
              continue;

            const method =
              data["DE"].optionInfo[currentTabLabelKey].options[variableType]
                .values[0];
            let processedData = processData(
              feature,
              currentTabLabelKey,
              alternativeFeatureType,
              exploreData,
              stats,
              features,
              method
            );

            currentTabFeatureInfo.push(processedData);
          }
        }
        my.featureTableData[currentTabLabelKey] = currentTabFeatureInfo;
      }
    }
    initialFeatureTableData = JSON.parse(JSON.stringify(my.featureTableData));
  };

  /**
   * @function fetchDataForExpertExperimentation
   * @description fetches all the data required for expert experimentation screen from the respective APIs
   * @private
   */
  var fetchDataForExpertExperimentation = async function (pkey, resetExpExp) {
    // // Getting data from API.
    if (!resetExpExp) {
      await APP.getModelConfigurationData().then((response) => {
        data = response.posts[0];
      });
    } else {
      await APP.resetModelConfigurationData().then((response) => {
        data = response.posts[0];
      });
    }

    // Getting 'userCfgd' attribute data
    if (!resetExpExp) {
      expData = await EXPLORE_DATA.getData(pkey);
      APP.resetProgress();

      // Collect minimum and manimum for showing on filter
      getFilterMinMax(expData, data);
    } else {
      expData = JSON.parse(JSON.stringify(intialExpConfigData));
    }

    if (!resetExpExp) {
      let url = SERVER.getBaseAddress() + "fetchtt",
        userHash = CREDENTIALS.getUserCreds(),
        projectKey = pkey ? pkey : PROJECT.currentProjectKey();
      let result;
      if (useTestData) {
        result = await TOOLTIP_DATA.getData();
      } else {
        APP.setProgress("Fetching tooltip data");
        if (userHash == null) {
          throw new Error(
            i18n.en.APP.UI.ERROR.USER_NOT_LOGGED_IN_CANNOT_RETRIEVE_PROJECTS +
              "\n" +
              i18n.en.APP.UI.ERROR.EXPLORE_DATA.GENERIC
          );
        }
        if (projectKey == null) {
          throw new Error(
            i18n.en.APP.UI.INFO.EXPLORE_DATA.NOT_IN_CONTEXT_OF_PROJECT
          );
        }

        let params = {
          key: userHash,
          projectKey: projectKey,
          projVersion: PROJECT.currentProjVersion(),
        };
        try {
          result = await SERVER.postData(url, params);
        } catch (err) {
          result = null;
        }
        if (result == null) {
          APP.showError(
            `${i18n.en.APP.UI.ERROR.EXPLORE_DATA.GENERIC} Please contact an Administrator.`
          );
          APP.resetProgress();
          let err = new Error(i18n.en.APP.UI.ERROR.EXPLORE_DATA.GENERIC);
          err.name = "GenericError";
          throw err;
        }
      }
      tooltipData = result;
    } else {
      tooltipData = JSON.parse(JSON.stringify(initialTooltipData));
    }
    sizeOfFeatureTableData = Object.keys(my.featureTableData).length;
    if (!resetExpExp) {
      formatDataForFeatureTable();
    } else {
      my.featureTableData = JSON.parse(JSON.stringify(initialFeatureTableData));
    }
    filteredFeatureTableData = JSON.parse(
      JSON.stringify(initialFeatureTableData)
    );
    // console.log(my.featureTableData);
    APP.resetProgress();
    formatData(data, expData, resetExpExp);
  };

  /**
   * @function load
   * @description serves as the starting point to load the page
   * @private
   */
  my.load = async function (pkey, resetExpExp = false) {
    expData;
    projectKey = pkey;

    // Fetch all the data which is required
    fetchDataForExpertExperimentation(pkey, resetExpExp).then(
      async (result) => {
        // Create the HTML
        showData();
        let projects = await getProjects();
        if (projects.length == 0) {
          // Checking any project exists with state higer than 5 exist or not
          qs(".import-btn-box .import-config").disabled = true; // If no project exists then button will be disabled
          qs(".import-btn-box .import-config").setAttribute(
            "title",
            "No projects available to import"
          ); // Changing tooltip title to No projects available
        }
      }
    );
    // Show the fetched data
  };

  return my;
})(EXPERT_EXPERIMENTATION || {});
