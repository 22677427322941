var PREDICTION_VS_DENSITY = (function (my) {
  my.type = "predictionVsDensity";

  my.optsGenerator = function (options) {
    let opts=extend({},CHART.opts);
    delete opts.axis.y.min;
    delete opts.axis.y.max;
    delete opts.axis.y.tick.values;
    delete opts.axis.x.min;
    delete opts.axis.x.max;
    delete opts.axis.x.tick.values;
    delete opts.axis.x.label.text;

    opts.bindto = "#predictionVsDensity .graph";
    // opts.axis.y.padding={top: 5, bottom: 5};
    // opts.axis.x.padding={left: 5, right: 5};
    opts.axis.y.tick.format = d3.format(".1f");
    opts.axis.x.tick.format = d3.format(".1f");
    opts.axis.y.tick.culling = true;
    opts.axis.x.tick.culling = true;
    opts.axis.y.label.text = "Density";
    opts.axis.x.label.text = "Prediction";
    opts.id = my.type;

    opts.data = {
      xs: {
        // Density: "x1",
        LineChart: "lineChartX"
      },
      columns: [
        // ["x1"].concat(options.data.y1.keys),
        // ["Density"].concat(options.data.y1.values),
        ['lineChartX'].concat(options.data.y1.keys),
        ['LineChart'].concat(options.data.y1.values)
      ],
      additionalData: options.data.additionalData,
      // types: {
      //   // Density: 'bar',
      //   Reference: 'line'
      // },
      type: 'line',

      colors: {
        LineChart: CHART.colors[1],
        // Density: CHART.colors[2],
      },
    },
    opts.bar = {
      space: -0.01,
    };
    opts.legend = {
      show: false,
    };
    opts.grid = {
      x: { show: false },
      y: { show: true },
    };
    opts.point = {
      show: true,
    };
    opts.size = {
      width: qs("#predictionVsDensityChart").offsetWidth - 30,
      height: qs("#predictionVsDensityChart").offsetHeight - qs("#predictionVsDensityChart h4").offsetHeight - 22,
    };
    const dec3Format = d3.format("0.3f");
    opts.tooltip = {
      contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
        if (d[0].id === "LineChart") {
          return `<table class='c3-tooltip'>
                    <tr class='c3-tooltip-name--x}'>
                      <td class='name'><span style='background-color:${
                        CHART.colors[1]
                      }'></span>Prediction</td>
                      <td class='value'>${dec3Format(d[0].x)}</td>
                    </tr>
                    <tr class='c3-tooltip-name--${d[0].id}'>
                      <td class='name'><span style='background-color:transparent'></span>Density</td>
                      <td class='value'>${defaultValueFormat(
                        d[0].value,
                        null,
                        d[0].id
                      )}</td>
                    </tr>
                  </table>`;
        }
      },
      horizontal: true,
    };

    if (!empty(options.c3d3properties)) {
      opts = extend(opts, options.c3d3properties);
    }

    return opts;
  };
  my.generate = function (options) {
    let opt = my.optsGenerator(options);
    CHART.charts[opt.id] = c3.generate(opt);
    return CHART.charts[opt.id];
  };

  return my;
})(PREDICTION_VS_DENSITY || {});
