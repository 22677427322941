var CONNECTION_DIALOG = (function (my) {

  /**
   * @method setDlgButtonStatus
   * @description Used for setting the status of the dlg box's test and next and test buttons.
   * @param {string} status if status is "untested", there is no overlay and next is disabled. If status is 
   * "success" next has a tick mark and next is enabled. If status is "fail" next has a red mark and next is 
   * disabled.
   * @param {string} action to know which dialog we have to select. Eg. File upload or db-connection.
   * @public
   */
  my.setDlgButtonStatus = function(status, action){
    let testBtn,nextBtn;
    if(action === "db" ){
      testBtn = qs("#new-db-connection-dialog #test-button");
      nextBtn = qs("#new-db-connection-dialog #next-button");
    }  
    if(action === "file"){
      testBtn = qs("#file-upload-dialog #file-test-button");
      nextBtn = qs("#file-upload-dialog #file-next-button");
    }
    if ("untested" === status) {
      testBtn.removeClass("fail,success").addClass("untested")
      nextBtn.setAttribute("disabled", "disabled");
    } else if ("success" === status) {
      testBtn.removeClass("fail,untested").addClass("success");
      nextBtn.removeAttribute("disabled");
    } else if ("fail" === status) {
      testBtn.removeClass("untested,success").addClass("fail");
      nextBtn.setAttribute("disabled", "disabled");
    }
  } 

  return my;
})(CONNECTION_DIALOG || {});