/**
 * Get the expiration date of n days from now
 * @param {number} cookieLife no. of days 
 */
function setExpiration(cookieLife){
  var today = new Date();
  var expr = new Date(today.getTime() + cookieLife * 24 * 60 * 60 * 1000);
  return  expr.toGMTString();
}

/**
 * Set a nave-value cookie with specified expiry, path, domain
 * @param {string} name Name of the cookie
 * @param {string} value Value of the cookie
 * @param {number} expires Number of days until it expires
 * @param {string} path url path context
 * @param {string} domain cookie domain
 * @param {boolean} secure 
 */
function setCookie(name, value, expires, path, domain, secure){
	cookieStr = name + "=" + escape(value) + "; ";
	
	if(expires){
		expires = setExpiration(expires);
		cookieStr += "expires=" + expires + "; ";
	}
	if(path){
		cookieStr += "path=" + path + "; ";
	}
	if(domain){
		cookieStr += "domain=" + domain + "; ";
	}
	if(secure){
		cookieStr += "secure; ";
	}
	
	document.cookie = cookieStr;
}

/**
 * Read a named cookie
 * @param {string} name Lookup name of the cookie
 */
function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
  }
  return null;
}