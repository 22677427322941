/* global projectTemplate, VALIDATE_FEATURES */
/**
 * @module DASHBOARD
 * @description The Dashboard page at "#/". Loads list of projects from the "projects/all" API
 * and renders them as boxes on the page. Also has code for the "Workflow Start" page.
 */
var DASHBOARD = (function (my) {
  /**
   * @member {number} index of the project whose versions are to be compared.
   * @public
   */
  my.selectedProjectKeyForComparison = null;

  /**
   * @member {number} projectKey of the project in which a new version is to be created.
   * @public
   */
  my.selectedProjectkey = null;

  /**
   * @member {array} array of sortable projects required at the time of sorting.
   * @private
   */
  var sortableProjectData = null;

  /**
   * @member {array} array of filtered projects according to the summary.
   * @private
   */
  var filteredProjects = null;

  /**
   * @member {array} array of projects that was returned by the projects/all API.
   * @public
   */
  my.projects = null;

  /**
   * @member {boolean} inited flag to indicate whether the dashboard's init() method has been called yet
   * @private
   */
  var inited = false;

  /**
   * @member {number} id returned by STORE.addObserver from when the dashboard registers to
   * listen for project list changes
   * @private
   */
  var projectsObserver = null;

  /**
   * @member {boolean}
   * @description Holdes the state of sorting direction.
   */
  var asc = true;

  /**
   * @member {string}
   * @description Holds the key on the basis of which sorting will be done.
   */
  var sortKey = "nameDesc";

  /**
   * @member {string}
   * @description Holds the value of description before editing.
   */
  var oldDescriptionValue = null;

  var accessType = null;

  var headerText = [];

  var headerKeys = [];

  const regeisterLogoutListener = function () {
    qs(".logout-btn").addEventListener("click", (evt) => {
      APP.setProgress(i18n.en.APP.UI.FOOTER.PROGRESS.LOGOUT);
      if (APP.getProperty("userManagement.enabled")) {
        APP.closeAllDialogs();
        SERVER.postData(SERVER.getBaseAddress() + "logout", {}).then((res) => {
          CREDENTIALS.resetCreds();
          DASHBOARD.unload();
          APP.updateNavigationVisibility();
          if (!APP.getProperty("userManagement.enabled")) {
            APP.router.navigate("/sign-up");
          } else {
            const userManagementLink = document.createElement("a");
            const userManagementUrl =
              localStorage.getItem("USER_MANAGEMENT_URL") || "";
            userManagementLink.href = userManagementUrl;
            userManagementLink.click();
          }
        });
      } else {
        APP.router.navigate("/logout");
      }
    });
  };

  /**
   * @method load
   * @description removes current project context and then loads the dashboard with projects
   * @throws {Error} Throws an error in case of a server error or if the userHash is not obtainable
   * from the projects/all API.
   * @async
   * @public
   */
  my.load = async function () {
    if (!APP.getProperty("cache.acrossProjects")) {
      STORE.purge();
    }
    regeisterLogoutListener();
    init();
    accessType = readCookie("accessType");
    if (accessType != "normal") {
      headerText = [
        "Project Name",
        "Description",
        "Type",
        "Project ID",
        "Created On",
        "Platform",
        "State",
        "Add to Compare",
        "Created By",
        "Actions",
      ];
      headerKeys = [
        "pname",
        "description",
        "ptype",
        "pid",
        "creation_ts",
        "platform",
        "namedState",
        "compare",
        "uname",
        "buttonPanel",
      ];
    } else {
      headerText = [
        "Project Name",
        "Description",
        "Type",
        "Project ID",
        "Created On",
        "Platform",
        "State",
        "Add to Compare",
        "Actions",
      ];
      headerKeys = [
        "pname",
        "description",
        "ptype",
        "pid",
        "creation_ts",
        "platform",
        "namedState",
        "compare",
        "buttonPanel",
      ];
    }
    let projects = null;
    // eslint-disable-next-line no-unused-vars
    let result = null;
    try {
      result = await APP.getAllProjects().then((response) => {
        projects = response.data.posts;
      });
    } catch (err) {
      APP.router.navigate("/logout");
      return;
    }
    var projectObjTemplate = {
      projectkey: "##ID##",
      createdOn: "##CREATED_ON##",
      pname: "##pname##",
      NoModels: "##NoModels##",
      platform: "##platform##",
      ptype: "##ptype##",
    };
    var plist = [];
    projects.forEach((project) =>
      plist.push(extend(projectObjTemplate, project))
    );
    STORE.projects = projects;
  };

  /**
   * @method unload
   * @description cleanup method to remove the dashboard view from the UI
   * @throws {Error} Throws an error in case of a server error or if the userHash is not obtainable.
   * @async
   * @public
   */
  my.unload = function () {
    if (!my.inited) return;
    STORE.removeObserver("projects", projectsObserver);
    projectsObserver = null;
    my.inited = false;
    qs("#main-content").classList.remove("dashboard");
    qs("#main-content .dashboardContent").innerHTML = "";
  };

  /**
   * @method searchHandler
   * @description Handles search through keyup event.
   * @public
   */
  my.searchHandler = function () {
    let searchedData = JSON.parse(JSON.stringify(filteredProjects));
    let searchText = document
      .getElementById("project-search-field")
      .value.toLowerCase();
    searchedData = searchedData.filter(
      (proj) => proj.pname.toLowerCase().indexOf(searchText) !== -1
    );
    let projectNode = null;
    // Rendering whole project list table using table.pug
    projectNode = createNode(
      projecttableTemplate({
        data: JSON.parse(JSON.stringify(searchedData)),
        headerText: headerText,
        headerKeys: headerKeys,
        tableAttributes: {
          class: "fixed-table",
          id: "dashboard-project-view",
        },
      })
    );
    qs("#dashboard-project-view").remove();
    qs("#main-content .dashboardContent").appendChild(projectNode);
    sortableProjectData = JSON.parse(JSON.stringify(searchedData));
    registerEventListenerOnProjectTable(my.projects);
    handleButtonDisabling();
  };

  var sortVersion = function (version, key) {
    return version.versionInfo.sort((currentVersion, nextVersion) => {
      return new Date(currentVersion[key]).getTime() >
        new Date(nextVersion[key]).getTime()
        ? asc
          ? -1
          : 1
        : asc
        ? 1
        : -1;
    });
  };

  /**
   * @public sortProjects
   * @description Sorts an array of projects based on a cetain key.
   * @param {array}  Array of projects to sort
   * @param {string} Key on the basis of which sorting will be done.
   * @param {string} Direction to sort in [ASC|DSC]
   */
  my.sortProjects = function (key, asc = true) {
    if (sortableProjectData.length == 1) {
      if (key === "creation_ts") {
        sortableProjectData[0].versionInfo = sortVersion(
          sortableProjectData[0],
          key
        );
      }
    } else {
      sortableProjectData = sortableProjectData.sort(
        (currentProject, nextProject) => {
          let direction;
          if (key === "creation_ts") {
            currentProject.versionInfo = sortVersion(currentProject, key);
            nextProject.versionInfo = sortVersion(nextProject, key);
            direction =
              new Date(currentProject.versionInfo[0][key]).getTime() >
              new Date(nextProject.versionInfo[0][key]).getTime()
                ? -1
                : 1;
          } else {
            direction = currentProject[key] > nextProject[key] ? -1 : 1;
          }
          return asc ? direction : direction * -1;
        }
      );
    }
  };

  /**
   * @public sortTableIfHeaderClicked
   * @description Checks if header of table is clicked and sorts accordingly.
   * @param {Event}
   */
  my.sortTableIfHeaderClicked = function (evt) {
    let table = qs("#dashboard-project-view");
    if (evt.target.hasClass("sortable-column")) {
      my.sortKey = evt.target.getAttribute("data-sort");
      asc = !asc;
      my.sortProjects(my.sortKey, asc);
      return true;
    }
    return false;
  };

  /**
   * @private updateDescription
   * @description updates the description in the UI and changes save button to edit button
   * @param {element} editableBtn the dom element for the edit button.
   * @param {element} descElement the dom element for the description.
   * @param {element} inputElement  the dom element for the input field of description.
   * @param {string} descriptionText to update the description.
   */
  const updateDescription = function (
    editableBtn,
    descElement,
    inputElement,
    descriptionText
  ) {
    const versionBox = editableBtn.parentElement.parentElement.parentElement;
    const stopEditBtn = editableBtn.parentElement.qs(".stop-editing");

    descElement.innerHTML = descriptionText;
    inputElement.parentElement.setAttribute("tooltip", descriptionText);
    inputElement.parentElement.replaceChild(descElement, inputElement);
    editableBtn.removeClass("save-description-btn");
    editableBtn.addClass("edit-description-btn");
    editableBtn.setAttribute("tooltip", "Edit description");
    stopEditBtn.addClass("hidden");
    versionBox.removeClass("editing-description");
  };

  /**
   * @private saveDescription
   * @description posts the updated description to the server using API call.
   * @param {projectKey} projectKey of the project in which updation in description is required.
   * @param {projVersion} projVersion of the project in which updation in description is required.
   * @param {string} desc to update the description.
   */
  const saveDescription = async function (projectKey, projVersion, desc) {
    let result = null;
    const params = {
      projectKey: projectKey,
      projVersion: projVersion,
      desc: desc,
    };
    result = await API_HELPER.getResult("updateprojdesc", params);
    if (
      result == null ||
      (result.status != "success" &&
        !(result.status >= 200 && result.status < 300))
    ) {
      return { status: "failed", reason: result.data.reason };
    }
    return { status: "success", result: result };
  };

  /**
   * @private saveAndUpdateDescription
   * @description saves description and updates the UI and makes changes in the current project data for the updated description.
   * @param {Event}
   * @param {array} projects list of all project data items obtained from projects/all API.
   * @param {projectKey} projectKey of the project in which updation in description is required.
   * @param {projVersion} projVersion of the project in which updation in description is required.
   */
  const saveAndUpdateDescription = async function (
    evt,
    projects,
    projectKey,
    projVersion
  ) {
    let response = null;
    const editableBtn = evt.target;
    const inputElement =
      editableBtn.parentElement.parentElement.parentElement.qs(
        ".description-input"
      );
    const descText = inputElement.value;
    const descElement = document.createElement("div");
    descElement.addClass("card-description");
    descElement.addClass("description-ellipsis");

    response = await saveDescription(projectKey, projVersion, descText);
    if (response.status === "failed") {
      APP.showError(response.reason);
      updateDescription(
        editableBtn,
        descElement,
        inputElement,
        oldDescriptionValue
      );
      return;
    }

    updateDescription(editableBtn, descElement, inputElement, descText);

    projWithChangedDesc = projects.find(
      (proj) => proj.projectkey === projectKey
    );
    versionWithChangedDesc = projWithChangedDesc.versionInfo.find(
      (version) => version.vname === projVersion
    );
    versionWithChangedDesc.description = descText;
  };

  const validateDescriptionInput = function (evt) {
    const inputVal = evt.target.value;
    const re = /[`~!@#$%^&*()|+\=?;:'",.<>\{\}\[\]\\\/]/gi;
    const isSplChar = re.test(inputVal);
    if (isSplChar) {
      const no_spl_char = inputVal.replace(
        /[`~!@#$%^&*()|+\=?;:'",.<>\{\}\[\]\\\/]/gi,
        ""
      );
      evt.target.value = no_spl_char;
    }
  };

  const saveSettings = async function (
    evt,
    projectKey,
    projVersion,
    settingsDialog
  ) {
    if (
      evt.type.toLowerCase() == "keypress" &&
      evt.key.toLowerCase() !== "enter"
    ) {
      return;
    }
    const settingCheckboxContainer = qs(".setting-checkbox-container");
    const allowProjDeletion = settingCheckboxContainer.qs("#project-deletion")
      .checked
      ? 1
      : 0;
    const allowEditDescripton = settingCheckboxContainer.qs(
      "#edit-project-description"
    ).checked
      ? 1
      : 0;
    const allowOotScoring = settingCheckboxContainer.qs("#oot-scoring").checked
      ? 1
      : 0;
    const allowReserving = 1;
    const payload = {
      projectKey: projectKey,
      projVersion: projVersion,
      operations: {
        delete: {
          enabled: allowProjDeletion,
        },
        editDesc: {
          enabled: allowEditDescripton,
        },
        scoring: {
          enabled: allowOotScoring,
        },
        reserved: {
          enabled: allowReserving,
        },
      },
    };
    APP.hideDialog(settingsDialog);
    settingsDialog.remove();
    result = await API_HELPER.getResult("update/accessctrl", payload);
    if (
      result == null ||
      (result.status != "success" &&
        !(result.status >= 200 && result.status < 300))
    ) {
      APP.showError("Something went wrong. Please try again later");
      return;
    }
    APP.showInfo("Successfully updated access control");
  };

  const getAccessnfo = async function (projectKey, projVersion) {
    let url = SERVER.getBaseAddress() + "fetch/accesscontrol";
    let userHash = CREDENTIALS.getUserCreds();
    if (userHash == null) {
      throw new Error(i18n.en.APP.UI.ERROR.MODELCFG.USER_NOT_LOGGED_IN);
    }
    const payload = {
      key: userHash,
      projectKey: projectKey,
      projVersion: projVersion,
    };
    let result = null;
    if (useTestData) {
      result = await ACCESS_CONTROL_DATA.getData(url, payload);
      return result;
    }
    try {
      result = await SERVER.postData(url, payload);
    } catch (err) {
      result = null;
    }
    if (result === "ROUTES_MISMATCHED") {
      return;
    }
    return result;
  };

  const showSettingsDialog = async function (projectKey, projVersion) {
    if (qs("#dialogs-active #settings-dialog")) {
      //if dialog already exists, cancel old dialog
      let settingsDialog = qs("#dialogs-active #settings-dialog");
      settingsDialog.fireCustomEvent("cancelled", {
        message: "Another dialog triggered.",
      });
    }
    const accessInfo = await getAccessnfo(projectKey, projVersion);
    var settingsDialog = null;
    settingsDialog = createNode(
      settingsdialogTemplate({
        projectKey: projectKey,
        projVersion: projVersion,
        accessInfo: accessInfo.data.operations,
      })
    );
    qs("#dialogs-sleeping").appendChild(settingsDialog);
    APP.showDialog(settingsDialog);
    settingsDialog
      .qs("#save-settings-button")
      .addEventListener("click", (evt) =>
        saveSettings(evt, projectKey, projVersion, settingsDialog)
      );
    settingsDialog.addEventListener("cancelled", (evt) => {
      settingsDialog.remove();
    });
    [].forEach.call(
      settingsDialog.qsa("button.close, #cancel-button"),
      function (button) {
        button.addEventListener("click", () => {
          APP.hideDialog(settingsDialog);
          settingsDialog.remove();
        });
      }
    );
  };

  const handleVersionLevelIconsClick = (evt, eventClass, projects) => {
    let buttonClicked = false;
    let target = "";
    let projectKey = "";
    let projVersion = "";
    let editButtonClass = "edit-description-btn";
    let exploreButtonClass = "project-explore-btn";
    let deleteButtonClass = "project-delete-btn";
    let saveDescClass = "save-description-btn";
    let stopEditClass = "stop-editing";
    let downloadButtonClass = "project-download-btn";
    let downloadDataClass = "download-data";
    let settingsClass = "project-settings-btn";
    if (
      eventClass == editButtonClass ||
      eventClass == exploreButtonClass ||
      eventClass == deleteButtonClass ||
      eventClass == saveDescClass ||
      eventClass == stopEditClass ||
      eventClass == downloadButtonClass ||
      eventClass == downloadDataClass ||
      eventClass == settingsClass
    ) {
      buttonClicked = true;
      target = evt.target.getAttribute("target");
      projectKey = evt.target.parentElement.getAttribute("data-project-key");
      projVersion = evt.target.parentElement.getAttribute("project-version");
    }
    if (buttonClicked && eventClass == exploreButtonClass) {
      PROJECT.setCurrentProjectKey(projectKey);
      PROJECT.setCurrentProjVersion(projVersion);
      APP.clearProjectConfigs(projectKey);
      APP.loadProjectProperty({
        projectKey: projectKey,
        propKey: "project",
        projVersion: projVersion,
      }).then(function () {
        APP.router.navigate(target);
      });
    } else if (
      buttonClicked &&
      eventClass == deleteButtonClass &&
      accessType != "view"
    ) {
      let ID = evt.target.getAttribute("id");
      if (ID === "fromVersion") {
        PROJECT.deleteTriggeredFromVersion = true;
      } else {
        PROJECT.deleteTriggeredFromVersion = false;
      }
      PROJECT.showDeleteProjectConfirmationDialog(projectKey, projVersion).then(
        function (param) {
          try {
            PROJECT.deleteProjectAction(param)
              .then(function (result) {
                if (result.status == 200) {
                  PROJECT.cleanUp();
                  DASHBOARD.load();
                  if (useTestData) {
                    // finding the index of project to be deleted and storing it in index variable.
                    let boolMask = projects.map((ele) => {
                      return projectKey == ele.projectkey;
                    });
                    let index = boolMask.indexOf(true);
                    STORE.projects.splice(index, 1);
                  }
                  APP.resetProgress();
                }
              })
              .catch(function (err) {
                APP.showError(err.message);
              });
          } catch (err) {
            APP.showError(err.message);
          }
          APP.resetProgress();
        }
      );
    } else if (buttonClicked && eventClass == editButtonClass) {
      const editableBtn = evt.target;
      const descElement =
        editableBtn.parentElement.parentElement.parentElement.qs(
          ".card-description"
        );
      const inputText = descElement.innerText;
      oldDescriptionValue = inputText;
      const inputDesc = document.createElement("input");

      inputDesc.addClass("description-input");
      inputDesc.value = inputText;
      descElement.parentElement.removeAttribute("tooltip");
      descElement.parentElement.replaceChild(inputDesc, descElement);
      inputDesc.focus();
      inputDesc.addEventListener("input", validateDescriptionInput);

      editableBtn.removeClass("edit-description-btn");
      editableBtn.addClass("save-description-btn");
      editableBtn.setAttribute("tooltip", "Save description");

      let stopBtn = editableBtn.parentElement.qs(".stop-editing");
      stopBtn.removeClass("hidden");
      let versionBox = editableBtn.parentElement.parentElement.parentElement;
      versionBox.addClass("editing-description");
    } else if (buttonClicked && eventClass == saveDescClass) {
      saveAndUpdateDescription(evt, projects, projectKey, projVersion);
    } else if (buttonClicked && eventClass == stopEditClass) {
      const stopEditBtn = evt.target;
      const descElement = document.createElement("div");
      const versionBox = stopEditBtn.parentElement.parentElement.parentElement;
      const inputElement =
        stopEditBtn.parentElement.parentElement.parentElement.qs(
          ".description-input"
        );
      const editableBtn = stopEditBtn.parentElement.qs(".save-description-btn");

      descElement.addClass("card-description");
      descElement.addClass("description-ellipsis");
      descElement.innerHTML = oldDescriptionValue;
      inputElement.parentElement.setAttribute("tooltip", oldDescriptionValue);
      inputElement.parentElement.replaceChild(descElement, inputElement);
      stopEditBtn.addClass("hidden");
      versionBox.removeClass("editing-description");
      editableBtn.removeClass("save-description-btn");
      editableBtn.addClass("edit-description-btn");
    } else if (buttonClicked && eventClass == downloadButtonClass) {
      const dropdownContent = evt.target.parentElement.qs(".dropdown-content");
      if (dropdownContent.hasClass("hidden")) {
        dropdownContent.removeClass("hidden");
        dropdownContent.addClass("active");
      } else {
        dropdownContent.addClass("hidden");
        dropdownContent.removeClass("active");
      }
    } else if (buttonClicked && eventClass == downloadDataClass) {
      const docType = evt.target.getAttribute("docType");
      let downloadUrl = null;
      const button = evt.target;
      const downloadButton = button.parentElement.parentElement;
      if (docType == "documentation") {
        downloadUrl =
          SERVER.getBaseAddress() +
          `downloaddoc?projVersion=${projVersion}&projectKey=${projectKey}`;
        my.downloadDoc(
          {
            projVersion: projVersion,
            projectKey: projectKey,
          },
          button,
          downloadButton,
          downloadUrl
        );
        button.disabled = true;
        button.style.cursor = "not-allowed";
        button.addClass("button-loading");
        downloadButton.addClass("project-download-button-loading");
      } else if (docType == "metadata" || docType == "errorLog") {
        downloadUrl =
          SERVER.getBaseAddress() +
          `downloaddoc?projVersion=${projVersion}&projectKey=${projectKey}&dType=${docType}`;
        my.downloadDoc(
          {
            projVersion: projVersion,
            projectKey: projectKey,
          },
          button,
          downloadButton,
          downloadUrl
        );
        button.disabled = true;
        button.style.cursor = "not-allowed";
        button.addClass("button-loading");
        downloadButton.addClass("project-download-button-loading");
      }
    } else if (buttonClicked && eventClass === settingsClass) {
      showSettingsDialog(projectKey, projVersion);
    }
  };

  var registerEventListenerOnProjectTable = function (projects) {
    for (let project of projects) {
      project.selectedVersion = [];
    }
    // Event Listener on View more
    qsa(".collapsable").forEach((x) => (x.style.display = "none"));
    qsa(".trigger").forEach((x) =>
      x.addEventListener("click", () => {
        index = x.getAttribute("index");
        number = x.getAttribute("numberOfVersions");
        qsa(`.collapse-${index}`).forEach((y) => {
          if (y.style.display === "none") {
            y.style.display = "block";
            x.innerText = "Show Less";
          } else {
            y.style.display = "none";
            x.innerText = `View +${number - 2} more`;
          }
        });
      })
    );

    // Adding event delegation on the whole project view..
    qs("#dashboard-project-view").addEventListener("click", (evt) => {
      // Any column on the header is clicked then, re-rendering the table with sorted data.
      if (my.sortTableIfHeaderClicked(evt)) {
        let projectNode = null;
        // Rendering whole project list table using table.pug
        projectNode = createNode(
          projecttableTemplate({
            data: sortableProjectData,
            headerText: headerText,
            headerKeys: headerKeys,
            tableAttributes: {
              class: "fixed-table",
              id: "dashboard-project-view",
            },
          })
        );
        qs("#dashboard-project-view").remove();
        qs("#main-content .dashboardContent").appendChild(projectNode);
        handleButtonDisabling();
        registerEventListenerOnProjectTable(projects);
        return;
      }

      let eventClass = evt.target.getAttribute("class");
      if (eventClass === "version-create-btn") {
        PROJECT.createTriggeredFromVersion = true;
        my.selectedProjectkey =
          evt.target.parentElement.getAttribute("data-project-key");
        APP.router.navigate("/new-project");
      }
      if (eventClass === "checkbox") {
        let projKey = evt.target.getAttribute("projectkey");
        let version = evt.target.getAttribute("version");
        let element = qs(`.compare-${projKey}`);
        let project = projects.find((v) => v.projectkey == projKey);
        if (evt.target.checked) {
          project.selectedVersion.push(version);
        } else {
          let index = project.selectedVersion.indexOf(version);
          project.selectedVersion.splice(index, 1);
        }
        if (element) {
          if (project.selectedVersion.length >= 2) {
            element.removeClass("hidden");
            element.innerText = `Compare (${project.selectedVersion.length})`;
          } else {
            element.addClass("hidden");
          }
        }
      }
      if (eventClass.includes("compare-button")) {
        my.selectedProjectKeyForComparison =
          evt.target.getAttribute("projectkey");
        let pkey = evt.target.getAttribute("projectkey");
        PROJECT.setCurrentProjectKey(pkey);
        APP.router.navigate(`/version-comparison/${pkey}`);
      }
      if (eventClass === "version-project-name") {
        let target = evt.target.getAttribute("target");
        let projectKey = evt.target.getAttribute("data-project-key");
        let projVersion = evt.target.getAttribute("project-version");
        PROJECT.setCurrentProjectKey(projectKey);
        PROJECT.setCurrentProjVersion(projVersion);
        APP.clearProjectConfigs(projectKey);
        APP.loadProjectProperty({
          projectKey: projectKey,
          propKey: "project",
          projVersion: projVersion,
        }).then(function () {
          APP.router.navigate(target);
        });
      }
      handleVersionLevelIconsClick(evt, eventClass, projects);
    });

    my.downloadDoc = async function (
      iparams,
      button,
      downloadButton,
      downloadUrl
    ) {
      let url = SERVER.getBaseAddress() + "comp";
      let userHash = CREDENTIALS.getUserCreds();
      if (userHash == null) {
        throw new Error(i18n.en.APP.UI.ERROR.MODELCFG.USER_NOT_LOGGED_IN);
      }
      let params = extend(
        {
          key: userHash,
          projectKey: "",
          projVersion: "",
        },
        iparams
      );
      APP.setProgress("Loading download data...", false);
      let result = null;
      try {
        if (useTestData) {
          result = await MC_TEST_DATA.getData(url, params);
        } else {
          result = await SERVER.postData(url, params, false);
        }
      } catch (err) {
        result = null;
        APP.resetProgress();
      }
      APP.resetProgress();
      if (result) {
        setTimeout(window.open(downloadUrl), 2000);
        //to remove loader from option button and download button
        button.disabled = false;
        button.style.cursor = "pointer";
        button.removeClass("button-loading");
        const childrenWithClass = downloadButton.querySelectorAll(
          ":not(:empty).button-loading"
        );
        if (childrenWithClass.length == 0) {
          downloadButton.removeClass("project-download-button-loading");
        }
        return;
      } else {
        return my.downloadDoc(iparams, button, downloadButton);
      }
    };

    qs(".dashboardContent").addEventListener("click", (evt) => {
      let downloadButtonClass = "project-download-btn";
      if (evt.target.className == downloadButtonClass) {
        return;
      }
      const activeDownloadDropdowns = qsa(
        ".dashboardContent .cards .dropdown-content.active"
      );
      activeDownloadDropdowns.forEach((dropdown) => {
        dropdown.addClass("hidden");
        dropdown.removeClass("active");
      });
    });
  };

  const handleButtonDisabling = function () {
    let createProjectBtn = qs("#createProject");
    let projectDeleteBtns = qsa(".project-delete-btn");
    let versionCreateBtns = qsa(".version-create-btn");
    if (accessType == "view") {
      createProjectBtn.disabled = true;
      createProjectBtn.setAttribute("tooltip", "Permission Denied");
      createProjectBtn.setAttribute("flow", "middle-top");
      projectDeleteBtns.forEach((deleteBtn) => {
        deleteBtn.addClass("cursor-disabled");
        deleteBtn.setAttribute("tooltip", "Permission Denied");
        deleteBtn.setAttribute("flow", "middle-down");
      });
      versionCreateBtns.forEach((createBtn) => {
        createBtn.addClass("cursor-disabled");
        createBtn.setAttribute("tooltip", "Permission Denied");
        createBtn.setAttribute("flow", "middle-down");
      });
    }
    if (accessType != "normal") {
      qs(".dashboardContent .fixed-table .heading ul").addClass(
        "with-created-by"
      );
      qsa(".dashboardContent .fixed-table .cards .project-card").forEach(
        (projectCard) => {
          projectCard.qs(".parent-project").addClass("with-created-by");
          projectCard
            .qsa(".version-box")
            .forEach((versionBox) => versionBox.addClass("with-created-by"));
        }
      );
    }
  };

  /**
   * @method renderProjects
   * @description render all the project boxes
   * @throws {Error} Throws an error in case of a server error or if the userHash is not obtainable.
   * @param {array} projects list of all project data items obtained from projects/all API
   * @async
   * @public
   */
  my.renderProjects = function (projects) {
    my.projects = projects;
    filteredProjects = projects;
    const config = APP.getProperty("common.project.states");
    let summaryObj = {};
    projects.forEach((project, index) => {
      summaryObj["projects"] = (summaryObj["projects"] || 0) + 1;
      // Added a `namedState` field in project object.
      // `namedState` value is set on the basis on string representation of project using config array.
      // namedState : string representation of project state.
      // `namedState` field is used as headerKey to display project state in table.
      if (project.state == 6) {
        project = extend(project, { namedState: "Completed" });
      } else {
        config.forEach((v) => {
          if (v.value == project.state) {
            project = extend(project, { namedState: v.name });
          }
        });
      }

      project = extend(project, {
        selectedVersion: [],
      });
      // adding some fields in versionInfo
      for (version of project.versionInfo) {
        summaryObj["versions"] = (summaryObj["versions"] || 0) + 1;
        if (version.state == 6) {
          version = extend(version, { namedState: "Completed" });
          summaryObj["Completed"] = (summaryObj["Completed"] || 0) + 1;
        } else {
          config.forEach((v) => {
            if (v.value == version.state) {
              version = extend(version, { namedState: v.name });
              summaryObj[v.name] = (summaryObj[v.name] || 0) + 1;
            }
          });
        }
        // version = extend(version, { buttonPanel: "buttonPanel" });
        if (version.state == 2) {
          version = extend(version, {
            target: "/db-connection",
          });
        } else if (version.state == 3) {
          version = extend(version, {
            target: "/workflow-start",
          });
        } else if (version.state == 4) {
          version = extend(version, {
            target: "/ae-automodel",
          });
        } else if (version.state == 5) {
          version = extend(version, {
            target: "/mp/model-comparison",
          });
        } else if (version.state == 6) {
          version = extend(version, {
            target: "/mp/model-comparison",
          });
        } else {
          version = extend(version, { target: "/" });
        }
      }
      // Replacing the project at index with modified project object.
      projects[index] = project;
    });
    qs("#main-content .dashboardContent").innerHTML = "";

    // Rendering search bar
    qs("#main-content .dashboardContent").innerHTML = projectsearchbarTemplate({
      summaryObj: summaryObj,
    });

    // Redering project count
    qs("#project-count").innerHTML = `Total Projects: ${projects.length}`;

    // Event listener for hiding and unhidng the summary container
    qs(".summary-btn").addEventListener("click", (evt) => {
      qs(".dashboard-summary-container").classList.toggle("hidden");
      evt.target.classList.toggle("selected");
    });

    // Event listener for filtering the the project table
    qsa(".filter-icon").forEach((x) =>
      x.addEventListener("click", (evt) => {
        let state = evt.target.getAttribute("state");
        qsa(".second-row").forEach((clearFilter) =>
          clearFilter.addClass("hidden")
        );
        qs(`.${state} .second-row`).removeClass("hidden");
        qsa(".summary-box").forEach((summaryBoxes) =>
          summaryBoxes.removeClass("selected")
        );
        evt.target.parentElement.parentElement.parentElement.addClass(
          "selected"
        );
        filteredProjects = JSON.parse(JSON.stringify(projects));
        for (proj of filteredProjects) {
          proj.versionInfo = proj.versionInfo.filter(
            (version) => version.namedState == state
          );
        }
        filteredProjects = filteredProjects.filter(
          (proj) => proj.versionInfo.length > 0
        );
        let projectNode = null;
        // Rendering whole project list table using table.pug
        projectNode = createNode(
          projecttableTemplate({
            data: JSON.parse(JSON.stringify(filteredProjects)),
            headerText: headerText,
            headerKeys: headerKeys,
            tableAttributes: {
              class: "fixed-table",
              id: "dashboard-project-view",
            },
          })
        );
        qs("#dashboard-project-view").remove();
        qs("#main-content .dashboardContent").appendChild(projectNode);
        sortableProjectData = JSON.parse(JSON.stringify(filteredProjects));
        registerEventListenerOnProjectTable(projects);
        handleButtonDisabling();
        qs("#project-search-field").value = "";
      })
    );

    // Event listener for resetting the project table
    qsa(".second-row").forEach((x) =>
      x.addEventListener("click", (evt) => {
        evt.target.addClass("hidden");
        qsa(".summary-box").forEach((summaryBoxes) =>
          summaryBoxes.removeClass("selected")
        );
        let projectNode = null;
        // Rendering whole project list table using table.pug
        projectNode = createNode(
          projecttableTemplate({
            data: projects,
            headerText: headerText,
            headerKeys: headerKeys,
            tableAttributes: {
              class: "fixed-table",
              id: "dashboard-project-view",
            },
          })
        );
        qs("#dashboard-project-view").remove();
        qs("#main-content .dashboardContent").appendChild(projectNode);
        sortableProjectData = JSON.parse(JSON.stringify(projects));
        filteredProjects = JSON.parse(JSON.stringify(projects));
        handleButtonDisabling();
        registerEventListenerOnProjectTable(projects);
        qs("#project-search-field").value = "";
      })
    );

    let projectNode = null;
    // Rendering whole project list table using table.pug
    projectNode = createNode(
      projecttableTemplate({
        data: projects,
        headerText: headerText,
        headerKeys: headerKeys,
        tableAttributes: {
          class: "fixed-table",
          id: "dashboard-project-view",
        },
      })
    );

    qs("#main-content .dashboardContent").appendChild(projectNode);
    // Create Project
    qs("#createProject").addEventListener("click", () => {
      PROJECT.createTriggeredFromVersion = false;
      APP.router.navigate("/new-project");
    });
    sortableProjectData = projects;
    my.sortProjects("creation_ts"); //sorts the projects based on creation_ts in descending order on initial load
    registerEventListenerOnProjectTable(projects);
    APP.nav.qs("#navItem-dashboard-nav-link").removeClass("hidden");
    handleButtonDisabling();
  };

  /**
   * @method init
   * @description if not previously inited, load all the projects into the dashboard and listen
   * for changes to the project list
   * @throws {Error} Throws an error in case of a server error or if the userHash is not obtainable
   * @async
   * @private
   */
  const init = function () {
    if (inited) return;
    qs("#main-content").classList.add("dashboard");
    projectsObserver = STORE.addObserver("projects", my.renderProjects);
    my.inited = true;
  };

  /**
   * @method workflowStart
   * @description Once a new project has been created and its data source connected,
   * this method is called to show the page with the workflow start graphic.
   * @throws {Error} Throws an error in case of a server error or if the userHash is not obtainable.
   * @async
   * @param {string} projectKey The ID of the project that was just created.
   * @public
   */
  my.workflowStart = function (projectKey) {
    my.unload();
    APP.showInfo("DB connection created successfully.");

    projectKey = projectKey ? projectKey : PROJECT.currentProjectKey();
    const workflowStartAction = function () {
      qs(
        "#main-content .workflow-start button#workflow-start"
      ).removeEventListener("click", workflowStartAction);
      if (
        STORE.getProjectMetadata(
          projectKey,
          PROJECT.currentProjVersion(),
          VALIDATE_FEATURES.STORE_KEY + "_features_saved"
        ) === true
      ) {
        APP.router.navigate(`/explore-data/${projectKey}`);
      } else {
        APP.router.navigate(`/validate-features/${projectKey}`);
      }
    };
    qs("#main-content .workflow-start button#workflow-start").addEventListener(
      "click",
      workflowStartAction
    );
  };

  return my;
})(DASHBOARD || {});
