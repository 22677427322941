var GLOBAL_AND_LOCAL_INTERP = (function(my){

  /**
   * @member {Number} pollTimeoutToken
   * @description This is the reference that can be used to find the current timeOut that's waiting.
   * @public
  */
  my.pollTimeoutToken = null;

  /**
   * @member {boolean} continuePolling
   * @description Polling stops if this is false at the time of evaluating the exit condition. This is set to `false`
   * by the [cancelPolling](#~cancelPolling) function.
   * @public
  */
  my.continuePolling = true;

  /**
   * @method loadData
   * @description Loads the data from the API on the server and returns it
   * @param {string} endpoint for the interpretability data, it should be globinterp or localinterp.
   * @param {string} projectKey id of the project for which to get data
   * @param {string} projVersion version id of the project for which to get data
   * @return {object} Raw interpretability data obtained from the API.
   * @private
   * @async
  */
  const loadData = async function(endpoint, projectkey, projVersion){
    let  iparams = {};
    let userHash = CREDENTIALS.getUserCreds();
    if (userHash == null) {
      throw new Error(i18n.en.APP.UI.ERROR.MODELCFG.USER_NOT_LOGGED_IN);
    }
    let params = extend({
      key: userHash,
      projectKey: projectkey,
      projVersion: projVersion,
    }, iparams);

    if(endpoint === "localinterp"){
      params = extend({
        requestList: MP_EAI_LOCAL.searchBarValues.searchIDs,
        modelName: empty(MP_EAI_LOCAL.searchBarValues.modelID) ? null : MP_EAI_LOCAL.searchBarValues.modelID,
        source: empty(MP_EAI_LOCAL.searchBarValues.source) ? null : MP_EAI_LOCAL.searchBarValues.source,
      },params);

      if (empty(params.requestList)) {
        params.modelName = null;
      }
    }

    let url = SERVER.getBaseAddress() + endpoint;
    let result = null;
    try {
        if (useTestData) {
          if(endpoint === "globinterp"){
            result = await EAI_GLOBAL_TEST_DATA.getGlobalInterpretabilityData();
          }
          if(endpoint === "localinterp"){
            result = await EAI_LOCAL_TEST_DATA.getLocalInterpretabilityData(params);
          }
        } else {
          result = await SERVER.postData(url, params);
        }
    } catch (err) {
        result = null;
      }
      if(result === "ROUTES_MISMATCHED"){
        return;
      }
      if (result == null || (result.status != "success" && !(result.status >= 100 && result.status < 300))) {
        APP.showError(`${i18n.en.APP.UI.ERROR.MODELPERFORMANCE.GENERIC} Please contact an Administrator.`);
        let err = new Error(i18n.en.APP.UI.ERROR.MODELPERFORMANCE.GENERIC); err.name = 'GenericError'; throw err;
      }
    return result;
  };

  /*
   * @method pollForData
   * @description Start polling the globalinterp api or localinterp api based on the endpoint provided until interrupted by user action or by occurance of success or failure
   * #### HOW THE POLLING CODE WORKS
   * There's a continuePolling flag that is checked each time the timer is restarted.
   * When the polling is first started, it returns a Promise that is resoved when the
   * self referential timer hits an exit condition. An exit condition is hit either when
   * the user navigates away from the page, or when the server call returns a non 198 result status
   * @return {Promise} Returns a promise that is resolved to a completion status. It is
   * rejected in case of any error or elapse of timeout.
   * @param {Number} timeout If the timer loop is never interrupted it should be stopped in
   * this much time in milliseconds
   * @param {Number} interval Loop every interval seconds.
   * @param {string} projectkey of the current project.
   * @param {string} projVersion of the current selected project.
   * @public
   * used by version-comparison.js, mp-eai-global.js, mp-eai-local.js
  */
  my.pollForData = async function(action, timeout, interval, projectkey, projVersion, fromVersionComparison = false){
    var endTime = Number(new Date()) + (timeout || 6 * 60 * 60 * 1000);
    interval = interval * 1000 || 10 * 1000;
    my.continuePolling = true;

    var checkCondition = async function (resolve, reject) {
      my.pollTimeoutToken = null;
      let displayText = "displaying local interpretability";
      if(this.action === "globinterp"){
        displayText = "global interpretability";
      }
      // If the condition is met, we're done!
      try {
        if(!fromVersionComparison){
          APP.setProgress("Polling for data...", false);
        }
        var result = await loadData(this.action, this.projectkey, this.projVersion);
        if(!fromVersionComparison){
          APP.resetProgress();
        }
      } catch (err) {
        console.error("Exception in polling.\n", err);
        APP.showError(sprintf(i18n.en.APP.UI.ERROR.MODELPERFORMANCE.POLLING_FAILURE, displayText));
        return reject(err);
      }
      if (empty(result) || typeof result == "undefined") {
        console.error("Null result in polling.")
        return reject(new Error(sprintf(i18n.en.APP.UI.ERROR.MODELPERFORMANCE.POLLING_FAILURE, displayText)));
      }
      if (!empty(result) && !empty(result.status) && (result.status >= 200 && result.status < 300)) {
        return resolve(result);
      }
      else if (!empty(result) && !empty(result.status) && (result.status == 198)) {
        // If polling is again needed
        my.continuePolling = true;
      }
      // If the condition isn't met but the timeout hasn't elapsed, go again
      else if (Number(new Date()) < endTime) {
        if (my.continuePolling) {
          pollTimeoutToken = setTimeout(checkCondition.bind({action:action,projectkey:projectkey,projVersion:projVersion}), interval, resolve, reject);
        } else {
          my.continuePolling = true;
          return reject({ State: "interrupted" });
        }
      }
      // Didn't match and too much time, reject!
      else {
        return reject(new Error('timed out for ' + my.pollForData + ': ' + arguments));
      }
    };

    return new Promise(checkCondition.bind({action:action,projectkey:projectkey,projVersion:projVersion}));
  };

  /**
   * @method cancelPolling
   * @description Sets the [continuePolling](#~continuePolling) flag false. Also clears any pending
   * polling timer.
   * @public
   */
  my.cancelPolling = function(){
    my.continuePolling=false;
    if (my.pollTimeoutToken){
      clearTimeout(my.pollTimeoutToken);
    }
    my.pollTimeoutToken = null;
  };

  return my;
})(GLOBAL_AND_LOCAL_INTERP || {});
