/* eslint-disable no-unused-vars */
/* global MD_SCORE_MODELS_TEST_DATA, DASHBOARD_DATA*/
/**
 * @module APP
 * @description Provides the application entrypoint and app level scope for
 * methods that affect the whole application. This file contains helper functions to load
 * data from the server that is required in multiple modules
 */

// eslint-disable-next-line no-extra-semi
APP = (function (my) {
  /**
   * @method getAllProjects
   * @description get a list of all projects from the server by calling the projects/all API
   * @throws {Error} Throws an error in case of a server error or if the userHash is not obtainable.
   * @async
   * @private
   */
  my.getAllProjects = async function () {
    let url = SERVER.getBaseAddress() + "projects/all";
    let userHash = CREDENTIALS.getUserCreds();
    APP.setProgress(i18n.en.APP.UI.FOOTER.PROGRESS.GET_ALL_PROJECTS);
    if (userHash == null) {
      throw new Error(
        i18n.en.APP.UI.ERROR.USER_NOT_LOGGED_IN_CANNOT_RETRIEVE_PROJECTS
      );
    }
    let params = { key: userHash };
    var result = null;
    var projects = null;
    try {
      if (useTestData) {
        result = await DASHBOARD_DATA.getData(url, params);
      } else {
        result = await SERVER.postData(url, params);
      }
      APP.resetProgress();
    } catch (err) {
      result = null;
      APP.resetProgress();
    }
    if (result === "ROUTES_MISMATCHED") {
      return;
    }
    try {
      if (result != null) {
        if (
          result.status === "success" ||
          (result.status >= 200 && result.status < 300)
        ) {
          // projects = result.data.posts; initially
          projects = result;
        }
      }
    } catch (err) {
      throw new Error(i18n.en.APP.UI.ERROR.PROJECT_RETRIEVAL);
    }
    if (projects == null) {
      throw new Error(i18n.en.APP.UI.ERROR.PROJECT_RETRIEVAL);
    }
    return projects;
  };

  /**
   * @method getModelConfigurationData
   * @description gets modelconfiguration tab data from the server by calling the automl/v1/apis/config/getexpcfg API
   * @throws {Error} Throws an error in case of a server error or if the userHash is not obtainable.
   * @async
   * @private
   */
  my.getModelConfigurationData = async function () {
    return API_HELPER.getAndResetExpcfg("config/getexpcfg", "get");
  };

  /**
   * @method resetModelConfigurationData
   * @description gets default reset data from the server by calling the /config/resetexpcfg API.
   * @throws {Error} Throws an error in case of a server error or if the userHash is not obtainable.
   * @async
   * @private
   */
  my.resetModelConfigurationData = async function () {
    return API_HELPER.getAndResetExpcfg("config/resetexpcfg", "reset");
  };

  /**
   * @method postModelConfigurationData
   * @description posts modelconfiguration tab data to the server by calling the /config/saveexpcfg API
   * @throws {Error} Throws an error in case of a server error or if the userHash is not obtainable.
   * @async
   * @private
   */
  my.postModelConfigurationData = async function (postData) {
    let url = SERVER.getBaseAddress() + "config/saveexpcfg";
    let userHash = CREDENTIALS.getUserCreds();
    APP.setProgress(
      i18n.en.APP.UI.FOOTER.PROGRESS.SAVE_EXPERT_EXPERIMENTATION_DATA
    );
    if (userHash == null) {
      throw new Error(
        i18n.en.APP.UI.ERROR.USER_NOT_LOGGED_IN_CANNOT_SAVE_EXPERT_EXPERIMENTATION_DATA
      );
    }
    let params = {
      key: userHash,
      projectKey: PROJECT.currentProjectKey(),
      projVersion: PROJECT.currentProjVersion(),
      info: { ...postData },
    };

    var result = null;
    try {
      if (useTestData) {
        // result = await MODEL_CONFIGURATION.postData(url, params);
        result = { data: {}, status: 200 };
      } else {
        // Write here logic for retreiving correct project key, key and send it in params.
        // PROJECT.currentProjectKey(); This function returns project key.
        result = await SERVER.postData(url, params);
      }
      APP.resetProgress();
    } catch (err) {
      result = null;
      APP.resetProgress();
    }
    if (result === "ROUTES_MISMATCHED") {
      return;
    }
    try {
      if (result != null) {
        if (
          result.status === "success" ||
          (result.status >= 200 && result.status < 300)
        ) {
          result = result.data;
        }
      }
    } catch (err) {
      throw new Error(i18n.en.APP.UI.ERROR.SAVE_EXPERT_EXPERIMENTATION_DATA);
    }
    if (result == null) {
      throw new Error(i18n.en.APP.UI.ERROR.SAVE_EXPERT_EXPERIMENTATION_DATA);
    }
    if (result.status == 404) {
      APP.showError(result.data.reason);
      result = null;
    }
    return result;
  };

  /**
   * @method loadAvailableSources
   * @description Get the sources currently available from the [SERVER](module-SERVER.html)'s connection/list/sources API
   * @param {object} iparams userHash(optional) and projectKey(required)
   * @property {string} iparams.projectKey id of the project for which to get scores
   * @public
   * @async
   */
  my.loadAvailableSources = async function (iparams) {
    let url = SERVER.getBaseAddress() + "connection/list/sources";
    let userHash = CREDENTIALS.getUserCreds();
    if (!empty(iparams.showProgress)) {
      //show progress if not explicitly not allowed
      APP.setProgress("Loading Sources...", true);
    }
    if (userHash == null) {
      throw new Error(i18n.en.APP.UI.ERROR.MODELCFG.USER_NOT_LOGGED_IN);
    }
    let params = extend(
      {
        key: userHash,
        projectKey: "",
        projVersion: "",
      },
      iparams
    );
    delete params.showProgress;
    let result = null;
    try {
      if (useTestData) {
        result = await MD_SCORE_MODELS_TEST_DATA.getSources(url, params);
      } else {
        result = await SERVER.postData(url, params);
      }
    } catch (err) {
      result = null;
    }
    if (result === "ROUTES_MISMATCHED") {
      return;
    }
    if (!empty(iparams.showProgress)) {
      //hide progress only if explicitly shown if not explicitly not allowed
      APP.resetProgress();
    }
    if (
      result == null ||
      (result.status != "success" &&
        !(result.status >= 200 && result.status < 300))
    ) {
      let msg = "";
      if (!empty(result) && !empty(result.data) && !empty(result.data.reason)) {
        msg = result.data.reason;
      }
      msg = sprintf(
        i18n.en.APP.UI.ERROR.MODELDEPLOYMENT.GET_SOURCES_ERROR,
        msg
      );
      let err = new Error(msg);
      err.name = "ConnectionListError";
      throw err;
    }
    return result;
  };

  return my;
})(APP || {});
