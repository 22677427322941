var MODEL_INTERPRETABILITY = (function (my) {
  /**
   * @member {Element} separator
   * @description The DOM element that acts as the draggable separator between the table and the charts area.
   * @private
   */
  var separator = null;

  /**
   * @member {boolean} dragging
   * @description While the user is dragging the [separator](#~separator) with the mouse, this variable is `true`.
   * @private
   */
  var dragging = false;

  /**
   * @member {number} originalLHSWidth
   * @description stores the width of the table area when the view first comes up. This is used to restrict
   * the dragging of the separator to this original size.
   * @private
   */
  var originalLHSWidth = null;

  /**
  * @member {object} charts
  * @description Stores the chart objects created using `d3.js` or `c3.js`.
  * @private
  */
  let charts = {};

  /**
   * @member {object} data
   * @description Holds the leaderboard data.
   * @private
   */
  var data = null;

  /**
   * @member {string} dataSource
   * @description Whether the current view is for the "test" or "training" data.
   * @private
   */
  var datasource = null;

  /**
   * @member {string} selectedModelID
   * @description Whether the current view is for the selected model ID from the dropdown.
   * @private
   */
  var selectedModelID = null;

  /**
   * @member {string} formattedSideBarData
   * @description formatted data to generate the horizontal bar graphs at the right side.
   * @private
   */
  var formattedSideBarData = {};

  /**
   * @member {string} radarChartType
   * @description can have two values-
   * variables : shows the radar chart with respect to the features.
   * clusters : shows the radar chart with respect to the clusters.
   * @private
   */
  var radarChartType = "variables";

  /**
   * @member {string} sortedData
   * @description data for the horizontal bar charts in descending order.
   * @private
   */
  var sortedData = {};

  /**
   * @method sortGraphData
   * @description sorts side bar graph data so that the clusters are displayed in order
   * of their importance
   */
  const sortGraphData = function (sortableData) {
    if (empty(sortableData)) {
      throw new Error("No graphdata to sort.");
    }

    const pairs = [];
    for (let i = 0; i < sortableData.features.length; i++) {
      pairs.push({
        feature: sortableData.features[i],
        score: sortableData.scores[i],
      });
    }
    pairs.sort(function (a, b) {
      return b.score - a.score;
    });
    for (let i = 0; i < pairs.length; i++) {
      sortableData.features[i] = pairs[i].feature;
      sortableData.scores[i] = pairs[i].score;
    }
    return sortableData;
  };

  /**
  * @method getDataSourceOptions
  * @description fetches the available data sources by calling an API and populates the sources dropdown.
  */
  const getDataSourceOptions = async function (source) {
    let sourcesList = [];
    try {
      sourcesList = await APP.loadAvailableSources({
        projectKey: PROJECT.currentProjectKey(),
        projVersion: PROJECT.currentProjVersion(),
      });

      if (
        sourcesList.data.posts[1]["OutOfTime"] &&
        sourcesList.data.posts[1]["OutOfTime"].length > 0
      ) {
        sourcesList = sourcesList.data.posts[1]["OutOfTime"];
        if (!sourcesList) {
          sourcesList = [];
        }
      } else {
        sourcesList = [];
      }
    } catch (err) {
      console.error(err.message);
      APP.showError(err.message);
      sourcesList = [];
    }
    const dataSourceList = sourcesList;
    const sourceOptionTemplate = qs("#sourceSelect .template");
    dataSourceList.forEach((src) => {
      if (empty(src.hasTarget)) {
        return;
      }
      const option = sourceOptionTemplate.cloneNode();
      ["value", "style", "class", "disabled"].forEach((attr) =>
        option.removeAttribute(attr)
      );
      option.setAttribute("value", src.id);
      option.innerText = src.name;
      qs("#sourceSelect").append(option);
    });
    datasource = empty(source) ? "train" : source;
    if (datasource != 'train') {
      let sourceSelect = qs(".variable-clustering #sourceSelect");
      sourceSelect.value = datasource;
    }
  };

  /**
  * @method getModelOptions
  * @description fetches the available models and populates the model dropdown.
  */
  const getModelOptions = function (model) {
    const modelList = Object.keys(data);
    selectedModelID = empty(model) ? modelList[0] : model.toUpperCase();
    const selectElement = qs("#modelSelect");
    modelList.forEach((modelID) => {
      const optionElement = document.createElement("option");
      optionElement.value = modelID;
      optionElement.textContent = data[modelID].name;
      if (modelID === selectedModelID) {
        optionElement.selected = true;
      }
      selectElement.appendChild(optionElement);
    });
  };

  /**
  * @method dataSourceChanged
  * @description reloads the page with the selected data source from the dropdown
  */
  const dataSourceChanged = function () {
    const selectElement = qs(".model-interpretability #sourceSelect");
    const selectedOption = selectElement.options[selectElement.selectedIndex];
    const selectedValue = selectedOption.value;
    datasource = selectedValue;
    reloadData();
  };

  /**
  * @method modelChanged
  * @description reloads the page with the selected model from the dropdown
  */
  const modelChanged = function () {
    const selectElement = qs(".model-interpretability #modelSelect");
    const selectedOption = selectElement.options[selectElement.selectedIndex];
    const selectedValue = selectedOption.value;
    selectedModelID = selectedValue;
    reloadData();
  };

  /**
  * @method resizeListener
  * @description window resize listener for redrawing graphs on zoom
  * @params evt resize event
  * @public
  */
  my.resizeListener = function (evt) {
    my.redrawGraphs();
  };

  /**
   * @method redrawGraphs
   * @description Ask `d3.js` or `c3.js` to redraw the graphs with the data they already have. Needed in case of
   * resizing.
   * @public
   */
  my.redrawGraphs = function () {
    CHART.allowedCharts[APP.getCurrentPageEndToken()].forEach((x) => {
      if (empty(charts[x])) return;
      if (charts[x].constructor === Array) {
        charts[x].forEach((chart) => {
          if (empty(chart)) return;
          let chartElement = chart.element;
          if (chart.isD3Chart) {
            chartElement = chart.element.node();
          }
          if (!chartElement.closest(".graphs-outer-area").hasClass("active")) {
            return;
          }
          let outerContainer = chartElement.closest(".graph-outer-container");
          if (outerContainer.offsetWidth < 30) {
            return;
          }
          chart.resize({
            width: outerContainer.offsetWidth - 30,
          });
        });
      } else {
        if (empty(charts[x])) return;
        let chartElement = charts[x].element;
        if (charts[x].isD3Chart) {
          chartElement = charts[x].element.node();
        }
        if (!chartElement.closest(".graphs-outer-area").hasClass("active")) {
          return;
        }
        let outerContainer = chartElement.closest(".graph-outer-container");
        if (outerContainer.offsetWidth < 30) {
          return;
        }
        charts[x].resize({
          width: outerContainer.offsetWidth - 30,
          height:
            outerContainer.offsetHeight -
            outerContainer.qs("h4").offsetHeight -
            22,
        });
      }
    });
  };

  /**
   * @method registerModelInterpretabilityListeners
   * @description Register listeners for:
   * * separator drag-and-drop
   * * source selection
   * * model selection
   * * controlling the number of clusters in the side bar graph
   * * window resizing
   * * toggling the radar chart
   * @private
   */
  var registerModelInterpretabilityListeners = function () {
    const modelInterpretability = qs(".model-interpretability");
    separator = modelInterpretability.qs(".separator");

    //splitter drag listener
    modelInterpretability.addEventListener("mousedown", (evt) => {
      if (evt.target === separator && evt.buttons === 1) {
        dragging = true;
        if (originalLHSWidth == null) {
          originalLHSWidth = qs("#selection-method-container").offsetWidth;
        }
      }
    });

    modelInterpretability.addEventListener("mouseup", () => {
      if (!dragging) return;
      dragging = false;
      requestAnimationFrame(my.redrawGraphs);
    });

    modelInterpretability.addEventListener("mousemove", (evt) => {
      if (dragging) {
        let oldValue = parseInt(
          window.getComputedStyle(modelInterpretability).getPropertyValue("--graph-area-delta")
        );
        if (originalLHSWidth + oldValue < 410 && evt.movementX < 0) {
          return;
        } //don't reduce below 400px lhs
        modelInterpretability.style.setProperty(
          "--graph-area-delta",
          oldValue + evt.movementX
        );
      }
    });

    if (!my.windowListenerAdded) {
      window.addEventListener("resize", my.resizeListener);
      my.windowListenerAdded = true;
    }

    modelInterpretability.qs("#sourceSelect").addEventListener("change", dataSourceChanged);
    modelInterpretability.qs("#modelSelect").addEventListener("change", modelChanged);

    // toogle button listner on radar chart
    modelInterpretability.qs(".toggle input").addEventListener(
      "change",
      function () {
        if (this.checked) {
          radarChartType = "clusters";
        } else {
          radarChartType = "variables";
        }
        loadRadarChart(radarChartType);
      }
    );
  };

  /**
  * @method reloadData
  * @description reloads the cluster charts and the radar chart.
  */
  const reloadData = function () {
    loadClusterCharts();
    loadRadarChart(radarChartType);
  };

  /**
  * @method formatSideBarChartData
  * @description formats the horizontal bar graphs data suitable to be rendered on UI.
  */
  const formatSideBarChartData = function (varImpData, index) {
    var sidebarData = {};
    sidebarData["features"] = varImpData.featureNames;
    sidebarData["scores"] = varImpData.varImpInfo[index];
    return sidebarData;
  };

  /**
   * @method populateGraphDropdown
   * @description populates the features dropdown on the right side.
   */
  const populateGraphDropdown = function (barData) {
    var barCountSelect = qs(".model-interpretability #feature-count-select");
    qs(".model-interpretability .features-dropdown").style.display = "flex"; // Making feature count dropdown visible
    qs(
      ".model-interpretability .graphs-container .graph-outer-container"
    ).style.marginTop = "5rem"; // Adding space for drop-down
    barCountSelect.innerHTML = ""; // Cleared previously loaded feature count if present
    var defaultSelectedOption = false; // flag for make only one option selected by default
    var numberOfFeatures = barData.features.length - 1; // Number of feature

    // Loop to add number of feature counts in drop-down

    for (let feature in barData.features) {
      var value = parseInt(feature) + 1;
      // condition to make one option selected by default
      if (
        (feature == 9 || feature == numberOfFeatures) &&
        !defaultSelectedOption
      ) {
        defaultSelectedOption = true;
        barCountSelect.innerHTML += `<option value=${value} selected>${value}</option>`;
      } else {
        barCountSelect.innerHTML += `<option value=${value}>${value}</option>`;
      }
    }
  };

  /**
   * @method generateSideBarChart
   * @description generates the horizontal bar chart for each cluster using feature-importance-chart.js file.
   */
  const generateSideBarChart = function (
    index,
    numberOfFeatures = null,
    regenerate = false
  ) {
    APP.setProgress("Rendering plot...", false);
    formattedSideBarData = {};
    formattedSideBarData = formatSideBarChartData(
      JSON.parse(
        JSON.stringify(data[selectedModelID][datasource].clusterVarImp)
      ),
      index
    );
    formattedSideBarData = sortGraphData(formattedSideBarData);
    sortedData[index] = JSON.parse(JSON.stringify(formattedSideBarData));
    if (index == 0 && !regenerate) {
      populateGraphDropdown(formattedSideBarData);
    }
    var featuresArray = regenerate
      ? formattedSideBarData.features
      : formattedSideBarData.features.slice(0, 10); // Slicing feature array to show only atmost 10 clusters after loading
    var scoresArray = regenerate
      ? formattedSideBarData.scores
      : formattedSideBarData.scores.slice(0, 10);
    var barCountSelect = qs(".model-interpretability #feature-count-select");
    barCountSelect.setAttribute(
      "onfocus",
      `this.size=${featuresArray.length >= 10 ? 10 : featuresArray.length};`
    ); // Set the size of drop-down list
    requestAnimationFrame(() => {
      if (charts[FEATURE_IMPORTANCE_CHART.type]) {
        if (charts[FEATURE_IMPORTANCE_CHART.type].constructor !== Array) {
          charts[FEATURE_IMPORTANCE_CHART.type] = [
            charts[FEATURE_IMPORTANCE_CHART.type],
          ];
        }
        charts[FEATURE_IMPORTANCE_CHART.type].push(
          FEATURE_IMPORTANCE_CHART.generate(
            {
              data: {
                y1: {
                  name: "y",
                  keys: regenerate
                    ? featuresArray.slice(0, numberOfFeatures)
                    : featuresArray,
                  values: scoresArray,
                },
              },
              c3d3properties: {
                onrendered: APP.resetProgress,
              },
            },
            index,
            "modelInterpretability"
          )
        );
      } else {
        charts[FEATURE_IMPORTANCE_CHART.type] =
          FEATURE_IMPORTANCE_CHART.generate(
            {
              data: {
                y1: {
                  name: "y",
                  keys: regenerate
                    ? featuresArray.slice(0, numberOfFeatures)
                    : featuresArray,
                  values: scoresArray,
                },
              },
              c3d3properties: {
                onrendered: APP.resetProgress,
              },
            },
            index,
            "modelInterpretability"
          );
      }
    });
  };

  /**
   * @method featureDropdownListner
   * @description Event listener for changing the numer of feature count in right side bar graphs.
   */
  const featureDropdownListner = function () {
    const featureDropdown = qs(
      ".model-interpretability .graphs-container #feature-count-select"
    );
    featureDropdown.addEventListener("change", (evt) => {
      const dropdownValue = evt.target.value;
      featureDropdown.value = dropdownValue;
      sortedData = {};
      for (
        let i = 0;
        i < data[selectedModelID][datasource].clusterVarImp.clusterNames.length;
        i++
      ) {
        generateSideBarChart(i, dropdownValue, true);
      }
      requestAnimationFrame(my.redrawGraphs);
      loadRadarChart(radarChartType);
    });
  };

  /**
   * @method loadClusterCharts
   * @description Loads the cluster charts on the right side
   */
  const loadClusterCharts = function () {
    sortedData = {};
    qs(".model-interpretability .graphs-container").innerHTML = "";
    qs(".model-interpretability .graphs-container").innerHTML =
      modelinterpretabilitysidebargraphsTemplate({
        data: data[selectedModelID][datasource].clusterVarImp,
      });
    for (
      let i = 0;
      i < data[selectedModelID][datasource].clusterVarImp.clusterNames.length;
      i++
    ) {
      generateSideBarChart(i);
    }
    requestAnimationFrame(my.redrawGraphs);
    featureDropdownListner();
  };

  /**
   * @method loadRadarChart
   * @description Loads the radar chart based on the radar chart type.
   */
  var loadRadarChart = function (radarChartType) {
    var config = {
      w: 350,
      h: 350,
      facet: false,
      levels: 5,
      levelScale: 0.85,
      labelScale: 0.9,
      facetPaddingScale: 2.1,
      showLevels: true,
      showLevelsLabels: false,
      showAxesLabels: true,
      showAxes: true,
      showLegend: true,
      showVertices: true,
      showPolygons: true,
    };

    function createGraphData(clusterData, radarChartType) {
      let featuresToBePicked = qs(
        ".model-interpretability #feature-count-select"
      ).value;
      let featuresPicked = 0;
      let formattedRadarData = [];
      let featuresVisited = [];
      let index = 0;
      let numberOfClusters = Object.keys(sortedData).length;
      while (featuresPicked != featuresToBePicked) {
        for (let x = 0; x < numberOfClusters; x++) {
          if (featuresPicked == featuresToBePicked) {
            break;
          }
          let feature = sortedData[x].features[index];
          if (featuresVisited.indexOf(feature) != -1) {
            for (let j = x == numberOfClusters - 1 ? 0 : x + 1; j != x; j++) {
              feature = sortedData[j].features[index];
              if (featuresVisited.indexOf(feature) != -1) {
                if (j == numberOfClusters - 1) {
                  j = -1;
                } else {
                  continue;
                }
              } else {
                featuresVisited.push(feature);
                break;
              }
            }
          } else {
            featuresVisited.push(feature);
          }
          var groupObj = {};
          if (radarChartType === "variables") {
            for (let i = 0; i < clusterData.clusterNames.length; i++) {
              let featureIndex = sortedData[i].features.indexOf(feature);
              let item = formattedRadarData.find(
                (item) => item.group == clusterData.clusterNames[i]
              );
              if (item) {
                item.axes.push({
                  axis: feature,
                  value: sortedData[i].scores[featureIndex],
                  description: "",
                });
              } else {
                groupObj = {
                  group: clusterData.clusterNames[i],
                  axes: [],
                };
                groupObj.axes.push({
                  axis: feature,
                  value: sortedData[i].scores[featureIndex],
                  description: "",
                });
                formattedRadarData.push(groupObj);
              }
            }
          } else {
            for (let i = 0; i < clusterData.clusterNames.length; i++) {
              let featureIndex = sortedData[i].features.indexOf(feature);
              let item = formattedRadarData.find(
                (item) => item.group == feature
              );
              if (item) {
                item.axes.push({
                  axis: clusterData.clusterNames[i],
                  value: sortedData[i].scores[featureIndex],
                  description: "",
                });
              } else {
                groupObj = {
                  group: feature,
                  axes: [],
                };
                groupObj.axes.push({
                  axis: clusterData.clusterNames[i],
                  value: sortedData[i].scores[featureIndex],
                  description: "",
                });
                formattedRadarData.push(groupObj);
              }
            }
          }
          featuresPicked++;
          index++;
          if (
            x == numberOfClusters - 1 &&
            featuresPicked != featuresToBePicked
          ) {
            x = -1;
          }
        }
      }
      return formattedRadarData;
    }

    function drawGraph() {
      var element = document.getElementById("radar-chart");
      var graphData = createGraphData(
        data[selectedModelID][datasource].clusterVarImp,
        radarChartType
      );
      SegmentationRadarChart.draw(element, graphData, config);
      let viewBox = qs(
        ".model-interpretability #radar-chart .svg-vis"
      ).getAttribute("viewBox");
      let viewBoxList = viewBox.split(" ");
      let legendItems = graphData.length;
      viewBoxList[3] = legendItems * 65;
      if (viewBoxList[3] < 560) {
        viewBoxList[3] = 560;
      } else if (viewBoxList[3] > 2500) {
        viewBoxList[3] = 2500;
      }
      viewBox = viewBoxList.toString();
      viewBox = viewBox.replaceAll(",", " ");
      qs(".model-interpretability #radar-chart .svg-vis").setAttribute(
        "viewBox",
        viewBox
      );
    }
    drawGraph();
  };

  /**
  * @method resetVariables
  * @description resets all the variables upon landing the page to avoid caching issues.
  */
  const resetVariables = function () {
    originalLHSWidth = null;
    dragging = false;
    separator = null;
    data = null;
    charts = {};
    datasource = null;
    selectedModelID = null;
    formattedSideBarData = {};
    radarChartType = "variables";
    sortedData = {};
  };

  /**
  * @method loadPage
  * @description loads the page for the first time
  */
  my.loadPage = async function (leaderboardData, source, model) {
    resetVariables();
    data = JSON.parse(JSON.stringify(leaderboardData));
    qs("#main-content .content.ml-leaderboard-segmentation").innerHTML =
      modelinterpretabilityTemplate();
    qs("#main-content .content.ml-leaderboard-segmentation").style.overflow =
      "hidden";
    getModelOptions(model);
    await getDataSourceOptions(source);
    loadClusterCharts();
    loadRadarChart(radarChartType);
    registerModelInterpretabilityListeners();
  };

  return my;
})(MODEL_INTERPRETABILITY || {});
