var MODEL_PERFORMANCE_SEGMENTATION = (function (my) {
  /**
   * @member {string} dataSource
   * @description Whether the current view is for the "test" or "training" data.
   * @private
   */
  var datasource = null;

  /**
   * @member {string} selectedModelID
   * @description Whether the current view is for the selected model ID from the dropdown.
   * @private
   */
  var selectedModelID = null;

  /**
  * @member {array} formattedConfMatrix
  * @description formatted data for confusion matrix used to generate on UI.
  * @private
  */
  var formattedConfMatrix = [];

  /**
  * @member {array} formattedPerformanceTable
  * @description formatted data for performance table used to generate on UI.
  * @private
  */
  var formattedPerformanceTable = [];

  /**
   * @member {object} data
   * @description Holds the leaderboard data.
   * @private
   */
  var data = null;

  /**
   * @member {object} mcConfig
   * @description the column details for the performance table.
   * @private
   */
  var mcConfig = null;

  /**
   * @member {object} mcConfig
   * @description the column details for the performance table , used only with test data otherwise we will get in get/config api.
   * @private
   */
  var compCols = {
    columns: [
      {
        label: "Silhouette Score",
        "data-key": "silhouette",
        "additional-tags": ["number", "percent"],
      },
      {
        label: "Accuracy",
        "data-key": "acc",
        "additional-tags": ["number", "percent"],
      },
      {
        label: "Adjusted Rand Index",
        "data-key": "adjRandIdx",
        "additional-tags": ["number"],
      },
      {
        label: "Mutual Information Score",
        "data-key": "miScore",
        "additional-tags": ["number"],
      },
    ],
  };

  /**
  * @method getDataSourceOptions
  * @description fetches the available data sources by calling an API and populates the sources dropdown.
  */
  const getDataSourceOptions = async function (source) {
    let sourcesList = [];
    try {
      sourcesList = await APP.loadAvailableSources({
        projectKey: PROJECT.currentProjectKey(),
        projVersion: PROJECT.currentProjVersion(),
      });

      if (
        sourcesList.data.posts[1]["OutOfTime"] &&
        sourcesList.data.posts[1]["OutOfTime"].length > 0
      ) {
        sourcesList = sourcesList.data.posts[1]["OutOfTime"];
        if (!sourcesList) {
          sourcesList = [];
        }
      } else {
        sourcesList = [];
      }
    } catch (err) {
      console.error(err.message);
      APP.showError(err.message);
      sourcesList = [];
    }
    const dataSourceList = sourcesList;
    const sourceOptionTemplate = qs("#sourceSelect .template");
    dataSourceList.forEach((src) => {
      if (empty(src.hasTarget)) {
        return;
      }
      const option = sourceOptionTemplate.cloneNode();
      ["value", "style", "class", "disabled"].forEach((attr) =>
        option.removeAttribute(attr)
      );
      option.setAttribute("value", src.id);
      option.innerText = src.name;
      qs("#sourceSelect").append(option);
    });
    datasource = empty(source) ? "train" : source;
    if (datasource != 'train') {
      let sourceSelect = qs(".variable-clustering #sourceSelect");
      sourceSelect.value = datasource;
    }
  };

  /**
  * @method getModelOptions
  * @description fetches the available models and populates the model dropdown.
  */
  const getModelOptions = function (model) {
    const modelList = Object.keys(data);
    selectedModelID = empty(model) ? modelList[0] : model.toUpperCase();
    const selectElement = qs("#modelSelect");
    modelList.forEach((modelID) => {
      const optionElement = document.createElement("option");
      optionElement.value = modelID;
      optionElement.textContent = data[modelID].name;
      if (modelID === selectedModelID) {
        optionElement.selected = true;
      }
      selectElement.appendChild(optionElement);
    });
  };

  /**
  * @method dataSourceChanged
  * @description reloads the page with the selected data source from the dropdown
  */
  const dataSourceChanged = function () {
    const selectElement = qs(".model-performance-segmentation #sourceSelect");
    const selectedOption = selectElement.options[selectElement.selectedIndex];
    const selectedValue = selectedOption.value;
    datasource = selectedValue;
    reloadData();
  };

  /**
  * @method modelChanged
  * @description reloads the page with the selected model from the dropdown
  */
  const modelChanged = function () {
    const selectElement = qs(".model-performance-segmentation #modelSelect");
    const selectedOption = selectElement.options[selectElement.selectedIndex];
    const selectedValue = selectedOption.value;
    selectedModelID = selectedValue;
    reloadData();
  };

  /**
  * @method reloadData
  * @description reloads the table and the side bar graph.
  */
  const reloadData = function () {
    formattedConfMatrix = [];
    formattedPerformanceTable = [];
    loadTable();
  };

  /**
  * @method formatConfusionMatrixData
  * @description formats the confusion matrix data and makes it ready to be rendered on UI.
  */
  const formatConfusionMatrixData = function (confMatrixData) {
    for (let i = 0; i < confMatrixData.cfMatrix.length; i++) {
      let intermediateObj = {};
      for (let j = 0; j < confMatrixData.clusterNames.length; j++) {
        intermediateObj[confMatrixData.clusterNames[j]] =
          confMatrixData.cfMatrix[i][j];
      }
      formattedConfMatrix.push(intermediateObj);
    }
  };

  /**
  * @method formatPerformanceTabelData
  * @description formats the model performance data and makes it ready to be rendered on UI.
  */
  const formatPerformanceTabelData = function (
    performanceTableData,
    tablecols
  ) {
    for (let col of tablecols) {
      let intermediateObj = {};
      intermediateObj["name"] = col.label;
      intermediateObj[col["data-key"]] =
        performanceTableData.compInfo[col["data-key"]];
      formattedPerformanceTable.push(intermediateObj);
    }
  };

  /**
  * @method loadConfusionMatrix
  * @description loads the confusion matrix using the segmentationTable.pug file.
  */
  const loadConfusionMatrix = function () {
    if (formattedConfMatrix.length == 0) {
      formatConfusionMatrixData(
        data[selectedModelID][datasource].confusionMatrix
      );
    }
    const confusionMatrix = qs(
      ".model-performance-segmentation .confusion-matrix"
    );
    confusionMatrix.innerHTML = "";
    confusionMatrix.appendChild(
      createNode(
        segmentationtableTemplate({
          data: formattedConfMatrix,
          headingRow: ["Confusion Matrix"],
          headerText: ["Actual/Prediction"].concat(
            Object.keys(formattedConfMatrix[0])
          ),
          headerKeys: Object.keys(formattedConfMatrix[0]),
          isConfusionMatrix: true,
          twoHeaders: true,
        })
      )
    );
    const confusionMatrixTable = confusionMatrix.qs(".segmentation-table");
    let tableWidth = 10 * Object.keys(formattedConfMatrix[0]).length;
    confusionMatrixTable.style.width = tableWidth + "%";
    confusionMatrixTable.style.maxWidth = "100%";
  };

  /**
  * @method loadPerformanceTable
  * @description loads the performance table using the performanceMetricBoxContainer.pug file.
  */
  const loadPerformanceTable = function () {
    if (useTestData) {
      mcConfig = compCols;
    } else {
      mcConfig = APP.getProperty(
        "project.config.modelcomp",
        PROJECT.currentProjectKey()
      );
    }
    if (formattedPerformanceTable.length == 0) {
      formatPerformanceTabelData(
        data[selectedModelID][datasource].modelCompInfo,
        mcConfig.columns
      );
    }
    const performanceMetricBoxContainer = qs(
      ".model-performance-segmentation .performance-metric-box-container"
    );
    performanceMetricBoxContainer.innerHTML = "";
    performanceMetricBoxContainer.appendChild(
      createNode(
        performancemetricboxcontainerTemplate({
          performanceMetricData: formattedPerformanceTable,
          headerKeys: Object.keys(
            data[selectedModelID][datasource].modelCompInfo.compInfo
          ),
        })
      )
    );
  };

  /**
  * @method loadTable
  * @description loads the confusion matrix and performance table.
  */
  const loadTable = function () {
    loadConfusionMatrix();
    loadPerformanceTable();
  };

  /**
   * @method registerMPSegmentationListeners
   * @description Register listeners for:
   * * source selection
   * * model selection
   * @private
   */
  const registerMPSegmentationListeners = function () {
    const modelPerformanceSegmentationPage = qs(
      "#main-content .content.ml-leaderboard-segmentation"
    );
    modelPerformanceSegmentationPage
      .qs("#sourceSelect")
      .addEventListener("change", dataSourceChanged);
    modelPerformanceSegmentationPage
      .qs("#modelSelect")
      .addEventListener("change", modelChanged);
  };

  /**
  * @method resetVariables
  * @description resets all the variables upon landing the page to avoid caching issues.
  */
  const resetVariables = function () {
    datasource = null;
    formattedConfMatrix = [];
    selectedModelID = null;
    data = null;
  };

  /**
  * @method loadPage
  * @description loads the page for the first time
  */
  my.loadPage = async function (leaderboardData, source, model) {
    resetVariables();
    data = leaderboardData;
    qs("#main-content .content.ml-leaderboard-segmentation").innerHTML =
      modelperformancesegmentationTemplate();
    qs("#main-content .content.ml-leaderboard-segmentation").style.overflow =
      "";
    getModelOptions(model);
    await getDataSourceOptions(source);
    loadTable();
    registerMPSegmentationListeners();
  };

  return my;
})(MODEL_PERFORMANCE_SEGMENTATION || {});
