/* global CHART */
/**
 * @module OGIVE_CHART
 * @description draws the Ogive chart shown on the `mp/mll/fprAndPA` page. Implements the 
 * [`CHART`](module-CHART.html) interface.
 */
// eslint-disable-next-line no-extra-semi
;var OGIVE_CHART=(function(my){
  my.type="ogive";

  my.optsGenerator=function(options){
    let opts=extend({},CHART.opts);
    delete opts.axis.y.tick.count;
    opts.bindto= "#ogiveCurve .graph";
    opts.axis.y.tick.format=d3.format(".2");
    opts.axis.y.label.text="Specificity / Sensitivity";
    opts.axis.x.label.text="Probability Levels";
    opts.legend={
      show: true,
    };
    opts.id=my.type;
    opts.data={
      xs:{
        Specificity: "x1",
        Sensitivity: "x2"
      },
      columns: [
        ['x1'].concat(options.data.y1.keys),
        ['x2'].concat(options.data.y2.keys),
        [options.data.y1.name].concat(options.data.y1.values),
        [options.data.y2.name].concat(options.data.y2.values),
      ],
      type: 'line',
      colors: {
         Specificity: CHART.colors[2],
         Sensitivity: CHART.colors[6]
      }
    };
    opts.grid={
      x:{show: true, lines: [{value: 0.2}, {value: 0.4}, {value: 0.6}, {value: 0.8}]},
      y:{show: true},
    };
    
    if (!empty(options.c3d3properties)){
      opts=extend(opts, options.c3d3properties);
    }
    
    return opts;
  };
  my.generate=function(options){
    let opt=my.optsGenerator(options);
    CHART.charts[opt.id]=c3.generate(opt);
    // d3.select(".c3-legend-item-ROC text").text("AUC="+(d3.format(".3f"))(options.data.auc));
    return CHART.charts[opt.id];
  }

  return my;
}(OGIVE_CHART || {}));
