/**
 * @function debounce 
 * @description Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * N milliseconds. If `immediate` is passed, trigger the function on the
 * leading edge, instead of the trailing.
 * @param {function} func Function to be debounced
 * @param {int} wait the number of milliseconds to wait before firing func again
 * @param {boolean} immediate if this is passed force call func
 * @return {function} Returns a function that you can register for your frequently called callback. It transmits the actual
 * event to the real listener only if it has been significantly long since the last time it was triggered.
 */
// eslint-disable-next-line no-unused-vars
function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
}

/**
 * @function extend 
 * @description add new and over-ride if already exist, the properties in _to that also exist in _from.
 * Similar to jQuery.extend and PHP array_merge
 * @param {object} _to target map
 * @param {object} _from source map
 * @returns {object} The _to object with all the properties in _from copied over.
 */
// eslint-disable-next-line no-redeclare, no-unused-vars
function extend(_to, _from) {
  for (var fromProp in _from) {
    var fromVal = _from[fromProp];
    // Is this value an object?  If so, iterate over its properties, copying them over
    if (fromVal && Object.prototype.toString.call(fromVal) === "[object Object]") {
      if (typeof _to[fromProp] !== typeof fromVal) {
        _to[fromProp] = fromVal;
      } else {
        _to[fromProp] = _to[fromProp] || {};
        extend(_to[fromProp], fromVal);
      }
    }
    else {
      _to[fromProp] = fromVal;
    }
  }
  return _to;
}

/**
 * @function isDnDAvailable
 * @description Is Drag and Drop functionality available. This needs to be calculated differently for different browsers.
 * This function checks for the combination of factors and returns a single `boolean`.
 * @returns {boolean} True if Drag and Drop functionality is available.
 */
// eslint-disable-next-line no-unused-vars
function isDnDAvailable(){
  var div=qs("div");
  return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window;
}

/**
 * @function tableToExcel
 * @description Convert an HTML table to a data URI that the browser can save as an excel file.
 * @return {function} a function that can be called with an HTML table and a download file name.
 */
// eslint-disable-next-line no-unused-vars
const tableToExcel = (function() {
  const uri = 'data:application/vnd.ms-excel;base64,'
    , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>'
    , base64 = function(s) { return window.btoa(unescape(encodeURIComponent(s))) }
    , format = function(s, c) { return s.replace(/{(\w+)}/g, function(m, p) { return c[p]; }) }
  return function(table, name) {
    if (!table.nodeType) table = document.getElementById(table)
    var ctx = {worksheet: name || 'Worksheet', table: table.innerHTML}
    window.location.href = uri + base64(format(template, ctx))
  }
})();

// eslint-disable-next-line no-unused-vars
function ceilPow10(num) {
  if (num === 0) {
    return 0;
  }
  if (num > 0) {
    return Math.pow(10, Math.ceil(Math.log10(num)));
  } else {
    return -1 * Math.pow(10, Math.ceil(Math.log10(Math.abs(num))));
  }
}

/**
 * @method empty
 * @description return true if parameter passed in is undefined, null, false, 0, '', '0' or an empty object or array.
 * @param {*} mixedVar 
 * @return true if value passed in passes the test above.
 */
// eslint-disable-next-line no-redeclare, no-unused-vars
function empty(mixedVar) {
  var undef
  var key
  var i
  var len
  var emptyValues = [undef, null, false, 0, '', '0']

  for (i = 0, len = emptyValues.length; i < len; i++) {
    if (mixedVar === emptyValues[i]) {
      return true
    }
  }

  if (typeof mixedVar === 'object') {
    for (key in mixedVar) {
      if (Object.prototype.hasOwnProperty.call(mixedVar, key)) {
        return false
      }
    }
    return true
  }

  return false
}

/**
 * @method strictEmpty
 * @description return true if parameter passed in is undefined, null, '' or an empty object or array.
 * @param {*} mixedVar 
 * @return true if value passed in passes the test above.
 */
// eslint-disable-next-line no-redeclare, no-unused-vars
function strictEmpty(mixedVar) {
  if (mixedVar === false || mixedVar === 0 || mixedVar === '0') {
    return false;
  } else {
    return empty(mixedVar);
  }
}
