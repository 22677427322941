/**
 * @module ML_LEADERBOARD_SEGMENTATION
 * @description Renders the Machine Learning leaderboard page for segmentation project type
 */

var ML_LEADERBOARD_SEGMENTATION = (function (my) {
  /**
   * @member {object} leaderboardData
   * @description Holds the leaderboard data.
   * @private
   */
  var leaderboardData = null;

  /**
   * @member {object} loadData
   * @description Makes the API call on /plot with suitable payload and returns them leaderboard data
   */
  const loadData = async function (iparams) {
    if (useTestData) {
      let result = await SEGMENTATION_LEADERBOARD_DATA.getData();
      return result;
    }
    return API_HELPER.getResult("plot", iparams);
  };

  /**
   * @member {object} removeResizeListener
   * @description Removes the window resize listener from the subpages.
   */
  const removeResizeListener = function () {
    if (SEGMENT_SELECTION.windowListenerAdded) {
      window.removeEventListener("resize", SEGMENT_SELECTION.resizeListener);
      delete SEGMENT_SELECTION.windowListenerAdded;
    }
    if (FEATURE_SELECTION.windowListenerAdded) {
      window.removeEventListener("resize", FEATURE_SELECTION.resizeListener);
      delete FEATURE_SELECTION.windowListenerAdded;
    }
    if (MODEL_INTERPRETABILITY.windowListenerAdded) {
      window.removeEventListener(
        "resize",
        MODEL_INTERPRETABILITY.resizeListener
      );
      delete MODEL_INTERPRETABILITY.windowListenerAdded;
    }
    if (CLUSTER_MERGING.windowListenerAdded) {
      window.removeEventListener("resize", CLUSTER_MERGING.resizeListener);
      delete CLUSTER_MERGING.windowListenerAdded;
    }
  };

  /**
   * @member {object} loadHtml
   * @description loads a particular page of the leaderboard based on the subnav.
   */
  var loadHtml = function (subnav, source, model) {
    qs("#main-content .content.ml-leaderboard-segmentation").style.display =
      "block";
    if (subnav == "featureSelection") {
      FEATURE_SELECTION.loadPage(leaderboardData, source, model);
    } else if (subnav == "optimalSegmentSelection") {
      SEGMENT_SELECTION.loadPage(leaderboardData, source, model);
    } else if (subnav == "variableImportance") {
      VARIABLE_IMPORTANCE.loadPage(leaderboardData, source, model);
    } else if (subnav == "modelPerformanceSegmentation") {
      MODEL_PERFORMANCE_SEGMENTATION.loadPage(leaderboardData, source, model);
    } else if (subnav == "modelInterpretability") {
      MODEL_INTERPRETABILITY.loadPage(leaderboardData, source, model);
    } else if (subnav == "clusterVisualization") {
      CLUSTER_VISUALIZATION.loadPage(leaderboardData, source, model);
    } else if (subnav == "clusterMerging") {
      CLUSTER_MERGING.loadPage(leaderboardData, source, model);
    }
  };

  /**
   * @member {object} loadHtml
   * @description the first function which is called from app-router.js on ml-leaderboard-segmentation route.
   * @public
   */
  my.show = async function (subnav, source, model, pkey) {
    qs("#main-content .content.ml-leaderboard-segmentation").style.zIndex = 1;
    qs("#main-content .content.ml-leaderboard-segmentation").removeClass(
      "isClusterVisualization"
    );
    const leaderboardSegmentationHtml = qs(".ml-leaderboard-segmentation");
    if (leaderboardSegmentationHtml) {
      leaderboardSegmentationHtml.innerHTML = "";
    }
    if (!leaderboardData) {
      APP.setProgress("Loading data...");
      leaderboardData = await loadData({
        projectKey: pkey,
        projVersion: PROJECT.currentProjVersion(),
      });
      APP.resetProgress();
    }
    removeResizeListener();
    loadHtml(subnav, source, model);
  };

  /**
   * @member {object} unloadPage
   * @description called from the app-router to unload the page when route is changed from ml-leaderboard-segmentation.
   * @public
   */
  my.unloadPage = function () {
    leaderboardData = null;
    qs("#main-content .content.ml-leaderboard-segmentation").style.display =
      "none";
  };

  return my;
})(ML_LEADERBOARD_SEGMENTATION || {});
