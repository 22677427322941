var MediaState = function(){
  this.device=null;
  this.orientation=null;
  var _this = this;
  window.addEventListener("orientationchange", function(){_this.detect();});
  window.addEventListener("resize", debounce(function(){_this.detect();}, 250), supportsPassive ? {passive: true} : false); 
  this.detect();
};
MediaState.prototype.equals = function( x ){return (x!=null && ("undefined"!==typeof x) && this.device === x.device && this.orientation===x.orientation);};
MediaState.prototype.fireChange = function(){document.body.fireCustomEvent("mediaChanged");};
MediaState.prototype.assign = function( x ){this.device = x.device; this.orientation = x.orientation; this.fireChange();};
MediaState.prototype.detect = function(){
  var mobile = window.matchMedia("(max-width: 500px)");
  var tablet = window.matchMedia("(min-width: 501px) and (max-width: 768px)");
  var laptop = window.matchMedia("(min-width: 769px) and (max-width: 1366px)");
  var desktop = window.matchMedia("(min-width: 1367px)");
  var portrait = window.matchMedia("(orientation: portrait");

  var changed=false;

  if (mobile.matches){this.device = "mobile"; changed=true;}
  if (tablet.matches){this.device = "tablet"; changed=true;}
  if (laptop.matches){this.device = "laptop"; changed=true;}
  if (desktop.matches){this.device = "desktop"; changed=true;}
  if (portrait.matches){
    if (this.orientation != "portrait"){
      this.orientation = "portrait";
      changed=true;
    }
  } else {
    if (this.orientation != "landscape"){
      this.orientation = "landscape";
      changed=true;
    }
  }
  if (changed){
    this.fireChange();
  }
};
