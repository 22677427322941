/**
 * @module  TEXT_MULTI_CLASSIFICATION_CUSTOMIZER
 * @description
 */
var TEXT_MULTI_CLASSIFICATION_CUSTOMIZER = (function (my) {
  my.id = "txt-mclass";

  // eslint-disable-next-line no-unused-vars
  my.preRoute = function (router, params) {};

  my.postRoute = function (router, params) {
    params.nav
      .qsa(
        [
          "#navItem-mlleaderboard-txt-mclass-modelPerformanceTextMultiClassification-nav-link",
        ].join(", ")
      )
      .forEach((x) => x.removeClass("hidden"));
    CUSTOMIZER_HELPER.configHandler(params);
  };

  my.reroute = function (params) {
    return CUSTOMIZER_HELPER.reroute(params, "txt-mclass");
  };

  return my;
})(TEXT_MULTI_CLASSIFICATION_CUSTOMIZER || {});
